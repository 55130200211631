/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React, { Fragment, useEffect, useState } from 'react';
import {
    Clickable,
    FileModel,
    FileStatusEnums,
    FormChangeable,
    Nullable,
} from '../../../interfaces';
import { CircleIconButton } from '../../atoms/buttons/CircleIconButton';
import { DocumentIcon, GarbageIcon } from '../../atoms/icons/BaseIcons';
import bytes from 'bytes';
import { FileUploadStatusIcon } from '../../atoms/upload/FileUploadStatusIcon';
import {
    MuiAutocomplete,
    MuiAutocompleteProps,
    OptionType,
} from '../../atoms/mui/muiAutocomplete/MuiAutocomplete';
import { MuiTextInput } from '../../atoms/mui/muiTextInput/MuiTextInput';
import { Alert, Card, CardContent, Grid } from '@mui/material';
import { FileCategoryEnums, is } from '../../../modules';

/**
 * Destructure necessary imports
 */
const { UNKNOWN } = FileCategoryEnums;

/**
 * Interface
 */
interface FileItemProps extends MuiAutocompleteProps {
    categoryErrorMessage?: string;
    enableCategories?: boolean;
    file: FileModel;
    handleAmountCallback?: ({
        id,
        amount,
    }: {
        id: string;
        amount: string;
    }) => void;
    handleDefinitionCallback?: ({
        id,
        definition,
    }: {
        id: string;
        definition: string;
    }) => void;
    onDelete: (e: Clickable, file: FileModel) => void;
    onRemove: (e: Clickable, file: FileModel) => void;
    receiptAmountErrorMessage?: string;
    receiptAmountLabel?: string;
    receiptAmountPlaceholder?: string;
    receiptDefinitionErrorMessage?: string;
    receiptDefinitionLabel?: string;
    receiptDefinitionPlaceholder?: string;
    receiptInformation?: boolean;
    requireCategory?: boolean;
}

/**
 * Component view and component logic
 */
export const FileItem = ({
    categoryErrorMessage,
    enableCategories,
    error,
    errorMessage,
    file,
    handleAmountCallback,
    handleDefinitionCallback,
    id,
    label,
    noOptionsText,
    onBlur,
    onChange,
    onDelete,
    onRemove,
    openOnFocus,
    options,
    placeholder,
    receiptAmountErrorMessage,
    receiptAmountLabel = '',
    receiptAmountPlaceholder = '',
    receiptDefinitionErrorMessage,
    receiptDefinitionLabel = '',
    receiptDefinitionPlaceholder = '',
    receiptInformation,
    requireCategory,
    value,
}: FileItemProps) => {
    const renderErrorMessage = (file: FileModel) => {
        return file.errors.map((error, idx) => {
            return (
                <Grid key={idx} item xs={12}>
                    <Alert severity={'error'} variant={'outlined'}>
                        {error.error.message}
                    </Alert>
                </Grid>
            );
        });
    };
    const [fileDefinition, setFileDefinition] = useState<string>('');
    const [fileAmount, setFileAmount] = useState<string>('');
    const [fileCategory, setFileCategory] =
        useState<Nullable<OptionType | OptionType[]>>(null);

    useEffect(() => {
        if (file) {
            if (file.amount) {
                setFileAmount(file.amount.toString());
            }
            if (file.definition) {
                setFileDefinition(file?.definition);
            }
        }

        if (value) {
            setFileCategory(value);
        }
    }, [file, value]);

    const handleDefinitionChange = (e: FormChangeable) => {
        e.preventDefault();
        const { value } = e.currentTarget;
        setFileDefinition(value);
        handleDefinitionCallback &&
            handleDefinitionCallback({ id: file.id, definition: value });
    };

    const handleAmountChange = (e: FormChangeable) => {
        e.preventDefault();
        const { value } = e.currentTarget;
        setFileAmount(value);
        handleAmountCallback &&
            handleAmountCallback({ id: file.id, amount: value });
    };

    return (
        <Fragment>
            <Card style={{ marginTop: '1rem' }} variant={'outlined'}>
                <CardContent>
                    <Grid
                        alignItems={'center'}
                        container
                        direction={'row'}
                        justifyContent={'center'}
                        key={`file-item-${file.id}`}
                        spacing={3}
                    >
                        <Grid item xs={2}>
                            <CircleIconButton
                                dataTestId={'icon-doc'}
                                className={'file-icon small'}
                                icon={<DocumentIcon />}
                                handleClick={() => null}
                                ariaLabel={'Open document'}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'left' }}>
                            <h3 className={'file-name'} data-hj-suppress>
                                {file.name}
                            </h3>
                            <span className={'file-size'}>
                                {bytes(file.size)}
                            </span>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            className={'item-wrapper'}
                            style={{ border: 0 }}
                        >
                            <FileUploadStatusIcon
                                file={file}
                                handleError={onRemove}
                                handleWarning={onRemove}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {file.status === FileStatusEnums.PASSED && (
                                <CircleIconButton
                                    dataTestId={'btn-delete'}
                                    icon={<GarbageIcon />}
                                    name={`btn-delete-${file.name}`}
                                    className={'delete-button small'}
                                    handleClick={(e: Clickable) =>
                                        onDelete(e, file)
                                    }
                                    ariaLabel={'Delete uploaded item'}
                                />
                            )}
                        </Grid>
                        {enableCategories && (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ alignSelf: 'flex-start' }}
                            >
                                <MuiAutocomplete
                                    error={
                                        (requireCategory &&
                                            (!file.category ||
                                                is(file.category, UNKNOWN))) ||
                                        error
                                    }
                                    errorMessage={
                                        requireCategory &&
                                        (!file.category ||
                                            is(file.category, UNKNOWN))
                                            ? categoryErrorMessage
                                            : errorMessage
                                    }
                                    value={fileCategory}
                                    {...{
                                        id,
                                        label,
                                        noOptionsText,
                                        onBlur,
                                        onChange,
                                        openOnFocus,
                                        options,
                                        placeholder,
                                    }}
                                />
                            </Grid>
                        )}
                        {receiptInformation && (
                            <Fragment>
                                <Grid item xs={12} md={4}>
                                    <MuiTextInput
                                        error={!fileDefinition}
                                        errorMessage={
                                            !fileDefinition
                                                ? receiptDefinitionErrorMessage
                                                : ''
                                        }
                                        fullWidth={true}
                                        id={`file-definition-${file.id}`}
                                        label={receiptDefinitionLabel}
                                        onChange={handleDefinitionChange}
                                        placeholder={
                                            receiptDefinitionPlaceholder
                                        }
                                        value={file.definition}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MuiTextInput
                                        error={!fileAmount}
                                        errorMessage={
                                            !fileAmount
                                                ? receiptAmountErrorMessage
                                                : ''
                                        }
                                        fullWidth={true}
                                        id={`file-amount-${file.id}`}
                                        inputMode={'numeric'}
                                        label={receiptAmountLabel}
                                        onChange={handleAmountChange}
                                        placeholder={receiptAmountPlaceholder}
                                        type={'number'}
                                        value={file.amount?.toString()}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                        {renderErrorMessage(file)}
                    </Grid>
                </CardContent>
            </Card>
        </Fragment>
    );
};
