export function registrationNumberFinlandValidator(value: string) {
    const diplomat = new RegExp(/^CD-[1-9]{1}[0-9]{0,3}$/);
    const other = new RegExp(/^C-[1-9]{1}[0-9]{0,4}$/);
    const regular = new RegExp(
        /^[a-zåäöA-ZÅÄÖ0-9]{1,3}-[a-zåäöA-ZÅÄÖ0-9]{1,4}$/
    );

    return {
        isValid: function isValid(): boolean {
            // diplomat licence plate
            if (diplomat.test(value)) {
                return true;
            }
            // other tax-free diplomat licence plate
            if (other.test(value)) {
                return true;
            }
            // regular licence plate
            return regular.test(value);
        },
    };
}
