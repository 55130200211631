import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    AssociationIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    CompanyIcon,
    InsuranceTypeKeys,
    MunicipalityIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectInsuranceType } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { COMPANY, MUNICIPALITY_SCHOOL, PRIVATE } = InsuranceTypeKeys;

/**
 * Page view and page logic
 */
export const deathInsuranceTypePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: COMPANY, name: t(`lob.person.death.insuranceType.select.${COMPANY}`), icon: <CompanyIcon /> },
        { id: PRIVATE, name: t(`lob.person.death.insuranceType.select.${PRIVATE}`), icon: <AssociationIcon /> },
        {
            id: MUNICIPALITY_SCHOOL,
            name: t(`lob.person.death.insuranceType.select.${MUNICIPALITY_SCHOOL}`),
            icon: <MunicipalityIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_DEATH_INSURANCE_TYPE,
        stateKey: 'insuranceType',
        i18n: {
            ns: 'lob.person.death.insuranceType',
        },
        selectorValue: selectInsuranceType(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { insuranceType: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
