/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { FileModel } from '../../../interfaces/models/File.model';
import { Clickable } from '../../../interfaces/types/Events.types';
import { Fragment } from 'react';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';
import { SummaryNavButton } from '../../atoms/summaryItems/SummaryNavButton';
import { TFunction } from 'i18next';

interface FileListProps {
    list: FileModel[];
    textKey: string;
    dataTestId: string;
    path: string;
    show: boolean;
    handleClick?: (e: Clickable, path: string) => void;
    t?: TFunction;
}

export const FileList = ({
    list,
    textKey,
    dataTestId,
    path,
    show,
    handleClick,
    t,
}: FileListProps) => {
    return (
        <Fragment>
            {show && <SummaryText text={textKey} />}
            {show &&
                list.map((item, idx) => {
                    const buttonText = `${item.name}${
                        item.category
                            ? t
                                ? ` (${t(
                                      `file.category.select.${item.category}`
                                  )})`
                                : ` (${item.category})`
                            : ''
                    }${item.amount ? ` (${item.amount})` : ''}${
                        item.definition ? ` (${item.definition})` : ''
                    }`;
                    return (
                        <div key={`files-${idx}`} className={'file-list'}>
                            <SummaryNavButton
                                dataTestId={`${dataTestId}${idx}`}
                                {...{ path, handleClick, buttonText }}
                            />
                        </div>
                    );
                })}
        </Fragment>
    );
};
