import FileModel from '../models/File';
import { Nullable } from '@protectorinsurance/ds-can';

export const createFormData = (file: FileModel, jwt?: Nullable<string>) => {
    const data = new FormData();
    data.append('file', file.src.file, file.name);
    if (jwt !== undefined && jwt !== null) {
        data.append('jwt', jwt);
    }
    return data;
};
