import { HttpStatusCodeEnums } from '../../common/interfaces/enums';
import { Nullable } from '../../../../interfaces/types';
import { CANResponseErrorModel } from '../../common/interfaces/models';
import { VehicleErrorEnums } from '../interfaces/enums';
import { vehicleServiceErrorHandlerLocalePrefix } from './vehicleServiceErrorHandlerLocalePrefix';

const { NOT_FOUND, NO_CONTENT } = HttpStatusCodeEnums;

export const vehicleServiceErrorHandler = (
    err: Nullable<CANResponseErrorModel>
) => {
    if ([NOT_FOUND, NO_CONTENT].includes(err?.status as HttpStatusCodeEnums)) {
        return vehicleServiceErrorHandlerLocalePrefix(
            VehicleErrorEnums.NOT_FOUND
        );
    } else if (err?.message) {
        return err.message;
    }

    return false;
};
