import React from 'react';

interface PartCheckboxGroupProps {
    label: string;
    labelFor?: string;
    description: string;
    children: React.ReactNode;
}

export const PartCheckboxGroupLeft = ({
    label,
    labelFor,
    description,
    children,
}: PartCheckboxGroupProps) => {
    const labelHtmlFor = labelFor ? `checkbox-${labelFor}` : undefined;
    return (
        <div className={'parts-left__group'}>
            <div className={'parts-left__description'}>
                <label htmlFor={labelHtmlFor}>{label}</label>
                <p>{description}</p>
            </div>
            <div className={'parts-left__checkboxes space-x-3'}>{children}</div>
        </div>
    );
};
