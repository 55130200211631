import { Nullable } from '../../../../../interfaces';

export enum BodyPartListKeys {
    ANKLE_LEFT = 'ANKLE_LEFT',
    ANKLE_RIGHT = 'ANKLE_RIGHT',
    ARM_LEFT = 'ARM_LEFT',
    ARM_RIGHT = 'ARM_RIGHT',
    ARM_LEFT_BACK = 'ARM_LEFT_BACK',
    ARM_RIGHT_BACK = 'ARM_RIGHT_BACK',
    ARM_LEFT_FRONT = 'ARM_LEFT_FRONT',
    ARM_RIGHT_FRONT = 'ARM_RIGHT_FRONT',
    BACK = 'BACK',
    BACK_SPINE = 'BACK_SPINE',
    CHEST = 'CHEST',
    CRANIUM = 'CRANIUM',
    EAR_LEFT = 'EAR_LEFT',
    EAR_RIGHT = 'EAR_RIGHT',
    EYE_LEFT = 'EYE_LEFT',
    EYE_RIGHT = 'EYE_RIGHT',
    FACE = 'FACE',
    FINGERS_LEFT = 'FINGERS_LEFT',
    FINGERS_RIGHT = 'FINGERS_RIGHT',
    FOOT_LEFT = 'FOOT_LEFT',
    FOOT_LEFT_LEGACY = 'FOOT_LEFT_LEGACY',
    FOOT_RIGHT = 'FOOT_RIGHT',
    FOOT_RIGHT_LEGACY = 'FOOT_RIGHT_LEGACY',
    HAND_LEFT = 'HAND_LEFT',
    HAND_LEFT_LEGACY = 'HAND_LEFT_LEGACY',
    HAND_RIGHT = 'HAND_RIGHT',
    HAND_RIGHT_LEGACY = 'HAND_RIGHT_LEGACY',
    HEAD = 'HEAD',
    HEAD_BACK = 'HEAD_BACK',
    HEAD_FRONT = 'HEAD_FRONT',
    HEAD_OTHER = 'HEAD_OTHER',
    HIP_BACK = 'HIP_BACK',
    HIP_FRONT = 'HIP_FRONT',
    LEG_LEFT = 'LEG_LEFT',
    LEG_RIGHT = 'LEG_RIGHT',
    LEG_LEFT_BACK = 'LEG_LEFT_BACK',
    LEG_RIGHT_BACK = 'LEG_RIGHT_BACK',
    LEG_LEFT_FRONT = 'LEG_LEFT_FRONT',
    LEG_RIGHT_FRONT = 'LEG_RIGHT_FRONT',
    LOWER_EXTREMITIES_BACK = 'LOWER_EXTREMITIES_BACK',
    LOWER_EXTREMITIES_FRONT = 'LOWER_EXTREMITIES_FRONT',
    LOWER_EXTREMITIES_OTHER_BACK = 'LOWER_EXTREMITIES_OTHER_BACK',
    LOWER_EXTREMITIES_OTHER_FRONT = 'LOWER_EXTREMITIES_OTHER_FRONT',
    NECK_BACK = 'NECK_BACK',
    NECK_FRONT = 'NECK_FRONT',
    PELVIC = 'PELVIC',
    RIBS_BACK = 'RIBS_BACK',
    RIBS_FRONT = 'RIBS_FRONT',
    SHOULDER_LEFT_BACK = 'SHOULDER_LEFT_BACK',
    SHOULDER_RIGHT_BACK = 'SHOULDER_RIGHT_BACK',
    SHOULDER_LEFT_FRONT = 'SHOULDER_LEFT_FRONT',
    SHOULDER_RIGHT_FRONT = 'SHOULDER_RIGHT_FRONT',
    TEETH = 'TEETH',
    TEETH_LEGACY = 'TEETH_LEGACY',
    TOES_LEFT = 'TOES_LEFT',
    TOES_RIGHT = 'TOES_RIGHT',
    TORSO_BACK = 'TORSO_BACK',
    TORSO_BACK_LEGACY = 'TORSO_BACK_LEGACY',
    TORSO_BACK_OTHER = 'TORSO_BACK_OTHER',
    TORSO_FRONT = 'TORSO_FRONT',
    TORSO_FRONT_LEGACY = 'TORSO_FRONT_LEGACY',
    TORSO_FRONT_OTHER = 'TORSO_FRONT_OTHER',
    UPPER_EXTREMITIES_BACK = 'UPPER_EXTREMITIES_BACK',
    UPPER_EXTREMITIES_FRONT = 'UPPER_EXTREMITIES_FRONT',
    UPPER_EXTREMITIES_OTHER_BACK = 'UPPER_EXTREMITIES_OTHER_BACK',
    UPPER_EXTREMITIES_OTHER_FRONT = 'UPPER_EXTREMITIES_OTHER_FRONT',
    WHOLE_BODY = 'WHOLE_BODY',
    WRIST_LEFT = 'WRIST_LEFT',
    WRIST_RIGHT = 'WRIST_RIGHT',
}

export type BodyPartListModel = { id: Nullable<number>; key: Nullable<string> };

export type BodyPartListTypeModel = Array<BodyPartListModel>;

export const bodyPartListIds = [
    { key: BodyPartListKeys.CRANIUM, id: 84 },
    { key: BodyPartListKeys.FACE, id: 85 },
    { key: BodyPartListKeys.EYE_LEFT, id: 86 },
    { key: BodyPartListKeys.EYE_RIGHT, id: 86 },
    { key: BodyPartListKeys.EAR_LEFT, id: 87 },
    { key: BodyPartListKeys.EAR_RIGHT, id: 87 },
    { key: BodyPartListKeys.TEETH, id: 88 },
    { key: BodyPartListKeys.HEAD, id: 89 },
    { key: BodyPartListKeys.HEAD_OTHER, id: 90 },
    { key: BodyPartListKeys.NECK_BACK, id: 91 },
    { key: BodyPartListKeys.NECK_FRONT, id: 92 },
    { key: BodyPartListKeys.BACK_SPINE, id: 93 },
    { key: BodyPartListKeys.BACK, id: 94 },
    { key: BodyPartListKeys.RIBS_BACK, id: 95 },
    { key: BodyPartListKeys.RIBS_FRONT, id: 95 },
    { key: BodyPartListKeys.CHEST, id: 96 },
    { key: BodyPartListKeys.PELVIC, id: 97 },
    { key: BodyPartListKeys.TORSO_FRONT, id: 98 },
    { key: BodyPartListKeys.TORSO_BACK, id: 98 },
    { key: BodyPartListKeys.TORSO_FRONT_OTHER, id: 99 },
    { key: BodyPartListKeys.TORSO_BACK_OTHER, id: 99 },
    { key: BodyPartListKeys.SHOULDER_LEFT_BACK, id: 100 },
    { key: BodyPartListKeys.SHOULDER_RIGHT_BACK, id: 100 },
    { key: BodyPartListKeys.SHOULDER_LEFT_FRONT, id: 100 },
    { key: BodyPartListKeys.SHOULDER_RIGHT_FRONT, id: 100 },
    { key: BodyPartListKeys.ARM_LEFT_BACK, id: 101 },
    { key: BodyPartListKeys.ARM_RIGHT_BACK, id: 101 },
    { key: BodyPartListKeys.ARM_LEFT_FRONT, id: 101 },
    { key: BodyPartListKeys.ARM_RIGHT_FRONT, id: 101 },
    { key: BodyPartListKeys.HAND_LEFT, id: 102 },
    { key: BodyPartListKeys.HAND_RIGHT, id: 102 },
    { key: BodyPartListKeys.FINGERS_LEFT, id: 103 },
    { key: BodyPartListKeys.FINGERS_RIGHT, id: 103 },
    { key: BodyPartListKeys.WRIST_LEFT, id: 104 },
    { key: BodyPartListKeys.WRIST_RIGHT, id: 104 },
    { key: BodyPartListKeys.UPPER_EXTREMITIES_BACK, id: 105 },
    { key: BodyPartListKeys.UPPER_EXTREMITIES_FRONT, id: 105 },
    { key: BodyPartListKeys.UPPER_EXTREMITIES_OTHER_BACK, id: 106 },
    { key: BodyPartListKeys.UPPER_EXTREMITIES_OTHER_FRONT, id: 106 },
    { key: BodyPartListKeys.HIP_BACK, id: 107 },
    { key: BodyPartListKeys.HIP_FRONT, id: 107 },
    { key: BodyPartListKeys.LEG_LEFT_BACK, id: 108 },
    { key: BodyPartListKeys.LEG_RIGHT_BACK, id: 108 },
    { key: BodyPartListKeys.LEG_LEFT_FRONT, id: 108 },
    { key: BodyPartListKeys.LEG_RIGHT_FRONT, id: 108 },
    { key: BodyPartListKeys.ANKLE_LEFT, id: 109 },
    { key: BodyPartListKeys.ANKLE_RIGHT, id: 109 },
    { key: BodyPartListKeys.FOOT_LEFT, id: 110 },
    { key: BodyPartListKeys.FOOT_RIGHT, id: 110 },
    { key: BodyPartListKeys.TOES_LEFT, id: 111 },
    { key: BodyPartListKeys.TOES_RIGHT, id: 111 },
    { key: BodyPartListKeys.LOWER_EXTREMITIES_BACK, id: 112 },
    { key: BodyPartListKeys.LOWER_EXTREMITIES_FRONT, id: 112 },
    { key: BodyPartListKeys.LOWER_EXTREMITIES_OTHER_BACK, id: 113 },
    { key: BodyPartListKeys.LOWER_EXTREMITIES_OTHER_FRONT, id: 113 },
    { key: BodyPartListKeys.WHOLE_BODY, id: 114 },
    { key: BodyPartListKeys.HEAD_OTHER, id: 116 },
    { key: BodyPartListKeys.HEAD_FRONT, id: 118 },
    { key: BodyPartListKeys.HEAD_BACK, id: 119 },
    { key: BodyPartListKeys.TORSO_FRONT_LEGACY, id: 120 },
    { key: BodyPartListKeys.TORSO_BACK_LEGACY, id: 121 },
    { key: BodyPartListKeys.ARM_LEFT, id: 122 },
    { key: BodyPartListKeys.ARM_RIGHT, id: 123 },
    { key: BodyPartListKeys.HAND_LEFT_LEGACY, id: 124 },
    { key: BodyPartListKeys.HAND_RIGHT_LEGACY, id: 125 },
    { key: BodyPartListKeys.LEG_LEFT, id: 126 },
    { key: BodyPartListKeys.LEG_RIGHT, id: 127 },
    { key: BodyPartListKeys.FOOT_LEFT_LEGACY, id: 128 },
    { key: BodyPartListKeys.FOOT_RIGHT_LEGACY, id: 129 },
    { key: BodyPartListKeys.TEETH_LEGACY, id: 130 },
    { key: BodyPartListKeys.WHOLE_BODY, id: 131 },
];
