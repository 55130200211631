import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectClaimCauseConsequences } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    AccidentIcon,
    addRemoveArray,
    Carousel,
    CarouselItem,
    Clickable,
    ContusionIcon,
    CrackedIcon,
    CrushInjuryIcon,
    CutInjuryIcon,
    DislocatedIcon,
    OtherInjuriesIcon,
    PageLayout,
    TwistedKneeIcon,
} from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { personActions } from '../../../sagas/person';
import { commonActions } from '../../../sagas/common';
import { ClaimCauseConsequencesKeys } from '../../../models/person/ClaimCauseConsequences';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { CRACK, CONTUSION, CRUSH, DISLOCATION, FRACTURE, OTHER, SPRAIN, WOUND } = ClaimCauseConsequencesKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { DYN_CAROUSEL_ACCIDENT_HAS_PLASTER_TREATMENT } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const AccidentClaimCauseConsequencesPage = () => {
    const dispatch = useDispatch();
    const claimCauseConsequences = useSelector(selectClaimCauseConsequences);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.claimCauseConsequences');

    const handleBackButton = useGoBack();

    const options = [
        { icon: <CutInjuryIcon />, id: WOUND, name: tWithNS.t(`select.${WOUND}`) },
        { icon: <ContusionIcon />, id: CONTUSION, name: tWithNS.t(`select.${CONTUSION}`) },
        { icon: <TwistedKneeIcon />, id: SPRAIN, name: tWithNS.t(`select.${SPRAIN}`) },
        { icon: <DislocatedIcon />, id: DISLOCATION, name: tWithNS.t(`select.${DISLOCATION}`) },
        { icon: <CrackedIcon />, id: CRACK, name: tWithNS.t(`select.${CRACK}`) },
        { icon: <AccidentIcon />, id: FRACTURE, name: tWithNS.t(`select.${FRACTURE}`) },
        { icon: <CrushInjuryIcon />, id: CRUSH, name: tWithNS.t(`select.${CRUSH}`) },
        { icon: <OtherInjuriesIcon />, id: OTHER, name: tWithNS.t(`select.${OTHER}`) },
    ];

    const selected = (id: ClaimCauseConsequencesKeys, e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const option = options.find((x) => x.id === id);

        if (option) {
            const tempClaimCauseConsequences = addRemoveArray(claimCauseConsequences, option.id);
            dispatcherWithPromise(dispatch, personActions.update, {
                claimCauseConsequences: tempClaimCauseConsequences,
            }).then(() => dispatcherWithPromise(dispatch, commonActions.send));
        }
    };

    const renderSelection = options.map((option, idx) => {
        return (
            <CarouselItem
                {...option}
                handleClick={selected}
                isSelected={claimCauseConsequences.includes(option.id)}
                key={idx}
                {...{ customCAN }}
            />
        );
    });

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let nextAction = wizardRouterActions.goToNext();
        if (claimCauseConsequences.includes(FRACTURE)) {
            nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_ACCIDENT_HAS_PLASTER_TREATMENT);
        }
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Carousel>{renderSelection}</Carousel>
        </PageLayout>
    );
};
