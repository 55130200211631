import {
    Rejectable,
    Resolvable,
} from '../../../../interfaces/types/Promisable.types';

export const actionWithPromise =
    <T, D = any>(type: T) =>
    (data?: D, resolve?: Resolvable, reject?: Rejectable) => ({
        type,
        data,
        resolve,
        reject,
    });
