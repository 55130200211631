import { businessNumberFinlandMatchPattern } from './businessNumberFinlandMatchPattern';

export function businessNumberFinlandValidator(businessNumber: string) {
    return {
        isValid: function isValid() {
            if (
                !businessNumber ||
                businessNumber.length === 0 ||
                businessNumber.length !== 9
            ) {
                return false;
            }

            const k = businessNumber.indexOf('-');
            return k === 7 && businessNumberFinlandMatchPattern(businessNumber);
        },
    };
}
