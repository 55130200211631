import { VehicleModel } from '../interfaces/models';

export const displayVehicleUtil = (vehicle: VehicleModel) => {
    const {
        registrationNumber,
        make,
        type,
        model,
        vehicleType,
        vehicleInternalReferenceNumber,
        chassisNumber,
    } = vehicle;
    return `${make ? make : ''}${
        !!type &&
        !!model &&
        !!vehicleType &&
        !!vehicleInternalReferenceNumber &&
        !!chassisNumber &&
        !!registrationNumber
            ? ' '
            : ''
    }${type ? type : ''}${
        !!model &&
        !!vehicleType &&
        !!vehicleInternalReferenceNumber &&
        !!chassisNumber &&
        !!registrationNumber
            ? ' '
            : ''
    }${model ? model : ''}${
        !!vehicleType &&
        !!vehicleInternalReferenceNumber &&
        !!chassisNumber &&
        !!registrationNumber
            ? ' '
            : ''
    }${vehicleType ? `(${vehicleType})` : ''}${
        !!vehicleInternalReferenceNumber &&
        !!chassisNumber &&
        !!registrationNumber
            ? ' '
            : ''
    }${registrationNumber ? registrationNumber : ''}${
        !!vehicleInternalReferenceNumber && !!chassisNumber ? ', ' : ''
    }${vehicleInternalReferenceNumber ? vehicleInternalReferenceNumber : ''}${
        chassisNumber ? ', ' : ''
    }${chassisNumber ? chassisNumber : ''}`;
};
