import { BankIDJWTModel } from '../../models/motor/BankID';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { api, APPLICATION_JSON_HEADER, HTTP_STATUS } from 'utils/api';
import { NODE_API_BASE_URL } from '../../config/api';
import { FormFieldErrors } from '../../config/formFieldNames';
import { selectBankIDService } from '../selectors/bankIDSelectors';
import { wizardRouterActions } from '../wizardRouter';
import { BaseRoutePaths } from '../../config/wizardRouter/baseWizardRoutes';
import { selectJWT } from '../selectors/commonSelectors';
import { motorInitState, MotorState } from '../motor';
import { commonActions } from '../common';
import {
    actionWithPromise,
    emptyFn,
    Nullable,
    PFError,
    PFRequestError,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { call, put, select } from 'typed-redux-saga';

/**
 * Action types
 */
export enum BankIDJWTServiceActionTypes {
    REQUEST = '@app/bankid/REQUEST',
    SUCCESS = '@app/bankid/SUCCESS',
    FAILURE = '@app/bankid/FAILURE',
    SUBMISSION_ID = '@app/bankid/SUBMISSION_ID',
    FETCH = '@app/bankid/FETCH',
    ERROR = '@app/bankid/ERROR',
}

/**
 * Action Definitions
 */
export interface BankIDJWTServiceAction {
    type: BankIDJWTServiceActionTypes;
    data?: BankIDJWTModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const bankIDJWTServiceActions = {
    request: actionWithPromise<BankIDJWTServiceActionTypes>(BankIDJWTServiceActionTypes.REQUEST),
    success: actionWithPromise<BankIDJWTServiceActionTypes, BankIDJWTData>(BankIDJWTServiceActionTypes.SUCCESS),
    failure: actionWithPromise<BankIDJWTServiceActionTypes, PFRequestError>(BankIDJWTServiceActionTypes.FAILURE),
    submissionId: actionWithPromise<BankIDJWTServiceActionTypes, BankIDJWTData>(
        BankIDJWTServiceActionTypes.SUBMISSION_ID
    ),
    fetch: actionWithPromise<BankIDJWTServiceActionTypes, BankIDJWTData>(BankIDJWTServiceActionTypes.FETCH),
    error: actionWithPromise<BankIDJWTServiceActionTypes, Partial<PFError>>(BankIDJWTServiceActionTypes.ERROR),
};

export interface BankIDJWTData {
    jwt: Nullable<string>;
    hasExistingClaim: boolean;
    submissionId: string;
    storedClaim: MotorState;
}

export interface BankIDJWTState {
    data: BankIDJWTData;
}

export const bankIDJWTInitState = {
    data: {
        jwt: null,
        hasExistingClaim: false,
        submissionId: '',
        storedClaim: motorInitState,
    },
};

export default function (state = bankIDJWTInitState, { type, data }: BankIDJWTServiceAction) {
    switch (type) {
        case BankIDJWTServiceActionTypes.SUCCESS:
            return { ...state, data };
        case BankIDJWTServiceActionTypes.ERROR:
            return { ...state, errors: [data] };
        default:
            return state;
    }
}

/**
 * Saga watchers
 */
export const bankIDJWTWatcher = function* () {
    yield takeLatest(BankIDJWTServiceActionTypes.REQUEST, bankIDJWTServiceSagas.request);
    yield takeEvery(BankIDJWTServiceActionTypes.SUBMISSION_ID, bankIDJWTServiceSagas.submissionId);
    yield takeEvery(BankIDJWTServiceActionTypes.FETCH, bankIDJWTServiceSagas.fetch);
};

/**
 * Saga functions
 */
export const bankIDJWTServiceSagas = {
    *request() {
        try {
            const res: any = yield* call(api.get, 'bankid/jwt', {
                baseURL: NODE_API_BASE_URL,
                headers: APPLICATION_JSON_HEADER,
            });

            if (res.status === HTTP_STATUS.NO_CONTENT) {
                yield put(
                    bankIDJWTServiceActions.failure({
                        name: 'No Content',
                        message: FormFieldErrors.NO_JWT_RECEIVED,
                        status: res.stats,
                        statusText: res.statusText,
                    })
                );
            } else {
                const responseData = res.data.data;
                yield put(bankIDJWTServiceActions.success(responseData));
            }
        } catch (e) {
            yield put(bankIDJWTServiceActions.failure(e as any));
        }
    },

    *submissionId({ resolve = emptyFn, reject = emptyFn }: BankIDJWTServiceAction) {
        const bankIdService = yield* select(selectBankIDService);
        const jwt = yield* select(selectJWT);
        try {
            const res: any = yield* call(
                api.post,
                'bankid/auth/submission',
                { jwt },
                {
                    baseURL: NODE_API_BASE_URL,
                    headers: APPLICATION_JSON_HEADER,
                }
            );
            if (res.status === HTTP_STATUS.NO_CONTENT) {
                yield put(commonActions.authPost(undefined, resolve, reject));
            } else {
                yield put(bankIDJWTServiceActions.success({ ...bankIdService, submissionId: res.data.uuid }));
                let submissionID = res.data.uuid;
                submissionID = submissionID.substring(1, submissionID.length - 1);
                yield put(commonActions.update({ id: submissionID }));
            }
            resolve();
        } catch (e) {
            reject(e);
            yield put(
                bankIDJWTServiceActions.error({ type: BankIDJWTServiceActionTypes.SUBMISSION_ID, error: e as any })
            );
            yield put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
        }
    },

    *fetch({ resolve = emptyFn, reject = emptyFn }: BankIDJWTServiceAction) {
        const bankIdService = yield* select(selectBankIDService);
        const jwt = yield* select(selectJWT);
        let submissionID = bankIdService.submissionId;
        if (submissionID) {
            submissionID = submissionID.substring(1, submissionID.length - 1);
        }

        try {
            const res: any = yield* call(
                api.post,
                `bankid/auth/fetch/${submissionID}`,
                { jwt },
                {
                    baseURL: NODE_API_BASE_URL,
                    headers: APPLICATION_JSON_HEADER,
                }
            );
            if (res.status === HTTP_STATUS.NO_CONTENT) {
                yield* put(
                    bankIDJWTServiceActions.failure({
                        name: 'No Content',
                        message: FormFieldErrors.NO_STORED_CLAIM,
                        status: res.stats,
                        statusText: res.statusText,
                    })
                );
            } else {
                yield* put(
                    bankIDJWTServiceActions.success({
                        ...bankIdService,
                        hasExistingClaim: true,
                        storedClaim: {
                            ...res.data,
                        },
                    })
                );
            }
            resolve();
        } catch (e) {
            reject(e);
            yield* put(bankIDJWTServiceActions.error({ type: BankIDJWTServiceActionTypes.FETCH, error: e as any }));
            yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
        }
    },
};
