/**
 *  Maksimum lengde for konti i PlusGiro er 8 karakterer (f.eks. 4959102-7). Det skal alltid finnes kun ett siffer etter bindestrek
 */

export function validateAccountNumberPGSE(accountNumber: string) {
    return {
        isValid: function isValid() {
            return /^([0-9]{1,7}-[0-9])$/.test(accountNumber);
        },
    };
}
