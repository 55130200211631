import { css } from '@emotion/react';
import { primary } from '../../variables';

export const summaryBtnCss = css({
    border: `1px solid ${primary}`,
    borderRadius: 2,
    display: 'inline',
    fontSize: '1.125rem',
    lineHeight: '1.25rem',
    minWidth: 120,
    padding: 10,
    width: 'fit-content',
    wordBreak: 'break-word',

    '&.summary': {
        display: 'flex',
    },

    '&:hover, &:focus': {
        borderColor: 'rgba(125, 136, 144, 0.1)',
        backgroundColor: 'rgba(125, 136, 144, 0.1)',
        color: primary,
        outline: 0,
    },
});
