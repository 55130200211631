/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactElement, SyntheticEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { muiAutocompleteStyle } from './MuiAutocomplete.style';
import { FormChangeable, Nullable } from '../../../../interfaces';
import { customCANProps } from '../../../../config/customCANCompanies';

/**
 * Interfaces
 */
export interface OptionType {
    label: string;
    value: string;
}

export interface MuiAutocompleteProps {
    customCAN?: customCANProps;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: string | ReactElement;
    filterSelectedOptions?: boolean;
    getOptionLabel?: (option: OptionType) => string;
    id?: string;
    inputFieldWrapper?: string;
    isOptionEqualToValue?: (option: OptionType, value: OptionType) => boolean;
    label?: string;
    multiple?: boolean;
    noOptionsText?: string;
    onBlur?: (e: FormChangeable) => void;
    onChange?: (e: SyntheticEvent, option: any) => void;
    onInputChange?: (e: SyntheticEvent, input: string) => void;
    openOnFocus?: boolean;
    options?: Array<OptionType>;
    placeholder?: string;
    value?: Nullable<OptionType | OptionType[]>;
}

/**
 * Component view and component logic
 */
export const MuiAutocomplete = ({
    customCAN,
    disabled,
    error,
    errorMessage,
    filterSelectedOptions,
    getOptionLabel,
    id,
    inputFieldWrapper,
    isOptionEqualToValue,
    label,
    multiple,
    noOptionsText,
    onBlur,
    onChange,
    onInputChange,
    openOnFocus,
    options,
    placeholder,
    value,
}: MuiAutocompleteProps) => {
    return (
        <div
            className={`${inputFieldWrapper}`}
            css={css(muiAutocompleteStyle(customCAN))}
        >
            {options && (
                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            data-testid={id}
                            {...params}
                            helperText={errorMessage}
                            {...{ error, label, placeholder }}
                        />
                    )}
                    {...{
                        disabled,
                        filterSelectedOptions,
                        getOptionLabel,
                        id,
                        isOptionEqualToValue,
                        multiple,
                        noOptionsText,
                        onBlur,
                        onChange,
                        onInputChange,
                        openOnFocus,
                        options,
                        value,
                    }}
                />
            )}
        </div>
    );
};
