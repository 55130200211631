/**
 * Validate bank account number towards mod10.
 *
 * @param {String} number Bank account number
 * @returns {Boolean}
 */
export const mod10 = (number: string): boolean => {
    const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    let len = number.length,
        bit = 1,
        sum = 0;
    while (len) {
        const val = parseInt(number.charAt(--len), 10);
        // eslint-disable-next-line no-cond-assign
        sum += (bit ^= 1) ? arr[val] : val;
    }

    return !!(sum && sum % 10 === 0);
};
