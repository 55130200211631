/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import moment from 'moment';
import { monthYearPickerCss } from './MonthYearPicker.style';

interface MonthYearPickerProps {
    month: moment.Moment;
    onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    onYearSelect: (currentMonth: moment.Moment, newYearVal: string) => void;
    locale?: string;
    allowFutureYears?: boolean;
}

export const MonthYearPicker = ({
    month,
    onMonthSelect,
    onYearSelect,
    locale,
    allowFutureYears,
}: MonthYearPickerProps) => {
    locale && moment.locale(locale);

    const returnYears = () => {
        let currentYear: number = moment().year();

        if (allowFutureYears) {
            currentYear = moment().year() + 5;
        }

        // Returns array of either the last 100 and next 5 years or just the last 100
        return [...new Array(105)].map(function (element, index) {
            return currentYear - index;
        });
    };

    return (
        <div css={css(monthYearPickerCss)}>
            <select
                className={'month-select'}
                aria-label={'Select month'}
                onChange={(e) => onMonthSelect(month, e.target.value)}
                value={month.month()}
            >
                {moment.months().map((label, value) => (
                    <option value={value} key={label}>
                        {label}
                    </option>
                ))}
            </select>
            <select
                className={'year-select'}
                aria-label={'Select year'}
                onChange={(e) => onYearSelect(month, e.target.value)}
                value={month.year()}
            >
                {returnYears().map((value) => (
                    <option value={value} key={value}>
                        {value}
                    </option>
                ))}
            </select>
        </div>
    );
};
