import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { DRIVING_SPEED_LABEL, SPEED_LIMIT_LABEL, SPEED_ON_IMPACT_LABEL } = PhraseKeys;
const { TYPE_ERROR, NO_NEGATIVE_NUMBERS } = FormFieldErrors;

/**
 * Validation logic
 */
export const speedSchema = (t: TFunction) => {
    return {
        speedLimit: Yup.number()
            .label(t(SPEED_LIMIT_LABEL))
            .integer(t(TYPE_ERROR))
            .moreThan(-1, t(NO_NEGATIVE_NUMBERS))
            .typeError(t(TYPE_ERROR))
            .required()
            .nullable(),
        drivingSpeed: Yup.number()
            .label(t(DRIVING_SPEED_LABEL))
            .integer(t(TYPE_ERROR))
            .moreThan(-1, t(NO_NEGATIVE_NUMBERS))
            .typeError(t(TYPE_ERROR))
            .required()
            .nullable(),
        speedOnImpact: Yup.number()
            .label(t(SPEED_ON_IMPACT_LABEL))
            .integer(t(TYPE_ERROR))
            .moreThan(-1, t(NO_NEGATIVE_NUMBERS))
            .typeError(t(TYPE_ERROR))
            .required()
            .nullable(),
    };
};
