import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { DENTIST_NAME_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const dentistSchema = (t: TFunction) => {
    return {
        name: Yup.string().label(t(DENTIST_NAME_LABEL)).required().noSpecialChars().nullable(),
    };
};
