import { is } from '../../common/utils/is';
import { InsuredByProtectorKeys } from '../interfaces/models/InsuredByProtector.model';

const { YES_RECENTLY_INSURED_BY_PROTECTOR, NO } = InsuredByProtectorKeys;

export const calcInsuredByProtectorFromApiResponse = (
    insuredByProtector: string
) => {
    return is(NO.toLocaleUpperCase(), insuredByProtector.toLocaleUpperCase())
        ? null
        : YES_RECENTLY_INSURED_BY_PROTECTOR;
};
