export enum OdCauseEnums {
    AFLATOXINS = 'AFLATOXINS',
    ALDEHYDS = 'ALDEHYDS',
    ALIFATIC = 'ALIFATIC',
    ALUMINIUM = 'ALUMINIUM',
    ANTIBIOTICS = 'ANTIBIOTICS',
    ANTIMONE = 'ANTIMONE',
    ARSENIC = 'ARSENIC',
    BACTERIA = 'BACTERIA',
    BERYLLIUM = 'BERYLLIUM',
    CADMIUM = 'CADMIUM',
    CANCER_MEDICINE = 'CANCER_MEDICINE',
    CARBON_HYDROGENS = 'CARBON_HYDROGENS',
    CARBON_MONOXIDE = 'CARBON_MONOXIDE',
    CHROMIUM = 'CHROMIUM',
    COBALT = 'COBALT',
    COMPRESSION = 'COMPRESSION',
    CYAN = 'CYAN',
    FENOL = 'FENOL',
    HALOGENS = 'HALOGENS',
    HYDROGEN_PEROXIDE = 'HYDROGEN_PEROXIDE',
    LEAD = 'LEAD',
    MANGANE = 'MANGANE',
    NICKEL = 'NICKEL',
    NITROGEN = 'NITROGEN',
    NITROGEN_ACIDS = 'NITROGEN_ACIDS',
    NOISE = 'NOISE',
    ORGANIC_ACIDS = 'ORGANIC_ACIDS',
    ORGANIC_DUST = 'ORGANIC_DUST',
    OTHER_BIOLOGICAL_FACTOR = 'OTHER_BIOLOGICAL_FACTOR',
    OTHER_CHEMICAL_FACTOR = 'OTHER_CHEMICAL_FACTOR',
    OTHER_PHYSICAL_FACTOR = 'OTHER_PHYSICAL_FACTOR',
    PHOSPHORUS = 'PHOSPHORUS',
    PLASTIC = 'PLASTIC',
    PRESSURE = 'PRESSURE',
    QUICKSILVER = 'QUICKSILVER',
    RADIATION = 'RADIATION',
    REPEATED_ABNORMAL_THERMAL_LOAD = 'REPEATED_ABNORMAL_THERMAL_LOAD',
    SULFUR = 'SULFUR',
    TIN = 'TIN',
    TIURAMS = 'TIURAMS',
    TUBERCULOSIS = 'TUBERCULOSIS',
    UN_ORGANIC_BASES = 'UN_ORGANIC_BASES',
    UN_ORGANIC_DUST = 'UN_ORGANIC_DUST',
    VANADIUM = 'VANADIUM',
    VIBRATION = 'VIBRATION',
    VIRUS = 'VIRUS',
    ZINC = 'ZINC',
}
