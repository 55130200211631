import { css } from '@emotion/react';
import { secondary, black, white } from '../../../variables';

export const monthYearPickerCss = css({
    '& .month-select, .year-select': {
        appearance: 'none',

        border: `1px solid ${secondary}`,
        borderRadius: '2px',
        color: secondary,
        fontSize: '1.125rem',
        left: '-4px',
        margin: '2px',
        padding: '4px 20px 4px 4px',
        position: 'relative',
        textTransform: 'capitalize',
        top: '-11px',

        '&:hover': {
            cursor: 'pointer',
        },

        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.25)',
            outline: 0,
        },

        '& option': {
            color: black,
            fontSize: '1rem',
        },
    },

    '& .month-select': {
        background: `${white} url('/assets/images/icons/polygon.svg') no-repeat 96%`,
    },

    '& .year-select': {
        background: `${white} url('/assets/images/icons/polygon.svg') no-repeat 93%`,
    },

    '& select::-ms-expand': {
        display: 'none',
    },
});
