import { createFileError } from '../../../models/File';
import {
    actionWithPromise,
    emptyFn,
    FileModel,
    FileStatusEnums,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { delay, takeEvery } from 'redux-saga/effects';
import { api } from '../../../utils/api';
import {
    CANRequestError,
    createCANRequestError,
    createCANRequestErrorFromError,
    HTTPResponseStatus,
    PollErrors,
} from '../../../models/CANRequestError';
import { safetyStandardsActions } from './safetyStandards';
import { selectLoggedInClaimReport } from '../../selectors/reportSelectors';
import { selectJWT, selectRequestId } from '../../selectors/commonSelectors';
import { call, put, select } from 'typed-redux-saga';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum PollSafetyStandardsActionTypes {
    REQUEST = '@app/upload/safetyStandards/poll/REQUEST',
    SUCCESS = '@app/upload/safetyStandards/poll/SUCCESS',
    FAILURE = '@app/upload/safetyStandards/poll/FAILURE',
}

/**
 * Action Definitions
 */
export interface PollSafetyStandardsAction {
    type: PollSafetyStandardsActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const pollSafetyStandardsActions = {
    request: actionWithPromise(PollSafetyStandardsActionTypes.REQUEST),
    success: actionWithPromise(PollSafetyStandardsActionTypes.SUCCESS),
    failure: actionWithPromise(PollSafetyStandardsActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const pollSafetyStandardsSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: PollSafetyStandardsAction) {
        let i = 0;
        const maxIterations = 20;
        const requestId = yield* select(selectRequestId);
        const loggedIn = yield* select(selectLoggedInClaimReport);
        const jwt = yield* select(selectJWT);
        for (i; i < maxIterations; i++) {
            yield delay(1000);
            try {
                if (data.externalId) {
                    let res: any;
                    if (loggedIn) {
                        res = yield* call(
                            api.post,
                            `upload/${data.externalId}/meta`,
                            { jwt },
                            {
                                baseURL: NODE_API_BASE_URL,
                                headers: { 'X-Request-Id': `${requestId}` },
                            }
                        );
                    } else {
                        res = yield* call(api.get, `upload/${data.externalId}/meta`, {
                            baseURL: NODE_API_BASE_URL,
                            headers: { 'X-Request-Id': `${requestId}` },
                        });
                    }
                    const status: string = res.data.data.scanResult.status;

                    if (status === FileStatusEnums.PASSED) {
                        yield* put(safetyStandardsActions.update({ ...data, status }));
                        yield* put(pollSafetyStandardsActions.success());
                        resolve();
                        break;
                    } else if (status === FileStatusEnums.FAILED || status === FileStatusEnums.ERROR) {
                        yield* put(safetyStandardsActions.update({ ...data, status }));
                        yield* put(pollSafetyStandardsActions.failure());
                        reject();
                        break;
                    }
                } else {
                    const error = createCANRequestError(
                        HTTPResponseStatus.BAD_REQUEST.code,
                        PollErrors.NO_EXTERNAL_ID,
                        ':externalId/meta'
                    );
                    const file = createFileError(data, PollSafetyStandardsActionTypes.FAILURE, error);
                    yield* put(pollSafetyStandardsActions.failure(file));
                    reject();
                    break;
                }
            } catch (e) {
                if ((e as any).response) {
                    yield* put(pollSafetyStandardsActions.failure((e as any).response.data));
                } else if ((e as any).status && (e as any).statusText && (e as any).url) {
                    const error: CANRequestError = createCANRequestErrorFromError(e as any);
                    yield* put(pollSafetyStandardsActions.failure(error));
                }
                reject();
            }

            yield delay(1000);
        }

        // Handle if file has not passed after max iterations
        if (i === maxIterations) {
            const error: CANRequestError = createCANRequestError(
                HTTPResponseStatus.INTERNAL_SERVER_ERROR.code,
                PollErrors.MAX_ITERATIONS,
                `${data?.externalId}/meta`
            );
            const file = createFileError(data, PollSafetyStandardsActionTypes.FAILURE, error);
            yield* put(safetyStandardsActions.update(file));
            yield* put(pollSafetyStandardsActions.failure(error));
        }
    },
};

/**
 * Saga watchers
 */
export const pollSafetyStandardsWatchers = function* () {
    yield takeEvery(PollSafetyStandardsActionTypes.REQUEST, pollSafetyStandardsSagas.request);
};
