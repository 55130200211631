import { Dispatch } from 'redux';
import { AppActions } from '../store/rootReducers';
import { Rejectable, Resolvable } from '@protectorinsurance/ds-can';

export default <D = any>(
    dispatch: Dispatch,
    action: (data?: D, resolve?: Resolvable, reject?: Rejectable) => AppActions,
    data?: D
) => {
    return new Promise((resolve, reject) => dispatch(action(data, resolve, reject)));
};
