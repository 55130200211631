import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CanvasDraw from 'react-canvas-draw';
import { WizardRouterAction, wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    AccidentDescriptionModel,
    CircleGarbageIcon,
    CircleIconButton,
    CircleUndoIcon,
    Clickable,
    FileUploader,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    InputLabel,
    is,
    isYes,
    MotorClaimCauseKeys,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import {
    selectAccidentDescription,
    selectAccidentSketch,
    selectClaimCause,
    selectIsOtherVehicleInvolved,
} from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../sagas/motor';
import './AccidentDescription.scss';
import { accidentDescriptionSchema } from '../../../validations/schemas/accidentDescriptionSchema';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import FileModel from '../../../models/File';
import { selectDescriptionFileFiles } from '../../../sagas/selectors/uploadSelectors';
import { uploadDescriptionFileActions } from '../../../sagas/upload/descriptionFile/uploadDescriptionFile';
import { deleteDescriptionFileActions } from '../../../sagas/upload/descriptionFile/deleteDescriptionFile';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ACCIDENT_DESCRIPTION_LABEL,
    ACCIDENT_DESCRIPTION_PLACEHOLDER,
    ACCIDENT_SKETCH_LABEL,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DROPZONE_DESCRIPTION,
    DROPZONE_TITLE,
    HELP_TEXT,
    PAGE_NAME,
    RESET_BUTTON,
    SUB_TITLE,
    TITLE,
    UNDO_BUTTON,
} = PhraseKeys;
const { ACCIDENT_DESCRIPTION, ACCIDENT_SKETCH } = FormFieldNames;
const { END_DAMAGE_OVERVIEW } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const AccidentDescriptionPage = () => {
    const canvasRef = useRef<CanvasDraw>(null);
    const dispatch = useDispatch();
    const accidentDescription = useSelector(selectAccidentDescription);
    const accidentSketch = useSelector(selectAccidentSketch);
    const isOtherVehicleInvolved = useSelector(selectIsOtherVehicleInvolved);
    const claimCause = useSelector(selectClaimCause);
    const fileStore = useSelector(selectDescriptionFileFiles);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.accidentDescription');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<AccidentDescriptionModel>({
        resolver: yupResolver(accidentDescriptionSchema(t, ACCIDENT_DESCRIPTION_LABEL)),
        defaultValues: { accidentDescription },
    });

    useEffect(() => {
        if (canvasRef !== null && accidentSketch?.data !== null) {
            canvasRef?.current?.loadSaveData(accidentSketch?.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleOnSuccess = (files: FileModel[]) => {
        files.forEach((file) => dispatch(uploadDescriptionFileActions.request(file)));
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleDelete = (e: Clickable, file: FileModel) => {
        e.preventDefault();
        dispatch(deleteDescriptionFileActions.request(file));
    };

    const onSubmit = ({ accidentDescription }: AccidentDescriptionModel, e?: FormChangeable) => {
        e?.preventDefault();
        let nextAction: WizardRouterAction<string | undefined | null> = wizardActions.goTo(END_DAMAGE_OVERVIEW);
        if (is(claimCause, MotorClaimCauseKeys.ENGINE)) {
            nextAction = wizardActions.goTo(MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING);
        }

        if (isYes(isOtherVehicleInvolved) || is(claimCause, MotorClaimCauseKeys.CYCLIST_COLLISION)) {
            nextAction = wizardActions.goToNext();
        }

        dispatcherWithPromise(dispatch, motorActions.update, { accidentDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    const onCanvasChange = (canvas: any) => {
        const data = canvas?.getSaveData();
        const blob = JSON.parse(data)?.lines.length === 0 ? '' : canvas?.canvasContainer?.children[1]?.toDataURL();

        dispatch(motorActions.update({ accidentSketch: { data, blob } }));
    };

    const checkFileErrors: boolean = fileStore.some((file) => {
        return file.errors.length > 0;
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={checkFileErrors}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <Grid>
                <div className={'col-12'}>
                    <FileUploader
                        onSuccess={handleOnSuccess}
                        onDelete={handleDelete}
                        text={tWithNS.t('upload.label')}
                        title={tWithNS.t(DROPZONE_TITLE)}
                        label={tWithNS.t(DROPZONE_DESCRIPTION)}
                        fileStore={fileStore}
                    />
                    <div className={'canvas'}>
                        <InputLabel label={t(ACCIDENT_SKETCH_LABEL)} name={ACCIDENT_SKETCH} />
                        <CanvasDraw
                            brushRadius={2}
                            className={'canvas--area'}
                            lazyRadius={2}
                            onChange={onCanvasChange}
                            ref={canvasRef}
                        />

                        <Grid>
                            <div className={'col-12'}>
                                <div className={'canvas--controls mt-5'}>
                                    <CircleIconButton
                                        ariaLabel={t(RESET_BUTTON)}
                                        dataTestId={'btn-reset'}
                                        handleClick={() => canvasRef?.current?.clear()}
                                        icon={<CircleGarbageIcon />}
                                        label={t(RESET_BUTTON)}
                                    />

                                    <CircleIconButton
                                        ariaLabel={t(UNDO_BUTTON)}
                                        dataTestId={'btn-undo'}
                                        handleClick={() => canvasRef?.current?.undo()}
                                        icon={<CircleUndoIcon />}
                                        label={t(UNDO_BUTTON)}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <HiddenInputSubmit />
                        <Grid className={'align-center'}>
                            <Controller
                                control={control}
                                name={ACCIDENT_DESCRIPTION}
                                render={({ field: { ref, ...field } }) => (
                                    <MuiTextInput
                                        {...field}
                                        error={!!errors.accidentDescription}
                                        errorMessage={errors.accidentDescription?.message}
                                        id={ACCIDENT_DESCRIPTION}
                                        inputFieldWrapper={'col-12'}
                                        label={t(ACCIDENT_DESCRIPTION_LABEL)}
                                        multiline={true}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t(ACCIDENT_DESCRIPTION_PLACEHOLDER)}
                                        reference={ref}
                                        rows={10}
                                        {...{ customCAN }}
                                    />
                                )}
                            />
                        </Grid>
                    </form>
                </div>
            </Grid>
        </PageLayout>
    );
};
