import React from 'react';
import { PersonSummaryProps } from '../../../types/SummaryProps';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { createTextYesNoDontKnow, trueFalseToYesNo } from 'utils/displayUtils';
import {
    ClaimantAgeCategoryKeys,
    FileList,
    is,
    isGreater,
    isNot,
    isYes,
    SummaryItem,
} from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { dtTimeParseAndFormat } from '../../../utils/date/dateUtils';
import { PhraseKeys } from '../../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { ADULT, CHILD } = ClaimantAgeCategoryKeys;
const {
    DEATH_DATE_OF_DEATH,
    DEATH_PRIVACY,
    DEATH_UPLOAD_DEATH_CERTIFICATE,
    DYN_CAROUSEL_DEATH_CLAIM_LOCATION,
    DYN_CAROUSEL_DEATH_CLAIM_REPORTER_ROLE,
    DYN_CAROUSEL_DEATH_CLAIMANT_AGE_CATEGORY,
    DYN_CAROUSEL_DEATH_CLAIMANT_MARITAL_STATUS,
    DYN_CAROUSEL_DEATH_INSURANCE_TYPE,
    DYN_CAROUSEL_DEATH_IS_ACCIDENT,
    DYN_CAROUSEL_DEATH_IS_VEHICLE_INVOLVED,
    INDEX,
} = PersonRoutePaths;
const { SELECT_NO, SELECT_YES } = PhraseKeys;

/**
 * Component view and component logic
 */
export const DisplayDeathSituationSummary = ({ model, t, handleClick, upload, error }: PersonSummaryProps) => {
    /**
     * Helpers
     */
    const {
        category,
        claimantAgeCategory,
        claimReporterRole,
        insuranceType,
        acceptedPrivacy,
        isAccident,
        claimLocation,
        isVehicleInvolved,
        registrationNumber,
        claimDate,
        claimantMaritalStatus,
    } = model;

    const { hasAttachment, files } = upload.certificate;

    const renderCategory = () => {
        if (category) {
            return (
                <SummaryItem
                    textKey={t('lob.person.death.summary.category.heading.title')}
                    dataTestId={TEST_ID_PREFIX.category}
                    buttonText={t(`lob.person.category.select.${category}`)}
                    path={INDEX}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.type')}</p>;
    };

    const renderClaimReporterRole = () => {
        if (claimReporterRole) {
            return (
                <SummaryItem
                    textKey={t('lob.person.death.summary.whoReport.heading.title')}
                    dataTestId={TEST_ID_PREFIX.whoReport}
                    buttonText={t(`lob.person.death.whoReport.select.${claimReporterRole}`)}
                    path={DYN_CAROUSEL_DEATH_CLAIM_REPORTER_ROLE}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.claimReporter')}</p>;
    };

    const renderInsuranceType = () => {
        if (insuranceType) {
            return (
                <SummaryItem
                    textKey={t('lob.person.death.summary.insuranceType.heading.title')}
                    dataTestId={TEST_ID_PREFIX.insuranceType}
                    buttonText={t(`lob.person.death.insuranceType.select.${insuranceType}`)}
                    path={DYN_CAROUSEL_DEATH_INSURANCE_TYPE}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.insuranceType')}</p>;
    };

    /**
     * Renders
     */
    const renderReport = () => {
        return (
            <>
                {/* Category */}
                <div
                    className={`col-4 divider ${
                        isNot(claimantAgeCategory, CHILD) && 'no-border'
                    } align-button-left text-align-left`}
                >
                    {renderCategory()}
                </div>

                {/* Claimant Age Category */}
                <div
                    className={`col-4 divider ${
                        isNot(claimantAgeCategory, CHILD) && 'no-border'
                    } align-button-left text-align-left`}
                >
                    <SummaryItem
                        textKey={t('lob.person.death.summary.claimantAgeCategory.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimantAgeCategory}
                        buttonText={t(`lob.person.claimantAgeCategory.death.select.${claimantAgeCategory}`)}
                        path={DYN_CAROUSEL_DEATH_CLAIMANT_AGE_CATEGORY}
                        {...{ handleClick }}
                    />
                </div>

                {/* Who Report */}
                <div
                    className={`col-4 divider ${
                        isNot(claimantAgeCategory, CHILD) && 'no-border'
                    } align-button-left text-align-left`}
                >
                    {renderClaimReporterRole()}
                </div>

                {/* Insurance Type */}
                {isNot(claimantAgeCategory, CHILD) && (
                    <div className={'col-12 divider align-button-left text-align-left'}>{renderInsuranceType()}</div>
                )}
            </>
        );
    };

    const renderPrivacy = () => {
        if (acceptedPrivacy) {
            return (
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.death.summary.privacy.heading.title')}
                    dataTestId={TEST_ID_PREFIX.acceptedPrivacy}
                    buttonText={t(trueFalseToYesNo(acceptedPrivacy))}
                    path={DEATH_PRIVACY}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return (
            <div className={'col-12 divider align-button-start text-align-left'}>
                <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.privacy')}</p>
            </div>
        );
    };

    const renderAccidentSummary = () => {
        return (
            <>
                {/* Is Accident */}
                <div className={`${isYes(isAccident) ? 'col-4' : 'col-12'} divider align-button-left text-align-left`}>
                    <SummaryItem
                        textKey={t('lob.person.death.summary.isAccident.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isAccident}
                        buttonText={t(createTextYesNoDontKnow(isAccident))}
                        path={DYN_CAROUSEL_DEATH_IS_ACCIDENT}
                        {...{ handleClick }}
                    />
                </div>

                {/* Claim Location */}
                {isYes(isAccident) && (
                    <div className={'col-8 divider align-button-left text-align-left'}>
                        <SummaryItem
                            textKey={t('lob.person.death.summary.claimLocation.heading.title')}
                            dataTestId={TEST_ID_PREFIX.location}
                            buttonText={t(`lob.person.death.claimLocation.select.${claimLocation}`)}
                            path={DYN_CAROUSEL_DEATH_CLAIM_LOCATION}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderVehicleSummary = () => {
        return (
            <>
                {' '}
                {/* Is Vehicle Involved */}
                <div
                    className={`${
                        isYes(isVehicleInvolved) ? 'col-4' : 'col-12'
                    } divider align-button-left text-align-left`}
                >
                    <SummaryItem
                        textKey={t('lob.person.death.summary.isVehicleInvolved.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isVehicleInvolved}
                        buttonText={t(createTextYesNoDontKnow(isVehicleInvolved))}
                        path={DYN_CAROUSEL_DEATH_IS_VEHICLE_INVOLVED}
                        {...{ handleClick }}
                    />
                </div>
                {/* Registration Number */}
                {isYes(isVehicleInvolved) && (
                    <div className={'col-8 divider align-button-left text-align-left'}>
                        <SummaryItem
                            textKey={t('lob.person.death.summary.vehicleInformation.heading.title')}
                            dataTestId={TEST_ID_PREFIX.vehicleInformation}
                            buttonText={registrationNumber ? registrationNumber : '-'}
                            path={DYN_CAROUSEL_DEATH_IS_VEHICLE_INVOLVED}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderDeathSummary = () => {
        return (
            <>
                {/* Date Of Death */}
                <div
                    className={`${
                        is(claimantAgeCategory, ADULT) ? 'col-4' : 'col-12'
                    } divider align-button-left text-align-left`}
                >
                    <SummaryItem
                        textKey={t('lob.person.death.summary.dateOfDeath.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimDate}
                        buttonText={claimDate ? dtTimeParseAndFormat(claimDate) : ''}
                        path={DEATH_DATE_OF_DEATH}
                        {...{ handleClick }}
                    />
                </div>

                {/* Relationship Status */}
                {is(claimantAgeCategory, ADULT) && (
                    <div className={'col-8 divider align-button-left text-align-left'}>
                        <SummaryItem
                            textKey={t('lob.person.death.summary.claimantMaritalStatus.heading.title')}
                            dataTestId={TEST_ID_PREFIX.claimantMaritalStatus}
                            buttonText={t(`lob.person.claimantMaritalStatus.death.select.${claimantMaritalStatus}`)}
                            path={DYN_CAROUSEL_DEATH_CLAIMANT_MARITAL_STATUS}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderAttachments = () => {
        return (
            <>
                {/* Has Attachments */}
                <div
                    className={`${
                        isGreater(files.length, 0) ? 'col-4' : 'col-12'
                    } item-content-top divider align-button-left text-align-left`}
                >
                    <SummaryItem
                        textKey={t('lob.person.death.summary.certificate.text.hasAttachments')}
                        buttonText={files.length > 0 ? `${t(SELECT_YES)}` : `${t(SELECT_NO)}`}
                        dataTestId={`${TEST_ID_PREFIX.hasAttachments}${hasAttachment}`}
                        path={DEATH_UPLOAD_DEATH_CERTIFICATE}
                        {...{ handleClick }}
                    />
                </div>

                {/* Certificate attachments */}
                {isGreater(files.length, 0) && (
                    <div className={'col-8 divider align-button-left text-align-left'}>
                        <FileList
                            list={files}
                            path={DEATH_UPLOAD_DEATH_CERTIFICATE}
                            show={true}
                            dataTestId={TEST_ID_PREFIX.uploadCertificate}
                            textKey={t('lob.person.death.summary.certificate.heading.title')}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {renderReport()}

            {renderPrivacy()}

            {renderAccidentSummary()}

            {renderVehicleSummary()}

            {renderDeathSummary()}

            {renderAttachments()}
        </>
    );
};
