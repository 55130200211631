import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { roadSchema } from '../fieldSchemas/roadSchema';

/**
 * Validation logic
 */
export const roadInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...roadSchema(t),
    });
};
