import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAcceptedPoliceContact, selectLpoState } from '../../../sagas/selectors/lpoSelectors';
import { selectUpload } from '../../../sagas/selectors/uploadSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { SWE_DATE_TIME_FORMAT } from '../../../config/locale';
import moment from 'moment';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    Grid,
    HistoryListener,
    InputValidationError,
    is,
    LobKeys,
    PageLayout,
    pdfServiceActions,
    PDFServiceActionTypes,
    Printable,
} from '@protectorinsurance/ds-can';
import { DisplayStartSummary } from './DisplayStartSummary';
import {
    selectApiId,
    selectCommonState,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
    selectRequestId,
    selectSubmitted,
} from '../../../sagas/selectors/commonSelectors';
import { DisplayPropertySummary } from './DisplayPropertySummary';
import { DisplayEndSummary } from './DisplayEndSummary';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { DisplayLiabilitySummary } from './DisplayLiabilitySummary';
import { DisplayLegalSummary } from './DisplayLegalSummary';
import { DisplayChangeOfOwnershipSummary } from './DisplayChangeOfOwnershipSummary';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { useHistoryListen } from '../../../hooks/useHistoryListen';
import { DisplayContactPolice } from './components/DisplayContactPolice';
import useErrorLoader from '../../../hooks/useErrorSelector';
import { logServiceActions } from 'sagas/services/logService';
import { useHistory } from 'react-router-dom';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, HELP_TEXT, PAGE_NAME, SEND_BUTTON, SUB_TITLE, SUBMITTING_BUTTON, TITLE } = PhraseKeys;
const { CHANGE_OF_OWNERSHIP_SWE, LEGAL_EXPENCES, LIABILITY, PROPERTY } = LobKeys;
const { END_FINAL_SUMMARY, END_REPORT_COMPLETED } = LpoRoutePaths;
const reportDate = moment().format(SWE_DATE_TIME_FORMAT);

/**
 * Page view and page logic
 */
interface PrintableSummaryProps {
    showSummary: boolean;
}

export const PrintableSummary = ({ showSummary = true }: PrintableSummaryProps) => {
    const dispatch = useDispatch();
    const uuid = useSelector(selectApiId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const lob = useSelector(selectLob);
    const requestId = useSelector(selectRequestId);
    const lpoState = useSelector(selectLpoState);
    const commonState = useSelector(selectCommonState);
    const uploadState = useSelector(selectUpload);
    const hasAcceptedPoliceContact = useSelector(selectAcceptedPoliceContact);
    const { error: pdfError } = useErrorLoader(PDFServiceActionTypes.FAILURE);
    const history = useHistory();
    const { listen, unlisten } = useHistoryListen();
    const pathNextRoute = END_REPORT_COMPLETED;
    const pathFinalSummary = END_FINAL_SUMMARY;
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.end.finalSummary');
    const submitted = useSelector(selectSubmitted);

    const model = { ...lpoState };
    const common = { ...commonState };
    const upload = { ...uploadState };

    const listener: HistoryListener = useCallback(
        (hLocation, hAction) => {
            if (hAction === 'POP' && hLocation.pathname === pathNextRoute && !hasAcceptedPoliceContact) {
                history.push(pathFinalSummary);
            }
        },
        [history, hasAcceptedPoliceContact, pathNextRoute, pathFinalSummary]
    );

    useEffect(() => {
        hasAcceptedPoliceContact ? unlisten() : listen(listener);
    }, [hasAcceptedPoliceContact, listen, unlisten, listener]);

    const handleBackButton = useGoBack();

    const handleContinueButton = () => {
        dispatch(
            logServiceActions.request({
                level: 'info',
                message: `User clicked Continue button to send claim. UUID=${uuid}`,
            })
        );
        dispatch(commonActions.update({ submitted: true }));
        const data = { uuid, requestId, lob, locale: { country, language }, model };
        if ((is(lob, PROPERTY) && hasAcceptedPoliceContact) || !is(lob, PROPERTY)) {
            dispatcherWithPromise(dispatch, commonActions.send)
                .then(() => dispatcherWithPromise(dispatch, pdfServiceActions.request, data))
                .then(() => dispatch(commonActions.submit()))
                .catch(() => dispatch(commonActions.update({ submitted: false })));
        }
    };

    return (
        <PageLayout
            backBtnAriaLabel={t(BACK_BUTTON)}
            backBtnText={t(BACK_BUTTON)}
            continueBtnAriaLabel={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            continueBtnText={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            disableBackButton={submitted}
            disableContinueButton={(is(lob, PROPERTY) && !hasAcceptedPoliceContact) || submitted}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerTitle={tWithNS.t(TITLE)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            pageClassName={!showSummary ? 'hide-content' : ''}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Printable title={tWithNS.t('print.title', { date: reportDate })}>
                <Grid>
                    <DisplayStartSummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />

                    {is(lob, PROPERTY) && (
                        <DisplayPropertySummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />
                    )}

                    {is(lob, LIABILITY) && (
                        <DisplayLiabilitySummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />
                    )}

                    {is(lob, LEGAL_EXPENCES) && (
                        <DisplayLegalSummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />
                    )}

                    {is(lob, CHANGE_OF_OWNERSHIP_SWE) && (
                        <DisplayChangeOfOwnershipSummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />
                    )}

                    {(is(lob, PROPERTY) || is(lob, LIABILITY)) && (
                        <DisplayEndSummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />
                    )}

                    {is(lob, PROPERTY) && (
                        <DisplayContactPolice
                            printSummary={showSummary}
                            tWithNS={tWithNS.t}
                            {...{ model, upload, t, common }}
                        />
                    )}

                    {pdfError ? (
                        <div className={'col-12'}>
                            <InputValidationError
                                fieldName={'PdfError'}
                                error={t('generatePDF.' + pdfError.status + 'Error')}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </Grid>
            </Printable>
        </PageLayout>
    );
};
