import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isDev } from '../../utils/env';
import { businessNumberSwedenValidator, isEmpty } from '@protectorinsurance/ds-can';

export default function businessNumber(
    this: StringSchema,
    required: boolean,
    key: string,
    message: string = yupCustomLocale.string.businessNumber
) {
    // @ts-ignore
    return this.test('businessNumber', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        const skipValidation = isDev() || (!required && isEmpty(value));
        return skipValidation ? true : businessNumberSwedenValidator(value).isValid();
    });
}
