import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';
import { FormFieldErrors, FormFieldNames } from '../../config/formFieldNames';

/**
 * Destructure necessary imports
 */
const {
    CHASSIS_NUMBER_LABEL,
    TYPE_LABEL,
    VEHICLE_TYPE_LABEL,
    VEHICLE_INTERNAL_REFERENCE_NUMBER_LABEL,
    MODEL_LABEL,
} = PhraseKeys;
const { CHASSIS_NUMBER, MAKE, TYPE, VEHICLE_TYPE, VEHICLE_INTERNAL_REFERENCE_NUMBER, MODEL } = FormFieldNames;
const { NUMBER_OF_DIGITS_ERROR, MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation logic
 */
export const vehicleSchema = (t: TFunction) => {
    return {
        type: Yup.string()
            .label(t(TYPE_LABEL))
            .noSpecialChars()
            .requiredIf([MAKE, VEHICLE_TYPE, MODEL, VEHICLE_INTERNAL_REFERENCE_NUMBER, CHASSIS_NUMBER])
            .nullable(),
        vehicleType: Yup.string().label(t(VEHICLE_TYPE_LABEL)).ensure().required().nullable(),
        model: Yup.string()
            .label(t(MODEL_LABEL))
            .digits(4, t(NUMBER_OF_DIGITS_ERROR, { path: t(MODEL_LABEL), count: 4 }))
            .requiredIf([MAKE, TYPE, VEHICLE_TYPE, VEHICLE_INTERNAL_REFERENCE_NUMBER, CHASSIS_NUMBER])
            .nullable(),
        vehicleInternalReferenceNumber: Yup.string()
            .label(t(VEHICLE_INTERNAL_REFERENCE_NUMBER_LABEL))
            .noSpecialChars()
            .max(
                17,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(VEHICLE_INTERNAL_REFERENCE_NUMBER_LABEL),
                    max: 17,
                })
            )
            .requiredIf([MAKE, TYPE, VEHICLE_TYPE, MODEL, CHASSIS_NUMBER])
            .nullable(),
        chassisNumber: Yup.string()
            .label(t(CHASSIS_NUMBER_LABEL))
            .noSpecialChars()
            .max(
                17,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(CHASSIS_NUMBER_LABEL),
                    max: 17,
                })
            )
            .requiredIf([MAKE, TYPE, VEHICLE_TYPE, MODEL, VEHICLE_INTERNAL_REFERENCE_NUMBER])
            .nullable(),
    };
};
