/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Fragment, ReactNode } from 'react';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';
import { claimVehicleSummaryCss } from './ClaimVehicleSummary.style';
import { Grid } from '../grid/Grid';
import { Nullable } from '../../../interfaces/types/Nullable.types';
import { mapEnum } from '../../../utils/mapEnum';
import {
    NeedVehicleTowingKeys,
    NeedVehicleTowingModel,
    VehicleDamagesKeyValueModel,
    VehicleModel,
} from '../../../modules/can/motor/interfaces/models';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Destructure necessary imports
 */
const { YES, NO } = NeedVehicleTowingKeys;

/**
 * Interfaces
 */
interface ClaimVehicleSummaryProps {
    claimVehicle: Nullable<VehicleModel>;
    customCAN?: customCANProps;
    needVehicleTowing: NeedVehicleTowingModel;
    textKey: string;
    printSummary: boolean;
    handleChange: (e: NeedVehicleTowingModel) => void;
    needTowingTextKey?: string;
    damagesTextKey?: string;
    needVehicleTowingYes?: string;
    needVehicleTowingNo?: string;
    needVehicleTowingAlreadyTowed?: string;
    needVehicleTowingSummary?: string;
    className?: string;
    damages?: VehicleDamagesKeyValueModel[];
    damageDescription?: Nullable<string>;
    damageDescriptionTitle?: string;
    claimVehicleDamages?: ReactNode;
    passengersText?: string;
    isTotalDamage?: boolean;
    isTotalDamageTitle?: string;
    ownedByLtdTitle?: string;
    ownedByLtd?: string;
    leasingTitle?: string;
    leasing?: string;
    VATTitle?: string;
    VAT?: string;
}

/**
 * Component view and component logic
 */
export const ClaimVehicleSummary = ({
    claimVehicle,
    customCAN,
    needVehicleTowing,
    textKey,
    needTowingTextKey,
    damagesTextKey,
    needVehicleTowingYes = 'YES',
    needVehicleTowingNo = 'NO',
    needVehicleTowingAlreadyTowed = 'ALREADY_TOWED',
    needVehicleTowingSummary,
    className,
    printSummary = false,
    handleChange,
    damages,
    damageDescription,
    damageDescriptionTitle,
    claimVehicleDamages,
    passengersText,
    isTotalDamage,
    isTotalDamageTitle,
    ownedByLtdTitle,
    ownedByLtd,
    leasingTitle,
    leasing,
    VATTitle,
    VAT,
}: ClaimVehicleSummaryProps) => {
    return (
        <div className={className} css={css(claimVehicleSummaryCss(customCAN))}>
            <Grid>
                <div className={'col-12'}>
                    <SummaryText text={textKey} />
                </div>
                <div className={'col-2 justify-content-center padding-bottom'}>
                    <div className={'regnr'}>
                        <span tabIndex={0}>
                            {claimVehicle?.registrationNumber
                                ? claimVehicle.registrationNumber.toUpperCase()
                                : '-'}
                        </span>
                    </div>
                </div>
                <div className={'col-10 justify-content-center padding-bottom'}>
                    <p className={'vehicle-information'} tabIndex={0}>
                        {claimVehicle?.model ? claimVehicle.model : ''}{' '}
                        {claimVehicle?.make ? claimVehicle.make : ''}{' '}
                        {claimVehicle?.type ? claimVehicle.type : ''}
                    </p>
                </div>
                {claimVehicle &&
                    claimVehicle.passengers &&
                    (passengersText !== null || passengersText !== '') && (
                        <div className={'col-12'}>
                            <p className={'vehicle-information'}>
                                {passengersText} {claimVehicle.passengers}
                            </p>
                        </div>
                    )}
                {claimVehicle &&
                    claimVehicle.companyVehicle &&
                    (ownedByLtdTitle !== null || ownedByLtdTitle !== '') && (
                        <div className={'col-12'}>
                            <p className={'vehicle-information'}>
                                {ownedByLtdTitle}: {ownedByLtd}
                            </p>
                        </div>
                    )}
                {claimVehicle &&
                    claimVehicle.leasing &&
                    (leasingTitle !== null || leasingTitle !== '') && (
                        <div className={'col-12'}>
                            <p className={'vehicle-information'}>
                                {leasingTitle}: {leasing}
                            </p>
                        </div>
                    )}
                {claimVehicle &&
                    claimVehicle.VAT &&
                    (VATTitle !== null || VAT !== '') && (
                        <div className={'col-12'}>
                            <p className={'vehicle-information'}>
                                {VATTitle}: {VAT}
                            </p>
                        </div>
                    )}
                {needVehicleTowing && (
                    <Fragment>
                        <div
                            className={
                                'col-3 padding-top justify-content-center'
                            }
                        >
                            <SummaryText text={needTowingTextKey} />
                        </div>
                        {printSummary ? (
                            <div
                                className={
                                    'col-9 justify-content-center text-align-left'
                                }
                            >
                                {needVehicleTowingSummary}
                            </div>
                        ) : (
                            <div className={'col-9 justify-content-center'}>
                                <div className={'button-container'}>
                                    {mapEnum(
                                        NeedVehicleTowingKeys,
                                        (key: NeedVehicleTowingModel) => {
                                            return (
                                                <button
                                                    type={'button'}
                                                    onClick={() =>
                                                        handleChange(key)
                                                    }
                                                    className={`need-towing-button ${
                                                        needVehicleTowing ===
                                                            key && 'active'
                                                    }`}
                                                >
                                                    {key === YES
                                                        ? needVehicleTowingYes
                                                        : key === NO
                                                        ? needVehicleTowingNo
                                                        : needVehicleTowingAlreadyTowed}
                                                </button>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
                {damages && damages.length > 0 && (
                    <Fragment>
                        <div className={'col-12 padding-top'}>
                            <SummaryText text={damagesTextKey} />
                        </div>
                        <div
                            className={`${
                                printSummary ? 'col-12' : 'col-6'
                            } damages`}
                        >
                            <Grid>
                                <div
                                    className={`${
                                        printSummary ? 'col-4' : 'col-3'
                                    } no-padding justify-content-center`}
                                >
                                    <ul className={'damages-list'}>
                                        {damages?.map(
                                            (
                                                damage: VehicleDamagesKeyValueModel,
                                                idx: number
                                            ) => {
                                                return (
                                                    <li
                                                        key={idx}
                                                        className={
                                                            'damages-item'
                                                        }
                                                        tabIndex={0}
                                                    >
                                                        <span>
                                                            {damage.value}
                                                        </span>
                                                        <p>{damage.key}</p>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                                <div
                                    className={`${
                                        printSummary ? 'col-8' : 'col-9'
                                    } no-padding justify-content-center damage`}
                                >
                                    {claimVehicleDamages}
                                </div>
                            </Grid>
                        </div>
                        {damageDescription && (
                            <Fragment>
                                <div className={'col-12 padding-top'}>
                                    <SummaryText
                                        text={damageDescriptionTitle}
                                    />
                                </div>
                                <div
                                    className={'col-12 text-align-left'}
                                    tabIndex={0}
                                >
                                    {damageDescription}
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}
                {isTotalDamage && (
                    <Fragment>
                        <div className={'col-12 padding-top'}>
                            <SummaryText text={damagesTextKey} />
                        </div>
                        <div
                            className={`${
                                printSummary ? 'col-12' : 'col-6'
                            } damages`}
                        >
                            <Grid>
                                <div
                                    className={`${
                                        printSummary ? 'col-4' : 'col-3'
                                    } no-padding justify-content-center`}
                                >
                                    {isTotalDamageTitle}
                                </div>
                                <div
                                    className={`${
                                        printSummary ? 'col-8' : 'col-9'
                                    } no-padding justify-content-center damage`}
                                >
                                    {claimVehicleDamages}
                                </div>
                            </Grid>
                        </div>
                    </Fragment>
                )}
            </Grid>
        </div>
    );
};
