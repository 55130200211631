import moment, { Moment } from 'moment';

export const dtParse = (date: Date | Moment | string) => {
    if (!moment.isMoment(date)) {
        return moment(date).utc();
    }

    return date.utc();
};

export const updateDateByHour = (
    date: moment.Moment | null,
    hour: number
): moment.Moment => {
    return date
        ? moment()
              .year(date.year())
              .month(date.month())
              .date(date.date())
              .hours(hour)
              .minutes(date.minutes())
              .utc(true)
        : moment().hours(hour).minutes(0).seconds(0).utc(true);
};

export const updateDateByMinutes = (
    date: moment.Moment | null,
    minutes: number
): moment.Moment => {
    return date
        ? moment()
              .year(date.year())
              .month(date.month())
              .date(date.date())
              .hours(date.hours())
              .minutes(minutes)
              .utc(true)
        : moment().hours(0).minutes(minutes).seconds(0).utc(true);
};
export const updateDateByDate = (
    date: moment.Moment | null,
    newDate: moment.Moment
): moment.Moment => {
    return date
        ? moment()
              .year(newDate.year())
              .month(newDate.month())
              .date(newDate.date())
              .hours(date.hours())
              .minutes(date.minutes())
              .utc(true)
        : moment()
              .year(newDate.year())
              .month(newDate.month())
              .date(newDate.date())
              .hours(0)
              .minutes(0)
              .seconds(0)
              .utc(true);
};
