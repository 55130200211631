import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useGoBack } from '../../../../../hooks/useGoBack';
import { useI18n } from '../../../../../hooks/useI18n';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    RoadModel,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../../config/phraseKeys';
import { FormFieldNames } from '../../../../../config/formFieldNames';
import { selectDistanceFromRoadSide, selectRoadWidth } from '../../../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { roadInformationSchema } from '../../../../../validations/schemas/roadInformationSchema';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { commonActions } from '../../../../../sagas/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DISTANCE_FROM_ROAD_SIDE_LABEL,
    DISTANCE_FROM_ROAD_SIDE_PLACEHOLDER,
    HELP_TEXT,
    PAGE_NAME,
    ROAD_WIDTH_LABEL,
    ROAD_WIDTH_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { DISTANCE_FROM_ROAD_SIDE, ROAD_WIDTH } = FormFieldNames;

/**
 * Page view and page logic
 */
export const DVHeadOnRoadInformationPage = () => {
    const dispatch = useDispatch();
    const roadWidth = useSelector(selectRoadWidth);
    const distanceFromRoadSide = useSelector(selectDistanceFromRoadSide);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.roadInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<RoadModel>({
        resolver: yupResolver(roadInformationSchema(t)),
        defaultValues: {
            roadWidth,
            distanceFromRoadSide,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ roadWidth, distanceFromRoadSide }: RoadModel) => {
        dispatcherWithPromise(dispatch, motorActions.update, { roadWidth, distanceFromRoadSide })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={ROAD_WIDTH}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.roadWidth}
                                errorMessage={errors.roadWidth?.message}
                                id={ROAD_WIDTH}
                                inputFieldWrapper={'col-6'}
                                label={t(ROAD_WIDTH_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ROAD_WIDTH_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={DISTANCE_FROM_ROAD_SIDE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.distanceFromRoadSide}
                                errorMessage={errors.distanceFromRoadSide?.message}
                                id={DISTANCE_FROM_ROAD_SIDE}
                                inputFieldWrapper={'col-6'}
                                label={t(DISTANCE_FROM_ROAD_SIDE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(DISTANCE_FROM_ROAD_SIDE_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
