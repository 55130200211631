import { css } from '@emotion/react';
import { secondary, white } from '../../../variables';
import { customCANProps } from '../../../../config/customCANCompanies';

export const muiAutocompleteStyle = (company?: customCANProps) =>
    css({
        '& label.Mui-focused': {
            color: company ? company.color : secondary,
        },

        '& .MuiOutlinedInput-root': {
            '& .MuiAutocomplete-endAdornment': {
                width: 'unset !important',
            },

            '&.Mui-disabled:hover': {
                '& fieldset': {
                    borderColor: 'rgba(0,0,0,0.26)',
                },
            },

            '&:hover': {
                '& fieldset': {
                    borderColor: company ? company.color : secondary,
                },
            },

            '&.Mui-focused': {
                '& legend': {
                    backgroundColor: white,
                },

                '& fieldset': {
                    borderColor: company ? company.color : secondary,
                },
            },
        },
    });
