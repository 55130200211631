import React from 'react';
import { Clickable } from '../../../interfaces';
import { emptyFn } from '../../../utils';
import { StandardModal } from '../standardModal/StandardModal';

interface TimeoutModalProps {
    title: string;
    confirmButtonText: string;
    sessionTimeoutText: string;
    onClose?: (e: Clickable) => void;
    onConfirm?: (e: Clickable) => void;
}

export const TimeoutModal = ({
    title,
    confirmButtonText,
    sessionTimeoutText,
    onClose = emptyFn,
    onConfirm = emptyFn,
}: TimeoutModalProps) => {
    return (
        <StandardModal
            title={title}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmButtonText={confirmButtonText}
            showConfirmButton={true}
        >
            <div
                className={'dialog__content text-center'}
                data-testid={'sessionTimeoutModalContent'}
            >
                {sessionTimeoutText}
            </div>
        </StandardModal>
    );
};
