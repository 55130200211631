import { css } from '@emotion/react';
import { primary } from '../../variables';

export const summaryPersonItemCss = css({
    margin: '0.25rem',
    width: 'inherit !important',

    '& button': {
        border: `1px solid ${primary}`,
        borderRadius: 2,
        display: 'inline',
        fontSize: '1.125rem',
        lineHeight: '1.125rem',
        minWidth: 120,
        padding: 10,
        width: '100%',
        wordBreak: 'break-word',

        '&:hover, &:focus': {
            borderColor: 'rgba(125, 136, 144, 0.1)',
            backgroundColor: 'rgba(125, 136, 144, 0.1)',
            color: primary,
            outline: 0,
        },
    },
});
