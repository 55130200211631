import { svgSerialize } from './svgSerialize';
import { svgSetXmlNSAttrIfNotExists } from './svgSetXmlNSAttrIfNotExists';

export const svgToString = (el: SVGSVGElement) => {
    const serialized = svgSerialize(el);
    const parser = new DOMParser();
    const newSvg = parser.parseFromString(serialized, 'image/svg+xml');
    const newSvgEl = newSvg.firstChild as SVGSVGElement;

    return svgSerialize(svgSetXmlNSAttrIfNotExists(newSvgEl));
};
