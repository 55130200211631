import { Component } from 'react';

interface ErrorInfo {
    componentStack: string;
}

export class ErrorBoundary extends Component {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error);
    }

    render() {
        return this.props.children || null;
    }
}
