import { createStyles, makeStyles } from '@mui/styles';

export const helpToolTipCss = makeStyles(() =>
    createStyles({
        toolTip: {
            cursor: 'pointer',
            marginTop: '70px',
            marginLeft: '10.5%',
            position: 'absolute',
        },
    })
);
