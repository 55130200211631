import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoBack } from '../../../hooks/useGoBack';
import { EstateExecutorModel } from '../../../models/person/EstateExecutors';
import { FormProvider, useForm } from 'react-hook-form';
import { useI18n } from '../../../hooks/useI18n';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectEstateExecutors } from '../../../sagas/selectors/personSelectors';
import { commonActions } from '../../../sagas/common';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { personActions } from '../../../sagas/person';
import { isEmpty as isEmptyObject, PageLayout, PersonModel } from '@protectorinsurance/ds-can';
import { estateExecutorsSchema } from '../../../validations/schemas/estateExecutorsSchema';
import { removePerson } from '../../../utils/personUtils';
import { EstateExecutorForm } from '../../../components/estateExecutors/EstateExecutorForm';
import { EstateExecutorList } from '../../../components/estateExecutors/EstateExecutorList';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Destructure necessary values
 */
const { BACK_BUTTON, CONTINUE_BUTTON, DELETE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { FIRST_NAME } = FormFieldNames;
const { DUPLICATE_ESTATE_EXECUTORS } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const DeathEstateExecutorsPage = () => {
    const dispatch = useDispatch();
    const estateExecutors = useSelector(selectEstateExecutors);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.death.estateExecutors');
    const schema = estateExecutorsSchema(t);
    const form = useForm<EstateExecutorModel>({
        resolver: yupResolver(schema),
    });

    const handleBackButton = useGoBack();

    const isEmpty = (values: EstateExecutorModel) => {
        const { email, firstName, lastName, phone } = values;
        return (
            (!firstName || firstName.length === 0) &&
            (!lastName || lastName.length === 0) &&
            (!email || email.length === 0) &&
            (!phone || phone.length === 0)
        );
    };

    const handleContinueButton = () => {
        const values = form.getValues();
        if (!isEmpty(values)) {
            schema.isValid(values).then((valid) => {
                if (valid) {
                    handleDispatch(values, true);
                } else {
                    form.trigger();
                }
            });
        } else if (isEmptyObject(estateExecutors)) {
            form.trigger();
        } else {
            // Send to server and go to next
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleDispatch = (executor: EstateExecutorModel, isNext = false) => {
        if (isDuplicate(executor)) return;

        dispatcherWithPromise(dispatch, personActions.update, {
            estateExecutors: [...estateExecutors, executor],
        })
            .then(() => isNext && dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => {
                form.reset({
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                });
                if (isNext) {
                    dispatch(wizardActions.goToNext());
                }
            });
    };

    const handleOnSubmit = (executor: EstateExecutorModel) => handleDispatch(executor);

    const isDuplicate = (executor: EstateExecutorModel): boolean => {
        const isDuplicate = estateExecutors.find(
            (x: PersonModel) => x.firstName === executor.firstName && x.lastName === executor.lastName
        );

        if (isDuplicate) {
            form.setError(FIRST_NAME, {
                type: 'Duplicates',
                message: t(DUPLICATE_ESTATE_EXECUTORS),
            });
        }

        return !!isDuplicate;
    };

    const handleDelete = (e: React.MouseEvent, estateExecutor: EstateExecutorModel) => {
        e.preventDefault();
        const updatedEstateExecutors = removePerson(estateExecutor, estateExecutors);
        dispatch(personActions.update({ estateExecutors: updatedEstateExecutors }));
    };

    return (
        <FormProvider {...form}>
            <PageLayout
                backBtnText={t(BACK_BUTTON)}
                continueBtnText={t(CONTINUE_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerText={tWithNS.t(HELP_TEXT)}
                headerSubTitle={tWithNS.t(SUB_TITLE)}
                headerTitle={tWithNS.t(TITLE)}
                {...{ handleBackButton, handleContinueButton }}
            >
                <EstateExecutorForm onSubmit={handleOnSubmit} />

                <EstateExecutorList
                    buttonText={t(DELETE_BUTTON)}
                    handleDelete={handleDelete}
                    persons={estateExecutors}
                />
            </PageLayout>
        </FormProvider>
    );
};
