import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../config/api';
import { CANRequestError } from '../models/CANRequestError';
import { isObject, isString } from '@protectorinsurance/ds-can';
import { isProd, isTest } from './env';

export const APPLICATION_JSON = 'application/json';
export const MULTI_PART_HEADER = {
    Accept: APPLICATION_JSON,
    'Content-Type': 'multipart/form-data',
};

export const APPLICATION_JSON_HEADER = {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
};

const config: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    headers: APPLICATION_JSON_HEADER,
};

export enum HTTP_STATUS {
    OK = 200,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    INTERNAL_SERVER_ERROR = 500,
}
export const isSuccessResponse = (status: number) => {
    return status >= 200 && status < 300;
};

export const getMessage = (e: AxiosError) => {
    let message;
    if (e.response && isObject(e.response) && e.response.data) {
        if (isString(e.response.data)) {
            message = e.response.data;
        } else if (isObject(e.response.data) && isString((e.response.data as AxiosError).message)) {
            message = (e.response.data as AxiosError).message;
        }
    } else if (isString(e.message)) {
        message = e.message;
    } else {
        message = '';
    }
    return message;
};

export const createCANError = (e: AxiosError): CANRequestError => {
    if (e.response) {
        return {
            name: e.name,
            message: `${getMessage(e)} ${e.response.status}`,
            status: e.response.status,
            statusText: e.response.statusText,
            url: e.config.url,
        };
    } else if (e.request) {
        return {
            name: e.name,
            message: e.message,
            status: 400,
            statusText: 'Something happened to the request',
            url: e.config.url,
        };
    } else {
        return {
            name: e.name,
            message: e.message,
            status: 500,
            statusText: 'Other error',
            url: e.config.url,
        };
    }
};
export const api = axios.create(config);
api.interceptors.response.use(
    (response: AxiosResponse) => Promise.resolve(response),
    (error: AxiosError) => {
        return Promise.reject(createCANError(error));
    }
);
