import React from 'react';
import { createSvgTabs, MainImageSVGProps, SVGImageView } from '../../../types/SVGProps';
import { HeavyTruckArialImage } from './HeavyTruckArialImage';
import { HeavyTruckRightImage } from './HeavyTruckRightImage';
import { HeavyTruckLeftImage } from './HeavyTruckLeftImage';
import '../VehicleImageSvg.scss';
import Tabs from '../../tabs/Tabs';
import TabPanel from '../../tabs/TabPanel';
import { useI18n } from '../../../hooks/useI18n';
const { TOP, LEFT, RIGHT } = SVGImageView;

export const HeavyTruckImage = (props: MainImageSVGProps) => {
    const { t } = useI18n('');
    const tabs = createSvgTabs(t);
    return (
        <div className={'vehicle-body-damages'}>
            <Tabs label={t('tabs.label.heavyTruck')} tabs={tabs}>
                <TabPanel tabId={TOP}>
                    <HeavyTruckArialImage title={props.titles.top} {...props} />
                </TabPanel>
                <TabPanel tabId={RIGHT}>
                    <HeavyTruckRightImage title={props.titles.right} {...props} />
                </TabPanel>
                <TabPanel tabId={LEFT}>
                    <HeavyTruckLeftImage title={props.titles.left} {...props} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
