import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    AnimalBiteIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ChemicalInjuryIcon,
    Clickable,
    CrushInjuryIcon,
    CutInjuryIcon,
    FallInjuryIcon,
    FireInjuryIcon,
    is,
    LiftingInjuryIcon,
    OtherInjuriesIcon,
    PersonClaimCauseKeys,
    QuarrelIcon,
    ShockInjuryIcon,
    ShockOrCrashInjuryIcon,
    TeethOrBodyKeys,
    TemperatureInjuryIcon,
    TwistedKneeIcon,
    ViolenceInjuryIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimCause, selectTeethOrBody } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const {
    ANIMAL_BITE,
    CHEMICALS,
    CRASH_INJURY,
    CRUSH_INJURY,
    CUT,
    FALL,
    FIRE,
    LIFTING_INJURY,
    OTHER,
    QUARREL,
    SHOCK,
    TEMPERATURE_INJURY,
    TWIST,
    VIOLENCE,
} = PersonClaimCauseKeys;
const { TEETH } = TeethOrBodyKeys;
const { DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const accidentClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const teethOrBody = selectTeethOrBody(state);
    let options = [
        { id: FALL, name: t(`lob.person.accident.claimCause.select.${FALL}`), icon: <FallInjuryIcon /> },
        {
            id: CRASH_INJURY,
            name: t(`lob.person.accident.claimCause.select.${CRASH_INJURY}`),
            icon: <ShockOrCrashInjuryIcon />,
        },
        { id: CUT, name: t(`lob.person.accident.claimCause.select.${CUT}`), icon: <CutInjuryIcon /> },
        {
            id: CRUSH_INJURY,
            name: t(`lob.person.accident.claimCause.select.${CRUSH_INJURY}`),
            icon: <CrushInjuryIcon />,
        },
        {
            id: LIFTING_INJURY,
            name: t(`lob.person.accident.claimCause.select.${LIFTING_INJURY}`),
            icon: <LiftingInjuryIcon />,
        },
        { id: TWIST, name: t(`lob.person.accident.claimCause.select.${TWIST}`), icon: <TwistedKneeIcon /> },
        { id: VIOLENCE, name: t(`lob.person.accident.claimCause.select.${VIOLENCE}`), icon: <ViolenceInjuryIcon /> },
        { id: FIRE, name: t(`lob.person.accident.claimCause.select.${FIRE}`), icon: <FireInjuryIcon /> },
        { id: SHOCK, name: t(`lob.person.accident.claimCause.select.${SHOCK}`), icon: <ShockInjuryIcon /> },
        { id: CHEMICALS, name: t(`lob.person.accident.claimCause.select.${CHEMICALS}`), icon: <ChemicalInjuryIcon /> },
        {
            id: TEMPERATURE_INJURY,
            name: t(`lob.person.accident.claimCause.select.${TEMPERATURE_INJURY}`),
            icon: <TemperatureInjuryIcon />,
        },
        { id: ANIMAL_BITE, name: t(`lob.person.accident.claimCause.select.${ANIMAL_BITE}`), icon: <AnimalBiteIcon /> },
        { id: QUARREL, name: t(`lob.person.accident.claimCause.select.${QUARREL}`), icon: <QuarrelIcon /> },
        { id: OTHER, name: t(`lob.person.accident.claimCause.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    if (is(teethOrBody, TEETH)) {
        options = [
            { id: FALL, name: t(`lob.person.accident.claimCause.select.${FALL}`), icon: <FallInjuryIcon /> },
            {
                id: CRASH_INJURY,
                name: t(`lob.person.accident.claimCause.select.${CRASH_INJURY}`),
                icon: <ShockOrCrashInjuryIcon />,
            },
            {
                id: CRUSH_INJURY,
                name: t(`lob.person.accident.claimCause.select.${CRUSH_INJURY}`),
                icon: <CrushInjuryIcon />,
            },
            {
                id: VIOLENCE,
                name: t(`lob.person.accident.claimCause.select.${VIOLENCE}`),
                icon: <ViolenceInjuryIcon />,
            },
            { id: OTHER, name: t(`lob.person.accident.claimCause.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
        ];
    }

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'lob.person.accident.claimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext(option.id);
                if (is(teethOrBody, TEETH)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY);
                } else if (!is(option.id, TEMPERATURE_INJURY || FIRE || SHOCK || CHEMICALS)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                    dispatch(personActions.update({ claimCauseConsequences: [] }));
                }

                dispatcherWithPromise(dispatch, personActions.update, { claimCause: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
