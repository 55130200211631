import { Nullable } from '@protectorinsurance/ds-can';

interface IReturn<T, P> {
    type: T;
    data?: P | Nullable<object>;
}

export default <T, P = any>(type: T, data?: P | Nullable<object>): IReturn<T, P> => {
    return { type, data };
};
