import React, { Dispatch, SetStateAction } from 'react';
import { useI18n } from '../../../../hooks/useI18n';
import { CompanyModel } from 'models/person/Company';
import { I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayCompanyInformationProps {
    model: Partial<CompanyModel>;
    i18n?: I18NOptions;
    error: Dispatch<SetStateAction<boolean>>;
}

export const DisplayCompanyInformation = ({ model, i18n, error }: DisplayCompanyInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.namespace : undefined;
    const { t } = useI18n(namespace);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));

    const renderName = () => {
        if (model.name) {
            return <SummaryItemText label={tWithPrefix(`text.name`)} text={`${model.name}`} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.companyName')}</p>;
    };
    const renderBusinessNumber = () => {
        if (model.businessNumber) {
            return <SummaryItemText label={tWithPrefix(`text.businessNumber`)} text={`${model.businessNumber}`} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.companyBusinessNumber')}</p>;
    };

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 spacing header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix(`text.companyInformation`)}
                </p>
            </div>
            <div className={'col-8 divider text-align-left'}>{renderName()}</div>
            <div className={'col-4 divider text-align-left'}>{renderBusinessNumber()}</div>
        </>
    );
};
