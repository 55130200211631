import { isFunction } from '../is/isFunction';
import { StateResetRule } from '../../interfaces/types/StateReset.types';

export const stateResetResolveResetValue = (
    rule: StateResetRule,
    newValue: any,
    oldValue: any
) =>
    isFunction(rule?.resetValue)
        ? rule?.resetValue(newValue, oldValue)
        : rule?.resetValue || null;
