import { HOTJAR_ID, HOTJAR_VERSION } from 'config/hotjar';
import { isProd } from '../utils/env';

const hotjar = () => {
    (function (h: any, o: any, t: any, j: any) {
        h.hj =
            h.hj ||
            function () {
                (h.hj.q = h.hj.q || []).push(arguments);
            };
        h._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_VERSION };
        const a = o.getElementsByTagName('head')[0];
        const r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
};

// Run HotJar only in prod
if (isProd()) {
    hotjar();
}
