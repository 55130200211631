export enum PropertyDamageEnums {
    AIRING_CUPBOARD = 'AIRING_CUPBOARD',
    ATTIC = 'ATTIC',
    BASEMENT = 'BASEMENT',
    BATHROOM = 'BATHROOM',
    BEDROOM = 'BEDROOM',
    BOUNDARY_WALL = 'BOUNDARY_WALL',
    CHIMNEY = 'CHIMNEY',
    COMMUNAL_AREA = 'COMMUNAL_AREA',
    CONSERVATORY = 'CONSERVATORY',
    DINING_ROOM = 'DINING_ROOM',
    DOOR = 'DOOR',
    DRIVE = 'DRIVE',
    EXTERNAL_GLAZING = 'EXTERNAL_GLAZING',
    FENCE = 'FENCE',
    GARAGE = 'GARAGE',
    GARDEN = 'GARDEN',
    GATE = 'GATE',
    GREEN_HOUSE = 'GREEN_HOUSE',
    HALL = 'HALL',
    KITCHEN = 'KITCHEN',
    LOFT = 'LOFT',
    LOUNGE = 'LOUNGE',
    OFFICE = 'OFFICE',
    OTHER = 'OTHER',
    OTHER_OUTBUILDING = 'OTHER_OUTBUILDING',
    OTHER_PROPERTY = 'OTHER_PROPERTY',
    PATH = 'PATH',
    PATIO = 'PATIO',
    PORCH = 'PORCH',
    RECEPTION = 'RECEPTION',
    ROOF = 'ROOF',
    SHED = 'SHED',
    SHOWROOM = 'SHOWROOM',
    STOCKROOM = 'STOCKROOM',
    SWIMMING_POOL = 'SWIMMING_POOL',
    UTILITY_ROOM = 'UTILITY_ROOM',
    WALL = 'WALL',
    WAREHOUSE = 'WAREHOUSE',
    WC = 'WC',
}
