import { Nullable } from '../../../../../interfaces';

export interface PolicyHoldersContactModel {
    email: Nullable<string>;
    firstName: Nullable<string>;
    isReporter: boolean;
    lastName: Nullable<string>;
    phone: Nullable<string>;
    role?: Nullable<string>;
}

export const initPolicyHoldersContact: PolicyHoldersContactModel = {
    email: null,
    firstName: null,
    isReporter: false,
    lastName: null,
    phone: null,
    role: null,
};
