export const initDriverInformation = {
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    email: null,
    phone: null,
    street: null,
    zip: null,
    city: null,
    nationalIdentity: null,
    isUnknown: false,
    missingSSN: false,
};
