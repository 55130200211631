import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    initDriverInformation,
    MotorClaimCauseKeys,
    MuiTextInput,
    Nullable,
    PageLayout,
    ReporterInformationModel,
    SingleCheckbox,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import {
    selectClaimCause,
    selectHasParkedWitnesses,
    selectIsDriving,
    selectReporterInformation,
} from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { reporterInformationSchema } from '../../../validations/schemas/reporterInformationSchema';
import { reporterAndDriverInformationSchema } from '../../../validations/schemas/reporterAndDriverInformationSchema';
import { motorActions } from '../../../sagas/motor';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    IS_DRIVER_LABEL,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NATIONAL_IDENTITY_LABEL,
    NATIONAL_IDENTITY_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL, FIRST_NAME, IS_DRIVER, LAST_NAME, NATIONAL_IDENTITY, PHONE } = FormFieldNames;
const { END_DRIVER_INFORMATION, END_WITNESS_INFORMATION, END_UPLOAD_ATTACHMENT } = MotorRoutePaths;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { NO, YES } = YesNoKeys;
const { COMPARTMENT_DAMAGE, ENGINE, LOST_KEY, OTHER, THEFT_AND_DAMAGE, WRONG_FUEL } = MotorClaimCauseKeys;

/**
 * Interfaces
 */
interface ReporterFormModel extends Omit<ReporterInformationModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const ReporterInformationPage = () => {
    const dispatch = useDispatch();
    const reporterInformation = useSelector(selectReporterInformation);
    const isDriving = useSelector(selectIsDriving);
    const hasParkedWitnesses = useSelector(selectHasParkedWitnesses);
    const claimCause = useSelector(selectClaimCause);
    const customCAN = useSelector(selectCustomCAN);
    const isDriver = reporterInformation.isDriver;
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.reporterInformation');
    const schema = isDriver ? reporterAndDriverInformationSchema(t) : reporterInformationSchema(t);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ReporterFormModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...reporterInformation,
            dateOfBirth: reporterInformation.dateOfBirth && reporterInformation.dateOfBirth.toDate(),
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setValue('isDriver', checked);
        dispatch(wizardActions.skipBackToPrev(true));
        dispatcherWithPromise(dispatch, motorActions.update, {
            reporterInformation: { ...reporterInformation, isDriver: checked },
        }).then(() => {
            if (!checked) {
                setValue('nationalIdentity', '');
                dispatch(motorActions.update({ driverInformation: initDriverInformation }));
            }
        });
    };

    const onSubmit = (values: ReporterFormModel) => {
        let formValues = {
            reporterInformation: {
                ...values,
                isDriver,
                dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
            },
        };
        if (isDriver) {
            formValues = {
                ...formValues,
                ...{
                    driverInformation: {
                        ...values,
                        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                    },
                },
            };
        }
        isDriver && dispatch(wizardActions.skipBackToPrev(true));

        let goToLink = wizardActions.goToNext();
        if (isDriving === YES && !isDriver) {
            goToLink = wizardActions.goTo(END_DRIVER_INFORMATION);
        } else if (hasParkedWitnesses === YES) {
            goToLink = wizardActions.goTo(END_WITNESS_INFORMATION);
        } else if (
            claimCause === COMPARTMENT_DAMAGE ||
            claimCause === WRONG_FUEL ||
            claimCause === LOST_KEY ||
            claimCause === THEFT_AND_DAMAGE ||
            claimCause === ENGINE ||
            (claimCause === OTHER && isDriving === NO)
        ) {
            goToLink = wizardActions.goTo(END_UPLOAD_ATTACHMENT);
        }

        dispatcherWithPromise(dispatch, motorActions.update, formValues)
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(goToLink));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    {isDriving === YES && (
                        <SingleCheckbox
                            checked={isDriver}
                            handleChange={handleChecked}
                            name={IS_DRIVER}
                            wrapperClass={'col-12 multiple'}
                            {...{ customCAN }}
                        >
                            {t(IS_DRIVER_LABEL)}
                        </SingleCheckbox>
                    )}
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    {isDriver && (
                        <Controller
                            control={control}
                            name={NATIONAL_IDENTITY}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    error={!!errors.nationalIdentity}
                                    errorMessage={errors.nationalIdentity?.message}
                                    id={NATIONAL_IDENTITY}
                                    inputFieldWrapper={'col-3'}
                                    label={t(NATIONAL_IDENTITY_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(NATIONAL_IDENTITY_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    )}
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-6'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={`${isDriver ? 'col-3' : 'col-6'}`}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
