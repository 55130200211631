import React from 'react';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import {
    findClaimVehicle,
    InsuredByProtectorKeys,
    MotorClaimCauseKeys,
    SummaryItem,
    SummaryText,
} from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { dtTimeParseAndFormat } from '../../../utils/date/dateUtils';
import { MotorSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_START_INSURED_BY_PROTECTOR,
    DYN_CAROUSEL_START_IS_DRIVING,
    START_CLAIM_DATE,
    START_COMPANY_INFORMATION,
} = MotorRoutePaths;

/**
 * Page view and logic
 */
export const DisplayMotorStartSummary = ({ model, t, tWithNS }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const { claimCauseList, claimDate, companyInformation, isDriving, vehicles } = model;
    const claimVehicle = findClaimVehicle(vehicles);

    const renderClaimDate = () => {
        return (
            <>
                {/* Claim Date */}
                <SummaryItem
                    textKey={tWithNS('claimDate.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimDate}
                    buttonText={claimDate ? dtTimeParseAndFormat(claimDate) : ''}
                    path={START_CLAIM_DATE}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />
            </>
        );
    };

    const renderClaimCauses = () => {
        return (
            <>
                {/* Claim Causes */}
                <div className={'col-12 divider'}>
                    <SummaryText text={tWithNS('claimCauses.heading.title')} />
                    {claimCauseList.map(
                        (cause: { claimCause: MotorClaimCauseKeys; page: MotorRoutePaths }, idx: number) => {
                            return (
                                <SummaryItem
                                    key={idx}
                                    dataTestId={`${TEST_ID_PREFIX.claimCauses}${idx}`}
                                    buttonText={cause ? tWithNS(`claimCause.select.${cause.claimCause}`) : '-'}
                                    path={cause.page}
                                />
                            );
                        }
                    )}
                </div>
            </>
        );
    };

    const renderVehicleInformation = () => {
        return (
            <>
                {/* Insured By Protector */}
                {claimVehicle?.insuredByProtector &&
                    claimVehicle?.insuredByProtector !== InsuredByProtectorKeys.YES_RECENTLY_INSURED_BY_PROTECTOR && (
                        <SummaryItem
                            textKey={tWithNS('insuredByProtector.heading.title')}
                            dataTestId={TEST_ID_PREFIX.insuredByProtector}
                            buttonText={t(`motor.start.insuredByProtector.select.${claimVehicle?.insuredByProtector}`)}
                            path={DYN_CAROUSEL_START_INSURED_BY_PROTECTOR}
                            inputWrapperClass={'col-12 spacing text-align-left'}
                        />
                    )}

                {/* Company Information */}
                {companyInformation.name && companyInformation.businessNumber && (
                    <SummaryItem
                        textKey={tWithNS('companyInformation.heading.title')}
                        dataTestId={TEST_ID_PREFIX.companyInformation}
                        buttonText={`${companyInformation.name} (${companyInformation.businessNumber})`}
                        path={START_COMPANY_INFORMATION}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                )}
            </>
        );
    };

    const renderIsDriving = () => {
        return (
            <>
                {/* Is Driving */}
                <SummaryItem
                    textKey={tWithNS('isDriving.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isDriving}
                    buttonText={t(createTextYesNoDontKnow(isDriving))}
                    path={DYN_CAROUSEL_START_IS_DRIVING}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />
            </>
        );
    };

    return (
        <>
            {renderClaimDate()}

            {renderClaimCauses()}

            {renderVehicleInformation()}

            {renderIsDriving()}
        </>
    );
};
