import {
    CountryCodeISOEnums,
    LanguageCodeEnums,
    LocaleServiceInstance,
    MomentLocaleEnums,
} from '@protectorinsurance/ds-can';

LocaleServiceInstance.setCountry(CountryCodeISOEnums.SWEDEN)
    .setLanguage(LanguageCodeEnums.SE)
    .setMoment(MomentLocaleEnums.SE)
    .initialize();
