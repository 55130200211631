import { Nullable } from '../../../../../interfaces';

export enum ClaimLocationKeys {
    AT_WORK = 'AT_WORK',
    COMMUTING_LEISURE = 'COMMUTING_LEISURE',
    COMMUTING_SCHOOL = 'COMMUTING_SCHOOL',
    INTERSECTION = 'INTERSECTION',
    LEISURE = 'LEISURE',
    LEISURE_CHILD = 'LEISURE_CHILD',
    ON_WAY_TO_WORK = 'COMMUTING',
    OTHER = 'OTHER',
    PARKING_OTHER = 'PARKING_OTHER',
    ROAD = 'ROAD',
    ROUNDABOUT = 'ROUNDABOUT',
    SCHOOL = 'SCHOOL',
    RIGHT_OF_WAY = 'RIGHT_OF_WAY',
}

export type ClaimLocationTypeModel = Nullable<ClaimLocationKeys>;
