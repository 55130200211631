export const ALLOWED_MIME_TYPES = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-outlook',
    'audio/aac',
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
    'image/*',
    'message/rfc822',
    'text/plain',
    'video/avi',
    'video/mp4',
    'video/mpeg',
    'video/quicktime',
    'video/webm',
    'video/x-msvideo',
];
