import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { costSchema } from '../fieldSchemas/costSchema';

/**
 * Validation logic
 */
export const costInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...costSchema(t),
    });
};
