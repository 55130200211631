import { VehicleTypeKeys } from '../models';

export const VehicleTypeCodesSwedenMap = new Map<
    VehicleTypeKeys,
    Array<string> | string
>([
    [VehicleTypeKeys.CAR, ['PB', '01 PB']],
    [VehicleTypeKeys.MOTORCYCLE, 'MC'],
    [VehicleTypeKeys.MOPED, 'MOPED'],
    [VehicleTypeKeys.BUS, 'BUSS'],
    [VehicleTypeKeys.TRUCK, ['LB', '02 LB']],
    [VehicleTypeKeys.MOTORIZED_TOOL, 'MRED'],
    [VehicleTypeKeys.TRAILER, 'SLÄP'],
    [VehicleTypeKeys.TERRAIN_SCOOTER, 'TGSNÖ'],
    [VehicleTypeKeys.TERRAIN_VEHICLE, 'TGHJUL'],
    [VehicleTypeKeys.TERRAIN_TRAILER, 'TGSL'],
    [VehicleTypeKeys.SUP_400_TERRAIN_VEHICLE, 'L7e'],
    [VehicleTypeKeys.TRACTOR, 'TR'],
]);
