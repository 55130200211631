import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimReporterRoleKeys,
    Clickable,
    OtherInjuriesIcon,
    ParentsChildIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimReporter } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { OTHER, PARENT } = ClaimReporterRoleKeys;

/**
 * Page view and page logic
 */
export const accidentClaimReporterPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: PARENT, name: t(`lob.person.accident.claimReporter.select.${PARENT}`), icon: <ParentsChildIcon /> },
        { id: OTHER, name: t(`lob.person.accident.claimReporter.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER,
        stateKey: 'claimReporter',
        i18n: {
            ns: 'lob.person.accident.claimReporter',
        },
        selectorValue: selectClaimReporter(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { claimReporter: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
