import { COUNTRY_CODE_SWEDEN, CountryCodeArray, LANG_CODE_SWEDEN, LanguageCodeArray } from '../config/locale';
import { LocaleModel } from '@protectorinsurance/ds-can';

export const getLocaleFromUrl = (): LocaleModel => {
    const url = window.location.pathname.split('/');

    // Check if language and country is specified in the url
    if (CountryCodeArray.includes(url[1]) && LanguageCodeArray.includes(url[2])) {
        return { country: url[1], language: url[2] };
    }

    return { country: COUNTRY_CODE_SWEDEN, language: LANG_CODE_SWEDEN };
};
