import { OptionType, YesNoKeys } from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { DONT_KNOW, NO, YES } = YesNoKeys;

/**
 * Utility logic
 */
const createReportedToInsurersInsuranceKey = (id: string) => {
    return `lpo.reportedToInsurersInsurance.select.${id}`;
};

export const getDontKnowOption = (t: TFunction): OptionType => {
    return {
        value: DONT_KNOW,
        label: t(createReportedToInsurersInsuranceKey(DONT_KNOW)),
    };
};

export const getNoOption = (t: TFunction): OptionType => {
    return {
        value: NO,
        label: t(createReportedToInsurersInsuranceKey(NO)),
    };
};

export const getYesOption = (t: TFunction): OptionType => {
    return {
        value: YES,
        label: t(createReportedToInsurersInsuranceKey(YES)),
    };
};
