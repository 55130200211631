/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React from 'react';
import { PencilIcon } from '../icons/CommonIcons';
import { Clickable } from '../../../interfaces';

interface EditButtonProps {
    ariaLabel?: string;
    className?: string;
    onClick?: (e: Clickable) => void;
    testId?: string;
}

export const EditButton = ({
    ariaLabel,
    className,
    onClick,
    testId,
}: EditButtonProps) => {
    return (
        <button
            aria-label={ariaLabel}
            className={className}
            data-testid={testId}
            onClick={onClick}
            type={'button'}
        >
            <PencilIcon />
        </button>
    );
};
