import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';

export const toggles: ObjectWithDynamicKey = {
    NO_HAS_EXPENSES_ON_ACCIDENT: false,
    MUNICIPALITY_SERVICE: false,

    // Used for testing
    TEST_FALSE: false,
    TEST_TRUE: true,
};
