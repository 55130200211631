import { isAllDigits } from '../../../utils';
import { ssnNorwayHasValidDate } from './ssnNorwayHasValidDate';
import { ssnNorwayMatchPattern } from './ssnNorwayMatchPattern';

/**
 * Weight A for person number
 */
const NATIONAL_IDENTITY_WEIGHT_A: number[] = [3, 7, 6, 1, 8, 9, 4, 5, 2, 1, 0];
/**
 * Weight B for person number
 */

const NATIONAL_IDENTITY_WEIGHT_B: number[] = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];

export function ssnNorwayValidator(ssn: string) {
    return {
        isValid: function isValid() {
            if (!ssnNorwayMatchPattern(ssn)) {
                return false;
            }

            if (ssn.length === 0) {
                return false;
            }

            if (ssn.length !== 11) {
                return false;
            }

            if (!isAllDigits(ssn)) {
                return false;
            }

            if (ssn.startsWith('00')) {
                return false;
            }

            if (
                !ssnNorwayHasValidDate(
                    derivation.getDay(ssn),
                    derivation.getMonth(ssn),
                    derivation.getYear(ssn)
                )
            ) {
                return false;
            }

            let sumA = 0;
            let sumB = 0;
            for (let i = 0; i < 11; i++) {
                sumA += parseInt(ssn.charAt(i)) * NATIONAL_IDENTITY_WEIGHT_A[i];
                sumB += parseInt(ssn.charAt(i)) * NATIONAL_IDENTITY_WEIGHT_B[i];
            }
            if (sumA % 11 !== 0) {
                return false;
            }
            if (sumB % 11 !== 0) {
                return false;
            }

            return ssnNorwayHasValidDate(
                derivation.getDay(ssn),
                derivation.getMonth(ssn),
                derivation.getYear(ssn)
            );
        },
    };
}

export const derivation = {
    getDay(ssn: string) {
        ssn = ssn.replace(/\D/g, '');
        let day = parseInt(ssn.substring(0, 2));

        /*
            Eit D-nummer er ellevesifra, som ordinære fødselsnummer, og bygd opp av ein modifisert sekssifra fødselsdato og eit femsifra personnummer.
            Fødselsdatoen er modifisert ved at talet 4 er lagt til første sifferet: ein person fødd 1. januar 1980 får dermed fødselsdato 410180,
            medan ein som er fødd 31. januar 1980 får 710180.
         */
        if (day >= 40) {
            day -= 40;
        }
        return day;
    },

    getMonth(ssn: string) {
        ssn = ssn.replace(/\D/g, '');
        let month: number = parseInt(ssn.substring(2, 4));
        /*
            Eit H-nummer er ellevesifra, som ordinære fødselsnummer, og bygd opp av ein modifisert sekssifra fødselsdato og eit femsifra personnummer.
            Fødselsdatoen er modifisert ved at det tredje sifferet er tillagt 4: ein person fødd 1. januar 1980 får dermed fødselsdato 014180,
            medan ein som er fødd 31. januar 1980 får 314180.
         */
        if (month > 41) {
            month = month - 40;
        }
        return month;
    },

    getYear(ssn: string) {
        ssn = ssn.replace(/\D/g, '');
        let year: number = parseInt(ssn.substring(4, 6));
        const individNo = parseInt(ssn.substring(6, 9));
        if (500 <= individNo && individNo <= 749 && year > 54) {
            year += 1800;
        } else if (0 <= individNo && individNo <= 499) {
            year += 1900;
        } else if (900 <= individNo && individNo <= 999 && year > 39) {
            year += 1900;
        } else if (500 <= individNo && individNo <= 999 && year < 40) {
            year += 2000;
        }
        return year;
    },
};
