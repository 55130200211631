import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDiagnosis } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { Clickable, FormChangeable, Grid, MuiAutocomplete, OptionType, PageLayout } from '@protectorinsurance/ds-can';
import { personActions } from '../../../sagas/person';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { diagnosisOptions } from '../../../config/person/diagnosisInformationOptions';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { DIAGNOSIS } = FormFieldNames;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DIAGNOSIS_LABEL,
    DIAGNOSIS_PLACEHOLDER,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const IllnessDiagnosisInformationPage = () => {
    const dispatch = useDispatch();
    const [diagnosis, setDiagnosis] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const claimantDiagnosis = useSelector(selectDiagnosis);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.illness.diagnosisInformation');
    const options = diagnosisOptions;

    useEffect(() => {
        const selected = diagnosisOptions.find((x) => x.label === claimantDiagnosis);
        if (selected) {
            setDiagnosis(selected);
        }
    }, [claimantDiagnosis, setDiagnosis]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (diagnosis === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.label : null;
        setError('');
        setDiagnosis(object);
        dispatch(personActions.update({ diagnosis: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (diagnosis === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={DIAGNOSIS}
                    inputFieldWrapper={'col-12'}
                    label={t(DIAGNOSIS_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(DIAGNOSIS_PLACEHOLDER)}
                    value={diagnosis}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
