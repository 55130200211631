import { Nullable } from '../../../../../interfaces';

export interface LocationModel {
    address?: Nullable<string>;
    isUnknownLocation: boolean;
    longitude: Nullable<number>;
    latitude: Nullable<number>;
    note: Nullable<string>;
    unit?: Nullable<string>;
}

export type LocationTypeModel = Nullable<string>;

export const initLocation: LocationModel = {
    address: null,
    isUnknownLocation: false,
    latitude: null,
    longitude: null,
    note: null,
    unit: null,
};
