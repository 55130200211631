import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isAllDigits, isEmpty } from '@protectorinsurance/ds-can';

export function digits(this: StringSchema, len?: number, message: string = yupCustomLocale.string.digits) {
    // @ts-ignore
    return this.test('digits', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        if (value?.length > 0) {
            const res = isAllDigits(value);
            return len ? res && value.length === len : res;
        }
        return isEmpty(value);
    });
}
