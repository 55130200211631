import React from 'react';
import { DisplayAccidentFinalSummary } from '../../components/summary/person/final/DisplayAccidentFinalSummary';
import { DisplayIllnessFinalSummary } from 'components/summary/person/final/DisplayIllnessFinalSummary';
import { DisplayDeathFinalSummary } from '../../components/summary/person/final/DisplayDeathFinalSummary';
import {
    CategoryKeys,
    ClaimLocationKeys,
    FlowKeys,
    FlowModel,
    MotorClaimCauseKeys,
    Nullable,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { DEATH, ILLNESS } = FlowKeys;

/**
 * Utility logic
 */
export const getFinalSummary = (
    flow: Nullable<FlowModel | MotorClaimCauseKeys | ClaimLocationKeys | CategoryKeys>,
    showSummary = true
) => {
    if (flow === ILLNESS) {
        return <DisplayIllnessFinalSummary showSummary={showSummary} />;
    } else if (flow === DEATH) {
        return <DisplayDeathFinalSummary showSummary={showSummary} />;
    } else {
        return <DisplayAccidentFinalSummary showSummary={showSummary} />;
    }
};
