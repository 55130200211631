import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { ASSOCIATION_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const associationSchema = (t: TFunction) => {
    return {
        association: Yup.string().label(t(ASSOCIATION_LABEL)).noSpecialChars().required().nullable(),
    };
};
