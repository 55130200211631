export default {
    '111 15': 'Fjäderholmen',
    '111 20': 'Stockholm',
    '111 21': 'Stockholm',
    '111 22': 'Stockholm',
    '111 23': 'Stockholm',
    '111 24': 'Stockholm',
    '111 27': 'Stockholm',
    '111 28': 'Stockholm',
    '111 29': 'Gamla stan',
    '111 30': 'Stockholm',
    '111 31': 'Stockholm',
    '111 34': 'Stockholm',
    '111 35': 'Stockholm',
    '111 36': 'Stockholm',
    '111 37': 'Stockholm',
    '111 38': 'Stockholm',
    '111 39': 'Stockholm',
    '111 40': 'Stockholm',
    '111 43': 'Stockholm',
    '111 44': 'Stockholm',
    '111 45': 'Stockholm',
    '111 46': 'Stockholm',
    '111 47': 'Stockholm',
    '111 48': 'Stockholm',
    '111 49': 'Skeppsholmen',
    '111 51': 'Stockholm',
    '111 52': 'Stockholm',
    '111 53': 'Stockholm',
    '111 56': 'Stockholm',
    '111 57': 'Norrmalm',
    '111 60': 'Stockholm',
    '111 61': 'Stockholm',
    '111 64': 'Stockholm',
    '112 15': 'Stockholm',
    '112 16': 'Stockholm',
    '112 17': 'Stadshagen',
    '112 18': 'Stockholm',
    '112 19': 'Stockholm',
    '112 20': 'Stockholm',
    '112 21': 'Stockholm',
    '112 22': 'Stockholm',
    '112 23': 'Stockholm',
    '112 24': 'Stockholm',
    '112 25': 'Stockholm',
    '112 26': 'Stockholm',
    '112 27': 'Stockholm',
    '112 28': 'Stockholm',
    '112 29': 'Stockholm',
    '112 30': 'Stockholm',
    '112 31': 'Stockholm',
    '112 32': 'Kungsholmen',
    '112 33': 'Stockholm',
    '112 34': 'Stockholm',
    '112 35': 'Stockholm',
    '112 36': 'Stockholm',
    '112 37': 'Stockholm',
    '112 38': 'Stockholm',
    '112 39': 'Stockholm',
    '112 40': 'Stockholm',
    '112 41': 'Stockholm',
    '112 42': 'Stockholm',
    '112 43': 'Stockholm',
    '112 44': 'Stockholm',
    '112 45': 'Stockholm',
    '112 46': 'Stockholm',
    '112 47': 'Stockholm',
    '112 48': 'Stockholm',
    '112 49': 'Stockholm',
    '112 50': 'Stockholm',
    '112 51': 'Stockholm',
    '112 52': 'Stockholm',
    '112 53': 'Kristineberg',
    '112 54': 'Stockholm',
    '112 55': 'Stockholm',
    '112 56': 'Fredhäll',
    '112 57': 'Stockholm',
    '112 58': 'Stockholm',
    '112 59': 'Marieberg',
    '112 60': 'Stockholm',
    '112 61': 'Stockholm',
    '112 62': 'Stockholm',
    '112 63': 'Lilla Essingen',
    '112 64': 'Stora Essingen',
    '112 65': 'Stockholm',
    '112 66': 'Stockholm',
    '112 67': 'Stockholm',
    '112 69': 'Stockholm',
    '113 20': 'Stockholm',
    '113 21': 'Stockholm',
    '113 22': 'Stockholm',
    '113 23': 'Stockholm',
    '113 24': 'Stockholm',
    '113 25': 'Vasastaden',
    '113 26': 'Stockholm',
    '113 27': 'Stockholm',
    '113 28': 'Stockholm',
    '113 29': 'Stockholm',
    '113 30': 'Stockholm',
    '113 31': 'Stockholm',
    '113 32': 'Stockholm',
    '113 33': 'Stockholm',
    '113 34': 'Stockholm',
    '113 35': 'Stockholm',
    '113 36': 'Stockholm',
    '113 37': 'Stockholm',
    '113 38': 'Stockholm',
    '113 39': 'Stockholm',
    '113 40': 'Stockholm',
    '113 41': 'Stockholm',
    '113 42': 'Stockholm',
    '113 43': 'Stockholm',
    '113 44': 'Stockholm',
    '113 45': 'Stockholm',
    '113 46': 'Stockholm',
    '113 47': 'Stockholm',
    '113 48': 'Stockholm',
    '113 49': 'Stockholm',
    '113 50': 'Stockholm',
    '113 51': 'Stockholm',
    '113 52': 'Stockholm',
    '113 53': 'Stockholm',
    '113 54': 'Stockholm',
    '113 55': 'Stockholm',
    '113 56': 'Stockholm',
    '113 57': 'Stockholm',
    '113 58': 'Stockholm',
    '113 59': 'Stockholm',
    '113 60': 'Stockholm',
    '113 61': 'Stockholm',
    '113 62': 'Stockholm',
    '113 64': 'Stockholm',
    '113 65': 'Stockholm',
    '114 15': 'Stockholm',
    '114 16': 'Stockholm',
    '114 17': 'Stockholm',
    '114 18': 'Norra Djurgården',
    '114 19': 'Stockholm',
    '114 20': 'Stockholm',
    '114 21': 'Stockholm',
    '114 22': 'Stockholm',
    '114 23': 'Stockholm',
    '114 24': 'Stockholm',
    '114 25': 'Stockholm',
    '114 26': 'Stockholm',
    '114 27': 'Stockholm',
    '114 28': 'Stockholm',
    '114 29': 'Stockholm',
    '114 30': 'Stockholm',
    '114 31': 'Stockholm',
    '114 32': 'Stockholm',
    '114 33': 'Stockholm',
    '114 34': 'Stockholm',
    '114 35': 'Stockholm',
    '114 36': 'Stockholm',
    '114 37': 'Stockholm',
    '114 38': 'Stockholm',
    '114 39': 'Stockholm',
    '114 40': 'Stockholm',
    '114 41': 'Stockholm',
    '114 42': 'Stockholm',
    '114 43': 'Stockholm',
    '114 44': 'Stockholm',
    '114 45': 'Stockholm',
    '114 46': 'Stockholm',
    '114 47': 'Stockholm',
    '114 48': 'Stockholm',
    '114 49': 'Stockholm',
    '114 50': 'Stockholm',
    '114 51': 'Stockholm',
    '114 52': 'Stockholm',
    '114 53': 'Stockholm',
    '114 54': 'Stockholm',
    '114 55': 'Stockholm',
    '114 56': 'Stockholm',
    '114 57': 'Stockholm',
    '114 58': 'Östermalm',
    '114 59': 'Stockholm',
    '114 60': 'Stockholm',
    '115 20': 'Stockholm',
    '115 21': 'Djurgården',
    '115 22': 'Stockholm',
    '115 23': 'Stockholm',
    '115 24': 'Stockholm',
    '115 25': 'Stockholm',
    '115 26': 'Stockholm',
    '115 27': 'Stockholm',
    '115 28': 'Stockholm',
    '115 29': 'Stockholm',
    '115 30': 'Stockholm',
    '115 31': 'Stockholm',
    '115 32': 'Stockholm',
    '115 33': 'Stockholm',
    '115 34': 'Ladugårdsgärdet',
    '115 35': 'Stockholm',
    '115 36': 'Stockholm',
    '115 37': 'Stockholm',
    '115 38': 'Stockholm',
    '115 39': 'Stockholm',
    '115 40': 'Stockholm',
    '115 41': 'Stockholm',
    '115 42': 'Hjorthagen',
    '115 43': 'Stockholm',
    '115 44': 'Stockholm',
    '115 45': 'Stockholm',
    '115 46': 'Stockholm',
    '115 47': 'Stockholm',
    '115 49': 'Stockholm',
    '115 50': 'Stockholm',
    '115 51': 'Stockholm',
    '115 53': 'Stockholm',
    '115 56': 'Stockholm',
    '115 57': 'Stockholm',
    '115 58': 'Stockholm',
    '115 59': 'Stockholm',
    '115 60': 'Stockholm',
    '116 20': 'Stockholm',
    '116 21': 'Södermalm',
    '116 22': 'Stockholm',
    '116 23': 'Stockholm',
    '116 24': 'Stockholm',
    '116 25': 'Stockholm',
    '116 28': 'Stockholm',
    '116 29': 'Stockholm',
    '116 30': 'Stockholm',
    '116 31': 'Stockholm',
    '116 32': 'Stockholm',
    '116 33': 'Stockholm',
    '116 34': 'Stockholm',
    '116 35': 'Stockholm',
    '116 36': 'Stockholm',
    '116 37': 'Stockholm',
    '116 38': 'Stockholm',
    '116 39': 'Stockholm',
    '116 40': 'Stockholm',
    '116 41': 'Stockholm',
    '116 42': 'Stockholm',
    '116 43': 'Stockholm',
    '116 44': 'Stockholm',
    '116 45': 'Stockholm',
    '116 46': 'Stockholm',
    '116 47': 'Stockholm',
    '116 48': 'Stockholm',
    '116 61': 'Stockholm',
    '116 62': 'Stockholm',
    '116 63': 'Stockholm',
    '116 64': 'Stockholm',
    '116 65': 'Stockholm',
    '116 66': 'Stockholm',
    '116 67': 'Stockholm',
    '116 68': 'Stockholm',
    '117 26': 'Stockholm',
    '117 27': 'Stockholm',
    '117 28': 'Stockholm',
    '117 29': 'Stockholm',
    '117 30': 'Stockholm',
    '117 31': 'Stockholm',
    '117 32': 'Stockholm',
    '117 33': 'Långholmen',
    '117 34': 'Stockholm',
    '117 35': 'Stockholm',
    '117 36': 'Stockholm',
    '117 37': 'Stockholm',
    '117 38': 'Stockholm',
    '117 39': 'Stockholm',
    '117 40': 'Stockholm',
    '117 41': 'Stockholm',
    '117 43': 'Liljeholmen',
    '117 50': 'Reimersholme',
    '117 56': 'Stockholm',
    '117 57': 'Stockholm',
    '117 58': 'Stockholm',
    '117 59': 'Stockholm',
    '117 60': 'Stockholm',
    '117 61': 'Stockholm',
    '117 62': 'Stockholm',
    '117 63': 'Stockholm',
    '117 64': 'Stockholm',
    '117 65': 'Stockholm',
    '117 66': 'Gröndal',
    '117 67': 'Stockholm',
    '117 68': 'Stockholm',
    '117 69': 'Stockholm',
    '117 75': 'Stockholm',
    '118 20': 'Stockholm',
    '118 21': 'Stockholm',
    '118 22': 'Stockholm',
    '118 23': 'Stockholm',
    '118 24': 'Stockholm',
    '118 25': 'Stockholm',
    '118 26': 'Stockholm',
    '118 27': 'Stockholm',
    '118 28': 'Stockholm',
    '118 29': 'Stockholm',
    '118 30': 'Stockholm',
    '118 42': 'Stockholm',
    '118 46': 'Stockholm',
    '118 47': 'Stockholm',
    '118 48': 'Stockholm',
    '118 49': 'Stockholm',
    '118 50': 'Stockholm',
    '118 51': 'Stockholm',
    '118 52': 'Stockholm',
    '118 53': 'Stockholm',
    '118 54': 'Stockholm',
    '118 55': 'Stockholm',
    '118 56': 'Stockholm',
    '118 57': 'Stockholm',
    '118 58': 'Stockholm',
    '118 59': 'Stockholm',
    '118 60': 'Stockholm',
    '118 61': 'Stockholm',
    '118 62': 'Stockholm',
    '118 63': 'Stockholm',
    '118 64': 'Stockholm',
    '118 65': 'Stockholm',
    '118 66': 'Stockholm',
    '118 67': 'Stockholm',
    '118 69': 'Stockholm',
    '118 72': 'Stockholm',
    '120 30': 'Stockholm',
    '120 31': 'Stockholm',
    '120 32': 'Stockholm',
    '120 38': 'Stockholm',
    '120 39': 'Stockholm',
    '120 40': 'Stockholm',
    '120 44': 'Stockholm',
    '120 47': 'Enskede gård',
    '120 48': 'Stockholm',
    '120 50': 'Stockholm',
    '120 51': 'Stockholm',
    '120 52': 'Stockholm',
    '120 53': 'Årsta',
    '120 54': 'Stockholm',
    '120 55': 'Stockholm',
    '120 56': 'Stockholm',
    '120 57': 'Stockholm',
    '120 58': 'Stockholm',
    '120 59': 'Stockholm',
    '120 60': 'Stockholm',
    '120 61': 'Stockholm',
    '120 62': 'Stockholm',
    '120 63': 'Stockholm',
    '120 64': 'Södra Hammarbyhamnen',
    '120 65': 'Stockholm',
    '120 66': 'Stockholm',
    '120 67': 'Stockholm',
    '120 68': 'Stockholm',
    '120 69': 'Stockholm',
    '120 70': 'Stockholm',
    '120 71': 'Stockholm',
    '120 79': 'Stockholm',
    '121 30': 'Stockholm',
    '121 31': 'Stockholm',
    '121 32': 'Stockholm',
    '121 33': 'Stockholm',
    '121 34': 'Enskededalen',
    '121 35': 'Stockholm',
    '121 36': 'Stockholm',
    '121 37': 'Stockholm',
    '121 38': 'Stockholm',
    '121 39': 'Stockholm',
    '121 40': 'Stockholm',
    '121 43': 'Stockholm',
    '121 44': 'Stockholm',
    '121 45': 'Stockholm',
    '121 46': 'Stockholm',
    '121 47': 'Stockholm',
    '121 48': 'Hammarbyhöjden',
    '121 49': 'Stockholm',
    '121 50': 'Stockholm',
    '121 51': 'Stockholm',
    '121 52': 'Stockholm',
    '121 53': 'Björkhagen',
    '121 54': 'Stockholm',
    '121 55': 'Kärrtorp',
    '121 56': 'Stockholm',
    '121 62': 'Stockholm',
    '121 63': 'Stockholm',
    '121 77': 'Johanneshov',
    '122 31': 'Stockholm',
    '122 32': 'Stockholm',
    '122 33': 'Stockholm',
    '122 34': 'Stockholm',
    '122 37': 'Enskedefältet',
    '122 38': 'Stockholm',
    '122 39': 'Stockholm',
    '122 40': 'Stockholm',
    '122 41': 'Stockholm',
    '122 42': 'Stureby',
    '122 43': 'Stockholm',
    '122 44': 'Stockholm',
    '122 45': 'Gubbängen',
    '122 46': 'Stockholm',
    '122 47': 'Stockholm',
    '122 48': 'Svedmyra',
    '122 49': 'Stockholm',
    '122 60': 'Tallkrogen',
    '122 62': 'Gamla Enskede',
    '122 63': 'Stockholm',
    '122 64': 'Stockholm',
    '122 65': 'Stockholm',
    '122 66': 'Stockholm',
    '123 31': 'Stockholm',
    '123 32': 'Stockholm',
    '123 33': 'Farsta',
    '123 34': 'Stockholm',
    '123 41': 'Stockholm',
    '123 42': 'Stockholm',
    '123 43': 'Larsboda',
    '123 44': 'Stockholm',
    '123 46': 'Stockholm',
    '123 47': 'Stockholm',
    '123 48': 'Stockholm',
    '123 49': 'Stockholm',
    '123 50': 'Stockholm',
    '123 52': 'Farstanäset',
    '123 53': 'Fagersjö',
    '123 54': 'Stockholm',
    '123 55': 'Stockholm',
    '123 56': 'Stockholm',
    '123 57': 'Hökarängen',
    '123 58': 'Stockholm',
    '123 59': 'Stockholm',
    '123 60': 'Stockholm',
    '123 61': 'Stockholm',
    '123 63': 'Stockholm',
    '123 71': 'Farsta strand',
    '123 72': 'Stockholm',
    '123 73': 'Stockholm',
    '124 30': 'Stockholm',
    '124 31': 'Bandhagen',
    '124 32': 'Stockholm',
    '124 33': 'Stockholm',
    '124 50': 'Stockholm',
    '124 51': 'Stockholm',
    '124 52': 'Stockholm',
    '124 53': 'Stockholm',
    '124 54': 'Stockholm',
    '124 55': 'Stockholm',
    '124 56': 'Stockholm',
    '124 57': 'Stockholm',
    '124 58': 'Högdalen',
    '124 59': 'Stockholm',
    '124 60': 'Stockholm',
    '124 61': 'Rågsved',
    '124 62': 'Stockholm',
    '124 63': 'Stockholm',
    '124 64': 'Stockholm',
    '124 65': 'Stockholm',
    '124 66': 'Stockholm',
    '124 67': 'Stockholm',
    '124 70': 'Stockholm',
    '124 71': 'Stockholm',
    '124 72': 'Stockholm',
    '124 73': 'Hagsätra',
    '124 74': 'Stockholm',
    '124 75': 'Stockholm',
    '124 76': 'Stockholm',
    '125 30': 'Älvsjö',
    '125 31': 'Stockholm',
    '125 32': 'Stockholm',
    '125 33': 'Långbro',
    '125 34': 'Stockholm',
    '125 35': 'Örby slott',
    '125 40': 'Örby',
    '125 41': 'Stockholm',
    '125 42': 'Stockholm',
    '125 43': 'Stockholm',
    '125 44': 'Solberga',
    '125 45': 'Stockholm',
    '125 46': 'Stockholm',
    '125 51': 'Stockholm',
    '125 52': 'Långsjö',
    '125 53': 'Stockholm',
    '125 54': 'Herrängen',
    '125 55': 'Stockholm',
    '125 56': 'Stockholm',
    '125 57': 'Stockholm',
    '125 58': 'Stockholm',
    '125 59': 'Stockholm',
    '125 60': 'Stockholm',
    '125 70': 'Stockholm',
    '125 71': 'Stockholm',
    '125 72': 'Stockholm',
    '125 73': 'Östberga',
    '125 74': 'Stockholm',
    '126 26': 'Stockholm',
    '126 28': 'Stockholm',
    '126 29': 'Stockholm',
    '126 30': 'Stockholm',
    '126 31': 'Västberga',
    '126 32': 'Stockholm',
    '126 33': 'Stockholm',
    '126 34': 'Stockholm',
    '126 35': 'Stockholm',
    '126 36': 'Midsommarkransen',
    '126 37': 'Stockholm',
    '126 38': 'Stockholm',
    '126 39': 'Stockholm',
    '126 40': 'Stockholm',
    '126 41': 'Stockholm',
    '126 42': 'Stockholm',
    '126 47': 'Stockholm',
    '126 48': 'Stockholm',
    '126 49': 'Stockholm',
    '126 50': 'Stockholm',
    '126 51': 'Stockholm',
    '126 52': 'Aspudden',
    '126 53': 'Stockholm',
    '126 54': 'Stockholm',
    '126 55': 'Stockholm',
    '126 77': 'Stockholm',
    '126 78': 'Stockholm',
    '126 79': 'Stockholm',
    '127 30': 'Stockholm',
    '127 31': 'Stockholm',
    '127 32': 'Stockholm',
    '127 33': 'Stockholm',
    '127 34': 'Bredäng',
    '127 35': 'Stockholm',
    '127 36': 'Sätra',
    '127 37': 'Stockholm',
    '127 38': 'Stockholm',
    '127 39': 'Stockholm',
    '127 40': 'Stockholm',
    '127 41': 'Vårberg',
    '127 42': 'Stockholm',
    '127 43': 'Stockholm',
    '127 44': 'Stockholm',
    '127 45': 'Stockholm',
    '127 46': 'Skärholmen',
    '127 47': 'Stockholm',
    '127 48': 'Stockholm',
    '127 49': 'Stockholm',
    '127 51': 'Stockholm',
    '127 60': 'Stockholm',
    '127 61': 'Stockholm',
    '128 30': 'Flaten',
    '128 31': 'Stockholm',
    '128 32': 'Stockholm',
    '128 33': 'Stockholm',
    '128 34': 'Stockholm',
    '128 35': 'Stockholm',
    '128 36': 'Skarpnäcks gård',
    '128 38': 'Stockholm',
    '128 39': 'Stockholm',
    '128 41': 'Stockholm',
    '128 42': 'Stockholm',
    '128 43': 'Stockholm',
    '128 44': 'Stockholm',
    '128 45': 'Bagarmossen',
    '128 46': 'Stockholm',
    '128 47': 'Stockholm',
    '128 48': 'Stockholm',
    '128 62': 'Stockholm',
    '128 63': 'Stockholm',
    '128 64': 'Stockholm',
    '128 65': 'Orhem',
    '128 66': 'Stockholm',
    '128 67': 'Sköndal',
    '128 68': 'Stockholm',
    '128 69': 'Stockholm',
    '129 30': 'Stockholm',
    '129 31': 'Stockholm',
    '129 32': 'Hägerstensåsen',
    '129 33': 'Stockholm',
    '129 34': 'Stockholm',
    '129 35': 'Stockholm',
    '129 36': 'Stockholm',
    '129 37': 'Stockholm',
    '129 38': 'Hägersten',
    '129 39': 'Stockholm',
    '129 40': 'Stockholm',
    '129 41': 'Stockholm',
    '129 42': 'Mälarhöjden',
    '129 43': 'Stockholm',
    '129 44': 'Stockholm',
    '129 45': 'Stockholm',
    '129 46': 'Stockholm',
    '129 47': 'Stockholm',
    '129 48': 'Västertorp',
    '129 49': 'Stockholm',
    '129 50': 'Stockholm',
    '129 51': 'Stockholm',
    '129 52': 'Fruängen',
    '129 53': 'Stockholm',
    '129 54': 'Stockholm',
    '129 55': 'Stockholm',
    '129 56': 'Stockholm',
    '129 57': 'Stockholm',
    '130 25': 'Österåker',
    '130 33': 'Värmdö',
    '130 34': 'Värmdö',
    '130 36': 'Värmdö',
    '130 38': 'Värmdö',
    '130 39': 'Sandhamn',
    '130 41': 'Värmdö',
    '130 42': 'Värmdö',
    '130 43': 'Berg',
    '130 44': 'Värmdö',
    '130 45': 'Haninge',
    '130 55': 'Lättinge',
    '130 56': 'Haninge',
    '131 30': 'Nacka',
    '131 31': 'Kvarnholmen',
    '131 32': 'Henriksdal',
    '131 33': 'Hästhagen',
    '131 34': 'Nacka',
    '131 35': 'Alphyddan',
    '131 36': 'Nacka',
    '131 37': 'Finntorp',
    '131 38': 'Nacka',
    '131 39': 'Nacka',
    '131 40': 'Nacka',
    '131 41': 'Fannydal',
    '131 42': 'Skogalund',
    '131 44': 'Saltängen',
    '131 45': 'Nacka',
    '131 46': 'Skuru',
    '131 47': 'Ektorp',
    '131 48': 'Jarlaberg',
    '131 49': 'Nacka',
    '131 50': 'Saltsjö-Duvnäs',
    '131 52': 'Nacka Strand',
    '131 53': 'Nacka',
    '131 54': 'Sickla',
    '131 55': 'Stockholm',
    '131 60': 'Nacka',
    '131 61': 'Nacka',
    '131 65': 'Nacka',
    '131 71': 'Danviken',
    '131 72': 'Nacka',
    '132 30': 'Nacka',
    '132 31': 'Nacka',
    '132 32': 'Orminge',
    '132 33': 'Nacka',
    '132 34': 'Nacka',
    '132 35': 'Boo',
    '132 36': 'Kil',
    '132 37': 'Kummelnäs',
    '132 38': 'Nacka',
    '132 39': 'Rensättra',
    '132 40': 'Nacka',
    '132 41': 'Nacka',
    '132 42': 'Eknäs',
    '132 43': 'Kocktorp',
    '132 44': 'Nacka',
    '132 45': 'Nacka',
    '132 46': 'Lännersta',
    '132 47': 'Nacka',
    '132 48': 'Björknäs',
    '132 49': 'Tollare',
    '132 51': 'Backeböl',
    '132 52': 'Nacka',
    '133 31': 'Nacka',
    '133 32': 'Nacka',
    '133 33': 'Nacka',
    '133 34': 'Nacka',
    '133 35': 'Saltsjöbaden',
    '133 36': 'Nacka',
    '133 37': 'Älgö',
    '133 38': 'Nacka',
    '133 39': 'Nacka',
    '133 41': 'Nacka',
    '133 42': 'Fisksätra',
    '133 43': 'Nacka',
    '133 44': 'Nacka',
    '133 48': 'Nacka',
    '133 49': 'Nacka',
    '134 30': 'Värmdö',
    '134 31': 'Höjdhagen',
    '134 32': 'Norra Lagnö',
    '134 33': 'Björnkärret',
    '134 34': 'Lugnet',
    '134 35': 'Värmdö',
    '134 36': 'Värmdö',
    '134 37': 'Munkmora',
    '134 38': 'Betsede',
    '134 39': 'Charlottendal',
    '134 40': 'Hålludden',
    '134 41': 'Hästhagen',
    '134 42': 'Ösbydalen',
    '134 43': 'Värmdö',
    '134 44': 'Mölnvik',
    '134 50': 'Värmdö',
    '134 51': 'Värmdö',
    '134 52': 'Värmdö',
    '134 53': 'Värmdö',
    '134 60': 'Brunn',
    '134 61': 'Lillängsdal',
    '134 62': 'Fågelvikshöjden',
    '134 63': 'Värmdö',
    '134 64': 'Återvall',
    '134 65': 'Hanskroka',
    '134 66': 'Evlinge och Ramsdalen',
    '134 67': 'Björnömalmen och Klacknäset',
    '134 69': 'Mörtviken',
    '134 92': 'Vaxholm',
    '135 30': 'Tyresö',
    '135 31': 'Tyresö',
    '135 33': 'Tyresö',
    '135 34': 'Tyresö',
    '135 35': 'Krusboda',
    '135 36': 'Tyresö',
    '135 37': 'Tyresö',
    '135 38': 'Tyresö',
    '135 39': 'Tyresö',
    '135 40': 'Tyresö',
    '135 41': 'Tyresö',
    '135 42': 'Fårdala',
    '135 43': 'Tyresö',
    '135 44': 'Tyresö',
    '135 45': 'Bollmora',
    '135 46': 'Tyresö',
    '135 47': 'Tyresö',
    '135 48': 'Tyresö',
    '135 49': 'Tyresö',
    '135 50': 'Tyresö',
    '135 51': 'Trollbäcken',
    '135 52': 'Tyresö',
    '135 53': 'Tyresö',
    '135 54': 'Tyresö',
    '135 55': 'Tyresö',
    '135 58': 'Tyresö',
    '135 59': 'Skrubba',
    '135 60': 'Tyresö',
    '135 61': 'Tyresö',
    '135 62': 'Tyresö',
    '135 63': 'Tyresö strand',
    '135 67': 'Raksta',
    '135 68': 'Brevikshalvön',
    '135 69': 'Brevik',
    '135 70': 'Stockholm',
    '136 35': 'Haninge',
    '136 36': 'Haninge',
    '136 37': 'Haninge',
    '136 38': 'Haninge',
    '136 39': 'Haninge',
    '136 40': 'Haninge',
    '136 41': 'Haninge',
    '136 42': 'Haninge',
    '136 43': 'Handen',
    '136 44': 'Haninge',
    '136 45': 'Haninge',
    '136 46': 'Haninge',
    '136 47': 'Kvarntorp',
    '136 48': 'Haninge',
    '136 49': 'Söderhagen',
    '136 50': 'Haninge',
    '136 58': 'Haninge',
    '136 59': 'Svartbäcken',
    '136 60': 'Brandbergen',
    '136 61': 'Haninge',
    '136 62': 'Haninge',
    '136 63': 'Haninge',
    '136 64': 'Haninge',
    '136 65': 'Söderby',
    '136 66': 'Haninge',
    '136 67': 'Vendelsömalm',
    '136 68': 'Ramsdalen',
    '136 69': 'Haninge',
    '136 70': 'Vendelsö',
    '136 71': 'Sågen',
    '136 72': 'Lötkärr',
    '136 73': 'Haninge',
    '136 74': 'Norrby',
    '136 75': 'Haninge',
    '137 31': 'Haninge',
    '137 32': 'Haninge',
    '137 34': 'Haninge',
    '137 36': 'Haninge',
    '137 37': 'Haninge',
    '137 38': 'Västerhaninge',
    '137 39': 'Haninge',
    '137 40': 'Haninge',
    '137 41': 'Haninge',
    '137 42': 'Haninge',
    '137 43': 'Haninge',
    '137 54': 'Haninge',
    '137 55': 'Krigslida',
    '137 56': 'Tungelsta',
    '137 57': 'Lillgården',
    '137 61': 'Haninge',
    '137 62': 'Haninge',
    '137 63': 'Haninge',
    '137 64': 'Jordbro',
    '137 65': 'Haninge',
    '137 66': 'Haninge',
    '137 67': 'Haninge',
    '137 68': 'Haninge',
    '137 70': 'Dalarö',
    '137 71': 'Haninge',
    '137 91': 'Haninge',
    '137 92': 'Haninge',
    '137 93': 'Väländan',
    '137 94': 'Norrudden',
    '137 95': 'Haninge',
    '137 96': 'Haninge',
    '137 97': 'Årsta havsbad',
    '138 30': 'Älta',
    '138 31': 'Nacka',
    '138 32': 'Stensö',
    '138 33': 'Kolarängen',
    '138 34': 'Nacka',
    '138 35': 'Nacka',
    '138 36': 'Nacka',
    '138 37': 'Nacka',
    '138 39': 'Nacka',
    '138 40': 'Nacka',
    '139 30': 'Värmdö',
    '139 31': 'Hemmesta',
    '139 32': 'Värmdö',
    '139 33': 'Värmdö',
    '139 34': 'Haghulta',
    '139 35': 'Hagaberg',
    '139 36': 'Mörtnäs',
    '139 40': 'Skeppsdalsström',
    '139 41': 'Strömma',
    '139 50': 'Ängsvik',
    '139 51': 'Torsby',
    '139 52': 'Värmdö',
    '139 53': 'Kopparmora',
    '139 54': 'Skärmarö',
    '139 55': 'Värmdö',
    '139 56': 'Bullandö',
    '139 60': 'Långstrandsudd',
    '139 70': 'Stavsnäs',
    '139 71': 'Värmdö',
    '139 72': 'Värmdö',
    '139 73': 'Björkås',
    '139 74': 'Värmdö',
    '139 75': 'Värmdö',
    '139 90': 'Stenslätten',
    '141 30': 'Huddinge',
    '141 31': 'Huddinge',
    '141 32': 'Glömsta',
    '141 33': 'Solgård',
    '141 34': 'Balingsnäs',
    '141 35': 'Sjödalen-Fullersta',
    '141 37': 'Huddinge',
    '141 38': 'Huddinge',
    '141 39': 'Huddinge',
    '141 40': 'Huddinge',
    '141 41': 'Huddinge',
    '141 42': 'Huddinge',
    '141 43': 'Stuvsta-Snättringe',
    '141 44': 'Huddinge',
    '141 45': 'Huddinge',
    '141 46': 'Huddinge',
    '141 47': 'Huddinge',
    '141 48': 'Huddinge',
    '141 49': 'Huddinge',
    '141 50': 'Visättra',
    '141 51': 'Huddinge',
    '141 52': 'Flemingsberg',
    '141 53': 'Huddinge',
    '141 54': 'Huddinge',
    '141 55': 'Huddinge',
    '141 56': 'Huddinge',
    '141 57': 'Huddinge',
    '141 58': 'Huddinge',
    '141 59': 'Kästa',
    '141 60': 'Sörskogen',
    '141 61': 'Huddinge',
    '141 62': 'Huddinge',
    '141 63': 'Huddinge',
    '141 68': 'Huddinge',
    '141 69': 'Vistaberg',
    '141 70': 'Segeltorp',
    '141 71': 'Huddinge',
    '141 72': 'Huddinge',
    '141 73': 'Huddinge',
    '141 74': 'Huddinge',
    '141 75': 'Kungens kurva',
    '141 91': 'Gladö kvarn',
    '141 92': 'Västra Balingsnäs',
    '142 30': 'Skogås',
    '142 31': 'Huddinge',
    '142 32': 'Huddinge',
    '142 33': 'Huddinge',
    '142 34': 'Huddinge',
    '142 35': 'Huddinge',
    '142 40': 'Huddinge',
    '142 41': 'Drevviksstrand',
    '142 42': 'Huddinge',
    '142 43': 'Huddinge',
    '142 50': 'Huddinge',
    '142 51': 'Huddinge',
    '142 52': 'Länna',
    '142 53': 'Huddinge',
    '142 60': 'Huddinge',
    '142 61': 'Huddinge',
    '142 62': 'Huddinge',
    '142 63': 'Huddinge',
    '142 64': 'Huddinge',
    '142 65': 'Trångsund',
    '142 66': 'Fållan',
    '142 91': 'Huddinge',
    '143 30': 'Masmo',
    '143 31': 'Huddinge',
    '143 32': 'Huddinge',
    '143 40': 'Huddinge',
    '143 41': 'Huddinge',
    '143 42': 'Huddinge',
    '143 43': 'Huddinge',
    '143 44': 'Huddinge',
    '143 45': 'Huddinge',
    '143 46': 'Vårby',
    '143 47': 'Huddinge',
    '144 30': 'Salem',
    '144 31': 'Salem',
    '144 32': 'Salem',
    '144 33': 'Salem',
    '144 37': 'Salem',
    '144 40': 'Salem',
    '144 42': 'Salem',
    '144 44': 'Salem',
    '144 51': 'Salem',
    '144 52': 'Salem',
    '144 61': 'Rönninge',
    '144 62': 'Salem',
    '144 63': 'Salem',
    '144 64': 'Salem',
    '145 50': 'Botkyrka',
    '145 51': 'Botkyrka',
    '145 52': 'Botkyrka',
    '145 53': 'Botkyrka',
    '145 56': 'Botkyrka',
    '145 57': 'Alby',
    '145 58': 'Botkyrka',
    '145 59': 'Botkyrka',
    '145 60': 'Botkyrka',
    '145 63': 'Botkyrka',
    '145 64': 'Botkyrka',
    '145 65': 'Botkyrka',
    '145 67': 'Brunna',
    '145 68': 'Botkyrka',
    '145 69': 'Hallunda',
    '145 70': 'Botkyrka',
    '145 71': 'Botkyrka',
    '145 72': 'Botkyrka',
    '145 73': 'Botkyrka',
    '145 74': 'Slagsta',
    '145 75': 'Botkyrka',
    '145 76': 'Botkyrka',
    '145 90': 'Norsborg',
    '146 30': 'Tullinge',
    '146 31': 'Botkyrka',
    '146 32': 'Botkyrka',
    '146 33': 'Lida',
    '146 34': 'Riksten Friluftsstaden',
    '146 36': 'Botkyrka',
    '146 37': 'Botkyrka',
    '146 38': 'Botkyrka',
    '146 40': 'Botkyrka',
    '146 41': 'Botkyrka',
    '146 45': 'Botkyrka',
    '146 46': 'Botkyrka',
    '146 48': 'Botkyrka',
    '146 49': 'Botkyrka',
    '146 50': 'Botkyrka',
    '146 52': 'Botkyrka',
    '146 53': 'Botkyrka',
    '146 54': 'Botkyrka',
    '147 30': 'Botkyrka',
    '147 31': 'Botkyrka',
    '147 32': 'Broängen',
    '147 33': 'Kassmyra',
    '147 34': 'Skäcklinge',
    '147 39': 'Botkyrka',
    '147 40': 'Tuna',
    '147 41': 'Botkyrka',
    '147 42': 'Nackdala',
    '147 43': 'Vretarna',
    '147 44': 'Botkyrka',
    '147 45': 'Botkyrka',
    '147 46': 'Botkyrka',
    '147 50': 'Storvreten',
    '147 51': 'Botkyrka',
    '147 52': 'Botkyrka',
    '147 53': 'Botkyrka',
    '147 54': 'Botkyrka',
    '147 55': 'Lövholmen',
    '147 60': 'Botkyrka',
    '147 61': 'Botkyrka',
    '147 62': 'Botkyrka',
    '147 63': 'Botkyrka',
    '147 70': 'Bremora',
    '147 71': 'Botkyrka',
    '147 91': 'Botkyrka',
    '147 92': 'Sibble',
    '148 30': 'Ösmo',
    '148 31': 'Nynäshamn',
    '148 32': 'Nynäshamn',
    '148 33': 'Nynäshamn',
    '148 34': 'Nynäshamn',
    '148 35': 'Nynäshamn',
    '148 40': 'Segersäng',
    '148 60': 'Stora Vika',
    '148 70': 'Sorunda',
    '148 71': 'Sunnerby',
    '148 91': 'Lidatorp och Klövsta',
    '148 92': 'Nynäshamn',
    '148 93': 'Landfjärden',
    '148 95': 'Muskö',
    '148 96': 'Ovelund',
    '148 97': 'Sandvik',
    '149 30': 'Nynäshamn',
    '149 31': 'Nynäshamn',
    '149 32': 'Nynäshamn',
    '149 33': 'Nynäshamn',
    '149 34': 'Nynäshamn',
    '149 35': 'Nynäshamn',
    '149 40': 'Nynäshamn',
    '149 41': 'Nynäshamn',
    '149 43': 'Nynäshamn',
    '149 45': 'Nynäshamn',
    '149 50': 'Nynäshamn',
    '149 51': 'Nynäshamn',
    '149 91': 'Nynäshamn',
    '149 92': 'Nynäshamn',
    '149 95': 'Nynäshamn',
    '151 32': 'Södertälje',
    '151 33': 'Södertälje',
    '151 34': 'Södertälje',
    '151 35': 'Södertälje',
    '151 36': 'Södertälje',
    '151 37': 'Södertälje',
    '151 38': 'Södra',
    '151 39': 'Pershagen',
    '151 40': 'Södertälje',
    '151 44': 'Södertälje',
    '151 45': 'Södertälje',
    '151 46': 'Södertälje',
    '151 47': 'Saltskog',
    '151 48': 'Södertälje',
    '151 50': 'Ekeby',
    '151 51': 'Ronna',
    '151 52': 'Södertälje',
    '151 53': 'Södertälje',
    '151 54': 'Södertälje',
    '151 55': 'Södertälje',
    '151 56': 'Södertälje',
    '151 57': 'Vattubrinken',
    '151 59': 'Geneta',
    '151 60': 'Södertälje',
    '151 61': 'Södertälje',
    '151 62': 'Södertälje',
    '151 63': 'Södertälje',
    '151 64': 'Södertälje',
    '151 65': 'Tveta',
    '151 66': 'Södertälje',
    '151 67': 'Södertälje',
    '151 68': 'Södertälje',
    '151 71': 'Södertälje',
    '151 72': 'Södertälje',
    '151 73': 'Södertälje',
    '151 96': 'Södertälje',
    '152 30': 'Södertälje',
    '152 31': 'Rosenlund',
    '152 40': 'Södertälje',
    '152 41': 'Grusåsen',
    '152 42': 'Moraberg',
    '152 43': 'Södertälje',
    '152 44': 'Södertälje',
    '152 48': 'Brunnsäng',
    '152 49': 'Södertälje',
    '152 50': 'Södertälje',
    '152 51': 'Södertälje',
    '152 52': 'Södertälje',
    '152 54': 'Viksäter',
    '152 56': 'Östertälje',
    '152 57': 'Södertälje',
    '152 58': 'Södertälje',
    '152 59': 'Södertälje',
    '152 70': 'Södertälje',
    '152 71': 'Södertälje',
    '152 95': 'Salem',
    '152 97': 'Södertälje',
    '153 30': 'Järna',
    '153 31': 'Södertälje',
    '153 32': 'Södertälje',
    '153 35': 'Södertälje',
    '153 36': 'Södertälje',
    '153 37': 'Södertälje',
    '153 38': 'Östra Kallfors',
    '153 60': 'Mölnbo',
    '153 71': 'Hölö',
    '153 91': 'Södertälje',
    '153 92': 'Södertälje',
    '153 93': 'Södertälje',
    '153 94': 'Södertälje',
    '153 95': 'Södertälje',
    '153 96': 'Södertälje',
    '155 30': 'Nykvarn',
    '155 31': 'Nykvarn',
    '155 32': 'Nykvarn',
    '155 33': 'Nykvarn',
    '155 34': 'Nykvarn',
    '155 35': 'Nykvarn',
    '155 91': 'Nykvarn',
    '155 92': 'Almnäs',
    '155 93': 'Finkarby',
    '155 94': 'Nykvarn',
    '162 43': 'Stockholm',
    '162 44': 'Stockholm',
    '162 45': 'Stockholm',
    '162 46': 'Kälvesta',
    '162 47': 'Stockholm',
    '162 50': 'Vinsta',
    '162 52': 'Stockholm',
    '162 53': 'Råcksta',
    '162 54': 'Stockholm',
    '162 55': 'Stockholm',
    '162 56': 'Stockholm',
    '162 57': 'Grimsta',
    '162 58': 'Stockholm',
    '162 60': 'Stockholm',
    '162 61': 'Stockholm',
    '162 62': 'Stockholm',
    '162 63': 'Stockholm',
    '162 64': 'Stockholm',
    '162 65': 'Vällingby',
    '162 66': 'Stockholm',
    '162 67': 'Stockholm',
    '162 68': 'Stockholm',
    '162 70': 'Stockholm',
    '162 71': 'Stockholm',
    '162 72': 'Stockholm',
    '162 73': 'Stockholm',
    '162 74': 'Stockholm',
    '163 40': 'Stockholm',
    '163 41': 'Bromsten',
    '163 42': 'Stockholm',
    '163 43': 'Stockholm',
    '163 44': 'Sundby',
    '163 45': 'Flysta',
    '163 46': 'Stockholm',
    '163 47': 'Nälsta',
    '163 50': 'Stockholm',
    '163 51': 'Solhem',
    '163 52': 'Stockholm',
    '163 53': 'Lunda industriområde',
    '163 54': 'Stockholm',
    '163 55': 'Stockholm',
    '163 56': 'Stockholm',
    '163 61': 'Stockholm',
    '163 62': 'Stockholm',
    '163 63': 'Stockholm',
    '163 64': 'Tensta',
    '163 65': 'Stockholm',
    '163 66': 'Stockholm',
    '163 67': 'Stockholm',
    '163 68': 'Stockholm',
    '163 70': 'Stockholm',
    '163 71': 'Stockholm',
    '163 72': 'Stockholm',
    '163 73': 'Rinkeby',
    '163 74': 'Stockholm',
    '163 75': 'Stockholm',
    '164 30': 'Stockholm',
    '164 31': 'Stockholm',
    '164 32': 'Husby',
    '164 33': 'Stockholm',
    '164 34': 'Stockholm',
    '164 35': 'Stockholm',
    '164 36': 'Stockholm',
    '164 37': 'Stockholm',
    '164 38': 'Stockholm',
    '164 39': 'Stockholm',
    '164 40': 'Stockholm',
    '164 41': 'Stockholm',
    '164 42': 'Stockholm',
    '164 43': 'Stockholm',
    '164 44': 'Stockholm',
    '164 45': 'Stockholm',
    '164 46': 'Stockholm',
    '164 47': 'Stockholm',
    '164 48': 'Stockholm',
    '164 51': 'Stockholm',
    '164 52': 'Stockholm',
    '164 53': 'Kista',
    '164 55': 'Stockholm',
    '164 71': 'Stockholm',
    '164 72': 'Stockholm',
    '164 73': 'Stockholm',
    '164 74': 'Stockholm',
    '164 75': 'Akalla',
    '164 76': 'Stockholm',
    '164 77': 'Stockholm',
    '164 78': 'Stockholm',
    '164 79': 'Stockholm',
    '164 91': 'Stockholm',
    '165 52': 'Stockholm',
    '165 53': 'Stockholm',
    '165 54': 'Stockholm',
    '165 55': 'Hässelby gård',
    '165 56': 'Stockholm',
    '165 57': 'Stockholm',
    '165 58': 'Stockholm',
    '165 59': 'Stockholm',
    '165 60': 'Stockholm',
    '165 61': 'Stockholm',
    '165 62': 'Stockholm',
    '165 63': 'Hässelby strand',
    '165 64': 'Stockholm',
    '165 65': 'Stockholm',
    '165 66': 'Stockholm',
    '165 70': 'Stockholm',
    '165 71': 'Stockholm',
    '165 72': 'Stockholm',
    '165 73': 'Stockholm',
    '165 74': 'Stockholm',
    '165 75': 'Hässelby Villastad',
    '165 76': 'Stockholm',
    '165 77': 'Stockholm',
    '165 78': 'Stockholm',
    '167 31': 'Stockholm',
    '167 32': 'Ulvsunda',
    '167 33': 'Stockholm',
    '167 35': 'Stockholm',
    '167 36': 'Stockholm',
    '167 37': 'Traneberg',
    '167 38': 'Stockholm',
    '167 39': 'Stockholm',
    '167 40': 'Stockholm',
    '167 41': 'Stockholm',
    '167 43': 'Stockholm',
    '167 44': 'Stockholm',
    '167 45': 'Stockholm',
    '167 51': 'Stockholm',
    '167 52': 'Alvik',
    '167 53': 'Stockholm',
    '167 54': 'Äppelviken',
    '167 55': 'Stockholm',
    '167 56': 'Stockholm',
    '167 57': 'Stockholm',
    '167 58': 'Stora mossen',
    '167 61': 'Stockholm',
    '167 62': 'Stockholm',
    '167 63': 'Smedslätten',
    '167 64': 'Stockholm',
    '167 65': 'Stockholm',
    '167 66': 'Ålsten',
    '167 67': 'Stockholm',
    '167 71': 'Höglandet',
    '167 72': 'Olovslund',
    '167 73': 'Stockholm',
    '167 74': 'Stockholm',
    '167 75': 'Stockholm',
    '167 76': 'Nockeby',
    '168 30': 'Stockholm',
    '168 31': 'Stockholm',
    '168 32': 'Abrahamsberg',
    '168 33': 'Stockholm',
    '168 34': 'Stockholm',
    '168 35': 'Stockholm',
    '168 36': 'Åkeslund',
    '168 37': 'Stockholm',
    '168 38': 'Åkeshov',
    '168 39': 'Nockebyhov',
    '168 40': 'Stockholm',
    '168 41': 'Stockholm',
    '168 43': 'Stockholm',
    '168 44': 'Stockholm',
    '168 45': 'Blackeberg',
    '168 46': 'Stockholm',
    '168 47': 'Stockholm',
    '168 48': 'Stockholm',
    '168 49': 'Stockholm',
    '168 50': 'Södra Ängby',
    '168 51': 'Stockholm',
    '168 52': 'Stockholm',
    '168 53': 'Stockholm',
    '168 54': 'Stockholm',
    '168 55': 'Stockholm',
    '168 56': 'Norra Ängby',
    '168 57': 'Stockholm',
    '168 58': 'Stockholm',
    '168 59': 'Bällsta',
    '168 60': 'Stockholm',
    '168 61': 'Stockholm',
    '168 62': 'Stockholm',
    '168 63': 'Stockholm',
    '168 64': 'Stockholm',
    '168 65': 'Stockholm',
    '168 66': 'Mariehäll',
    '168 67': 'Ulvsunda industriområde',
    '168 68': 'Stockholm',
    '168 69': 'Stockholm',
    '168 70': 'Stockholm',
    '168 71': 'Stockholm',
    '168 72': 'Stockholm',
    '168 73': 'Stockholm',
    '168 74': 'Riksby',
    '168 75': 'Stockholm',
    '168 76': 'Bromma',
    '168 77': 'Stockholm',
    '169 30': 'Solna',
    '169 31': 'Solna',
    '169 32': 'Solna',
    '169 33': 'Solna',
    '169 34': 'Solna',
    '169 35': 'Solna',
    '169 36': 'Solna',
    '169 37': 'Solna',
    '169 38': 'Solna',
    '169 39': 'Solna',
    '169 40': 'Solna',
    '169 50': 'Solna',
    '169 51': 'Solna',
    '169 52': 'Solna',
    '169 53': 'Solna',
    '169 54': 'Råsunda',
    '169 55': 'Solna',
    '169 56': 'Solna',
    '169 57': 'Solna',
    '169 58': 'Solna',
    '169 59': 'Solna',
    '169 60': 'Solna',
    '169 61': 'Solna',
    '169 62': 'Solna',
    '169 63': 'Solna',
    '169 64': 'Solna',
    '169 65': 'Solna',
    '169 66': 'Hagalund',
    '169 67': 'Solna',
    '169 68': 'Solna',
    '169 69': 'Solna',
    '169 70': 'Solna',
    '169 71': 'Solna',
    '169 72': 'Solna',
    '169 73': 'Frösunda',
    '169 74': 'Solna',
    '169 79': 'Solna',
    '170 62': 'Järvastaden',
    '170 63': 'Solna',
    '170 65': 'Bagartorp',
    '170 66': 'Solna',
    '170 67': 'Norra Ritorp',
    '170 68': 'Solna',
    '170 69': 'Solna',
    '170 70': 'Solna',
    '170 71': 'Solna',
    '170 72': 'Solna',
    '170 73': 'Solna',
    '170 74': 'Solna',
    '170 75': 'Solna',
    '170 76': 'Bergshamra',
    '170 77': 'Solna',
    '170 78': 'Solna',
    '170 79': 'Solna',
    '171 41': 'Solna',
    '171 42': 'Solna',
    '171 43': 'Solna',
    '171 44': 'Solna',
    '171 45': 'Solna',
    '171 47': 'Solna',
    '171 48': 'Solna',
    '171 49': 'Huvudsta',
    '171 50': 'Solna',
    '171 51': 'Solna',
    '171 52': 'Solna',
    '171 53': 'Solna',
    '171 54': 'Solna',
    '171 55': 'Solna',
    '171 56': 'Solna',
    '171 57': 'Solna',
    '171 58': 'Solna',
    '171 59': 'Solna',
    '171 60': 'Solna',
    '171 61': 'Solna',
    '171 62': 'Solna',
    '171 63': 'Solna',
    '171 64': 'Solna Kyrkby',
    '171 65': 'Solna',
    '171 66': 'Solna',
    '171 67': 'Solna',
    '171 68': 'Solna',
    '171 69': 'Solna',
    '171 70': 'Solna',
    '171 71': 'Solna',
    '171 72': 'Västra skogen',
    '171 73': 'Solna',
    '172 30': 'Sundbyberg',
    '172 31': 'Sundbyberg',
    '172 32': 'Sundbyberg',
    '172 33': 'Sundbyberg',
    '172 34': 'Sundbyberg',
    '172 35': 'Sundbyberg',
    '172 36': 'Sundbyberg',
    '172 37': 'Sundbyberg',
    '172 38': 'Sundbyberg',
    '172 39': 'Sundbyberg',
    '172 40': 'Sundbyberg',
    '172 41': 'Sundbyberg',
    '172 62': 'Sundbyberg',
    '172 63': 'Sundbyberg',
    '172 64': 'Sundbyberg',
    '172 65': 'Sundbyberg',
    '172 66': 'Lilla Alby',
    '172 67': 'Sundbyberg',
    '172 68': 'Centrala Sundbyberg',
    '172 69': 'Sundbyberg',
    '172 70': 'Sundbyberg',
    '172 71': 'Sundbyberg',
    '172 72': 'Sundbyberg',
    '172 73': 'Sundbyberg',
    '172 74': 'Sundbyberg',
    '172 75': 'Sundbyberg',
    '172 76': 'Duvbo',
    '172 77': 'Sundbyberg',
    '172 78': 'Sundbyberg',
    '172 79': 'Storskogen',
    '174 41': 'Sundbyberg',
    '174 42': 'Sundbyberg',
    '174 43': 'Sundbyberg',
    '174 44': 'Sundbyberg',
    '174 45': 'Sundbyberg',
    '174 46': 'Lilla Ursvik',
    '174 47': 'Sundbyberg',
    '174 48': 'Sundbyberg',
    '174 49': 'Sundbyberg',
    '174 50': 'Ör',
    '174 51': 'Sundbyberg',
    '174 52': 'Hallonbergen',
    '174 53': 'Sundbyberg',
    '174 54': 'Sundbyberg',
    '174 55': 'Sundbyberg',
    '174 56': 'Sundbyberg',
    '174 57': 'Rissne',
    '174 58': 'Sundbyberg',
    '174 59': 'Sundbyberg',
    '174 60': 'Sundbyberg',
    '174 61': 'Sundbyberg',
    '174 62': 'Sundbyberg',
    '174 63': 'Stora Ursvik',
    '174 64': 'Sundbyberg',
    '174 66': 'Sundbyberg',
    '175 41': 'Järfälla',
    '175 43': 'Järfälla',
    '175 44': 'Järfälla',
    '175 45': 'Järfälla',
    '175 46': 'Järfälla',
    '175 47': 'Järfälla',
    '175 48': 'Järfälla',
    '175 49': 'Järfälla',
    '175 50': 'Järfälla',
    '175 51': 'Järfälla',
    '175 52': 'Järfälla',
    '175 53': 'Viksjö',
    '175 54': 'Järfälla',
    '175 55': 'Järfälla',
    '175 56': 'Järfälla',
    '175 57': 'Järfälla',
    '175 60': 'Järfälla',
    '175 61': 'Järfälla',
    '175 62': 'Veddesta',
    '175 63': 'Järfälla',
    '175 64': 'Barkarby',
    '175 65': 'Järfälla',
    '175 66': 'Järfälla',
    '175 67': 'Järfälla',
    '175 68': 'Skälby',
    '175 69': 'Järfälla',
    '176 68': 'Järfälla',
    '176 69': 'Järfälla',
    '176 70': 'Järfälla',
    '176 71': 'Kallhäll',
    '176 72': 'Järfälla',
    '176 73': 'Järfälla',
    '176 74': 'Villastaden',
    '176 75': 'Järfälla',
    '176 76': 'Stäket',
    '176 77': 'Järfälla',
    '177 30': 'Jakobsberg',
    '177 31': 'Järfälla',
    '177 32': 'Järfälla',
    '177 33': 'Järfälla',
    '177 34': 'Järfälla',
    '177 35': 'Söderhöjden',
    '177 36': 'Järfälla',
    '177 37': 'Järfälla',
    '177 38': 'Järfälla',
    '177 39': 'Järfälla',
    '177 40': 'Järfälla',
    '177 41': 'Järfälla',
    '177 42': 'Järfälla',
    '177 43': 'Järfälla',
    '177 44': 'Järfälla',
    '177 45': 'Järfälla',
    '177 46': 'Järfälla',
    '177 50': 'Järfälla',
    '177 51': 'Järfälla',
    '177 52': 'Järfälla',
    '177 53': 'Järfälla',
    '177 54': 'Järfälla',
    '177 55': 'Järfälla',
    '177 56': 'Järfälla',
    '177 57': 'Järfälla',
    '177 58': 'Järfälla',
    '177 59': 'Järfälla',
    '177 60': 'Järfälla',
    '177 61': 'Västra Jakobsberg',
    '177 62': 'Järfälla',
    '177 63': 'Järfälla',
    '177 64': 'Järfälla',
    '177 70': 'Järfälla',
    '177 71': 'Järfälla',
    '178 30': 'Ekerö',
    '178 31': 'Ekerö',
    '178 32': 'Ekerö',
    '178 33': 'Ekerö',
    '178 34': 'Ekerö',
    '178 35': 'Ekerö',
    '178 36': 'Ekerö',
    '178 37': 'Ekerö',
    '178 38': 'Ekerö',
    '178 39': 'Älvnäs',
    '178 40': 'Ekerö',
    '178 41': 'Ekerö',
    '178 51': 'Solsidan',
    '178 52': 'Ekerö sommarstad',
    '178 53': 'Sundby',
    '178 54': 'Lurudden',
    '178 90': 'Ekerö',
    '178 91': 'Ekeby',
    '178 92': 'Lilla Stenby',
    '178 93': 'Drottningholm',
    '179 60': 'Stenhamra',
    '179 61': 'Ekerö',
    '179 62': 'Ekerö',
    '179 63': 'Ekerö',
    '179 65': 'Ekerö',
    '179 75': 'Tureholm',
    '179 95': 'Ekerö',
    '179 96': 'Sockarby',
    '179 97': 'Kungsberga',
    '179 98': 'Ölsta',
    '181 29': 'Sticklinge',
    '181 30': 'Lidingö',
    '181 31': 'Islinge',
    '181 32': 'Lidingö',
    '181 33': 'Lidingö',
    '181 34': 'Lidingö',
    '181 35': 'Baggeby',
    '181 36': 'Lidingö',
    '181 37': 'Lidingö',
    '181 38': 'Lidingö',
    '181 39': 'Larsberg',
    '181 40': 'Lidingö',
    '181 41': 'Stockby',
    '181 42': 'Hersby',
    '181 43': 'Bo',
    '181 44': 'Näset',
    '181 45': 'Lidingö',
    '181 46': 'Björnbo',
    '181 47': 'Rudboda',
    '181 48': 'Lidingö',
    '181 50': 'Lidingö',
    '181 51': 'Herserud',
    '181 55': 'Lidingö',
    '181 56': 'Lidingö',
    '181 57': 'Kottla',
    '181 58': 'Högsätra',
    '181 59': 'Lidingö',
    '181 60': 'Skärsätra',
    '181 61': 'Lidingö',
    '181 62': 'Brevik',
    '181 63': 'Käppala',
    '181 64': 'Killinge',
    '181 65': 'Lidingö',
    '181 66': 'Lidingö',
    '181 67': 'Lidingö',
    '181 90': 'Hustega',
    '182 30': 'Danderyd',
    '182 31': 'Danderyd',
    '182 32': 'Mörby',
    '182 33': 'Klingsta',
    '182 34': 'Danderyd',
    '182 35': 'Danderyd',
    '182 36': 'Danderyd',
    '182 37': 'Danderyd',
    '182 38': 'Danderyd',
    '182 39': 'Danderyd',
    '182 45': 'Danderyd',
    '182 46': 'Danderyd',
    '182 47': 'Danderyd',
    '182 48': 'Enebyberg',
    '182 49': 'Danderyd',
    '182 50': 'Danderyd',
    '182 52': 'Danderyd',
    '182 53': 'Danderyd',
    '182 54': 'Danderyd',
    '182 55': 'Danderyd',
    '182 56': 'Nora',
    '182 57': 'Danderyd',
    '182 60': 'Danderyd',
    '182 61': 'Danderyd',
    '182 62': 'Danderyd',
    '182 63': 'Danderyd',
    '182 64': 'Danderyd',
    '182 65': 'Danderyd',
    '182 66': 'Danderyd',
    '182 67': 'Danderyd',
    '182 68': 'Danderyd',
    '182 69': 'Djursholm',
    '182 70': 'Tranholmen',
    '182 73': 'Danderyd',
    '182 74': 'Danderyd',
    '182 75': 'Danderyd',
    '182 76': 'Danderyd',
    '182 77': 'Danderyd',
    '182 78': 'Stocksund',
    '182 79': 'Danderyd',
    '182 91': 'Danderyd',
    '183 30': 'Roslags-Näsby',
    '183 31': 'Täby',
    '183 32': 'Täby',
    '183 33': 'Täby',
    '183 34': 'Täby',
    '183 35': 'Täby',
    '183 36': 'Täby',
    '183 37': 'Näsbydal',
    '183 38': 'Täby',
    '183 39': 'Täby',
    '183 47': 'Täby',
    '183 48': 'Grindtorp',
    '183 49': 'Täby',
    '183 50': 'Täby',
    '183 51': 'Täby',
    '183 52': 'Täby',
    '183 53': 'Täby',
    '183 54': 'Täby',
    '183 55': 'Lahäll',
    '183 56': 'Täby',
    '183 57': 'Täby',
    '183 58': 'Näsbypark',
    '183 59': 'Täby',
    '183 60': 'Täby',
    '183 61': 'Hägernäs',
    '183 62': 'Täby',
    '183 63': 'Viggbyholm',
    '183 64': 'Bergtorp',
    '183 65': 'Täby',
    '183 66': 'Österåker',
    '183 67': 'Täby',
    '183 68': 'Hägernäs strand',
    '183 69': 'Täby',
    '183 70': 'Täby',
    '183 71': 'Täby',
    '183 77': 'Täby',
    '183 78': 'Täby',
    '183 79': 'Täby',
    '184 30': 'Österåker',
    '184 31': 'Österåker',
    '184 32': 'Åkersberga',
    '184 33': 'Österåker',
    '184 34': 'Österåker',
    '184 35': 'Österåker',
    '184 36': 'Österåker',
    '184 37': 'Österåker',
    '184 38': 'Österåker',
    '184 39': 'Österåker',
    '184 40': 'Österåker',
    '184 41': 'Österåker',
    '184 42': 'Norrö',
    '184 43': 'Stava',
    '184 44': 'Österåker',
    '184 50': 'Österåker',
    '184 51': 'Österskär',
    '184 52': 'Österåker',
    '184 53': 'Österåker',
    '184 60': 'Österåker',
    '184 61': 'Österåker',
    '184 62': 'Österåker',
    '184 63': 'Österåker',
    '184 64': 'Österåker',
    '184 70': 'Skärgårdsstad',
    '184 91': 'Solberga',
    '184 92': 'Svinninge',
    '184 93': 'Björnhuvud',
    '184 94': 'Rydbo',
    '184 95': 'Åsättra',
    '184 96': 'Österåker',
    '184 97': 'Österåker',
    '184 99': 'Österåker',
    '185 31': 'Vaxholm',
    '185 32': 'Vaxholm',
    '185 33': 'Vaxholm',
    '185 34': 'Vaxholm',
    '185 35': 'Vaxholm',
    '185 37': 'Vaxholm',
    '185 39': 'Kullö',
    '185 41': 'Oskar-Fredriksborg',
    '185 51': 'Vaxholm',
    '185 91': 'Vaxholm',
    '185 93': 'Vaxholm',
    '185 94': 'Ytterby',
    '185 95': 'Vaxholm',
    '185 99': 'Vaxholm',
    '186 30': 'Vallentuna',
    '186 31': 'Vallentuna',
    '186 32': 'Vallentuna',
    '186 33': 'Vallentuna',
    '186 34': 'Ekeby',
    '186 35': 'Ormsta',
    '186 36': 'Vallentuna',
    '186 37': 'Vallentuna',
    '186 38': 'Sörgården',
    '186 39': 'Vallentuna',
    '186 40': 'Vallentuna',
    '186 41': 'Rickeby',
    '186 42': 'Karlberg',
    '186 43': 'Gustavslund',
    '186 44': 'Norrgården',
    '186 45': 'Vallentuna',
    '186 46': 'Vallentuna',
    '186 47': 'Vallentuna',
    '186 48': 'Vallentuna',
    '186 50': 'Bällsta',
    '186 51': 'Kragstalund',
    '186 52': 'Nyborg',
    '186 53': 'Lovisedal',
    '186 54': 'Vallentuna',
    '186 55': 'Bällstaberg',
    '186 56': 'Bällstaberg',
    '186 60': 'Kårsta',
    '186 70': 'Karby',
    '186 91': 'Vallentuna',
    '186 92': 'Johannesudd',
    '186 93': 'Vallentuna',
    '186 94': 'Lindholmen',
    '186 95': 'Frösunda',
    '186 96': 'Ekskogen',
    '186 97': 'Ekskogen',
    '187 30': 'Täby',
    '187 31': 'Ella gård',
    '187 32': 'Täby',
    '187 33': 'Ella park',
    '187 34': 'Täby',
    '187 35': 'Ensta',
    '187 36': 'Täby',
    '187 37': 'Täby',
    '187 38': 'Täby',
    '187 40': 'Täby',
    '187 41': 'Täby',
    '187 42': 'Skarpäng',
    '187 43': 'Täby',
    '187 44': 'Täby',
    '187 45': 'Täby',
    '187 46': 'Täby',
    '187 50': 'Vallabrink',
    '187 51': 'Täby',
    '187 52': 'Vallatorp',
    '187 53': 'Lövbrunna',
    '187 54': 'Visinge',
    '187 61': 'Ullna strand',
    '187 62': 'Täby',
    '187 63': 'Täby',
    '187 64': 'Täby',
    '187 65': 'Gribbylund',
    '187 66': 'Arninge',
    '187 67': 'Täby',
    '187 68': 'Täby',
    '187 69': 'Löttingelund',
    '187 70': 'Karlslund',
    '187 72': 'Runborg',
    '187 73': 'Byle',
    '187 74': 'Täby kyrkby',
    '187 75': 'Täby',
    '187 76': 'Margretelund',
    '187 77': 'Täby',
    '190 60': 'Sigtuna',
    '191 33': 'Sollentuna',
    '191 34': 'Sollentuna',
    '191 35': 'Sollentuna',
    '191 36': 'Sollentuna',
    '191 37': 'Sollentuna',
    '191 38': 'Silverdal',
    '191 39': 'Sollentuna',
    '191 40': 'Sollentuna',
    '191 41': 'Sollentuna',
    '191 42': 'Helenelund',
    '191 43': 'Sollentuna',
    '191 44': 'Sollentuna',
    '191 45': 'Sollentuna',
    '191 46': 'Sollentuna',
    '191 47': 'Sollentuna',
    '191 48': 'Sollentuna',
    '191 49': 'Sollentuna',
    '191 50': 'Häggvik',
    '191 60': 'Sollentuna',
    '191 61': 'Tureberg',
    '191 62': 'Sollentuna',
    '191 63': 'Sollentuna',
    '191 64': 'Sollentuna',
    '192 32': 'Sollentuna',
    '192 48': 'Vaxmora',
    '192 51': 'Sollentuna',
    '192 52': 'Edsberg',
    '192 53': 'Sollentuna',
    '192 54': 'Sollentuna',
    '192 55': 'Sjöberg',
    '192 56': 'Kärrdal',
    '192 57': 'Sollentuna',
    '192 58': 'Sollentuna',
    '192 59': 'Sollentuna',
    '192 66': 'Sollentuna',
    '192 67': 'Sollentuna',
    '192 68': 'Rotebro',
    '192 69': 'Sollentuna',
    '192 70': 'Norrviken',
    '192 71': 'Sollentuna',
    '192 72': 'Sollentuna',
    '192 73': 'Viby',
    '192 74': 'Sollentuna',
    '192 75': 'Sollentuna',
    '192 76': 'Sollentuna',
    '192 77': 'Sollentuna',
    '192 78': 'Sollentuna',
    '192 79': 'Sollentuna',
    '193 30': 'Sigtuna',
    '193 31': 'Sigtuna',
    '193 32': 'Sigtuna',
    '193 33': 'Sigtuna',
    '193 34': 'Sigtuna',
    '193 35': 'Sigtuna',
    '193 36': 'Sigtuna',
    '193 37': 'Sigtuna',
    '193 40': 'Sigtuna',
    '193 41': 'Sigtuna',
    '193 91': 'Wenngarn',
    '194 30': 'Upplands Väsby',
    '194 31': 'Ekebo',
    '194 32': 'Upplands Väsby',
    '194 33': 'Upplands Väsby',
    '194 34': 'Upplands Väsby',
    '194 35': 'Upplands Väsby',
    '194 36': 'Upplands Väsby',
    '194 37': 'Upplands Väsby',
    '194 38': 'Upplands Väsby',
    '194 39': 'Upplands Väsby',
    '194 40': 'Upplands Väsby',
    '194 41': 'Upplands Väsby',
    '194 42': 'Upplands Väsby',
    '194 43': 'Upplands Väsby',
    '194 44': 'Upplands Väsby',
    '194 45': 'Löwenströmska lasarettet',
    '194 46': 'Upplands Väsby',
    '194 47': 'Upplands Väsby',
    '194 49': 'Vilunda',
    '194 51': 'Upplands Väsby',
    '194 52': 'Upplands Väsby',
    '194 53': 'Fresta',
    '194 54': 'Upplands Väsby',
    '194 55': 'Upplands Väsby',
    '194 56': 'Upplands Väsby',
    '194 57': 'Upplands Väsby',
    '194 60': 'Upplands Väsby',
    '194 61': 'Bredden',
    '194 62': 'Upplands Väsby',
    '194 63': 'Upplands Väsby',
    '194 64': 'Bollstanäs',
    '194 65': 'Upplands Väsby',
    '194 66': 'Upplands Väsby',
    '194 67': 'Upplands Väsby',
    '194 68': 'Upplands Väsby',
    '194 69': 'Upplands Väsby',
    '194 70': 'Upplands Väsby',
    '194 71': 'Smedby',
    '194 72': 'Upplands Väsby',
    '194 73': 'Upplands Väsby',
    '194 74': 'Upplands Väsby',
    '194 75': 'Upplands Väsby',
    '194 76': 'Upplands Väsby',
    '194 77': 'Upplands Väsby',
    '194 78': 'Upplands Väsby',
    '194 79': 'Upplands Väsby',
    '194 91': 'Upplands Väsby',
    '194 92': 'Ekeby',
    '195 30': 'Sigtuna',
    '195 31': 'Sigtuna',
    '195 32': 'Sigtuna',
    '195 33': 'Ekilla',
    '195 34': 'Sigtuna',
    '195 35': 'Sigtuna',
    '195 36': 'Sigtuna',
    '195 40': 'Sigtuna',
    '195 41': 'Sigtuna',
    '195 42': 'Märsta',
    '195 43': 'Sigtuna',
    '195 44': 'Sigtuna',
    '195 45': 'Sigtuna',
    '195 46': 'Sigtuna',
    '195 47': 'Sigtuna',
    '195 50': 'Sigtuna',
    '195 51': 'Sigtuna',
    '195 52': 'Sigtuna',
    '195 53': 'Sigtuna',
    '195 54': 'Sigtuna',
    '195 55': 'Sigtuna',
    '195 56': 'Steninge',
    '195 57': 'Sigtuna',
    '195 58': 'Sigtuna',
    '195 60': 'Sigtuna',
    '195 61': 'Sigtuna',
    '195 65': 'Sigtuna',
    '195 70': 'Sigtuna',
    '195 71': 'Rosersberg',
    '195 72': 'Sigtuna',
    '195 91': 'Sigtuna',
    '195 92': 'Sigtuna',
    '195 93': 'Skepptuna',
    '195 94': 'Vidbo',
    '195 95': 'Sigtuna',
    '195 96': 'Sigtuna',
    '196 30': 'Upplands-Bro',
    '196 31': 'Upplands-Bro',
    '196 32': 'Upplands-Bro',
    '196 33': 'Upplands-Bro',
    '196 34': 'Sylta',
    '196 35': 'Upplands-Bro',
    '196 36': 'Upplands-Bro',
    '196 37': 'Brunna',
    '196 38': 'Upplands-Bro',
    '196 39': 'Upplands-Bro',
    '196 40': 'Kungsängen',
    '196 41': 'Upplands-Bro',
    '196 91': 'Upplands-Bro',
    '196 92': 'Upplands-Bro',
    '196 93': 'Upplands-Bro',
    '197 30': 'Upplands-Bro',
    '197 31': 'Bro',
    '197 32': 'Upplands-Bro',
    '197 33': 'Upplands-Bro',
    '197 34': 'Upplands-Bro',
    '197 35': 'Upplands-Bro',
    '197 36': 'Upplands-Bro',
    '197 40': 'Upplands-Bro',
    '197 91': 'Rättarboda',
    '197 92': 'Mariedal',
    '197 93': 'Håbo-Tibble kyrkby',
    '760 15': 'Gräddö',
    '760 17': 'Norrtälje',
    '760 18': 'Norrtälje',
    '760 19': 'Norrtälje',
    '760 21': 'Harg',
    '760 49': 'Herräng',
    '761 11': 'Bergshamra',
    '761 12': 'Svensboda',
    '761 13': 'Riala',
    '761 30': 'Norrtälje',
    '761 31': 'Norrtälje',
    '761 32': 'Norrtälje',
    '761 33': 'Norrtälje',
    '761 40': 'Kvisthamra',
    '761 41': 'Görla industriområde',
    '761 42': 'Norrtälje',
    '761 43': 'Norrtälje',
    '761 44': 'Norrtälje',
    '761 45': 'Norrtälje',
    '761 46': 'Campus Roslagen',
    '761 50': 'Norrtälje',
    '761 51': 'Norrtälje',
    '761 52': 'Vigelsjö',
    '761 53': 'Norrtälje',
    '761 54': 'Norrtälje',
    '761 60': 'Norrtälje',
    '761 61': 'Grind',
    '761 62': 'Norrtälje',
    '761 63': 'Fågelsången',
    '761 64': 'Solbacka',
    '761 65': 'Norrtälje',
    '761 71': 'Malsta',
    '761 72': 'Norrtälje',
    '761 73': 'Svanberga',
    '761 74': 'Söderby-Karl',
    '761 75': 'Roslagsbro',
    '761 76': 'Nysättra',
    '761 91': 'Norrtälje',
    '761 93': 'Hysingsvik',
    '761 94': 'Södersvik',
    '761 96': 'Norrtälje',
    '761 97': 'Spillersboda',
    '761 98': 'Norrtälje',
    '762 31': 'Norrtälje',
    '762 41': 'Rimbo',
    '762 51': 'Norrtälje',
    '762 61': 'Norrtälje',
    '762 71': 'Norrtälje',
    '762 91': 'Norrtälje',
    '762 92': 'Norrtälje',
    '762 93': 'Norrtälje',
    '762 94': 'Norrtälje',
    '762 95': 'Norrtälje',
    '762 96': 'Rånäs',
    '762 97': 'Bro',
    '762 98': 'Edsbro',
    '763 30': 'Norrtälje',
    '763 34': 'Norrtälje',
    '763 35': 'Hallstavik',
    '763 40': 'Norrtälje',
    '763 41': 'Norrtälje',
    '763 42': 'Norrtälje',
    '763 43': 'Häverödal',
    '763 91': 'Edebo',
    '763 92': 'Norrtälje',
    '763 93': 'Skebobruk',
    '763 94': 'Norrtälje',
    '764 30': 'Älmsta',
    '764 31': 'Norrtälje',
    '764 53': 'Simpnäs',
    '764 54': 'Norrtälje',
    '764 56': 'Grisslehamn',
    '764 57': 'Norrtälje',
    '764 91': 'Norrtälje',
    '764 92': 'Norrtälje',
    '764 93': 'Norrtälje',
    '293 31': 'Olofström',
    '293 32': 'Olofström',
    '293 33': 'Olofström',
    '293 34': 'Olofström',
    '293 35': 'Olofström',
    '293 36': 'Olofström',
    '293 37': 'Olofström',
    '293 38': 'Olofström',
    '293 39': 'Olofström',
    '293 40': 'Olofström',
    '293 41': 'Olofström',
    '293 42': 'Olofström',
    '293 72': 'Olofström',
    '293 73': 'Jämshög',
    '293 74': 'Kyrkhult',
    '293 91': 'Olofström',
    '293 92': 'Olofström',
    '293 93': 'Gränum',
    '293 94': 'Olofström',
    '293 95': 'Vilshult',
    '294 07': 'Sölvesborg',
    '294 31': 'Sölvesborg',
    '294 32': 'Sölvesborg',
    '294 33': 'Sölvesborg',
    '294 34': 'Sölvesborg',
    '294 35': 'Sölvesborg',
    '294 36': 'Sölvesborg',
    '294 37': 'Sölvesborg',
    '294 38': 'Sölvesborg',
    '294 39': 'Sölvesborg',
    '294 40': 'Sölvesborg',
    '294 71': 'Mjällby',
    '294 72': 'Hällevik',
    '294 74': 'Hörvik',
    '294 75': 'Sölvesborg',
    '294 76': 'Norje',
    '294 77': 'Valje',
    '294 91': 'Sandviken',
    '294 92': 'Sölvesborg',
    '294 93': 'Sölvesborg',
    '294 94': 'Sölvesborg',
    '294 95': 'Nogersund',
    '371 30': 'Karlskrona',
    '371 31': 'Karlskrona',
    '371 32': 'Karlskrona',
    '371 33': 'Karlskrona',
    '371 34': 'Pottholmen',
    '371 35': 'Karlskrona',
    '371 36': 'Karlskrona',
    '371 37': 'Karlskrona',
    '371 38': 'Pantarholmen',
    '371 39': 'Karlskrona',
    '371 40': 'Galgamarken',
    '371 41': 'Bergåsa',
    '371 42': 'Hästö',
    '371 43': 'Karlskrona',
    '371 44': 'Kungsmarken',
    '371 45': 'Mariedal',
    '371 46': 'Backabo',
    '371 47': 'Karlskrona',
    '371 48': 'Torskors',
    '371 49': 'Karlskrona',
    '371 50': 'Karlskrona',
    '371 51': 'Karlskrona',
    '371 52': 'Hässlegården',
    '371 53': 'Karlskrona',
    '371 54': 'Gullberna',
    '371 55': 'Karlskrona',
    '371 56': 'Karlskrona',
    '371 57': 'Karlskrona',
    '371 60': 'Östra Torp',
    '371 61': 'Karlskrona',
    '371 62': 'Lyckeby',
    '371 63': 'Spandelstorp',
    '371 64': 'Knösö',
    '371 65': 'Verkö',
    '371 91': 'Rosenholm',
    '371 92': 'Karlskrona',
    '371 93': 'Kättilsmåla',
    '371 94': 'Lösen',
    '372 30': 'Ronneby',
    '372 31': 'Ronneby',
    '372 32': 'Ronneby',
    '372 33': 'Ronneby',
    '372 34': 'Ronneby',
    '372 35': 'Ronneby',
    '372 36': 'Korsanäs',
    '372 37': 'Ronneby',
    '372 38': 'Ronneby',
    '372 39': 'Ronneby',
    '372 40': 'Ronneby',
    '372 50': 'Kallinge',
    '372 51': 'Ronneby',
    '372 52': 'Ronneby',
    '372 53': 'Ronneby',
    '372 60': 'Bräkne-Hoby',
    '372 61': 'Ronneby',
    '372 62': 'Ronneby',
    '372 63': 'Järnavik',
    '372 73': 'Ronnebyhamn',
    '372 74': 'Listerby',
    '372 75': 'Johannishus',
    '372 76': 'Hallabro',
    '372 77': 'Backaryd',
    '372 78': 'Ronneby',
    '372 91': 'Ronneby',
    '372 92': 'Möljeryd',
    '372 93': 'Ronneby',
    '372 94': 'Ronneby',
    '372 95': 'Ronneby',
    '372 96': 'Ronneby',
    '372 97': 'Saxemara',
    '372 98': 'Eringsboda',
    '373 30': 'Nättraby',
    '373 31': 'Karlskrona',
    '373 32': 'Skavkulla och Skillingenäs',
    '373 33': 'Karlskrona',
    '373 34': 'Ronneby',
    '373 35': 'Björkeryd',
    '373 36': 'Fridlevstad',
    '373 37': 'Holmsjö',
    '373 38': 'Spetsamåla',
    '373 39': 'Tving',
    '373 40': 'Karlskrona',
    '373 41': 'Spjutsbygd',
    '373 42': 'Rödeby',
    '373 43': 'Karlskrona',
    '373 44': 'Karlskrona',
    '373 45': 'Karlskrona',
    '373 51': 'Gängletorp',
    '373 52': 'Ramdala',
    '373 53': 'Karlskrona',
    '373 54': 'Karlskrona',
    '373 61': 'Sanda',
    '373 62': 'Sturkö',
    '373 63': 'Karlskrona',
    '373 64': 'Karlskrona',
    '373 65': 'Karlskrona',
    '373 66': 'Karlskrona',
    '373 71': 'Torhamn',
    '373 72': 'Jämjö',
    '373 73': 'Karlskrona',
    '373 74': 'Karlskrona',
    '373 75': 'Karlskrona',
    '373 77': 'Fågelmara',
    '373 78': 'Kristianopel',
    '374 30': 'Karlshamn',
    '374 31': 'Karlshamn',
    '374 32': 'Karlshamn',
    '374 33': 'Karlshamn',
    '374 34': 'Karlshamn',
    '374 35': 'Karlshamn',
    '374 36': 'Karlshamn',
    '374 37': 'Karlshamn',
    '374 38': 'Karlshamn',
    '374 39': 'Karlshamn',
    '374 40': 'Karlshamn',
    '374 41': 'Karlshamn',
    '374 50': 'Karlshamn',
    '374 51': 'Asarum',
    '374 52': 'Karlshamn',
    '374 53': 'Karlshamn',
    '374 70': 'Hällaryd',
    '374 91': 'Torarp',
    '374 92': 'Karlshamn',
    '374 93': 'Stillerydshamnen',
    '374 94': 'Karlshamn',
    '374 95': 'Halahult',
    '374 96': 'Åryd',
    '375 30': 'Karlshamn',
    '375 31': 'Karlshamn',
    '375 32': 'Mörrum',
    '375 33': 'Karlshamn',
    '375 34': 'Karlshamn',
    '375 90': 'Karlshamn',
    '375 91': 'Karlshamn',
    '376 35': 'Svängsta',
    '376 36': 'Karlshamn',
    '376 37': 'Karlshamn',
    '376 92': 'Karlshamn',
    '376 93': 'Karlshamn',
    '770 10': 'Fredriksberg',
    '770 12': 'Sunnansjö',
    '770 13': 'Grangärde',
    '770 14': 'Nyhammar',
    '771 30': 'Ludvika',
    '771 31': 'Ludvika',
    '771 32': 'Ludvika',
    '771 33': 'Ludvika',
    '771 34': 'Ludvika',
    '771 35': 'Ludvika',
    '771 36': 'Håksberg',
    '771 40': 'Ludvika',
    '771 41': 'Ludvika',
    '771 42': 'Ludvika',
    '771 43': 'Ludvika',
    '771 50': 'Ludvika',
    '771 51': 'Ludvika',
    '771 52': 'Ludvika',
    '771 53': 'Ludvika',
    '771 54': 'Ludvika',
    '771 55': 'Smedjebacken',
    '771 60': 'Ludvika',
    '771 65': 'Blötberget',
    '771 90': 'Hagge',
    '771 92': 'Sörvik',
    '771 94': 'Gonäs',
    '772 30': 'Grängesberg',
    '772 31': 'Ludvika',
    '772 32': 'Ludvika',
    '772 40': 'Ludvika',
    '772 50': 'Ludvika',
    '772 70': 'Saxdalen',
    '772 90': 'Ludvika',
    '774 30': 'Avesta',
    '774 31': 'Avesta',
    '774 32': 'Avesta',
    '774 33': 'Avesta',
    '774 34': 'Avesta',
    '774 35': 'Avesta',
    '774 40': 'Avesta',
    '774 41': 'Avesta',
    '774 60': 'Skogsbo',
    '774 61': 'Avesta',
    '774 62': 'Avesta',
    '774 63': 'Avesta',
    '774 67': 'Horndal',
    '774 68': 'Avesta',
    '774 91': 'Avesta',
    '774 92': 'Avesta',
    '774 94': 'Hedemora',
    '774 97': 'Fors',
    '774 98': 'Avesta',
    '774 99': 'Näs bruk',
    '775 50': 'Nordanö',
    '775 51': 'Krylbo',
    '775 53': 'Avesta',
    '775 54': 'Avesta',
    '775 70': 'Avesta',
    '775 71': 'Avesta',
    '775 95': 'Avesta',
    '775 96': 'Avesta',
    '776 30': 'Hedemora',
    '776 31': 'Hedemora',
    '776 32': 'Hedemora',
    '776 33': 'Hedemora',
    '776 34': 'Hedemora',
    '776 35': 'Hedemora',
    '776 36': 'Hedemora',
    '776 70': 'Vikmanshyttan',
    '776 71': 'Hedemora',
    '776 72': 'Långshyttan',
    '776 73': 'Hedemora',
    '776 74': 'Hedemora',
    '776 90': 'Hedemora',
    '776 91': 'Hedemora',
    '776 92': 'Hedemora',
    '776 93': 'Västerby',
    '776 95': 'Hedemora',
    '776 96': 'Hedemora',
    '776 97': 'Husby',
    '776 98': 'Garpenberg',
    '777 30': 'Smedjebacken',
    '777 31': 'Smedjebacken',
    '777 32': 'Smedjebacken',
    '777 33': 'Smedjebacken',
    '777 34': 'Smedjebacken',
    '777 50': 'Smedjebacken',
    '777 51': 'Smedjebacken',
    '777 60': 'Söderbärke',
    '777 90': 'Smedjebacken',
    '777 91': 'Gubbo',
    '777 93': 'Smedjebacken',
    '777 94': 'Vad',
    '780 61': 'Malung-Sälen',
    '780 64': 'Lima',
    '780 66': 'Rörbäcksnäs',
    '780 67': 'Sälen',
    '780 68': 'Transtrand',
    '780 69': 'Älvdalen',
    '781 68': 'Borlänge',
    '781 69': 'Repbäcken',
    '781 70': 'Borlänge',
    '781 71': 'Borlänge',
    '781 72': 'Borlänge',
    '781 73': 'Borlänge',
    '781 74': 'Borlänge',
    '781 90': 'Norr Amsberg',
    '781 91': 'Sörbo',
    '781 92': 'Borlänge',
    '781 93': 'Borlänge',
    '781 94': 'Torsång',
    '781 95': 'Bäsna',
    '781 96': 'Säter',
    '781 97': 'Borlänge',
    '781 98': 'Halvarsgårdarna',
    '781 99': 'Idkerberget',
    '782 30': 'Malung-Sälen',
    '782 31': 'Malung',
    '782 33': 'Malung-Sälen',
    '782 34': 'Malung-Sälen',
    '782 35': 'Malung-Sälen',
    '782 75': 'Malungsfors',
    '782 90': 'Malung-Sälen',
    '782 91': 'Yttermalung',
    '783 30': 'Säter',
    '783 31': 'Säter',
    '783 32': 'Säter',
    '783 34': 'Säter',
    '783 35': 'Säter',
    '783 50': 'Enbacka',
    '783 90': 'Säter',
    '783 91': 'Säter',
    '783 92': 'Skedvi kyrkby',
    '783 93': 'Säter',
    '783 95': 'Solvarbo',
    '784 30': 'Borlänge',
    '784 31': 'Borlänge',
    '784 32': 'Borlänge',
    '784 33': 'Borlänge',
    '784 34': 'Borlänge',
    '784 35': 'Borlänge',
    '784 36': 'Borlänge',
    '784 37': 'Borlänge',
    '784 39': 'Borlänge',
    '784 40': 'Borlänge',
    '784 41': 'Borlänge',
    '784 42': 'Borlänge',
    '784 43': 'Borlänge',
    '784 44': 'Borlänge',
    '784 45': 'Borlänge',
    '784 50': 'Borlänge',
    '784 51': 'Borlänge',
    '784 52': 'Borlänge',
    '784 53': 'Borlänge',
    '784 54': 'Borlänge',
    '784 55': 'Borlänge',
    '784 56': 'Borlänge',
    '784 60': 'Borlänge',
    '784 61': 'Borlänge',
    '784 62': 'Borlänge',
    '784 63': 'Borlänge',
    '784 65': 'Borlänge',
    '784 66': 'Borlänge',
    '784 67': 'Borlänge',
    '784 68': 'Borlänge',
    '784 72': 'Borlänge',
    '784 73': 'Borlänge',
    '784 74': 'Borlänge',
    '784 75': 'Borlänge',
    '784 76': 'Borlänge',
    '784 77': 'Ornäs',
    '784 78': 'Borlänge',
    '785 30': 'Gagnef',
    '785 42': 'Gagnef',
    '785 43': 'Mockfjärd',
    '785 60': 'Gagnef',
    '785 61': 'Djurås',
    '785 62': 'Djurmo',
    '785 63': 'Sifferbo',
    '785 91': 'Gagnef',
    '785 92': 'Leksand',
    '786 31': 'Vansbro',
    '786 32': 'Vansbro',
    '786 33': 'Vansbro',
    '786 71': 'Vansbro',
    '786 72': 'Järna',
    '786 91': 'Vansbro',
    '786 92': 'Skålö',
    '786 93': 'Nås',
    '786 94': 'Äppelbo',
    '786 95': 'Dala-Floda',
    '786 97': 'Björbo',
    '790 15': 'Sundborn',
    '790 20': 'Grycksbo',
    '790 21': 'Bjursås',
    '790 22': 'Sågmyra',
    '790 23': 'Svärdsjö',
    '790 24': 'Toftbyn',
    '790 25': 'Linghed',
    '790 26': 'Enviken',
    '790 70': 'Furudal',
    '790 90': 'Särna',
    '790 91': 'Idre',
    '791 30': 'Falun',
    '791 31': 'Falun',
    '791 32': 'Falun',
    '791 33': 'Falun',
    '791 34': 'Falun',
    '791 35': 'Falun',
    '791 36': 'Falun',
    '791 37': 'Falun',
    '791 38': 'Falun',
    '791 40': 'Falun',
    '791 41': 'Falun',
    '791 42': 'Falun',
    '791 43': 'Falun',
    '791 44': 'Falun',
    '791 45': 'Falun',
    '791 46': 'Falun',
    '791 47': 'Falun',
    '791 50': 'Falun',
    '791 51': 'Falun',
    '791 52': 'Falun',
    '791 53': 'Falun',
    '791 55': 'Falun',
    '791 56': 'Falun',
    '791 60': 'Falun',
    '791 61': 'Falun',
    '791 62': 'Falun',
    '791 70': 'Falun',
    '791 71': 'Falun',
    '791 72': 'Falun',
    '791 73': 'Falun',
    '791 74': 'Falun',
    '791 75': 'Falun',
    '791 76': 'Falun',
    '791 77': 'Falun',
    '791 91': 'Falun',
    '791 92': 'Falun',
    '791 93': 'Falun',
    '791 94': 'Falun',
    '791 95': 'Vika',
    '791 96': 'Falun',
    '792 30': 'Morastrand',
    '792 31': 'Mora',
    '792 32': 'Mora',
    '792 33': 'Mora',
    '792 34': 'Mora',
    '792 35': 'Mora',
    '792 36': 'Mora',
    '792 37': 'Mora',
    '792 50': 'Mora-Noret',
    '792 51': 'Mora',
    '792 52': 'Mora',
    '792 75': 'Färnäs',
    '792 77': 'Nusnäs',
    '792 90': 'Sollerön',
    '792 91': 'Vinäs',
    '792 92': 'Mora',
    '792 93': 'Venjan',
    '792 94': 'Selja',
    '792 95': 'Östnor',
    '792 96': 'Våmhus',
    '792 97': 'Vattnäs',
    '792 98': 'Garsås',
    '793 30': 'Leksand',
    '793 31': 'Leksand',
    '793 32': 'Leksand',
    '793 33': 'Leksand',
    '793 35': 'Leksand',
    '793 36': 'Leksand',
    '793 40': 'Leksand',
    '793 41': 'Insjön',
    '793 50': 'Häradsbygden',
    '793 60': 'Siljansnäs',
    '793 70': 'Tällberg',
    '793 90': 'Leksand',
    '793 91': 'Leksand',
    '793 92': 'Karlsarvet',
    '793 93': 'Djura',
    '793 94': 'Leksand',
    '793 97': 'Alvik',
    '794 30': 'Orsa',
    '794 31': 'Orsa',
    '794 32': 'Orsa',
    '794 33': 'Orsa',
    '794 34': 'Orsa',
    '794 90': 'Orsa',
    '794 91': 'Orsa',
    '794 92': 'Orsa',
    '794 93': 'Orsa',
    '794 95': 'Skattungbyn',
    '794 98': 'Orsa',
    '795 30': 'Rättvik',
    '795 31': 'Rättvik',
    '795 32': 'Rättvik',
    '795 33': 'Rättvik',
    '795 35': 'Rättvik',
    '795 36': 'Rättvik',
    '795 70': 'Vikarbyn',
    '795 90': 'Rättvik',
    '795 91': 'Rättvik',
    '795 92': 'Nedre Gärdsjö',
    '795 95': 'Röjeråsen',
    '795 96': 'Boda',
    '796 30': 'Älvdalen',
    '796 31': 'Älvdalen',
    '796 90': 'Åsen',
    '796 91': 'Evertsberg',
    '796 99': 'Älvdalen',
    '842 99': 'Älvdalen',
    '802 50': 'Gävle',
    '802 51': 'Gävle',
    '802 52': 'Gävle',
    '802 53': 'Gävle',
    '802 54': 'Gävle',
    '802 55': 'Södertull',
    '802 56': 'Gävle',
    '802 57': 'Hemlingby',
    '802 60': 'Gävle',
    '802 61': 'Gävle',
    '802 62': 'Sörby',
    '802 63': 'Gävle',
    '802 64': 'Gävle',
    '802 65': 'Gävle',
    '802 66': 'Gävle',
    '802 67': 'Villastan',
    '802 69': 'Olsbacka',
    '802 70': 'Höjersdal',
    '802 71': 'Gävle',
    '802 72': 'Gävle',
    '802 74': 'Gävle',
    '802 75': 'Gävle',
    '802 76': 'Gävle',
    '802 77': 'Andersberg',
    '802 80': 'Gävle',
    '802 81': 'Gävle',
    '802 82': 'Gävle',
    '802 83': 'Gävle',
    '802 84': 'Brynäs',
    '802 85': 'Gävle',
    '802 86': 'Gävle',
    '802 88': 'Gävle',
    '802 91': 'Sörby Urfjäll',
    '802 92': 'Gävle',
    '802 93': 'Gävle',
    '803 01': 'Gävle',
    '803 02': 'Gävle',
    '803 09': 'Näringen',
    '803 10': 'Gävle',
    '803 11': 'Gävle',
    '803 20': 'Gävle',
    '803 21': 'Gävle',
    '803 22': 'Gävle',
    '803 23': 'Gävle',
    '803 24': 'Gävle',
    '803 25': 'Gävle',
    '804 23': 'Gävle',
    '804 24': 'Gävle',
    '804 25': 'Gävle',
    '804 26': 'Gävle',
    '804 27': 'Gävle',
    '804 28': 'Gävle',
    '804 29': 'Gävle',
    '804 30': 'Gävle',
    '804 31': 'Gävle',
    '804 32': 'Bomhus',
    '804 33': 'Gävle',
    '804 34': 'Gävle',
    '805 91': 'Sälgsjön',
    '805 92': 'Järvsta',
    '805 95': 'Bönan',
    '805 96': 'Trödje',
    '805 97': 'Björke',
    '805 98': 'Södra Åbyggeby',
    '806 26': 'Gävle',
    '806 27': 'Lexe',
    '806 28': 'Hagaström',
    '806 30': 'Gävle',
    '806 31': 'Gävle',
    '806 32': 'Gävle',
    '806 33': 'Gävle',
    '806 34': 'Gävle',
    '806 35': 'Sätra',
    '806 36': 'Gävle',
    '806 37': 'Gävle',
    '806 38': 'Gävle',
    '806 41': 'Stigslund',
    '806 42': 'Gävle',
    '806 43': 'Forsby',
    '806 45': 'Strömsbro',
    '806 46': 'Gävle',
    '806 47': 'Gävle',
    '806 48': 'Hille',
    '806 49': 'Gävle',
    '811 30': 'Sandviken',
    '811 31': 'Sandviken',
    '811 32': 'Sandviken',
    '811 33': 'Sandviken',
    '811 34': 'Sandviken',
    '811 35': 'Sandviken',
    '811 36': 'Sandviken',
    '811 37': 'Sandviken',
    '811 38': 'Vallhov',
    '811 39': 'Sandviken',
    '811 40': 'Sandviken',
    '811 41': 'Sandviken',
    '811 50': 'Sandviken',
    '811 51': 'Sandviken',
    '811 52': 'Sandviken',
    '811 53': 'Sandviken',
    '811 54': 'Sandviken',
    '811 60': 'Sandviken',
    '811 61': 'Sandviken',
    '811 62': 'Sandviken',
    '811 70': 'Sandviken',
    '811 71': 'Järbo',
    '811 73': 'Årsunda',
    '811 74': 'Österfärnebo',
    '811 91': 'Jäderfors',
    '811 92': 'Sandviken',
    '811 93': 'Sandviken',
    '811 94': 'Sandviken',
    '811 95': 'Sandviken',
    '811 96': 'Sandviken',
    '811 97': 'Gysinge',
    '811 98': 'Sandviken',
    '811 99': 'Sandviken',
    '812 30': 'Sandviken',
    '812 31': 'Storvik',
    '812 40': 'Sandviken',
    '812 41': 'Gästrike-Hammarby',
    '812 51': 'Åshammar',
    '812 90': 'Västerberg',
    '812 92': 'Sandviken',
    '812 93': 'Backberg',
    '812 94': 'Sandviken',
    '813 30': 'Hofors',
    '813 31': 'Hofors',
    '813 32': 'Hofors',
    '813 33': 'Hofors',
    '813 34': 'Hofors',
    '813 35': 'Hofors',
    '813 36': 'Hofors',
    '813 40': 'Torsåker',
    '813 82': 'Hofors',
    '813 91': 'Hofors',
    '813 94': 'Hofors',
    '813 95': 'Hofors',
    '813 96': 'Hofors',
    '814 91': 'Furuvik',
    '816 30': 'Ockelbo',
    '816 31': 'Ockelbo',
    '816 90': 'Herratjärnfäbodarna',
    '816 91': 'Jädraås',
    '816 92': 'Lingbo',
    '816 93': 'Ockelbo',
    '816 94': 'Ockelbo',
    '816 95': 'Åmot',
    '817 30': 'Norrsundet',
    '817 40': 'Totra',
    '817 91': 'Ockelbo',
    '817 92': 'Gävle',
    '817 93': 'Hamrångefjärden',
    '817 94': 'Gävle',
    '818 30': 'Gävle',
    '818 31': 'Valbo',
    '818 32': 'Gävle',
    '818 33': 'Gävle',
    '818 34': 'Gävle',
    '818 35': 'Gävle',
    '818 41': 'Forsbacka',
    '818 42': 'Gävle',
    '818 43': 'Brunnsvik',
    '818 45': 'Hedesunda',
    '818 91': 'Gävle',
    '818 92': 'Lund',
    '818 95': 'Gävle',
    '818 96': 'Berg',
    '821 30': 'Bollnäs',
    '821 31': 'Bollnäs',
    '821 32': 'Bollnäs',
    '821 33': 'Bollnäs',
    '821 34': 'Bollnäs',
    '821 35': 'Bollnäs',
    '821 36': 'Bollnäs',
    '821 40': 'Bollnäs',
    '821 41': 'Bollnäs',
    '821 42': 'Bollnäs',
    '821 43': 'Bollnäs',
    '821 50': 'Bollnäs',
    '821 51': 'Bollnäs',
    '821 58': 'Rengsjö',
    '821 60': 'Bollnäs',
    '821 61': 'Arbrå',
    '821 62': 'Bollnäs',
    '821 63': 'Bollnäs',
    '821 67': 'Vallsta',
    '821 68': 'Bollnäs',
    '821 91': 'Bollnäs',
    '821 92': 'Bollnäs',
    '821 93': 'Bollnäs',
    '821 94': 'Lottefors',
    '821 95': 'Bollnäs',
    '821 98': 'Glössbo',
    '822 30': 'Alfta',
    '822 31': 'Ovanåker',
    '822 40': 'Ovanåker',
    '822 91': 'Ovanåker',
    '822 92': 'Runemo',
    '822 99': 'Ovanåker',
    '823 30': 'Kilafors',
    '823 91': 'Bollnäs',
    '823 92': 'Söderhamn',
    '823 93': 'Segersta',
    '824 30': 'Hudiksvall',
    '824 31': 'Sofiedal',
    '824 33': 'Sandvalla',
    '824 34': 'Sanna',
    '824 40': 'Idenor',
    '824 41': 'Hudiksvall',
    '824 42': 'Hudiksvall',
    '824 43': 'Hudiksvall',
    '824 50': 'Hudiksvall',
    '824 51': 'Hudiksvall',
    '824 52': 'Kålhagen',
    '824 60': 'Sörforsa',
    '824 61': 'Hudiksvall',
    '824 62': 'Hudiksvall',
    '824 65': 'Näsviken',
    '824 66': 'Hudiksvall',
    '824 67': 'Hålsjö',
    '824 70': 'Delsbo',
    '824 71': 'Hudiksvall',
    '824 72': 'Hudiksvall',
    '824 73': 'Hudiksvall',
    '824 74': 'Hudiksvall',
    '824 77': 'Hudiksvall',
    '824 78': 'Friggesund',
    '824 79': 'Hudiksvall',
    '824 91': 'Hudiksvall',
    '824 92': 'Knösta',
    '824 93': 'Rogsta',
    '824 94': 'Via',
    '824 95': 'Strömsbruk',
    '824 96': 'Stocka',
    '825 30': 'Iggesund',
    '825 31': 'Hudiksvall',
    '825 32': 'Hudiksvall',
    '825 91': 'Hudiksvall',
    '825 92': 'Njutånger',
    '825 93': 'Nianfors',
    '825 95': 'Enånger',
    '825 96': 'Långvinds bruk',
    '826 30': 'Söderhamn',
    '826 31': 'Söderhamn',
    '826 32': 'Väster',
    '826 34': 'Söderhamn',
    '826 35': 'Norrmyra',
    '826 36': 'Norrberget',
    '826 37': 'Söderhamn',
    '826 39': 'Klossdammen',
    '826 40': 'Björnänge',
    '826 50': 'Knektslätten',
    '826 60': 'Grundvik',
    '826 61': 'Söderala',
    '826 62': 'Hägnan',
    '826 66': 'Marmaverken',
    '826 67': 'Vannsätter',
    '826 68': 'Bergvik',
    '826 69': 'Skog',
    '826 70': 'Söderhamn',
    '826 72': 'Sandarne',
    '826 73': 'Söderhamn',
    '826 75': 'Ljusne',
    '826 76': 'Söderhamn',
    '826 77': 'Söderhamn',
    '826 79': 'Vallvik',
    '826 91': 'Skärså',
    '826 92': 'Mohed',
    '826 93': 'Söderhamn',
    '826 94': 'Norrala',
    '826 95': 'Trönö',
    '827 30': 'Nore',
    '827 31': 'Ljusdal',
    '827 32': 'Ljusdal',
    '827 33': 'Ljusdal',
    '827 34': 'Ljusdal',
    '827 35': 'Ljusdal',
    '827 40': 'Tallåsen',
    '827 50': 'Järvsö',
    '827 51': 'Ljusdal',
    '827 52': 'Ljusdal',
    '827 53': 'Ljusdal',
    '827 54': 'Ljusdal',
    '827 60': 'Färila',
    '827 61': 'Ljusdal',
    '827 62': 'Ljusdal',
    '827 63': 'Stocksbo',
    '827 70': 'Los',
    '827 71': 'Hamra',
    '827 72': 'Ljusdal',
    '827 73': 'Kårböle',
    '827 75': 'Finneby',
    '827 76': 'Ljusdal',
    '827 91': 'Rolfhamre',
    '827 92': 'Ljusdal',
    '827 93': 'Ljusdal',
    '827 94': 'Ljusdal',
    '827 95': 'Ljusdal',
    '828 30': 'Ovanåker',
    '828 31': 'Ovanåker',
    '828 32': 'Edsbyn',
    '828 33': 'Ovanåker',
    '828 34': 'Roteberg',
    '828 91': 'Ovanåker',
    '828 92': 'Ovanåker',
    '828 93': 'Ovanåker',
    '828 94': 'Ovanåker',
    '828 95': 'Viksjöfors',
    '828 99': 'Ovanåker',
    '829 50': 'Bergsjö',
    '829 51': 'Nordanstig',
    '829 52': 'Nordanstig',
    '829 53': 'Nordanstig',
    '829 60': 'Gnarp',
    '829 61': 'Nordanstig',
    '829 62': 'Sörfjärden',
    '829 91': 'Vattlång',
    '829 95': 'Lönnångersfjärden',
    '829 96': 'Jättendal',
    '829 98': 'Hassela',
    '621 37': 'Gotland',
    '621 38': 'Gotland',
    '621 39': 'Gotland',
    '621 40': 'Gotland',
    '621 41': 'Österby',
    '621 42': 'Bingeby',
    '621 43': 'Östra Vi',
    '621 44': 'Gotland',
    '621 45': 'Gotland',
    '621 46': 'Gotland',
    '621 47': 'Visborgsstaden',
    '621 49': 'Lyckåker',
    '621 50': 'Djuplunda',
    '621 51': 'Gråbo',
    '621 52': 'Gotland',
    '621 53': 'Terra Nova',
    '621 54': 'Furulund',
    '621 55': 'Gotland',
    '621 56': 'Gotland',
    '621 57': 'Visby',
    '621 58': 'Hällarna',
    '621 70': 'Gotland',
    '621 71': 'Burge',
    '621 73': 'Gotland',
    '621 74': 'Gotland',
    '621 75': 'Gotland',
    '621 76': 'Gotland',
    '621 77': 'Gotland',
    '621 78': 'Gotland',
    '621 79': 'Gotland',
    '621 91': 'Gotland',
    '621 92': 'Gotland',
    '621 93': 'Gotland',
    '621 94': 'Träkumla',
    '621 95': 'Stenkumla',
    '621 96': 'Gotland',
    '621 99': 'Gotland',
    '622 30': 'Gotland',
    '622 32': 'Gotland',
    '622 34': 'Gotland',
    '622 36': 'Gotland',
    '622 38': 'Gotland',
    '622 40': 'Gotland',
    '622 42': 'Gotland',
    '622 44': 'Gotland',
    '622 46': 'Gotland',
    '622 48': 'Gotland',
    '622 50': 'Gotland',
    '622 52': 'Gotland',
    '622 54': 'Roma kyrkby',
    '622 56': 'Gotland',
    '622 58': 'Gotland',
    '622 59': 'Vibble',
    '622 60': 'Gotland',
    '622 61': 'Västerhejde',
    '622 65': 'Gotland',
    '622 66': 'Gotland',
    '622 70': 'Gotland',
    '622 75': 'Gotland',
    '622 76': 'Gotland',
    '623 30': 'Gotland',
    '623 31': 'Gotland',
    '623 32': 'Gotland',
    '623 33': 'Gotland',
    '623 35': 'Burgsvik',
    '623 36': 'Gotland',
    '623 37': 'Gotland',
    '623 38': 'Gotland',
    '623 40': 'Gotland',
    '623 41': 'Havdhem',
    '623 42': 'Gotland',
    '623 43': 'Gotland',
    '623 44': 'Gotland',
    '623 46': 'Gotland',
    '623 47': 'Gotland',
    '623 48': 'När',
    '623 49': 'Gotland',
    '623 50': 'Hemse',
    '623 51': 'Gotland',
    '623 52': 'Gotland',
    '623 53': 'Gotland',
    '623 54': 'Gotland',
    '623 55': 'Sandhamn',
    '623 56': 'Gotland',
    '623 57': 'Gotland',
    '623 59': 'Gotland',
    '623 60': 'Stånga',
    '623 61': 'Gotland',
    '623 62': 'Gotland',
    '623 63': 'Gotland',
    '623 64': 'Gotland',
    '623 65': 'Ljugarn',
    '623 66': 'Gotland',
    '623 67': 'Gotland',
    '623 68': 'Gotland',
    '623 69': 'Gotland',
    '623 70': 'Gotland',
    '623 71': 'Gotland',
    '623 72': 'Gotland',
    '623 74': 'Gotland',
    '623 75': 'Gotland',
    '623 76': 'Klinte',
    '623 77': 'Klintehamn',
    '623 78': 'Gotland',
    '623 79': 'Gotland',
    '624 30': 'Gotland',
    '624 32': 'Gotland',
    '624 34': 'Gotland',
    '624 36': 'Gotland',
    '624 38': 'Gotland',
    '624 40': 'Gotland',
    '624 42': 'Stenkyrkehuk',
    '624 44': 'Tingstäde',
    '624 46': 'Gotland',
    '624 48': 'Slite',
    '624 49': 'Gotland',
    '624 50': 'Gotland',
    '624 52': 'Lärbro',
    '624 53': 'Gotland',
    '624 54': 'Gotland',
    '624 55': 'Gotland',
    '624 56': 'Gotland',
    '624 58': 'Gotland',
    '624 60': 'Gotland',
    '624 62': 'Fårösund',
    '624 64': 'Gotland',
    '624 65': 'Gotland',
    '624 66': 'Gotland',
    '624 67': 'Gotland',
    '624 70': 'Gotland',
    '302 20': 'Nyhem',
    '302 21': 'Halmstad',
    '302 22': 'Halmstad',
    '302 23': 'Furet',
    '302 24': 'Halmstad',
    '302 25': 'Frennarp',
    '302 26': 'Gamletull',
    '302 27': 'Halmstad',
    '302 28': 'Halmstad',
    '302 29': 'Halmstad',
    '302 30': 'Kärleken',
    '302 31': 'Halmstad',
    '302 32': 'Norr',
    '302 33': 'Halmstad',
    '302 34': 'Halmstad',
    '302 35': 'Mickedala',
    '302 36': 'Halmstad',
    '302 37': 'Rotorp',
    '302 38': 'Slottsjorden',
    '302 39': 'Alet',
    '302 40': 'Stenhuggeriet',
    '302 41': 'Kristineberg',
    '302 42': 'Halmstad',
    '302 43': 'Halmstad',
    '302 44': 'Sannarp',
    '302 45': 'Sibirien',
    '302 46': 'Halmstad',
    '302 47': 'Nyatorp',
    '302 48': 'Halmstad',
    '302 49': 'Halmstad',
    '302 50': 'Halmstad',
    '302 51': 'Halmstad',
    '302 52': 'Halmstad',
    '302 53': 'Gustavsfält',
    '302 54': 'Linehed',
    '302 55': 'Andersberg',
    '302 56': 'Snöstorp',
    '302 57': 'Halmstad',
    '302 58': 'Halmstad',
    '302 59': 'Vallås',
    '302 60': 'Östra Stranden',
    '302 61': 'Fyllinge',
    '302 62': 'Halmstad',
    '302 63': 'Halmstad',
    '302 64': 'Halmstad',
    '302 65': 'Trönninge',
    '302 66': 'Halmstad',
    '302 67': 'Halmstad',
    '302 68': 'Halmstad',
    '302 69': 'Halmstad',
    '302 70': 'Halmstad',
    '302 71': 'Bäckagård',
    '302 72': 'Trottaberg',
    '302 73': 'Görvik',
    '302 74': 'Halmstad',
    '302 75': 'Frösakull',
    '302 76': 'Halmstad',
    '302 77': 'Bergsgård',
    '302 79': 'Halmstad',
    '302 83': 'Brogård',
    '302 90': 'Söder',
    '302 91': 'Eketånga',
    '302 92': 'Söndrum',
    '302 93': 'Hallägra',
    '302 94': 'Halmstad',
    '302 95': 'Halmstad',
    '302 96': 'Halmstad',
    '305 60': 'Harplinge',
    '305 61': 'Halmstad',
    '305 64': 'Gullbrandstorp',
    '305 65': 'Villshärad',
    '305 70': 'Haverdal',
    '305 71': 'Halmstad',
    '305 72': 'Steninge',
    '305 75': 'Getinge',
    '305 76': 'Halmstad',
    '305 77': 'Halmstad',
    '305 78': 'Kvibille',
    '305 91': 'Halmstad',
    '305 92': 'Holm',
    '305 93': 'Skedala',
    '305 94': 'Halmstad',
    '305 95': 'Laxvik',
    '305 96': 'Gullbranna',
    '305 97': 'Eldsberga',
    '311 30': 'Falkenberg',
    '311 31': 'Falkenberg',
    '311 32': 'Falkenberg',
    '311 33': 'Skogstorp',
    '311 34': 'Falkenberg',
    '311 35': 'Falkenberg',
    '311 36': 'Falkenberg',
    '311 37': 'Falkenberg',
    '311 38': 'Falkenberg',
    '311 39': 'Falkenberg',
    '311 40': 'Falkenberg',
    '311 41': 'Falkenberg',
    '311 42': 'Skrea Strand',
    '311 43': 'Falkenberg',
    '311 44': 'Falkenberg',
    '311 45': 'Falkenberg',
    '311 46': 'Falkenberg',
    '311 50': 'Falkenberg',
    '311 51': 'Ätran',
    '311 52': 'Falkenberg',
    '311 54': 'Glommen',
    '311 60': 'Ullared',
    '311 61': 'Gällared',
    '311 62': 'Falkenberg',
    '311 63': 'Älvsered',
    '311 64': 'Vessigebro',
    '311 65': 'Falkenberg',
    '311 67': 'Stensjö',
    '311 68': 'Slöinge',
    '311 69': 'Falkenberg',
    '311 70': 'Skrea',
    '311 71': 'Olofsbo',
    '311 72': 'Ringsegård',
    '311 73': 'Falkenberg',
    '311 74': 'Falkenberg',
    '311 75': 'Falkenberg',
    '311 76': 'Falkenberg',
    '311 78': 'Falkenberg',
    '311 90': 'Morup',
    '311 91': 'Falkenberg',
    '311 92': 'Falkenberg',
    '311 93': 'Långås',
    '311 94': 'Vinbergs kyrkby',
    '311 95': 'Bergagård',
    '311 96': 'Heberg',
    '311 97': 'Årstad',
    '311 98': 'Falkenberg',
    '311 99': 'Vinberg',
    '312 30': 'Laholm',
    '312 31': 'Laholm',
    '312 32': 'Laholm',
    '312 33': 'Laholm',
    '312 34': 'Laholm',
    '312 35': 'Lilla Tjärby',
    '312 36': 'Laholm',
    '312 40': 'Genevad',
    '312 45': 'Veinge',
    '312 50': 'Vallberga',
    '312 51': 'Knäred',
    '312 52': 'Laholm',
    '312 53': 'Hishult',
    '312 60': 'Laholm',
    '312 61': 'Mellbystrand',
    '312 71': 'Laholm',
    '312 72': 'Laholm',
    '312 75': 'Våxtorp',
    '312 77': 'Skottorp',
    '312 91': 'Laholm',
    '312 92': 'Veinge kyrkby',
    '312 93': 'Laholm',
    '312 94': 'Ysby',
    '312 95': 'Edenberga',
    '312 96': 'Laholm',
    '312 97': 'Hasslöv',
    '312 98': 'Laholm',
    '313 30': 'Halmstad',
    '313 31': 'Oskarström',
    '313 32': 'Halmstad',
    '313 33': 'Halmstad',
    '313 50': 'Åled',
    '313 91': 'Halmstad',
    '313 92': 'Halmstad',
    '313 93': 'Slättåkra',
    '313 94': 'Sennan',
    '313 95': 'Halmstad',
    '313 96': 'Simlångsdalen',
    '313 97': 'Halmstad',
    '314 31': 'Hyltebruk',
    '314 32': 'Hylte',
    '314 33': 'Hylte',
    '314 34': 'Hylte',
    '314 41': 'Torup',
    '314 42': 'Rydöbruk',
    '314 51': 'Unnaryd',
    '314 52': 'Hylte',
    '314 91': 'Femsjö',
    '314 92': 'Hylte',
    '314 93': 'Hylte',
    '314 94': 'Landeryd',
    '314 95': 'Kinnared',
    '314 96': 'Hylte',
    '314 97': 'Hylte',
    '314 98': 'Hylte',
    '427 50': 'Kungsbacka',
    '429 30': 'Kungsbacka',
    '429 31': 'Kungsbacka',
    '429 32': 'Kungsbacka',
    '429 33': 'Kullavik',
    '429 34': 'Brattås',
    '429 35': 'Kungsbacka',
    '429 41': 'Kungsbacka',
    '429 42': 'Kungsbacka',
    '429 43': 'Särö',
    '429 44': 'Kungsbacka',
    '432 30': 'Varberg',
    '432 31': 'Varberg',
    '432 32': 'Varberg',
    '432 33': 'Brunnsberg',
    '432 34': 'Varberg',
    '432 35': 'Varberg',
    '432 36': 'Varberg',
    '432 37': 'Prästkullen',
    '432 38': 'Varberg',
    '432 39': 'Varberg',
    '432 40': 'Varberg',
    '432 41': 'Varberg',
    '432 42': 'Varberg',
    '432 43': 'Varberg',
    '432 44': 'Varberg',
    '432 45': 'Varberg',
    '432 46': 'Lugnet',
    '432 47': 'Varberg',
    '432 48': 'Varberg',
    '432 50': 'Sörse',
    '432 51': 'Varberg',
    '432 52': 'Varberg',
    '432 53': 'Varberg',
    '432 54': 'Södra Näs',
    '432 55': 'Varberg',
    '432 56': 'Varberg',
    '432 63': 'Bua',
    '432 64': 'Varberg',
    '432 65': 'Limabacka',
    '432 66': 'Varberg',
    '432 67': 'Veddige',
    '432 68': 'Åsby',
    '432 69': 'Varberg',
    '432 74': 'Gamla Köpstad',
    '432 75': 'Träslövsläge',
    '432 76': 'Björkäng',
    '432 77': 'Himle',
    '432 78': 'Tvååker',
    '432 79': 'Varberg',
    '432 91': 'Varberg',
    '432 92': 'Trönninge',
    '432 93': 'Varberg',
    '432 94': 'Trönningenäs',
    '432 95': 'Kärradal',
    '432 96': 'Åskloster',
    '432 97': 'Rolfstorp',
    '432 98': 'Varberg',
    '432 99': 'Skällinge',
    '434 30': 'Kungsbacka',
    '434 31': 'Kungsbacka',
    '434 32': 'Kungsbacka',
    '434 33': 'Kungsbacka',
    '434 34': 'Kungsbacka',
    '434 35': 'Kungsbacka',
    '434 36': 'Kungsbacka',
    '434 37': 'Kungsbacka',
    '434 39': 'Kungsbacka',
    '434 40': 'Kungsbacka',
    '434 41': 'Kungsbacka',
    '434 42': 'Kungsbacka',
    '434 43': 'Kungsbacka',
    '434 44': 'Kungsbacka',
    '434 45': 'Södra Hammerö',
    '434 46': 'Kungsbacka',
    '434 47': 'Kungsbacka',
    '434 48': 'Kungsbacka',
    '434 50': 'Kungsbacka',
    '434 51': 'Kolla Parkstad',
    '434 52': 'Kungsbacka',
    '434 60': 'Kungsbacka',
    '434 79': 'Kungsbacka',
    '434 90': 'Vallda',
    '434 91': 'Oskarsberg',
    '434 92': 'Halla Heberg',
    '434 93': 'Backa',
    '434 94': 'Kungsbacka',
    '434 95': 'Kungsbacka',
    '434 96': 'Anneberg',
    '434 97': 'Hjälm',
    '434 98': 'Kungsbacka',
    '434 99': 'Hjälmared',
    '439 30': 'Onsala kyrkby',
    '439 31': 'Kungsbacka',
    '439 32': 'Onsala',
    '439 33': 'Kungsbacka',
    '439 34': 'Kungsbacka',
    '439 35': 'Kungsbacka',
    '439 36': 'Kungsbacka',
    '439 37': 'Kungsbacka',
    '439 51': 'Kläppa',
    '439 52': 'Kungsbacka',
    '439 53': 'Kungsbacka',
    '439 54': 'Åsa',
    '439 55': 'Vassbäck',
    '439 61': 'Kungsbacka',
    '439 62': 'Frillesås',
    '439 63': 'Kungsbacka',
    '439 64': 'Kungsbacka',
    '439 65': 'Löftaskog',
    '439 71': 'Fjärås kyrkby',
    '439 72': 'Kungsbacka',
    '439 73': 'Kungsbacka',
    '439 74': 'Kungsbacka',
    '439 75': 'Kungsbacka',
    '439 91': 'Buerås',
    '439 92': 'Västra Hagen',
    '439 93': 'Kungsbacka',
    '439 94': 'Gottskär',
    '519 92': 'Kungsäter',
    '519 93': 'Varberg',
    '831 30': 'Östersund',
    '831 31': 'Östersund',
    '831 32': 'Östersund',
    '831 33': 'Östersund',
    '831 34': 'Östersund',
    '831 35': 'Östersund',
    '831 36': 'Östersund',
    '831 37': 'Östersund',
    '831 38': 'Östersund',
    '831 39': 'Östersund',
    '831 40': 'Östersund',
    '831 41': 'Östersund',
    '831 42': 'Östersund',
    '831 43': 'Östersund',
    '831 44': 'Östersund',
    '831 45': 'Östersund',
    '831 46': 'Östersund',
    '831 47': 'Östersund',
    '831 48': 'Odenskog',
    '831 49': 'Östersund',
    '831 50': 'Östersund',
    '831 51': 'Lugnvik',
    '831 52': 'Bystranden',
    '831 53': 'Östersund',
    '831 55': 'Krokom',
    '831 60': 'Östersund',
    '831 61': 'Östersund',
    '831 62': 'Östersund',
    '831 63': 'Östersund',
    '831 64': 'Odensala',
    '831 65': 'Östersund',
    '831 66': 'Östersund',
    '831 70': 'Östersund',
    '831 71': 'Östersund',
    '831 72': 'Torvalla',
    '831 73': 'Östersund',
    '831 74': 'Östersund',
    '831 75': 'Östersund',
    '831 76': 'Östersund',
    '831 78': 'Östersund',
    '831 91': 'Lillsjöhögen',
    '831 92': 'Optand',
    '832 41': 'Östersund',
    '832 42': 'Hornsberg',
    '832 43': 'Östersund',
    '832 44': 'Östersund',
    '832 45': 'Östersund',
    '832 46': 'Östersund',
    '832 47': 'Östersund',
    '832 51': 'Östersund',
    '832 53': 'Mjälle',
    '832 54': 'Valla',
    '832 55': 'Östersund',
    '832 93': 'Östersund',
    '832 94': 'Orrviken',
    '832 96': 'Östersund',
    '833 31': 'Strömsund',
    '833 32': 'Strömsund',
    '833 33': 'Strömsund',
    '833 34': 'Strömsund',
    '833 35': 'Strömsund',
    '833 36': 'Strömsund',
    '833 41': 'Hammerdal',
    '833 48': 'Görvik',
    '833 49': 'Sikås',
    '833 51': 'Hoting',
    '833 59': 'Strömsund',
    '833 61': 'Gäddede',
    '833 91': 'Norråker',
    '833 92': 'Tullingsås',
    '833 93': 'Ulriksfors',
    '833 94': 'Strömsund',
    '833 95': 'Lövberga',
    '833 96': 'Ringvattnet',
    '833 97': 'Lidsjöberg',
    '833 98': 'Stora Blåsjön',
    '833 99': 'Strömsund',
    '834 31': 'Östersund',
    '834 32': 'Brunflo',
    '834 33': 'Östersund',
    '834 34': 'Östersund',
    '834 97': 'Östersund',
    '834 98': 'Marieby',
    '834 99': 'Tandsbyn',
    '835 31': 'Krokom',
    '835 32': 'Krokom',
    '835 33': 'Krokom',
    '835 40': 'Nälden',
    '835 41': 'Dvärsätt',
    '835 61': 'Föllinge',
    '835 62': 'Laxsjö',
    '835 63': 'Hotagen',
    '835 64': 'Krokom',
    '835 67': 'Valsjöbyn',
    '835 68': 'Östersund',
    '835 91': 'Rödön',
    '835 92': 'Krokom',
    '835 93': 'Krokom',
    '835 94': 'Aspås',
    '835 95': 'Ytterån',
    '835 96': 'Trångsviken',
    '835 97': 'Kaxås',
    '835 98': 'Tulleråsen',
    '836 31': 'Lit',
    '836 91': 'Östersund',
    '836 92': 'Östersund',
    '836 93': 'Häggenås',
    '836 94': 'Ås',
    '836 95': 'Sånghusvallen',
    '837 31': 'Järpen',
    '837 32': 'Åre',
    '837 41': 'Mörsil',
    '837 51': 'Åre',
    '837 52': 'Åre',
    '837 71': 'Ånn',
    '837 72': 'Åre',
    '837 75': 'Åre',
    '837 91': 'Mällbyn',
    '837 92': 'Ocke',
    '837 93': 'Åre',
    '837 94': 'Huså',
    '837 95': 'Undersåker',
    '837 96': 'Ottsjö',
    '837 97': 'Åre Björnen',
    '837 98': 'Åre',
    '837 99': 'Storlien',
    '840 80': 'Lillhärdal',
    '840 85': 'Lofsdalen',
    '840 90': 'Ytterhogdal',
    '840 91': 'Vemhån',
    '840 92': 'Vemdalen',
    '840 93': 'Hede',
    '840 94': 'Högvålen',
    '840 95': 'Funäsdalen',
    '840 96': 'Härjedalen',
    '840 97': 'Bruksvallarna',
    '840 98': 'Tänndalen',
    '842 31': 'Härjedalen',
    '842 32': 'Sveg',
    '842 33': 'Härjedalen',
    '842 91': 'Linsell',
    '842 92': 'Älvros',
    '842 93': 'Härjedalen',
    '842 94': 'Duvberg',
    '843 31': 'Bräcke',
    '843 32': 'Bräcke',
    '843 41': 'Gällö',
    '843 91': 'Bräcke',
    '843 92': 'Ocksjön',
    '843 93': 'Nyhem',
    '843 94': 'Fjällsta',
    '843 95': 'Bräcke',
    '843 96': 'Bräcke',
    '843 97': 'Pilgrimstad',
    '843 98': 'Sörbygden',
    '844 31': 'Ragunda',
    '844 32': 'Hammarstrand',
    '844 41': 'Bispgården',
    '844 51': 'Stugun',
    '844 61': 'Kälarne',
    '844 91': 'Gevåg',
    '844 92': 'Ragunda',
    '844 93': 'Ragunda',
    '844 94': 'Sågstugan',
    '844 95': 'Strömsnäs',
    '844 96': 'Ragunda',
    '844 97': 'Bräcke',
    '844 98': 'Bräcke',
    '845 31': 'Svenstavik',
    '845 51': 'Åsarna',
    '845 52': 'Skålan',
    '845 61': 'Berg',
    '845 62': 'Hoverberg',
    '845 71': 'Myrviken',
    '845 72': 'Berg',
    '845 73': 'Oviken',
    '845 91': 'Hallen',
    '845 93': 'Fåker',
    '845 94': 'Hackås',
    '845 95': 'Rätan',
    '845 97': 'Klövsjö',
    '845 98': 'Storsjö',
    '845 99': 'Berg',
    '880 50': 'Backe',
    '880 51': 'Rossön',
    '917 04': 'Borgafjäll',
    '331 30': 'Värnamo',
    '331 31': 'Värnamo',
    '331 32': 'Värnamo',
    '331 33': 'Värnamo',
    '331 34': 'Värnamo',
    '331 35': 'Värnamo',
    '331 37': 'Värnamo',
    '331 40': 'Värnamo',
    '331 41': 'Värnamo',
    '331 42': 'Värnamo',
    '331 43': 'Värnamo',
    '331 44': 'Värnamo',
    '331 50': 'Värnamo',
    '331 51': 'Värnamo',
    '331 52': 'Värnamo',
    '331 53': 'Värnamo',
    '331 54': 'Värnamo',
    '331 55': 'Värnamo',
    '331 56': 'Värnamo',
    '331 71': 'Forsheda',
    '331 72': 'Värnamo',
    '331 73': 'Bor',
    '331 74': 'Gällaryd',
    '331 76': 'Rydaholm',
    '331 77': 'Värnamo',
    '331 79': 'Horda',
    '331 91': 'Värnamo',
    '331 92': 'Hörle',
    '331 93': 'Värnamo',
    '331 94': 'Hånger',
    '331 95': 'Kärda',
    '331 96': 'Värnamo',
    '331 97': 'Tånnö',
    '331 98': 'Os',
    '332 30': 'Gislaved',
    '332 31': 'Gislaved',
    '332 32': 'Gislaved',
    '332 33': 'Gislaved',
    '332 34': 'Gislaved',
    '332 35': 'Gislaved',
    '332 36': 'Gislaved',
    '332 37': 'Gislaved',
    '332 38': 'Gislaved',
    '332 91': 'Gislaved',
    '332 92': 'Gislaved',
    '333 30': 'Gislaved',
    '333 31': 'Smålandsstenar',
    '333 32': 'Gislaved',
    '333 33': 'Gislaved',
    '333 71': 'Bredaryd',
    '333 72': 'Värnamo',
    '333 73': 'Ås',
    '333 74': 'Lanna',
    '333 75': 'Reftele',
    '333 76': 'Gislaved',
    '333 77': 'Burseryd',
    '333 78': 'Gislaved',
    '333 91': 'Gislaved',
    '333 92': 'Broaryd',
    '333 93': 'Skeppshult',
    '334 31': 'Gislaved',
    '334 32': 'Anderstorp',
    '334 33': 'Gislaved',
    '334 35': 'Gislaved',
    '334 91': 'Gislaved',
    '335 30': 'Gnosjö',
    '335 31': 'Gnosjö',
    '335 32': 'Gnosjö',
    '335 33': 'Gnosjö',
    '335 71': 'Hestra',
    '335 72': 'Gislaved',
    '335 73': 'Hillerstorp',
    '335 74': 'Törestorp',
    '335 91': 'Gnosjö',
    '335 92': 'Nissafors',
    '335 93': 'Åsenhöga',
    '335 94': 'Marieholm',
    '335 96': 'Gnosjö',
    '553 02': 'Fridhem',
    '553 03': 'Solåsen',
    '553 05': 'Ryhov',
    '553 07': 'Ljungarum',
    '553 08': 'Jönköping',
    '553 10': 'Lerhagen',
    '553 11': 'Jönköping',
    '553 12': 'Jönköping',
    '553 13': 'Bäckalyckan',
    '553 14': 'Jönköping',
    '553 15': 'Tändsticksområdet',
    '553 16': 'Jönköping',
    '553 17': 'Väster',
    '553 18': 'Jönköping',
    '553 20': 'Öster',
    '553 21': 'Jönköping',
    '553 22': 'Jönköping',
    '553 23': 'Kålgården',
    '553 24': 'Jönköping',
    '553 30': 'Munksjöstaden',
    '553 31': 'Jönköping',
    '553 32': 'Söder',
    '553 33': 'Jönköping',
    '553 34': 'Jönköping',
    '553 35': 'Jönköping',
    '553 36': 'Jönköping',
    '553 37': 'Jönköping',
    '553 38': 'Torpa',
    '553 39': 'Gamla Råslätt',
    '554 38': 'Jönköping',
    '554 39': 'Mariebo',
    '554 45': 'Dalvik',
    '554 46': 'Skänkeberg',
    '554 47': 'Hisingstorp',
    '554 48': 'Bymarken',
    '554 49': 'Samset',
    '554 50': 'Jönköping',
    '554 51': 'Liljeholmen',
    '554 52': 'Jönköping',
    '554 53': 'Jönköping',
    '554 54': 'Rosenlund',
    '554 56': 'Jönköping',
    '554 57': 'Jönköping',
    '554 58': 'Jönköping',
    '554 59': 'Jönköping',
    '554 62': 'Jönköping',
    '554 63': 'Jönköping',
    '554 64': 'Österängen',
    '554 65': 'Jönköping',
    '554 66': 'Vättersnäs',
    '554 72': 'Gräshagen',
    '554 73': 'Jönköping',
    '554 74': 'Tokarp',
    '555 92': 'Lovsjö',
    '555 93': 'Sandseryd',
    '555 94': 'Kortebo',
    '556 10': 'Jönköping',
    '556 11': 'Jönköping',
    '556 12': 'Jönköping',
    '556 14': 'Råslätt',
    '556 25': 'Grästorp',
    '556 26': 'Hökhult',
    '556 27': 'Hagaberg',
    '556 28': 'Åsafors',
    '556 29': 'Branten',
    '556 31': 'Odensjö',
    '556 32': 'Jönköping',
    '556 33': 'Jönköping',
    '556 50': 'Jönköping',
    '556 52': 'Jönköping',
    '560 13': 'Hok',
    '561 30': 'Jönköping',
    '561 31': 'Jönköping',
    '561 32': 'Huskvarna',
    '561 33': 'Jönköping',
    '561 34': 'Jönköping',
    '561 35': 'Egna Hem',
    '561 36': 'Jönköping',
    '561 38': 'Jöransberg',
    '561 39': 'Pettersberg',
    '561 40': 'Jutaholm',
    '561 41': 'Jönköping',
    '561 42': 'Bråneryd',
    '561 43': 'Jönköping',
    '561 44': 'Jönköping',
    '561 46': 'Kaxholmen',
    '561 48': 'Jönköping',
    '561 49': 'Jönköping',
    '561 50': 'Jönköping',
    '561 51': 'Öxnehaga',
    '561 60': 'Tenhult',
    '561 61': 'Jönköping',
    '561 62': 'Öggestorp',
    '561 63': 'Ödestugu',
    '561 91': 'Skärstad',
    '561 92': 'Gissebo',
    '561 93': 'Jönköping',
    '561 95': 'Jönköping',
    '561 96': 'Lekeryd',
    '562 30': 'Jönköping',
    '562 31': 'Jönköping',
    '562 32': 'Norrahammar',
    '562 33': 'Jönköping',
    '562 40': 'Jönköping',
    '562 41': 'Jönköping',
    '562 42': 'Taberg',
    '562 50': 'Månsarp',
    '562 91': 'Vaggeryd',
    '563 31': 'Gränna',
    '563 32': 'Jönköping',
    '563 91': 'Örserum',
    '563 92': 'Jönköping',
    '563 93': 'Ölmstad',
    '563 94': 'Tunnerstad',
    '564 31': 'Sjöåkra',
    '564 32': 'Jönköping',
    '564 33': 'Attarp',
    '564 34': 'Nyarp',
    '564 35': 'Backamo',
    '564 36': 'Berghalla',
    '564 91': 'Granshult',
    '565 31': 'Mullsjö',
    '565 32': 'Mullsjö',
    '565 33': 'Mullsjö',
    '565 34': 'Mullsjö',
    '565 76': 'Bottnaryd',
    '565 91': 'Mullsjö',
    '565 92': 'Mullsjö',
    '565 94': 'Sandhem',
    '565 96': 'Jönköping',
    '565 97': 'Jönköping',
    '566 31': 'Habo',
    '566 32': 'Habo',
    '566 33': 'Habo',
    '566 34': 'Habo',
    '566 35': 'Habo',
    '566 36': 'Habo',
    '566 91': 'Furusjö',
    '566 92': 'Fagerhult',
    '566 93': 'Brandstorp',
    '567 30': 'Vaggeryd',
    '567 31': 'Vaggeryd',
    '567 32': 'Vaggeryd',
    '567 33': 'Vaggeryd',
    '567 91': 'Vaggeryd',
    '567 92': 'Byarum',
    '568 30': 'Vaggeryd',
    '568 31': 'Marielundsområdet',
    '568 32': 'Vaggeryd',
    '568 91': 'Vaggeryd',
    '568 92': 'Klevshult',
    '568 93': 'Vaggeryd',
    '570 19': 'Pauliström',
    '570 31': 'Ingatorp',
    '570 32': 'Bellö',
    '570 34': 'Bruzaholm',
    '571 31': 'Nässjö',
    '571 32': 'Nässjö',
    '571 33': 'Nässjö',
    '571 34': 'Norrboda',
    '571 35': 'Nässjö',
    '571 36': 'Nässjö',
    '571 37': 'Nässjö',
    '571 38': 'Nässjö',
    '571 39': 'Nässjö',
    '571 40': 'Nässjö',
    '571 41': 'Nässjö',
    '571 42': 'Nässjö',
    '571 43': 'Nässjö',
    '571 61': 'Bodafors',
    '571 62': 'Nässjö',
    '571 63': 'Nässjö',
    '571 64': 'Nässjö',
    '571 65': 'Grimstorp',
    '571 66': 'Nässjö',
    '571 67': 'Nässjö',
    '571 68': 'Malmbäck',
    '571 69': 'Nässjö',
    '571 71': 'Anneberg',
    '571 72': 'Ormaryd',
    '571 73': 'Nässjö',
    '571 74': 'Nässjö',
    '571 75': 'Fredriksdal',
    '571 76': 'Solberga',
    '571 77': 'Nässjö',
    '571 78': 'Forserum',
    '571 91': 'Nässjö',
    '571 92': 'Nässjö',
    '571 93': 'Nässjö',
    '571 94': 'Nässjö',
    '571 95': 'Nässjö',
    '571 97': 'Jönköping',
    '573 31': 'Tranås',
    '573 32': 'Tranås',
    '573 33': 'Tranås',
    '573 34': 'Tranås',
    '573 35': 'Tranås',
    '573 36': 'Tranås',
    '573 37': 'Tranås',
    '573 38': 'Tranås',
    '573 39': 'Tranås',
    '573 40': 'Tranås',
    '573 41': 'Tranås',
    '573 42': 'Tranås',
    '573 43': 'Tranås',
    '573 61': 'Sommen',
    '573 73': 'Sunhultsbrunn',
    '573 91': 'Tranås',
    '573 92': 'Gripenberg',
    '573 93': 'Tranås',
    '573 96': 'Aneby',
    '573 97': 'Tranås',
    '573 98': 'Adelöv',
    '574 31': 'Vetlanda',
    '574 32': 'Vetlanda',
    '574 33': 'Vetlanda',
    '574 34': 'Vetlanda',
    '574 35': 'Vetlanda',
    '574 36': 'Vetlanda',
    '574 37': 'Vetlanda',
    '574 38': 'Vetlanda',
    '574 39': 'Vetlanda',
    '574 40': 'Vetlanda',
    '574 41': 'Bäckseda',
    '574 42': 'Vetlanda',
    '574 43': 'Vetlanda',
    '574 50': 'Ekenässjön',
    '574 53': 'Holsbybrunn',
    '574 54': 'Vetlanda',
    '574 55': 'Kvillsfors',
    '574 71': 'Myresjö',
    '574 72': 'Landsbro',
    '574 73': 'Vetlanda',
    '574 74': 'Ramkvilla',
    '574 75': 'Korsberga',
    '574 76': 'Vetlanda',
    '574 91': 'Vetlanda',
    '574 92': 'Vetlanda',
    '574 93': 'Skede',
    '574 94': 'Vetlanda',
    '574 95': 'Björköby',
    '574 96': 'Skönberga',
    '574 97': 'Stenberga',
    '574 98': 'Nye',
    '575 31': 'Eksjö',
    '575 32': 'Eksjö',
    '575 33': 'Eksjö',
    '575 34': 'Eksjö',
    '575 35': 'Eksjö',
    '575 36': 'Eksjö',
    '575 37': 'Eksjö',
    '575 38': 'Eksjö',
    '575 39': 'Eksjö',
    '575 91': 'Eksjö',
    '575 92': 'Hult',
    '575 93': 'Eksjö',
    '575 94': 'Eksjö',
    '575 95': 'Höreda',
    '575 96': 'Eksjö',
    '576 31': 'Sävsjö',
    '576 32': 'Sävsjö',
    '576 33': 'Sävsjö',
    '576 35': 'Sävsjö',
    '576 36': 'Sävsjö',
    '576 91': 'Hylletofta',
    '576 92': 'Skepperstad',
    '576 93': 'Rörvik',
    '576 94': 'Sävsjö',
    '576 95': 'Stockaryd',
    '576 96': 'Vrigstad',
    '576 97': 'Sävsjö',
    '578 31': 'Aneby',
    '578 32': 'Aneby',
    '578 33': 'Aneby',
    '578 74': 'Frinnaryd',
    '578 75': 'Flisby',
    '578 91': 'Aneby',
    '578 92': 'Aneby',
    '578 93': 'Aneby',
    '578 94': 'Aneby',
    '578 95': 'Nässjö',
    '598 97': 'Mariannelund',
    '598 98': 'Eksjö',
    '361 30': 'Emmaboda',
    '361 31': 'Emmaboda',
    '361 32': 'Emmaboda',
    '361 33': 'Emmaboda',
    '361 42': 'Lindås',
    '361 53': 'Johansfors',
    '361 54': 'Vissefjärda',
    '361 91': 'Emmaboda',
    '361 92': 'Ryggamo',
    '361 93': 'Algutsboda',
    '361 94': 'Eriksmåla',
    '361 95': 'Långasjö',
    '361 96': 'Emmaboda',
    '361 97': 'Boda Glasbruk',
    '373 79': 'Brömsebro Norra',
    '382 30': 'Nybro',
    '382 31': 'Nybro',
    '382 32': 'Nybro',
    '382 33': 'Nybro',
    '382 34': 'Nybro',
    '382 35': 'Nybro',
    '382 36': 'Blomstermålen',
    '382 37': 'Nybro',
    '382 38': 'Nybro',
    '382 39': 'Nybro',
    '382 40': 'Nybro',
    '382 41': 'Nybro',
    '382 42': 'Nybro',
    '382 43': 'Nybro',
    '382 44': 'Nybro',
    '382 45': 'Nybro',
    '382 46': 'Nybro',
    '382 71': 'Orrefors',
    '382 72': 'Flemmingeland',
    '382 73': 'Kråksmåla',
    '382 74': 'Bäckebo',
    '382 75': 'Nybro',
    '382 76': 'Målerås',
    '382 90': 'Örsjö',
    '382 91': 'Duvetorp',
    '382 92': 'Nybro',
    '382 93': 'Nybro',
    '382 94': 'Nybro',
    '382 96': 'Nybro',
    '382 97': 'Eskilsryd',
    '383 30': 'Mönsterås',
    '383 31': 'Mönsterås',
    '383 32': 'Mönsterås',
    '383 33': 'Mönsterås',
    '383 34': 'Mönsterås',
    '383 35': 'Mönsterås',
    '383 36': 'Mönsterås',
    '383 37': 'Mönsterås',
    '383 38': 'Mönsterås',
    '383 39': 'Mönsterås',
    '383 91': 'Mönsterås',
    '383 92': 'Mönsterås',
    '384 30': 'Blomstermåla',
    '384 31': 'Mönsterås',
    '384 40': 'Ålem',
    '384 71': 'Timmernabben',
    '384 72': 'Mönsterås',
    '384 73': 'Fliseryd',
    '384 91': 'Mönsterås',
    '384 92': 'Mönsterås',
    '384 93': 'Mönsterås',
    '385 30': 'Torsås',
    '385 31': 'Torsås',
    '385 32': 'Torsås',
    '385 33': 'Torsås',
    '385 34': 'Torsås',
    '385 40': 'Bergkvara',
    '385 50': 'Torsås',
    '385 51': 'Söderåkra',
    '385 90': 'Torsås',
    '385 91': 'Torsås',
    '385 92': 'Gullabo',
    '385 93': 'Torsås',
    '385 94': 'Torsås',
    '385 95': 'Torsås',
    '385 97': 'Djursvik',
    '385 98': 'Bröms',
    '385 99': 'Torsås',
    '386 30': 'Mörbylånga',
    '386 31': 'Färjestaden',
    '386 32': 'Mörbylånga',
    '386 33': 'Mörbylånga',
    '386 34': 'Mörbylånga',
    '386 35': 'Mörbylånga',
    '386 50': 'Mörbylånga',
    '386 51': 'Mörbylånga',
    '386 60': 'Mörbylånga',
    '386 61': 'Mörbylånga',
    '386 62': 'Mörbylånga',
    '386 63': 'Degerhamn',
    '386 64': 'Mörbylånga',
    '386 90': 'Mörbylånga',
    '386 92': 'Gårdby',
    '386 93': 'Vickleby',
    '386 94': 'Borgholm',
    '386 95': 'Mörbylånga',
    '386 96': 'Arontorp',
    '387 31': 'Borgholm',
    '387 32': 'Borgholm',
    '387 33': 'Borgholm',
    '387 34': 'Borgholm',
    '387 35': 'Borgholm',
    '387 36': 'Borgholm',
    '387 37': 'Björkviken',
    '387 50': 'Köpingsvik',
    '387 52': 'Borgholm',
    '387 70': 'Borgholm',
    '387 71': 'Borgholm',
    '387 72': 'Löttorp',
    '387 73': 'Borgholm',
    '387 74': 'Borgholm',
    '387 75': 'Borgholm',
    '387 90': 'Borgholm',
    '387 91': 'Borgholm',
    '387 92': 'Borgholm',
    '387 93': 'Borgholm',
    '387 94': 'Borgholm',
    '387 95': 'Borgholm',
    '387 96': 'Borgholm',
    '388 30': 'Ljungbyholm',
    '388 31': 'Kalmar',
    '388 32': 'Kalmar',
    '388 40': 'Trekanten',
    '388 41': 'Kalmar',
    '388 50': 'Påryd',
    '388 91': 'Vassmolösa',
    '388 92': 'Kalmar',
    '388 93': 'Tvärskog',
    '388 94': 'Kalmar',
    '388 95': 'Kalmar',
    '388 96': 'Hagby',
    '388 97': 'Halltorp',
    '388 98': 'Kalmar',
    '388 99': 'Kalmar',
    '392 30': 'Kalmar',
    '392 31': 'Kalmar',
    '392 32': 'Kalmar',
    '392 33': 'Gamla Stan',
    '392 34': 'Kalmar',
    '392 35': 'Kalmar',
    '392 36': 'Getingen',
    '392 37': 'Kalmar',
    '392 38': 'Oxhagen',
    '392 39': 'Kalmar',
    '392 41': 'Kalmar',
    '392 43': 'Kalmar',
    '392 44': 'Tegelviken',
    '392 45': 'Kalmar',
    '392 46': 'Kalmar',
    '392 47': 'Kalmar',
    '392 49': 'Malmen',
    '393 49': 'Kalmar',
    '393 50': 'Funkabo',
    '393 51': 'Berga',
    '393 52': 'Kalmar',
    '393 53': 'Djurängen',
    '393 54': 'Krafslösa',
    '393 55': 'Vimpeltorpet',
    '393 56': 'Kalmar',
    '393 57': 'Kalmar',
    '393 58': 'Norrliden',
    '393 59': 'Bergavik',
    '393 60': 'Kalmar',
    '393 61': 'Kalmar',
    '393 63': 'Kalmar',
    '393 64': 'Kalmar',
    '393 65': 'Lindsdal',
    '394 70': 'Smedby',
    '394 71': 'Kalmar',
    '394 77': 'Rinkabyholm',
    '395 90': 'Kalmar',
    '395 91': 'Kalmar',
    '395 93': 'Rockneby',
    '395 94': 'Drag',
    '395 95': 'Kalmar',
    '395 97': 'Läckeby',
    '395 98': 'Kalmar',
    '570 72': 'Fagerhult',
    '570 75': 'Fågelfors',
    '570 76': 'Ruda',
    '570 81': 'Järnforsen',
    '570 90': 'Påskallavik',
    '570 91': 'Kristdala',
    '572 30': 'Oskarshamn',
    '572 31': 'Oskarshamn',
    '572 32': 'Norrtorn',
    '572 35': 'Oskarshamn',
    '572 36': 'Oskarshamn',
    '572 37': 'Norrby',
    '572 40': 'Oskarshamn',
    '572 41': 'Oskarshamn',
    '572 42': 'Oskarshamn',
    '572 50': 'Oskarshamn',
    '572 51': 'Oskarshamn',
    '572 55': 'Oskarshamn',
    '572 56': 'Oskarshamn',
    '572 57': 'Oskarshamn',
    '572 58': 'Oskarshamn',
    '572 60': 'Oskarshamn',
    '572 61': 'Kristineberg',
    '572 62': 'Oskarshamn',
    '572 63': 'Mysingsö',
    '572 75': 'Figeholm',
    '572 76': 'Fårbo',
    '572 91': 'Oskarshamn',
    '572 92': 'Saltvik',
    '572 93': 'Oskarshamn',
    '572 95': 'Misterhult',
    '572 96': 'Mörtfors',
    '577 30': 'Hultsfred',
    '577 31': 'Hultsfred',
    '577 32': 'Hultsfred',
    '577 33': 'Hultsfred',
    '577 34': 'Hultsfred',
    '577 35': 'Hultsfred',
    '577 36': 'Hultsfred',
    '577 37': 'Hultsfred',
    '577 38': 'Hultsfred',
    '577 39': 'Hultsfred',
    '577 50': 'Hultsfred',
    '577 51': 'Hultsfred',
    '577 71': 'Hultsfred',
    '577 72': 'Virserum',
    '577 73': 'Hultsfred',
    '577 74': 'Målilla',
    '577 75': 'Mörlunda',
    '577 90': 'Vena',
    '577 91': 'Hultsfred',
    '577 92': 'Hultsfred',
    '577 93': 'Hultsfred',
    '577 94': 'Lönneberga',
    '577 95': 'Hultsfred',
    '577 96': 'Hultsfred',
    '577 97': 'Rosenfors',
    '577 98': 'Hultsfred',
    '579 30': 'Högsby',
    '579 31': 'Högsby',
    '579 32': 'Högsby',
    '579 33': 'Högsby',
    '579 40': 'Berga',
    '579 90': 'Högsby',
    '579 91': 'Bockara',
    '579 92': 'Sinnerbo',
    '590 92': 'Totebo',
    '590 95': 'Loftahammar',
    '590 96': 'Överum',
    '590 98': 'Edsbruk',
    '593 30': 'Västervik',
    '593 31': 'Västervik',
    '593 32': 'Västervik',
    '593 33': 'Västervik',
    '593 34': 'Västervik',
    '593 35': 'Västervik',
    '593 36': 'Västervik',
    '593 37': 'Västervik',
    '593 38': 'Grantorpet',
    '593 39': 'Ludvigsborg',
    '593 40': 'Västervik',
    '593 41': 'Västervik',
    '593 42': 'Västervik',
    '593 43': 'Jenny',
    '593 44': 'Gertrudsvik',
    '593 50': 'Västervik',
    '593 51': 'Västervik',
    '593 52': 'Västervik',
    '593 53': 'Västervik',
    '593 54': 'Västervik',
    '593 61': 'Västervik',
    '593 62': 'Västervik',
    '593 70': 'Ankarsrum',
    '593 71': 'Västervik',
    '593 73': 'Hjorted',
    '593 74': 'Västrum',
    '593 75': 'Gunnebo',
    '593 76': 'Blackstad',
    '593 91': 'Västervik',
    '593 92': 'Västervik',
    '593 93': 'Västervik',
    '593 95': 'Almvik',
    '593 96': 'Verkebäck',
    '593 97': 'Västervik',
    '594 30': 'Västervik',
    '594 31': 'Västervik',
    '594 32': 'Gamleby',
    '594 91': 'Hallingeberg',
    '594 92': 'Västervik',
    '594 93': 'Björnsholm',
    '594 94': 'Odensvi',
    '598 30': 'Vimmerby',
    '598 31': 'Vimmerby',
    '598 32': 'Vimmerby',
    '598 34': 'Norrgärdet',
    '598 35': 'Vimmerby',
    '598 36': 'Vimmerby',
    '598 37': 'Vimmerby',
    '598 38': 'Vimmerby',
    '598 39': 'Vimmerby',
    '598 40': 'Åbro',
    '598 70': 'Storebro',
    '598 71': 'Södra Vi',
    '598 72': 'Vimmerby',
    '598 73': 'Gullringen',
    '598 91': 'Vimmerby',
    '598 92': 'Rumskulla',
    '598 93': 'Vimmerby',
    '598 94': 'Locknevi',
    '598 95': 'Frödinge',
    '598 96': 'Tuna',
    '285 31': 'Markaryd',
    '285 32': 'Markaryd',
    '285 33': 'Markaryd',
    '285 34': 'Markaryd',
    '285 35': 'Markaryd',
    '285 36': 'Markaryd',
    '285 37': 'Markaryd',
    '285 38': 'Markaryd',
    '285 39': 'Markaryd',
    '285 40': 'Timsfors',
    '285 91': 'Markaryd',
    '285 93': 'Markaryd',
    '287 31': 'Strömsnäsbruk',
    '287 32': 'Markaryd',
    '287 33': 'Markaryd',
    '287 34': 'Markaryd',
    '287 72': 'Traryd',
    '287 91': 'Markaryd',
    '287 92': 'Markaryd',
    '287 93': 'Hinneryd',
    '341 31': 'Ljungby',
    '341 32': 'Ljungby',
    '341 33': 'Stensberg',
    '341 34': 'Annelund',
    '341 35': 'Kungshög',
    '341 36': 'Brunnsgården',
    '341 37': 'Ljungby',
    '341 38': 'Lagavallen',
    '341 39': 'Hångers',
    '341 40': 'Replösa',
    '341 41': 'Hjortsberg',
    '341 42': 'Ekebacken',
    '341 43': 'Centrum',
    '341 44': 'Villastaden',
    '341 50': 'Lagan',
    '341 51': 'Ljungby',
    '341 52': 'Ljungby',
    '341 55': 'Vittaryd',
    '341 60': 'Ljungby',
    '341 70': 'Kånna',
    '341 71': 'Lidhult',
    '341 72': 'Ljungby',
    '341 73': 'Ljungby',
    '341 74': 'Ljungby',
    '341 75': 'Hamneda',
    '341 76': 'Ryssby',
    '341 77': 'Agunnaryd',
    '341 91': 'Södra Ljunga',
    '341 93': 'Bolmsö kyrkby',
    '341 94': 'Angelstad',
    '341 96': 'Ljungby',
    '342 30': 'Alvesta',
    '342 31': 'Alvesta',
    '342 32': 'Alvesta',
    '342 33': 'Alvesta',
    '342 34': 'Alvesta',
    '342 35': 'Alvesta',
    '342 36': 'Alvesta',
    '342 50': 'Vislanda',
    '342 51': 'Alvesta',
    '342 52': 'Alvesta',
    '342 53': 'Alvesta',
    '342 54': 'Alvesta',
    '342 55': 'Grimslöv',
    '342 60': 'Moheda',
    '342 61': 'Alvesta',
    '342 62': 'Alvesta',
    '342 63': 'Alvesta',
    '342 64': 'Växjö',
    '342 65': 'Torpsbruk',
    '342 90': 'Alvesta',
    '342 91': 'Alvesta',
    '342 92': 'Alvesta',
    '342 93': 'Hjortsberga',
    '342 94': 'Alvesta',
    '342 95': 'Alvesta',
    '343 30': 'Älmhult',
    '343 31': 'Älmhult',
    '343 32': 'Älmhult',
    '343 33': 'Älmhult',
    '343 34': 'Älmhult',
    '343 35': 'Älmhult',
    '343 36': 'Älmhult',
    '343 37': 'Älmhult',
    '343 38': 'Älmhult',
    '343 71': 'Diö',
    '343 72': 'Eneryda',
    '343 73': 'Virestad',
    '343 74': 'Liatorp',
    '343 90': 'Älmhult',
    '343 91': 'Älmhult',
    '343 92': 'Älmhult',
    '343 93': 'Pjätteryd',
    '343 94': 'Älmhult',
    '343 95': 'Delary',
    '343 96': 'Älmhult',
    '343 97': 'Älmhult',
    '352 20': 'Vikaholm',
    '352 22': 'Växjö',
    '352 29': 'Växjö',
    '352 30': 'Växjö',
    '352 31': 'Växjö',
    '352 32': 'Växjö',
    '352 33': 'Växjö',
    '352 34': 'Växjö',
    '352 35': 'Söder',
    '352 36': 'Väster',
    '352 37': 'Växjö',
    '352 38': 'Växjö',
    '352 39': 'Växjö',
    '352 40': 'Växjö',
    '352 41': 'Öster',
    '352 42': 'Högstorp',
    '352 43': 'Norr',
    '352 44': 'Växjö',
    '352 45': 'Hov',
    '352 46': 'Västra Mark',
    '352 47': 'Araby',
    '352 48': 'Växjö',
    '352 49': 'Växjö',
    '352 50': 'Växjö',
    '352 51': 'Teleborg',
    '352 52': 'Växjö',
    '352 53': 'Växjö',
    '352 54': 'Växjö',
    '352 55': 'Växjö',
    '352 56': 'Växjö',
    '352 57': 'Växjö',
    '352 58': 'Bredvik',
    '352 59': 'Växjö',
    '352 60': 'Växjö',
    '352 61': 'Hovshaga',
    '352 62': 'Växjö',
    '352 63': 'Växjö',
    '352 64': 'Växjö',
    '352 65': 'Växjö',
    '352 70': 'Växjö',
    '352 71': 'Växjö',
    '352 72': 'Sandsbro',
    '352 73': 'Öjaby',
    '352 74': 'Räppe',
    '355 71': 'Växjö',
    '355 72': 'Ingelstad',
    '355 73': 'Gemla',
    '355 74': 'Nöbbele',
    '355 75': 'Hemmesjö',
    '355 91': 'Växjö',
    '355 92': 'Gårdsby',
    '355 93': 'Växjö',
    '355 94': 'Vederslöv',
    '355 95': 'Tävelsås',
    '355 96': 'Växjö',
    '355 97': 'Alvesta',
    '355 98': 'Växjö',
    '355 99': 'Växjö',
    '362 30': 'Tingsryd',
    '362 31': 'Tingsryd',
    '362 32': 'Tingsryd',
    '362 40': 'Konga',
    '362 50': 'Tingsryd',
    '362 51': 'Väckelsång',
    '362 52': 'Växjö',
    '362 53': 'Växjö',
    '362 54': 'Urshult',
    '362 56': 'Ryd',
    '362 57': 'Fridafors',
    '362 58': 'Linneryd',
    '362 91': 'Tingsryd',
    '362 92': 'Tingsryd',
    '362 93': 'Tingsryd',
    '362 94': 'Tingsryd',
    '362 95': 'Bastaremåla',
    '362 96': 'Tingsryd',
    '362 97': 'Tingsryd',
    '362 98': 'Rävemåla',
    '363 30': 'Rottne',
    '363 31': 'Växjö',
    '363 33': 'Brittatorp',
    '363 34': 'Åby',
    '363 35': 'Växjö',
    '363 41': 'Braås',
    '363 42': 'Växjö',
    '363 44': 'Lammhult',
    '363 45': 'Växjö',
    '363 91': 'Dädesjö',
    '363 92': 'Alvesta',
    '363 93': 'Växjö',
    '363 94': 'Växjö',
    '364 30': 'Åseda',
    '364 31': 'Uppvidinge',
    '364 33': 'Näshult',
    '364 41': 'Uppvidinge',
    '364 42': 'Lenhovda',
    '364 43': 'Alstermo',
    '364 44': 'Lindshammar',
    '364 45': 'Uppvidinge',
    '364 91': 'Möcklehult',
    '364 92': 'Uppvidinge',
    '364 93': 'Uppvidinge',
    '364 95': 'Älghult',
    '364 96': 'Uppvidinge',
    '364 97': 'Uppvidinge',
    '365 31': 'Lessebo',
    '365 32': 'Lessebo',
    '365 33': 'Lessebo',
    '365 41': 'Hovmantorp',
    '365 42': 'Lessebo',
    '365 43': 'Kosta',
    '365 44': 'Lessebo',
    '365 91': 'Lessebo',
    '365 92': 'Bergdala',
    '365 93': 'Furuby',
    '365 94': 'Skruv',
    '579 93': 'Högsby',
    '930 81': 'Glommersträsk',
    '930 82': 'Abborrträsk',
    '930 86': 'Moskosel',
    '930 91': 'Slagnäs',
    '933 31': 'Arvidsjaur',
    '933 32': 'Arvidsjaur',
    '933 33': 'Arvidsjaur',
    '933 34': 'Arvidsjaur',
    '933 91': 'Lauker',
    '933 99': 'Arvidsjaur',
    '938 31': 'Arjeplog',
    '938 32': 'Arjeplog',
    '938 92': 'Arjeplog',
    '938 93': 'Laisvall',
    '938 94': 'Arjeplog',
    '938 95': 'Jäkkvik',
    '938 96': 'Arjeplog',
    '941 31': 'Piteå',
    '941 32': 'Piteå',
    '941 33': 'Piteå',
    '941 34': 'Piteå',
    '941 35': 'Piteå',
    '941 36': 'Piteå',
    '941 37': 'Piteå',
    '941 38': 'Piteå',
    '941 39': 'Piteå',
    '941 40': 'Piteå',
    '941 41': 'Piteå',
    '941 42': 'Piteå',
    '941 43': 'Piteå',
    '941 46': 'Piteå',
    '941 47': 'Piteå',
    '941 48': 'Piteå',
    '941 50': 'Piteå',
    '941 51': 'Piteå',
    '941 52': 'Piteå',
    '941 53': 'Piteå',
    '941 61': 'Piteå',
    '941 62': 'Piteå',
    '941 63': 'Piteå',
    '941 64': 'Piteå',
    '941 65': 'Piteå',
    '941 66': 'Piteå',
    '941 91': 'Böle',
    '941 92': 'Piteå',
    '941 99': 'Piteå',
    '942 03': 'Piteå',
    '942 04': 'Storsund',
    '942 05': 'Långträsk',
    '942 31': 'Älvsbyn',
    '942 32': 'Älvsbyn',
    '942 33': 'Älvsbyn',
    '942 34': 'Älvsbyn',
    '942 35': 'Älvsbyn',
    '942 36': 'Älvsbyn',
    '942 91': 'Vistträsk',
    '942 92': 'Korsträsk',
    '942 93': 'Tvärån',
    '942 94': 'Sikfors',
    '942 95': 'Vidsel',
    '943 31': 'Öjebyn',
    '943 32': 'Piteå',
    '943 33': 'Piteå',
    '943 35': 'Piteå',
    '943 36': 'Piteå',
    '944 31': 'Piteå',
    '944 32': 'Piteå',
    '944 71': 'Piteå',
    '944 72': 'Bergsviken',
    '944 73': 'Piteå',
    '944 91': 'Piteå',
    '944 92': 'Piteå',
    '944 93': 'Hemmingsmark',
    '944 94': 'Jävre',
    '945 31': 'Norrfjärden',
    '945 32': 'Piteå',
    '945 33': 'Rosvik',
    '945 34': 'Piteå',
    '945 91': 'Piteå',
    '945 92': 'Piteå',
    '945 93': 'Sjulsmark',
    '946 31': 'Piteå',
    '946 40': 'Svensbyn',
    '946 91': 'Roknäs',
    '946 92': 'Lillpite',
    '946 99': 'Piteå',
    '950 42': 'Morjärv',
    '952 04': 'Påläng',
    '952 31': 'Kalix',
    '952 32': 'Kalix',
    '952 33': 'Kalix',
    '952 34': 'Kalix',
    '952 35': 'Kalix',
    '952 36': 'Kalix',
    '952 41': 'Kalix',
    '952 42': 'Kalix',
    '952 43': 'Töre',
    '952 44': 'Siknäs',
    '952 50': 'Nyborg',
    '952 51': 'Rolfs',
    '952 61': 'Kalix',
    '952 62': 'Kalix',
    '952 63': 'Näsbyn',
    '952 70': 'Risön',
    '952 71': 'Karlsborg',
    '952 72': 'Sangis',
    '952 91': 'Storön',
    '952 92': 'Gammelgården',
    '952 93': 'Kälsjärv',
    '952 94': 'Björkfors',
    '952 95': 'Båtskärsnäs',
    '952 96': 'Haparanda',
    '952 97': 'Kalix',
    '953 31': 'Haparanda',
    '953 32': 'Haparanda',
    '953 33': 'Grankullen',
    '953 34': 'Närsta',
    '953 35': 'Marielund',
    '953 36': 'Granvik',
    '953 37': 'Haparanda',
    '953 91': 'Haparanda',
    '953 92': 'Vuono',
    '953 93': 'Karungi',
    '953 94': 'Seskarö',
    '953 95': 'Nikkala',
    '954 31': 'Luleå',
    '954 32': 'Luleå',
    '954 33': 'Gammelstad',
    '954 34': 'Luleå',
    '954 35': 'Luleå',
    '954 41': 'Södra Sunderbyn',
    '954 42': 'Luleå',
    '955 31': 'Råneå',
    '955 32': 'Luleå',
    '955 91': 'Böle',
    '955 92': 'Jämtön',
    '955 95': 'Niemisel',
    '955 96': 'Vitå',
    '955 99': 'Luleå',
    '956 31': 'Överkalix',
    '956 32': 'Överkalix',
    '956 91': 'Svartbyn',
    '956 92': 'Överkalix',
    '956 93': 'Kypasjärv',
    '956 98': 'Ängesån',
    '956 99': 'Överkalix',
    '957 23': 'Juoksengi',
    '957 31': 'Övertorneå',
    '957 32': 'Övertorneå',
    '957 91': 'Övertorneå',
    '957 92': 'Övertorneå',
    '957 93': 'Pello',
    '957 94': 'Aapua',
    '957 95': 'Hedenäset',
    '957 99': 'Övertorneå',
    '960 24': 'Harads',
    '960 30': 'Vuollerim',
    '960 32': 'Jokkmokk',
    '960 33': 'Murjek',
    '961 31': 'Boden',
    '961 32': 'Boden',
    '961 33': 'Boden',
    '961 34': 'Boden',
    '961 35': 'Boden',
    '961 36': 'Boden',
    '961 37': 'Boden',
    '961 38': 'Boden',
    '961 39': 'Södra Svartbyn',
    '961 40': 'Boden',
    '961 42': 'Boden',
    '961 43': 'Boden',
    '961 44': 'Boden',
    '961 45': 'Boden',
    '961 46': 'Boden',
    '961 47': 'Boden',
    '961 48': 'Boden',
    '961 49': 'Boden',
    '961 50': 'Sävast',
    '961 51': 'Boden',
    '961 61': 'Boden',
    '961 62': 'Boden',
    '961 64': 'Boden',
    '961 67': 'Boden',
    '961 68': 'Boden',
    '961 73': 'Boden',
    '961 74': 'Boden',
    '961 75': 'Boden',
    '961 76': 'Boden',
    '961 77': 'Boden',
    '961 91': 'Svartbäcken',
    '961 92': 'Lakaträsk',
    '961 93': 'Avan',
    '961 94': 'Boden',
    '961 95': 'Brännberg',
    '961 96': 'Vittjärv',
    '961 97': 'Boden',
    '961 98': 'Norra Bredåker',
    '962 02': 'Kvikkjokk',
    '962 03': 'Jokkmokk',
    '962 05': 'Kåbdalis',
    '962 31': 'Jokkmokk',
    '962 32': 'Jokkmokk',
    '962 33': 'Jokkmokk',
    '962 98': 'Jokkmokk',
    '962 99': 'Jokkmokk',
    '972 31': 'Luleå',
    '972 32': 'Luleå',
    '972 33': 'Luleå',
    '972 34': 'Luleå',
    '972 35': 'Luleå',
    '972 36': 'Varvet',
    '972 38': 'Luleå',
    '972 39': 'Gültzauudden',
    '972 41': 'Luleå',
    '972 42': 'Östermalm',
    '972 45': 'Malmudden',
    '972 51': 'Bergnäset',
    '972 52': 'Luleå',
    '972 53': 'Luleå',
    '972 54': 'Luleå',
    '973 31': 'Bergviken',
    '973 32': 'Luleå',
    '973 33': 'Luleå',
    '973 34': 'Luleå',
    '973 35': 'Klintbacken',
    '973 41': 'Mjölkudden',
    '973 42': 'Notviken',
    '973 43': 'Luleå',
    '973 44': 'Tuna',
    '973 45': 'Storheden',
    '973 46': 'Luleå',
    '973 47': 'Luleå',
    '974 32': 'Luleå',
    '974 33': 'Luleå',
    '974 34': 'Skurholmen',
    '974 35': 'Luleå',
    '974 36': 'Lövskatan',
    '974 37': 'Svartöstan',
    '974 38': 'Luleå',
    '974 39': 'Örnäset',
    '974 41': 'Luleå',
    '974 42': 'Kronan',
    '974 43': 'Luleå',
    '974 44': 'Luleå',
    '974 51': 'Lerbäcken',
    '974 52': 'Luleå',
    '974 53': 'Hertsön',
    '974 54': 'Luleå',
    '974 55': 'Luleå',
    '974 99': 'Luleå',
    '975 61': 'Bensbyn',
    '975 91': 'Klöverträsk',
    '975 92': 'Alvik',
    '975 93': 'Antnäs',
    '975 94': 'Karlsvik',
    '975 95': 'Kallax',
    '975 96': 'Björsbyn',
    '975 97': 'Persön',
    '975 98': 'Luleå',
    '976 31': 'Lulsundet',
    '976 32': 'Luleå',
    '976 33': 'Björkskatan',
    '976 34': 'Luleå',
    '976 36': 'Hällbacken',
    '977 51': 'Porsön',
    '977 52': 'Luleå',
    '977 53': 'Luleå',
    '977 54': 'Luleå',
    '977 55': 'Luleå',
    '977 56': 'Luleå',
    '977 75': 'Luleå',
    '980 10': 'Vittangi',
    '980 13': 'Lannavaara',
    '980 14': 'Övre Soppero',
    '980 16': 'Karesuando',
    '980 20': 'Svappavaara',
    '980 41': 'Hakkas',
    '980 42': 'Ullatti',
    '980 45': 'Gällivare',
    '980 60': 'Korpilombolo',
    '980 61': 'Tärendö',
    '980 62': 'Junosuando',
    '980 63': 'Kangos',
    '980 65': 'Pajala',
    '981 07': 'Abisko',
    '981 30': 'Kiruna',
    '981 31': 'Kiruna',
    '981 32': 'Kiruna',
    '981 33': 'Östermalm',
    '981 34': 'Lokstallet',
    '981 35': 'Kiruna',
    '981 36': 'Kiruna',
    '981 37': 'Kiruna',
    '981 38': 'Triangeln',
    '981 39': 'Övre Norrmalm',
    '981 40': 'Luossavaara',
    '981 41': 'Tuolluvaara',
    '981 42': 'Kiruna',
    '981 43': 'Lombolo backe',
    '981 44': 'Glaciären',
    '981 45': 'Solvinden',
    '981 46': 'Kurravaara',
    '981 47': 'Kiruna',
    '981 91': 'Jukkasjärvi',
    '981 92': 'Kiruna',
    '981 93': 'Kiruna',
    '981 94': 'Kiruna',
    '981 95': 'Kiruna',
    '981 99': 'Kiruna',
    '982 03': 'Kompelusvaara',
    '982 04': 'Skaulo',
    '982 05': 'Puoltikasvaara',
    '982 06': 'Gällivare',
    '982 07': 'Nattavaara by',
    '982 31': 'Gällivare',
    '982 32': 'Gällivare',
    '982 34': 'Gällivare',
    '982 35': 'Gällivare',
    '982 36': 'Gällivare',
    '982 37': 'Gällivare',
    '982 38': 'Gällivare',
    '982 39': 'Gällivare',
    '982 41': 'Gällivare',
    '982 60': 'Porjus',
    '982 91': 'Nilivaara',
    '982 92': 'Dokkas',
    '982 99': 'Nikkaluokta',
    '983 03': 'Tjautjas',
    '983 31': 'Gällivare',
    '983 32': 'Malmberget',
    '983 33': 'Gällivare',
    '983 34': 'Gällivare',
    '983 35': 'Gällivare',
    '983 36': 'Malmsta',
    '983 41': 'Koskullskulle',
    '984 31': 'Pajala',
    '984 32': 'Pajala',
    '984 91': 'Kaunisvaara',
    '984 92': 'Välivaara',
    '984 94': 'Lovikka',
    '984 95': 'Muodoslompolo',
    '984 99': 'Käymäjärvi',
    '591 99': 'Askersund',
    '691 31': 'Karlskoga',
    '691 32': 'Karlskoga',
    '691 33': 'Bohult',
    '691 34': 'Karlskoga',
    '691 35': 'Löten',
    '691 36': 'Karlberg',
    '691 37': 'Karlskoga',
    '691 38': 'Ekeby',
    '691 41': 'Karlskoga',
    '691 42': 'Häsängen',
    '691 43': 'Karlskoga',
    '691 44': 'Karlskoga',
    '691 45': 'Aggerud',
    '691 46': 'Karlskoga',
    '691 47': 'Storängstorp',
    '691 48': 'Stråningstorp',
    '691 50': 'Karlskoga',
    '691 51': 'Karlskoga',
    '691 52': 'Brickegården',
    '691 53': 'Rosendal',
    '691 54': 'Bråten',
    '691 92': 'Granbergsdal',
    '691 93': 'Botorp',
    '691 94': 'Karlskoga',
    '692 30': 'Kumla',
    '692 31': 'Kumla',
    '692 32': 'Kumla',
    '692 33': 'Kumla',
    '692 34': 'Kumla',
    '692 35': 'Kumla',
    '692 36': 'Kumla',
    '692 37': 'Kumla',
    '692 38': 'Kumla',
    '692 71': 'Kumla',
    '692 72': 'Hällabrottet',
    '692 73': 'Åbytorp',
    '692 75': 'Kumla',
    '692 91': 'Ekeby',
    '692 92': 'Sannahed',
    '692 93': 'Kumla',
    '693 30': 'Degerfors',
    '693 31': 'Degerfors',
    '693 32': 'Degerfors',
    '693 33': 'Degerfors',
    '693 34': 'Degerfors',
    '693 35': 'Degerfors',
    '693 70': 'Åtorp',
    '693 73': 'Svartå',
    '693 91': 'Degerfors',
    '693 92': 'Degerfors',
    '693 93': 'Degerfors',
    '693 94': 'Degerfors',
    '693 95': 'Degerfors',
    '694 30': 'Hallsberg',
    '694 31': 'Hallsberg',
    '694 32': 'Hallsberg',
    '694 33': 'Hallsberg',
    '694 34': 'Hallsberg',
    '694 35': 'Hallsberg',
    '694 36': 'Hallsberg',
    '694 37': 'Hallsberg',
    '694 50': 'Vretstorp',
    '694 60': 'Åsbro',
    '694 72': 'Östansjö',
    '694 91': 'Tunbohässle',
    '694 92': 'Skogaholm',
    '694 93': 'Hallsberg',
    '694 94': 'Askersund',
    '694 95': 'Hallsberg',
    '694 96': 'Askersund',
    '694 97': 'Rönneshytta',
    '694 98': 'Närkesberg',
    '694 99': 'Skyllberg',
    '695 30': 'Laxå',
    '695 31': 'Saltängen',
    '695 32': 'Lassåna',
    '695 50': 'Finnerödja',
    '695 60': 'Hasselfors',
    '695 72': 'Röfors',
    '695 91': 'Laxå',
    '695 92': 'Laxå',
    '695 93': 'Laxå',
    '695 94': 'Laxå',
    '695 95': 'Laxå',
    '695 96': 'Laxå',
    '695 97': 'Laxå',
    '696 30': 'Askersund',
    '696 31': 'Askersund',
    '696 32': 'Askersund',
    '696 33': 'Askersund',
    '696 34': 'Askersund',
    '696 73': 'Olshammar',
    '696 74': 'Hammar',
    '696 75': 'Åmmeberg',
    '696 76': 'Zinkgruvan',
    '696 91': 'Askersund',
    '696 92': 'Askersund',
    '696 93': 'Askersund',
    '696 94': 'Sänna',
    '696 95': 'Askersund',
    '696 96': 'Askersund',
    '697 30': 'Pålsboda',
    '697 31': 'Hallsberg',
    '697 32': 'Hallsberg',
    '697 74': 'Sköllersta',
    '697 91': 'Hallsberg',
    '697 92': 'Svennevad',
    '697 93': 'Hjortkvarn',
    '697 94': 'Hallsberg',
    '702 10': 'Örebro',
    '702 11': 'Örebro',
    '702 12': 'Örebro',
    '702 13': 'Örebro',
    '702 14': 'Örebro',
    '702 15': 'Wadköping',
    '702 16': 'Örebro',
    '702 17': 'Sörby',
    '702 18': 'Sörbyängen',
    '702 19': 'Tybble',
    '702 21': 'Brickeberg',
    '702 22': 'Örebro',
    '702 23': 'Örebro',
    '702 24': 'Örebro',
    '702 25': 'Eklunda',
    '702 26': 'Ladugårdsängen',
    '702 27': 'Örebro',
    '702 28': 'Örebro',
    '702 29': 'Örebro',
    '702 30': 'Örebro',
    '702 31': 'Marieberg',
    '702 32': 'Örnsro',
    '702 33': 'Mosås',
    '702 34': 'Örebro',
    '702 35': 'Brickebacken',
    '702 36': 'Örebro',
    '702 37': 'Örebro',
    '702 39': 'Örebro',
    '702 81': 'Örebro',
    '702 82': 'Örebro',
    '702 83': 'Örebro',
    '702 84': 'Örebro',
    '702 85': 'Almby',
    '702 86': 'Näsby',
    '703 40': 'Örebro',
    '703 41': 'Örebro',
    '703 42': 'Örebro',
    '703 43': 'Rosta',
    '703 44': 'Örebro',
    '703 45': 'Solhaga',
    '703 46': 'Västhaga',
    '703 47': 'Örebro',
    '703 48': 'Älvtomta',
    '703 49': 'Örebro',
    '703 50': 'Markbacken',
    '703 51': 'Varberga',
    '703 52': 'Örebro',
    '703 53': 'Örebro',
    '703 54': 'Örebro',
    '703 55': 'Örebro',
    '703 56': 'Örebro',
    '703 57': 'Örebro',
    '703 58': 'Hjärsta',
    '703 59': 'Baronbackarna',
    '703 60': 'Nya Hjärsta',
    '703 61': 'Örebro',
    '703 62': 'Örebro',
    '703 63': 'Örebro',
    '703 64': 'Örebro',
    '703 65': 'Rynninge',
    '703 66': 'Norr',
    '703 67': 'Örebro',
    '703 68': 'Örebro',
    '703 69': 'Örebro',
    '703 70': 'Örebro',
    '703 71': 'Vivalla',
    '703 72': 'Örebro',
    '703 73': 'Örebro',
    '703 74': 'Boglundsängen',
    '703 75': 'Lillån',
    '703 76': 'Hovsta',
    '703 78': 'Björkhaga',
    '703 80': 'Örebro',
    '703 81': 'Örebro',
    '703 82': 'Örebro',
    '703 83': 'Hagaby',
    '703 85': 'Glanshammar',
    '705 10': 'Ekeby-Almby',
    '705 91': 'Örebro',
    '705 92': 'Örebro',
    '705 93': 'Örebro',
    '705 94': 'Almbro',
    '705 95': 'Ormesta',
    '705 96': 'Örebro',
    '705 97': 'Fiskartorpet',
    '705 98': 'Örebro',
    '711 30': 'Lindesberg',
    '711 31': 'Lindesberg',
    '711 32': 'Lindesberg',
    '711 33': 'Lindesberg',
    '711 34': 'Lindesberg',
    '711 35': 'Lindesberg',
    '711 36': 'Lindesberg',
    '711 72': 'Vedevåg',
    '711 76': 'Storå',
    '711 77': 'Stråssa',
    '711 78': 'Guldsmedshyttan',
    '711 91': 'Lindesberg',
    '711 92': 'Lindesberg',
    '711 93': 'Lindesberg',
    '711 94': 'Kåfalla',
    '711 95': 'Gusselby',
    '711 96': 'Lindesberg',
    '711 97': 'Lindesberg',
    '711 98': 'Ramsberg',
    '712 30': 'Hällefors',
    '712 31': 'Hällefors',
    '712 32': 'Hällefors',
    '712 33': 'Hällefors',
    '712 34': 'Hällefors',
    '712 60': 'Grythyttan',
    '712 91': 'Hällefors',
    '712 93': 'Hällefors',
    '712 94': 'Hällefors',
    '712 95': 'Hällefors',
    '713 30': 'Nora',
    '713 31': 'Nora',
    '713 32': 'Nora',
    '713 33': 'Nora',
    '713 34': 'Ås',
    '713 72': 'Gyttorp',
    '713 91': 'Nora',
    '713 92': 'Nora',
    '713 93': 'Striberg',
    '713 94': 'Finnshyttan',
    '714 30': 'Kopparberg',
    '714 31': 'Ljusnarsberg',
    '714 32': 'Ljusnarsberg',
    '714 33': 'Ljusnarsberg',
    '714 34': 'Ljusnarsberg',
    '714 35': 'Ljusnarsberg',
    '714 36': 'Ljusnarsberg',
    '714 37': 'Ljusnarsberg',
    '714 72': 'Ställdalen',
    '714 91': 'Ljusnarsberg',
    '714 92': 'Ljusnarsberg',
    '714 93': 'Ljusnarsberg',
    '714 94': 'Ljusnarsberg',
    '715 31': 'Odensbacken',
    '715 32': 'Örebro',
    '715 72': 'Stora Mellösa',
    '715 91': 'Örebro',
    '715 92': 'Örebro',
    '715 93': 'Hampetorp',
    '715 94': 'Askersby',
    '715 95': 'Kilsmo',
    '715 96': 'Brevens bruk',
    '716 30': 'Lekeberg',
    '716 31': 'Fjugesta',
    '716 91': 'Lekeberg',
    '716 92': 'Lekeberg',
    '716 93': 'Brotorp',
    '716 94': 'Mullhyttan',
    '718 30': 'Lindesberg',
    '718 31': 'Frövi',
    '718 32': 'Lindesberg',
    '718 33': 'Lindesberg',
    '718 40': 'Ölmbrotorp',
    '718 91': 'Rockhammar',
    '718 92': 'Lindesberg',
    '718 93': 'Strömstorp',
    '718 94': 'Örebro',
    '718 95': 'Ervalla',
    '718 96': 'Örebro',
    '718 97': 'Örebro',
    '719 30': 'Örebro',
    '719 31': 'Vintrosa',
    '719 32': 'Örebro',
    '719 40': 'Garphyttan',
    '719 41': 'Örebro',
    '719 91': 'Örebro',
    '719 92': 'Örebro',
    '719 93': 'Lanna',
    '719 94': 'Latorpsbruk',
    '719 95': 'Lannafors',
    '732 71': 'Fellingsbro',
    '732 72': 'Lindesberg',
    '732 73': 'Lindesberg',
    '772 94': 'Ljusnarsberg',
    '570 60': 'Österbymo',
    '570 61': 'Rydsnäs',
    '570 62': 'Tullerum',
    '570 63': 'Asby',
    '573 94': 'Ydre',
    '573 95': 'Hestra',
    '573 99': 'Ödeshög',
    '582 12': 'Linköping',
    '582 14': 'Linköping',
    '582 15': 'Linköping',
    '582 16': 'Linköping',
    '582 17': 'Linköping',
    '582 18': 'Linköping',
    '582 19': 'Linköping',
    '582 20': 'Linköping',
    '582 21': 'Linköping',
    '582 22': 'Linköping',
    '582 23': 'Innerstaden',
    '582 24': 'Linköping',
    '582 25': 'Linköping',
    '582 26': 'Linköping',
    '582 27': 'Linköping',
    '582 28': 'Linköping',
    '582 29': 'Ekkällan',
    '582 30': 'Linköping',
    '582 31': 'Linköping',
    '582 32': 'Vasastaden',
    '582 33': 'Linköping',
    '582 34': 'Linköping',
    '582 35': 'Linköping',
    '582 36': 'Linköping',
    '582 37': 'Gottfridsberg',
    '582 38': 'Linköping',
    '582 39': 'Linköping',
    '582 40': 'Linköping',
    '582 42': 'Linköping',
    '582 43': 'Linköping',
    '582 44': 'Hejdegården',
    '582 45': 'Ramshäll',
    '582 46': 'Östra Valla',
    '582 47': 'Linköping',
    '582 48': 'Linköping',
    '582 49': 'Linköping',
    '582 52': 'Linköping',
    '582 53': 'Tannefors',
    '582 54': 'Linköping',
    '582 55': 'Linköping',
    '582 56': 'Linköping',
    '582 72': 'Tallboda',
    '582 73': 'Tornby',
    '582 74': 'Linköping',
    '582 75': 'Ekängen',
    '582 76': 'Linköping',
    '582 77': 'Linköping',
    '582 78': 'Kallerstad',
    '583 28': 'Vallastaden',
    '583 29': 'Linköping',
    '583 30': 'Mjärdevi',
    '583 31': 'Linköping',
    '583 32': 'Linköping',
    '583 33': 'Lambohov',
    '583 34': 'Linköping',
    '583 35': 'Linköping',
    '583 36': 'Linköping',
    '583 37': 'Slaka',
    '583 60': 'Linköping',
    '584 22': 'Jägarvallen',
    '584 31': 'Linköping',
    '584 32': 'Linköping',
    '584 34': 'Linköping',
    '584 35': 'Ryd',
    '584 36': 'Linköping',
    '584 37': 'Linköping',
    '584 39': 'Linköping',
    '585 61': 'Linköping',
    '585 62': 'Askeby',
    '585 63': 'Gistad',
    '585 64': 'Linköping',
    '585 65': 'Linghem',
    '585 91': 'Linköping',
    '585 92': 'Linköping',
    '585 93': 'Bankekind',
    '585 94': 'Linköping',
    '585 95': 'Grebo',
    '585 96': 'Linköping',
    '585 97': 'Skeda Udde',
    '585 98': 'Linköping',
    '585 99': 'Linköping',
    '586 42': 'Linköping',
    '586 43': 'Linköping',
    '586 44': 'Linköping',
    '586 45': 'Linköping',
    '586 46': 'Linköping',
    '586 47': 'Linköping',
    '586 48': 'Skäggetorp',
    '586 49': 'Linköping',
    '586 62': 'Malmslätt',
    '586 63': 'Linköping',
    '586 65': 'Linköping',
    '586 66': 'Tokarp',
    '587 23': 'Linköping',
    '587 24': 'Linköping',
    '587 25': 'Johannelund',
    '587 26': 'Linköping',
    '587 27': 'Linköping',
    '587 29': 'Linköping',
    '587 31': 'Vimanshäll',
    '587 32': 'Linköping',
    '587 33': 'Linköping',
    '587 34': 'Linköping',
    '587 35': 'Linköping',
    '587 37': 'Berga',
    '587 38': 'Linköping',
    '587 39': 'Vidingsjö',
    '587 50': 'Linköping',
    '587 52': 'Linköping',
    '587 58': 'Garnisonen',
    '589 21': 'Ekholmen',
    '589 23': 'Linköping',
    '589 25': 'Linköping',
    '589 27': 'Linköping',
    '589 29': 'Linköping',
    '589 31': 'Linköping',
    '589 33': 'Hjulsbro',
    '589 35': 'Linköping',
    '589 37': 'Linköping',
    '589 39': 'Harvestad',
    '589 41': 'Hackefors',
    '589 43': 'Linköping',
    '589 50': 'Ullstämma',
    '589 51': 'Linköping',
    '589 52': 'Linköping',
    '589 53': 'Linköping',
    '589 55': 'Linköping',
    '589 57': 'Linköping',
    '589 61': 'Linköping',
    '589 63': 'Sturefors',
    '589 64': 'Bjärka-Säby',
    '589 65': 'Bestorp',
    '590 11': 'Boxholm',
    '590 12': 'Boxholm',
    '590 13': 'Boxholm',
    '590 14': 'Blåvik',
    '590 15': 'Strålsnäs',
    '590 16': 'Boxholm',
    '590 17': 'Mjölby',
    '590 18': 'Mantorp',
    '590 19': 'Mjölby',
    '590 21': 'Väderstad',
    '590 22': 'Mjölby',
    '590 23': 'Mjölby',
    '590 28': 'Motala',
    '590 29': 'Motala',
    '590 31': 'Borensberg',
    '590 32': 'Klockrike',
    '590 33': 'Fornåsa',
    '590 34': 'Tjällmo',
    '590 36': 'Kisa',
    '590 37': 'Kinda',
    '590 38': 'Kinda',
    '590 39': 'Kinda',
    '590 42': 'Horn',
    '590 43': 'Kinda',
    '590 44': 'Rimforsa',
    '590 45': 'Brokind',
    '590 46': 'Kinda',
    '590 47': 'Västerlösa',
    '590 48': 'Sjögestad',
    '590 49': 'Vikingstad',
    '590 51': 'Linköping',
    '590 52': 'Nykil',
    '590 53': 'Ulrika',
    '590 71': 'Ljungsbro',
    '590 72': 'Linköping',
    '590 73': 'Linköping',
    '590 74': 'Linköping',
    '590 75': 'Linköping',
    '590 76': 'Maspelösa',
    '590 77': 'Berg',
    '590 78': 'Bjäsätter',
    '591 26': 'Motala',
    '591 27': 'Motala',
    '591 30': 'Motala',
    '591 32': 'Motala',
    '591 35': 'Centrum',
    '591 36': 'Motala',
    '591 37': 'Motala',
    '591 38': 'Motala',
    '591 39': 'Motala',
    '591 45': 'Motala',
    '591 46': 'Motala',
    '591 47': 'Motala',
    '591 50': 'Motala',
    '591 51': 'Motala',
    '591 52': 'Motala',
    '591 53': 'Motala',
    '591 54': 'Motala',
    '591 55': 'Motala',
    '591 60': 'Motala',
    '591 61': 'Motala',
    '591 62': 'Motala',
    '591 70': 'Motala',
    '591 71': 'Väster',
    '591 72': 'Motala',
    '591 73': 'Motala',
    '591 91': 'Motala',
    '591 92': 'Motala',
    '591 93': 'Fågelsta',
    '591 94': 'Varv',
    '591 95': 'Österstad',
    '591 96': 'Karlsby',
    '591 97': 'Medevi',
    '591 98': 'Godegård',
    '592 30': 'Vadstena',
    '592 31': 'Vadstena',
    '592 32': 'Vadstena',
    '592 40': 'Vadstena',
    '592 41': 'Vadstena',
    '592 42': 'Vadstena',
    '592 91': 'Vadstena',
    '592 92': 'Vadstena',
    '592 93': 'Borghamn',
    '592 94': 'Vadstena',
    '595 30': 'Mjölby',
    '595 31': 'Mjölby',
    '595 32': 'Mjölby',
    '595 33': 'Mjölby',
    '595 34': 'Mjölby',
    '595 35': 'Viringe',
    '595 40': 'Mjölby',
    '595 41': 'Mjölby',
    '595 42': 'Mjölby',
    '595 43': 'Mjölby',
    '595 44': 'Mjölby',
    '595 50': 'Mjölby',
    '595 51': 'Mjölby',
    '595 52': 'Mjölby',
    '595 53': 'Mjölby',
    '595 54': 'Mjölby',
    '595 91': 'Mjölby',
    '595 92': 'Mjölby',
    '595 93': 'Hogstad',
    '595 94': 'Mjölby',
    '595 95': 'Mjölby',
    '595 96': 'Mjölby',
    '596 31': 'Skänninge',
    '596 32': 'Mjölby',
    '596 33': 'Mjölby',
    '596 34': 'Mjölby',
    '596 91': 'Mjölby',
    '596 92': 'Mjölby',
    '596 93': 'Normlösa',
    '597 30': 'Åtvidaberg',
    '597 31': 'Åtvidaberg',
    '597 32': 'Åtvidaberg',
    '597 40': 'Åtvidaberg',
    '597 42': 'Åtvidaberg',
    '597 43': 'Åtvidaberg',
    '597 50': 'Åtvidaberg',
    '597 52': 'Åtvidaberg',
    '597 53': 'Åtvidaberg',
    '597 55': 'Berg',
    '597 91': 'Åtvidaberg',
    '597 94': 'Björsäter',
    '597 96': 'Åtvidaberg',
    '597 97': 'Falerum',
    '599 31': 'Ödeshög',
    '599 32': 'Ödeshög',
    '599 33': 'Ödeshög',
    '599 91': 'Ödeshög',
    '599 92': 'Ödeshög',
    '599 93': 'Hästholmen',
    '599 94': 'Ödeshög',
    '602 08': 'Norrköping',
    '602 09': 'Enebymo',
    '602 10': 'Pryssgården',
    '602 11': 'Norrköping',
    '602 12': 'Norrköping',
    '602 13': 'Norrköping',
    '602 14': 'Norrköping',
    '602 15': 'Haga',
    '602 16': 'Norrköping',
    '602 17': 'Norrköping',
    '602 18': 'Marielund',
    '602 19': 'Norrköping',
    '602 20': 'Nordantill',
    '602 21': 'Norrköping',
    '602 22': 'Norrköping',
    '602 23': 'Butängen',
    '602 24': 'Gamla Staden',
    '602 25': 'Norrköping',
    '602 26': 'Norrköping',
    '602 27': 'Norrköping',
    '602 28': 'Sylten',
    '602 29': 'Norrköping',
    '602 30': 'Norrköping',
    '602 31': 'Norrköping',
    '602 32': 'Norrköping',
    '602 33': 'Berget',
    '602 34': 'Norrköping',
    '602 35': 'Söderstaden',
    '602 38': 'Saltängen',
    '602 39': 'Såpkullen',
    '602 40': 'Norrköping',
    '602 41': 'Lagerlunda',
    '602 42': 'Norrköping',
    '602 43': 'Norrköping',
    '602 44': 'Oxelbergen',
    '602 45': 'Östantill',
    '602 46': 'Himmelstalund',
    '602 47': 'Norrköping',
    '603 36': 'Kneippen',
    '603 37': 'Norrköping',
    '603 45': 'Norrköping',
    '603 47': 'Norrköping',
    '603 48': 'Norrköping',
    '603 49': 'Norrköping',
    '603 50': 'Norrköping',
    '603 51': 'Ljura',
    '603 52': 'Norrköping',
    '603 53': 'Norrköping',
    '603 54': 'Klingsberg',
    '603 55': 'Norrköping',
    '603 56': 'Vilbergen',
    '603 57': 'Norrköping',
    '603 58': 'Norrköping',
    '603 59': 'Norrköping',
    '603 60': 'Norrköping',
    '603 61': 'Smedby',
    '603 62': 'Hageby',
    '603 63': 'Norrköping',
    '603 64': 'Norrköping',
    '603 65': 'Lindö',
    '603 66': 'Ensjön',
    '603 67': 'Vrinnevi',
    '603 68': 'Navestad',
    '603 69': 'Norrköping',
    '603 70': 'Brånnestad',
    '603 71': 'Rambodal',
    '603 72': 'Norrköping',
    '603 73': 'Norrköping',
    '603 74': 'Ektorp',
    '603 75': 'Norrköping',
    '603 76': 'Norrköping',
    '603 77': 'Norrköping',
    '603 78': 'Klockaretorpet',
    '603 79': 'Kättsätter',
    '603 80': 'Skarphagen',
    '603 81': 'Norrköping',
    '603 85': 'Norrköping',
    '603 86': 'Norrköping',
    '603 87': 'Norrköping',
    '605 60': 'Norrköping',
    '605 70': 'Svärtinge',
    '605 80': 'Norrköping',
    '605 91': 'Ljunga',
    '605 92': 'Lundby',
    '605 93': 'Furingstad',
    '605 94': 'Norrköping',
    '605 95': 'Björkalund',
    '605 96': 'Västra Husby',
    '605 97': 'Eksund',
    '605 99': 'Norrköping',
    '610 24': 'Östra Husby',
    '610 25': 'Norrköping',
    '610 26': 'Norrköping',
    '610 27': 'Norrköping',
    '610 28': 'Norrköping',
    '610 29': 'Norrköping',
    '610 30': 'Norrköping',
    '610 31': 'Norrköping',
    '610 32': 'Norrköping',
    '610 33': 'Norrköping',
    '612 30': 'Finspång',
    '612 31': 'Finspång',
    '612 32': 'Finspång',
    '612 33': 'Nyhem',
    '612 34': 'Viggestorp',
    '612 35': 'Finspång',
    '612 36': 'Svälthagen',
    '612 37': 'Högby',
    '612 38': 'Finspång',
    '612 40': 'Finspång',
    '612 41': 'Grosvad',
    '612 42': 'Finspång',
    '612 43': 'Östermalm',
    '612 44': 'Viberga',
    '612 45': 'Finspång',
    '612 46': 'Dunderbacken',
    '612 71': 'Rejmyre',
    '612 72': 'Finspång',
    '612 73': 'Ljusfallshammar',
    '612 74': 'Grytgöl',
    '612 75': 'Hällestad',
    '612 76': 'Lotorp',
    '612 91': 'Butbro',
    '612 92': 'Finspång',
    '612 93': 'Finspång',
    '612 94': 'Sonstorp',
    '612 95': 'Igelfors',
    '612 96': 'Ramstorp',
    '614 30': 'Söderköping',
    '614 31': 'Söderköping',
    '614 32': 'Söderköping',
    '614 33': 'Söderköping',
    '614 34': 'Söderköping',
    '614 90': 'Mogata',
    '614 92': 'Söderköping',
    '614 93': 'Söderköping',
    '614 94': 'Söderköping',
    '614 95': 'Söderköping',
    '614 96': 'Östra Ryd',
    '614 97': 'Söderköping',
    '614 98': 'Söderköping',
    '614 99': 'Söderköping',
    '615 30': 'Valdemarsvik',
    '615 31': 'Valdemarsvik',
    '615 32': 'Valdemarsvik',
    '615 33': 'Valdemarsvik',
    '615 71': 'Gusum',
    '615 72': 'Valdemarsvik',
    '615 73': 'Söderköping',
    '615 74': 'Ringarum',
    '615 75': 'Valdemarsvik',
    '615 91': 'Valdemarsvik',
    '615 92': 'Valdemarsvik',
    '615 93': 'Söderköping',
    '615 94': 'Valdemarsvik',
    '615 95': 'Valdemarsvik',
    '615 96': 'Kättilö',
    '616 30': 'Norrköping',
    '616 31': 'Norrköping',
    '616 32': 'Åby',
    '616 33': 'Norrköping',
    '616 34': 'Jursla',
    '616 90': 'Skriketorp',
    '616 91': 'Simonstorp',
    '617 30': 'Norrköping',
    '617 31': 'Norrköping',
    '617 32': 'Skärblacka',
    '617 34': 'Norrköping',
    '617 71': 'Kimstad',
    '617 90': 'Vånga',
    '617 91': 'Norrköping',
    '617 92': 'Norsholm',
    '618 30': 'Krokek',
    '618 31': 'Norrköping',
    '618 32': 'Norrköping',
    '618 33': 'Strömsfors',
    '618 34': 'Norrköping',
    '618 35': 'Norrköping',
    '618 92': 'Norrköping',
    '618 93': 'Kvarsebo',
    '643 94': 'Finspång',
    '643 99': 'Regna',
    '696 97': 'Askersund',
    '211 11': 'Malmö',
    '211 12': 'Malmö',
    '211 13': 'Malmö',
    '211 14': 'Malmö',
    '211 15': 'Malmö',
    '211 16': 'Malmö',
    '211 17': 'Malmö',
    '211 18': 'Malmö',
    '211 19': 'Malmö',
    '211 20': 'Malmö',
    '211 21': 'Malmö',
    '211 22': 'Malmö',
    '211 24': 'Malmö',
    '211 25': 'Malmö',
    '211 26': 'Malmö',
    '211 27': 'Malmö',
    '211 28': 'Malmö',
    '211 29': 'Malmö',
    '211 30': 'Malmö',
    '211 31': 'Malmö',
    '211 32': 'Malmö',
    '211 33': 'Malmö',
    '211 34': 'Malmö',
    '211 35': 'Malmö',
    '211 36': 'Malmö',
    '211 37': 'Malmö',
    '211 38': 'Malmö',
    '211 39': 'Malmö',
    '211 40': 'Malmö',
    '211 41': 'Malmö',
    '211 42': 'Malmö',
    '211 43': 'Malmö',
    '211 44': 'Malmö',
    '211 45': 'Malmö',
    '211 46': 'Malmö',
    '211 47': 'Malmö',
    '211 48': 'Malmö',
    '211 49': 'Malmö',
    '211 50': 'Malmö',
    '211 51': 'Malmö',
    '211 52': 'Malmö',
    '211 53': 'Malmö',
    '211 54': 'Malmö',
    '211 55': 'Malmö',
    '211 56': 'Malmö',
    '211 57': 'Malmö',
    '211 58': 'Malmö',
    '211 59': 'Malmö',
    '211 60': 'Malmö',
    '211 71': 'Malmö',
    '211 73': 'Malmö',
    '211 74': 'Malmö',
    '211 75': 'Västra Hamnen',
    '211 76': 'Malmö',
    '211 77': 'Malmö',
    '212 11': 'Malmö',
    '212 12': 'Malmö',
    '212 13': 'Malmö',
    '212 14': 'Malmö',
    '212 15': 'Malmö',
    '212 16': 'Malmö',
    '212 17': 'Malmö',
    '212 18': 'Malmö',
    '212 19': 'Malmö',
    '212 20': 'Malmö',
    '212 21': 'Malmö',
    '212 22': 'Malmö',
    '212 23': 'Malmö',
    '212 24': 'Kirseberg',
    '212 25': 'Malmö',
    '212 26': 'Malmö',
    '212 27': 'Malmö',
    '212 28': 'Malmö',
    '212 29': 'Malmö',
    '212 30': 'Malmö',
    '212 31': 'Malmö',
    '212 32': 'Malmö',
    '212 33': 'Malmö',
    '212 34': 'Malmö',
    '212 35': 'Malmö',
    '212 36': 'Malmö',
    '212 37': 'Malmö',
    '212 38': 'Husie',
    '212 39': 'Malmö',
    '212 40': 'Malmö',
    '212 41': 'Malmö',
    '212 42': 'Malmö',
    '212 43': 'Malmö',
    '212 44': 'Malmö',
    '212 45': 'Malmö',
    '212 46': 'Malmö',
    '212 50': 'Malmö',
    '212 51': 'Malmö',
    '212 52': 'Malmö',
    '212 91': 'Malmö',
    '213 61': 'Malmö',
    '213 62': 'Malmö',
    '213 63': 'Malmö',
    '213 64': 'Malmö',
    '213 65': 'Malmö',
    '213 66': 'Malmö',
    '213 67': 'Malmö',
    '213 68': 'Malmö',
    '213 69': 'Malmö',
    '213 70': 'Malmö',
    '213 71': 'Malmö',
    '213 72': 'Malmö',
    '213 73': 'Rosengård',
    '213 74': 'Malmö',
    '213 75': 'Malmö',
    '213 76': 'Malmö',
    '213 77': 'Malmö',
    '214 20': 'Malmö',
    '214 21': 'Malmö',
    '214 22': 'Malmö',
    '214 23': 'Malmö',
    '214 24': 'Malmö',
    '214 25': 'Malmö',
    '214 26': 'Malmö',
    '214 27': 'Malmö',
    '214 28': 'Malmö',
    '214 29': 'Malmö',
    '214 30': 'Malmö',
    '214 31': 'Malmö',
    '214 32': 'Malmö',
    '214 33': 'Malmö',
    '214 34': 'Malmö',
    '214 35': 'Malmö',
    '214 36': 'Malmö',
    '214 37': 'Malmö',
    '214 38': 'Malmö',
    '214 39': 'Malmö',
    '214 40': 'Malmö',
    '214 41': 'Malmö',
    '214 42': 'Malmö',
    '214 43': 'Malmö',
    '214 44': 'Malmö',
    '214 45': 'Malmö',
    '214 46': 'Malmö',
    '214 47': 'Malmö',
    '214 48': 'Malmö',
    '214 49': 'Malmö',
    '214 50': 'Malmö',
    '214 54': 'Malmö',
    '214 55': 'Malmö',
    '214 56': 'Malmö',
    '214 57': 'Malmö',
    '214 58': 'Malmö',
    '214 59': 'Malmö',
    '214 63': 'Malmö',
    '214 66': 'Malmö',
    '214 67': 'Malmö',
    '215 26': 'Malmö',
    '215 27': 'Malmö',
    '215 32': 'Malmö',
    '215 33': 'Malmö',
    '215 34': 'Malmö',
    '215 35': 'Malmö',
    '215 36': 'Malmö',
    '215 37': 'Malmö',
    '215 50': 'Fosie',
    '215 51': 'Malmö',
    '215 52': 'Malmö',
    '215 53': 'Malmö',
    '215 58': 'Malmö',
    '215 59': 'Malmö',
    '215 60': 'Malmö',
    '215 61': 'Malmö',
    '215 62': 'Malmö',
    '215 63': 'Malmö',
    '215 64': 'Malmö',
    '215 65': 'Malmö',
    '215 66': 'Malmö',
    '215 67': 'Malmö',
    '215 68': 'Malmö',
    '215 69': 'Malmö',
    '215 70': 'Malmö',
    '215 71': 'Malmö',
    '215 72': 'Malmö',
    '215 73': 'Malmö',
    '215 74': 'Malmö',
    '215 78': 'Malmö',
    '215 79': 'Malmö',
    '215 80': 'Malmö',
    '215 81': 'Malmö',
    '215 82': 'Malmö',
    '215 83': 'Malmö',
    '215 84': 'Malmö',
    '215 85': 'Malmö',
    '215 86': 'Malmö',
    '216 11': 'Malmö',
    '216 12': 'Malmö',
    '216 13': 'Malmö',
    '216 14': 'Limhamn',
    '216 15': 'Malmö',
    '216 16': 'Malmö',
    '216 17': 'Malmö',
    '216 18': 'Malmö',
    '216 19': 'Malmö',
    '216 20': 'Malmö',
    '216 21': 'Malmö',
    '216 22': 'Malmö',
    '216 23': 'Malmö',
    '216 24': 'Malmö',
    '216 25': 'Hyllie',
    '216 30': 'Malmö',
    '216 31': 'Malmö',
    '216 32': 'Malmö',
    '216 33': 'Malmö',
    '216 41': 'Malmö',
    '216 42': 'Malmö',
    '216 43': 'Malmö',
    '216 44': 'Malmö',
    '216 45': 'Malmö',
    '216 46': 'Malmö',
    '216 47': 'Malmö',
    '216 48': 'Malmö',
    '217 41': 'Malmö',
    '217 42': 'Malmö',
    '217 43': 'Malmö',
    '217 44': 'Malmö',
    '217 45': 'Malmö',
    '217 46': 'Malmö',
    '217 47': 'Malmö',
    '217 48': 'Malmö',
    '217 49': 'Malmö',
    '217 51': 'Malmö',
    '217 52': 'Malmö',
    '217 53': 'Slottsstaden',
    '217 54': 'Malmö',
    '217 55': 'Malmö',
    '217 56': 'Malmö',
    '217 57': 'Malmö',
    '217 58': 'Malmö',
    '217 59': 'Malmö',
    '217 61': 'Malmö',
    '217 62': 'Malmö',
    '217 63': 'Malmö',
    '217 64': 'Malmö',
    '217 65': 'Malmö',
    '217 66': 'Malmö',
    '217 67': 'Malmö',
    '217 71': 'Malmö',
    '217 72': 'Malmö',
    '217 73': 'Malmö',
    '217 74': 'Malmö',
    '217 75': 'Malmö',
    '218 31': 'Malmö',
    '218 32': 'Bunkeflostrand',
    '218 33': 'Malmö',
    '218 34': 'Malmö',
    '218 36': 'Malmö',
    '218 37': 'Malmö',
    '218 38': 'Malmö',
    '218 39': 'Malmö',
    '218 40': 'Malmö',
    '218 41': 'Malmö',
    '218 42': 'Malmö',
    '218 45': 'Vintrie',
    '218 46': 'Skumparp',
    '218 47': 'Malmö',
    '218 51': 'Malmö',
    '218 52': 'Malmö',
    '218 53': 'Klagshamn',
    '218 54': 'Malmö',
    '218 55': 'Malmö',
    '218 71': 'Tygelsjö',
    '218 72': 'Malmö',
    '218 73': 'Malmö',
    '218 74': 'Västra Klagstorp',
    '218 75': 'Malmö',
    '222 20': 'Lund',
    '222 21': 'Lund',
    '222 22': 'Lund',
    '222 23': 'Lund',
    '222 24': 'Lund',
    '222 25': 'Lund',
    '222 26': 'Lund',
    '222 27': 'Lund',
    '222 28': 'Lund',
    '222 29': 'Lund',
    '222 35': 'Lund',
    '222 36': 'Lund',
    '222 37': 'Lund',
    '222 40': 'Lund',
    '222 41': 'Lund',
    '222 42': 'Lund',
    '222 70': 'Lund',
    '222 71': 'Lund',
    '222 74': 'Lund',
    '223 49': 'Lund',
    '223 50': 'Lund',
    '223 51': 'Lund',
    '223 52': 'Lund',
    '223 53': 'Lund',
    '223 54': 'Lund',
    '223 55': 'Lund',
    '223 56': 'Lund',
    '223 57': 'Lund',
    '223 58': 'Lund',
    '223 59': 'Lund',
    '223 61': 'Lund',
    '223 62': 'Lund',
    '223 63': 'Lund',
    '223 69': 'Lund',
    '224 56': 'Lund',
    '224 57': 'Lund',
    '224 58': 'Lund',
    '224 60': 'Lund',
    '224 64': 'Lund',
    '224 65': 'Lund',
    '224 66': 'Lund',
    '224 67': 'Lund',
    '224 68': 'Lund',
    '224 71': 'Lund',
    '224 72': 'Lund',
    '224 73': 'Lund',
    '224 74': 'Lund',
    '224 75': 'Lund',
    '224 76': 'Lund',
    '224 77': 'Lund',
    '224 78': 'Lund',
    '224 79': 'Lund',
    '224 80': 'Lund',
    '224 81': 'Lund',
    '224 82': 'Lund',
    '224 84': 'Lund',
    '225 91': 'Lund',
    '225 92': 'Lund',
    '225 93': 'Lund',
    '225 94': 'Lund',
    '226 39': 'Lund',
    '226 42': 'Lund',
    '226 43': 'Lund',
    '226 44': 'Lund',
    '226 45': 'Lund',
    '226 46': 'Lund',
    '226 47': 'Lund',
    '226 48': 'Lund',
    '226 49': 'Lund',
    '226 50': 'Vallkärra',
    '226 51': 'Stångby',
    '226 52': 'Lund',
    '226 53': 'Lund',
    '226 54': 'Lund',
    '226 55': 'Lund',
    '226 57': 'Lund',
    '226 60': 'Lund',
    '227 30': 'Lund',
    '227 31': 'Lund',
    '227 32': 'Lund',
    '227 33': 'Lund',
    '227 34': 'Lund',
    '227 35': 'Lund',
    '227 36': 'Lund',
    '227 38': 'Lund',
    '227 60': 'Lund',
    '227 61': 'Lund',
    '227 62': 'Lund',
    '227 63': 'Lund',
    '227 64': 'Lund',
    '230 53': 'Lomma',
    '231 31': 'Trelleborg',
    '231 32': 'Trelleborg',
    '231 33': 'Trelleborg',
    '231 34': 'Trelleborg',
    '231 36': 'Trelleborg',
    '231 38': 'Trelleborg',
    '231 42': 'Trelleborg',
    '231 43': 'Trelleborg',
    '231 44': 'Trelleborg',
    '231 45': 'Trelleborg',
    '231 51': 'Trelleborg',
    '231 52': 'Trelleborg',
    '231 53': 'Trelleborg',
    '231 54': 'Trelleborg',
    '231 55': 'Trelleborg',
    '231 56': 'Trelleborg',
    '231 61': 'Trelleborg',
    '231 62': 'Trelleborg',
    '231 63': 'Trelleborg',
    '231 64': 'Trelleborg',
    '231 65': 'Trelleborg',
    '231 66': 'Trelleborg',
    '231 67': 'Kurland',
    '231 68': 'Trelleborg',
    '231 69': 'Skegrie',
    '231 70': 'Anderslöv',
    '231 72': 'Trelleborg',
    '231 73': 'Trelleborg',
    '231 75': 'Beddingestrand',
    '231 76': 'Trelleborg',
    '231 78': 'Trelleborg',
    '231 79': 'Smygehamn',
    '231 91': 'Trelleborg',
    '231 92': 'Trelleborg',
    '231 93': 'Västra Tommarp',
    '231 94': 'Trelleborg',
    '231 95': 'Alstad',
    '231 96': 'Trelleborg',
    '231 97': 'Klagstorp',
    '231 98': 'Trelleborg',
    '231 99': 'Trelleborg',
    '232 31': 'Burlöv',
    '232 32': 'Burlöv',
    '232 33': 'Burlöv',
    '232 34': 'Arlöv',
    '232 35': 'Burlöv',
    '232 36': 'Burlöv',
    '232 37': 'Burlöv',
    '232 38': 'Burlöv',
    '232 39': 'Burlövs egnahem',
    '232 41': 'Burlöv',
    '232 51': 'Burlöv',
    '232 52': 'Burlöv',
    '232 53': 'Åkarp',
    '232 54': 'Burlöv',
    '232 61': 'Burlöv',
    '232 91': 'Nordanå',
    '233 31': 'Svedala',
    '233 32': 'Svedala',
    '233 33': 'Svedala',
    '233 34': 'Svedala',
    '233 35': 'Svedala',
    '233 36': 'Svedala',
    '233 37': 'Svedala',
    '233 38': 'Svedala',
    '233 39': 'Svedala',
    '233 41': 'Svedala',
    '233 42': 'Svedala',
    '233 43': 'Svedala',
    '233 44': 'Svedala',
    '233 51': 'Svedala',
    '233 61': 'Svedala',
    '233 62': 'Svedala',
    '233 63': 'Svedala',
    '233 64': 'Svedala',
    '233 65': 'Bara',
    '233 71': 'Svedala',
    '233 75': 'Svedala',
    '233 76': 'Klågerup',
    '233 91': 'Svedala',
    '233 92': 'Svedala',
    '233 93': 'Svedala',
    '233 94': 'Holmeja',
    '234 31': 'Lomma',
    '234 32': 'Lomma',
    '234 33': 'Lomma',
    '234 34': 'Lomma',
    '234 35': 'Lomma',
    '234 36': 'Lomma',
    '234 37': 'Lomma',
    '234 38': 'Lomma',
    '234 39': 'Lomma',
    '234 40': 'Lomma',
    '234 41': 'Lomma',
    '234 42': 'Lomma',
    '234 43': 'Lomma',
    '235 31': 'Vellinge',
    '235 32': 'Vellinge',
    '235 33': 'Vellinge',
    '235 34': 'Vellinge',
    '235 35': 'Vellinge',
    '235 36': 'Vellinge',
    '235 37': 'Vellinge',
    '235 38': 'Vellinge',
    '235 39': 'Vellinge',
    '235 41': 'Västra Ingelstad',
    '235 42': 'Vellinge',
    '235 43': 'Hököpinge',
    '235 91': 'Vellinge',
    '235 93': 'Gessie villastad',
    '235 94': 'Vellinge',
    '235 99': 'Östra Grevie',
    '236 31': 'Höllviken',
    '236 32': 'Vellinge',
    '236 33': 'Vellinge',
    '236 34': 'Vellinge',
    '236 35': 'Kämpinge',
    '236 36': 'Vellinge',
    '236 37': 'Vellinge',
    '236 38': 'Vellinge',
    '236 41': 'Vellinge',
    '236 42': 'Ljunghusen',
    '236 51': 'Vellinge',
    '236 61': 'Rängs sand',
    '236 91': 'Vellinge',
    '237 31': 'Bjärred',
    '237 32': 'Lomma',
    '237 33': 'Lomma',
    '237 34': 'Lomma',
    '237 35': 'Lomma',
    '237 36': 'Lomma',
    '237 37': 'Lomma',
    '237 41': 'Lomma',
    '237 42': 'Borgeby',
    '237 91': 'Flädie',
    '238 30': 'Malmö',
    '238 31': 'Malmö',
    '238 32': 'Malmö',
    '238 33': 'Malmö',
    '238 34': 'Malmö',
    '238 35': 'Oxie',
    '238 36': 'Malmö',
    '238 37': 'Malmö',
    '238 38': 'Malmö',
    '238 39': 'Malmö',
    '238 40': 'Malmö',
    '238 41': 'Toarp',
    '238 42': 'Malmö',
    '238 43': 'Malmö',
    '239 30': 'Vellinge',
    '239 31': 'Vellinge',
    '239 32': 'Skanör',
    '239 33': 'Vellinge',
    '239 34': 'Vellinge',
    '239 35': 'Vellinge',
    '239 36': 'Vellinge',
    '239 40': 'Vellinge',
    '239 41': 'Skanör med Falsterbo',
    '239 42': 'Falsterbo',
    '241 30': 'Eslöv',
    '241 31': 'Eslöv',
    '241 32': 'Eslöv',
    '241 33': 'Eslöv',
    '241 34': 'Eslöv',
    '241 35': 'Eslöv',
    '241 36': 'Eslöv',
    '241 37': 'Eslöv',
    '241 38': 'Eslöv',
    '241 39': 'Eslöv',
    '241 40': 'Eslöv',
    '241 61': 'Eslöv',
    '241 62': 'Löberöd',
    '241 64': 'Harlösa',
    '241 65': 'Eslöv',
    '241 71': 'Eslöv',
    '241 72': 'Marieholm',
    '241 74': 'Stehag',
    '241 75': 'Eslöv',
    '241 91': 'Kungshult',
    '241 92': 'Eslöv',
    '241 93': 'Väggarp',
    '241 94': 'Hurva',
    '241 95': 'Billinge',
    '241 96': 'Stockamöllan',
    '242 30': 'Hörby',
    '242 31': 'Hörby',
    '242 32': 'Hörby',
    '242 33': 'Hörby',
    '242 34': 'Hörby',
    '242 35': 'Hörby',
    '242 71': 'Ludvigsborg',
    '242 72': 'Hörby',
    '242 91': 'Hörby',
    '242 92': 'Hörby',
    '242 93': 'Osbyholm',
    '242 94': 'Hörby',
    '242 95': 'Hörby',
    '242 96': 'Hörby',
    '242 97': 'Östraby',
    '242 98': 'Önneköp',
    '243 30': 'Höör',
    '243 31': 'Höör',
    '243 32': 'Höör',
    '243 33': 'Höör',
    '243 34': 'Höör',
    '243 35': 'Sätofta',
    '243 36': 'Höör',
    '243 39': 'Höör',
    '243 40': 'Höör',
    '243 71': 'Höör',
    '243 72': 'Tjörnarp',
    '243 73': 'Hässleholm',
    '243 91': 'Ormanäs och Stanstorp',
    '243 92': 'Norra Rörum',
    '243 93': 'Höör',
    '243 94': 'Ljungstorp och Jägersbo',
    '243 95': 'Höör',
    '243 96': 'Höör',
    '243 97': 'Höör',
    '244 30': 'Kävlinge',
    '244 31': 'Kävlinge',
    '244 32': 'Kävlinge',
    '244 33': 'Kävlinge',
    '244 36': 'Kävlinge',
    '244 38': 'Kävlinge',
    '244 39': 'Kävlinge',
    '244 41': 'Kävlinge',
    '244 44': 'Kävlinge',
    '244 45': 'Kävlinge',
    '244 46': 'Kävlinge',
    '244 60': 'Kävlinge',
    '244 62': 'Kävlinge',
    '244 63': 'Kävlinge',
    '244 65': 'Furulund',
    '244 66': 'Kävlinge',
    '244 71': 'Dösjebro',
    '244 91': 'Lilla Harrie',
    '244 93': 'Kävlinge',
    '244 94': 'Kävlinge',
    '244 95': 'Kävlinge',
    '245 31': 'Staffanstorp',
    '245 32': 'Staffanstorp',
    '245 33': 'Staffanstorp',
    '245 34': 'Staffanstorp',
    '245 35': 'Staffanstorp',
    '245 37': 'Staffanstorp',
    '245 38': 'Staffanstorp',
    '245 41': 'Staffanstorp',
    '245 42': 'Staffanstorp',
    '245 43': 'Staffanstorp',
    '245 44': 'Staffanstorp',
    '245 45': 'Staffanstorp',
    '245 61': 'Staffanstorp',
    '245 62': 'Staffanstorp',
    '245 63': 'Hjärup',
    '245 64': 'Staffanstorp',
    '245 65': 'Staffanstorp',
    '245 66': 'Staffanstorp',
    '245 91': 'Staffanstorp',
    '245 92': 'Kyrkheddinge',
    '245 93': 'Staffanstorp',
    '246 30': 'Kävlinge',
    '246 31': 'Kävlinge',
    '246 32': 'Sandskogen',
    '246 33': 'Kävlinge',
    '246 34': 'Löddeköpinge',
    '246 35': 'Kävlinge',
    '246 36': 'Kävlinge',
    '246 42': 'Kävlinge',
    '246 43': 'Kävlinge',
    '246 50': 'Kävlinge',
    '246 51': 'Hofterup',
    '246 52': 'Kävlinge',
    '246 55': 'Kävlinge',
    '246 57': 'Barsebäckshamn',
    '247 31': 'Södra Sandby',
    '247 32': 'Lund',
    '247 33': 'Lund',
    '247 34': 'Lund',
    '247 35': 'Lund',
    '247 36': 'Lund',
    '247 41': 'Revingeby',
    '247 45': 'Torna Hällestad',
    '247 47': 'Flyinge',
    '247 50': 'Dalby',
    '247 51': 'Lund',
    '247 52': 'Lund',
    '247 53': 'Lund',
    '247 54': 'Lund',
    '247 55': 'Lund',
    '247 56': 'Lund',
    '247 60': 'Idala',
    '247 61': 'Lund',
    '247 62': 'Veberöd',
    '247 63': 'Lund',
    '247 64': 'Lund',
    '247 70': 'Lund',
    '247 71': 'Genarp',
    '247 72': 'Lund',
    '247 91': 'Lund',
    '247 92': 'Lund',
    '247 93': 'Lund',
    '247 94': 'Lund',
    '247 95': 'Lund',
    '247 96': 'Lund',
    '247 97': 'Gårdstånga',
    '247 98': 'Lund',
    '247 99': 'Lund',
    '252 18': 'Helsingborg',
    '252 19': 'Helsingborg',
    '252 20': 'Helsingborg',
    '252 21': 'Helsingborg',
    '252 22': 'Helsingborg',
    '252 23': 'Helsingborg',
    '252 24': 'Helsingborg',
    '252 25': 'Helsingborg',
    '252 26': 'Helsingborg',
    '252 27': 'Helsingborg',
    '252 28': 'Helsingborg',
    '252 29': 'Helsingborg',
    '252 30': 'Helsingborg',
    '252 31': 'Helsingborg',
    '252 32': 'Helsingborg',
    '252 33': 'Helsingborg',
    '252 34': 'Helsingborg',
    '252 36': 'Helsingborg',
    '252 37': 'Helsingborg',
    '252 38': 'Helsingborg',
    '252 40': 'Helsingborg',
    '252 41': 'Helsingborg',
    '252 44': 'Helsingborg',
    '252 45': 'Helsingborg',
    '252 46': 'Helsingborg',
    '252 47': 'Helsingborg',
    '252 48': 'Helsingborg',
    '252 49': 'Helsingborg',
    '252 50': 'Helsingborg',
    '252 51': 'Helsingborg',
    '252 52': 'Helsingborg',
    '252 63': 'Helsingborg',
    '252 66': 'Helsingborg',
    '252 67': 'Helsingborg',
    '252 68': 'Helsingborg',
    '252 69': 'Helsingborg',
    '252 70': 'Helsingborg',
    '252 71': 'Helsingborg',
    '252 75': 'Helsingborg',
    '252 76': 'Helsingborg',
    '252 77': 'Helsingborg',
    '252 78': 'Helsingborg',
    '253 41': 'Vallåkra',
    '253 42': 'Helsingborg',
    '253 51': 'Helsingborg',
    '253 52': 'Helsingborg',
    '253 53': 'Påarp',
    '253 54': 'Mörarp',
    '253 55': 'Helsingborg',
    '253 56': 'Helsingborg',
    '253 60': 'Helsingborg',
    '253 61': 'Helsingborg',
    '253 62': 'Helsingborg',
    '253 68': 'Helsingborg',
    '253 73': 'Gantofta',
    '253 74': 'Helsingborg',
    '253 75': 'Bårslöv',
    '254 33': 'Helsingborg',
    '254 35': 'Helsingborg',
    '254 36': 'Helsingborg',
    '254 37': 'Helsingborg',
    '254 38': 'Helsingborg',
    '254 39': 'Helsingborg',
    '254 40': 'Helsingborg',
    '254 41': 'Helsingborg',
    '254 42': 'Helsingborg',
    '254 43': 'Helsingborg',
    '254 44': 'Helsingborg',
    '254 48': 'Helsingborg',
    '254 49': 'Helsingborg',
    '254 50': 'Helsingborg',
    '254 51': 'Helsingborg',
    '254 52': 'Helsingborg',
    '254 53': 'Helsingborg',
    '254 54': 'Helsingborg',
    '254 55': 'Helsingborg',
    '254 56': 'Helsingborg',
    '254 57': 'Helsingborg',
    '254 58': 'Helsingborg',
    '254 59': 'Helsingborg',
    '254 60': 'Helsingborg',
    '254 61': 'Helsingborg',
    '254 62': 'Helsingborg',
    '254 63': 'Helsingborg',
    '254 64': 'Helsingborg',
    '254 65': 'Helsingborg',
    '254 66': 'Helsingborg',
    '254 67': 'Helsingborg',
    '254 68': 'Helsingborg',
    '254 70': 'Kattarp',
    '254 71': 'Hasslarp',
    '254 72': 'Helsingborg',
    '254 73': 'Helsingborg',
    '254 74': 'Ödåkra',
    '254 75': 'Helsingborg',
    '254 76': 'Allerum',
    '254 77': 'Helsingborg',
    '254 78': 'Domsten',
    '254 81': 'Hittarp',
    '254 82': 'Laröd',
    '254 83': 'Helsingborg',
    '254 84': 'Helsingborg',
    '255 92': 'Helsingborg',
    '256 54': 'Helsingborg',
    '256 55': 'Helsingborg',
    '256 56': 'Helsingborg',
    '256 57': 'Helsingborg',
    '256 58': 'Helsingborg',
    '256 59': 'Helsingborg',
    '256 61': 'Helsingborg',
    '256 62': 'Helsingborg',
    '256 63': 'Helsingborg',
    '256 64': 'Helsingborg',
    '256 65': 'Helsingborg',
    '256 66': 'Helsingborg',
    '256 67': 'Helsingborg',
    '256 68': 'Helsingborg',
    '256 69': 'Helsingborg',
    '256 71': 'Helsingborg',
    '257 30': 'Helsingborg',
    '257 31': 'Helsingborg',
    '257 32': 'Helsingborg',
    '257 33': 'Helsingborg',
    '257 34': 'Rydebäck',
    '260 13': 'Tuna',
    '260 36': 'Helsingborg',
    '261 30': 'Landskrona',
    '261 31': 'Landskrona',
    '261 32': 'Landskrona',
    '261 33': 'Landskrona',
    '261 34': 'Landskrona',
    '261 35': 'Landskrona',
    '261 36': 'Landskrona',
    '261 37': 'Landskrona',
    '261 38': 'Landskrona',
    '261 39': 'Landskrona',
    '261 40': 'Landskrona',
    '261 41': 'Landskrona',
    '261 42': 'Karlslund',
    '261 43': 'Landskrona',
    '261 44': 'Landskrona',
    '261 45': 'Västra Fäladen',
    '261 46': 'Landskrona',
    '261 47': 'Landskrona',
    '261 51': 'Landskrona',
    '261 52': 'Landskrona',
    '261 53': 'Landskrona',
    '261 60': 'Landskrona',
    '261 61': 'Borstahusen',
    '261 62': 'Glumslöv',
    '261 63': 'Landskrona',
    '261 65': 'Härslöv',
    '261 71': 'Landskrona',
    '261 72': 'Häljarp',
    '261 73': 'Landskrona',
    '261 75': 'Asmundtorp',
    '261 76': 'Landskrona',
    '261 91': 'Landskrona',
    '261 92': 'Landskrona',
    '261 93': 'Saxtorpsskogen',
    '261 94': 'Annelöv',
    '262 31': 'Ängelholm',
    '262 32': 'Ängelholm',
    '262 33': 'Ängelholm',
    '262 34': 'Ängelholm',
    '262 35': 'Ängelholm',
    '262 36': 'Ängelholm',
    '262 37': 'Ängelholm',
    '262 41': 'Ängelholm',
    '262 42': 'Ängelholm',
    '262 43': 'Ängelholm',
    '262 44': 'Ängelholm',
    '262 50': 'Ängelholm',
    '262 51': 'Ängelholm',
    '262 52': 'Ängelholm',
    '262 53': 'Ängelholm',
    '262 54': 'Ängelholm',
    '262 55': 'Ängelholm',
    '262 57': 'Ängelholm',
    '262 58': 'Ängelholm',
    '262 61': 'Ängelholm',
    '262 62': 'Ängelholm',
    '262 63': 'Ängelholm',
    '262 64': 'Ängelholm',
    '262 65': 'Ängelholm',
    '262 68': 'Ängelholm',
    '262 70': 'Strövelstorp',
    '262 71': 'Ängelholm',
    '262 72': 'Ängelholm',
    '262 73': 'Ängelholm',
    '262 74': 'Ängelholm',
    '262 91': 'Skepparkroken',
    '262 92': 'Ängelholm',
    '262 93': 'Höja',
    '262 94': 'Utvälinge',
    '262 95': 'Ängelholm',
    '262 96': 'Ängelholm',
    '263 31': 'Höganäs',
    '263 32': 'Höganäs',
    '263 33': 'Höganäs',
    '263 34': 'Höganäs',
    '263 36': 'Höganäs',
    '263 37': 'Höganäs',
    '263 38': 'Höganäs',
    '263 39': 'Höganäs',
    '263 51': 'Höganäs',
    '263 52': 'Lerberget',
    '263 53': 'Höganäs',
    '263 54': 'Höganäs',
    '263 57': 'Väsby',
    '263 58': 'Höganäs',
    '263 61': 'Höganäs',
    '263 62': 'Höganäs',
    '263 63': 'Viken',
    '263 65': 'Höganäs',
    '263 71': 'Jonstorp',
    '263 72': 'Höganäs',
    '263 73': 'Arild',
    '263 75': 'Nyhamnsläge',
    '263 76': 'Höganäs',
    '263 77': 'Mölle',
    '263 91': 'Ingelsträde',
    '263 92': 'Höganäs',
    '263 94': 'Mjöhult',
    '263 95': 'Höganäs',
    '264 31': 'Klippan',
    '264 32': 'Klippan',
    '264 33': 'Klippan',
    '264 34': 'Klippan',
    '264 35': 'Klippan',
    '264 36': 'Klippan',
    '264 37': 'Klippan',
    '264 38': 'Klippan',
    '264 39': 'Pappersbruket',
    '264 51': 'Ljungbyhed',
    '264 52': 'Klippan',
    '264 53': 'Klippan',
    '264 54': 'Klippan',
    '264 70': 'Stidsvig',
    '264 71': 'Östra Ljungby',
    '264 91': 'Klippan',
    '264 92': 'Klippan',
    '264 93': 'Klippan',
    '264 94': 'Klippan',
    '265 31': 'Åstorp',
    '265 32': 'Åstorp',
    '265 33': 'Åstorp',
    '265 34': 'Åstorp',
    '265 35': 'Åstorp',
    '265 36': 'Åstorp',
    '265 37': 'Åstorp',
    '265 38': 'Åstorp',
    '265 39': 'Åstorp',
    '265 40': 'Åstorp',
    '265 50': 'Nyvång',
    '265 71': 'Kvidinge',
    '265 72': 'Åstorp',
    '265 74': 'Hyllinge',
    '265 75': 'Åstorp',
    '265 90': 'Åstorp',
    '266 31': 'Ängelholm',
    '266 32': 'Munka-Ljungby',
    '266 33': 'Ängelholm',
    '266 34': 'Ängelholm',
    '266 51': 'Båstad',
    '266 52': 'Ängelholm',
    '266 53': 'Vejbystrand',
    '266 54': 'Ängelholm',
    '266 55': 'Ängelholm',
    '266 75': 'Hjärnarp',
    '266 91': 'Ängelholm',
    '266 92': 'Ängelholm',
    '266 93': 'Ängelholm',
    '266 94': 'Ängelholm',
    '266 95': 'Ängelholm',
    '266 97': 'Lerbäckshult',
    '266 98': 'Margretetorp',
    '267 31': 'Bjuv',
    '267 32': 'Bjuv',
    '267 33': 'Bjuv',
    '267 34': 'Bjuv',
    '267 35': 'Bjuv',
    '267 36': 'Bjuv',
    '267 37': 'Bjuv',
    '267 38': 'Bjuv',
    '267 39': 'Bjuv',
    '267 40': 'Bjuv',
    '267 41': 'Gunnarstorp',
    '267 71': 'Bjuv',
    '267 72': 'Bjuv',
    '267 73': 'Billesholm',
    '267 74': 'Södra Vrams fälad',
    '267 75': 'Bjuv',
    '267 76': 'Ekeby',
    '267 77': 'Bjuv',
    '267 90': 'Bjuv',
    '268 31': 'Svalöv',
    '268 32': 'Svalöv',
    '268 33': 'Svalöv',
    '268 34': 'Svalöv',
    '268 68': 'Röstånga',
    '268 71': 'Teckomatorp',
    '268 72': 'Svalöv',
    '268 73': 'Billeberga',
    '268 74': 'Svalöv',
    '268 75': 'Tågarp',
    '268 76': 'Svalöv',
    '268 77': 'Kågeröd',
    '268 78': 'Svalöv',
    '268 90': 'Svalöv',
    '269 31': 'Båstad',
    '269 32': 'Båstad',
    '269 33': 'Båstad',
    '269 34': 'Båstad',
    '269 35': 'Båstad',
    '269 36': 'Båstad',
    '269 37': 'Båstad',
    '269 38': 'Båstad',
    '269 39': 'Båstad',
    '269 40': 'Båstad',
    '269 41': 'Östra Karup',
    '269 42': 'Båstad',
    '269 43': 'Båstad',
    '269 44': 'Båstad',
    '269 62': 'Grevie',
    '269 71': 'Förslöv',
    '269 72': 'Båstad',
    '269 73': 'Båstad',
    '269 74': 'Västra Karup',
    '269 76': 'Båstad',
    '269 77': 'Torekov',
    '269 78': 'Båstad',
    '269 91': 'Båstad',
    '269 92': 'Båstad',
    '269 93': 'Båstad',
    '269 94': 'Båstad',
    '269 95': 'Båstad',
    '269 96': 'Båstad',
    '271 31': 'Ystad',
    '271 32': 'Ystad',
    '271 33': 'Ystad',
    '271 34': 'Ystad',
    '271 35': 'Ystad',
    '271 36': 'Ystad',
    '271 37': 'Ystad',
    '271 38': 'Ystad',
    '271 39': 'Ystad',
    '271 40': 'Ystad',
    '271 41': 'Ystad',
    '271 42': 'Ystad',
    '271 43': 'Ystad',
    '271 44': 'Ystad',
    '271 45': 'Ystad',
    '271 46': 'Ystad',
    '271 50': 'Ystad',
    '271 51': 'Ystad',
    '271 52': 'Ystad',
    '271 53': 'Ystad',
    '271 54': 'Ystad',
    '271 55': 'Ystad',
    '271 56': 'Ystad',
    '271 57': 'Ystad',
    '271 60': 'Ystad',
    '271 70': 'Ystad',
    '271 71': 'Nybrostrand',
    '271 72': 'Köpingebro',
    '271 73': 'Ystad',
    '271 74': 'Glemmingebro',
    '271 75': 'Ystad',
    '271 76': 'Löderup',
    '271 77': 'Ystad',
    '271 78': 'Ystad',
    '271 91': 'Ystad',
    '271 92': 'Svarte',
    '271 93': 'Ystad',
    '271 94': 'Ystad',
    '271 95': 'Sövestad',
    '271 96': 'Ystad',
    '271 97': 'Ystad',
    '271 98': 'Stora Herrestad',
    '271 99': 'Hedeskoga',
    '272 31': 'Simrishamn',
    '272 32': 'Simrishamn',
    '272 33': 'Simrishamn',
    '272 34': 'Simrishamn',
    '272 35': 'Simrishamn',
    '272 36': 'Simrishamn',
    '272 37': 'Simrishamn',
    '272 38': 'Brantevik',
    '272 39': 'Simrishamn',
    '272 61': 'Gärsnäs',
    '272 91': 'Simris',
    '272 92': 'Simrishamn',
    '272 93': 'Östra Tommarp',
    '272 94': 'Simrishamn',
    '272 95': 'Vik',
    '272 96': 'Simrishamn',
    '272 97': 'Simrishamn',
    '273 30': 'Tomelilla',
    '273 31': 'Tomelilla',
    '273 32': 'Tomelilla',
    '273 33': 'Tomelilla',
    '273 34': 'Tomelilla',
    '273 35': 'Tomelilla',
    '273 36': 'Tomelilla',
    '273 50': 'Brösarp',
    '273 55': 'Tomelilla',
    '273 56': 'Tomelilla',
    '273 57': 'Tomelilla',
    '273 91': 'Tomelilla',
    '273 92': 'Tomelilla',
    '273 93': 'Tomelilla',
    '273 94': 'Tomelilla',
    '273 95': 'Onslunda',
    '273 96': 'Lunnarp',
    '273 97': 'Tomelilla',
    '273 98': 'Smedstorp',
    '274 30': 'Skurup',
    '274 31': 'Skurup',
    '274 32': 'Skurup',
    '274 33': 'Skurup',
    '274 34': 'Skurup',
    '274 35': 'Skurup',
    '274 36': 'Skurup',
    '274 37': 'Skurup',
    '274 50': 'Skivarp',
    '274 51': 'Skurup',
    '274 53': 'Skurup',
    '274 54': 'Skurup',
    '274 56': 'Abbekås',
    '274 60': 'Skurup',
    '274 61': 'Rydsgård',
    '274 62': 'Skurup',
    '274 63': 'Skurup',
    '274 91': 'Skurup',
    '274 92': 'Skurup',
    '274 93': 'Skurup',
    '274 94': 'Trelleborg',
    '275 30': 'Sjöbo',
    '275 31': 'Sjöbo',
    '275 32': 'Sjöbo',
    '275 33': 'Sjöbo',
    '275 34': 'Sjöbo',
    '275 35': 'Sjöbo',
    '275 36': 'Sjöbo',
    '275 37': 'Sjöbo',
    '275 38': 'Sjöbo',
    '275 39': 'Sjöbo sommarby och Svansjö sommarby',
    '275 61': 'Sjöbo',
    '275 62': 'Blentarp',
    '275 63': 'Sjöbo',
    '275 64': 'Karups sommarby',
    '275 66': 'Vollsjö',
    '275 67': 'Sjöbo',
    '275 68': 'Sjöbo',
    '275 70': 'Vanstad',
    '275 71': 'Sjöbo',
    '275 72': 'Sjöbo',
    '275 75': 'Lövestad',
    '275 76': 'Äsperöd',
    '275 91': 'Sjöbo',
    '275 92': 'Sjöbo',
    '275 93': 'Sjöbo',
    '275 94': 'Sjöbo',
    '275 95': 'Bjärsjölagård',
    '275 96': 'Sövde',
    '276 30': 'Borrby',
    '276 35': 'Simrishamn',
    '276 36': 'Simrishamn',
    '276 37': 'Simrishamn',
    '276 50': 'Hammenhög',
    '276 55': 'Simrishamn',
    '276 56': 'Simrishamn',
    '276 60': 'Skillinge',
    '277 30': 'Simrishamn',
    '277 31': 'Kivik',
    '277 32': 'Vitemölla',
    '277 35': 'Simrishamn',
    '277 36': 'Vitaby',
    '277 37': 'Simrishamn',
    '277 40': 'Sankt Olof',
    '277 45': 'Simrishamn',
    '281 31': 'Hässleholm',
    '281 32': 'Hässleholm',
    '281 33': 'Hässleholm',
    '281 35': 'Sjörröd',
    '281 36': 'Hässleholm',
    '281 37': 'Hässleholm',
    '281 38': 'Hässleholm',
    '281 39': 'Hässleholm',
    '281 40': 'Hässleholm',
    '281 41': 'Hässleholm',
    '281 42': 'Hässleholm',
    '281 43': 'Hässleholm',
    '281 44': 'Stoby',
    '281 46': 'Tormestorp',
    '281 47': 'Hässleholm',
    '281 48': 'Hässleholm',
    '281 49': 'Hässleholm',
    '281 50': 'Hässleholm',
    '281 51': 'Hässleholm',
    '281 52': 'Hässleholm',
    '281 53': 'Finja',
    '281 91': 'Hässleholm',
    '281 92': 'Hässleholm',
    '281 93': 'Hässleholm',
    '281 95': 'Vankiva',
    '281 97': 'Ballingslöv',
    '282 31': 'Hässleholm',
    '282 32': 'Hässleholm',
    '282 33': 'Hässleholm',
    '282 34': 'Hässleholm',
    '282 35': 'Tyringe',
    '282 61': 'Hässleholm',
    '282 62': 'Hässleholm',
    '282 63': 'Bjärnum',
    '282 64': 'Hässleholm',
    '282 65': 'Mala',
    '282 66': 'Hässleholm',
    '282 67': 'Vittsjö',
    '282 68': 'Emmaljunga',
    '282 69': 'Osby',
    '282 71': 'Hässleholm',
    '282 72': 'Sösdala',
    '282 73': 'Hässleholm',
    '282 74': 'Hässleholm',
    '282 75': 'Hästveda',
    '282 76': 'Hässleholm',
    '282 91': 'Hässleholm',
    '282 92': 'Hässleholm',
    '282 93': 'Röke',
    '282 94': 'Hässleholm',
    '282 95': 'Hässleholm',
    '283 31': 'Osby',
    '283 32': 'Osby',
    '283 33': 'Osby',
    '283 34': 'Osby',
    '283 35': 'Osby',
    '283 36': 'Osby',
    '283 41': 'Osby',
    '283 42': 'Osby',
    '283 43': 'Osby',
    '283 44': 'Osby',
    '283 45': 'Osby',
    '283 50': 'Osby',
    '283 71': 'Lönsboda',
    '283 72': 'Osby',
    '283 76': 'Killeberg',
    '283 91': 'Osby',
    '283 93': 'Osby',
    '283 94': 'Osby',
    '283 95': 'Osby',
    '283 96': 'Osby',
    '284 31': 'Perstorp',
    '284 32': 'Perstorp',
    '284 33': 'Perstorp',
    '284 34': 'Perstorp',
    '284 35': 'Perstorp',
    '284 36': 'Perstorp',
    '284 37': 'Perstorp',
    '284 38': 'Perstorp',
    '284 91': 'Perstorp',
    '284 92': 'Perstorp',
    '286 31': 'Örkelljunga',
    '286 32': 'Örkelljunga',
    '286 33': 'Örkelljunga',
    '286 34': 'Örkelljunga',
    '286 35': 'Örkelljunga',
    '286 36': 'Örkelljunga',
    '286 37': 'Örkelljunga',
    '286 38': 'Örkelljunga',
    '286 72': 'Åsljunga',
    '286 73': 'Skånes-Fagerhult',
    '286 74': 'Örkelljunga',
    '286 75': 'Hässleholm',
    '286 91': 'Örkelljunga',
    '286 92': 'Örkelljunga',
    '286 95': 'Eket',
    '288 31': 'Hässleholm',
    '288 32': 'Vinslöv',
    '288 33': 'Hässleholm',
    '288 34': 'Hässleholm',
    '288 90': 'Hässleholm',
    '288 93': 'Hässleholm',
    '289 31': 'Knislinge',
    '289 32': 'Östra Göinge',
    '289 33': 'Östra Göinge',
    '289 41': 'Broby',
    '289 42': 'Östra Göinge',
    '289 43': 'Östra Göinge',
    '289 46': 'Glimåkra',
    '289 47': 'Östra Göinge',
    '289 50': 'Hanaskog',
    '289 71': 'Östra Göinge',
    '289 72': 'Sibbhult',
    '289 73': 'Immeln',
    '289 90': 'Hjärsås',
    '289 92': 'Östanå',
    '289 93': 'Östra Göinge',
    '289 94': 'Östra Göinge',
    '291 31': 'Kristianstad',
    '291 32': 'Kristianstad',
    '291 33': 'Kristianstad',
    '291 34': 'Kristianstad',
    '291 35': 'Kristianstad',
    '291 36': 'Kristianstad',
    '291 37': 'Kristianstad',
    '291 38': 'Kristianstad',
    '291 39': 'Kristianstad',
    '291 40': 'Kristianstad',
    '291 41': 'Kristianstad',
    '291 42': 'Kristianstad',
    '291 43': 'Kristianstad',
    '291 44': 'Kristianstad',
    '291 45': 'Kristianstad',
    '291 46': 'Kristianstad',
    '291 47': 'Kristianstad',
    '291 48': 'Kristianstad',
    '291 49': 'Kristianstad',
    '291 50': 'Hammar',
    '291 51': 'Viby',
    '291 53': 'Kristianstad',
    '291 54': 'Kristianstad',
    '291 55': 'Arkelstorp',
    '291 56': 'Kristianstad',
    '291 57': 'Kristianstad',
    '291 58': 'Hammarslund',
    '291 59': 'Kristianstad',
    '291 60': 'Kristianstad',
    '291 61': 'Kristianstad',
    '291 62': 'Kristianstad',
    '291 63': 'Norra Åsum',
    '291 65': 'Kristianstad',
    '291 66': 'Kristianstad',
    '291 67': 'Fjälkinge',
    '291 68': 'Kristianstad',
    '291 69': 'Vanneberga',
    '291 70': 'Kristianstad',
    '291 73': 'Önnestad',
    '291 75': 'Färlöv',
    '291 76': 'Rinkaby',
    '291 77': 'Gärds Köpinge',
    '291 91': 'Kristianstad',
    '291 92': 'Ovesholm',
    '291 93': 'Kristianstad',
    '291 94': 'Torsebro',
    '291 95': 'Kristianstad',
    '291 97': 'Kristianstad',
    '291 98': 'Kristianstad',
    '295 31': 'Bromölla',
    '295 32': 'Bromölla',
    '295 34': 'Bromölla',
    '295 35': 'Bromölla',
    '295 36': 'Bromölla',
    '295 37': 'Bromölla',
    '295 38': 'Bromölla',
    '295 39': 'Bromölla',
    '295 51': 'Bromölla',
    '295 72': 'Gualöv',
    '295 73': 'Nymölla',
    '295 74': 'Näsum',
    '295 91': 'Edenryd',
    '295 94': 'Bromölla',
    '296 31': 'Åhus',
    '296 32': 'Kristianstad',
    '296 33': 'Kristianstad',
    '296 34': 'Kristianstad',
    '296 35': 'Kristianstad',
    '296 37': 'Kristianstad',
    '296 38': 'Kristianstad',
    '296 41': 'Kristianstad',
    '296 72': 'Yngsjö',
    '296 91': 'Kristianstad',
    '296 92': 'Kristianstad',
    '297 31': 'Degeberga',
    '297 32': 'Kristianstad',
    '297 72': 'Everöd',
    '297 91': 'Vittskövle',
    '297 92': 'Kristianstad',
    '297 94': 'Kristianstad',
    '297 95': 'Kristianstad',
    '298 31': 'Tollarp',
    '298 32': 'Kristianstad',
    '298 33': 'Kristianstad',
    '298 34': 'Kristianstad',
    '298 91': 'Kristianstad',
    '298 92': 'Kristianstad',
    '298 93': 'Huaröd',
    '298 94': 'Linderöd',
    '298 96': 'Östra Sönnarslöv',
    '611 30': 'Väster',
    '611 31': 'Spelhagen',
    '611 32': 'Nyköping',
    '611 33': 'Öster',
    '611 34': 'Nyköping',
    '611 35': 'Kungshagen',
    '611 36': 'Fågelbo',
    '611 37': 'Isaksdal',
    '611 38': 'Nyköping',
    '611 39': 'Nöthagen',
    '611 44': 'Sjösa',
    '611 45': 'Arnö',
    '611 46': 'Herrhagen',
    '611 47': 'Örstig',
    '611 50': 'Bergshammar',
    '611 55': 'Oppeby Gård',
    '611 56': 'Oppeby',
    '611 57': 'Harg',
    '611 60': 'Stenkulla',
    '611 61': 'Nyköping',
    '611 62': 'Rosenkälla',
    '611 63': 'Bryngelstorp',
    '611 64': 'Brandholmen',
    '611 65': 'Ekensberg',
    '611 66': 'Brandkärr',
    '611 67': 'Oxbacken',
    '611 70': 'Jönåker',
    '611 72': 'Stigtomta',
    '611 73': 'Vrena',
    '611 74': 'Tystberga',
    '611 75': 'Enstaberga',
    '611 76': 'Nävekvarn',
    '611 90': 'Ålberga',
    '611 91': 'Nyköping',
    '611 92': 'Bränn-Ekeby',
    '611 93': 'Runtuna',
    '611 94': 'Påljungshage',
    '611 95': 'Buskhyttan',
    '611 96': 'Nyköping',
    '611 97': 'Nyköping',
    '611 98': 'Ekeby',
    '611 99': 'Lästringe',
    '613 30': 'Oxelösund',
    '613 31': 'Oxelösund',
    '613 32': 'Oxelösund',
    '613 33': 'Oxelösund',
    '613 35': 'Ramdalshöjden',
    '613 36': 'Stenvik',
    '613 37': 'Danvikshagen',
    '613 38': 'Inskogen',
    '613 40': 'Oxelösund',
    '613 41': 'Oxelösund',
    '613 51': 'Oxelösund',
    '618 95': 'Stavsjö',
    '619 30': 'Trosa',
    '619 31': 'Trosa',
    '619 32': 'Trosa',
    '619 33': 'Trosa',
    '619 34': 'Trosa',
    '619 35': 'Trosa',
    '619 71': 'Vagnhärad',
    '619 72': 'Trosa',
    '619 73': 'Trosa',
    '619 91': 'Stensund och Krymla',
    '619 92': 'Stora Uttervik',
    '619 94': 'Sund',
    '619 95': 'Västerljung',
    '632 17': 'Eskilstuna',
    '632 18': 'Eskilstuna',
    '632 19': 'Eskilstuna',
    '632 20': 'Eskilstuna',
    '632 21': 'Eskilstuna',
    '632 22': 'Flacksta',
    '632 23': 'Brottsta',
    '632 24': 'Eskilstuna',
    '632 25': 'Eskilstuna',
    '632 26': 'Eskilstuna',
    '632 27': 'Eskilstuna',
    '632 28': 'Eskilstuna',
    '632 29': 'Eskilstuna',
    '632 30': 'Eskilstuna',
    '632 31': 'Eskilstuna',
    '632 32': 'Eskilstuna',
    '632 33': 'Borsökna',
    '632 34': 'Eskilstuna',
    '632 35': 'Eskilstuna',
    '632 36': 'Mesta',
    '632 37': 'Eskilstuna',
    '632 39': 'Hällbybrunn',
    '633 40': 'Eskilstuna',
    '633 41': 'Gamla staden',
    '633 42': 'Eskilstuna',
    '633 43': 'Eskilstuna',
    '633 44': 'Eskilstuna',
    '633 45': 'Eskilstuna',
    '633 46': 'Eskilstuna',
    '633 47': 'Slagsta',
    '633 48': 'Måsta',
    '633 49': 'Eskilstuna',
    '633 50': 'Eskilstuna',
    '633 51': 'Eskilstuna',
    '633 52': 'Öster',
    '633 53': 'Skiftinge',
    '633 54': 'Eskilstuna',
    '633 55': 'Eskilstuna',
    '633 56': 'Söder',
    '633 57': 'Eskilstuna',
    '633 58': 'Snopptorp',
    '633 59': 'Eskilstuna',
    '633 62': 'Eskilstuna',
    '633 69': 'Skogstorp',
    '633 70': 'Hållsta',
    '635 02': 'Eskilstuna',
    '635 03': 'Eskilstuna',
    '635 04': 'Eskilstuna',
    '635 05': 'Eskilstuna',
    '635 06': 'Kjulaås',
    '635 07': 'Eskilstuna',
    '635 08': 'Sundbyholm',
    '635 09': 'Eskilstuna',
    '635 10': 'Eskilstuna',
    '635 11': 'Eskilstuna',
    '635 12': 'Eskilstuna',
    '635 13': 'Eskilstuna',
    '635 14': 'Eskilstuna',
    '635 17': 'Svalboviken',
    '635 18': 'Bälgviken',
    '635 19': 'Hällberga',
    '635 20': 'Eskilstuna',
    '635 31': 'Tumbo',
    '635 34': 'Alberga',
    '635 35': 'Eskilstuna',
    '635 36': 'Ärla',
    '635 37': 'Eskilstuna',
    '640 31': 'Mellösa',
    '641 30': 'Katrineholm',
    '641 31': 'Katrineholm',
    '641 32': 'Katrineholm',
    '641 33': 'Katrineholm',
    '641 34': 'Katrineholm',
    '641 35': 'Katrineholm',
    '641 36': 'Katrineholm',
    '641 37': 'Katrineholm',
    '641 39': 'Katrineholm',
    '641 45': 'Katrineholm',
    '641 46': 'Katrineholm',
    '641 47': 'Lasstorp',
    '641 48': 'Katrineholm',
    '641 49': 'Katrineholm',
    '641 50': 'Katrineholm',
    '641 51': 'Katrineholm',
    '641 52': 'Katrineholm',
    '641 53': 'Katrineholm',
    '641 61': 'Valla',
    '641 62': 'Lerbo',
    '641 63': 'Björkvik',
    '641 64': 'Sköldinge',
    '641 91': 'Östra Vingåker',
    '641 92': 'Strångsjö',
    '641 93': 'Forssjö',
    '641 94': 'Baggetorp',
    '641 95': 'Marmorbyn',
    '641 96': 'Bie',
    '641 97': 'Katrineholm',
    '641 98': 'Katrineholm',
    '641 99': 'Katrineholm',
    '642 31': 'Flen',
    '642 32': 'Flen',
    '642 33': 'Flen',
    '642 34': 'Flen',
    '642 35': 'Flen',
    '642 36': 'Flen',
    '642 37': 'Flen',
    '642 60': 'Flen',
    '642 61': 'Malmköping',
    '642 62': 'Bettna',
    '642 91': 'Skebokvarn',
    '642 92': 'Orrhammar',
    '642 93': 'Flen',
    '642 94': 'Flen',
    '642 95': 'Flen',
    '642 96': 'Flen',
    '642 97': 'Flen',
    '642 98': 'Nyköping',
    '643 30': 'Vingåker',
    '643 31': 'Vingåker',
    '643 32': 'Vingåker',
    '643 33': 'Vingåker',
    '643 60': 'Äsköping',
    '643 62': 'Högsjö',
    '643 91': 'Vingåker',
    '643 92': 'Vingåker',
    '643 93': 'Österåker',
    '643 95': 'Läppe',
    '643 96': 'Katrineholm',
    '643 97': 'Eskilstuna',
    '643 98': 'Katrineholm',
    '644 30': 'Eskilstuna',
    '644 31': 'Eskilstuna',
    '644 32': 'Torshälla',
    '644 33': 'Eskilstuna',
    '644 34': 'Eskilstuna',
    '644 35': 'Eskilstuna',
    '644 36': 'Torshälla huvud',
    '645 30': 'Strängnäs',
    '645 31': 'Strängnäs',
    '645 32': 'Strängnäs',
    '645 33': 'Strängnäs',
    '645 34': 'Strängnäs',
    '645 35': 'Strängnäs',
    '645 40': 'Ulvhäll',
    '645 41': 'Strängnäs',
    '645 42': 'Strängnäs',
    '645 43': 'Finninge',
    '645 44': 'Strängnäs',
    '645 45': 'Strängnäs',
    '645 47': 'Strängnäs',
    '645 50': 'Abborrberget',
    '645 51': 'Sundby',
    '645 61': 'Stallarholmen',
    '645 62': 'Strängnäs',
    '645 91': 'Härad',
    '645 92': 'Vansö',
    '645 93': 'Sanda',
    '645 94': 'Djupvik',
    '645 96': 'Strängnäs',
    '645 97': 'Strängnäs',
    '646 30': 'Dansut',
    '646 31': 'Gnesta',
    '646 32': 'Gnesta',
    '646 33': 'Gnesta',
    '646 34': 'Vackerby',
    '646 35': 'Gnesta',
    '646 50': 'Björnlunda',
    '646 51': 'Stjärnhov',
    '646 91': 'Frustuna',
    '646 92': 'Torsåker',
    '646 93': 'Nibble',
    '646 94': 'Gnesta',
    '646 95': 'Gnesta',
    '646 96': 'Gnesta',
    '647 30': 'Mariefred',
    '647 31': 'Slottsbrinken',
    '647 32': 'Ekhov',
    '647 35': 'Marielund',
    '647 50': 'Strängnäs',
    '647 51': 'Strängnäs',
    '647 52': 'Åkers styckebruk',
    '647 91': 'Strängnäs',
    '647 92': 'Läggesta',
    '647 93': 'Kalkudden',
    '647 95': 'Strängnäs',
    '648 30': 'Hälleforsnäs',
    '648 31': 'Flen',
    '649 30': 'Sparreholm',
    '649 91': 'Flen',
    '732 93': 'Eskilstuna',
    '740 10': 'Almunge',
    '740 11': 'Länna',
    '740 12': 'Knutby',
    '740 20': 'Skogs-Tibble',
    '740 45': 'Tärnsjö',
    '740 46': 'Östervåla',
    '740 47': 'Harbo',
    '741 30': 'Knivsta',
    '741 31': 'Knivsta',
    '741 39': 'Knivsta',
    '741 40': 'Knivsta',
    '741 41': 'Knivsta',
    '741 42': 'Knivsta',
    '741 43': 'Knivsta',
    '741 44': 'Knivsta',
    '741 45': 'Knivsta',
    '741 46': 'Knivsta',
    '741 47': 'Knivsta',
    '741 71': 'Knivsta',
    '741 90': 'Knivsta',
    '741 91': 'Vassunda',
    '741 92': 'Alsike',
    '741 93': 'Lagga',
    '741 94': 'Knivsta',
    '741 95': 'Husby',
    '742 31': 'Östhammar',
    '742 32': 'Östhammar',
    '742 33': 'Östhammar',
    '742 34': 'Östhammar',
    '742 35': 'Östhammar',
    '742 36': 'Östhammar',
    '742 41': 'Östhammar',
    '742 42': 'Öregrund',
    '742 43': 'Östhammar',
    '742 50': 'Östhammar',
    '742 91': 'Östhammar',
    '742 92': 'Östhammar',
    '742 93': 'Norrskedika',
    '742 94': 'Forsmark',
    '742 95': 'Hargshamn',
    '742 96': 'Östhammar',
    '742 97': 'Östhammar',
    '743 30': 'Uppsala',
    '743 32': 'Uppsala',
    '743 34': 'Storvreta',
    '743 35': 'Uppsala',
    '743 40': 'Uppsala',
    '743 41': 'Uppsala',
    '743 45': 'Uppsala',
    '743 50': 'Vattholma',
    '743 61': 'Uppsala',
    '743 62': 'Björklinge',
    '743 63': 'Uppsala',
    '743 64': 'Uppsala',
    '743 71': 'Uppsala',
    '743 72': 'Läby',
    '743 73': 'Uppsala',
    '743 74': 'Uppsala',
    '743 81': 'Uppsala',
    '743 82': 'Bälinge',
    '743 86': 'Blackstalund',
    '743 87': 'Uppsala',
    '743 91': 'Uppsala',
    '743 92': 'Uppsala',
    '743 93': 'Uppsala',
    '743 94': 'Skyttorp',
    '744 31': 'Heby',
    '744 32': 'Heby',
    '744 50': 'Morgongåva',
    '744 51': 'Heby',
    '744 91': 'Heby',
    '744 92': 'Huddunge',
    '744 93': 'Runhällen',
    '744 94': 'Heby',
    '744 95': 'Vittinge',
    '744 96': 'Järlåsa',
    '744 97': 'Uppsala',
    '745 30': 'Enköping',
    '745 31': 'Enköping',
    '745 32': 'Enköping',
    '745 33': 'Enköping',
    '745 34': 'Enköping',
    '745 36': 'Enköping',
    '745 37': 'Myran',
    '745 38': 'Enköping',
    '745 39': 'Romberga',
    '745 60': 'Enköping',
    '745 61': 'Enköping',
    '745 62': 'Galgvreten',
    '745 63': 'Lillsidan',
    '745 71': 'Hummelsta',
    '745 80': 'Enköping',
    '745 92': 'Enköping',
    '745 93': 'Enköping',
    '745 94': 'Enköping',
    '745 95': 'Enköping',
    '745 96': 'Enköping',
    '745 97': 'Lillkyrka',
    '745 98': 'Eknäs',
    '745 99': 'Enköping',
    '746 30': 'Håbo',
    '746 31': 'Håbo',
    '746 32': 'Bålsta',
    '746 33': 'Håbo',
    '746 34': 'Håbo',
    '746 35': 'Håbo',
    '746 36': 'Håbo',
    '746 37': 'Håbo',
    '746 38': 'Håbo',
    '746 39': 'Håbo',
    '746 40': 'Håbo',
    '746 41': 'Håbo',
    '746 50': 'Håbo',
    '746 51': 'Håbo',
    '746 52': 'Håbo',
    '746 91': 'Råby',
    '746 92': 'Håbo',
    '746 93': 'Krägga',
    '746 94': 'Håbo',
    '746 95': 'Söderskogen',
    '746 96': 'Slottsskogen',
    '747 30': 'Östhammar',
    '747 31': 'Alunda',
    '747 32': 'Östhammar',
    '747 40': 'Östhammar',
    '747 41': 'Gimo',
    '747 42': 'Östhammar',
    '747 43': 'Östhammar',
    '747 44': 'Östhammar',
    '747 91': 'Skoby',
    '747 92': 'Östhammar',
    '747 93': 'Uppsala',
    '747 94': 'Uppsala',
    '747 95': 'Östhammar',
    '748 30': 'Dannemora',
    '748 31': 'Östhammar',
    '748 32': 'Österbybruk',
    '748 40': 'Tierp',
    '748 41': 'Örbyhus',
    '748 42': 'Tierp',
    '748 50': 'Tobo',
    '748 91': 'Östhammar',
    '748 92': 'Film',
    '748 93': 'Östhammar',
    '748 94': 'Upplanda',
    '748 95': 'Vendel',
    '748 96': 'Tierp',
    '749 35': 'Enköping',
    '749 40': 'Enköping',
    '749 41': 'Enköping',
    '749 42': 'Fanna',
    '749 43': 'Enköping',
    '749 44': 'Bergvreten',
    '749 45': 'Husberg',
    '749 46': 'Enköping',
    '749 47': 'Enköping',
    '749 48': 'Bredsand',
    '749 49': 'Enköping',
    '749 50': 'Enköping',
    '749 51': 'Enköping',
    '749 52': 'Grillby',
    '749 59': 'Enköping',
    '749 60': 'Örsundsbro',
    '749 61': 'Enköping',
    '749 62': 'Hjälsta',
    '749 63': 'Nysätra',
    '749 70': 'Fjärdhundra',
    '749 71': 'Frösthult',
    '749 72': 'Österunda',
    '752 17': 'Uppsala',
    '752 18': 'Uppsala',
    '752 19': 'Uppsala',
    '752 20': 'Uppsala',
    '752 21': 'Uppsala',
    '752 22': 'Uppsala',
    '752 23': 'Uppsala',
    '752 24': 'Uppsala',
    '752 25': 'Uppsala',
    '752 26': 'Uppsala',
    '752 27': 'Luthagen',
    '752 28': 'Fyrisvall',
    '752 29': 'Stabby',
    '752 30': 'Uppsala',
    '752 31': 'Rickomberga',
    '752 32': 'Uppsala',
    '752 33': 'Uppsala',
    '752 34': 'Uppsala',
    '752 35': 'Uppsala',
    '752 36': 'Kåbo',
    '752 37': 'Polacksbacken',
    '752 38': 'Uppsala',
    '752 39': 'Kungsgärdet',
    '752 40': 'Uppsala',
    '752 41': 'Eriksberg',
    '752 42': 'Uppsala',
    '752 43': 'Uppsala',
    '752 44': 'Uppsala',
    '752 57': 'Uppsala',
    '752 58': 'Uppsala',
    '752 60': 'Berthåga',
    '752 61': 'Flogsta',
    '752 62': 'Uppsala',
    '752 63': 'Ekeby',
    '752 64': 'Uppsala',
    '752 65': 'Uppsala',
    '752 66': 'Stenhagen',
    '752 67': 'Uppsala',
    '752 68': 'Uppsala',
    '752 69': 'Uppsala',
    '752 71': 'Uppsala',
    '752 72': 'Uppsala',
    '752 73': 'Uppsala',
    '753 08': 'Uppsala',
    '753 09': 'Uppsala',
    '753 10': 'Fjärdingen',
    '753 11': 'Uppsala',
    '753 12': 'Uppsala',
    '753 13': 'Uppsala',
    '753 14': 'Uppsala',
    '753 15': 'Uppsala',
    '753 16': 'Uppsala',
    '753 17': 'Uppsala',
    '753 18': 'Uppsala',
    '753 19': 'Uppsala',
    '753 20': 'Centrum',
    '753 21': 'Uppsala',
    '753 22': 'Uppsala',
    '753 23': 'Boländerna',
    '753 24': 'Fålhagen',
    '753 25': 'Uppsala',
    '753 26': 'Uppsala',
    '753 27': 'Uppsala',
    '753 28': 'Uppsala',
    '753 29': 'Uppsala',
    '753 30': 'Kvarngärdet',
    '753 31': 'Uppsala',
    '753 32': 'Uppsala',
    '753 33': 'Uppsala',
    '753 34': 'Svartbäcken',
    '753 35': 'Uppsala',
    '753 36': 'Tuna backar',
    '753 37': 'Ärna',
    '753 40': 'Uppsala',
    '753 41': 'Uppsala',
    '753 42': 'Uppsala',
    '754 18': 'Uppsala',
    '754 19': 'Uppsala',
    '754 20': 'Uppsala',
    '754 21': 'Uppsala',
    '754 22': 'Uppsala',
    '754 23': 'Uppsala',
    '754 24': 'Kapellgärdet',
    '754 25': 'Uppsala',
    '754 26': 'Uppsala',
    '754 27': 'Löten',
    '754 28': 'Uppsala',
    '754 29': 'Uppsala',
    '754 30': 'Gränby',
    '754 31': 'Uppsala',
    '754 32': 'Uppsala',
    '754 33': 'Uppsala',
    '754 34': 'Sala backe',
    '754 35': 'Uppsala',
    '754 36': 'Uppsala',
    '754 37': 'Uppsala',
    '754 39': 'Uppsala',
    '754 40': 'Gamla Uppsala',
    '754 41': 'Uppsala',
    '754 42': 'Nyby',
    '754 43': 'Uppsala',
    '754 44': 'Uppsala',
    '754 45': 'Uppsala',
    '754 46': 'Årsta',
    '754 47': 'Uppsala',
    '754 48': 'Uppsala',
    '754 49': 'Uppsala',
    '754 50': 'Fyrislund',
    '754 52': 'Uppsala',
    '754 53': 'Uppsala',
    '754 54': 'Uppsala',
    '754 60': 'Uppsala',
    '754 71': 'Lindbacken',
    '755 50': 'Lövstalöt',
    '755 91': 'Vreta',
    '755 92': 'Börje',
    '755 93': 'Forkarby',
    '755 94': 'Skölsta',
    '755 95': 'Uppsala',
    '755 96': 'Gåvsta',
    '755 97': 'Gunsta',
    '755 98': 'Danmark',
    '756 42': 'Uppsala',
    '756 43': 'Ulleråker',
    '756 44': 'Rosendal',
    '756 45': 'Norby',
    '756 46': 'Uppsala',
    '756 47': 'Uppsala',
    '756 48': 'Valsätra',
    '756 49': 'Uppsala',
    '756 50': 'Uppsala',
    '756 51': 'Uppsala',
    '756 52': 'Sunnersta',
    '756 53': 'Flottsund',
    '756 54': 'Uppsala',
    '756 55': 'Vårdsätra',
    '756 56': 'Uppsala',
    '756 57': 'Uppsala',
    '756 58': 'Gottsunda',
    '756 59': 'Uppsala',
    '757 52': 'Uppsala',
    '757 53': 'Uppsala',
    '757 54': 'Sävja',
    '757 55': 'Uppsala',
    '757 56': 'Bergsbrunna',
    '757 57': 'Nåntuna',
    '757 58': 'Uppsala',
    '757 59': 'Uppsala',
    '814 30': 'Älvkarleby',
    '814 31': 'Skutskär',
    '814 32': 'Älvkarleby',
    '814 40': 'Älvkarleby',
    '814 41': 'Älvkarleby',
    '814 42': 'Älvkarleby',
    '814 70': 'Älvkarleby',
    '814 81': 'Älvkarleby',
    '814 92': 'Älvkarleby',
    '814 93': 'Gårdskär',
    '814 94': 'Älvkarleby',
    '814 95': 'Marma',
    '815 35': 'Tierp',
    '815 36': 'Tierp',
    '815 37': 'Tierp',
    '815 38': 'Tierp',
    '815 40': 'Tierp',
    '815 41': 'Tierp',
    '815 44': 'Tierp',
    '815 45': 'Tierp',
    '815 69': 'Månkarbo',
    '815 75': 'Tierp',
    '815 76': 'Söderfors',
    '815 91': 'Frebro',
    '815 92': 'Tolfta',
    '815 93': 'Mehedeby',
    '815 94': 'Västland',
    '815 95': 'Tierp',
    '815 96': 'Strömsberg',
    '819 30': 'Skärplinge',
    '819 40': 'Karlholmsbruk',
    '819 61': 'Österlövsta',
    '819 62': 'Tierp',
    '819 63': 'Fagerviken',
    '819 64': 'Edvalla',
    '819 65': 'Tierp',
    '819 66': 'Tierp',
    '652 14': 'Karlstad',
    '652 15': 'Karlstad',
    '652 16': 'Karlstad',
    '652 17': 'Karlstad',
    '652 18': 'Karlstad',
    '652 19': 'Herrhagen',
    '652 20': 'Haga',
    '652 21': 'Tormestad',
    '652 22': 'Karlstad',
    '652 23': 'Karlstad',
    '652 24': 'Tingvallastaden',
    '652 25': 'Karlstad',
    '652 26': 'Viken',
    '652 27': 'Orrholmen',
    '652 28': 'Karlstad',
    '652 29': 'Marieberg',
    '652 30': 'Sommarro',
    '653 38': 'Zakrisdal',
    '653 39': 'Karlstad',
    '653 40': 'Klara',
    '653 41': 'Strand',
    '653 42': 'Romstad',
    '653 43': 'Gruvlyckan',
    '653 44': 'Våxnäs',
    '653 45': 'Hagalund',
    '653 46': 'Bergvik',
    '653 47': 'Karlstad',
    '653 48': 'Karlstad',
    '653 49': 'Älvåker',
    '653 50': 'Skåre',
    '653 51': 'Karlstad',
    '654 55': 'Karlstad',
    '654 57': 'Karlstad',
    '654 58': 'Karlstad',
    '654 59': 'Karlstad',
    '654 60': 'Sjöstad',
    '654 61': 'Norrstrand',
    '654 62': 'Karlstad',
    '654 63': 'Sundsta',
    '654 64': 'Karlstad',
    '654 65': 'Jägartorpet',
    '654 66': 'Rud',
    '654 67': 'Karlstad',
    '654 68': 'Norra Kroppkärr',
    '654 69': 'Stockfallet',
    '655 60': 'Molkom',
    '655 61': 'Karlstad',
    '655 91': 'Karlstad',
    '655 92': 'Karlstad',
    '655 93': 'Vallargärdet',
    '655 94': 'Karlstad',
    '655 95': 'Väse',
    '655 96': 'Karlstad',
    '655 97': 'Blombacka',
    '655 98': 'Acksjön',
    '656 31': 'Karlstad',
    '656 32': 'Karlstad',
    '656 33': 'Karlstad',
    '656 34': 'Kronoparken',
    '656 35': 'Granhult',
    '656 36': 'Karlstad',
    '656 37': 'Karlstad',
    '656 38': 'Mjölnartorpet',
    '656 39': 'Välsviken',
    '656 71': 'Karlstad',
    '656 72': 'Skattkärr',
    '661 30': 'Säffle',
    '661 31': 'Annelund',
    '661 32': 'Höglunda',
    '661 33': 'Norelund',
    '661 40': 'Rolfserud',
    '661 41': 'Säffle',
    '661 42': 'Säffle',
    '661 43': 'Treabackarna',
    '661 91': 'Eskilsäter',
    '661 92': 'Värmlandsbro',
    '661 93': 'Säffle',
    '661 94': 'Säffle',
    '661 95': 'Nysäter',
    '661 96': 'Säffle',
    '662 50': 'Svaneholm',
    '662 96': 'Säffle',
    '663 30': 'Mörmon',
    '663 31': 'Edsviken',
    '663 32': 'Gunnarskär',
    '663 33': 'Hammarö',
    '663 34': 'Götetorp',
    '663 40': 'Jonsbol',
    '663 41': 'Lövnäs',
    '663 42': 'Hallersrud',
    '663 43': 'Bärstad',
    '663 91': 'Fiskvik',
    '663 92': 'Holken',
    '664 30': 'Grums',
    '664 31': 'Grums',
    '664 32': 'Grums',
    '664 33': 'Grums',
    '664 34': 'Grums',
    '664 40': 'Slottsbron',
    '664 50': 'Karlstad',
    '664 51': 'Karlstad',
    '664 52': 'Vålberg',
    '664 91': 'Grums',
    '664 92': 'Grums',
    '664 93': 'Edsvalla',
    '664 94': 'Karlstad',
    '664 95': 'Grums',
    '664 96': 'Segmon',
    '665 30': 'Kil',
    '665 31': 'Kil',
    '665 32': 'Kil',
    '665 33': 'Kil',
    '665 34': 'Kil',
    '665 35': 'Kil',
    '665 91': 'Nilsby',
    '665 92': 'Kil',
    '665 93': 'Högboda',
    '665 94': 'Fagerås',
    '667 30': 'Dyvelsten',
    '667 31': 'Forshaga',
    '667 32': 'Forshaga',
    '667 33': 'Forshaga',
    '667 34': 'Forshaga',
    '667 91': 'Forshaga',
    '669 30': 'Forshaga',
    '669 31': 'Deje',
    '669 32': 'Forshaga',
    '669 91': 'Forshaga',
    '669 92': 'Olsäter',
    '670 10': 'Töcksfors',
    '670 20': 'Glava',
    '670 35': 'Gunnarskog',
    '670 40': 'Åmotfors',
    '670 41': 'Koppom',
    '670 43': 'Skillingsfors',
    '671 30': 'Arvika',
    '671 31': 'Arvika',
    '671 32': 'Arvika',
    '671 33': 'Dottevik',
    '671 34': 'Arvika',
    '671 40': 'Arvika',
    '671 41': 'Arvika',
    '671 42': 'Arvika',
    '671 50': 'Arvika',
    '671 51': 'Arvika',
    '671 52': 'Arvika',
    '671 60': 'Jössefors',
    '671 70': 'Edane',
    '671 91': 'Arvika',
    '671 92': 'Arvika',
    '671 93': 'Sulvik',
    '671 94': 'Arvika',
    '671 95': 'Klässbol',
    '671 96': 'Arvika',
    '672 30': 'Årjäng',
    '672 31': 'Årjäng',
    '672 32': 'Årjäng',
    '672 91': 'Årjäng',
    '672 92': 'Årjäng',
    '672 93': 'Årjäng',
    '672 94': 'Årjäng',
    '672 95': 'Årjäng',
    '673 31': 'Eda',
    '673 32': 'Charlottenberg',
    '673 91': 'Eda',
    '673 92': 'Eda Glasbruk',
    '673 93': 'Eda',
    '680 51': 'Stöllet',
    '680 52': 'Ambjörby',
    '680 60': 'Sysslebäck',
    '680 61': 'Torsby',
    '680 63': 'Torsby',
    '680 65': 'Höljes',
    '680 71': 'Björneborg',
    '680 90': 'Nykroppa',
    '680 96': 'Lesjöfors',
    '681 30': 'Kristinehamn',
    '681 31': 'Kristinehamn',
    '681 32': 'Kristinehamn',
    '681 33': 'Kristinehamn',
    '681 34': 'Marielund',
    '681 35': 'Villastaden',
    '681 36': 'Kristinehamn',
    '681 37': 'Marieberg',
    '681 38': 'Kristinehamn',
    '681 40': 'Södermalm',
    '681 41': 'Kristinehamn',
    '681 42': 'Drevsta',
    '681 43': 'Östermalm',
    '681 50': 'Kristinehamn',
    '681 51': 'Kristinehamn',
    '681 52': 'Fältet',
    '681 53': 'Kristinehamn',
    '681 54': 'Presterud',
    '681 60': 'Bäckhammar',
    '681 91': 'Kristinehamn',
    '681 92': 'Kristinehamn',
    '681 93': 'Kristinehamn',
    '681 94': 'Kristinehamn',
    '681 95': 'Ölme',
    '681 96': 'Kristinehamn',
    '682 30': 'Filipstad',
    '682 31': 'Filipstad',
    '682 32': 'Filipstad',
    '682 33': 'Filipstad',
    '682 34': 'Filipstad',
    '682 40': 'Persberg',
    '682 91': 'Filipstad',
    '682 92': 'Filipstad',
    '682 93': 'Nordmark',
    '683 30': 'Hagfors',
    '683 31': 'Hagfors',
    '683 32': 'Hagfors',
    '683 33': 'Hagfors',
    '683 34': 'Hagfors',
    '683 40': 'Uddeholm',
    '683 60': 'Ekshärad',
    '683 61': 'Hagfors',
    '683 62': 'Hagfors',
    '683 91': 'Geijersholm',
    '683 92': 'Edebäck',
    '683 93': 'Råda',
    '683 94': 'Hagfors',
    '683 95': 'Sunnemo',
    '684 30': 'Blia',
    '684 31': 'Munkfors',
    '684 32': 'Munkerud',
    '684 91': 'Mossängen',
    '684 92': 'Mjönäs',
    '684 93': 'Ransäter',
    '684 95': 'Hagfors',
    '685 30': 'Torsby',
    '685 32': 'Torsby',
    '685 33': 'Torsby',
    '685 34': 'Torsby',
    '685 91': 'Torsby',
    '685 92': 'Oleby',
    '685 93': 'Torsby',
    '685 94': 'Torsby',
    '685 97': 'Östmark',
    '686 30': 'Sunne',
    '686 31': 'Sunne',
    '686 33': 'Sunne',
    '686 34': 'Sunne',
    '686 35': 'Leran',
    '686 91': 'Sunne',
    '686 92': 'Sunne',
    '686 93': 'Sunne',
    '686 94': 'Rottneros',
    '686 95': 'Västra Ämtervik',
    '686 96': 'Sunne',
    '686 97': 'Lysvik',
    '686 98': 'Uddheden',
    '688 30': 'Storfors',
    '688 31': 'Storfors',
    '688 91': 'Storfors',
    '688 92': 'Storfors',
    '691 91': 'Kyrksten',
    '901 30': 'Umeå',
    '901 31': 'Umeå',
    '901 32': 'Umeå',
    '901 33': 'Umeå',
    '901 37': 'Umeå',
    '903 20': 'Umeå',
    '903 21': 'Umeå',
    '903 22': 'Umeå',
    '903 23': 'Väst på stan',
    '903 25': 'Umeå',
    '903 26': 'Umeå',
    '903 27': 'Umeå',
    '903 28': 'Umeå',
    '903 29': 'Umeå',
    '903 30': 'Umeå',
    '903 31': 'Öst på stan',
    '903 32': 'Umeå',
    '903 33': 'Öbacka',
    '903 34': 'Umeå',
    '903 36': 'Haga',
    '903 37': 'Fridhem',
    '903 38': 'Umeå',
    '903 39': 'Berghem',
    '903 40': 'Umeå',
    '903 42': 'Umeå',
    '903 43': 'Umeå',
    '903 44': 'Umeå',
    '903 45': 'Umeå',
    '903 46': 'Sandbacka',
    '903 47': 'Umeå',
    '903 51': 'Umeå',
    '903 52': 'Västerslätts bostadsområde',
    '903 53': 'Rödäng',
    '903 54': 'Grubbe',
    '903 55': 'Grisbacka',
    '903 60': 'Umeå',
    '903 61': 'Umeå',
    '903 62': 'Backen',
    '903 63': 'Umeå',
    '903 64': 'Umedalen',
    '903 65': 'Umeå',
    '904 20': 'Teg',
    '904 21': 'Umeå',
    '904 22': 'Alvik',
    '904 26': 'Umeå',
    '904 31': 'Umeå',
    '904 32': 'Umeå',
    '904 33': 'Väst-Teg',
    '904 34': 'Böle',
    '904 35': 'Böleäng',
    '904 36': 'Umeå',
    '904 40': 'Röbäck',
    '904 41': 'Umeå',
    '905 31': 'Umeå',
    '905 32': 'Hörnefors',
    '905 71': 'Umeå',
    '905 72': 'Umeå',
    '905 80': 'Stöcksjö',
    '905 81': 'Stöcke',
    '905 82': 'Norrmjöle',
    '905 83': 'Sörmjöle',
    '905 86': 'Yttersjö',
    '905 87': 'Klabböle',
    '905 88': 'Sörfors',
    '905 91': 'Hissjö',
    '905 92': 'Baggböle',
    '905 93': 'Brattby',
    '905 94': 'Österå',
    '905 95': 'Bodbyn',
    '905 96': 'Tavleliden',
    '906 20': 'Umeå',
    '906 21': 'Umeå',
    '906 22': 'Umeå',
    '906 24': 'Umeå',
    '906 25': 'Umeå',
    '906 26': 'Ersboda',
    '906 27': 'Umeå',
    '906 28': 'Umeå',
    '906 29': 'Umeå',
    '906 30': 'Umeå',
    '906 37': 'Ersmark',
    '906 38': 'Umeå',
    '906 40': 'Umeå',
    '906 41': 'Sandahöjd',
    '906 42': 'Mariedal',
    '906 43': 'Marieberg',
    '906 50': 'Umeå',
    '906 51': 'Mariehem',
    '906 52': 'Nydala fritidsområde',
    '906 53': 'Umeå',
    '906 54': 'Umeå',
    '906 55': 'Nydalahöjden',
    '906 56': 'Umeå',
    '906 60': 'Mariestrand',
    '907 27': 'Umeå',
    '907 28': 'Lilljansberget',
    '907 29': 'Umeå',
    '907 30': 'Umeå',
    '907 31': 'Umeå',
    '907 32': 'Umeå',
    '907 33': 'Ålidhem',
    '907 34': 'Umeå',
    '907 35': 'Umeå',
    '907 36': 'Ålidhöjden',
    '907 37': 'Ålidbacken',
    '907 38': 'Sofiehem',
    '907 40': 'Umeå',
    '907 41': 'Carlshem',
    '907 42': 'Carlshöjd',
    '907 43': 'Umeå',
    '907 46': 'Umeå',
    '907 49': 'Umeå',
    '907 50': 'Carlslid',
    '907 51': 'Tomtebo',
    '907 52': 'Umeå',
    '907 53': 'Umeå',
    '907 54': 'Umeå',
    '907 88': 'Täfteå',
    '910 88': 'Saxnäs',
    '911 30': 'Vännäs',
    '911 31': 'Vännäs',
    '911 32': 'Vännäs',
    '911 33': 'Älvdala',
    '911 34': 'Lägret',
    '911 35': 'Vännäs',
    '911 36': 'Vännäsby',
    '911 91': 'Orrböle',
    '911 92': 'Västerselet',
    '911 93': 'Brån',
    '911 94': 'Östanbäck',
    '912 31': 'Vilhelmina',
    '912 32': 'Vilhelmina',
    '912 33': 'Vilhelmina',
    '912 34': 'Vilhelmina',
    '912 90': 'Meselefors',
    '912 91': 'Skansholm',
    '912 92': 'Lövliden',
    '912 93': 'Vilhelmina',
    '912 94': 'Latikberg',
    '912 95': 'Storseleby',
    '912 97': 'Vilhelmina',
    '912 98': 'Klimpfjäll',
    '912 99': 'Vilhelmina',
    '913 31': 'Umeå',
    '913 32': 'Umeå',
    '913 33': 'Umeå',
    '913 34': 'Holmsund',
    '913 35': 'Umeå',
    '913 41': 'Nyvik',
    '913 42': 'Umeå',
    '914 31': 'Nordmaling',
    '914 32': 'Nordmaling',
    '914 33': 'Nordmaling',
    '914 41': 'Rundvik',
    '914 90': 'Brattfors',
    '914 91': 'Olofsfors',
    '914 92': 'Lögdeå',
    '914 93': 'Nordmaling',
    '914 94': 'Tallberg',
    '914 95': 'Gräsmyr',
    '914 96': 'Nordmaling',
    '914 97': 'Kronborg',
    '915 31': 'Robertsfors',
    '915 32': 'Robertsfors',
    '915 34': 'Ånäset',
    '915 91': 'Överklinten',
    '915 92': 'Robertsfors',
    '915 93': 'Sikeå',
    '915 94': 'Robertsfors',
    '915 95': 'Robertsfors',
    '915 96': 'Robertsfors',
    '915 97': 'Ratan',
    '915 98': 'Bygdeå',
    '916 31': 'Bjurholm',
    '916 91': 'Brännland',
    '916 92': 'Bjurholm',
    '916 93': 'Bastuträsk',
    '916 95': 'Vänjaurbäck',
    '917 02': 'Högland',
    '917 03': 'Risbäck',
    '917 31': 'Dorotea',
    '917 32': 'Dorotea',
    '917 91': 'Avaträsk',
    '917 93': 'Dorotea',
    '917 94': 'Lavsjö',
    '917 95': 'Svanabyn',
    '917 99': 'Dorotea',
    '918 03': 'Byviken',
    '918 31': 'Sävar',
    '918 32': 'Ostnäs',
    '918 91': 'Umeå',
    '918 92': 'Bullmark',
    '919 31': 'Åsele',
    '919 32': 'Åsele',
    '919 91': 'Lomsjö',
    '919 92': 'Åsele',
    '919 93': 'Fredrika',
    '919 94': 'Åsele',
    '919 95': 'Åsele',
    '920 34': 'Lycksele',
    '920 39': 'Malå',
    '920 40': 'Kristineberg',
    '920 41': 'Björksele',
    '920 42': 'Lycksele',
    '920 51': 'Åskilje',
    '920 64': 'Tärnaby',
    '920 66': 'Hemavan',
    '921 29': 'Hornmyr',
    '921 31': 'Lycksele',
    '921 32': 'Lycksele',
    '921 33': 'Lycksele',
    '921 34': 'Lycksele',
    '921 35': 'Lycksele',
    '921 36': 'Lycksele',
    '921 37': 'Lycksele',
    '921 41': 'Lycksele',
    '921 42': 'Lycksele',
    '921 45': 'Lycksele',
    '921 46': 'Lycksele',
    '921 91': 'Umgransele',
    '921 92': 'Lycksele',
    '921 93': 'Bålforsen',
    '921 94': 'Rusksele',
    '921 95': 'Norrbyberg',
    '921 99': 'Lycksele',
    '922 31': 'Vindeln',
    '922 32': 'Vindeln',
    '922 61': 'Umeå',
    '922 62': 'Tavelsjö',
    '922 63': 'Umeå',
    '922 66': 'Blomåker',
    '922 67': 'Sjömellankälen',
    '922 73': 'Hällnäs',
    '922 75': 'Åmsele',
    '922 76': 'Botsmark',
    '922 77': 'Umeå',
    '922 78': 'Forsliden',
    '922 91': 'Vindeln',
    '922 92': 'Degerås',
    '922 93': 'Sarsjöliden',
    '922 94': 'Tvärålund',
    '922 95': 'Granö',
    '923 31': 'Storuman',
    '923 32': 'Storuman',
    '923 41': 'Stensele',
    '923 94': 'Dikanäs',
    '923 95': 'Kittelfjäll',
    '923 96': 'Barsele',
    '923 97': 'Slussfors',
    '923 98': 'Skarvsjöby',
    '923 99': 'Ankarsund',
    '924 31': 'Sorsele',
    '924 32': 'Sorsele',
    '924 92': 'Blattnicksele',
    '924 93': 'Gargnäs',
    '924 94': 'Sorsele',
    '924 95': 'Ammarnäs',
    '930 27': 'Skellefteå',
    '930 61': 'Bastuträsk',
    '931 30': 'Skellefteå',
    '931 31': 'Centrum',
    '931 32': 'Skellefteå',
    '931 33': 'Skellefteå',
    '931 34': 'Älvsbacka',
    '931 35': 'Skellefteå',
    '931 36': 'Skellefteå',
    '931 37': 'Skellefteå',
    '931 38': 'Skellefteå',
    '931 39': 'Skellefteå',
    '931 40': 'Skellefteå',
    '931 41': 'Norrböle',
    '931 42': 'Skellefteå',
    '931 44': 'Prästbordet',
    '931 45': 'Skellefteå',
    '931 46': 'Sjungande Dalen',
    '931 48': 'Moröhöjden',
    '931 49': 'Morön',
    '931 50': 'Morö Backe',
    '931 51': 'Skellefteå',
    '931 52': 'Skellefteå',
    '931 53': 'Bergsbyn',
    '931 54': 'Skellefteå',
    '931 55': 'Skellefteå',
    '931 56': 'Skellefteå',
    '931 57': 'Anderstorp',
    '931 58': 'Skellefteå',
    '931 61': 'Skellefteå',
    '931 62': 'Sörböle',
    '931 63': 'Skellefteå',
    '931 64': 'Sunnanå',
    '931 65': 'Skellefteå',
    '931 70': 'Skellefteå',
    '931 76': 'Skellefteå',
    '931 77': 'Skellefteå',
    '931 91': 'Skellefteå',
    '931 92': 'Skellefteå',
    '931 93': 'Vallen',
    '931 94': 'Hjoggböle',
    '931 95': 'Skellefteå',
    '931 96': 'Skråmträsk',
    '931 97': 'Krångfors',
    '931 98': 'Medle',
    '931 99': 'Skellefteå',
    '932 31': 'Skellefteå',
    '932 32': 'Skelleftehamn',
    '932 33': 'Skellefteå',
    '932 34': 'Örviken',
    '932 35': 'Yttre Ursviken',
    '932 36': 'Ursviken',
    '932 37': 'Skellefteå',
    '932 51': 'Bureå',
    '932 52': 'Skellefteå',
    '932 61': 'Lövånger',
    '932 91': 'Backen',
    '932 93': 'Uttersjöbäcken',
    '932 94': 'Skellefteå',
    '934 31': 'Skellefteå',
    '934 32': 'Kåge',
    '934 41': 'Ersmark',
    '934 51': 'Byske',
    '934 52': 'Furuögrund',
    '934 61': 'Fällfors',
    '934 91': 'Ostvik',
    '934 92': 'Skellefteå',
    '934 93': 'Kusmark',
    '934 94': 'Skellefteå',
    '934 95': 'Norrlångträsk',
    '934 96': 'Drängsmark',
    '934 97': 'Åbyn',
    '934 98': 'Båtfors',
    '935 31': 'Norsjö',
    '935 32': 'Norsjö',
    '935 91': 'Norsjö',
    '935 92': 'Norsjö',
    '935 93': 'Norsjö',
    '936 31': 'Skellefteå',
    '936 32': 'Boliden',
    '936 51': 'Jörn',
    '936 91': 'Holmfors',
    '936 92': 'Skellefteå',
    '936 93': 'Norsjö',
    '936 94': 'Skellefteå',
    '936 95': 'Skellefteå',
    '937 31': 'Burträsk',
    '937 32': 'Skellefteå',
    '937 33': 'Bygdsiljum',
    '937 91': 'Åbyn',
    '937 92': 'Skellefteå',
    '937 93': 'Skellefteå',
    '937 94': 'Skellefteå',
    '937 95': 'Skellefteå',
    '937 96': 'Västanträsk',
    '939 31': 'Malå',
    '939 32': 'Malå',
    '939 91': 'Malå',
    '939 92': 'Adak',
    '939 93': 'Malå',
    '939 94': 'Malå',
    '939 95': 'Malå',
    '840 10': 'Ljungaverk',
    '840 12': 'Fränsta',
    '840 13': 'Grönsta',
    '840 24': 'Berg',
    '841 31': 'Ånge',
    '841 32': 'Ånge',
    '841 33': 'Ånge',
    '841 34': 'Ånge',
    '841 44': 'Alby',
    '841 91': 'Ovansjö',
    '841 92': 'Ånge',
    '841 93': 'Östavall',
    '841 94': 'Ånge',
    '841 96': 'Erikslund',
    '841 97': 'Ånge',
    '852 29': 'Södra Kajen',
    '852 30': 'Åkroken',
    '852 31': 'Sundsvall',
    '852 32': 'Stenstaden',
    '852 34': 'Norrmalm',
    '852 35': 'Södermalm',
    '852 36': 'Sundsvall',
    '852 37': 'Västermalm',
    '852 38': 'Sundsvall',
    '852 39': 'Fagerdal',
    '852 40': 'Sidsjöhöjden',
    '853 50': 'Sundsvall',
    '853 51': 'Sundsvall',
    '853 52': 'Nacksta',
    '853 53': 'Sallyhill',
    '853 56': 'Sundsvall',
    '853 57': 'Mårtensro',
    '853 58': 'Medskogsbron',
    '853 59': 'Sundsvall',
    '854 60': 'Östermalm',
    '854 61': 'Sundsvall',
    '854 62': 'Skönsmon',
    '854 63': 'Sundsvall',
    '854 66': 'Bredsand',
    '854 67': 'Stockvik',
    '854 68': 'Fläsian',
    '855 61': 'Kovland',
    '855 91': 'Selånger',
    '855 92': 'Sundsvall',
    '855 97': 'Indal',
    '855 98': 'Holm',
    '855 99': 'Liden',
    '856 30': 'Skönsberg',
    '856 31': 'Rosenlund',
    '856 32': 'Ortviken',
    '856 33': 'Heffners',
    '856 34': 'Petersvik',
    '856 35': 'Norra Kajen',
    '856 40': 'Sundsvall',
    '856 41': 'Norrliden',
    '856 42': 'Sundsvall',
    '856 43': 'Haga',
    '856 44': 'Sundsvall',
    '856 45': 'Nedre Haga',
    '856 50': 'Gärde',
    '856 51': 'Sundsvall',
    '856 52': 'Bosvedjan',
    '856 53': 'Bydalen',
    '857 30': 'Håkanstå',
    '857 31': 'Sundsvall',
    '857 32': 'Granloholm',
    '857 33': 'Sundsvall',
    '857 34': 'Sundsvall',
    '857 35': 'Sundsvall',
    '857 40': 'Granlohög',
    '857 41': 'Granlo',
    '857 42': 'Sundsvall',
    '857 50': 'Sundsvall',
    '857 51': 'Solbacken',
    '857 52': 'Bergsåker',
    '861 31': 'Timrå',
    '861 32': 'Vivsta',
    '861 33': 'Timrå',
    '861 34': 'Timrå',
    '861 35': 'Timrå',
    '861 36': 'Timrå',
    '861 38': 'Timrå',
    '861 39': 'Bergeforsen',
    '861 41': 'Timrå',
    '861 42': 'Sörberge',
    '861 43': 'Timrå',
    '861 45': 'Fagervik',
    '861 51': 'Söråker',
    '861 52': 'Timrå',
    '861 91': 'Laggarberg',
    '861 92': 'Stavreviken',
    '861 93': 'Ljustorp',
    '861 94': 'Timrå',
    '861 95': 'Västerå',
    '862 31': 'Kvissleby',
    '862 32': 'Nolby',
    '862 33': 'Hemmanet',
    '862 34': 'Essvik',
    '862 40': 'Njurundabommen',
    '862 41': 'Dingersjö',
    '862 91': 'Juniskär',
    '862 92': 'Sundsvall',
    '862 94': 'Sundsvall',
    '862 95': 'Maj',
    '862 96': 'Junibodsand',
    '863 31': 'Finsta',
    '863 32': 'Sundsvall',
    '863 33': 'Sundsbruk',
    '863 34': 'Ljustadalen',
    '863 35': 'Sundsvall',
    '863 36': 'Sundsvall',
    '863 37': 'Birsta',
    '863 41': 'Sundsvall',
    '863 42': 'Sundsvall',
    '864 31': 'Matfors',
    '864 32': 'Sundsvall',
    '864 33': 'Sundsvall',
    '864 41': 'Stöde',
    '864 91': 'Vattjom',
    '864 92': 'Lucksta',
    '864 95': 'Sundsvall',
    '864 96': 'Nedansjö',
    '865 31': 'Vi',
    '865 32': 'Sundsvall',
    '865 33': 'Sundsvall',
    '865 51': 'Ankarsvik',
    '865 91': 'Hovid',
    '865 92': 'Hartungviken',
    '870 10': 'Älandsbro',
    '870 11': 'Härnösand',
    '870 15': 'Utansjö',
    '870 16': 'Ramvik',
    '870 30': 'Bönhamn',
    '870 31': 'Norrfällsviken',
    '870 32': 'Ullånger',
    '870 33': 'Docksta',
    '870 52': 'Prästmon',
    '871 30': 'Norrstaden',
    '871 31': 'Östanbäcken',
    '871 32': 'Härnösand',
    '871 33': 'Sälsten',
    '871 40': 'Gådeåberget',
    '871 41': 'Gådeåstaden',
    '871 42': 'Baldershage',
    '871 45': 'Kronholmen',
    '871 50': 'Änget',
    '871 51': 'Murbergstaden',
    '871 52': 'Bondsjöstaden',
    '871 53': 'Bondsjöhöjden',
    '871 54': 'Saltvik',
    '871 60': 'Södra Brännan',
    '871 61': 'Geresta',
    '871 62': 'Eriksdal',
    '871 65': 'Stenhammar',
    '871 66': 'Gånsvik',
    '871 91': 'Antjärn',
    '871 92': 'Härnösand',
    '871 93': 'Härnösand',
    '871 94': 'Härnösand',
    '872 30': 'Kramfors',
    '872 31': 'Kramfors',
    '872 32': 'Kramfors',
    '872 33': 'Kramfors',
    '872 34': 'Kramfors',
    '872 35': 'Kramfors',
    '872 36': 'Kramfors',
    '872 43': 'Frånö',
    '872 63': 'Lunde',
    '872 64': 'Klockestrand',
    '872 75': 'Lugnvik',
    '872 91': 'Kramfors',
    '872 92': 'Kramfors',
    '872 93': 'Kramfors',
    '872 94': 'Kramfors',
    '872 95': 'Kramfors',
    '872 96': 'Bjärtrå',
    '872 97': 'Herrskog',
    '872 98': 'Nora',
    '873 30': 'Bollstabruk',
    '873 40': 'Kramfors',
    '873 50': 'Sandviken',
    '873 91': 'Kramfors',
    '873 92': 'Kramfors',
    '880 30': 'Näsåker',
    '880 31': 'Gåsnäs',
    '880 37': 'Junsele',
    '880 40': 'Ramsele',
    '880 41': 'Edsele',
    '881 30': 'Sollefteå',
    '881 31': 'Sollefteå',
    '881 32': 'Sollefteå',
    '881 33': 'Skärvsta',
    '881 34': 'Sollefteå',
    '881 35': 'Sollefteå',
    '881 40': 'Sollefteå',
    '881 41': 'Sollefteå',
    '881 50': 'Sollefteå',
    '881 51': 'Sollefteå',
    '881 52': 'Sollefteå',
    '881 60': 'Sollefteå',
    '881 91': 'Österås',
    '881 92': 'Sollefteå',
    '881 93': 'Sollefteå',
    '881 94': 'Sollefteå',
    '881 95': 'Sollefteå',
    '882 30': 'Långsele',
    '882 40': 'Sollefteå',
    '882 50': 'Sollefteå',
    '882 91': 'Österforse',
    '882 93': 'Sollefteå',
    '882 95': 'Björknäset',
    '890 50': 'Björna',
    '890 51': 'Långviksmon',
    '890 54': 'Trehörningsjö',
    '891 30': 'Örnsköldsvik',
    '891 31': 'Örnsköldsvik',
    '891 32': 'Örnsköldsvik',
    '891 33': 'Örnsköldsvik',
    '891 34': 'Örnsköldsvik',
    '891 35': 'Örnsköldsvik',
    '891 36': 'Örnsköldsvik',
    '891 37': 'Valhalla',
    '891 38': 'Örnsköldsvik',
    '891 39': 'Örnsköldsvik',
    '891 40': 'Gimåt',
    '891 41': 'Örnsköldsvik',
    '891 42': 'Kroksta',
    '891 43': 'Örnsköldsvik',
    '891 50': 'Örnsköldsvik',
    '891 51': 'Svartby',
    '891 55': 'Arnäsvall',
    '891 60': 'Salmostaden',
    '891 61': 'Örnsköldsvik',
    '891 62': 'Örnsköldsvik',
    '891 77': 'Järved',
    '891 78': 'Örnsköldsvik',
    '891 90': 'Örnsköldsvik',
    '891 92': 'Örnsköldsvik',
    '891 95': 'Örnsköldsvik',
    '891 96': 'Hornön',
    '892 30': 'Gene',
    '892 31': 'Örnsköldsvik',
    '892 32': 'Sund',
    '892 33': 'Svedjeholmen',
    '892 34': 'Örnsköldsvik',
    '892 40': 'Örnsköldsvik',
    '892 41': 'Skidsta',
    '892 42': 'Domsjö',
    '892 43': 'Örnsköldsvik',
    '892 50': 'Örnsköldsvik',
    '892 51': 'Hörnett',
    '892 92': 'Nötbolandet',
    '893 01': 'Trysunda',
    '893 30': 'Bjästa',
    '893 31': 'Örnsköldsvik',
    '893 40': 'Köpmanholmen',
    '893 91': 'Örnsköldsvik',
    '893 92': 'Örnsköldsvik',
    '893 95': 'Sidensjö',
    '893 96': 'Sidensjö',
    '893 99': 'Örnsköldsvik',
    '894 30': 'Översjäla',
    '894 31': 'Själevad',
    '894 35': 'Örnsköldsvik',
    '894 40': 'Örnsköldsvik',
    '894 41': 'Örnsköldsvik',
    '894 91': 'Billsta',
    '894 93': 'Örnsköldsvik',
    '894 95': 'Moliden',
    '895 30': 'Bredbyn',
    '895 40': 'Mellansel',
    '895 91': 'Örnsköldsvik',
    '895 92': 'Örnsköldsvik',
    '895 93': 'Kubbe',
    '895 95': 'Gottne',
    '895 97': 'Skorped',
    '895 98': 'Solberg',
    '895 99': 'Örnsköldsvik',
    '896 31': 'Husum',
    '896 32': 'Örnsköldsvik',
    '896 91': 'Örnsköldsvik',
    '896 93': 'Gideå',
    '635 32': 'Kvicksund',
    '721 30': 'Ängsgärdet',
    '721 31': 'Västerås',
    '721 32': 'Hacksta',
    '721 33': 'Västerås',
    '721 34': 'Hälla',
    '721 35': 'Västerås',
    '721 36': 'Finnslätten',
    '721 37': 'Tunbytorp',
    '721 38': 'Erikslund',
    '722 09': 'Västerås',
    '722 10': 'Västerås',
    '722 11': 'Västerås',
    '722 12': 'Västerås',
    '722 13': 'Västerås',
    '722 14': 'Östermalm',
    '722 15': 'Kyrkbacken',
    '722 16': 'Västerås',
    '722 17': 'Aroslund',
    '722 18': 'Norrmalm',
    '722 19': 'Karlsdal',
    '722 20': 'Kristiansborg',
    '722 21': 'Västerås',
    '722 22': 'Skallberget',
    '722 23': 'Vega',
    '722 24': 'Västerås',
    '722 25': 'Gideonsberg',
    '722 26': 'Teknikbyn Tegnér',
    '722 27': 'Västerås',
    '722 28': 'Västerås',
    '722 31': 'Hökåsen',
    '722 33': 'Tillberga',
    '722 40': 'Västerås',
    '722 41': 'Rönnby',
    '722 42': 'Västerås',
    '722 43': 'Södra Gryta',
    '722 44': 'Önsta',
    '722 45': 'Västerås',
    '722 46': 'Norra Gryta',
    '723 34': 'Korsängsgärdet',
    '723 35': 'Freja',
    '723 36': 'Sandgärdet',
    '723 37': 'Malmaberg',
    '723 38': 'Västerås',
    '723 39': 'Västerås',
    '723 40': 'Norra Malmaberg',
    '723 41': 'Skiljebo',
    '723 42': 'Västerås',
    '723 43': 'Östra Malmaberg',
    '723 44': 'Västerås',
    '723 45': 'Västerås',
    '723 46': 'Västerås',
    '723 47': 'Viksäng',
    '723 48': 'Berghamra',
    '723 49': 'Hamre',
    '723 50': 'Talltorp',
    '723 51': 'Brandthovda',
    '723 52': 'Västerås',
    '723 53': 'Bjurhovda',
    '723 55': 'Irsta',
    '723 56': 'Västerås',
    '724 60': 'Vetterstorp',
    '724 61': 'Vasastaden',
    '724 62': 'Jakobsberg',
    '724 63': 'Västerås',
    '724 64': 'Pettersberg',
    '724 65': 'Stohagen',
    '724 66': 'Hammarby',
    '724 67': 'Västerås',
    '724 68': 'Västerås',
    '724 69': 'Västerås',
    '724 70': 'Västerås',
    '724 71': 'Råby',
    '724 72': 'Västerås',
    '724 73': 'Västerås',
    '724 74': 'Västerås',
    '724 75': 'Dingtuna',
    '724 76': 'Skälby',
    '724 77': 'Västerås',
    '724 78': 'Västerås',
    '724 79': 'Bäckby',
    '724 80': 'Vallby',
    '724 81': 'Norra Vallby',
    '724 82': 'Brottberga',
    '725 90': 'Tidö-Lindö',
    '725 91': 'Enhagen-Ekbacken',
    '725 92': 'Västerås',
    '725 93': 'Västerås',
    '725 94': 'Munga',
    '725 95': 'Sevalla',
    '725 96': 'Tortuna',
    '725 97': 'Herrgårdsängen',
    '725 98': 'Västerås',
    '726 30': 'Skultuna',
    '726 31': 'Västerås',
    '726 91': 'Västerås',
    '726 92': 'Svanå',
    '730 60': 'Ramnäs',
    '730 61': 'Virsbo',
    '730 91': 'Riddarhyttan',
    '731 10': 'Kolsva',
    '731 11': 'Köping',
    '731 12': 'Köping',
    '731 13': 'Köping',
    '731 14': 'Köping',
    '731 15': 'Skinnskatteberg',
    '731 30': 'Köping',
    '731 31': 'Köping',
    '731 32': 'Köping',
    '731 33': 'Köping',
    '731 34': 'Köping',
    '731 35': 'Köping',
    '731 36': 'Köping',
    '731 37': 'Malmön',
    '731 40': 'Köping',
    '731 41': 'Köping',
    '731 42': 'Köping',
    '731 43': 'Köping',
    '731 50': 'Köping',
    '731 51': 'Köping',
    '731 52': 'Köping',
    '731 53': 'Köping',
    '731 54': 'Köping',
    '731 60': 'Valskog',
    '731 70': 'Munktorp',
    '731 91': 'Köping',
    '731 92': 'Köping',
    '731 93': 'Odensvi',
    '731 94': 'Köping',
    '731 95': 'Köping',
    '731 96': 'Sundänge',
    '731 97': 'Kungsör',
    '731 98': 'Köping',
    '732 30': 'Arboga',
    '732 31': 'Arboga',
    '732 32': 'Arboga',
    '732 33': 'Arboga',
    '732 34': 'Arboga',
    '732 45': 'Arboga',
    '732 46': 'Arboga',
    '732 47': 'Arboga',
    '732 48': 'Arboga',
    '732 49': 'Arboga',
    '732 50': 'Arboga',
    '732 91': 'Arboga',
    '732 92': 'Tyringe',
    '732 94': 'Arboga',
    '732 95': 'Arboga',
    '732 96': 'Medåker',
    '732 97': 'Götlunda',
    '732 98': 'Arboga',
    '733 30': 'Sala',
    '733 31': 'Sala',
    '733 32': 'Sala',
    '733 33': 'Sala',
    '733 34': 'Sala',
    '733 35': 'Sala',
    '733 36': 'Sala',
    '733 37': 'Sala',
    '733 38': 'Sala',
    '733 39': 'Sala',
    '733 40': 'Sala',
    '733 60': 'Västerfärnebo',
    '733 61': 'Sala',
    '733 62': 'Rosshyttan',
    '733 63': 'Fläckebo',
    '733 73': 'Ransta',
    '733 75': 'Möklinta',
    '733 91': 'Saladamm',
    '733 92': 'Varmsätra',
    '733 93': 'Kumla kyrkby',
    '733 94': 'Kila',
    '733 95': 'Sätra brunn',
    '733 96': 'Sala',
    '733 97': 'Broddbo',
    '733 98': 'Sala',
    '733 99': 'Sala',
    '734 30': 'Hallstahammar',
    '734 31': 'Hallstahammar',
    '734 32': 'Hallstahammar',
    '734 33': 'Hallstahammar',
    '734 34': 'Hallstahammar',
    '734 35': 'Hallstahammar',
    '734 36': 'Hallstahammar',
    '734 37': 'Hallstahammar',
    '734 38': 'Hallstahammar',
    '734 39': 'Hallstahammar',
    '734 40': 'Hallstahammar',
    '734 50': 'Hallstahammar',
    '734 51': 'Sörstafors',
    '734 91': 'Hallstahammar',
    '734 92': 'Hallstahammar',
    '734 93': 'Hallstahammar',
    '734 94': 'Strömsholm',
    '735 31': 'Surahammar',
    '735 32': 'Surahammar',
    '735 33': 'Surahammar',
    '735 34': 'Surahammar',
    '735 35': 'Surahammar',
    '735 36': 'Surahammar',
    '735 37': 'Surahammar',
    '735 38': 'Surahammar',
    '735 91': 'Surahammar',
    '736 30': 'Kungsör',
    '736 31': 'Kungsör',
    '736 32': 'Kungsör',
    '736 33': 'Kungsör',
    '736 34': 'Kungsör',
    '736 35': 'Kungsör',
    '736 36': 'Kungsör',
    '736 91': 'Kungsör',
    '736 92': 'Kungsör',
    '736 93': 'Kungsör',
    '737 30': 'Fagersta',
    '737 31': 'Fagersta',
    '737 32': 'Fagersta',
    '737 33': 'Fagersta',
    '737 34': 'Fagersta',
    '737 40': 'Fagersta',
    '737 41': 'Fagersta',
    '737 42': 'Fagersta',
    '737 43': 'Fagersta',
    '737 44': 'Fagersta',
    '737 45': 'Fagersta',
    '737 46': 'Fagersta',
    '737 47': 'Fagersta',
    '737 48': 'Fagersta',
    '737 49': 'Fagersta',
    '737 90': 'Ängelsberg',
    '737 91': 'Fagersta',
    '737 92': 'Fagersta',
    '738 30': 'Norberg',
    '738 31': 'Norberg',
    '738 32': 'Norberg',
    '738 33': 'Norberg',
    '738 34': 'Norberg',
    '738 35': 'Norberg',
    '738 50': 'Kärrgruvan',
    '738 91': 'Norberg',
    '738 92': 'Norberg',
    '739 30': 'Skinnskatteberg',
    '739 31': 'Skinnskatteberg',
    '739 70': 'Färna',
    '739 91': 'Skinnskatteberg',
    '739 92': 'Skinnskatteberg',
    '774 93': 'Norberg',
    '411 01': 'Stampen',
    '411 02': 'Göteborg',
    '411 03': 'Göteborg',
    '411 04': 'Gullbergsvass',
    '411 05': 'Nordstaden',
    '411 06': 'Göteborg',
    '411 07': 'Göteborg',
    '411 08': 'Göteborg',
    '411 09': 'Göteborg',
    '411 10': 'Göteborg',
    '411 11': 'Göteborg',
    '411 12': 'Göteborg',
    '411 13': 'Göteborg',
    '411 14': 'Göteborg',
    '411 15': 'Inom Vallgraven',
    '411 16': 'Göteborg',
    '411 17': 'Göteborg',
    '411 18': 'Göteborg',
    '411 19': 'Göteborg',
    '411 20': 'Göteborg',
    '411 21': 'Göteborg',
    '411 22': 'Göteborg',
    '411 23': 'Göteborg',
    '411 24': 'Göteborg',
    '411 25': 'Göteborg',
    '411 26': 'Göteborg',
    '411 27': 'Göteborg',
    '411 28': 'Göteborg',
    '411 29': 'Göteborg',
    '411 30': 'Göteborg',
    '411 31': 'Landala',
    '411 32': 'Göteborg',
    '411 33': 'Göteborg',
    '411 34': 'Lorensberg',
    '411 35': 'Göteborg',
    '411 36': 'Göteborg',
    '411 37': 'Göteborg',
    '411 38': 'Göteborg',
    '411 39': 'Heden',
    '411 40': 'Göteborg',
    '411 43': 'Göteborg',
    '412 49': 'Torp',
    '412 50': 'Göteborg',
    '412 51': 'Göteborg',
    '412 52': 'Göteborg',
    '412 53': 'Göteborg',
    '412 54': 'Göteborg',
    '412 55': 'Göteborg',
    '412 56': 'Göteborg',
    '412 57': 'Göteborg',
    '412 58': 'Göteborg',
    '412 59': 'Göteborg',
    '412 60': 'Göteborg',
    '412 61': 'Göteborg',
    '412 62': 'Krokslätt',
    '412 63': 'Göteborg',
    '412 64': 'Göteborg',
    '412 65': 'Göteborg',
    '412 66': 'Örgryte',
    '412 67': 'Göteborg',
    '412 68': 'Göteborg',
    '412 69': 'Göteborg',
    '412 70': 'Göteborg',
    '412 71': 'Göteborg',
    '412 72': 'Göteborg',
    '412 73': 'Göteborg',
    '412 74': 'Göteborg',
    '412 75': 'Göteborg',
    '412 76': 'Kallebäck',
    '412 79': 'Göteborg',
    '412 80': 'Göteborg',
    '412 81': 'Johanneberg',
    '412 82': 'Göteborg',
    '412 85': 'Göteborg',
    '413 01': 'Pustervik',
    '413 02': 'Haga',
    '413 03': 'Göteborg',
    '413 04': 'Göteborg',
    '413 05': 'Göteborg',
    '413 06': 'Göteborg',
    '413 07': 'Göteborg',
    '413 08': 'Göteborg',
    '413 09': 'Olivedal',
    '413 10': 'Göteborg',
    '413 11': 'Göteborg',
    '413 12': 'Göteborg',
    '413 13': 'Annedal',
    '413 14': 'Kommendantsängen',
    '413 15': 'Göteborg',
    '413 16': 'Göteborg',
    '413 17': 'Göteborg',
    '413 18': 'Masthugget',
    '413 19': 'Änggården',
    '413 20': 'Göteborg',
    '413 21': 'Göteborg',
    '413 22': 'Göteborg',
    '413 23': 'Guldheden',
    '413 24': 'Göteborg',
    '413 25': 'Göteborg',
    '413 26': 'Göteborg',
    '413 27': 'Göteborg',
    '413 28': 'Göteborg',
    '413 46': 'Göteborg',
    '413 90': 'Göteborg',
    '414 51': 'Göteborg',
    '414 52': 'Göteborg',
    '414 53': 'Göteborg',
    '414 54': 'Göteborg',
    '414 55': 'Göteborg',
    '414 56': 'Göteborg',
    '414 57': 'Majorna',
    '414 58': 'Göteborg',
    '414 59': 'Göteborg',
    '414 60': 'Göteborg',
    '414 61': 'Göteborg',
    '414 62': 'Göteborg',
    '414 63': 'Göteborg',
    '414 64': 'Göteborg',
    '414 65': 'Göteborg',
    '414 66': 'Göteborg',
    '414 67': 'Göteborg',
    '414 68': 'Göteborg',
    '414 69': 'Göteborg',
    '414 70': 'Göteborg',
    '414 71': 'Göteborg',
    '414 72': 'Göteborg',
    '414 73': 'Göteborg',
    '414 74': 'Göteborg',
    '414 75': 'Göteborg',
    '414 76': 'Kungsladugård',
    '414 77': 'Göteborg',
    '414 78': 'Göteborg',
    '414 79': 'Högsbotorp',
    '414 80': 'Göteborg',
    '414 81': 'Göteborg',
    '414 82': 'Göteborg',
    '414 83': 'Högsbohöjd',
    '415 02': 'Göteborg',
    '415 05': 'Göteborg',
    '415 11': 'Göteborg',
    '415 12': 'Göteborg',
    '415 13': 'Gamlestaden',
    '415 14': 'Göteborg',
    '415 15': 'Göteborg',
    '415 16': 'Göteborg',
    '415 17': 'Göteborg',
    '415 22': 'Göteborg',
    '415 23': 'Göteborg',
    '415 24': 'Göteborg',
    '415 25': 'Göteborg',
    '415 26': 'Göteborg',
    '415 27': 'Göteborg',
    '415 28': 'Kviberg',
    '415 31': 'Göteborg',
    '415 32': 'Göteborg',
    '415 33': 'Göteborg',
    '415 34': 'Kortedala',
    '415 35': 'Göteborg',
    '415 36': 'Göteborg',
    '415 37': 'Göteborg',
    '415 38': 'Göteborg',
    '415 41': 'Göteborg',
    '415 42': 'Göteborg',
    '415 43': 'Göteborg',
    '415 44': 'Göteborg',
    '415 45': 'Göteborg',
    '415 46': 'Göteborg',
    '415 47': 'Göteborg',
    '415 48': 'Göteborg',
    '415 51': 'Bergsjön',
    '415 52': 'Göteborg',
    '415 53': 'Göteborg',
    '415 54': 'Göteborg',
    '415 55': 'Göteborg',
    '415 56': 'Göteborg',
    '415 57': 'Göteborg',
    '415 61': 'Göteborg',
    '415 62': 'Göteborg',
    '415 63': 'Göteborg',
    '415 64': 'Göteborg',
    '415 65': 'Göteborg',
    '415 66': 'Göteborg',
    '415 67': 'Göteborg',
    '415 68': 'Göteborg',
    '415 71': 'Göteborg',
    '415 72': 'Utby',
    '415 73': 'Göteborg',
    '415 74': 'Göteborg',
    '416 47': 'Göteborg',
    '416 48': 'Göteborg',
    '416 49': 'Göteborg',
    '416 50': 'Göteborg',
    '416 51': 'Göteborg',
    '416 52': 'Kålltorp',
    '416 53': 'Göteborg',
    '416 54': 'Göteborg',
    '416 55': 'Skatås',
    '416 56': 'Göteborg',
    '416 57': 'Göteborg',
    '416 58': 'Göteborg',
    '416 59': 'Lunden',
    '416 60': 'Göteborg',
    '416 61': 'Göteborg',
    '416 62': 'Göteborg',
    '416 63': 'Göteborg',
    '416 64': 'Gårda',
    '416 65': 'Göteborg',
    '416 66': 'Olskroken',
    '416 67': 'Göteborg',
    '416 68': 'Göteborg',
    '416 69': 'Göteborg',
    '416 70': 'Göteborg',
    '416 71': 'Göteborg',
    '416 72': 'Göteborg',
    '416 73': 'Göteborg',
    '416 74': 'Göteborg',
    '416 75': 'Göteborg',
    '416 76': 'Göteborg',
    '416 77': 'Göteborg',
    '416 78': 'Göteborg',
    '416 79': 'Göteborg',
    '416 80': 'Göteborg',
    '416 81': 'Göteborg',
    '417 01': 'Göteborg',
    '417 02': 'Göteborg',
    '417 03': 'Göteborg',
    '417 04': 'Göteborg',
    '417 05': 'Göteborg',
    '417 06': 'Göteborg',
    '417 07': 'Göteborg',
    '417 08': 'Göteborg',
    '417 13': 'Göteborg',
    '417 14': 'Göteborg',
    '417 15': 'Göteborg',
    '417 16': 'Göteborg',
    '417 17': 'Göteborg',
    '417 18': 'Göteborg',
    '417 19': 'Göteborg',
    '417 20': 'Göteborg',
    '417 21': 'Göteborg',
    '417 22': 'Göteborg',
    '417 23': 'Göteborg',
    '417 24': 'Göteborg',
    '417 26': 'Göteborg',
    '417 27': 'Göteborg',
    '417 28': 'Tolered',
    '417 29': 'Göteborg',
    '417 42': 'Göteborg',
    '417 43': 'Göteborg',
    '417 44': 'Tuve',
    '417 45': 'Göteborg',
    '417 46': 'Göteborg',
    '417 47': 'Göteborg',
    '417 48': 'Göteborg',
    '417 49': 'Göteborg',
    '417 53': 'Göteborg',
    '417 55': 'Göteborg',
    '417 56': 'Göteborg',
    '417 57': 'Lindholmen',
    '417 58': 'Göteborg',
    '417 60': 'Sannegården',
    '417 61': 'Göteborg',
    '417 62': 'Eriksberg',
    '417 63': 'Göteborg',
    '417 64': 'Göteborg',
    '417 65': 'Göteborg',
    '417 66': 'Göteborg',
    '417 67': 'Göteborg',
    '418 30': 'Göteborg',
    '418 31': 'Göteborg',
    '418 32': 'Göteborg',
    '418 33': 'Göteborg',
    '418 34': 'Göteborg',
    '418 35': 'Göteborg',
    '418 36': 'Göteborg',
    '418 37': 'Biskopsgården',
    '418 38': 'Göteborg',
    '418 39': 'Göteborg',
    '418 40': 'Göteborg',
    '418 41': 'Göteborg',
    '418 42': 'Göteborg',
    '418 43': 'Göteborg',
    '418 70': 'Göteborg',
    '418 71': 'Göteborg',
    '418 72': 'Göteborg',
    '418 73': 'Göteborg',
    '418 74': 'Göteborg',
    '418 75': 'Göteborg',
    '418 76': 'Göteborg',
    '418 77': 'Bräcke',
    '418 78': 'Göteborg',
    '418 79': 'Göteborg',
    '421 30': 'Göteborg',
    '421 31': 'Göteborg',
    '421 32': 'Göteborg',
    '421 33': 'Göteborg',
    '421 34': 'Järnbrott',
    '421 35': 'Göteborg',
    '421 37': 'Flatås',
    '421 38': 'Kaverös',
    '421 39': 'Ruddalen',
    '421 40': 'Göteborg',
    '421 41': 'Göteborg',
    '421 42': 'Göteborg',
    '421 43': 'Göteborg',
    '421 44': 'Göteborg',
    '421 45': 'Göteborg',
    '421 46': 'Göteborg',
    '421 47': 'Göteborg',
    '421 48': 'Göteborg',
    '421 49': 'Göteborg',
    '421 50': 'Göteborg',
    '421 51': 'Västra Frölunda',
    '421 52': 'Göteborg',
    '421 53': 'Tynnered',
    '421 57': 'Önnered',
    '421 58': 'Göteborg',
    '421 59': 'Göteborg',
    '421 60': 'Göteborg',
    '421 61': 'Göteborg',
    '421 62': 'Göteborg',
    '421 63': 'Göteborg',
    '421 65': 'Åkered',
    '421 66': 'Näset',
    '421 67': 'Göteborg',
    '421 70': 'Göteborg',
    '421 71': 'Göteborg',
    '421 72': 'Göteborg',
    '421 73': 'Göteborg',
    '421 74': 'Göteborg',
    '422 41': 'Brunnsbo',
    '422 42': 'Göteborg',
    '422 43': 'Göteborg',
    '422 44': 'Göteborg',
    '422 45': 'Göteborg',
    '422 46': 'Skräppekärr',
    '422 47': 'Göteborg',
    '422 48': 'Göteborg',
    '422 49': 'Göteborg',
    '422 50': 'Göteborg',
    '422 51': 'Göteborg',
    '422 52': 'Göteborg',
    '422 53': 'Göteborg',
    '422 54': 'Göteborg',
    '422 55': 'Backa',
    '422 56': 'Göteborg',
    '422 57': 'Backatorp',
    '422 58': 'Göteborg',
    '422 59': 'Göteborg',
    '423 32': 'Torslanda',
    '423 33': 'Göteborg',
    '423 34': 'Göteborg',
    '423 36': 'Göteborg',
    '423 37': 'Göteborg',
    '423 38': 'Andalen',
    '423 39': 'Hällsvik',
    '423 40': 'Hjuvik',
    '423 41': 'Dalen',
    '423 42': 'Gossbydal',
    '423 43': 'Tumlehed',
    '423 46': 'Göteborg',
    '423 47': 'Göteborg',
    '423 49': 'Göteborg',
    '423 50': 'Göteborg',
    '423 51': 'Göteborg',
    '423 52': 'Västra Låssby',
    '423 53': 'Göteborg',
    '423 54': 'Göteborg',
    '423 55': 'Kvisljungeby',
    '423 56': 'Låssby',
    '423 59': 'Trulsegården',
    '423 61': 'Björlanda',
    '423 63': 'Nolvik',
    '423 70': 'Säve',
    '423 71': 'Göteborg',
    '423 72': 'Göteborg',
    '423 73': 'Göteborg',
    '424 31': 'Göteborg',
    '424 32': 'Hjällbo',
    '424 33': 'Göteborg',
    '424 34': 'Eriksbo',
    '424 35': 'Göteborg',
    '424 36': 'Göteborg',
    '424 37': 'Hammarkullen',
    '424 38': 'Göteborg',
    '424 39': 'Göteborg',
    '424 40': 'Göteborg',
    '424 41': 'Göteborg',
    '424 42': 'Gårdsten',
    '424 43': 'Göteborg',
    '424 44': 'Göteborg',
    '424 45': 'Lövgärdet',
    '424 46': 'Göteborg',
    '424 47': 'Göteborg',
    '424 48': 'Göteborg',
    '424 49': 'Rannebergen',
    '424 50': 'Göteborg',
    '424 53': 'Göteborg',
    '424 54': 'Göteborg',
    '424 55': 'Linnarhult',
    '424 56': 'Gunnilse',
    '424 57': 'Göteborg',
    '424 61': 'Göteborg',
    '424 65': 'Angered',
    '424 66': 'Göteborg',
    '424 67': 'Göteborg',
    '424 68': 'Göteborg',
    '424 69': 'Göteborg',
    '424 70': 'Olofstorp',
    '424 71': 'Göteborg',
    '424 72': 'Göteborg',
    '424 90': 'Göteborg',
    '424 91': 'Göteborg',
    '425 30': 'Göteborg',
    '425 31': 'Kärra',
    '425 32': 'Göteborg',
    '425 33': 'Göteborg',
    '425 34': 'Göteborg',
    '425 35': 'Göteborg',
    '425 36': 'Göteborg',
    '425 37': 'Göteborg',
    '425 38': 'Göteborg',
    '425 39': 'Göteborg',
    '425 41': 'Göteborg',
    '425 42': 'Göteborg',
    '425 43': 'Göteborg',
    '425 65': 'Mysterna',
    '426 50': 'Göteborg',
    '426 51': 'Göteborg',
    '426 52': 'Göteborg',
    '426 53': 'Göteborg',
    '426 54': 'Göteborg',
    '426 55': 'Göteborg',
    '426 56': 'Bratthammar',
    '426 58': 'Fiskebäck',
    '426 59': 'Göteborg',
    '426 68': 'Göteborg',
    '426 69': 'Göteborg',
    '426 70': 'Göteborg',
    '426 71': 'Nya Varvet',
    '426 72': 'Göteborg',
    '426 73': 'Göteborg',
    '426 74': 'Göteborg',
    '426 76': 'Göteborg',
    '426 77': 'Påvelund',
    '426 79': 'Göteborg',
    '427 34': 'Göteborg',
    '427 35': 'Göteborg',
    '427 36': 'Göteborg',
    '427 37': 'Göteborg',
    '427 38': 'Billdal',
    '427 39': 'Göteborg',
    '427 40': 'Göteborg',
    '428 30': 'Mölndal',
    '428 31': 'Mölndal',
    '428 32': 'Kållered',
    '428 33': 'Mölndal',
    '428 34': 'Tulebo',
    '428 35': 'Mölndal',
    '428 36': 'Mölndal',
    '428 37': 'Mölndal',
    '430 80': 'Asperö',
    '430 81': 'Göteborg',
    '430 82': 'Donsö',
    '430 83': 'Vrångö',
    '430 84': 'Styrsö',
    '430 85': 'Brännö',
    '431 30': 'Mölndal',
    '431 31': 'Mölndal',
    '431 32': 'Åby',
    '431 33': 'Mölndal',
    '431 34': 'Glasberget',
    '431 35': 'Mölndal',
    '431 36': 'Gunnebo',
    '431 37': 'Mölndal',
    '431 38': 'Mölndal',
    '431 39': 'Mölndal',
    '431 40': 'Mölndal',
    '431 41': 'Mölndal',
    '431 42': 'Bosgården',
    '431 43': 'Mölndal',
    '431 44': 'Solängen',
    '431 45': 'Mölndal',
    '431 46': 'Mölndal',
    '431 47': 'Mölndal',
    '431 48': 'Mölndal',
    '431 49': 'Eklanda',
    '431 50': 'Mölndal',
    '431 51': 'Mölndal',
    '431 53': 'Mölndal',
    '431 59': 'Mölndal',
    '431 60': 'Mölndal',
    '431 61': 'Mölndal',
    '431 62': 'Mölndal',
    '431 63': 'Mölndal',
    '431 64': 'Kikås',
    '431 66': 'Lackarebäck',
    '431 67': 'Mölndal',
    '431 68': 'Mölndal',
    '431 69': 'Mölndal',
    '431 90': 'Mölndal',
    '433 30': 'Partille',
    '433 31': 'Partille',
    '433 32': 'Partille',
    '433 33': 'Partille',
    '433 34': 'Partille',
    '433 35': 'Partille',
    '433 36': 'Partille',
    '433 37': 'Partille',
    '433 38': 'Partille',
    '433 39': 'Partille',
    '433 41': 'Partille',
    '433 42': 'Partille',
    '433 43': 'Partille',
    '433 44': 'Partille',
    '433 45': 'Partille',
    '433 46': 'Partille',
    '433 47': 'Partille',
    '433 48': 'Partille',
    '433 49': 'Partille',
    '433 50': 'Öjersjö',
    '433 51': 'Partille',
    '433 52': 'Partille',
    '433 53': 'Partille',
    '433 60': 'Partille',
    '433 61': 'Sävedalen',
    '433 62': 'Partille',
    '433 63': 'Partille',
    '433 64': 'Partille',
    '433 65': 'Partille',
    '433 66': 'Partille',
    '433 67': 'Partille',
    '433 68': 'Partille',
    '433 69': 'Partille',
    '433 70': 'Partille',
    '433 75': 'Jonsered',
    '433 76': 'Kåhög',
    '435 30': 'Mölnlycke',
    '435 31': 'Härryda',
    '435 32': 'Härryda',
    '435 33': 'Härryda',
    '435 35': 'Härryda',
    '435 37': 'Härryda',
    '435 38': 'Härryda',
    '435 39': 'Benareby',
    '435 40': 'Härryda',
    '435 41': 'Härryda',
    '435 42': 'Härryda',
    '435 43': 'Pixbo',
    '435 44': 'Härryda',
    '436 32': 'Göteborg',
    '436 33': 'Göteborg',
    '436 34': 'Göteborg',
    '436 35': 'Göteborg',
    '436 36': 'Göteborg',
    '436 37': 'Göteborg',
    '436 38': 'Göteborg',
    '436 39': 'Göteborg',
    '436 40': 'Askim',
    '436 42': 'Göteborg',
    '436 43': 'Göteborg',
    '436 44': 'Göteborg',
    '436 45': 'Göteborg',
    '436 50': 'Hovås',
    '436 51': 'Göteborg',
    '436 52': 'Göteborg',
    '436 53': 'Göteborg',
    '436 54': 'Göteborg',
    '436 55': 'Göteborg',
    '436 56': 'Göteborg',
    '436 58': 'Göteborg',
    '437 30': 'Mölndal',
    '437 31': 'Mölndal',
    '437 32': 'Lindome',
    '437 33': 'Mölndal',
    '437 34': 'Mölndal',
    '437 35': 'Mölndal',
    '437 36': 'Mölndal',
    '437 38': 'Mölndal',
    '437 40': 'Mölndal',
    '437 41': 'Mölndal',
    '437 42': 'Mölndal',
    '437 91': 'Mölndal',
    '437 92': 'Hällesåker',
    '437 93': 'Mölndal',
    '438 32': 'Landvetter',
    '438 33': 'Härryda',
    '438 34': 'Härryda',
    '438 35': 'Härryda',
    '438 36': 'Härryda',
    '438 38': 'Tahult',
    '438 51': 'Rävlanda',
    '438 52': 'Härryda',
    '438 53': 'Hindås',
    '438 54': 'Grönhult',
    '438 70': 'Härryda',
    '438 91': 'Härryda',
    '438 92': 'Härryda',
    '438 93': 'Eskilsby',
    '438 94': 'Stora Bugärde',
    '438 95': 'Härryda',
    '438 96': 'Hällingsjö',
    '438 97': 'Härryda',
    '438 98': 'Bollebygd',
    '441 30': 'Alingsås',
    '441 31': 'Alingsås',
    '441 32': 'Alingsås',
    '441 33': 'Sörhaga',
    '441 34': 'Alingsås',
    '441 35': 'Nolby',
    '441 36': 'Östlyckan',
    '441 37': 'Brogården',
    '441 38': 'Alingsås',
    '441 39': 'Stampen',
    '441 40': 'Torvmossen',
    '441 41': 'Mariedal',
    '441 42': 'Alingsås',
    '441 43': 'Kullingsberg',
    '441 44': 'Stadsskogen',
    '441 45': 'Hedvigsberg',
    '441 46': 'Alingsås',
    '441 50': 'Stockslycke',
    '441 51': 'Ängabo',
    '441 55': 'Noltorp',
    '441 56': 'Tegelbruket',
    '441 57': 'Dammtorpet',
    '441 58': 'Alingsås',
    '441 60': 'Västra Bodarna',
    '441 63': 'Norsesund',
    '441 65': 'Ingared',
    '441 70': 'Sollebrunn',
    '441 71': 'Alingsås',
    '441 72': 'Gräfsnäs',
    '441 73': 'Stora Mellby',
    '441 74': 'Bjärlanda',
    '441 75': 'Alingsås',
    '441 91': 'Långared',
    '441 92': 'Östadkulle',
    '441 93': 'Vårgårda',
    '441 94': 'Vårgårda',
    '441 95': 'Hjälmared',
    '441 96': 'Hemsjö',
    '442 30': 'Kungälv',
    '442 31': 'Kungälv',
    '442 32': 'Kungälv',
    '442 33': 'Kungälv',
    '442 34': 'Kungälv',
    '442 35': 'Kungälv',
    '442 36': 'Kungälv',
    '442 37': 'Kungälv',
    '442 38': 'Kungälv',
    '442 39': 'Kungälv',
    '442 40': 'Kungälv',
    '442 41': 'Kungälv',
    '442 42': 'Kungälv',
    '442 43': 'Kungälv',
    '442 44': 'Kungälv',
    '442 45': 'Kungälv',
    '442 48': 'Kungälv',
    '442 49': 'Kungälv',
    '442 50': 'Enekullen',
    '442 51': 'Kungälv',
    '442 52': 'Kungälv',
    '442 53': 'Kungälv',
    '442 54': 'Kungälv',
    '442 60': 'Kode',
    '442 65': 'Kungälv',
    '442 66': 'Marstrand',
    '442 67': 'Kungälv',
    '442 70': 'Kärna',
    '442 71': 'Kungälv',
    '442 72': 'Kungälv',
    '442 73': 'Kungälv',
    '442 74': 'Kungälv',
    '442 75': 'Tjuvkil',
    '442 77': 'Diseröd',
    '442 90': 'Rödbo',
    '442 91': 'Kungälv',
    '442 92': 'Duvesjön',
    '442 93': 'Kareby',
    '442 94': 'Kungälv',
    '442 95': 'Kungälv',
    '442 96': 'Kungälv',
    '442 97': 'Aröd',
    '442 98': 'Kungälv',
    '443 30': 'Lerum',
    '443 31': 'Lerum',
    '443 32': 'Lerum',
    '443 33': 'Lerum',
    '443 34': 'Lerum',
    '443 35': 'Lerum',
    '443 36': 'Lerum',
    '443 38': 'Lerum',
    '443 39': 'Lerum',
    '443 40': 'Lerum',
    '443 41': 'Lerum',
    '443 42': 'Lerum',
    '443 43': 'Gråbo',
    '443 45': 'Sjövik',
    '443 50': 'Lerum',
    '443 51': 'Lerum',
    '443 60': 'Lerum',
    '443 61': 'Stenkullen',
    '443 70': 'Olstorp',
    '443 71': 'Lerum',
    '443 72': 'Björboholm',
    '443 73': 'Lerum',
    '443 74': 'Lerum',
    '443 91': 'Lerum',
    '443 92': 'Stamsjö',
    '443 95': 'Lerum',
    '443 96': 'Lerum',
    '444 30': 'Stenungsund',
    '444 31': 'Stenungsund',
    '444 32': 'Doterod',
    '444 40': 'Stenungsund',
    '444 41': 'Stenungsund',
    '444 42': 'Söbacken',
    '444 43': 'Stenungsund',
    '444 44': 'Stenungsund',
    '444 45': 'Högenorum',
    '444 46': 'Kyrkenorum',
    '444 47': 'Östergård',
    '444 48': 'Stenungsön',
    '444 52': 'Stenungsund',
    '444 53': 'Stenungsund',
    '444 54': 'Stenungsund',
    '444 55': 'Strandnorum',
    '444 60': 'Stora Höga',
    '444 61': 'Stenungsund',
    '444 65': 'Jörlanda',
    '444 91': 'Fråstorp',
    '444 92': 'Timmervik',
    '444 93': 'Stenungsund',
    '444 94': 'Ucklum',
    '444 95': 'Ödsmål',
    '444 96': 'Starrkärr och Näs',
    '444 97': 'Svenshögen',
    '445 32': 'Ale',
    '445 33': 'Ale',
    '445 34': 'Ale',
    '445 36': 'Ale',
    '445 37': 'Ale',
    '445 52': 'Ale',
    '445 55': 'Ale',
    '445 56': 'Surte',
    '445 57': 'Ale',
    '446 32': 'Älvängen',
    '446 33': 'Ale',
    '446 34': 'Ale',
    '446 35': 'Ale',
    '446 36': 'Ale',
    '446 37': 'Ale',
    '446 38': 'Ale',
    '446 40': 'Skepplanda',
    '446 41': 'Ale',
    '446 91': 'Alvhem',
    '446 92': 'Ale',
    '446 93': 'Ale',
    '446 94': 'Ale',
    '446 95': 'Ale',
    '446 96': 'Ale',
    '446 97': 'Ale',
    '447 30': 'Vårgårda',
    '447 31': 'Vårgårda',
    '447 32': 'Vårgårda',
    '447 33': 'Klockarebolet',
    '447 34': 'Vårgårda',
    '447 35': 'Vårgårda',
    '447 37': 'Vårgårda',
    '447 91': 'Vårgårda',
    '447 92': 'Herrljunga',
    '447 93': 'Vårgårda',
    '447 94': 'Vårgårda',
    '447 95': 'Vårgårda',
    '448 30': 'Floda',
    '448 31': 'Lerum',
    '448 32': 'Lerum',
    '448 33': 'Lerum',
    '448 34': 'Lerum',
    '448 35': 'Lerum',
    '448 36': 'Lerum',
    '448 37': 'Lerum',
    '448 50': 'Tollered',
    '448 91': 'Lerum',
    '448 92': 'Lerum',
    '448 95': 'Lerum',
    '448 96': 'Alingsås',
    '449 30': 'Ale',
    '449 31': 'Ale',
    '449 32': 'Ale',
    '449 33': 'Ale',
    '449 34': 'Ale',
    '449 35': 'Ale',
    '449 36': 'Ale',
    '449 40': 'Nödinge-Nol',
    '449 41': 'Ale',
    '449 42': 'Ale',
    '449 43': 'Ale',
    '449 44': 'Ale',
    '449 50': 'Ale',
    '449 51': 'Ale',
    '449 70': 'Ale',
    '449 90': 'Ale',
    '451 30': 'Uddevalla',
    '451 31': 'Linneby',
    '451 32': 'Uddevalla',
    '451 33': 'Tureborg',
    '451 34': 'Äsperöd',
    '451 40': 'Uddevalla',
    '451 41': 'Vadbacken',
    '451 42': 'Uddevalla',
    '451 43': 'Bohusgården',
    '451 44': 'Kapelle',
    '451 45': 'Uddevalla',
    '451 50': 'Uddevalla',
    '451 51': 'Ramneröd',
    '451 52': 'Hedegärde',
    '451 53': 'Elseberg',
    '451 54': 'Vännerberg',
    '451 55': 'Kuröd',
    '451 60': 'Skogslyckan',
    '451 61': 'Uddevalla',
    '451 62': 'Unneröd',
    '451 63': 'Kurveröd',
    '451 70': 'Uddevalla',
    '451 71': 'Hovhult',
    '451 72': 'Dalaberg',
    '451 73': 'Helenedal',
    '451 75': 'Kissleberg',
    '451 76': 'Uddevalla',
    '451 77': 'Sunningen',
    '451 78': 'Fiskebäckskil',
    '451 79': 'Grundsund',
    '451 90': 'Ammenäs',
    '451 91': 'Sundstrand',
    '451 92': 'Uddevalla',
    '451 93': 'Fridhem',
    '451 94': 'Uddevalla',
    '451 95': 'Hogstorp',
    '451 96': 'Uddevalla',
    '451 97': 'Uddevalla',
    '451 98': 'Lanesund',
    '452 04': 'Strömstad',
    '452 05': 'Strömstad',
    '452 30': 'Strömstad',
    '452 31': 'Strömstad',
    '452 32': 'Kristorp',
    '452 33': 'Strömstad',
    '452 34': 'Röd',
    '452 35': 'Fridhem',
    '452 36': 'Strömstad',
    '452 37': 'Strömstad',
    '452 38': 'Strömstad',
    '452 40': 'Strömstad',
    '452 50': 'Strömstad',
    '452 51': 'Strömstad',
    '452 52': 'Strömstad',
    '452 60': 'Skee',
    '452 71': 'Strömstad',
    '452 90': 'Kebal',
    '452 91': 'Strömstad',
    '452 92': 'Östra Ånneröd',
    '452 93': 'Strömstad',
    '452 94': 'Strömstad',
    '452 95': 'Strömstad',
    '452 96': 'Strömstad',
    '452 97': 'Strömstad',
    '453 30': 'Dona',
    '453 31': 'Lysekil',
    '453 32': 'Lysekil',
    '453 33': 'Badhusberget',
    '453 34': 'Slätten',
    '453 35': 'Lysekil',
    '453 36': 'Mariedal',
    '453 37': 'Åkerbräckan',
    '453 38': 'Tröten',
    '453 40': 'Lysekil',
    '453 41': 'Lysekil',
    '453 50': 'Lysekil',
    '453 91': 'Lysekil',
    '453 92': 'Lysekil',
    '453 93': 'Lysekil',
    '454 30': 'Lysekil',
    '454 31': 'Brastad',
    '454 32': 'Lysekil',
    '454 91': 'Lysekil',
    '454 92': 'Lysekil',
    '454 93': 'Rixö',
    '454 94': 'Lysekil',
    '454 95': 'Lysekil',
    '455 30': 'Munkedal',
    '455 31': 'Munkedal',
    '455 32': 'Munkedal',
    '455 33': 'Munkedal',
    '455 34': 'Munkedal',
    '455 35': 'Munkedal',
    '455 41': 'Hedekas',
    '455 61': 'Dingle',
    '455 91': 'Munkedal',
    '455 92': 'Munkedal',
    '455 93': 'Torreby',
    '455 95': 'Munkedal',
    '455 96': 'Munkedal',
    '455 97': 'Munkedal',
    '455 98': 'Munkedal',
    '456 31': 'Kungshamn',
    '456 32': 'Sotenäs',
    '456 33': 'Sotenäs',
    '456 34': 'Sotenäs',
    '456 42': 'Sotenäs',
    '456 43': 'Sotenäs',
    '456 44': 'Väjern',
    '456 47': 'Bovallstrand',
    '456 50': 'Sotenäs',
    '456 51': 'Smögen',
    '456 55': 'Sotenäs',
    '456 60': 'Ulebergshamn',
    '456 61': 'Hunnebostrand',
    '456 62': 'Sotenäs',
    '456 91': 'Sotenäs',
    '456 92': 'Sotenäs',
    '456 93': 'Sotenäs',
    '456 94': 'Sotenäs',
    '457 30': 'Tanum',
    '457 31': 'Tanumshede',
    '457 32': 'Tanum',
    '457 40': 'Fjällbacka',
    '457 41': 'Tanum',
    '457 42': 'Tanum',
    '457 43': 'Tanum',
    '457 45': 'Tanum',
    '457 46': 'Tanum',
    '457 47': 'Hamburgsund',
    '457 48': 'Tanum',
    '457 50': 'Vassbotten',
    '457 51': 'Tanum',
    '457 55': 'Rabbalshede',
    '457 56': 'Tanum',
    '457 60': 'Hällevadsholm',
    '457 61': 'Munkedal',
    '457 72': 'Grebbestad',
    '457 73': 'Tanum',
    '457 91': 'Tanum',
    '457 93': 'Tanum',
    '457 95': 'Tanum',
    '457 96': 'Tanum',
    '457 97': 'Tanum',
    '458 30': 'Färgelanda',
    '458 31': 'Färgelanda',
    '458 32': 'Färgelanda',
    '458 40': 'Ödeborg',
    '458 70': 'Högsäter',
    '458 91': 'Färgelanda',
    '458 92': 'Valbo-Ryr',
    '458 93': 'Färgelanda',
    '458 94': 'Stigen',
    '458 96': 'Färgelanda',
    '458 97': 'Färgelanda',
    '459 30': 'Ljungskile',
    '459 31': 'Uddevalla',
    '459 32': 'Uddevalla',
    '459 33': 'Uddevalla',
    '459 91': 'Uddevalla',
    '459 93': 'Uddevalla',
    '459 94': 'Uddevalla',
    '461 30': 'Trollhättan',
    '461 31': 'Trollhättan',
    '461 32': 'Trollhättan',
    '461 33': 'Trollhättan',
    '461 34': 'Trollhättan',
    '461 35': 'Trollhättan',
    '461 37': 'Håjum',
    '461 38': 'Malöga',
    '461 39': 'Hjulkvarn',
    '461 40': 'Trollhättan',
    '461 41': 'Trollhättan',
    '461 42': 'Stavre',
    '461 43': 'Dannebacken',
    '461 44': 'Halvorstorp',
    '461 50': 'Trollhättan',
    '461 51': 'Hjortmossen',
    '461 52': 'Trollhättan',
    '461 53': 'Karlstorp',
    '461 54': 'Skoftebyn',
    '461 55': 'Trollhättan',
    '461 57': 'Strömslund',
    '461 58': 'Torsred',
    '461 59': 'Björndalen',
    '461 60': 'Kronogården',
    '461 61': 'Trollhättan',
    '461 62': 'Trollhättan',
    '461 63': 'Trollhättan',
    '461 64': 'Lextorp',
    '461 65': 'Sylte',
    '461 66': 'Trollhättan',
    '461 67': 'Trollhättan',
    '461 70': 'Överby',
    '461 71': 'Trollhättan',
    '461 73': 'Skogshöjden',
    '461 77': 'Trollhättan',
    '461 78': 'Sjuntorp',
    '461 79': 'Upphärad',
    '461 91': 'Hälltorp',
    '461 92': 'Trollhättan',
    '461 93': 'Vänersborg',
    '461 94': 'Väne-Åsaka',
    '461 95': 'Norra Björke',
    '461 96': 'Trollhättan',
    '461 97': 'Trollhättan',
    '461 98': 'Velanda',
    '461 99': 'Trollhättan',
    '462 30': 'Vänersborg',
    '462 31': 'Vänersborg',
    '462 32': 'Vänersborg',
    '462 33': 'Vänersborg',
    '462 34': 'Vänersborg',
    '462 35': 'Vänersborg',
    '462 36': 'Vänersborg',
    '462 37': 'Vänersborg',
    '462 38': 'Vänersborg',
    '462 40': 'Vänersborg',
    '462 41': 'Vänersborg',
    '462 42': 'Vänersborg',
    '462 50': 'Vänersborg',
    '462 51': 'Vänersborg',
    '462 52': 'Vänersborg',
    '462 53': 'Vänersborg',
    '462 54': 'Restad Gård',
    '462 55': 'Vänersborg',
    '462 56': 'Vänersborg',
    '462 60': 'Vänersborg',
    '462 61': 'Vänersborg',
    '462 62': 'Vänersborg',
    '462 69': 'Frändefors',
    '462 73': 'Vänersborg',
    '462 91': 'Vänersborg',
    '462 93': 'Vänersborg',
    '462 94': 'Timmervik',
    '462 95': 'Vänersborg',
    '462 96': 'Vänersborg',
    '462 97': 'Vänersborg',
    '463 30': 'Lilla Edet',
    '463 31': 'Lilla Edet',
    '463 32': 'Lilla Edet',
    '463 33': 'Lilla Edet',
    '463 34': 'Lilla Edet',
    '463 35': 'Göta',
    '463 71': 'Lödöse',
    '463 72': 'Lilla Edet',
    '463 75': 'Hjärtum',
    '463 92': 'Lilla Edet',
    '463 93': 'Lilla Edet',
    '463 94': 'Lilla Edet',
    '463 95': 'Lilla Edet',
    '463 96': 'Nygård',
    '463 97': 'Lilla Edet',
    '464 30': 'Mellerud',
    '464 31': 'Mellerud',
    '464 32': 'Mellerud',
    '464 33': 'Mellerud',
    '464 34': 'Mellerud',
    '464 40': 'Åsensbruk',
    '464 50': 'Dals Rostock',
    '464 61': 'Vänersborg',
    '464 62': 'Brålanda',
    '464 63': 'Vänersborg',
    '464 64': 'Vänersborg',
    '464 65': 'Sikhall',
    '464 66': 'Mellerud',
    '464 71': 'Mellerud',
    '464 72': 'Mellerud',
    '464 91': 'Mellerud',
    '464 92': 'Mellerud',
    '464 93': 'Jakobsbyn',
    '464 94': 'Mellerud',
    '464 95': 'Mellerud',
    '465 30': 'Nossebro',
    '465 31': 'Essunga',
    '465 91': 'Essunga',
    '465 93': 'Essunga',
    '465 94': 'Essunga',
    '465 95': 'Jonslund',
    '465 96': 'Essunga',
    '465 97': 'Främmestad',
    '465 98': 'Essunga',
    '467 30': 'Grästorp',
    '467 31': 'Grästorp',
    '467 32': 'Grästorp',
    '467 35': 'Grästorp',
    '467 40': 'Grästorp',
    '467 91': 'Grästorp',
    '467 92': 'Grästorp',
    '467 93': 'Grästorp',
    '467 94': 'Grästorp',
    '467 95': 'Grästorp',
    '467 96': 'Grästorp',
    '468 30': 'Vänersborg',
    '468 31': 'Vänersborg',
    '468 32': 'Vänersborg',
    '468 33': 'Vargön',
    '468 34': 'Nordkroken',
    '468 90': 'Vänersborg',
    '471 30': 'Tjörn',
    '471 31': 'Tjörn',
    '471 32': 'Skärhamn',
    '471 33': 'Tjörn',
    '471 41': 'Rönnäng',
    '471 42': 'Tjörn',
    '471 43': 'Dyrön',
    '471 44': 'Åstol',
    '471 50': 'Bleket',
    '471 51': 'Klädesholmen',
    '471 60': 'Myggenäs',
    '471 61': 'Tjörn',
    '471 62': 'Tjörn',
    '471 70': 'Höviksnäs',
    '471 71': 'Tjörn',
    '471 72': 'Tjörn',
    '471 73': 'Tjörn',
    '471 90': 'Tjörn',
    '471 91': 'Tjörn',
    '471 92': 'Tjörn',
    '471 93': 'Tjörn',
    '471 94': 'Kållekärr',
    '471 95': 'Tjörn',
    '471 97': 'Tjörn',
    '471 98': 'Tjörn',
    '472 31': 'Svanesund',
    '472 32': 'Orust',
    '472 91': 'Orust',
    '472 92': 'Orust',
    '472 93': 'Orust',
    '472 94': 'Orust',
    '472 95': 'Varekil',
    '472 96': 'Orust',
    '472 97': 'Orust',
    '472 98': 'Stenungsund',
    '473 31': 'Orust',
    '473 32': 'Henån',
    '473 33': 'Näset',
    '473 34': 'Orust',
    '473 91': 'Orust',
    '473 92': 'Orust',
    '473 93': 'Orust',
    '473 94': 'Orust',
    '473 95': 'Orust',
    '473 96': 'Orust',
    '473 97': 'Orust',
    '473 98': 'Orust',
    '473 99': 'Orust',
    '474 31': 'Ellös',
    '474 32': 'Grönevik',
    '474 70': 'Mollösund',
    '474 71': 'Orust',
    '474 74': 'Orust',
    '474 91': 'Orust',
    '474 92': 'Stocken',
    '474 93': 'Orust',
    '474 94': 'Orust',
    '474 95': 'Hälleviksstrand',
    '474 96': 'Nösund',
    '475 31': 'Öckerö',
    '475 32': 'Öckerö',
    '475 35': 'Öckerö',
    '475 36': 'Öckerö',
    '475 37': 'Björkö',
    '475 40': 'Öckerö',
    '475 41': 'Hönö',
    '475 42': 'Öckerö',
    '475 45': 'Fotö',
    '475 50': 'Hälsö',
    '475 51': 'Källö-Knippla',
    '475 52': 'Öckerö',
    '475 53': 'Öckerö',
    '503 30': 'Borås',
    '503 31': 'Borås',
    '503 32': 'Borås',
    '503 33': 'Borås',
    '503 34': 'Borås',
    '503 35': 'Borås',
    '503 36': 'Borås',
    '503 37': 'Borås',
    '503 38': 'Borås',
    '503 42': 'Borås',
    '503 43': 'Lugnet',
    '504 30': 'Borås',
    '504 31': 'Borås',
    '504 32': 'Göta',
    '504 33': 'Borås',
    '504 34': 'Druvefors',
    '504 35': 'Borås',
    '504 37': 'Norrby',
    '504 38': 'Borås',
    '504 39': 'Parkstaden',
    '504 41': 'Furuberg',
    '504 42': 'Borås',
    '504 43': 'Borås',
    '504 44': 'Kristineberg',
    '504 45': 'Dammsvedjan',
    '504 46': 'Hedvigsborg',
    '504 47': 'Borås',
    '504 50': 'Borås',
    '504 51': 'Borås',
    '504 52': 'Borås',
    '504 53': 'Borås',
    '504 54': 'Villastaden',
    '504 55': 'Östermalm',
    '504 56': 'Borås',
    '504 57': 'Salängen',
    '504 58': 'Bergdalen',
    '504 60': 'Borås',
    '504 61': 'Tullen',
    '504 62': 'Borås',
    '504 63': 'Lundaskog',
    '504 64': 'Borås',
    '504 65': 'Borås',
    '504 66': 'Byttorp',
    '504 67': 'Borås',
    '504 68': 'Borås',
    '504 70': 'Hestra parkstad',
    '504 71': 'Borås',
    '504 72': 'Hestra',
    '504 75': 'Borås',
    '504 78': 'Sandhult',
    '504 90': 'Borås',
    '504 91': 'Borås',
    '504 92': 'Hedared',
    '504 93': 'Bosnäs',
    '504 94': 'Borås',
    '504 95': 'Borås',
    '504 96': 'Borås',
    '504 97': 'Bredared',
    '506 30': 'Borås',
    '506 31': 'Alideberg',
    '506 32': 'Borås',
    '506 34': 'Borås',
    '506 35': 'Borås',
    '506 36': 'Borås',
    '506 37': 'Borås',
    '506 38': 'Borås',
    '506 39': 'Norrmalm',
    '506 40': 'Borås',
    '506 41': 'Borås',
    '506 42': 'Sjöbo',
    '506 43': 'Borås',
    '506 44': 'Borås',
    '506 46': 'Borås',
    '506 47': 'Borås',
    '506 48': 'Borås',
    '506 49': 'Tosseryd',
    '506 70': 'Frufällan',
    '507 30': 'Svensgärde',
    '507 31': 'Borås',
    '507 32': 'Brämhult',
    '507 33': 'Borås',
    '507 34': 'Borås',
    '507 40': 'Borås',
    '507 41': 'Borås',
    '507 42': 'Hässleholmen',
    '507 43': 'Borås',
    '507 44': 'Borås',
    '507 45': 'Borås',
    '507 50': 'Borås',
    '507 51': 'Borås',
    '507 52': 'Hulta',
    '507 53': 'Borås',
    '507 60': 'Borås',
    '507 61': 'Borås',
    '507 62': 'Söderkulla',
    '507 63': 'Trandared',
    '507 64': 'Borås',
    '507 65': 'Brotorp',
    '507 70': 'Gånghester',
    '507 71': 'Borås',
    '507 90': 'Borås',
    '511 31': 'Örby',
    '511 41': 'Mark',
    '511 42': 'Mark',
    '511 54': 'Kinna',
    '511 55': 'Mark',
    '511 56': 'Mark',
    '511 57': 'Mark',
    '511 58': 'Mark',
    '511 59': 'Mark',
    '511 61': 'Skene',
    '511 62': 'Mark',
    '511 63': 'Mark',
    '511 68': 'Hyssna',
    '511 69': 'Sätila',
    '511 70': 'Rydal',
    '511 71': 'Fritsla',
    '511 72': 'Mark',
    '511 73': 'Mark',
    '511 74': 'Mark',
    '511 91': 'Mark',
    '511 92': 'Mark',
    '511 93': 'Torestorp',
    '511 94': 'Mark',
    '511 95': 'Öxabäck',
    '511 96': 'Berghem',
    '511 97': 'Hajom',
    '511 98': 'Mark',
    '511 99': 'Mark',
    '512 50': 'Svenljunga',
    '512 51': 'Svenljunga',
    '512 52': 'Svenljunga',
    '512 53': 'Svenljunga',
    '512 54': 'Svenljunga',
    '512 60': 'Överlida',
    '512 61': 'Svenljunga',
    '512 62': 'Svenljunga',
    '512 63': 'Östra Frölunda',
    '512 64': 'Svenljunga',
    '512 65': 'Mjöbäck',
    '512 76': 'Hillared',
    '512 77': 'Sexdrega',
    '512 90': 'Svenljunga',
    '512 91': 'Svenljunga',
    '512 92': 'Svenljunga',
    '512 93': 'Svenljunga',
    '512 94': 'Svenljunga',
    '512 95': 'Svenljunga',
    '513 32': 'Borås',
    '513 33': 'Borås',
    '513 34': 'Fristad',
    '513 35': 'Borås',
    '513 50': 'Borås',
    '513 70': 'Borgstena',
    '513 91': 'Borås',
    '513 92': 'Borås',
    '513 93': 'Tämta',
    '513 94': 'Borås',
    '513 95': 'Borås',
    '513 96': 'Borås',
    '513 97': 'Borås',
    '514 31': 'Tranemo',
    '514 32': 'Tranemo',
    '514 33': 'Tranemo',
    '514 34': 'Tranemo',
    '514 35': 'Tranemo',
    '514 40': 'Tranemo',
    '514 41': 'Limmared',
    '514 42': 'Rosenlund',
    '514 43': 'Tranemo',
    '514 44': 'Länghem',
    '514 50': 'Tranemo',
    '514 52': 'Tranemo',
    '514 53': 'Tranemo',
    '514 54': 'Tranemo',
    '514 55': 'Ljungsarp',
    '514 60': 'Dalstorp',
    '514 61': 'Tranemo',
    '514 62': 'Tranemo',
    '514 63': 'Tranemo',
    '514 70': 'Grimsås',
    '514 91': 'Tranemo',
    '514 92': 'Tranemo',
    '514 93': 'Ambjörnarp',
    '514 94': 'Tranemo',
    '514 95': 'Tranemo',
    '515 32': 'Viskafors',
    '515 33': 'Borås',
    '515 34': 'Borås',
    '515 35': 'Borås',
    '515 60': 'Borås',
    '515 61': 'Borås',
    '515 70': 'Rydboholm',
    '515 91': 'Borås',
    '515 92': 'Kinnarumma',
    '515 93': 'Borås',
    '516 30': 'Borås',
    '516 31': 'Dalsjöfors',
    '516 32': 'Borås',
    '516 33': 'Borås',
    '516 34': 'Borås',
    '516 50': 'Borås',
    '516 77': 'Borås',
    '516 90': 'Borås',
    '516 91': 'Borås',
    '516 92': 'Borås',
    '516 93': 'Rångedala',
    '516 94': 'Dannike',
    '516 95': 'Borås',
    '516 96': 'Borås',
    '517 33': 'Bollebygd',
    '517 34': 'Bollebygd',
    '517 35': 'Bollebygd',
    '517 36': 'Bollebygd',
    '517 37': 'Bollebygd',
    '517 70': 'Töllsjö',
    '517 71': 'Olsfors',
    '517 91': 'Bollebygd',
    '517 92': 'Bollebygd',
    '517 93': 'Borås',
    '517 94': 'Bollebygd',
    '517 95': 'Bollebygd',
    '517 96': 'Hultafors',
    '518 30': 'Sandared',
    '518 31': 'Borås',
    '518 32': 'Borås',
    '518 33': 'Borås',
    '518 40': 'Sjömarken',
    '518 41': 'Borås',
    '518 42': 'Borås',
    '518 43': 'Borås',
    '518 90': 'Borås',
    '518 91': 'Borås',
    '519 30': 'Horred',
    '519 31': 'Mark',
    '519 90': 'Mark',
    '519 91': 'Mark',
    '519 94': 'Björketorp',
    '519 95': 'Mark',
    '519 96': 'Mark',
    '519 97': 'Mark',
    '521 30': 'Falköping',
    '521 31': 'Falköping',
    '521 32': 'Falköping',
    '521 33': 'Falköping',
    '521 34': 'Falköping',
    '521 35': 'Falköping',
    '521 36': 'Falköping',
    '521 37': 'Falköping',
    '521 40': 'Falköping',
    '521 41': 'Falköping',
    '521 42': 'Falköping',
    '521 43': 'Falköping',
    '521 44': 'Falköping',
    '521 45': 'Falköping',
    '521 46': 'Falköping',
    '521 47': 'Falköping',
    '521 51': 'Falköping',
    '521 52': 'Floby',
    '521 53': 'Falköping',
    '521 54': 'Herrljunga',
    '521 55': 'Falköping',
    '521 56': 'Falköping',
    '521 60': 'Stenstorp',
    '521 61': 'Falköping',
    '521 62': 'Dala',
    '521 63': 'Falköping',
    '521 64': 'Skövde',
    '521 70': 'Åsarp',
    '521 71': 'Falköping',
    '521 72': 'Falköping',
    '521 73': 'Kinnarp',
    '521 74': 'Gudhem',
    '521 75': 'Vartofta',
    '521 91': 'Falköping',
    '521 92': 'Odensberg',
    '521 93': 'Falköping',
    '521 94': 'Falköping',
    '521 95': 'Kättilstorp',
    '521 96': 'Torbjörntorp',
    '521 97': 'Falköping',
    '521 98': 'Falköping',
    '522 30': 'Tidaholm',
    '522 31': 'Tidaholm',
    '522 32': 'Tidaholm',
    '522 33': 'Tidaholm',
    '522 34': 'Tidaholm',
    '522 35': 'Tidaholm',
    '522 36': 'Tidaholm',
    '522 37': 'Tidaholm',
    '522 91': 'Madängsholm',
    '522 92': 'Tidaholm',
    '522 93': 'Ekedalen',
    '522 94': 'Tidaholm',
    '523 30': 'Ulricehamn',
    '523 31': 'Ulricehamn',
    '523 32': 'Ulricehamn',
    '523 33': 'Ulricehamn',
    '523 34': 'Ulricehamn',
    '523 35': 'Ulricehamn',
    '523 36': 'Ulricehamn',
    '523 37': 'Ulricehamn',
    '523 38': 'Ulricehamn',
    '523 39': 'Ulricehamn',
    '523 40': 'Ulricehamn',
    '523 41': 'Ulricehamn',
    '523 43': 'Ulricehamn',
    '523 44': 'Ulricehamn',
    '523 45': 'Ulricehamn',
    '523 60': 'Gällstad',
    '523 61': 'Rånnaväg',
    '523 62': 'Vegby',
    '523 72': 'Timmele',
    '523 74': 'Hökerum',
    '523 75': 'Dalum',
    '523 76': 'Blidsberg',
    '523 77': 'Ulricehamn',
    '523 78': 'Trädet',
    '523 90': 'Ulricehamn',
    '523 91': 'Ulricehamn',
    '523 92': 'Ulricehamn',
    '523 93': 'Marbäck',
    '523 94': 'Hulu',
    '523 95': 'Ulricehamn',
    '523 96': 'Ulricehamn',
    '523 97': 'Ulricehamn',
    '523 98': 'Ulricehamn',
    '523 99': 'Nitta',
    '524 30': 'Herrljunga',
    '524 31': 'Herrljunga',
    '524 32': 'Herrljunga',
    '524 41': 'Ljung',
    '524 42': 'Annelund',
    '524 91': 'Herrljunga',
    '524 92': 'Herrljunga',
    '524 93': 'Herrljunga',
    '524 95': 'Herrljunga',
    '524 96': 'Herrljunga',
    '530 30': 'Lidköping',
    '530 32': 'Lidköping',
    '531 30': 'Lidköping',
    '531 31': 'Lidköping',
    '531 32': 'Gamla staden',
    '531 33': 'Vänersvik',
    '531 34': 'Lidköping',
    '531 35': 'Lidköping',
    '531 36': 'Lidköping',
    '531 37': 'Lidköping',
    '531 38': 'Lidköping',
    '531 39': 'Lidåker',
    '531 40': 'Östra hamnen',
    '531 41': 'Ljunghed',
    '531 42': 'Majåker',
    '531 43': 'Östra Margretelund',
    '531 50': 'Nya staden',
    '531 51': 'Lidköping',
    '531 52': 'Ågårdsområdet',
    '531 53': 'Dalängen',
    '531 54': 'Framnäs',
    '531 55': 'Tofta',
    '531 56': 'Stenhammar',
    '531 57': 'Lillängen',
    '531 58': 'Lidköping',
    '531 59': 'Råda mosse',
    '531 70': 'Filsbäck',
    '531 71': 'Vinninga',
    '531 73': 'Källby',
    '531 75': 'Järpås',
    '531 91': 'Lidköping',
    '531 92': 'Lidköping',
    '531 93': 'Saleby',
    '531 94': 'Lidköping',
    '531 95': 'Götene',
    '531 96': 'Råda',
    '531 97': 'Örslösa',
    '531 98': 'Lidköping',
    '531 99': 'Lidköping',
    '532 30': 'Skara',
    '532 31': 'Skara',
    '532 32': 'Skara',
    '532 33': 'Skara',
    '532 34': 'Skara',
    '532 35': 'Skara',
    '532 36': 'Skara',
    '532 37': 'Skara',
    '532 38': 'Skara',
    '532 72': 'Axvall',
    '532 73': 'Varnhem',
    '532 74': 'Ardala',
    '532 92': 'Eggby',
    '532 93': 'Skara',
    '532 94': 'Skara',
    '532 95': 'Skara',
    '532 96': 'Skara',
    '533 30': 'Götene',
    '533 31': 'Götene',
    '533 32': 'Götene',
    '533 33': 'Götene',
    '533 34': 'Götene',
    '533 72': 'Lundsbrunn',
    '533 74': 'Hällekis',
    '533 91': 'Götene',
    '533 92': 'Hangelösa',
    '533 94': 'Gössäter',
    '533 95': 'Götene',
    '533 96': 'Götene',
    '533 97': 'Götene',
    '534 30': 'Vara',
    '534 31': 'Vara',
    '534 32': 'Vara',
    '534 50': 'Vara',
    '534 60': 'Vedum',
    '534 61': 'Vara',
    '534 62': 'Larv',
    '534 73': 'Stora Levene',
    '534 90': 'Vara',
    '534 91': 'Emtunga',
    '534 92': 'Tråvad',
    '534 93': 'Vara',
    '534 94': 'Arentorp',
    '534 95': 'Vara',
    '534 96': 'Vara',
    '535 30': 'Kvänum',
    '535 91': 'Vara',
    '535 92': 'Jung',
    '535 93': 'Vara',
    '541 30': 'Skövde',
    '541 31': 'Skövde',
    '541 32': 'Skövde',
    '541 33': 'Skövde',
    '541 34': 'Skövde',
    '541 35': 'Skövde',
    '541 36': 'Skövde',
    '541 37': 'Skövde',
    '541 38': 'Skövde',
    '541 39': 'Skövde',
    '541 40': 'Skövde',
    '541 41': 'Skövde',
    '541 42': 'Skövde',
    '541 43': 'Skövde',
    '541 44': 'Skövde',
    '541 45': 'Skövde',
    '541 46': 'Skövde',
    '541 47': 'Skövde',
    '541 48': 'Skövde',
    '541 49': 'Skövde',
    '541 50': 'Skövde',
    '541 52': 'Skövde',
    '541 53': 'Skövde',
    '541 54': 'Skövde',
    '541 55': 'Skövde',
    '541 56': 'Skövde',
    '541 57': 'Skultorp',
    '541 61': 'Skövde',
    '541 62': 'Skövde',
    '541 63': 'Skövde',
    '541 64': 'Skövde',
    '541 65': 'Skövde',
    '541 66': 'Skövde',
    '541 70': 'Stöpen',
    '541 71': 'Timmersdala',
    '541 76': 'Väring',
    '541 77': 'Igelstorp',
    '541 78': 'Värsås',
    '541 91': 'Skövde',
    '541 92': 'Skövde',
    '541 93': 'Skövde',
    '541 94': 'Varola',
    '541 95': 'Mariestad',
    '541 96': 'Skövde',
    '541 97': 'Lerdala',
    '541 98': 'Skövde',
    '542 30': 'Mariestad',
    '542 31': 'Mariestad',
    '542 32': 'Mariestad',
    '542 33': 'Mariestad',
    '542 34': 'Mariestad',
    '542 35': 'Mariestad',
    '542 36': 'Mariestad',
    '542 40': 'Mariestad',
    '542 41': 'Mariestad',
    '542 42': 'Mariestad',
    '542 43': 'Mariestad',
    '542 44': 'Mariestad',
    '542 45': 'Ekudden',
    '542 66': 'Sjötorp',
    '542 72': 'Mariestad',
    '542 73': 'Ullervad',
    '542 74': 'Lugnås',
    '542 75': 'Götene',
    '542 91': 'Mariestad',
    '542 92': 'Mariestad',
    '542 93': 'Mariestad',
    '542 94': 'Mariestad',
    '542 95': 'Mariestad',
    '543 30': 'Tibro',
    '543 31': 'Tibro',
    '543 32': 'Tibro',
    '543 33': 'Tibro',
    '543 34': 'Tibro',
    '543 35': 'Tibro',
    '543 36': 'Tibro',
    '543 37': 'Tibro',
    '543 50': 'Tibro',
    '543 51': 'Tibro',
    '543 91': 'Tibro',
    '543 92': 'Tibro',
    '543 93': 'Tibro',
    '543 94': 'Tibro',
    '543 95': 'Fagersanna',
    '544 30': 'Hjo',
    '544 31': 'Hjo',
    '544 32': 'Hjo',
    '544 33': 'Hjo',
    '544 50': 'Hjo',
    '544 91': 'Hjo',
    '544 92': 'Grebban',
    '544 93': 'Blikstorp',
    '544 94': 'Hjo',
    '545 30': 'Töreboda',
    '545 31': 'Töreboda',
    '545 32': 'Töreboda',
    '545 33': 'Töreboda',
    '545 34': 'Töreboda',
    '545 35': 'Töreboda',
    '545 72': 'Älgarås',
    '545 90': 'Töreboda',
    '545 91': 'Töreboda',
    '545 92': 'Töreboda',
    '545 93': 'Töreboda',
    '546 30': 'Karlsborg',
    '546 31': 'Karlsborg',
    '546 32': 'Karlsborg',
    '546 33': 'Karlsborg',
    '546 34': 'Karlsborg',
    '546 72': 'Mölltorp',
    '546 73': 'Forsvik',
    '546 91': 'Karlsborg',
    '546 92': 'Karlsborg',
    '546 93': 'Karlsborg',
    '546 94': 'Undenäs',
    '546 95': 'Björkenäs',
    '547 30': 'Gullspång',
    '547 31': 'Gullspång',
    '547 72': 'Otterbäcken',
    '547 91': 'Gullspång',
    '547 92': 'Gullspång',
    '547 93': 'Skagersvik',
    '548 31': 'Gullspång',
    '548 32': 'Hova',
    '548 73': 'Lyrestad',
    '548 74': 'Gullspång',
    '548 91': 'Gullspång',
    '548 92': 'Gullspång',
    '548 93': 'Mariestad',
    '549 31': 'Tidan',
    '549 41': 'Moholm',
    '549 91': 'Skövde',
    '549 92': 'Töreboda',
    '549 93': 'Ekeskog',
    '565 93': 'Ulricehamn',
    '662 30': 'Åmål',
    '662 31': 'Åmål',
    '662 32': 'Åmål',
    '662 33': 'Åmål',
    '662 34': 'Åmål',
    '662 35': 'Åmål',
    '662 36': 'Åmål',
    '662 37': 'Åmål',
    '662 91': 'Åmål',
    '662 92': 'Åmål',
    '662 95': 'Fengersfors',
    '662 97': 'Åmål',
    '662 98': 'Tösse',
    '666 30': 'Bengtsfors',
    '666 31': 'Bengtsfors',
    '666 32': 'Bengtsfors',
    '666 40': 'Skåpafors',
    '666 41': 'Billingsfors',
    '666 91': 'Herrenäs',
    '666 92': 'Bengtsfors',
    '666 93': 'Bengtsfors',
    '666 94': 'Bengtsfors',
    '666 95': 'Dals Långed',
    '668 30': 'Ed',
    '668 31': 'Dals-Ed',
    '668 32': 'Dals-Ed',
    '668 40': 'Bäckefors',
    '668 91': 'Dals-Ed',
    '668 92': 'Dals-Ed',
    '668 93': 'Dals-Ed',
    '668 94': 'Bengtsfors',
    '668 95': 'Bengtsfors',
};
