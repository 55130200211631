import ReactGA from 'react-ga';
import { History } from 'history';
import { GA_ID } from '../config/ga';

export const ga = (history: History): void => {
    ReactGA.initialize(GA_ID);

    history.listen(function (location) {
        ReactGA.pageview(location.pathname + location.search);
    });
};
