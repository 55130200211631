import { ProtectorDomainEnums } from '../interfaces/enums/ProtectorDomain.enums';
import { CountryCodeEnums } from '../interfaces/enums';

export const isDomain = (domain: ProtectorDomainEnums): Boolean => {
    const hostArr = window.location.host.split('.').reverse();
    const isUK = hostArr[0] === CountryCodeEnums.UK;
    const host = hostArr
        .splice(0, isUK ? 3 : 2)
        .reverse()
        .join('.') as ProtectorDomainEnums;

    return host === domain;
};
