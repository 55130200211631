import { motorProgressBarRoutes } from '../config/wizardRouter/motorProgressBarRoutes';
import { personProgressBarRoutes } from '../config/wizardRouter/personProgressBarRoutes';
import { LobKeys, Nullable } from '@protectorinsurance/ds-can';
import { lpoProgressBarRoutes } from '../config/wizardRouter/lpoProgressBarRoutes';

/**
 * Destructure necessary imports
 */
const { AUTO, PERSON } = LobKeys;

/**
 * Utility logic
 */
export const getLobProgressBarRoutes = (lob: Nullable<LobKeys>) => {
    if (lob === PERSON) {
        return personProgressBarRoutes;
    } else if (lob === AUTO) {
        return motorProgressBarRoutes;
    } else {
        return lpoProgressBarRoutes;
    }
};
