import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { selectDriverInformation, selectHasParkedWitnesses } from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../sagas/motor';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    DriverInformationModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    MuiTextInput,
    Nullable,
    PageLayout,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import { driverInformationSchema } from '../../../validations/schemas/driverInformationSchema';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { DRIVER_IS_UNKNOWN, DRIVER_MISSING_SSN, EMAIL, FIRST_NAME, LAST_NAME, NATIONAL_IDENTITY, PHONE } =
    FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DRIVER_IS_UNKNOWN_LABEL,
    DRIVER_MISSING_SSN_LABEL,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NATIONAL_IDENTITY_LABEL,
    NATIONAL_IDENTITY_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { END_WITNESS_INFORMATION } = MotorRoutePaths;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;

/**
 * Interfaces
 */
interface DriverFormModel extends Omit<DriverInformationModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const DriverInformationPage = () => {
    const dispatch = useDispatch();
    const driverInformation = useSelector(selectDriverInformation);
    const customCAN = useSelector(selectCustomCAN);
    const driverIsUnknown = driverInformation.isUnknown || false;
    const driverMissingSSN = driverInformation.missingSSN || false;

    const hasParkedWitnesses = useSelector(selectHasParkedWitnesses);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.driverInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<DriverFormModel>({
        resolver: yupResolver(driverInformationSchema(t, driverIsUnknown, driverMissingSSN)),
        defaultValues: {
            ...driverInformation,
            dateOfBirth: driverInformation.dateOfBirth && driverInformation.dateOfBirth.toDate(),
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked, id } = e.target;
        if (checked && is(id, DRIVER_MISSING_SSN)) {
            setValue('missingSSN', checked);
            setValue('nationalIdentity', null);
        }

        if (checked && is(id, DRIVER_IS_UNKNOWN)) {
            setValue('isUnknown', checked);
            setValue('firstName', null);
            setValue('lastName', null);
            setValue('email', null);
            setValue('phone', null);
            setValue('nationalIdentity', null);
        }
        dispatch(wizardActions.skipBackToPrev(true));
        dispatcherWithPromise(dispatch, motorActions.update, {
            driverInformation: {
                ...driverInformation,
                [id]: checked,
            },
        });
    };

    const onSubmit = (values: DriverFormModel) => {
        if (hasParkedWitnesses !== null) {
            dispatcherWithPromise(dispatch, motorActions.update, {
                driverInformation: {
                    ...driverInformation,
                    ...values,
                    dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                },
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goTo(END_WITNESS_INFORMATION)));
        } else {
            dispatcherWithPromise(dispatch, motorActions.update, {
                driverInformation: {
                    ...driverInformation,
                    ...values,
                    dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                },
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <SingleCheckbox
                        checked={driverIsUnknown}
                        handleChange={handleChecked}
                        name={DRIVER_IS_UNKNOWN}
                        wrapperClass={'col-6 multiple'}
                        {...{ customCAN }}
                    >
                        {t(DRIVER_IS_UNKNOWN_LABEL)}
                    </SingleCheckbox>
                    <SingleCheckbox
                        checked={driverMissingSSN}
                        handleChange={handleChecked}
                        name={DRIVER_MISSING_SSN}
                        wrapperClass={'col-6 multiple'}
                        {...{ customCAN }}
                    >
                        {t(DRIVER_MISSING_SSN_LABEL)}
                    </SingleCheckbox>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                disabled={driverIsUnknown}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                disabled={driverIsUnknown}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={NATIONAL_IDENTITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                disabled={driverMissingSSN || driverIsUnknown}
                                error={!!errors.nationalIdentity}
                                errorMessage={errors.nationalIdentity?.message}
                                id={NATIONAL_IDENTITY}
                                inputFieldWrapper={'col-3'}
                                label={t(NATIONAL_IDENTITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(NATIONAL_IDENTITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                disabled={driverIsUnknown}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-6'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                disabled={driverIsUnknown}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-3'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
