import { StringSchema, TestContext, ValidationError } from 'yup';
import { FormFieldNames } from 'config/formFieldNames';
import { yupCustomLocale } from 'config/yup';
import { isDev } from '../../utils/env';
import { bankAccountNumberUtils } from '../../utils/bank/bankAccountNumberUtils';
import { bankClearingNumberUtils } from '../../utils/bank/bankClearingNumberUtils';
import { isEmpty } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { CLEARING_NUMBER } = FormFieldNames;

/**
 * Method logic
 */
export default function bankAccountNumber(
    this: StringSchema,
    required: boolean,
    message: string = yupCustomLocale.string.bankAccountNumber
) {
    // @ts-ignore
    return this.test('bankAccountNumber', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        const skipValidation = isDev() || (!required && isEmpty(value));
        if (skipValidation) {
            return true;
        }

        // Needed for tests
        if (!this.parent) {
            return false;
        }

        const clearingNumber = this.parent[CLEARING_NUMBER];
        return !bankClearingNumberUtils.isValid(clearingNumber)
            ? this.createError({
                  message: yupCustomLocale.string.bankAccountDepOnClearingNumber,
                  params: { type: 'required' },
              })
            : bankAccountNumberUtils.isValid(value, clearingNumber);
    });
}
