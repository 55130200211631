import React from 'react';
import {
    createMinutesList,
    TimePickerMode,
} from '../../../../utils/dateTimeUtils';

interface MinuteOptionsProps {
    mode: TimePickerMode;
}

export const MinuteOptions = ({ mode }: MinuteOptionsProps) => {
    const minutesList = createMinutesList(mode);
    return (
        <>
            {minutesList.map((m) => {
                return (
                    <option value={m} key={`min-${m}`}>
                        {m}
                    </option>
                );
            })}
        </>
    );
};
