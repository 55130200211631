import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';

export const claimantInformationDeathSchema = (t: TFunction, missingSSN: boolean = false) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...(!missingSSN && nationalIdentitySchema(t)),
    });
};
