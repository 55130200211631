/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Clickable } from '../../../interfaces/types/Events.types';
import { PersonModel } from '../../../interfaces/models/Person.model';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';
import { summaryPersonItemCss } from './SummaryPersonItem.style';
import { Grid } from '../grid/Grid';

interface SummaryPersonItemProps {
    item: PersonModel;
    textKey: string;
    path: string;
    dataTestId: string;
    className: string;
    printSummary: boolean;
    handleClick?: (e: Clickable, path: string) => void;
}
export const SummaryPersonItem = ({
    item,
    path,
    textKey,
    dataTestId,
    className,
    printSummary = false,
    handleClick,
}: SummaryPersonItemProps) => {
    return (
        <div className={className}>
            <SummaryText text={textKey} />
            <div className={'persons-list'} css={css(summaryPersonItemCss)}>
                {!handleClick || printSummary ? (
                    <span data-hj-suppress tabIndex={0}>
                        <Grid>
                            <div className={'col-6 spacing text-align-left'}>
                                {item.firstName} {item.lastName}{' '}
                                {item.nationalIdentity
                                    ? `(${item.nationalIdentity})`
                                    : ''}
                            </div>
                            <div className={'col-6 spacing text-align-left'}>
                                {item.email}
                            </div>
                            <div className={'col-12 spacing text-align-left'}>
                                {item.phone}
                            </div>
                        </Grid>
                    </span>
                ) : (
                    <button
                        type={'button'}
                        data-testid={dataTestId}
                        onClick={(e) => handleClick(e, path)}
                        data-hj-suppress
                    >
                        <Grid>
                            <div className={'col-4'}>
                                <p>
                                    {item.firstName} {item.lastName}
                                </p>
                            </div>
                            <div className={'col-5'}>
                                <p>{item.email}</p>
                            </div>
                            <div className={'col-3'}>
                                <p>{item.phone}</p>
                            </div>
                        </Grid>
                    </button>
                )}
            </div>
        </div>
    );
};
