/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { buttonBase } from './Button.style';
import { Clickable } from '../../../interfaces';
import { doubleButtonCss } from './DoubleButton.style';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface DoubleButtonProps {
    customCAN?: customCANProps;
    primaryAriaLabel?: string;
    primaryButtonClassName?: string;
    primaryButtonText: string;
    primaryOnClick?: (e: Clickable) => void;
    primaryTestId?: string;
    secondaryAriaLabel?: string;
    secondaryButtonClassName?: string;
    secondaryButtonText: string;
    secondaryOnClick?: (e: Clickable) => void;
    secondaryTestId?: string;
    title?: string;
    wrapperClassName?: string;
}

/**
 * Component view and component logic
 */
export const DoubleButton = ({
    customCAN,
    primaryAriaLabel,
    primaryButtonClassName = '',
    primaryButtonText,
    primaryOnClick,
    primaryTestId = 'primary-button',
    secondaryAriaLabel,
    secondaryButtonClassName = '',
    secondaryButtonText,
    secondaryOnClick,
    secondaryTestId = 'secondary-button',
    title,
    wrapperClassName = '',
}: DoubleButtonProps) => {
    return (
        <div className={wrapperClassName}>
            <p>{title}</p>
            <div css={css(doubleButtonCss(customCAN))}>
                <button
                    aria-label={primaryAriaLabel}
                    className={`${primaryButtonClassName} primary`}
                    css={css([buttonBase])}
                    data-testid={primaryTestId}
                    onClick={primaryOnClick}
                    type={'button'}
                >
                    {primaryButtonText}
                </button>
                <button
                    aria-label={secondaryAriaLabel}
                    className={`${secondaryButtonClassName} secondary`}
                    css={css([buttonBase])}
                    data-testid={secondaryTestId}
                    onClick={secondaryOnClick}
                    type={'button'}
                >
                    {secondaryButtonText}
                </button>
            </div>
        </div>
    );
};
