import { TFunction } from 'i18next';
import { LpoClaimCauseEnums, OptionType } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const {
    BREAKAGE,
    BURGLARY,
    CONSULTANT,
    CONTRACT,
    DAMAGE,
    DEFECTIVE_WATERPROOFING,
    DOWNFALL,
    ELECTRIC,
    ENGINE,
    ERROR,
    EXPLOSION,
    FALL,
    FIRE,
    FLOOD,
    GLASS,
    HOUSEHOLD_APPLIANCE,
    INJURY,
    INTERRUPTION,
    LEAKAGE,
    LIABILITY,
    LIGHTNING,
    OTHER,
    OUTBREAK,
    PRESSURIZED_PIPE,
    ROBBERY,
    STEM_STOP,
    STORM,
    THEFT_AND_DAMAGE,
    TRANSPORT,
    UNLOCKED_BUILDING,
    UNPRESSURIZED_PIPE,
    WATER,
    WET_ROOM,
} = LpoClaimCauseEnums;

/**
 * Utility logic
 */
const createClaimCauseKey = (id: string) => {
    return `lpo.claimCause.select.${id}`;
};

export const getBreakageOption = (t: TFunction): OptionType => {
    return {
        value: BREAKAGE,
        label: t(createClaimCauseKey(BREAKAGE)),
    };
};

export const getBurglaryOption = (t: TFunction): OptionType => {
    return {
        value: BURGLARY,
        label: t(createClaimCauseKey(BURGLARY)),
    };
};

export const getConsultantOption = (t: TFunction): OptionType => {
    return {
        value: CONSULTANT,
        label: t(createClaimCauseKey(CONSULTANT)),
    };
};

export const getContractOption = (t: TFunction): OptionType => {
    return {
        value: CONTRACT,
        label: t(createClaimCauseKey(CONTRACT)),
    };
};

export const getDamageOption = (t: TFunction): OptionType => {
    return {
        value: DAMAGE,
        label: t(createClaimCauseKey(DAMAGE)),
    };
};

export const getDefectiveWaterproofingOption = (t: TFunction): OptionType => {
    return {
        value: DEFECTIVE_WATERPROOFING,
        label: t(createClaimCauseKey(DEFECTIVE_WATERPROOFING)),
    };
};

export const getDownfallOption = (t: TFunction): OptionType => {
    return {
        value: DOWNFALL,
        label: t(createClaimCauseKey(DOWNFALL)),
    };
};

export const getElectricOption = (t: TFunction): OptionType => {
    return {
        value: ELECTRIC,
        label: t(createClaimCauseKey(ELECTRIC)),
    };
};

export const getEngineOption = (t: TFunction): OptionType => {
    return {
        value: ENGINE,
        label: t(createClaimCauseKey(ENGINE)),
    };
};

export const getErrorOption = (t: TFunction): OptionType => {
    return {
        value: ERROR,
        label: t(createClaimCauseKey(ERROR)),
    };
};

export const getExplosionOption = (t: TFunction): OptionType => {
    return {
        value: EXPLOSION,
        label: t(createClaimCauseKey(EXPLOSION)),
    };
};

export const getFallOption = (t: TFunction): OptionType => {
    return {
        value: FALL,
        label: t(createClaimCauseKey(FALL)),
    };
};

export const getFireOption = (t: TFunction): OptionType => {
    return {
        value: FIRE,
        label: t(createClaimCauseKey(FIRE)),
    };
};

export const getFloodOption = (t: TFunction): OptionType => {
    return {
        value: FLOOD,
        label: t(createClaimCauseKey(FLOOD)),
    };
};

export const getGlassOption = (t: TFunction): OptionType => {
    return {
        value: GLASS,
        label: t(createClaimCauseKey(GLASS)),
    };
};

export const getHouseholdApplianceOption = (t: TFunction): OptionType => {
    return {
        value: HOUSEHOLD_APPLIANCE,
        label: t(createClaimCauseKey(HOUSEHOLD_APPLIANCE)),
    };
};

export const getInjuryOption = (t: TFunction): OptionType => {
    return {
        value: INJURY,
        label: t(createClaimCauseKey(INJURY)),
    };
};

export const getInterruptionOption = (t: TFunction): OptionType => {
    return {
        value: INTERRUPTION,
        label: t(createClaimCauseKey(INTERRUPTION)),
    };
};

export const getLeakageOption = (t: TFunction): OptionType => {
    return {
        value: LEAKAGE,
        label: t(createClaimCauseKey(LEAKAGE)),
    };
};

export const getLiabilityOption = (t: TFunction): OptionType => {
    return {
        value: LIABILITY,
        label: t(createClaimCauseKey(LIABILITY)),
    };
};

export const getLightningOption = (t: TFunction): OptionType => {
    return {
        value: LIGHTNING,
        label: t(createClaimCauseKey(LIGHTNING)),
    };
};

export const getOtherOption = (t: TFunction): OptionType => {
    return {
        value: OTHER,
        label: t(createClaimCauseKey(OTHER)),
    };
};

export const getOutbreakOption = (t: TFunction): OptionType => {
    return {
        value: OUTBREAK,
        label: t(createClaimCauseKey(OUTBREAK)),
    };
};

export const getPressurizedPipeOption = (t: TFunction): OptionType => {
    return {
        value: PRESSURIZED_PIPE,
        label: t(createClaimCauseKey(PRESSURIZED_PIPE)),
    };
};

export const getRobberyOption = (t: TFunction): OptionType => {
    return {
        value: ROBBERY,
        label: t(createClaimCauseKey(ROBBERY)),
    };
};

export const getStemStopOption = (t: TFunction): OptionType => {
    return {
        value: STEM_STOP,
        label: t(createClaimCauseKey(STEM_STOP)),
    };
};

export const getStormOption = (t: TFunction): OptionType => {
    return {
        value: STORM,
        label: t(createClaimCauseKey(STORM)),
    };
};

export const getTheftAndDamageOption = (t: TFunction): OptionType => {
    return {
        value: THEFT_AND_DAMAGE,
        label: t(createClaimCauseKey(THEFT_AND_DAMAGE)),
    };
};

export const getTransportOption = (t: TFunction): OptionType => {
    return {
        value: TRANSPORT,
        label: t(createClaimCauseKey(TRANSPORT)),
    };
};

export const getUnlockedBuildingOption = (t: TFunction): OptionType => {
    return {
        value: UNLOCKED_BUILDING,
        label: t(createClaimCauseKey(UNLOCKED_BUILDING)),
    };
};

export const getUnpressurizedPipeOption = (t: TFunction): OptionType => {
    return {
        value: UNPRESSURIZED_PIPE,
        label: t(createClaimCauseKey(UNPRESSURIZED_PIPE)),
    };
};

export const getWaterOption = (t: TFunction): OptionType => {
    return {
        value: WATER,
        label: t(createClaimCauseKey(WATER)),
    };
};

export const getWetRoomOption = (t: TFunction): OptionType => {
    return {
        value: WET_ROOM,
        label: t(createClaimCauseKey(WET_ROOM)),
    };
};
