/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { MutableRefObject, ReactElement } from 'react';
import './InputField.style';
import { Changable, Focusable } from '../../../interfaces/types/Events.types';
import { Loader } from '../../atoms/spinners/loader/Loader';
import { InputValidationError } from '../../atoms/inputs/InputValidationError';
import { Input, InputModeTypes } from '../../atoms/inputs/Input';
import { InputLabel } from '../../atoms/inputs/InputLabel';
import { inputFieldCss } from './InputField.style';

interface InputFieldProps {
    name: string;
    label: string;
    reference?: (instance: HTMLInputElement) => void;
    placeholder?: string;
    inputType?: 'text' | 'number' | 'email';
    inputFieldWrapper?: string;
    errorClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    append?: string | ReactElement;
    autocomplete?: 'on' | 'off';
    autocompleteValue?: string;
    error?: string | React.ReactElement;
    handleChange: (e: Changable) => void;
    handleBlur: (e: Focusable) => void;
    immRef?: MutableRefObject<HTMLInputElement | null>;
    isLoading?: boolean;
    disabled?: boolean;
    min?: string;
    max?: string;
    inputMode?: InputModeTypes;
}

export const InputField = ({
    name,
    label,
    inputType = 'text',
    placeholder = '',
    inputFieldWrapper = '',
    errorClassName = '',
    inputClassName = '',
    labelClassName,
    autocomplete = 'on',
    autocompleteValue,
    append,
    error,
    handleChange,
    handleBlur,
    reference,
    immRef,
    isLoading = false,
    disabled = false,
    min,
    max,
    inputMode = 'text',
}: InputFieldProps) => {
    return (
        <div
            className={`input-wrapper ${inputFieldWrapper}`}
            css={css(inputFieldCss)}
        >
            <InputLabel className={labelClassName} {...{ label, name }} />
            <div className={'input-loader-wrapper'}>
                <Input
                    {...{
                        disabled,
                        name,
                        label,
                        reference,
                        inputType,
                        placeholder,
                        handleBlur,
                        handleChange,
                        error,
                        min,
                        max,
                        inputClassName,
                        autocomplete,
                        autocompleteValue,
                        immRef,
                        append,
                        inputMode,
                    }}
                />
                {isLoading && (
                    <Loader
                        fixed={false}
                        size={'small'}
                        inline={true}
                        placement={'start'}
                    />
                )}
            </div>
            <InputValidationError
                fieldName={name}
                className={`${errorClassName}`}
                error={error}
            />
        </div>
    );
};
