import { Nullable } from '../../../../../interfaces/types';

export enum LightOfDayKeys {
    DAYLIGHT = 'DAYLIGHT',
    DAWN_DUSK = 'DAWN_DUSK',
    DARK_LIT = 'DARK_LIT',
    DARK_UNLIT = 'DARK_UNLIT',
}

export type LightOfDayModel = Nullable<LightOfDayKeys>;
