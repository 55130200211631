import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { propertySchema } from '../fieldSchemas/propertySchema';
import { unitSchema } from '../fieldSchemas/unitSchema';

/**
 * Validation logic
 */
export const propertyAddressSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...propertySchema(t),
        ...unitSchema(t),
    });
};
