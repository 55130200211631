import React from 'react';
import { EstateExecutorModel } from '../../../../models/person/EstateExecutors';
import { useI18n } from '../../../../hooks/useI18n';
import { I18NOptions, SummaryNavButton, SummaryText } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';

/**
 * Destructure necessary imports
 */
const { DEATH_ESTATE_EXECUTORS } = PersonRoutePaths;

interface DisplayIsEstateExecutorProps {
    model: EstateExecutorModel[];
    onClick?: () => void;
    i18n?: I18NOptions;
}

/**
 * Component view and component logic
 */
export const DisplayEstateExecutors = ({ model, i18n }: DisplayIsEstateExecutorProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace); // Internal I18N instance

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));

    /**
     * Renders
     */
    const renderExecutor = (executor: EstateExecutorModel, idx: number) => (
        <SummaryNavButton
            key={`${TEST_ID_PREFIX.estateExecutors}${idx}`}
            dataTestId={`${TEST_ID_PREFIX.estateExecutors}${idx}`}
            path={DEATH_ESTATE_EXECUTORS}
            className={'mb-1'}
            buttonText={`${executor.firstName} ${executor.lastName}, ${executor.email}, ${executor.phone}`}
        />
    );
    const renderExecutors = () => model.map((executor, idx) => renderExecutor(executor, idx));
    return (
        <>
            <div className={'col-8 divider text-align-left'}>
                <SummaryText text={tWithPrefix(`text.estateExecutor`)} />
                {renderExecutors()}
            </div>
        </>
    );
};
