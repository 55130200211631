import { useEffect, useState } from 'react';
import { DamagePoint } from '../types/SVGProps';

export const useMarkers = (selectedDamagePoints: DamagePoint[], id: string) => {
    const [thisMarkers, setThisMarkers] = useState<DamagePoint[]>([]);
    useEffect(() => {
        setThisMarkers(selectedDamagePoints.filter((dp) => dp.svgId === id));
    }, [selectedDamagePoints, setThisMarkers, id]);
    return thisMarkers;
};
