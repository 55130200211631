import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { useI18n } from '../../../hooks/useI18n';
import { useForm } from 'react-hook-form';
import { CheckboxInput } from '../../../components/inputs/CheckboxInput';
import { LabelDirections } from '../../../models/common/LabelDirections';
import { personActions } from '../../../sagas/person';
import { selectTeeth } from '../../../sagas/selectors/personSelectors';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { PhraseKeys } from '../../../config/phraseKeys';
import {
    Clickable,
    emptyFn,
    Formable,
    inArray,
    isPartChecked,
    PageLayout,
    PartCheckboxGroup,
    SVGClickable,
    TeethImage,
    TeethKeys,
    TeethModel,
    updateSelectableParts,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const {
    CANINES_LOWER_LEFT,
    CANINES_LOWER_RIGHT,
    CANINES_UPPER_LEFT,
    CANINES_UPPER_RIGHT,
    FRONT_TEETH_LOWER_LEFT,
    FRONT_TEETH_LOWER_RIGHT,
    FRONT_TEETH_UPPER_RIGHT,
    FRONT_TEETH_UPPER_LEFT,
    MOLARS_LOWER_LEFT,
    MOLARS_LOWER_RIGHT,
    MOLARS_UPPER_LEFT,
    MOLARS_UPPER_RIGHT,
    PREMOLARS_LOWER_LEFT,
    PREMOLARS_LOWER_RIGHT,
    PREMOLARS_UPPER_LEFT,
    PREMOLARS_UPPER_RIGHT,
    WHOLE_MOUTH,
} = TeethKeys;
const { LEFT, RIGHT } = LabelDirections;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */

export const AccidentTeethPage = () => {
    const dispatch = useDispatch();
    const teeth = useSelector(selectTeeth);
    const { register } = useForm<TeethModel>({});
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.teeth');

    /**
     * Internal helper functions
     */
    const updateTeeth = (key: TeethKeys, selected: boolean) => {
        const parts = updateSelectableParts(key, teeth, selected);

        dispatch(personActions.update({ teeth: parts }));
    };

    const isDisabled = isPartChecked(WHOLE_MOUTH, teeth);

    /**
     * Handle functions
     */
    const handleBackButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(goBack());
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleBlur = emptyFn;

    const handleChange = (e: Formable) => {
        const { checked, name } = e.currentTarget;

        updateTeeth(name as TeethKeys, checked);
    };

    const handleSvgClick = (e: SVGClickable) => {
        const name = e.currentTarget.id as TeethKeys;
        const checked = inArray<TeethKeys>(name, teeth);
        updateTeeth(name, !checked);
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={'page__teeth'}
            {...{ handleBackButton, handleContinueButton }}
        >
            <div className="teeth space-x-5 mt-5">
                <div className={'teeth__image'}>
                    <div className={'wrapper'}>
                        <TeethImage onClick={handleSvgClick} selectedTeeth={teeth} />
                        <div className="footer">
                            <span>{tWithNS.t('image.footer.right')}</span>
                            <span>{tWithNS.t('image.footer.left')}</span>
                        </div>
                    </div>
                </div>
                <div className={'teeth__content'}>
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.WHOLE_MOUTH.description')}
                        label={tWithNS.t('teethGroups.WHOLE_MOUTH.label')}
                        labelFor={WHOLE_MOUTH}
                    >
                        <CheckboxInput
                            id={WHOLE_MOUTH}
                            isChecked={isPartChecked(WHOLE_MOUTH, teeth)}
                            reference={register}
                            {...{ handleBlur, handleChange }}
                        />
                    </PartCheckboxGroup>

                    <h2>{tWithNS.t('teethGroups.heading.upperJaw')}</h2>

                    {/* FRONT_TEETH_UPPER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.FRONT_TEETH_UPPER.description')}
                        label={tWithNS.t('teethGroups.FRONT_TEETH_UPPER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={FRONT_TEETH_UPPER_LEFT}
                            isChecked={isPartChecked(FRONT_TEETH_UPPER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.FRONT_TEETH_UPPER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={FRONT_TEETH_UPPER_RIGHT}
                            isChecked={isPartChecked(FRONT_TEETH_UPPER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.FRONT_TEETH_UPPER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>

                    {/* CANINES_UPPER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.CANINES_UPPER.description')}
                        label={tWithNS.t('teethGroups.CANINES_UPPER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={CANINES_UPPER_LEFT}
                            isChecked={isPartChecked(CANINES_UPPER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.CANINES_UPPER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={CANINES_UPPER_RIGHT}
                            isChecked={isPartChecked(CANINES_UPPER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.CANINES_UPPER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>

                    {/* PREMOLARS_UPPER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.PREMOLARS_UPPER.description')}
                        label={tWithNS.t('teethGroups.PREMOLARS_UPPER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={PREMOLARS_UPPER_LEFT}
                            isChecked={isPartChecked(PREMOLARS_UPPER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.PREMOLARS_UPPER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={PREMOLARS_UPPER_RIGHT}
                            isChecked={isPartChecked(PREMOLARS_UPPER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.PREMOLARS_UPPER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>

                    {/* MOLARS_UPPER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.MOLARS_UPPER.description')}
                        label={tWithNS.t('teethGroups.MOLARS_UPPER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={MOLARS_UPPER_LEFT}
                            isChecked={isPartChecked(MOLARS_UPPER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.MOLARS_UPPER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={MOLARS_UPPER_RIGHT}
                            isChecked={isPartChecked(MOLARS_UPPER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.MOLARS_UPPER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>

                    <h2>{tWithNS.t('teethGroups.heading.lowerJaw')}</h2>

                    {/* FRONT_TEETH_LOWER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.FRONT_TEETH_LOWER.description')}
                        label={tWithNS.t('teethGroups.FRONT_TEETH_LOWER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={FRONT_TEETH_LOWER_LEFT}
                            isChecked={isPartChecked(FRONT_TEETH_LOWER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.FRONT_TEETH_LOWER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={FRONT_TEETH_LOWER_RIGHT}
                            isChecked={isPartChecked(FRONT_TEETH_LOWER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.FRONT_TEETH_LOWER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>

                    {/* CANINES_LOWER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.CANINES_LOWER.description')}
                        label={tWithNS.t('teethGroups.CANINES_LOWER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={CANINES_LOWER_LEFT}
                            isChecked={isPartChecked(CANINES_LOWER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.CANINES_LOWER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={CANINES_LOWER_RIGHT}
                            isChecked={isPartChecked(CANINES_LOWER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.CANINES_LOWER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>

                    {/* PREMOLARS_LOWER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.PREMOLARS_LOWER.description')}
                        label={tWithNS.t('teethGroups.PREMOLARS_LOWER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={PREMOLARS_LOWER_LEFT}
                            isChecked={isPartChecked(PREMOLARS_LOWER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.PREMOLARS_LOWER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={PREMOLARS_LOWER_RIGHT}
                            isChecked={isPartChecked(PREMOLARS_LOWER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.PREMOLARS_LOWER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>

                    {/* MOLARS_LOWER */}
                    <PartCheckboxGroup
                        description={tWithNS.t('teethGroups.MOLARS_LOWER.description')}
                        label={tWithNS.t('teethGroups.MOLARS_LOWER.label')}
                    >
                        <CheckboxInput
                            checkIconClassName={LEFT}
                            id={MOLARS_LOWER_LEFT}
                            isChecked={isPartChecked(MOLARS_LOWER_LEFT, teeth)}
                            label={tWithNS.t('teethGroups.MOLARS_LOWER_LEFT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />

                        <CheckboxInput
                            checkIconClassName={RIGHT}
                            id={MOLARS_LOWER_RIGHT}
                            isChecked={isPartChecked(MOLARS_LOWER_RIGHT, teeth)}
                            label={tWithNS.t('teethGroups.MOLARS_LOWER_RIGHT.label')}
                            reference={register}
                            {...{ handleBlur, handleChange, isDisabled }}
                        />
                    </PartCheckboxGroup>
                </div>
            </div>
        </PageLayout>
    );
};
