import { FileStatusEnums } from '../enums';
import { PFError } from './PFError.model';
import { FileCategoryEnums } from '../../modules';
import { Nullable } from '../types';

export const MAX_FILE_SIZE_ATTACHMENT = '100000000';

export interface FileError extends PFError {}

export interface FileModel {
    id: string;
    name: string;
    type: string;
    size: number;
    status: FileStatusEnums;
    category?: FileCategoryEnums;
    definition?: Nullable<string>;
    requireDefinition?: boolean;
    amount?: Nullable<number>;
    requireAmount?: boolean;
    src: {
        file: File;
    };
    errors: FileError[];
    externalId?: string;
}

export enum FileErrorTypes {
    UNSUPPORTED = 'UNSUPPORTED',
    MAX_FILE_SIZE = 'MAX_FILE_SIZE',
    MULTI_MAX_FILE_SIZE = 'MULTI_MAX_FILE_SIZE',
    MULTI_MAX_COUNT = 'MULTI_MAX_COUNT',
    MULTI_NOT_ALLOWED = 'MULTI_NOT_ALLOWED',
}

export interface FileHandlers {
    onSuccess?: (files: Array<FileModel>) => void;
    onError?: (files: Array<FileModel>) => void;
}
