import { css } from '@emotion/react';
import { defaultFocusStyle, primary } from '../../variables';

export const pageCss = css({
    display: 'flex',
    flex: '0 0 100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 0.625rem',
    textAlign: 'center',
    width: '100%',
    color: primary,
    margin: '0 auto',

    '& .page-header': {
        marginTop: 0,
    },

    '& .page-content-wrapper': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
    },

    '& .page-footer': {
        minHeight: 138,
    },
    '&.hide-content': {
        display: 'none',
    },

    p: {
        '&:focus': {
            ...defaultFocusStyle,
        },
    },

    '@media print': {
        '&.no-print': {
            display: 'none',
        },
        '&.hide-content': {
            display: 'contents',
        },
    },

    '@media screen and (min-width: 576px)': {
        padding: '0 1.875rem',

        '& .page-footer': {
            marginBottom: '1.5rem',
            marginTop: '1.5rem',
        },
    },

    '@media screen and (min-width: 992px)': {
        flex: '0 0 83.33333%',
        maxWidth: 900,

        '& .page-header': {
            marginBottom: '1.5rem',
            marginTop: '1.5rem',
        },
    },

    '@media screen and (min-width: 1200px)': {
        flex: '0 0 66.66667%',
    },

    '&.landing-page': {
        '& .page-content-wrapper': {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
});
