import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    ForeignRegistrationIcon,
    initVehicleModel,
    OtherInjuriesIcon,
    UnidentifiedTypeKeys,
    UnregisteredIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { selectSelectedVehicle, selectVehicles } from '../../../../../sagas/selectors/motorSelectors';
import { motorActions } from '../../../../../sagas/motor';

/**
 * Destructure necessary imports
 */
const { FOREIGN_VEHICLE, OTHER, UNREGISTERED_VEHICLE } = UnidentifiedTypeKeys;

/**
 * Page view and page logic
 */
export const drivingVehiclesUnidentifiedTypePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const selectedVehicle = selectSelectedVehicle(state);
    const options = [
        {
            id: FOREIGN_VEHICLE,
            name: t(`motor.driving.unidentifiedType.select.${FOREIGN_VEHICLE}`),
            icon: <ForeignRegistrationIcon />,
        },
        {
            id: UNREGISTERED_VEHICLE,
            name: t(`motor.driving.unidentifiedType.select.${UNREGISTERED_VEHICLE}`),
            icon: <UnregisteredIcon />,
        },
        { id: OTHER, name: t(`motor.driving.unidentifiedType.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_UNIDENTIFIED_TYPE,
        stateKey: 'vehicles',
        i18n: {
            ns: 'motor.driving.unidentifiedType',
        },
        selectorValue: selectVehicles(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, motorActions.update, {
                    selectedVehicle: { ...initVehicleModel, ...selectedVehicle, unidentifiedType: option.id },
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
        isSelected: (id) => {
            return id === (selectedVehicle?.unidentifiedType ?? undefined);
        },
        testInitState: (key) => ({
            selectedVehicleId: 'id-1',
            selectedVehicle: {
                ...initVehicleModel,
                internalId: 'id-1',
                unidentifiedType: key,
            },
        }),
    };
};
