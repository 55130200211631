export enum CountryKeys {
    NORWAY = 1,
    SWEDEN = 2,
    DENMARK = 3,
    FINLAND = 4,
    UK = 5,
}

export enum CountryPathKeys {
    NORWAY = 'nor/nb',
    SWEDEN = 'swe/se',
}
