import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { freightWeightSchema } from '../fieldSchemas/freightWeightSchema';

/**
 * Validation logic
 */
export const vehicleFreightWeightSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...freightWeightSchema(t),
    });
};
