import { Nullable } from '../../../../../interfaces';

export interface ClaimantPositionModel {
    ownership: Nullable<number>;
    position: Nullable<string>;
}

export const initClaimantPosition = {
    ownership: null,
    position: null,
};
