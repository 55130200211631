import React from 'react';
import { Changable, SingleCheckbox } from '@protectorinsurance/ds-can';
import { trueFalseToYesNo } from '../../../../utils/displayUtils';
import { LpoSummaryProps } from '../../../../types/SummaryProps';
import { lpoActions } from '../../../../sagas/lpo';
import { useDispatch, useSelector } from 'react-redux';
import { renderPrivacy } from './start/RenderPrivacy';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { LpoRoutePaths } from '../../../../config/wizardRouter/lpoWizardRoutes';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { END_FINAL_SUMMARY } = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayContactPolice = ({ model, t, tWithNS, printSummary, handleClick }: LpoSummaryProps) => {
    const dispatch = useDispatch();
    const customCAN = useSelector(selectCustomCAN);
    const { acceptedPoliceContact } = model;

    const handleCheckbox = (e: Changable) => {
        dispatch(lpoActions.update({ acceptedPoliceContact: e.target.checked }));
    };

    return printSummary ? (
        <>
            <SingleCheckbox
                name={'acceptedPoliceContact'}
                checked={acceptedPoliceContact}
                handleChange={handleCheckbox}
                wrapperClass={'col-12 divider'}
                {...{ customCAN }}
            >
                {tWithNS('policeContact.text')}
            </SingleCheckbox>
        </>
    ) : (
        renderPrivacy({
            inputWrapperClass: 'col-12 divider text-align-left',
            textKey: tWithNS('policeContact.heading.title'),
            dataTestId: TEST_ID_PREFIX.acceptedPoliceContact,
            buttonText: t(trueFalseToYesNo(acceptedPoliceContact)),
            path: END_FINAL_SUMMARY,
            handleClick,
        })
    );
};
