import { VehicleTypeKeys } from '../models';

export const VehicleTypeCodesFinlandMap = new Map<
    VehicleTypeKeys,
    Array<string> | string
>([
    [VehicleTypeKeys.CAR, 'M1'],
    [VehicleTypeKeys.MOTORCYCLE, 'L3e'],
    [VehicleTypeKeys.MOPED, ['L1e', 'L1e-B']],
    [VehicleTypeKeys.BUS, ['M2', 'M3']],
    [VehicleTypeKeys.EXCAVATOR, 'MTK'],
    [VehicleTypeKeys.TRUCK, ['N2', 'N3']],
    [VehicleTypeKeys.LIGHT_TRUCK, 'N1'],
    [VehicleTypeKeys.TRAILER, ['O1', 'O2', 'O3', 'O4']],
    [VehicleTypeKeys.TERRAIN_VEHICLE, ['L6e', 'L7e-A']],
    [VehicleTypeKeys.SUP_400_TERRAIN_VEHICLE, 'L7e'],
    [VehicleTypeKeys.TRACTOR, ['T2', 'T3', 'T4', 'C1', 'C2', 'C3', 'C4']],
]);
