import React from 'react';
import { BankAccountInformationModel } from 'models/person/BankAccountInformation';
import { useI18n } from '../../../../hooks/useI18n';
import { I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayBankAccountInformationChildProps {
    model: Partial<BankAccountInformationModel>;
    i18n?: I18NOptions;
}

export const DisplayBankAccountInformationChild = ({ model, i18n }: DisplayBankAccountInformationChildProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace); // Internal I18N instance

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 spacing header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix('text.bankAccountInformation')}
                </p>
            </div>
            <div className={'col-8 no-border divider text-align-left'}>
                <SummaryItemText
                    label={tWithPrefix('text.name')}
                    text={`${model.ownerGivenName} ${model.ownerFamilyName}`}
                />
            </div>
            <div className={'col-4 no-border divider text-align-left'}>
                <SummaryItemText
                    label={tWithPrefix('text.bankName')}
                    text={`${model.bankName ? model.bankName : '-'}`}
                />
            </div>
            <div className={'col-4 no-border divider text-align-left'}>
                <SummaryItemText
                    label={tWithPrefix('text.clearingNumber')}
                    text={`${model.clearingNumber ? model.clearingNumber : '-'}`}
                />
            </div>
            <div className={'col-8 no-border divider text-align-left'}>
                <SummaryItemText
                    label={tWithPrefix('text.accountNumber')}
                    text={`${model.accountNumber ? model.accountNumber : '-'}`}
                />
            </div>
        </>
    );
};
