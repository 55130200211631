/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { summaryItemTextCss } from './SummaryItemText.style';
import { Clickable, Nullable } from '../../../interfaces/types';
import { EditButton } from '../buttons/EditButton';
import { action } from '@storybook/addon-actions';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface SummaryItemTextProps {
    className?: string;
    customCAN?: customCANProps;
    dangerouslySetInnerHTML?: boolean;
    dataTestId?: string;
    editable?: boolean;
    label: Nullable<string>;
    onClick?: (e: Clickable) => void;
    text?: Nullable<string>;
}

/**
 * Component view and component logic
 */
export const SummaryItemText = ({
    className,
    customCAN,
    dangerouslySetInnerHTML = false,
    dataTestId,
    editable,
    label,
    text,
}: SummaryItemTextProps) => {
    if (text && dangerouslySetInnerHTML) {
        const div = document.createElement('div');
        div.innerHTML = text;
        const htmlText = div.textContent || div.innerText || '';
        text = htmlText.replace(/\n/g, '<br/>');
        div.remove();
    }

    return (
        <div className={`${className}`}>
            <p
                className={`summary-title ${text ? 'margin-bottom' : ''}`}
                css={css(summaryItemTextCss(customCAN))}
                tabIndex={0}
            >
                {label ? label : ''}
                {editable && (
                    <EditButton onClick={action('Edit Button Clicked')} />
                )}
            </p>
            {dangerouslySetInnerHTML && text && (
                <p
                    className={'summary-text'}
                    data-testId={dataTestId}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            )}
            {!dangerouslySetInnerHTML && text && (
                <p
                    className={'summary-text'}
                    data-hj-suppress
                    data-testid={dataTestId}
                    tabIndex={0}
                >
                    {text}
                </p>
            )}
        </div>
    );
};
