import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { claimVehicleMakeSchema } from '../fieldSchemas/claimVehicleMakeSchema';
import { vehicleSchema } from '../fieldSchemas/vehicleSchema';

export const claimVehicleInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...claimVehicleMakeSchema(t),
        ...vehicleSchema(t),
    });
};
