/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { linkCss } from './Link.style';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface LinkButtonProps {
    className?: string;
    customCAN?: customCANProps;
    linkText: string;
    url: string;
}

/**
 * Component view and component logic
 */
export const Link = ({
    className = '',
    customCAN,
    linkText,
    url,
}: LinkButtonProps) => {
    return (
        <a
            href={url}
            target={'_black'}
            className={`link ${className}`}
            rel="noopener noreferrer"
            css={css(linkCss(customCAN))}
        >
            {linkText}
        </a>
    );
};
