import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import {
    AccidentIcon,
    Carousel,
    CarouselItem,
    CategoryKeys,
    DeceaseIcon,
    IllnessIcon,
    PageLayout,
} from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { personActions } from '../../../sagas/person';
import { commonActions } from '../../../sagas/common';
import { flowActions } from '../../../sagas/flow';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { resetActions } from '../../../sagas/reset';
import { goBack } from 'connected-react-router';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { ACCIDENT, DEATH, ILLNESS } = CategoryKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const CategoryPage = () => {
    const dispatch = useDispatch();
    const category = useSelector(selectCategory);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.category');

    const handleBackButton = () => {
        dispatcherWithPromise(dispatch, flowActions.update, null).then(() => dispatch(goBack()));
    };

    const options = [
        { icon: <AccidentIcon />, id: ACCIDENT, name: tWithNS.t(`select.${ACCIDENT}`) },
        { icon: <IllnessIcon />, id: ILLNESS, name: tWithNS.t(`select.${ILLNESS}`) },
        { icon: <DeceaseIcon />, id: DEATH, name: tWithNS.t(`select.${DEATH}`) },
    ];

    const selected = (id: CategoryKeys, e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const option = options.find((x) => x.id === id);

        if (option) {
            if (option.id !== category) {
                dispatch(resetActions.resetPages());
                dispatch(wizardActions.update({ backToPrev: false }));
            }

            dispatcherWithPromise(dispatch, personActions.update, { category: option.id })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                .then(() => dispatch(wizardActions.goToNext(option.id)));
        }
    };

    const renderSelection = options.map((option, idx) => {
        return (
            <CarouselItem
                {...option}
                handleClick={selected}
                isSelected={option.id === category}
                key={idx}
                {...{ customCAN }}
            />
        );
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <Carousel>{renderSelection}</Carousel>
        </PageLayout>
    );
};
