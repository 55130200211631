import { StateResetCbFnProps } from '../../interfaces/types/StateReset.types';
import { get, set } from 'object-path-immutable';
import { stateResetObjectIterateAndReset } from './stateResetObjectIterateAndReset';

export const stateReset = <T = any>(
    state: T,
    { key, value, depth }: StateResetCbFnProps
): T => {
    if (depth) {
        const tree = stateResetObjectIterateAndReset(get(state, key), value);
        return set(state, key, tree);
    } else {
        return set(state, key, value);
    }
};
