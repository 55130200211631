import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    Datable,
    FlowKeys,
    getLocaleFromUrl,
    is,
    LobKeys,
    LpoClaimCauseEnums,
    MuiDatePicker,
    PageLayout,
    TimePicker,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { commonActions } from '../../../sagas/common';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { updateDateByDate, updateDateByHour, updateDateByMinutes } from '../../../utils/date/dateUtils';
import { selectClaimCause, selectClaimDate } from 'sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../sagas/lpo';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { flowActions } from '../../../sagas/flow';
import { getMomentLocale } from '../../../utils/getMomentLocale';
import moment from 'moment';

/**
 * Destructure models, phrases etc.
 */

const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { CLAIM_DATE, CLAIM_TIME } = FormFieldNames;
const { MANDATORY_DATE_FIELD } = FormFieldErrors;
const { FALL } = LpoClaimCauseEnums;
const { LIABILITY_CLAIMANT_INFORMATION } = LpoRoutePaths;
const { LIABILITY } = LobKeys;
const { LIABILITY: LIABILITY_FLOW, PROPERTY } = FlowKeys;

/**
 * Page view and page logic
 */
export const StartClaimDatePage = () => {
    const dispatch = useDispatch();
    const claimDate = useSelector(selectClaimDate);
    const claimCause = useSelector(selectClaimCause);
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const [date, setDate] = useState<Datable>(claimDate || null);
    const [time, setTime] = useState<Datable>(null);
    const [errors, setErrors] = useState<string[]>([]);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.start.claimDate');
    const handleBackButton = useGoBack();

    useEffect(() => {
        setDate(claimDate);
        setTime(claimDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();

        if (is(date, null)) {
            setErrors([t(MANDATORY_DATE_FIELD)]);
            return;
        }

        // Set time to 00:00 if it's not provided
        const newTime = time || moment().hour(0).minute(0);
        const newFullDate = updateDateByHour(claimDate, newTime.hour());
        const newFullDateWithMinutes = updateDateByMinutes(newFullDate, newTime.minute());

        let nextAction = wizardActions.goToNext();
        let flow = PROPERTY;
        if (is(lob, LIABILITY)) {
            nextAction = wizardActions.goTo(LIABILITY_CLAIMANT_INFORMATION);
            flow = LIABILITY_FLOW;
        }

        dispatcherWithPromise(dispatch, lpoActions.update, { claimDate: newFullDateWithMinutes })
            .then(() => dispatcherWithPromise(dispatch, flowActions.update, flow))
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    const selectedDate = (date: Datable) => {
        if (date) {
            const newFullDate = updateDateByDate(claimDate, date);
            setDate(date);
            setErrors((prev) => {
                return prev ? prev.filter((p) => p !== t(MANDATORY_DATE_FIELD)) : [];
            });
            dispatch(lpoActions.update({ claimDate: newFullDate }));
        }
    };

    const onHourSelect = (newHour: number) => {
        const newFullDate = updateDateByHour(claimDate, newHour);
        setTime(newFullDate);
        dispatch(lpoActions.update({ claimDate: newFullDate }));
    };

    const onMinSelect = (newMin: number) => {
        const newFullDate = updateDateByMinutes(claimDate, newMin);
        setTime(newFullDate);
        dispatch(lpoActions.update({ claimDate: newFullDate }));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            {is(claimCause, FALL) && (
                <TimePicker
                    className={'mb-1 mt-5 self-center'}
                    hoursLabel={t('timePicker.label.hours')}
                    minutesLabel={t('timePicker.label.minutes')}
                    mode={'normal'}
                    name={CLAIM_TIME}
                    time={time || null}
                    {...{ customCAN, onHourSelect, onMinSelect }}
                />
            )}
            <MuiDatePicker
                disableFuture={true}
                error={errors.find((e: string) => e === t(MANDATORY_DATE_FIELD))}
                locale={getMomentLocale(getLocaleFromUrl())}
                name={CLAIM_DATE}
                onChange={selectedDate}
                {...{ customCAN, date }}
            />
        </PageLayout>
    );
};
