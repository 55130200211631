import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'mdn-polyfills/String.prototype.includes';
import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/Element.prototype.classList';
import 'core-js/es/array';
import 'core-js/es/symbol';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ga } from './utils/ga';
import { isProd, isTest } from './utils/env';
import { configureStore } from './store/configureStore';
import './styles/main.scss';
import './initializers/locale';
import './initializers/pfDesignSystem';
import './initializers/yup';
import './initializers/i18n';
import './initializers/hotjar';
import 'initializers/moment';
import 'normalize.css';
import { Loader } from './components/loader/Loader';
import { ErrorBoundary } from './components/ErrorBoundary';
import { initApm } from './initializers/apm';

const history = createBrowserHistory();
const initState = (window as any).initialReduxState;
const store = configureStore(initState, history);

if (isProd()) {
    // Init Google Analytics
    ga(history);
}
if (isProd() || isTest()) {
    // Init Elastic API
    initApm();
}

ReactDOM.render(
    <Suspense fallback={<Loader fixed={false} />}>
        <ErrorBoundary>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        </ErrorBoundary>
    </Suspense>,
    document.getElementById('root')
);

if (!isProd()) {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
