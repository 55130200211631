/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { Changable, Focusable } from '../../../interfaces/types/Events.types';
import { Nullable } from '../../../interfaces/types/Nullable.types';
import { textareaCss } from './Textarea.style';

interface TextareaProps {
    name: string;
    handleChange: (e: Changable) => void;
    handleBlur: (e: Focusable) => void;
    reference?: (instance: HTMLTextAreaElement) => void;
    placeholder?: string;
    rows?: number;
    maxHeight?: number;
    value: Nullable<string>;
}

export const Textarea = ({
    name,
    handleChange,
    handleBlur,
    reference,
    placeholder = '',
    rows = 3,
    maxHeight = 300,
    value = null,
}: TextareaProps) => {
    const enhancedHandleChange = (e: Changable) => {
        // Something is wonky with the scrollbar, so we need offset
        const offset = 2;

        // Automatically resize the textarea when user changes the text
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight + offset}px`;

        // Only scale to maxHeight
        e.target.style.height = `${Math.min(
            e.target.scrollHeight + offset,
            maxHeight
        )}px`;

        // Return the event so component can have other change events
        return handleChange(e);
    };

    return (
        <textarea
            className={'form-control'}
            id={name}
            name={name}
            data-testid={name}
            rows={rows}
            onChange={enhancedHandleChange}
            placeholder={placeholder}
            onBlur={handleBlur}
            value={value || ''}
            ref={reference}
            css={css(textareaCss)}
        />
    );
};
