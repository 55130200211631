import { css } from '@emotion/react';
import { danger } from '../../variables';

export const inputValidationErrorCss = css({
    color: danger,
    display: 'flex',
    fontSize: '0.75rem',
    maxWidth: '200px',
    minHeight: '36px',
    paddingTop: '5px',
    textAlign: 'start',
    width: 'auto',

    '& .error-msg': {
        display: 'flex',
        alignItems: 'center',
    },

    '& .error-icon': {
        width: 'auto !important',

        '& svg': {
            '& path': {
                stroke: danger,
            },
        },
    },
});
