export function businessNumberDenmarkValidateChecksum(businessNumber: string) {
    const lastChar = businessNumber.substring(
        businessNumber.length - 1,
        businessNumber.length
    );
    const checkSum = getMod11Check(
        businessNumber.substring(0, businessNumber.length - 1)
    );
    return (
        (lastChar === '-' && checkSum == 10) || checkSum === parseInt(lastChar)
    );
}

function getMod11Check(query: string) {
    let sum = 0;
    let weight = 2;
    const ints = Array(query.length)
        .fill(0)
        .map(() => new Array(3).fill(0));
    for (let i = ints.length - 1; i >= 0; i--) {
        ints[i][0] = parseInt(query.substring(i, i + 1));
        ints[i][1] = weight;
        if (weight == 7) {
            weight = 2;
        } else {
            weight++;
        }
        ints[i][2] = ints[i][0] * ints[i][1];
        sum += ints[i][2];
    }
    const rem = sum % 11;
    return rem == 0 ? 0 : 11 - rem;
}
