import React from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import { selectFlow } from '../../../sagas/selectors/flowSelectors';
import { selectClaimReporterRole } from '../../../sagas/selectors/personSelectors';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import {
    ClaimReporterRoleKeys,
    Clickable,
    FlowKeys,
    InsuranceTypeKeys,
    PageLayout,
    StartSummary,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { personActions } from '../../../sagas/person';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { ACCIDENT, CHILD, DEATH, ILLNESS } = FlowKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { MUNICIPALITY_SCHOOL } = InsuranceTypeKeys;
const { ON_BEHALF_CHILD } = ClaimReporterRoleKeys;
const { ACCIDENT_PRIVACY, ILLNESS_PRIVACY } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const StartSummaryPage = () => {
    const dispatch = useDispatch();
    const flow = useSelector(selectFlow);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.start.summary');
    const whoReport = useSelector(selectClaimReporterRole);
    const customCAN = useSelector(selectCustomCAN);

    const handleBackButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(goBack());
    };

    // Prefix language
    let prefix: string;
    if (flow === ILLNESS) prefix = 'illness';
    else if (flow === DEATH) prefix = 'death';
    else if (flow === CHILD) prefix = 'child';
    else prefix = 'accident';

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let nextAction = wizardActions.goToNext(flow);
        if (flow === ILLNESS && whoReport === ON_BEHALF_CHILD) {
            nextAction = wizardActions.goTo(ILLNESS_PRIVACY);
        }
        if (flow === ACCIDENT && whoReport === ON_BEHALF_CHILD) {
            nextAction = wizardActions.goTo(ACCIDENT_PRIVACY);
            dispatch(
                personActions.update({
                    insuranceType: MUNICIPALITY_SCHOOL,
                })
            );
        }

        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <StartSummary
                avgMin={tWithNS.t(`${prefix}.avgMin`)}
                description={tWithNS.t(`${prefix}.description`)}
                sessionText={tWithNS.t(`${prefix}.sessionInfo`)}
                subTitle={tWithNS.t('body.subTitle')}
                title={tWithNS.t('body.title')}
                {...{ customCAN }}
            />
        </PageLayout>
    );
};
