import { MotorRoutePaths } from '../../config/wizardRouter/motorWizardRoutes';
import { PersonRoutePaths } from '../../config/wizardRouter/personWizardRoutes';
import {
    CategoryKeys,
    ClaimLocationKeys,
    FlowKeys,
    FlowModel,
    MotorClaimCauseKeys,
    ObjectWithDynamicKey,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { DRIVING, END, NO_VEHICLES, PARKED_VEHICLE, START, VEHICLES } = FlowKeys;
const {
    ANIMAL_COLLISION,
    CYCLIST_COLLISION,
    DITCH_COLLISION,
    ENGINE,
    FIRE,
    HEAD_ON,
    LANE_CHANGE,
    OTHER,
    OVERTAKING,
    PARKED,
    PARKING,
    PEDESTRIAN_COLLISION,
    REAR_ENDED,
    REVERSING,
    STATIONARY_OBJECT_COLLISION,
    THEFT_AND_DAMAGE,
} = MotorClaimCauseKeys;
const { INTERSECTION, PARKING_OTHER, ROAD, ROUNDABOUT } = ClaimLocationKeys;

/**
 * Utility logic
 */
export const progressBarUtil = (
    flow: FlowModel | MotorClaimCauseKeys | ClaimLocationKeys | CategoryKeys,
    path: MotorRoutePaths | PersonRoutePaths | string,
    progressBarRoutes: ObjectWithDynamicKey
): number => {
    if (flow && progressBarRoutes) {
        const progress = progressBarRoutes[flow];
        const total = progress.length;
        const current = progress.indexOf(path) + 1;
        const parkedOrNoVehicleFlow: (MotorClaimCauseKeys | FlowKeys | ClaimLocationKeys | CategoryKeys)[] = [
            DITCH_COLLISION,
            ANIMAL_COLLISION,
            STATIONARY_OBJECT_COLLISION,
            PEDESTRIAN_COLLISION,
            THEFT_AND_DAMAGE,
            ENGINE,
            PARKING,
            FIRE,
            OTHER,
        ];
        const drivingClaimLocationFlow: (MotorClaimCauseKeys | FlowKeys | ClaimLocationKeys | CategoryKeys)[] = [
            ROAD,
            ROUNDABOUT,
            INTERSECTION,
            PARKING_OTHER,
        ];
        const drivingClaimCauseFlow: (MotorClaimCauseKeys | FlowKeys | ClaimLocationKeys | CategoryKeys)[] = [
            REVERSING,
            LANE_CHANGE,
            OVERTAKING,
            HEAD_ON,
            REAR_ENDED,
            PARKED,
        ];

        /**
         * Return percentages based on routing
         */
        const percent0To25 = ((current / total) * 100) / 4;
        const percent25To50 = ((current / total) * 100) / 4 + 25;
        const percent50To65 = ((current / total) * 100) / (100 / 15) + 50;
        const percent65To75 = ((current / total) * 100) / 10 + 65;
        const percent25To75 = ((current / total) * 100) / 2 + 25;
        const percent75To100 = ((current / total) * 100) / 4 + 75;

        /**
         * Routing for different flows
         */
        if (flow === START) {
            return percent0To25;
        }

        if (flow === PARKED_VEHICLE || flow === DRIVING || flow === NO_VEHICLES) {
            return 25;
        }

        if (flow === CYCLIST_COLLISION || flow === VEHICLES) {
            return percent25To50;
        }

        if (drivingClaimLocationFlow.includes(flow)) {
            return percent50To65;
        }

        if (drivingClaimCauseFlow.includes(flow)) {
            return percent65To75;
        }

        if (parkedOrNoVehicleFlow.includes(flow)) {
            return percent25To75;
        }

        if (flow === END) {
            return percent75To100;
        }

        return (current / total) * 100;
    }
    return 0;
};
