import { css } from '@emotion/react';
import { inputFieldPlaceholder, primary, secondary } from '../../variables';

export const textareaCss = css({
    border: `1px solid ${inputFieldPlaceholder}`,
    borderRadius: 2,
    color: primary,
    fontWeight: 400,
    lineHeight: '1.5',
    padding: '0.5rem 1rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    width: '100%',

    '&:focus': {
        borderColor: secondary,
        boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.25)',
        outline: 0,
    },
});
