import React from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { selectClaimDescription } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import {
    ClaimDescriptionModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    LobKeys,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { claimDescriptionSchema } from '../../../validations/schemas/claimDescriptionSchema';
import { useGoBack } from '../../../hooks/useGoBack';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { FormFieldNames } from '../../../config/formFieldNames';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { claimDescriptionUtil } from '../../../utils/claimDescriptionUtil';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_LABEL,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { LIABILITY } = LobKeys;
const { DYN_CAROUSEL_END_HAS_CONTRACT_WITH_OTHER_PART } = LpoRoutePaths;
const { CLAIM_DESCRIPTION } = FormFieldNames;

/**
 * Page view and page logic
 */
export const EndClaimDescriptionPage = () => {
    const dispatch = useDispatch();
    const claimDescription = useSelector(selectClaimDescription);
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.end.claimDescription');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimDescriptionModel>({
        resolver: yupResolver(claimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            claimDescription,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ claimDescription }: ClaimDescriptionModel, e?: FormChangeable) => {
        e?.preventDefault();
        let nextAction = wizardRouterActions.goToNext();
        if (is(lob, LIABILITY)) {
            nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_END_HAS_CONTRACT_WITH_OTHER_PART);
        }
        dispatcherWithPromise(dispatch, lpoActions.update, { claimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={CLAIM_DESCRIPTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.claimDescription}
                                errorMessage={errors.claimDescription?.message}
                                id={CLAIM_DESCRIPTION}
                                inputFieldWrapper={'col-12'}
                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                reference={ref}
                                rows={10}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
