import React from 'react';
import {
    createHourMinArray,
    HOURS_IN_DAY,
} from '../../../../utils/dateTimeUtils';

export const HourOptions = () => {
    return (
        <>
            {createHourMinArray(HOURS_IN_DAY).map((value) => (
                <option value={value} key={`hours-${value}`}>
                    {value}
                </option>
            ))}
        </>
    );
};
