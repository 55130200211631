/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Fragment, ReactNode } from 'react';
import { Grid } from '../grid/Grid';
import { incidentSummaryCss } from './IncidentSummary.style';
import { Nullable } from '../../../interfaces/types';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface IncidentSummaryProps {
    children?: ReactNode;
    claimCause?: Nullable<string>;
    claimDate?: Nullable<string>;
    className?: string;
    customCAN?: customCANProps;
    incidentAt: string;
    incidentBy: string;
    incidentInvolving: string;
    incidentOn: string;
    location?: Nullable<string>;
    locationUrl?: string;
    locationText?: Nullable<string>;
    make?: Nullable<string>;
    registrationNumber?: Nullable<string>;
    type?: Nullable<string>;
}

/**
 * Component view and component logic
 */
export const IncidentSummary = ({
    children,
    claimCause,
    claimDate,
    className,
    customCAN,
    incidentAt,
    incidentBy,
    incidentInvolving,
    incidentOn,
    location,
    locationUrl,
    make,
    registrationNumber,
    type,
}: IncidentSummaryProps) => {
    return (
        <div
            className={`${className}`}
            css={css(incidentSummaryCss(customCAN))}
        >
            <Grid>
                <div className={'col-4 justify-content-center'}>
                    <div className={'registration-number'}>
                        <span tabIndex={0}>
                            {registrationNumber
                                ? registrationNumber.toLocaleUpperCase()
                                : '-'}
                        </span>
                    </div>
                </div>
                <div className={'col-8 justify-content-center'}>
                    <h2 className={'vehicle-info'} tabIndex={0}>
                        {make}
                        {type ? (make ? ` ${type}` : type) : type}
                    </h2>
                </div>
                {claimDate && (
                    <Fragment>
                        <div
                            className={'col-4 justify-content-center'}
                            tabIndex={0}
                        >
                            <p className={'incident-title'}>{incidentOn}</p>
                        </div>
                        <div
                            className={'col-8 justify-content-center'}
                            tabIndex={0}
                        >
                            <p className={'incident-text'}>{claimDate}</p>
                        </div>
                    </Fragment>
                )}
                {location && (
                    <Fragment>
                        <div
                            className={'col-4 justify-content-center'}
                            tabIndex={0}
                        >
                            <p className={'incident-title'}>{incidentAt}</p>
                        </div>
                        <a
                            className={'col-8 justify-content-center location'}
                            href={locationUrl}
                            target={'_blank'}
                            tabIndex={0}
                            rel="noreferrer"
                        >
                            <p className={'incident-text'}>{location}</p>
                        </a>
                    </Fragment>
                )}
                {claimCause && (
                    <Fragment>
                        <div
                            className={'col-4 justify-content-center'}
                            tabIndex={0}
                        >
                            <p className={'incident-title'}>{incidentBy}</p>
                        </div>
                        <div
                            className={'col-8 justify-content-center'}
                            tabIndex={0}
                        >
                            <p className={'incident-text'}>{claimCause}</p>
                        </div>
                    </Fragment>
                )}
                {children && (
                    <Fragment>
                        <div className={'col-4 align-self-start'} tabIndex={0}>
                            <p className={'incident-title'}>
                                {incidentInvolving}
                            </p>
                        </div>
                        <div className={'col-8'}>{children}</div>
                    </Fragment>
                )}
            </Grid>
        </div>
    );
};
