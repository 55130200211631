import { css } from '@emotion/react';
import { primary, secondary, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const claimVehicleSummaryCss = (company?: customCANProps) =>
    css({
        '& .regnr': {
            '& span': {
                borderRadius: 2,
                boxShadow: '0 0 5px rgba(68, 85, 98, 0.5)',
                maxWidth: 150,
                padding: '0.5rem 1rem',
                textAlign: 'center',
            },
        },

        '& .vehicle-information, & .button-container': {
            paddingTop: '1rem',
            textAlign: 'left',

            '@media print, screen and (min-width: 768px)': {
                paddingTop: 'unset',
            },
        },

        '& .need-towing-button': {
            border: `1px solid ${primary}`,
            display: 'inline',
            minWidth: '100%',
            padding: '0.25rem 0.75rem',

            '@media print, screen and (min-width: 768px)': {
                minWidth: 120,
            },

            '&:hover, &:focus': {
                backgroundColor: 'rgba(125, 136, 144, 0.1)',
                borderColor: 'rgba(125, 136, 144, 0.1)',
                color: primary,
                outline: 0,
            },

            '&.active': {
                backgroundColor: company ? company.color : secondary,
                color: company ? company.textColor : white,
            },
        },

        '& .damages': {
            border: `1px solid ${primary}`,
            marginBottom: '2rem',
            padding: '0 1rem',

            '& .damages-list': {
                '& .damages-item': {
                    margin: '0.5rem 0',
                    textAlign: 'left',

                    '& span': {
                        alignItems: 'center',
                        backgroundColor: company ? company.color : secondary,
                        borderRadius: '50%',
                        color: white,
                        display: 'inline-flex',
                        height: 25,
                        justifyContent: 'center',
                        marginRight: '0.25rem',
                        width: 25,
                    },

                    '& p': {
                        display: 'inline',
                    },
                },
            },
        },
    });
