import { FormChangeable } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const claimDescriptionUtil = (e: FormChangeable) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    return window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
    });
};
