import { css } from '@emotion/react';

export const partnerWorkshopsLinkCardCss = css({
    background: '#fff',
    border: '1px solid #e8ecef',
    boxShadow:
        '0px 2px 4px rgba(119, 140, 163, 0.06),0px 4px 6px rgba(119, 140, 163, 0.1)',
    padding: '32px 32px',
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',

    '.partner-workshops-link-card__title': {
        fontSize: '24px',
        padding: '12px',
        fontWeight: 'bold',
    },

    '.partner-workshops-link-card__description': {
        width: '100%',
        display: 'block',
    },

    '.partner-workshops-link-card__link': {
        marginTop: '32px',
        width: '100%',
    },
});
