import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';

/**
 * Method logic
 */
export function secureEndingEmail(this: StringSchema, message: string = yupCustomLocale.string.email) {
    // @ts-ignore
    return this.test('secureEndingEmail', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        return !value ? true : !value.toLowerCase().endsWith('.s');
    });
}
