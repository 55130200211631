import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { speedSchema } from '../fieldSchemas/speedSchema';

/**
 * Validation logic
 */
export const speedInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...speedSchema(t),
    });
};
