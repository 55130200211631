import { css } from '@emotion/react';
import { grey, primary, white } from '../../variables';

export const circleIconButtonCss = css({
    alignItems: 'center',
    background: white,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',

    '&.add-btn': {
        marginTop: '1rem',
    },

    '&:focus': {
        outline: '1px solid',
    },

    '& .icon': {
        alignItems: 'center',
        border: `1px solid ${grey}`,
        borderRadius: '50%',
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        width: 50,

        '& svg': {
            height: 50,
            width: 50,
        },

        '&.small': {
            backgroundColor: primary,
            border: `2px solid ${primary}`,
            height: 30,
            width: 30,

            '& img, & svg': {
                height: 15,
                width: 15,
            },
        },
    },

    '&.small': {
        '& .icon': {
            '& svg': {
                height: 25,
                width: 25,
            },
        },
    },

    '& .label': {
        margiLeft: '3rem',
    },
});

export const circleIconLabelCss = css({
    alignItems: 'baseline',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center !important',
    paddingLeft: '1rem',
});
