import { ClaimantModel } from '../../interfaces';
import { dtParse } from '../date';

export const formatClaimant = (claimant: ClaimantModel) => {
    const {
        city,
        dateOfBirth,
        email,
        firstName,
        lastName,
        nationalIdentity,
        phone,
        street,
        zip,
    } = claimant;
    return `${firstName} ${lastName}${
        nationalIdentity ? ` (${nationalIdentity})` : ''
    }${dateOfBirth ? ` (${dtParse(dateOfBirth)})` : ''}${
        email ? `, ${email}` : ''
    }${phone ? `, ${phone}` : ''}${street ? `, ${street}` : ''}${
        zip ? `, ${zip}` : ''
    }${city ? `, ${city}` : ''}`;
};
