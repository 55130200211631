import React, { Fragment } from 'react';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';
import { SummaryNavButton } from '../../atoms/summaryItems/SummaryNavButton';
import { ListProps } from '../../../modules/can/common/interfaces/types/List.types';

interface SummaryArrayItemProps extends ListProps {
    list: Array<any>;
    dataTestId: string;
    labelTextKey: string;
    buttonText: string;
    inputWrapperClass?: string;
}

export const SummaryArrayItem = ({
    list,
    show,
    handleClick,
    path,
    labelTextKey,
    dataTestId,
    inputWrapperClass,
}: SummaryArrayItemProps) => {
    return (
        <Fragment>
            {show && (
                <div className={inputWrapperClass}>
                    <SummaryText text={labelTextKey} />
                    {list.map((item, idx: number) => {
                        return (
                            <SummaryNavButton
                                key={idx}
                                buttonText={item.name}
                                {...{ path, handleClick, dataTestId }}
                            />
                        );
                    })}
                </div>
            )}
        </Fragment>
    );
};
