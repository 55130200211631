export enum TeethKeys {
    WHOLE_MOUTH = 'WHOLE_MOUTH',
    FRONT_TEETH_UPPER_LEFT = 'FRONT_TEETH_UPPER_LEFT',
    FRONT_TEETH_UPPER_RIGHT = 'FRONT_TEETH_UPPER_RIGHT',
    CANINES_UPPER_LEFT = 'CANINES_UPPER_LEFT',
    CANINES_UPPER_RIGHT = 'CANINES_UPPER_RIGHT',
    PREMOLARS_UPPER_LEFT = 'PREMOLARS_UPPER_LEFT',
    PREMOLARS_UPPER_RIGHT = 'PREMOLARS_UPPER_RIGHT',
    MOLARS_UPPER_LEFT = 'MOLARS_UPPER_LEFT',
    MOLARS_UPPER_RIGHT = 'MOLARS_UPPER_RIGHT',
    FRONT_TEETH_LOWER_LEFT = 'FRONT_TEETH_LOWER_LEFT',
    FRONT_TEETH_LOWER_RIGHT = 'FRONT_TEETH_LOWER_RIGHT',
    CANINES_LOWER_LEFT = 'CANINES_LOWER_LEFT',
    CANINES_LOWER_RIGHT = 'CANINES_LOWER_RIGHT',
    PREMOLARS_LOWER_LEFT = 'PREMOLARS_LOWER_LEFT',
    PREMOLARS_LOWER_RIGHT = 'PREMOLARS_LOWER_RIGHT',
    MOLARS_LOWER_LEFT = 'MOLARS_LOWER_LEFT',
    MOLARS_LOWER_RIGHT = 'MOLARS_LOWER_RIGHT',
}

export type TeethModel = Array<TeethKeys>;
