import { OrgnrModel } from '../../models/orgnr';
import { takeLatest } from 'redux-saga/effects';
import { api, APPLICATION_JSON_HEADER, HTTP_STATUS } from 'utils/api';
import { NODE_API_BASE_URL } from '../../config/api';
import { FormFieldErrors } from '../../config/formFieldNames';
import { actionWithPromise, PFError, PFRequestError, Rejectable, Resolvable } from '@protectorinsurance/ds-can';
import { call, put } from 'typed-redux-saga';

/**
 * Action types
 */
export enum OrgnrServiceActionTypes {
    REQUEST = '@app/orgnr/REQUEST',
    SUCCESS = '@app/orgnr/SUCCESS',
    FAILURE = '@app/orgnr/FAILURE',
    ERROR = '@app/orgnr/ERROR',
}

/**
 * Action Definitions
 */
export interface OrgnrServiceAction {
    type: OrgnrServiceActionTypes;
    data?: OrgnrModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const orgnrServiceActions = {
    request: actionWithPromise<OrgnrServiceActionTypes, OrgnrState>(OrgnrServiceActionTypes.REQUEST),
    success: actionWithPromise<OrgnrServiceActionTypes, OrgnrState>(OrgnrServiceActionTypes.SUCCESS),
    failure: actionWithPromise<OrgnrServiceActionTypes, PFRequestError>(OrgnrServiceActionTypes.FAILURE),
    error: actionWithPromise<OrgnrServiceActionTypes, Partial<PFError>>(OrgnrServiceActionTypes.ERROR),
};

export interface OrgnrState {
    country: string;
    orgnr: string;
    name?: string;
}

export const orgnrInitState = {};

export default function (state = orgnrInitState, { type, data }: OrgnrServiceAction) {
    switch (type) {
        case OrgnrServiceActionTypes.SUCCESS:
            return data;
        case OrgnrServiceActionTypes.ERROR:
            return { errors: [data] };
        default:
            return state;
    }
}

/**
 * Saga watchers
 */
export const orgnrWatcher = function* () {
    yield takeLatest(OrgnrServiceActionTypes.REQUEST, orgnrServiceSagas.request);
};

/**
 * Saga functions
 */
export const orgnrServiceSagas = {
    *request({ data }: any) {
        try {
            const res: any = yield* call(api.get, `orgnr/${data.country}/${data.orgnr}`, {
                baseURL: NODE_API_BASE_URL,
                headers: APPLICATION_JSON_HEADER,
            });

            if (res.status === HTTP_STATUS.NO_CONTENT) {
                yield put(
                    orgnrServiceActions.failure({
                        name: 'No Content',
                        message: FormFieldErrors.NO_JWT_RECEIVED,
                        status: res.stats,
                        statusText: res.statusText,
                    })
                );
            } else {
                const responseData = { orgnr: data.orgnr, country: data.country, name: res.data.data.name };
                yield put(orgnrServiceActions.success(responseData));
            }
        } catch (e) {
            yield put(orgnrServiceActions.failure(e as any));
        }
    },
};
