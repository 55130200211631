/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactNode } from 'react';
import { Grid } from '../grid/Grid';
import { DocumentIcon } from '../../atoms/icons/BaseIcons';
import { claimListItemCss } from './ClaimListItem.style';
import { Clickable } from '../../../interfaces/types';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface ClaimListItemProps {
    children?: ReactNode;
    claims?: any;
    claimsTitle: string;
    customCAN?: customCANProps;
    handleClick(e: Clickable): void;
}

/**
 * Component view and component logic
 */
export const ClaimListItem = ({
    children,
    claimsTitle,
    customCAN,
    handleClick,
}: ClaimListItemProps) => {
    return (
        <button
            onClick={handleClick}
            className={'col-12 padding-top padding-bottom all-around-border'}
            css={css(claimListItemCss(customCAN))}
        >
            <Grid>
                <div className={'col-1'}>
                    <DocumentIcon />
                </div>
                <div className={'col-11 justify-content-center'}>
                    <p>{claimsTitle}</p>
                    {children}
                </div>
            </Grid>
        </button>
    );
};
