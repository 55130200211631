/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { Fragment } from 'react';
import { Clickable, Nullable } from '../../../interfaces';
import {
    circleIconButtonCss,
    circleIconLabelCss,
} from './CircleIconButton.style';

interface CircleIconButtonProps {
    icon: string | object;
    ariaLabel: string;
    label?: string;
    id?: string;
    name?: string;
    className?: string;
    dataTestId?: string;
    handleClick?(e: Clickable, id?: Nullable<number> | string): void;
}

export const CircleIconButton = ({
    icon,
    ariaLabel,
    label,
    id,
    name,
    className = '',
    dataTestId,
    handleClick,
}: CircleIconButtonProps) => {
    const i =
        typeof icon === 'string' ? (
            <img src={icon} height={'100px'} alt={name || ''} />
        ) : (
            icon
        );
    const onClick = (e: Clickable) =>
        id !== undefined || id !== null
            ? handleClick && handleClick(e, id)
            : handleClick && handleClick(e);

    return (
        <Fragment>
            <button
                type={'submit'}
                className={`circle-icon-button ${className}`}
                data-testid={dataTestId}
                onClick={onClick}
                aria-label={ariaLabel}
                css={css(circleIconButtonCss)}
            >
                {icon && <div className={'icon'}>{i}</div>}
                {label && (
                    <label
                        htmlFor={ariaLabel}
                        css={css(circleIconLabelCss)}
                        className={'col-3'}
                    >
                        {label}
                    </label>
                )}
            </button>
        </Fragment>
    );
};
