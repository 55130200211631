export enum CountryCodeISOEnums {
    NORWAY = 'nor',
    SWEDEN = 'swe',
    DENMARK = 'dnk',
    FINLAND = 'fin',
    UK = 'gbr',
}

export const CountryCodeISOArray = [
    CountryCodeISOEnums.NORWAY,
    CountryCodeISOEnums.SWEDEN,
    CountryCodeISOEnums.DENMARK,
    CountryCodeISOEnums.FINLAND,
    CountryCodeISOEnums.UK,
];
