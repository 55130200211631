import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { MotorClaimCauseListTypeModel } from 'models/motor/MotorClaimCause';
import { MotorClaimCauseKeys } from '@protectorinsurance/ds-can';

export const claimCauseListUtil = (
    claimCauseList: MotorClaimCauseListTypeModel,
    page: MotorRoutePaths,
    claimCause: MotorClaimCauseKeys
): MotorClaimCauseListTypeModel => {
    if (claimCauseList.some((claimCause) => claimCause.page === page)) {
        const claimCauseIndex = claimCauseList.findIndex(
            (cause: { claimCause: MotorClaimCauseKeys; page: MotorRoutePaths }) => cause.page === page
        );
        claimCauseList[claimCauseIndex] = {
            claimCause,
            page,
        };
        return claimCauseList;
    } else {
        return [...claimCauseList, { claimCause, page }];
    }
};
