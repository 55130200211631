/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ButtonProps } from '../../../interfaces';
import { buttonBase } from './Button.style';
import { actionButtonCss } from './ActionButton.style';

export const ActionButton = ({
    ariaLabel,
    className = '',
    customCAN,
    disabled = false,
    onClick,
    testId = 'action-button',
    type = 'button',
    value,
}: ButtonProps) => {
    return (
        <button
            aria-label={ariaLabel}
            className={`${className}`}
            css={css([buttonBase, actionButtonCss(customCAN)])}
            data-testid={testId}
            {...{ disabled, onClick, type }}
        >
            {value}
        </button>
    );
};
