/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { FileModel } from '../../../interfaces';
import {
    DocumentExcelIcon,
    DocumentIcon,
    DocumentImageIcon,
    DocumentPDFIcon,
    DocumentWordIcon,
} from '../icons/BaseIcons';
import { FileTypeEnums } from '../../../interfaces';
import { summaryDocumentItemCss } from './SummaryDocumentItem.style';

/**
 * Interface
 */
interface SummaryDocumentItemProps {
    file: FileModel;
    className?: string;
    dataTestId?: string;
    showDocumentIcon?: boolean;
}

/**
 * Destructure necessary imports
 */
const { DOC, DOCX, JPEG, PDF, PNG, XLS, XLSX } = FileTypeEnums;

/**
 * Component view and component logic
 */
export const SummaryDocumentItem = ({
    className,
    dataTestId,
    file,
    showDocumentIcon = true,
}: SummaryDocumentItemProps) => {
    const findDocumentIcon = (document: FileModel) => {
        if (document.type === DOC || document.type === DOCX) {
            return <DocumentWordIcon />;
        }

        if (document.type === XLS || document.type === XLSX) {
            return <DocumentExcelIcon />;
        }

        if (document.type === PDF) {
            return <DocumentPDFIcon />;
        }

        if (document.type === JPEG || document.type === PNG) {
            return <DocumentImageIcon />;
        }

        return <DocumentIcon />;
    };

    return (
        <div className={`${className}`} css={css(summaryDocumentItemCss)}>
            {showDocumentIcon && findDocumentIcon(file)}
            <p data-hj-suppress data-testid={dataTestId} tabIndex={0}>
                {file.name}
            </p>
        </div>
    );
};
