import { Nullable } from '../../../../../interfaces';

export type FlowModel = Nullable<FlowKeys>;

export enum FlowKeys {
    START = 'START',
    DRIVING = 'DRIVING',
    VEHICLES = 'VEHICLES',
    NO_VEHICLES = 'NO_VEHICLES',
    PARKED_VEHICLE = 'PARKED_VEHICLE',
    END = 'END',
    ACCIDENT = 'ACCIDENT',
    DEATH = 'DEATH',
    ILLNESS = 'ILLNESS',
    CHILD = 'CHILD',
    CHANGE_OF_OWNERSHIP = 'CHANGE_OF_OWNERSHIP',
    GLASS = 'GLASS',
    LEGAL = 'LEGAL',
    LIABILITY = 'LIABILITY',
    PROPERTY = 'PROPERTY',
}
