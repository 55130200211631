export enum LpoClaimCauseEnums {
    ACCIDENTAL_DAMAGE = 'ACCIDENTAL_DAMAGE',
    ACCIDENTAL_DAMAGE_GLASS = 'ACCIDENTAL_DAMAGE_GLASS',
    ACCIDENT_DURING_TRANSPORT = 'ACCIDENT_DURING_TRANSPORT',
    AIRCRAFT = 'AIRCRAFT',
    ALLERGIC_REACTION = 'ALLERGIC_REACTION',
    ANIMAL = 'ANIMAL',
    ARSON = 'ARSON',
    ASBESTOS_EXPOSURE = 'ASBESTOS_EXPOSURE',
    ASSAULT = 'ASSAULT',
    ASSAULT_COLLEAGUE = 'ASSAULT_COLLEAGUE',
    ASSAULT_SERVICE_USER = 'ASSAULT_SERVICE_USER',
    BREACH = 'BREACH',
    BREAKAGE = 'BREAKAGE',
    BUILDER = 'BUILDER',
    BULLYING = 'BULLYING',
    BURGLARY = 'BURGLARY',
    BUSINESS = 'BUSINESS',
    CALCULATION_ERROR = 'CALCULATION_ERROR',
    CARELESSNESS = 'CARELESSNESS',
    CARELESSNESS_CIGARETTE = 'CARELESSNESS_CIGARETTE',
    CHEMICALS = 'CHEMICALS',
    CHILD_WELFARE = 'CHILD_WELFARE',
    COLLISION = 'COLLISION',
    COMPUTER_FRAUD = 'COMPUTER_FRAUD',
    COMPUTER_VIRUS = 'COMPUTER_VIRUS',
    CONSULTANT = 'CONSULTANT',
    CONTACT_WITH_SHARPS = 'CONTACT_WITH_SHARPS',
    CONTRACT = 'CONTRACT',
    CONTRACTOR_NEGLIGENCE = 'CONTRACTOR_NEGLIGENCE',
    CRASH_INJURY = 'CRASH_INJURY',
    CRIME = 'CRIME',
    CRUSH_INJURY = 'CRUSH_INJURY',
    CUSTOMS_CLEARANCE = 'CUSTOMS_CLEARANCE',
    CUSTOMER_SUPPLIERS = 'CUSTOMER_SUPPLIERS',
    CUT = 'CUT',
    DAMAGE = 'DAMAGE',
    DEFECTIVE_EQUIPMENT = 'DEFECTIVE_EQUIPMENT',
    DEFECTIVE_MACHINERY = 'DEFECTIVE_MACHINERY',
    DEFECTIVE_PPE = 'DEFECTIVE_PPE',
    DEFECTIVE_PREMISES = 'DEFECTIVE_PREMISES',
    DEFECTIVE_WATERPROOFING = 'DEFECTIVE_WATERPROOFING',
    DELAY = 'DELAY',
    DELIVERY_INJURY = 'DELIVERY_INJURY',
    DENIAL_OF_ACCESS = 'DENIAL_OF_ACCESS',
    DESIGN_ERROR = 'DESIGN_ERROR',
    DIGGING = 'DIGGING',
    DISEASE = 'DISEASE',
    DISPUTE = 'DISPUTE',
    DOWNFALL = 'DOWNFALL',
    DRAINAGE = 'DRAINAGE',
    EARTHQUAKE = 'EARTHQUAKE',
    ELECTIONS = 'ELECTIONS',
    ELECTRIC = 'ELECTRIC',
    ELECTRICAL = 'ELECTRICAL',
    EMBEZZLEMENT = 'EMBEZZLEMENT',
    ENGINE = 'ENGINE',
    ERROR = 'ERROR',
    ESCAPE_OIL = 'ESCAPE_OIL',
    ESCAPE_WATER = 'ESCAPE_WATER',
    EXPLOSION = 'EXPLOSION',
    EXTERNAL_DAMAGE = 'EXTERNAL_DAMAGE',
    EXTERNAL_PROPERTY_DAMAGE = 'EXTERNAL_PROPERTY_DAMAGE',
    FALL = 'FALL',
    FALLING_OBJECT = 'FALLING_OBJECT',
    FALLING_TREE = 'FALLING_TREE',
    FIDELITY = 'FIDELITY',
    FINANCIAL_LOSS = 'FINANCIAL_LOSS',
    FIRE = 'FIRE',
    FLOOD = 'FLOOD',
    FLOOD_COASTAL = 'FLOOD_COASTAL',
    FLOOD_RIVER = 'FLOOD_RIVER',
    FRAUD = 'FRAUD',
    FORGERY = 'FORGERY',
    FROST = 'FROST',
    FROZEN_PIPES = 'FROZEN_PIPES',
    GLASS = 'GLASS',
    GOODS_DAMAGE = 'GOODS_DAMAGE',
    GOODS_LOSS = 'GOODS_LOSS',
    GROUNDING = 'GROUNDING',
    HACKING = 'HACKING',
    HAIL = 'HAIL',
    HEAVE = 'HEAVE',
    HIGHWAY = 'HIGHWAY',
    HORSEPLAY = 'HORSEPLAY',
    HOT_WORK = 'HOT_WORK',
    HOUSEHOLD_APPLIANCE = 'HOUSEHOLD_APPLIANCE',
    INCORRECT_PROFESSIONAL_ADVICE = 'INCORRECT_PROFESSIONAL_ADVICE',
    INJURY = 'INJURY',
    INJURY_FOSTER_CHILDREN = 'INJURY_FOSTER_CHILDREN',
    INJURY_FROM_RTA = 'INJURY_FROM_RTA',
    INTERNAL_DAMAGE = 'INTERNAL_DAMAGE',
    INTERRUPTION = 'INTERRUPTION',
    INVALID_MANAGEMENT_DECISION = 'INVALID_MANAGEMENT_DECISION',
    LACK_OF_MAINTENANCE = 'LACK_OF_MAINTENANCE',
    LACK_OF_SECURITY = 'LACK_OF_SECURITY',
    LAND_CHARGES = 'LAND_CHARGES',
    LANDSLIDE = 'LANDSLIDE',
    LANDSLIP = 'LANDSLIP',
    LEAKAGE = 'LEAKAGE',
    LEAKY_ROOF = 'LEAKY_ROOF',
    LIABILITY = 'LIABILITY',
    LIBEL = 'LIBEL',
    LIFTING_INJURY = 'LIFTING_INJURY',
    LIGHTNING = 'LIGHTNING',
    MALICIOUS_DAMAGE = 'MALICIOUS_DAMAGE',
    MANUAL_HANDLING = 'MANUAL_HANDLING',
    NEGLIGENCE_EMPLOYEE = 'NEGLIGENCE_EMPLOYEE',
    NOISE_INDUCED_HEARING_LOSS = 'NOISE_INDUCED_HEARING_LOSS',
    OTHER = 'OTHER',
    OTHER_DISEASE = 'OTHER_DISEASE',
    OUTBREAK = 'OUTBREAK',
    OWN_VEHICLE = 'OWN_VEHICLE',
    PHYSICAL_ABUSE = 'PHYSICAL_ABUSE',
    POOR_HOUSEKEEPING = 'POOR_HOUSEKEEPING',
    POWER_FAILURE = 'POWER_FAILURE',
    PRESSURIZED_PIPE = 'PRESSURIZED_PIPE',
    PRODUCT_LIABILITY = 'PRODUCT_LIABILITY',
    PROPERTY = 'PROPERTY',
    PUBLIC_HEALTH = 'PUBLIC_HEALTH',
    PUBLIC_PROCUREMENT = 'PUBLIC_PROCUREMENT',
    PUBLIC_UTILITIES = 'PUBLIC_UTILITIES',
    RAIN = 'RAIN',
    RIOT = 'RIOT',
    ROBBERY = 'ROBBERY',
    RSI = 'RSI',
    SELF_IGNITION = 'SELF_IGNITION',
    SENTENCE_DAMAGE = 'SENTENCE_DAMAGE',
    SETBACK = 'SETBACK',
    SEXUAL_ABUSE = 'SEXUAL_ABUSE',
    SHOCK = 'SHOCK',
    SINKHOLE = 'SINKHOLE',
    SINKING = 'SINKING',
    SLANDER = 'SLANDER',
    SLIP_ON_DEBRIS = 'SLIP_ON_DEBRIS',
    SLIP_ON_FLOOR = 'SLIP_ON_FLOOR',
    SLIP_ON_ICE = 'SLIP_ON_ICE',
    SLIP_ON_STEPS = 'SLIP_ON_STEPS',
    SLIP_ON_WET_FLOOR = 'SLIP_ON_WET_FLOOR',
    SMOKE_INHALATION = 'SMOKE_INHALATION',
    SNOW = 'SNOW',
    SPONTANEOUS_COMBUSTION = 'SPONTANEOUS_COMBUSTION',
    SPREAD_FIRE = 'SPREAD_FIRE',
    SPRINKLER_LEAKAGE = 'SPRINKLER_LEAKAGE',
    STEM_STOP = 'STEM_STOP',
    STORM = 'STORM',
    STORM_FLOOD = 'STORM_FLOOD',
    STRESS = 'STRESS',
    STRUCK_BY_OBJECT = 'STRUCK_BY_OBJECT',
    STRUCK_BY_VEHICLE = 'STRUCK_BY_VEHICLE',
    TECHNICAL_FAILURE = 'TECHNICAL_FAILURE',
    TECHNICAL_FAILURE_GATE = 'TECHNICAL_FAILURE_GATE',
    TECHNICAL_FAILURE_WASHING_MACHINE = 'TECHNICAL_FAILURE_WASHING_MACHINE',
    TEMPERATURE_ERROR = 'TEMPERATURE_ERROR',
    TEMPERATURE_INJURY = 'TEMPERATURE_INJURY',
    TERRORISM = 'TERRORISM',
    THEFT_AND_DAMAGE = 'THEFT_AND_DAMAGE',
    TP_VEHICLE = 'TP_VEHICLE',
    TRANSPORT = 'TRANSPORT',
    TRAPPED_PHYSICAL_INJURY = 'TRAPPED_PHYSICAL_INJURY',
    TRAPPED_PSYCHOLOGICAL_INJURY = 'TRAPPED_PSYCHOLOGICAL_INJURY',
    TREE = 'TREE',
    TRIP_ON_EQUIPMENT = 'TRIP_ON_EQUIPMENT',
    TWIST = 'TWIST',
    UNKNOWN = 'UNKNOWN',
    UNLOCKED_BUILDING = 'UNLOCKED_BUILDING',
    UNPRESSURIZED_PIPE = 'UNPRESSURIZED_PIPE',
    VANDALISM = 'VANDALISM',
    VIOLENCE = 'VIOLENCE',
    VOLCANIC_ERUPTION = 'VOLCANIC_ERUPTION',
    WAREHOUSE = 'WAREHOUSE',
    WATER = 'WATER',
    WATER_SUPPLY_FAILURE = 'WATER_SUPPLY_FAILURE',
    WEIGHT_SNOW = 'WEIGHT_SNOW',
    WET_ROOM = 'WET_ROOM',
    WIND = 'WIND',
}
