import { Nullable } from '../../../../../interfaces';

export enum InsuranceTypeKeys {
    COMPANY = 'COMPANY',
    DIAGNOSIS = 'DIAGNOSIS_INSURANCE',
    GROUP_ACCIDENT = 'GROUP_ACCIDENT',
    HEALTH = 'HEALTH',
    ILLNESS = 'ILLNESS_INSURANCE',
    MUNICIPALITY_SCHOOL = 'MUNICIPALITY_SCHOOL',
    PRIVATE = 'PRIVATE',
    SCHOOL_KINDER_GARDEN = 'SCHOOL_KINDER_GARDEN',
    WORK_COMP = 'WORK_COMP',
}

export type InsuranceTypeModel = Nullable<InsuranceTypeKeys>;
