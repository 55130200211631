import { ssnDenmarkMatchPattern } from './ssnDenmarkMatchPattern';
import { ssnDenmarkHasValidDate } from './ssnDenmarkHasValidDate';

export function ssnDenmarkValidator(ssn: string) {
    return {
        isValid: function isValid() {
            if (!ssnDenmarkMatchPattern(ssn)) {
                return false;
            }

            const cleaned = ssn.replace(/[-]/, '');
            if (cleaned.length !== 10) {
                return false;
            }

            return ssnDenmarkHasValidDate(ssn);
        },
    };
}
