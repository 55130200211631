import React from 'react';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { displayPerson, formatSeller, formatGenericName, is, GenericNameModel } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { renderSellerInformation } from './components/changeOfOwnership/RenderSellerInformation';
import { renderPolicyNumber } from './components/changeOfOwnership/RenderPolicyNumber';
import { renderLocation } from './components/changeOfOwnership/RenderLocation';
import { renderUnit } from './components/end/RenderUnit';
import classNames from 'classnames';
import { renderClaimDate } from './components/changeOfOwnership/RenderClaimDate';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { renderReportedToSellerDate } from './components/changeOfOwnership/RenderReportedToSellerDate';
import { renderClaimDescription } from './components/changeOfOwnership/RenderClaimDescription';
import { renderCost } from './components/changeOfOwnership/RenderCost';
import { renderAttachment } from './components/end/RenderAttachment';
import { renderReporterInformation } from './components/end/RenderReporterInformation';
import { renderClaimantInformation } from './components/changeOfOwnership/RenderClaimantInformation';

/**
 * Destructure necessary imports
 */
const {
    CHANGE_OF_OWNERSHIP_ACCIDENT_LOCATION,
    CHANGE_OF_OWNERSHIP_CLAIM_DATE,
    CHANGE_OF_OWNERSHIP_CLAIM_DESCRIPTION,
    CHANGE_OF_OWNERSHIP_CLAIMANT_INFORMATION,
    CHANGE_OF_OWNERSHIP_COST,
    CHANGE_OF_OWNERSHIP_POLICY_NUMBER,
    CHANGE_OF_OWNERSHIP_PROPERTY_ADDRESS,
    CHANGE_OF_OWNERSHIP_REPORTED_TO_SELLER_DATE,
    CHANGE_OF_OWNERSHIP_REPORTER_INFORMATION,
    CHANGE_OF_OWNERSHIP_SELLER_INFORMATION,
    CHANGE_OF_OWNERSHIP_UPLOAD_ATTACHMENT,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayChangeOfOwnershipSummary = ({ model, t, tWithNS, handleClick, upload }: LpoSummaryProps) => {
    const {
        accidentLocation,
        claimDate,
        claimDescription,
        companyInformation,
        cost,
        reportedToSellerDate,
        reporterInformation,
        sellerInformation,
        claimantInformation,
    } = model;
    const { attachment } = upload;

    // Classes
    const locationClass = classNames(
        `${!is(accidentLocation.unit, null) ? 'col-6' : 'col-12'}`,
        'divider text-align-left'
    );

    return (
        <>
            {renderSellerInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('sellerInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.sellerInformation,
                buttonText: sellerInformation ? formatSeller(sellerInformation) : '-',
                path: CHANGE_OF_OWNERSHIP_SELLER_INFORMATION,
                handleClick,
            })}

            {renderClaimantInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimantInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimantInformation,
                buttonText: claimantInformation ? formatGenericName(claimantInformation as GenericNameModel) : '-',
                path: CHANGE_OF_OWNERSHIP_CLAIMANT_INFORMATION,
                handleClick,
            })}

            {renderPolicyNumber({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyNumber.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyNumber,
                buttonText: companyInformation.policyNumber ? companyInformation.policyNumber : '-',
                path: CHANGE_OF_OWNERSHIP_POLICY_NUMBER,
                handleClick,
            })}

            {renderLocation(
                {
                    inputWrapperClass: locationClass,
                    textKey: tWithNS('accidentLocation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.accidentLocation,
                    buttonText: accidentLocation.note
                        ? accidentLocation.note
                        : `${accidentLocation.latitude}, ${accidentLocation.longitude}`,
                    path: CHANGE_OF_OWNERSHIP_ACCIDENT_LOCATION,
                    handleClick,
                },
                accidentLocation
            )}

            {renderUnit(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('propertyAddress.heading.title'),
                    dataTestId: TEST_ID_PREFIX.propertyAddress,
                    buttonText: accidentLocation.unit ? accidentLocation.unit : '-',
                    path: CHANGE_OF_OWNERSHIP_PROPERTY_ADDRESS,
                    handleClick,
                },
                accidentLocation
            )}

            {renderClaimDate({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtParseAndFormat(claimDate) : '-',
                path: CHANGE_OF_OWNERSHIP_CLAIM_DATE,
                handleClick,
            })}

            {renderReportedToSellerDate({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('reportedToSellerDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.reportedToSellerDate,
                buttonText: reportedToSellerDate ? dtParseAndFormat(reportedToSellerDate) : '-',
                path: CHANGE_OF_OWNERSHIP_REPORTED_TO_SELLER_DATE,
                handleClick,
            })}

            {renderClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimDescription,
                    buttonText: claimDescription ? claimDescription : '-',
                    path: CHANGE_OF_OWNERSHIP_CLAIM_DESCRIPTION,
                    handleClick,
                },
                claimDescription
            )}

            {renderCost({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('cost.heading.title'),
                dataTestId: TEST_ID_PREFIX.cost,
                buttonText: cost ? cost.toString() : '-',
                path: CHANGE_OF_OWNERSHIP_COST,
                handleClick,
            })}

            {renderAttachment(
                {
                    textKey: `${tWithNS('uploadAttachment.heading.title')}`,
                    dataTestId: TEST_ID_PREFIX.uploadAttachments,
                    path: CHANGE_OF_OWNERSHIP_UPLOAD_ATTACHMENT,
                    buttonText: '',
                },
                `${tWithNS('uploadAttachment.heading.title')}`,
                TEST_ID_PREFIX.uploadAttachments,
                attachment
            )}

            {renderReporterInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('reporterInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.reporterInformation,
                buttonText: displayPerson(reporterInformation),
                path: CHANGE_OF_OWNERSHIP_REPORTER_INFORMATION,
                handleClick,
            })}
        </>
    );
};
