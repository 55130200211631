import React from 'react';
import { FormFieldNames } from '../../config/formFieldNames';
import { PhraseKeys } from '../../config/phraseKeys';
import { HospitalisationInformationModel } from '../../models/person/HospitalisationInformation';
import { Controller, useFormContext } from 'react-hook-form';
import { useI18n } from '../../hooks/useI18n';
import {
    AddIcon,
    CircleIconButton,
    FormChangeable,
    getLocaleFromUrl,
    Grid,
    HiddenInputSubmit,
    MuiDatePickerInput,
    MuiTextInput,
    Nullable,
} from '@protectorinsurance/ds-can';
import { getMomentLocale } from '../../utils/getMomentLocale';
import { useSelector } from 'react-redux';
import { selectCustomCAN } from '../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary values
 */
const { END_DATE, HOSPITAL_NAME, START_DATE } = FormFieldNames;
const { ADD_BUTTON, END_DATE_LABEL, HOSPITAL_NAME_LABEL, HOSPITAL_NAME_PLACEHOLDER, START_DATE_LABEL } = PhraseKeys;

/**
 * Interfaces and Types
 */
interface HospitalisationFormModel extends Omit<HospitalisationInformationModel, 'startDate' | 'endDate'> {
    endDate: Nullable<Date>;
    startDate: Nullable<Date>;
}

interface HospitalisationFormProps {
    onSubmit: (values: HospitalisationFormModel) => void;
}

/**
 * Component view and component logic
 */
export const HospitalisationInformationForm = ({ onSubmit }: HospitalisationFormProps) => {
    const customCAN = useSelector(selectCustomCAN);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
        watch,
    } = useFormContext<HospitalisationFormModel>();
    const { t } = useI18n();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        setValue(id, value, { shouldValidate: true });
    };

    const values = watch();

    const handleStartDateChange = (newValue: Nullable<Date>) => {
        setValue('startDate', newValue, { shouldValidate: true });
    };

    const handleEndDateChange = (newValue: Nullable<Date>) => {
        setValue('endDate', newValue, { shouldValidate: true });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <HiddenInputSubmit />
            <Grid className={'align-center'}>
                <MuiDatePickerInput
                    error={!!errors.startDate}
                    errorMessage={errors.startDate?.message}
                    id={START_DATE}
                    inputFieldWrapper={'col-4'}
                    label={t(START_DATE_LABEL)}
                    locale={getMomentLocale(getLocaleFromUrl())}
                    onChange={handleStartDateChange}
                    value={values.startDate}
                    {...{ customCAN }}
                />
                <MuiDatePickerInput
                    error={!!errors.endDate}
                    errorMessage={errors.endDate?.message}
                    id={END_DATE}
                    inputFieldWrapper={'col-4'}
                    label={t(END_DATE_LABEL)}
                    locale={getMomentLocale(getLocaleFromUrl())}
                    onChange={handleEndDateChange}
                    value={values.endDate}
                    {...{ customCAN }}
                />
                <Controller
                    control={control}
                    name={HOSPITAL_NAME}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            error={!!errors.hospitalName}
                            errorMessage={errors.hospitalName?.message}
                            id={HOSPITAL_NAME}
                            inputFieldWrapper={'col-4'}
                            label={t(HOSPITAL_NAME_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(HOSPITAL_NAME_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <CircleIconButton
                    ariaLabel={t(ADD_BUTTON)}
                    className={'flex flex-col single-4-center margin-bottom'}
                    dataTestId={'btn-add-hospitalisation'}
                    handleClick={handleSubmit(onSubmit)}
                    icon={<AddIcon />}
                    label={t(ADD_BUTTON)}
                />
            </Grid>
        </form>
    );
};
