/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ReactNode } from 'react';
import { gridCss } from './Grid.style';

interface GridProps {
    children: ReactNode;
    className?: string;
}

export const Grid = ({ children, className }: GridProps) => {
    return (
        <div className={`grid ${className}`} css={css(gridCss)}>
            {children}
        </div>
    );
};
