import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDateOfPreviousInjury } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { personActions } from '../../../sagas/person';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { Clickable, Datable, getLocaleFromUrl, MuiDatePicker, PageLayout } from '@protectorinsurance/ds-can';
import { getMomentLocale } from '../../../utils/getMomentLocale';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { MANDATORY_DATE_FIELD } = FormFieldErrors;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { DATE_OF_PREVIOUS_INJURY } = FormFieldNames;

/**
 * Page view and page logic
 */
export const AccidentDateOfPreviousInjuryPage = () => {
    const claimDate = useSelector(selectDateOfPreviousInjury);
    const customCAN = useSelector(selectCustomCAN);
    const [date, setDate] = useState<Datable>(claimDate || null);
    const [error, setError] = useState<string>();
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.dateOfPreviousInjury');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (date !== null) {
            dispatcherWithPromise(dispatch, personActions.update, {
                dateOfPreviousInjury: date,
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        } else {
            setError(t(MANDATORY_DATE_FIELD));
        }
    };

    const selectedDate = (date: Datable) => {
        if (date) {
            setDate(date);
            setError('');
            dispatch(personActions.update({ dateOfPreviousInjury: date }));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <MuiDatePicker
                disableFuture={true}
                locale={getMomentLocale(getLocaleFromUrl())}
                name={DATE_OF_PREVIOUS_INJURY}
                onChange={selectedDate}
                {...{ customCAN, date, error }}
            />
        </PageLayout>
    );
};
