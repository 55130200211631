import { PFRequestError } from '../../../../../interfaces';

export const createPFRequestErrorFromError = (
    e: PFRequestError
): PFRequestError => {
    return {
        message: e.message,
        name: e.name,
        status: e.status,
        statusText: e.statusText,
        url: e.url,
    };
};
