import { StringSchema, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isDev } from '../../utils/env';
import { ssnSwedenValidator } from '@protectorinsurance/ds-can';

export default function ssn(this: StringSchema, message: string = yupCustomLocale.string.ssn) {
    return this.test('ssn', message, (value, context):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> => {
        return isDev() ? true : value ? ssnSwedenValidator(value).isValid() : false;
    });
}
