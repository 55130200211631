import { Nullable } from '../../../../../interfaces';

export enum PersonClaimCauseKeys {
    ANIMAL_BITE = 'ANIMAL_BITE',
    CHEMICALS = 'CHEMICALS',
    CRASH_INJURY = 'CRASH_INJURY',
    CRUSH_INJURY = 'CRUSH_INJURY',
    CUT = 'CUT',
    DEATH = 'DEATH',
    FALL = 'FALL',
    FIRE = 'FIRE',
    LIFTING_INJURY = 'LIFTING_INJURY',
    OTHER = 'OTHER',
    QUARREL = 'QUARREL',
    SHOCK = 'SHOCK',
    SLIPPING = 'SLIPPING',
    TEETH_INJURY = 'TEETH_INJURY',
    TEMPERATURE_INJURY = 'TEMPERATURE_INJURY',
    TRAFFIC = 'TRAFFIC',
    TWIST = 'TWIST',
    VIOLENCE = 'VIOLENCE',
}

export type PersonClaimCauseTypeModel = Nullable<PersonClaimCauseKeys>;
