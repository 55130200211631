import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    DiagnosIcon,
    IllnessIcon,
    InsuranceTypeKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectInsuranceType } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { DIAGNOSIS, ILLNESS } = InsuranceTypeKeys;

/**
 * Page view and page logic
 */
export const illnessInsuranceTypePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: DIAGNOSIS, name: t(`lob.person.illness.insuranceType.select.${DIAGNOSIS}`), icon: <DiagnosIcon /> },
        { id: ILLNESS, name: t(`lob.person.illness.insuranceType.select.${ILLNESS}`), icon: <IllnessIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_INSURANCE_TYPE,
        stateKey: 'insuranceType',
        i18n: {
            ns: 'lob.person.illness.insuranceType',
        },
        selectorValue: selectInsuranceType(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { insuranceType: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
