export function businessNumberSwedenValidateChecksum(businessNumber: string) {
    if (businessNumber.length === 0) {
        return false;
    }

    let k = businessNumber.indexOf('-');
    if (k === -1) {
        k = businessNumber.indexOf('+');
    }

    if (k !== 6 || businessNumber.length !== 11) {
        return false;
    }

    const normalized = businessNumber.replace(/\D/g, '');
    let number;
    let checkSum = 0;
    for (let i = normalized.length - 1; i >= 0; i--) {
        number = parseInt(normalized.charAt(i));
        if (i % 2 === 1) {
            checkSum += number;
        } else {
            checkSum += number * 2 > 9 ? number * 2 - 9 : number * 2;
        }
    }

    return checkSum % 10 === 0;
}
