import { BankInformationModel } from '../models/common/BankInformation';

export const banks: BankInformationModel[] = [
    {
        name: 'Svea Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^966[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^966[0-9]$/,
        },
    },
    {
        name: 'Avanza Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^95[5-6][0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^95[5-6][0-9]$/,
        },
    },
    {
        name: 'BlueStep Finans',
        type: 1,
        comment: 1,
        regex: {
            both: /^968[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^968[0-9]$/,
        },
    },
    {
        name: 'BNP Paribas SA.',
        type: 1,
        comment: 2,
        regex: {
            both: /^947[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^947[0-9]$/,
        },
    },
    {
        name: 'Citibank',
        type: 1,
        comment: 2,
        regex: {
            both: /^904[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^904[0-9]$/,
        },
    },
    {
        name: 'Danske Bank',
        type: 1,
        comment: 1,
        regex: {
            both: /^(12|13|24)[0-9]{9}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^(12|13|24)[0-9]{2}$/,
        },
    },
    {
        name: 'DNB Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^(919|926)[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^(919|926)[0-9]$/,
        },
    },
    {
        name: 'Ekobanken',
        type: 1,
        comment: 2,
        regex: {
            both: /^970[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^970[0-9]$/,
        },
    },
    {
        name: 'Erik Penser',
        type: 1,
        comment: 2,
        regex: {
            both: /^959[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^959[0-9]$/,
        },
    },
    {
        name: 'Forex Bank',
        type: 1,
        comment: 1,
        regex: {
            both: /^94[0-4][0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^94[0-4][0-9]$/,
        },
    },
    {
        name: 'ICA Banken',
        type: 1,
        comment: 1,
        regex: {
            both: /^927[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^927[0-9]$/,
        },
    },
    {
        name: 'IKANO Bank',
        type: 1,
        comment: 1,
        regex: {
            both: /^917[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^917[0-9]$/,
        },
    },
    {
        name: 'JAK Medlemsbank',
        type: 1,
        comment: 2,
        regex: {
            both: /^967[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^967[0-9]$/,
        },
    },
    {
        name: 'Klarna Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^978[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^978[0-9]$/,
        },
    },
    {
        name: 'Landshypotek',
        type: 1,
        comment: 2,
        regex: {
            both: /^939[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^939[0-9]$/,
        },
    },
    {
        name: 'Lån & Spar Bank Sverige',
        type: 1,
        comment: 1,
        regex: {
            both: /^963[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^963[0-9]$/,
        },
    },
    {
        name: 'Länsförsäkringar Bank',
        type: 1,
        comment: 1,
        regex: {
            both: /^(340|906)[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^(340|906)[0-9]$/,
        },
    },
    {
        name: 'Länsförsäkringar Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^902[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^902[0-9]$/,
        },
    },
    {
        name: 'Marginalen Bank',
        type: 1,
        comment: 1,
        regex: {
            both: /^923[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^923[0-9]$/,
        },
    },
    {
        name: 'MedMera Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^965[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^965[0-9]$/,
        },
    },
    {
        name: 'Nordax Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^964[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^964[0-9]$/,
        },
    },
    {
        name: 'Nordea',
        type: 1,
        comment: 1,
        regex: {
            both: /^(?!3300|3782)(1[1456789][0-9]{2}|20[0-9]{2}|3[0-3][0-9]{2}|34[1-9][0-9]|3[5-9][0-9]{2})[0-9]{7}$/,
            accountNumber: /^(?!3300|3782)(1[1456789][0-9]{2}|20[0-9]{2}|3[0-3][0-9]{2}|34[1-9][0-9]|3[5-9][0-9]{2})[0-9]{7}$/,
            clearingNumber: /^(?!3300|3782)(1[1456789][0-9]{2}|20[0-9]{2}|3[0-3][0-9]{2}|34[1-9][0-9]|3[5-9][0-9]{2})$/,
        },
    },
    {
        name: 'Nordea',
        type: 1,
        comment: 2,
        regex: {
            both: /^4[0-9]{10}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^4[0-9]{3}$/,
        },
    },
    {
        name: 'Nordnet Bank',
        type: 1,
        comment: 2,
        regex: {
            both: /^910[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^910[0-9]$/,
        },
    },
    {
        name: 'Resurs Bank',
        type: 1,
        comment: 1,
        regex: {
            both: /^928[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^928[0-9]$/,
        },
    },
    {
        name: 'Riksgälden',
        type: 1,
        comment: 2,
        regex: {
            both: /^988[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^988[0-9]$/,
        },
    },
    {
        name: 'Santander Consumer Bank',
        type: 1,
        comment: 1,
        regex: {
            both: /^946[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^946[0-9]$/,
        },
    },
    {
        name: 'SBAB',
        type: 1,
        comment: 1,
        regex: {
            both: /^925[0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^925[0-9]$/,
        },
    },
    {
        name: 'SEB',
        type: 1,
        comment: 1,
        regex: {
            both: /^(5[0-9]{3}|912[0-4]|91[3-4][0-9])[0-9]{7}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^(5[0-9]{3}|912[0-4]|91[3-4][0-9])$/,
        },
    },
    {
        name: 'Skandiabanken',
        type: 1,
        comment: 2,
        regex: {
            both: /^91[5-6][0-9]{8}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^91[5-6][0-9]$/,
        },
    },
    {
        name: 'Swedbank',
        type: 1,
        comment: 1,
        regex: {
            both: /^7[0-9]{10}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^7[0-9]{3}$/,
        },
    },
    {
        name: 'Ålandsbanken',
        type: 1,
        comment: 2,
        regex: {
            both: /^23[0-9]{9}$/,
            accountNumber: /^[0-9]{7}$/,
            clearingNumber: /^23[0-9]{2}$/,
        },
    },
    // TYP 2
    {
        name: 'Danske Bank',
        type: 2,
        comment: 1,
        regex: {
            both: /^918[0-9]{11}$/,
            accountNumber: /^[0-9]{10}$/,
            clearingNumber: /^918[0-9]$/,
        },
    },
    {
        name: 'Handelsbanken',
        type: 2,
        comment: 2,
        regex: {
            both: /^6[0-9]{12}$/,
            accountNumber: /^[0-9]{9}$/,
            clearingNumber: /^6[0-9]{3}$/,
        },
    },
    {
        name: 'Nordea Plusgirot',
        type: 2,
        comment: 3,
        regex: {
            both: /^(95[0-4]|996)[0-9]{8,11}$/, // Clearingnr 4 siffror, räkningsnr 7-10 siffror
            accountNumber: /^[0-9]{7,10}$/,
            clearingNumber: /^(95[0-4]|996)[0-9]$/,
        },
    },
    {
        name: 'Nordea',
        type: 2,
        comment: 1,
        regex: {
            both: /^(3300|3782)[0-9]{10}$/,
            accountNumber: /^[0-9]{10}$/,
            clearingNumber: /^(3300|3782)$/,
        },
    },
    {
        name: 'Riksgälden',
        type: 2,
        comment: 1,
        regex: {
            both: /^989[0-9]{11}$/,
            accountNumber: /^[0-9]{10}$/,
            clearingNumber: /^989[0-9]$/,
        },
    },
    {
        name: 'Sparbanken Syd',
        type: 2,
        comment: 1,
        regex: {
            both: /^957[0-9]{11}$/,
            accountNumber: /^[0-9]{10}$/,
            clearingNumber: /^957[0-9]$/,
        },
    },
    {
        name: 'Swedbank',
        type: 2,
        comment: 3,
        regex: {
            both: /^8[0-9]{10,14}$/, // Clearingnr 5 siffror, räkningsnr 6-10 siffror
            accountNumber: /^[0-9]{6,10}$/,
            clearingNumber: /^8[0-9]{4}$/,
        },
    },
    {
        name: 'Swedbank',
        type: 2,
        comment: 1,
        regex: {
            both: /^93[0-4][0-9]{11}$/,
            accountNumber: /^[0-9]{10}$/,
            clearingNumber: /^93[0-4][0-9]$/,
        },
    },
];
