import { css } from '@emotion/react';
import { dev, primary, secondary, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const navBarCss = (company?: customCANProps) =>
    css({
        '&.is-dev': {
            background: dev,
            '& a': {
                color: white,
            },
        },

        '&.is-test': {
            background: secondary,
            '& a': {
                color: white,
            },
        },

        background: company && company.color,
        margin: '0 auto',
        padding: '9px 10px',

        '& .navbar-link': {
            alignItems: 'center',
            color: secondary,
            display: 'flex',
            fontFamily: 'BrandonTextBold, sans-serif',
            fontSize: 20,
            fontWeight: 500,
            height: company ? 70 : 30,
            lineHeight: 30,
            margin: '0 auto',
            maxWidth: 1920,
            textDecoration: 'none',

            '& svg': {
                display: 'inline-block',
                height: company && '100%',

                '& .pf-logo-letter': {
                    fill: primary,
                },
            },

            '&:hover, &:focus': {
                opacity: '50%',
                outline: 'none',
            },
        },

        '@media (min-width: 576px)': {
            padding: '9px 30px',
        },
    });
