/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { Clickable } from '../../../interfaces/types/Events.types';
import { printLinkCss } from './PrintLink.style';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface PrintLinkProps {
    customCAN?: customCANProps;
    onClick(e: Clickable): void;
    title: string;
}

/**
 * Component view and component logic
 */
export const PrintLink = ({ customCAN, onClick, title }: PrintLinkProps) => {
    return (
        <div css={css(printLinkCss(customCAN))}>
            <button onClick={onClick}>{title}</button>
        </div>
    );
};
