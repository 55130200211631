import { Nullable } from '../../../../../interfaces';

export enum VehicleTypeKeys {
    CAR = 'CAR',
    MOTORCYCLE = 'MOTORCYCLE',
    MOPED = 'MOPED',
    BUS = 'BUS',
    EXCAVATOR = 'EXCAVATOR',
    WHEEL_LOADER = 'WHEEL_LOADER',
    TRUCK = 'TRUCK',
    LIGHT_TRUCK = 'LIGHT_TRUCK',
    MOTORIZED_TOOL = 'MOTORIZED_TOOL',
    TRAILER = 'TRAILER',
    TERRAIN_SCOOTER = 'TERRAIN_SCOOTER',
    TERRAIN_VEHICLE = 'TERRAIN_VEHICLE',
    TERRAIN_TRAILER = 'TERRAIN_TRAILER',
    SUP_400_TERRAIN_VEHICLE = 'SUP_400_TERRAIN_VEHICLE',
    TRACTOR = 'TRACTOR',
    POWERED_INDUSTRIAL_TRUCK = 'POWERED_INDUSTRIAL_TRUCK',
    PRIVATE_CAR = 'PRIVATE_CAR',
    COACHES = 'COACHES',
    COMMERCIAL_VEHICLE = 'COMMERCIAL_VEHICLE',
    LIGHT_COMMERCIAL_VEHICLE = 'LIGHT_COMMERCIAL_VEHICLE',
    SPECIAL_TYPE_VEHICLE = 'SPECIAL_TYPE_VEHICLE',
    OTHER = 'OTHER',
}

export interface VehicleTypeModel {
    vehicleType: VehicleTypeTypeModel;
}

export type VehicleTypeTypeModel = Nullable<VehicleTypeKeys>;
