import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    isYes,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectIsVehicleMotorcycle } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const accidentIsVehicleMotorcyclePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: YES, name: t(`lob.person.accident.isVehicleMotorcycle.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lob.person.accident.isVehicleMotorcycle.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_MOTORCYCLE,
        stateKey: 'isVehicleMotorcycle',
        i18n: {
            ns: 'lob.person.accident.isVehicleMotorcycle',
        },
        selectorValue: selectIsVehicleMotorcycle(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (isYes(option.id)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                dispatcherWithPromise(dispatch, personActions.update, { isVehicleMotorcycle: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
