import React from 'react';
import { createTextYesNoDontKnow, trueFalseToYesNo } from '../../../utils/displayUtils';
import { PersonSummaryProps } from '../../../types/SummaryProps';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import {
    ClaimReporterRoleKeys,
    FileList,
    InsuranceTypeKeys,
    is,
    isNo,
    isNot,
    isYes,
    PersonClaimCauseKeys,
    PersonInVehicleKeys,
    SummaryItem,
    TeethOrBodyKeys,
    TypeOfMedicalInstitutionEnums,
} from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { dtParseAndFormat, dtTimeParseAndFormat } from '../../../utils/date/dateUtils';
import { SummaryArrayItem } from 'components/summaryArrayItem/SummaryArrayItem';
import { SummaryBodyArrayItem } from '../../summaryArrayItem/SummaryBodyArrayItem';

/**
 * Destructure necessary imports
 */
const { ON_BEHALF_CHILD } = ClaimReporterRoleKeys;
const { DRIVER, PASSENGER } = PersonInVehicleKeys;
const { BOTH, BODY, TEETH } = TeethOrBodyKeys;
const { TEMPERATURE_INJURY, FIRE, SHOCK, CHEMICALS } = PersonClaimCauseKeys;
const { SCHOOL_NURSE } = TypeOfMedicalInstitutionEnums;
const { MUNICIPALITY_SCHOOL } = InsuranceTypeKeys;
const {
    ACCIDENT_BODY_PARTS,
    ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
    ACCIDENT_CLAIM_DATE,
    ACCIDENT_DATE_OF_PREVIOUS_INJURY,
    ACCIDENT_DATE_OF_TREATMENT,
    ACCIDENT_DENTIST_INFORMATION,
    ACCIDENT_MEDICAL_INSTITUTION_INFORMATION,
    ACCIDENT_OTHER_INSURANCE_COMPANY,
    ACCIDENT_PRIVACY,
    ACCIDENT_TEETH,
    ACCIDENT_TEETH_OR_BODY,
    ACCIDENT_TYPE_OF_SPORT,
    ACCIDENT_UPLOAD_ATTACHMENT,
    ACCIDENT_UPLOAD_MEDICAL_CERTIFICATE,
    ACCIDENT_UPLOAD_RECEIPT,
    ACCIDENT_WEEKS_OF_PLASTER_TREATMENT,
    DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE,
    DYN_CAROUSEL_ACCIDENT_CLAIM_LOCATION,
    DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER,
    DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER_ROLE,
    DYN_CAROUSEL_ACCIDENT_CLAIM_TIME_OF_DAY,
    DYN_CAROUSEL_ACCIDENT_DURING_SPORT,
    DYN_CAROUSEL_ACCIDENT_FINISHED_TREATMENT,
    DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY,
    DYN_CAROUSEL_ACCIDENT_HAS_ATTACHMENT,
    DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES,
    DYN_CAROUSEL_ACCIDENT_HAS_HELMET,
    DYN_CAROUSEL_ACCIDENT_HAS_LICENSE,
    DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES,
    DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_ACCIDENT_HAS_PLASTER_TREATMENT,
    DYN_CAROUSEL_ACCIDENT_IN_ELEMENTARY_SCHOOL,
    DYN_CAROUSEL_ACCIDENT_INSURANCE_TYPE,
    DYN_CAROUSEL_ACCIDENT_INVALIDITY,
    DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED,
    DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED,
    DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_MOTORCYCLE,
    DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_INVOLVED,
    DYN_CAROUSEL_ACCIDENT_NEED_TRANSPORT,
    DYN_CAROUSEL_ACCIDENT_PERSON_IN_VEHICLE,
    DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
    DYN_CAROUSEL_ACCIDENT_TYPE_OF_MEDICAL_INSTITUTION,
    INDEX,
} = PersonRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayAccidentSituationSummary = ({ model, t, handleClick, upload, error }: PersonSummaryProps) => {
    /**
     * Helpers
     */
    const {
        category,
        claimReporter,
        claimReporterRole,
        insuranceType,
        acceptedPrivacy,
        isVehicleInvolved,
        registrationNumber,
        isVehicleMotorcycle,
        personInVehicle,
        hasLicense,
        hasHelmet,
        claimLocation,
        claimDate,
        claimTimeOfDay,
        happenDomestically,
        duringSport,
        typeOfSport,
        teethOrBody,
        bodyPartList,
        teeth,
        claimCause,
        claimCauseConsequences,
        hasPlasterTreatment,
        weeksOfPlasterTreatment,
        previousInjury,
        dateOfPreviousInjury,
        isDoctorContacted,
        typeOfMedicalInstitution,
        dateOfTreatment,
        medicalInstitution,
        isDentistContacted,
        dentist,
        finishedTreatment,
        invalidity,
        hasExpenses,
        needTransport,
        inElementarySchool,
        hasOtherExpenses,
        otherInsurance,
        otherInsuranceCompany,
        hasAttachment,
    } = model;

    const { certificate, receipt, attachment } = upload;

    const renderCategory = () => {
        if (category) {
            return (
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.category.heading.title')}
                    dataTestId={TEST_ID_PREFIX.category}
                    buttonText={t(`lob.person.category.select.${category}`)}
                    path={INDEX}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return (
            <div className={'col-4 divider align-button-start text-align-left'}>
                <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.type')}</p>
            </div>
        );
    };

    const renderClaimReporterRole = () => {
        if (claimReporterRole) {
            return (
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.whoReport.heading.title')}
                    dataTestId={TEST_ID_PREFIX.whoReport}
                    buttonText={t(`lob.person.accident.whoReport.select.${claimReporterRole}`)}
                    path={DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER_ROLE}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return (
            <div className={'col-4 divider align-button-start text-align-left'}>
                <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.claimReporter')}</p>
            </div>
        );
    };

    const renderInsuranceType = () => {
        if (insuranceType) {
            return (
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.insuranceType.heading.title')}
                    dataTestId={TEST_ID_PREFIX.insuranceType}
                    buttonText={t(`lob.person.accident.insuranceType.select.${insuranceType}`)}
                    path={DYN_CAROUSEL_ACCIDENT_INSURANCE_TYPE}
                    show={isNot(claimReporterRole, ON_BEHALF_CHILD)}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return (
            <div className={'col-4 divider align-button-start text-align-left'}>
                <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.insuranceType')}</p>
            </div>
        );
    };

    const renderReport = () => {
        return (
            <>
                {/* Category */}
                {renderCategory()}

                {/* Who Report */}
                {renderClaimReporterRole()}

                {/* Insurance type */}
                {renderInsuranceType()}

                {/* Claim Reporter */}
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.claimReporter.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimReporter}
                    buttonText={t(`lob.person.accident.claimReporter.select.${claimReporter}`)}
                    path={DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER}
                    show={is(claimReporterRole, ON_BEHALF_CHILD)}
                    {...{ handleClick }}
                />
            </>
        );
    };

    const renderPrivacy = () => {
        if (acceptedPrivacy) {
            return (
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.privacy.heading.title')}
                    dataTestId={TEST_ID_PREFIX.acceptedPrivacy}
                    buttonText={t(trueFalseToYesNo(acceptedPrivacy))}
                    path={ACCIDENT_PRIVACY}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return (
            <div className={'col-12 divider align-button-start text-align-left'}>
                <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.privacy')}</p>
            </div>
        );
    };

    const renderIncident = () => {
        return (
            <>
                {/* Claim location */}
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.claimLocation.heading.title')}
                    dataTestId={TEST_ID_PREFIX.location}
                    buttonText={t(`lob.person.accident.claimLocation.select.${claimLocation}`)}
                    path={DYN_CAROUSEL_ACCIDENT_CLAIM_LOCATION}
                    {...{ handleClick }}
                />

                {/* Claim date */}
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.claimDate.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimDate}
                    buttonText={claimDate ? dtParseAndFormat(claimDate) : ''}
                    path={ACCIDENT_CLAIM_DATE}
                    handleClick={handleClick}
                />

                {/* Claim time of day */}
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.claimTimeOfDay.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimTimeOfDay}
                    buttonText={t(`lob.person.accident.claimTimeOfDay.select.${claimTimeOfDay}`)}
                    path={DYN_CAROUSEL_ACCIDENT_CLAIM_TIME_OF_DAY}
                    {...{ handleClick }}
                />
            </>
        );
    };

    const renderVehicle = () => {
        return (
            <>
                {/* Is Vehicle Involved */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(isVehicleInvolved) ? 'col-4 spacing' : 'col-12 divider'
                    } align-button-start text-align-left`}
                    textKey={t('lob.person.accident.summary.isVehicleInvolved.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isVehicleInvolved}
                    buttonText={t(createTextYesNoDontKnow(isVehicleInvolved))}
                    path={DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_INVOLVED}
                    {...{ handleClick }}
                />
                {isYes(isVehicleInvolved) && (
                    <>
                        {/* Registration number */}
                        <SummaryItem
                            inputWrapperClass={'col-4 spacing align-button-start text-align-left'}
                            textKey={t('lob.person.accident.summary.vehicleInformation.heading.title')}
                            dataTestId={TEST_ID_PREFIX.vehicleInformation}
                            buttonText={registrationNumber ? registrationNumber : '-'}
                            path={DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_INVOLVED}
                            {...{ handleClick }}
                        />

                        {/* Is Vehicle Motorcycle */}
                        <SummaryItem
                            inputWrapperClass={'col-4 spacing align-button-start text-align-left'}
                            textKey={t('lob.person.accident.summary.isVehicleMotorcycle.heading.title')}
                            dataTestId={TEST_ID_PREFIX.isVehicleMotorcycle}
                            buttonText={t(createTextYesNoDontKnow(isVehicleMotorcycle))}
                            path={DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_MOTORCYCLE}
                            {...{ handleClick }}
                        />

                        {/* Person In Vehicle */}
                        <SummaryItem
                            inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                            textKey={t('lob.person.accident.summary.personInVehicle.heading.title')}
                            dataTestId={TEST_ID_PREFIX.personInVehicle}
                            buttonText={t(`lob.person.accident.personInVehicle.select.${personInVehicle}`)}
                            path={DYN_CAROUSEL_ACCIDENT_PERSON_IN_VEHICLE}
                            {...{ handleClick }}
                        />

                        {/* Has License */}
                        <SummaryItem
                            inputWrapperClass={`${
                                isYes(hasHelmet) ? 'col-4' : 'col-8'
                            } divider align-button-start text-align-left`}
                            textKey={t('lob.person.accident.summary.hasLicense.heading.title')}
                            dataTestId={TEST_ID_PREFIX.hasLicense}
                            buttonText={t(createTextYesNoDontKnow(hasLicense))}
                            path={DYN_CAROUSEL_ACCIDENT_HAS_LICENSE}
                            show={is(personInVehicle, DRIVER)}
                            {...{ handleClick }}
                        />

                        {/* Has Helmet */}
                        {isYes(isVehicleMotorcycle) && is(personInVehicle, DRIVER || PASSENGER) && (
                            <SummaryItem
                                inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                                textKey={t('lob.person.accident.summary.hasHelmet.heading.title')}
                                dataTestId={TEST_ID_PREFIX.hasHelmet}
                                buttonText={t(createTextYesNoDontKnow(hasHelmet))}
                                path={DYN_CAROUSEL_ACCIDENT_HAS_HELMET}
                                {...{ handleClick }}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const renderDomestic = () => {
        return (
            <>
                {/* Happen Domestically summary */}
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.happenDomestically.heading.title')}
                    dataTestId={TEST_ID_PREFIX.happenDomestically}
                    buttonText={t(createTextYesNoDontKnow(happenDomestically))}
                    path={DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY}
                    {...{ handleClick }}
                />
            </>
        );
    };

    const renderSport = () => {
        return (
            isNo(isVehicleInvolved) && (
                <>
                    {/* DuringSport */}
                    <SummaryItem
                        inputWrapperClass={`${
                            isYes(duringSport) ? 'col-4' : 'col-12'
                        } divider align-button-start text-align-left`}
                        textKey={t('lob.person.accident.summary.duringSport.heading.title')}
                        dataTestId={TEST_ID_PREFIX.duringSport}
                        buttonText={t(createTextYesNoDontKnow(duringSport))}
                        path={DYN_CAROUSEL_ACCIDENT_DURING_SPORT}
                        {...{ handleClick }}
                    />

                    {/* Type Of Sport */}
                    {isYes(duringSport) && (
                        <SummaryItem
                            inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                            textKey={t(`lob.person.accident.summary.typeOfSport.heading.title`)}
                            dataTestId={TEST_ID_PREFIX.typeOfSport}
                            buttonText={typeOfSport ? typeOfSport : ''}
                            path={ACCIDENT_TYPE_OF_SPORT}
                            show={isYes(duringSport) && !!typeOfSport}
                            {...{ handleClick }}
                        />
                    )}
                </>
            )
        );
    };

    const renderTeethOrBody = () => {
        return (
            <>
                {/* Teeth Or Body*/}
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.teethOrBody.heading.title')}
                    dataTestId={TEST_ID_PREFIX.teethOrBody}
                    buttonText={t(`lob.person.accident.teethOrBody.select.${teethOrBody}`)}
                    path={ACCIDENT_TEETH_OR_BODY}
                    {...{ handleClick }}
                />

                {/* Body parts*/}
                {(is(teethOrBody, BOTH) || is(teethOrBody, BODY)) && (
                    <div
                        className={`${
                            is(teethOrBody, BOTH) ? 'col-4' : 'col-8'
                        } divider align-button-start text-align-left`}
                    >
                        <SummaryBodyArrayItem
                            labelTextKey={t('lob.person.accident.summary.bodyParts.heading.title')}
                            name={TEST_ID_PREFIX.bodyParts}
                            list={bodyPartList}
                            textItemKeyPrefix={'lob.person.accident.summary.bodyParts.parts'}
                            textItemKeyPostfix={`label`}
                            path={ACCIDENT_BODY_PARTS}
                            show={bodyPartList.length !== 0}
                            {...{ handleClick }}
                        />
                    </div>
                )}

                {/* Teeth */}
                {(is(teethOrBody, BOTH) || is(teethOrBody, TEETH)) && (
                    <div
                        className={`${
                            is(teethOrBody, BOTH) ? 'col-4' : 'col-8'
                        } divider align-button-start text-align-left`}
                    >
                        <SummaryArrayItem
                            labelTextKey={t('lob.person.accident.summary.teeth.heading.title')}
                            name={TEST_ID_PREFIX.teeth}
                            list={teeth}
                            textItemKeyPrefix={'lob.person.accident.teeth.teethGroups'}
                            textItemKeyPostfix={'label'}
                            path={ACCIDENT_TEETH}
                            show={teeth.length !== 0}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderClaimCause = () => {
        return (
            <>
                {/* Claim cause */}
                <SummaryItem
                    inputWrapperClass={`${
                        isNot(claimCause, TEMPERATURE_INJURY || FIRE || SHOCK || CHEMICALS) && isNot(teethOrBody, TEETH)
                            ? 'col-4'
                            : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.accident.summary.claimCause.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimCause}
                    buttonText={t(`lob.person.accident.claimCause.select.${claimCause}`)}
                    path={DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE}
                    {...{ handleClick }}
                />

                {/* Claim cause consequences */}
                {isNot(claimCause, TEMPERATURE_INJURY || FIRE || SHOCK || CHEMICALS) && isNot(teethOrBody, TEETH) && (
                    <div className={'col-8 divider align-button-start text-align-left'}>
                        <SummaryArrayItem
                            labelTextKey={t('lob.person.accident.summary.claimCauseConsequences.heading.title')}
                            name={TEST_ID_PREFIX.claimCauseConsequences}
                            list={claimCauseConsequences}
                            textItemKeyPrefix={'lob.person.accident.claimCauseConsequences.select'}
                            path={ACCIDENT_CLAIM_CAUSE_CONSEQUENCES}
                            show={claimCauseConsequences.length !== 0}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderPlasterTreatment = () => {
        return (
            <>
                {/* Has Plaster Treatment */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasPlasterTreatment) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.accident.summary.hasPlasterTreatment.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasPlasterTreatment}
                    buttonText={t(createTextYesNoDontKnow(hasPlasterTreatment))}
                    path={DYN_CAROUSEL_ACCIDENT_HAS_PLASTER_TREATMENT}
                    {...{ handleClick }}
                />

                {/* Weeks Of Plaster Treatment */}
                {isYes(hasPlasterTreatment) && (
                    <SummaryItem
                        inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                        textKey={t('lob.person.accident.summary.weeksOfPlasterTreatment.heading.title')}
                        dataTestId={TEST_ID_PREFIX.weeksOfPlasterTreatment}
                        buttonText={weeksOfPlasterTreatment || ''}
                        path={ACCIDENT_WEEKS_OF_PLASTER_TREATMENT}
                        {...{ handleClick }}
                    />
                )}
            </>
        );
    };

    const renderPreviousInjury = () => {
        return (
            <>
                {/* Previous injury */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(previousInjury) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.accident.summary.previousInjury.heading.title')}
                    dataTestId={TEST_ID_PREFIX.previousInjury}
                    buttonText={t(createTextYesNoDontKnow(previousInjury))}
                    path={DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY}
                    {...{ handleClick }}
                />

                {/* Date of previous injury */}
                {isYes(previousInjury) && (
                    <SummaryItem
                        inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                        textKey={t('lob.person.accident.summary.dateOfPreviousInjury.heading.title')}
                        dataTestId={TEST_ID_PREFIX.dateOfPreviousInjury}
                        buttonText={dateOfPreviousInjury ? dtTimeParseAndFormat(dateOfPreviousInjury) : ''}
                        path={ACCIDENT_DATE_OF_PREVIOUS_INJURY}
                        {...{ handleClick }}
                    />
                )}
            </>
        );
    };

    const renderMedicalInstitution = () => {
        return (
            <>
                {/* Doctor contacted */}
                {isNot(teethOrBody, TEETH) && (
                    <SummaryItem
                        inputWrapperClass={
                            isYes(isDoctorContacted)
                                ? `col-4 ${
                                      isNot(typeOfMedicalInstitution, SCHOOL_NURSE) ? 'spacing' : 'divider'
                                  } align-button-start text-align-left`
                                : 'col-12 divider align-button-start text-align-left'
                        }
                        textKey={t('lob.person.accident.summary.isDoctorContacted.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isDoctorContacted}
                        buttonText={t(createTextYesNoDontKnow(isDoctorContacted))}
                        path={DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED}
                        {...{ handleClick }}
                    />
                )}

                {isYes(isDoctorContacted) && (
                    <>
                        {/* Type of medical institution */}
                        <SummaryItem
                            inputWrapperClass={`col-4 ${
                                isNot(typeOfMedicalInstitution, SCHOOL_NURSE) ? 'spacing' : 'divider'
                            } align-button-start text-align-left`}
                            textKey={t('lob.person.accident.summary.typeOfMedicalInstitution.heading.title')}
                            dataTestId={TEST_ID_PREFIX.typeOfMedicalInstitution}
                            buttonText={t(
                                `lob.person.accident.typeOfMedicalInstitution.select.${typeOfMedicalInstitution}`
                            )}
                            path={DYN_CAROUSEL_ACCIDENT_TYPE_OF_MEDICAL_INSTITUTION}
                            {...{ handleClick }}
                        />

                        {/* Date of treatment */}
                        <SummaryItem
                            inputWrapperClass={`col-8 ${
                                isNot(typeOfMedicalInstitution, SCHOOL_NURSE) ? 'spacing' : 'divider'
                            } align-button-start text-align-left`}
                            textKey={t('lob.person.accident.summary.dateOfTreatment.heading.title')}
                            dataTestId={TEST_ID_PREFIX.dateOfTreatment}
                            buttonText={dateOfTreatment ? dtTimeParseAndFormat(dateOfTreatment) : ''}
                            path={ACCIDENT_DATE_OF_TREATMENT}
                            {...{ handleClick }}
                        />

                        {/* Medical institution information */}
                        {isNot(typeOfMedicalInstitution, SCHOOL_NURSE) && (
                            <SummaryItem
                                inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                                textKey={t('lob.person.accident.summary.medicalInstitutionInformation.heading.title')}
                                dataTestId={TEST_ID_PREFIX.medicalInstitution}
                                buttonText={medicalInstitution ? medicalInstitution : ''}
                                path={ACCIDENT_MEDICAL_INSTITUTION_INFORMATION}
                                {...{ handleClick }}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const renderDentalInstitution = () => {
        return (
            (is(teethOrBody, TEETH) || is(teethOrBody, BOTH)) && (
                <>
                    {/* Dentist contacted */}
                    <SummaryItem
                        inputWrapperClass={`${
                            isYes(isDentistContacted) ? 'col-4' : 'col-12'
                        } divider align-button-start text-align-left`}
                        textKey={t('lob.person.accident.summary.isDentistContacted.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isDentistContacted}
                        buttonText={t(createTextYesNoDontKnow(isDentistContacted))}
                        path={DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED}
                        {...{ handleClick }}
                    />

                    {/* Dentist information */}
                    {isYes(isDentistContacted) && (
                        <SummaryItem
                            inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                            textKey={t('lob.person.accident.summary.dentistInformation.heading.title')}
                            dataTestId={TEST_ID_PREFIX.dentist}
                            buttonText={dentist ? dentist : ''}
                            path={ACCIDENT_DENTIST_INFORMATION}
                            handleClick={handleClick}
                        />
                    )}
                </>
            )
        );
    };

    const renderTreatment = () => {
        return (
            (isYes(isDoctorContacted) || isYes(isDentistContacted)) && (
                <>
                    {/* Finished Treatment */}
                    <SummaryItem
                        inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                        textKey={t('lob.person.accident.summary.finishedTreatment.heading.title')}
                        dataTestId={TEST_ID_PREFIX.finishedTreatment}
                        buttonText={t(createTextYesNoDontKnow(finishedTreatment))}
                        path={DYN_CAROUSEL_ACCIDENT_FINISHED_TREATMENT}
                        {...{ handleClick }}
                    />

                    {/* Invalidity */}
                    <SummaryItem
                        inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                        textKey={t('lob.person.accident.summary.invalidity.heading.title')}
                        dataTestId={TEST_ID_PREFIX.invalidity}
                        buttonText={t(createTextYesNoDontKnow(invalidity))}
                        path={DYN_CAROUSEL_ACCIDENT_INVALIDITY}
                        {...{ handleClick }}
                    />
                </>
            )
        );
    };

    const renderExpenses = () => {
        return (
            <>
                {/* Has expenses */}
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.accident.summary.hasExpenses.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasExpenses}
                    buttonText={t(createTextYesNoDontKnow(hasExpenses))}
                    path={DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES}
                    {...{ handleClick }}
                />
            </>
        );
    };

    const renderSchoolTransport = () => {
        return (
            is(insuranceType, MUNICIPALITY_SCHOOL) &&
            is(claimReporterRole, ON_BEHALF_CHILD) && (
                <>
                    {/* Need transport */}
                    <SummaryItem
                        inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                        textKey={t('lob.person.accident.summary.needTransport.heading.title')}
                        dataTestId={TEST_ID_PREFIX.needTransport}
                        buttonText={t(createTextYesNoDontKnow(needTransport))}
                        path={DYN_CAROUSEL_ACCIDENT_NEED_TRANSPORT}
                        {...{ handleClick }}
                    />

                    {isYes(needTransport) && (
                        <>
                            {/* In elementary school */}
                            <SummaryItem
                                inputWrapperClass={`${
                                    isYes(inElementarySchool) ? 'col-4' : 'col-8'
                                } divider align-button-start text-align-left`}
                                textKey={t('lob.person.accident.summary.inElementarySchool.heading.title')}
                                dataTestId={TEST_ID_PREFIX.inElementarySchool}
                                buttonText={t(createTextYesNoDontKnow(inElementarySchool))}
                                path={DYN_CAROUSEL_ACCIDENT_IN_ELEMENTARY_SCHOOL}
                                {...{ handleClick }}
                            />

                            {/* Medical certificate upload */}
                            {isYes(inElementarySchool) && (
                                <div className={'col-8 divider align-button-start text-align-left'}>
                                    <FileList
                                        list={certificate.files}
                                        path={ACCIDENT_UPLOAD_MEDICAL_CERTIFICATE}
                                        show={certificate.files.length > 0}
                                        dataTestId={TEST_ID_PREFIX.uploadCertificate}
                                        textKey={`${t(
                                            'lob.person.accident.summary.uploadMedicalCertificate.heading.title'
                                        )}:`}
                                        handleClick={handleClick}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )
        );
    };

    const renderOtherExpenses = () => {
        return (
            <>
                {/* Has other expenses */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasOtherExpenses) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.accident.summary.hasOtherExpenses.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasOtherExpenses}
                    buttonText={t(createTextYesNoDontKnow(hasOtherExpenses))}
                    path={DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES}
                    {...{ handleClick }}
                />

                {/* Receipt upload */}
                {isYes(hasOtherExpenses) && (
                    <div className={'col-8 divider align-button-start text-align-left'}>
                        <FileList
                            list={receipt.files}
                            path={ACCIDENT_UPLOAD_RECEIPT}
                            show={receipt.files.length > 0}
                            dataTestId={TEST_ID_PREFIX.uploadReceipt}
                            textKey={t('lob.person.accident.summary.uploadReceipt.heading.title')}
                            handleClick={handleClick}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderOtherInsurance = () => {
        return (
            <>
                {/* Other insurance */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(otherInsurance) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.accident.summary.hasOtherInsurance.heading.title')}
                    dataTestId={TEST_ID_PREFIX.otherInsurance}
                    buttonText={t(createTextYesNoDontKnow(otherInsurance))}
                    path={DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE}
                    {...{ handleClick }}
                />

                {/* Other insurance company */}
                {isYes(otherInsurance) && (
                    <SummaryItem
                        inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                        textKey={t('lob.person.accident.summary.otherInsuranceCompany.heading.title')}
                        dataTestId={TEST_ID_PREFIX.otherInsuranceCompany}
                        buttonText={otherInsuranceCompany ? otherInsuranceCompany : ''}
                        path={ACCIDENT_OTHER_INSURANCE_COMPANY}
                        {...{ handleClick }}
                    />
                )}
            </>
        );
    };

    const renderAttachments = () => {
        return (
            <>
                {/* Has attachment */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasAttachment) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.accident.summary.hasAttachment.heading.title')}
                    buttonText={t(createTextYesNoDontKnow(hasAttachment))}
                    dataTestId={`${TEST_ID_PREFIX.hasAttachments}${attachment.hasAttachment}`}
                    path={DYN_CAROUSEL_ACCIDENT_HAS_ATTACHMENT}
                    {...{ handleClick }}
                />

                {/* List of attachments */}
                {isYes(hasAttachment) && (
                    <div className={'col-8 divider align-button-start text-align-left'}>
                        <FileList
                            list={attachment.files}
                            path={ACCIDENT_UPLOAD_ATTACHMENT}
                            show={attachment.files.length > 0}
                            dataTestId={TEST_ID_PREFIX.uploadAttachments}
                            textKey={t('lob.person.accident.summary.uploadAttachment.heading.title')}
                            handleClick={handleClick}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {renderReport()}

            {renderPrivacy()}

            {renderIncident()}

            {renderVehicle()}

            {renderDomestic()}

            {renderSport()}

            {renderTeethOrBody()}

            {renderClaimCause()}

            {renderPlasterTreatment()}

            {renderPreviousInjury()}

            {renderMedicalInstitution()}

            {renderDentalInstitution()}

            {renderTreatment()}

            {renderExpenses()}

            {renderSchoolTransport()}

            {renderOtherExpenses()}

            {renderOtherInsurance()}

            {renderAttachments()}
        </>
    );
};
