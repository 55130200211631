import { css } from '@emotion/react';

export const displayErrorCss = css({
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '3rem 0',
    width: '80%',

    '& .message': {
        padding: '1rem',
        width: '100%',
        height: '70%',
        minHeight: '15rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },

    svg: {
        width: 'auto',
    },

    '@media screen and (min-width: 768px)': {
        flexDirection: 'row',

        '& .message': {
            padding: '1rem 0rem 1rem 3rem',
            width: '70%',
            flexDirection: 'column',
        },
    },
});
