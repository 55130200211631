import { PhraseKeys } from '../config/phraseKeys';

export interface CANRequestError {
    name: string;
    message: string;
    status: number;
    statusText: string;
    url?: string;
}

export const HTTPResponseStatus = {
    BAD_REQUEST: {
        code: 400,
        text: PhraseKeys.BAD_REQUEST,
    },
    INTERNAL_SERVER_ERROR: {
        code: 500,
        text: PhraseKeys.INTERNAL_SERVER_ERROR,
    },
};

export enum PollErrors {
    NO_EXTERNAL_ID = 'NO_EXTERNAL_ID',
    MAX_ITERATIONS = 'MAX_ITERATIONS',
}

export enum FileErrorTypes {
    UNSUPPORTED = 'UNSUPPORTED',
    MAX_FILE_SIZE = 'MAX_FILE_SIZE',
    MULTI_MAX_FILE_SIZE = 'MULTI_MAX_FILE_SIZE',
    MULTI_MAX_COUNT = 'MULTI_MAX_COUNT',
    MULTI_NOT_ALLOWED = 'MULTI_NOT_ALLOWED',
}

export const createCANRequestError = (
    status: number,
    message: string,
    url: string,
    name?: string,
    statusText?: string
): CANRequestError => {
    return {
        message,
        name: name || `${status}`,
        status,
        statusText: statusText || message,
        url,
    };
};

export const createCANRequestErrorFromError = (e: CANRequestError): CANRequestError => {
    return {
        message: e.message,
        name: e.name,
        status: e.status,
        statusText: e.statusText,
        url: e.url,
    };
};
