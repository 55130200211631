import { AppState } from '../../store';
import { cleanStateBeforeSubmit } from '../../utils/cleanStateBeforeSubmit';
import { LobKeys, YesNoKeys } from '@protectorinsurance/ds-can';
import { convertYesNoKeysToBool } from '../../utils/convertYesNoKeysToBool';

/**
 * Destructure necessary imports
 */
const { AUTO, PERSON } = LobKeys;

export const selectClaimReportId = (state: AppState) => state.common.id;
export const selectClaimReport = (state: AppState) => {
    const { id, lob, country, requestId, timeoutWarning } = state.common;
    const { hasParkedWitnesses } = state.motor;
    const { isAccident } = state.person;
    let claimState;
    if (lob === PERSON) {
        claimState = state.person;
    } else if (lob === AUTO) {
        claimState = state.motor;
    } else {
        claimState = state.lpo;
    }

    const sessionObject = {
        id,
        lob,
        country,
        requestId,
        timeoutWarning,
        ...claimState,
        ...(!!hasParkedWitnesses &&
            hasParkedWitnesses === YesNoKeys.YES && { hasParkedWitnesses: null, hasWitnesses: YesNoKeys.YES }),
    };

    return {
        ...cleanStateBeforeSubmit({ ...sessionObject }),
        ...(!!isAccident && { isAccident: convertYesNoKeysToBool(isAccident) }),
    };
};
export const selectLoggedInClaimReport = (state: AppState) => state.common.loggedIn;
