import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isDev } from '../../utils/env';
import { bankClearingNumberUtils } from 'utils/bank/bankClearingNumberUtils';
import { isEmpty } from '@protectorinsurance/ds-can';

export default function bankClearingNumber(
    this: StringSchema,
    required: boolean,
    message: string = yupCustomLocale.string.bankClearingNumber
) {
    // @ts-ignore
    return this.test('bankClearingNumber', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        const skipValidation = isDev() || (!required && isEmpty(value));
        return skipValidation ? true : bankClearingNumberUtils.isValid(value);
    });
}
