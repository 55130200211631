import { withIconStyle } from '../../../hoc/withIconStyle';

import { ReactComponent as AddIconSVG } from '../../../assets/icons/common/add.svg';
import { ReactComponent as ArrowLeftIconSVG } from '../../../assets/icons/common/arrow-left.svg';
import { ReactComponent as ArrowRightIconSVG } from '../../../assets/icons/common/arrow-right.svg';
import { ReactComponent as AtvIconSVG } from '../../../assets/icons/common/atv.svg';
import { ReactComponent as BusIconSVG } from '../../../assets/icons/common/bus.svg';
import { ReactComponent as CalendarIconSVG } from '../../../assets/icons/common/calendar.svg';
import { ReactComponent as CircleGarbageIconSVG } from '../../../assets/icons/common/circle-garbage.svg';
import { ReactComponent as CircleUndoIconSVG } from '../../../assets/icons/common/circle-undo.svg';
import { ReactComponent as ClientErrorIconSVG } from '../../../assets/icons/common/client-error.svg';
import { ReactComponent as CommonCarIconSVG } from '../../../assets/icons/common/common-car.svg';
import { ReactComponent as CommonMotorcycleIconSVG } from '../../../assets/icons/common/common-motorcycle.svg';
import { ReactComponent as ExcavatorIconSVG } from '../../../assets/icons/common/excavator.svg';
import { ReactComponent as ForkliftIconSVG } from '../../../assets/icons/common/forklift.svg';
import { ReactComponent as GeneralPersonIconSVG } from '../../../assets/icons/common/general-person.svg';
import { ReactComponent as MiniTruckIconSVG } from '../../../assets/icons/common/mini-truck.svg';
import { ReactComponent as MotorredskapIconSVG } from '../../../assets/icons/common/motorredskap.svg';
import { ReactComponent as PencilIconSVG } from '../../../assets/icons/common/pencil.svg';
import { ReactComponent as QuestionMarkIconSVG } from '../../../assets/icons/common/question-mark.svg';
import { ReactComponent as ServerErrorIconSVG } from '../../../assets/icons/common/server-error.svg';
import { ReactComponent as TerrainScootersIconSVG } from '../../../assets/icons/common/terrain-scooters.svg';
import { ReactComponent as TerrianTrailerIconSVG } from '../../../assets/icons/common/terrian-trailer.svg';
import { ReactComponent as TerrianWagonIconSVG } from '../../../assets/icons/common/terrian-wagon.svg';
import { ReactComponent as TimeoutIconSVG } from '../../../assets/icons/common/timeout.svg';
import { ReactComponent as TractorIconSVG } from '../../../assets/icons/common/tractor.svg';
import { ReactComponent as TrailerIconSVG } from '../../../assets/icons/common/trailer.svg';
import { ReactComponent as TruckIconSVG } from '../../../assets/icons/common/truck.svg';
import { ReactComponent as UploadDropzoneIconSVG } from '../../../assets/icons/common/upload-dropzone.svg';
import { ReactComponent as WheelLoadersIconSVG } from '../../../assets/icons/common/wheel-loaders.svg';

export const AddIcon = withIconStyle(AddIconSVG);
export const ArrowLeftIcon = withIconStyle(ArrowLeftIconSVG);
export const ArrowRightIcon = withIconStyle(ArrowRightIconSVG);
export const AtvIcon = withIconStyle(AtvIconSVG);
export const BusIcon = withIconStyle(BusIconSVG);
export const CalendarIcon = withIconStyle(CalendarIconSVG);
export const CircleGarbageIcon = withIconStyle(CircleGarbageIconSVG);
export const CircleUndoIcon = withIconStyle(CircleUndoIconSVG);
export const ClientErrorIcon = withIconStyle(ClientErrorIconSVG);
export const CommonCarIcon = withIconStyle(CommonCarIconSVG);
export const CommonMotorcycleIcon = withIconStyle(CommonMotorcycleIconSVG);
export const ExcavatorIcon = withIconStyle(ExcavatorIconSVG);
export const ForkliftIcon = withIconStyle(ForkliftIconSVG);
export const GeneralPersonIcon = withIconStyle(GeneralPersonIconSVG);
export const MiniTruckIcon = withIconStyle(MiniTruckIconSVG);
export const MotorredskapIcon = withIconStyle(MotorredskapIconSVG);
export const PencilIcon = withIconStyle(PencilIconSVG);
export const QuestionMarkIcon = withIconStyle(QuestionMarkIconSVG);
export const ServerErrorIcon = withIconStyle(ServerErrorIconSVG);
export const TerrainScootersIcon = withIconStyle(TerrainScootersIconSVG);
export const TerrianTrailerIcon = withIconStyle(TerrianTrailerIconSVG);
export const TerrianWagonIcon = withIconStyle(TerrianWagonIconSVG);
export const TimeoutIcon = withIconStyle(TimeoutIconSVG);
export const TractorIcon = withIconStyle(TractorIconSVG);
export const TrailerIcon = withIconStyle(TrailerIconSVG);
export const TruckIcon = withIconStyle(TruckIconSVG);
export const UploadDropzoneIcon = withIconStyle(UploadDropzoneIconSVG);
export const WheelLoadersIcon = withIconStyle(WheelLoadersIconSVG);
