/**
 *  Et bankgironummer kan ha 7 eller 8 karakterer (f.eks. 262-0326 or 5962-0823). Det skal være 3 eller 4 siffer før bindestrek og minst ett etter
 */

export function validateAccountNumberBGSE(accountNumber: string) {
    return {
        isValid: function isValid() {
            return (
                /^[0-9]{3,4}-[0-9]{1,}$/.test(accountNumber) &&
                (accountNumber.length == 8 || accountNumber.length == 9)
            );
        },
    };
}
