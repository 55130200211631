import { Nullable } from '../../../../../interfaces';

export enum MotorClaimCauseKeys {
    THEFT_AND_DAMAGE = 'THEFT_AND_DAMAGE',
    ENGINE = 'ENGINE',
    FIRE = 'FIRE',
    PARKING = 'PARKING',
    WRONG_FUEL = 'WRONG_FUEL',
    LOST_KEY = 'LOST_KEY',
    COMPARTMENT_DAMAGE = 'COMPARTMENT_DAMAGE',
    NATURAL_DISASTER = 'NATURAL_DISASTER',
    OTHER = 'OTHER',
    ANIMAL_COLLISION = 'ANIMAL_COLLISION',
    STATIONARY_OBJECT_COLLISION = 'STATIONARY_OBJECT_COLLISION',
    CYCLIST_COLLISION = 'CYCLIST_COLLISION',
    DITCH_COLLISION = 'DITCH_COLLISION',
    PEDESTRIAN_COLLISION = 'PEDESTRIAN_COLLISION',
    REAR_ENDED = 'REAR_ENDED',
    HEAD_ON = 'HEAD_ON',
    PARKED = 'PARKED',
    LANE_CHANGE = 'LANE_CHANGE',
    REVERSING = 'REVERSING',
    OVERTAKING = 'OVERTAKING',
    MULTIPLE_COLLISION = 'MULTIPLE_COLLISION',
    NARROW_ROAD_COLLISION = 'NARROW_ROAD_COLLISION',
    RIGHT_DUTY = 'RIGHT_DUTY',
    UNCONDITIONAL_RIGHT_OF_WAY = 'UNCONDITIONAL_RIGHT_OF_WAY',
    UNCONDITIONAL_RIGHT_OF_WAY_ROUNDABOUT = 'UNCONDITIONAL_RIGHT_OF_WAY_ROUNDABOUT',
}

export type MotorClaimCauseTypeModel = Nullable<MotorClaimCauseKeys>;
