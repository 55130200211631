import { TFunction } from 'i18next';
import { RefObject } from 'react';
import { SVGClickable } from '../../../../../interfaces/types';
import { BodyModel } from '../models';

export interface SVGImageProps {
    onClick: (e: SVGClickable, refSVG?: RefObject<SVGSVGElement>) => void;
    addDamagePoint: (d: DamagePoint, refSVG?: RefObject<SVGSVGElement>) => void;
    removeDamagePoint: (
        e: SVGClickable,
        point: DamagePoint,
        refSVG?: RefObject<SVGSVGElement>
    ) => void;
    selectedDamagePoints: DamagePoint[];
    className?: string;
}
export interface Point {
    x: number;
    y: number;
}
export interface SVGImage {
    svgId: string;
}
export type SVGImagePoint = Point & SVGImage;

export interface DamagePoint extends Point, SVGImage {
    vehicleBodyPart: BodyModel;
}

export interface SVGImageTitles {
    right: string;
    left: string;
    top: string;
}

export interface MainImageSVGProps
    extends Pick<
        SVGImageProps,
        | 'addDamagePoint'
        | 'removeDamagePoint'
        | 'selectedDamagePoints'
        | 'className'
    > {
    titles: SVGImageTitles;
    id?: string;
}
export interface SvgImg
    extends Pick<
        MainImageSVGProps,
        | 'removeDamagePoint'
        | 'selectedDamagePoints'
        | 'id'
        | 'addDamagePoint'
        | 'className'
    > {
    title: string;
}

export enum SVGImageView {
    TOP = 'TOP',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export const createSvgTabs = (t: TFunction) => {
    return [
        { id: SVGImageView.TOP, label: t('tabs.tab.label.top') },
        { id: SVGImageView.LEFT, label: t('tabs.tab.label.left') },
        { id: SVGImageView.RIGHT, label: t('tabs.tab.label.right') },
    ];
};
