import React, { useEffect } from 'react';

interface GeoCoderProps {
    setGeocoder: (geocoder: google.maps.Geocoder | null) => void;
}

export const GeoCoder = ({ setGeocoder }: GeoCoderProps) => {
    useEffect(() => {
        const geocoder = initGeocoder();
        setGeocoder(geocoder);
        return () => {
            setGeocoder(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
};

export const initGeocoder = () => {
    return new google.maps.Geocoder();
};
export const getGeocoderAddressByLocation = (
    geocoder: google.maps.Geocoder,
    location: google.maps.LatLng
): Promise<google.maps.GeocoderResult[]> => {
    return new Promise((resolve, reject) => {
        geocoder.geocode({ location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                resolve(results || []);
            } else {
                reject({ message: 'geocode.error', status });
            }
        });
    });
};

export const getGeocoderLocationByPlaceId = (
    geocoder: google.maps.Geocoder,
    placeId: string
): Promise<google.maps.GeocoderResult[]> => {
    return new Promise((resolve, reject) => {
        geocoder.geocode({ placeId }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                resolve(results || []);
            } else {
                reject({ message: 'geocode.error', status });
            }
        });
    });
};
