import { css } from '@emotion/react';
import { secondary, secondaryHover, white } from '../../../variables';
import { customCANProps } from '../../../../config/customCANCompanies';

export const muiDatePickerStyle = (company?: customCANProps) =>
    css({
        '& .MuiPickersDay-root': {
            '&:hover': {
                backgroundColor: company ? company.color : secondary,
                color: white,
            },

            '&.Mui-selected': {
                backgroundColor: `${
                    company ? company.color : secondary
                } !important`,

                '&:hover': {
                    backgroundColor: company
                        ? company.colorFaded
                        : secondaryHover,
                },
            },
        },

        '& .invalid-feedback': {
            margin: '0 auto',
            minWidth: '280px',
        },
    });
