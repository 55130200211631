import { isNumber } from '../../../utils/is/isNumber';

export function PIAPolicyNumberValidator(policyNumber: string) {
    return {
        isValid: function isValid(): boolean {
            if (policyNumber) {
                return (
                    (policyNumber.length === 7 || policyNumber.length === 6) &&
                    isNumber(policyNumber)
                );
            }

            return false;
        },
    };
}
