import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { hospitalNameSchema } from '../fieldSchemas/hospitalNameSchema';
import { periodSchema } from '../fieldSchemas/periodSchema';

export const hospitalisationInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...periodSchema(t),
        ...hospitalNameSchema(t),
    });
};
