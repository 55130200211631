export enum FileCategoryEnums {
    OTHER_DOCUMENTATION = 'OTHER_DOCUMENTATION',
    PURCHASE_CONTRACT = 'PURCHASE_CONTRACT',
    RATE = 'RATE',
    SALES_STATEMENT = 'SALES_STATEMENT',
    SELLERS_SELF_DECLARATION_FORM = 'SELLERS_SELF_DECLARATION_FORM',
    TAKEOVER_PROTOCOL = 'TAKEOVER_PROTOCOL',
    GENERAL_DOCTOR = 'GENERAL_DOCTOR',
    SPECIALIST = 'SPECIALIST',
    IMAGING = 'IMAGING',
    MEDICINES = 'MEDICINES',
    PHYSIOTHERAPY = 'PHYSIOTHERAPY',
    SURGERY = 'SURGERY',
    LABORATORY = 'LABORATORY',
    OTHER_COMPENSATIONS = 'OTHER_COMPENSATIONS',
    UNKNOWN = 'UNKNOWN',
}
