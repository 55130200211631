export function ssnDenmarkHasValidDate(ssn: string) {
    // @ts-ignore
    // eslint-disable-next-line no-unused-vars
    const [_, dayStr, monthStr, yearStr] = /^(\d{2})(\d{2})(\d{2})/.exec(ssn);

    const year = Number(yearStr);
    const month = Number(monthStr) - 1;
    let day = Number(dayStr);

    if (day > 60) {
        day -= 60;
    }

    const date = new Date(year, month, day);

    const yearIsValid = String(date.getFullYear()).substr(-2) === yearStr;
    const monthIsValid = date.getMonth() === month;
    const dayIsValid = date.getDate() === day;

    return yearIsValid && monthIsValid && dayIsValid;
}
