/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Fragment } from 'react';
import { Clickable } from '../../../interfaces';
import { btnGroupCss, helpTextCss } from './PageFooter.style';
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton';
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton';

/**
 * Interface
 */
interface PageFooterProps {
    backBtnAriaLabel?: string;
    backBtnText: string;
    continueBtnAriaLabel?: string;
    continueBtnText: string;
    disableBackButton?: boolean;
    disableContinueButton?: boolean;
    footerComponent?: any;
    footerText?: string;
    handleBackButton: (e: Clickable) => void;
    handleContinueButton: (e: Clickable) => void;
    showBackButton?: boolean;
    showContinueButton?: boolean;
}

/**
 * Component view and component logic
 */
export const PageFooter = ({
    backBtnAriaLabel,
    backBtnText,
    continueBtnAriaLabel,
    continueBtnText,
    disableBackButton,
    disableContinueButton,
    footerComponent,
    footerText,
    handleBackButton,
    handleContinueButton,
    showBackButton,
    showContinueButton,
}: PageFooterProps) => {
    const renderBackButton = () => (
        <SecondaryButton
            ariaLabel={backBtnAriaLabel}
            className={'btn btn-lg'}
            disabled={disableBackButton}
            onClick={handleBackButton}
            testId={'back-btn'}
            value={backBtnText}
        />
    );

    const renderContinueButton = () => (
        <PrimaryButton
            ariaLabel={continueBtnAriaLabel}
            className={'btn btn-lg'}
            disabled={disableContinueButton}
            onClick={handleContinueButton}
            testId={'continue-btn'}
            type="submit"
            value={continueBtnText}
        />
    );

    return (
        <Fragment>
            {footerComponent && (
                <div className={'help-text'} css={css(helpTextCss)}>
                    {footerComponent}
                </div>
            )}
            {footerText?.trim() && (
                <div
                    className={'help-text'}
                    css={css(helpTextCss)}
                    dangerouslySetInnerHTML={{ __html: footerText }}
                    tabIndex={0}
                />
            )}
            <div className={'btn-group'} css={css(btnGroupCss)}>
                {showContinueButton && renderContinueButton()}
                {showBackButton && renderBackButton()}
            </div>
        </Fragment>
    );
};
