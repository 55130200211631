import { VehicleModel, VehicleServiceModel } from '../interfaces/models';
import { updateVehicleType } from './vehicleUtils';
import { calcInsuredByProtectorFromApiResponse } from './calcInsuredByProtectorFromApiResponse';

export const transformVehicleServiceResponse = (
    res: VehicleModel | VehicleServiceModel
) => {
    const vehicleData = res as VehicleServiceModel;

    return {
        ...res,
        insuredByProtector: calcInsuredByProtectorFromApiResponse(
            vehicleData.insuredByProtector || ''
        ),
        vehicleType: updateVehicleType(vehicleData),
    };
};
