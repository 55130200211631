import React, { Dispatch, SetStateAction } from 'react';
import { AssociationInformationModel } from '../../../../models/person/AssociationInformation';
import { useI18n } from '../../../../hooks/useI18n';
import { I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayAssociationInformationProps {
    model: Partial<AssociationInformationModel>;
    i18n?: I18NOptions;
    error: Dispatch<SetStateAction<boolean>>;
}

export const DisplayAssociationInformation = ({ model, i18n, error }: DisplayAssociationInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace); // Internal I18N instance

    const renderName = () => {
        if (model.name) {
            return <SummaryItemText label={tWithPrefix('text.associationName')} text={model.name} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.association')}</p>;
    };

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));

    return (
        <>
            <div className={'col-12 spacing header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix(`associationInformation`)}
                </p>
            </div>
            <div className={`col-12 divider text-align-left`}>{renderName()}</div>
        </>
    );
};
