import {
    PartyRelationKeys,
    SelectedVehicleTypeModel,
    VehicleModel,
    VehicleServiceModel,
    VehiclesModel,
    VehicleTypeKeys,
} from '../interfaces/models';
import { DamagePoint } from '../interfaces/types';
import { mapInsuredByProtector } from './mapInsuredByProtector';
import { initVehicleModel } from '../sagas';
import { isClaimVehicle, mapVehicleType } from './vehicle';

/**
 * DEPRECATED_updateVehicleType
 * @param vehicleType
 * @param type
 * @param weight
 *
 * @see vehicleTypeToEnum
 */
export const updateVehicleType = ({
    vehicleType,
    type,
    weight,
}: VehicleServiceModel): VehicleTypeKeys | null => {
    return !!vehicleType || !!type
        ? mapVehicleType(vehicleType || type, weight)
        : null;
};

/**
 * DEPRECATED_mapNewVehicle
 * @param newVehicle
 *
 * @see spreadNewVehicle
 */
const mapNewVehicle = (newVehicle: VehicleServiceModel) => {
    return {
        ...initVehicleModel,
        ...newVehicle,
        vehicleType: updateVehicleType(newVehicle),
        insuredByProtector: mapInsuredByProtector(
            newVehicle.insuredByProtector
        ),
    };
};

/**
 * DEPRECATED_updateVehicleClaimVehicle
 *
 * @param vehicles
 * @param newVehicle
 *
 * @see updateOrAddClaimVehicle
 */
export const updateVehicleClaimVehicle = (
    vehicles: VehiclesModel,
    newVehicle: VehicleServiceModel
) => {
    const exist = vehicles.find(isClaimVehicle);
    if (!exist) {
        return [
            ...vehicles,
            {
                ...mapNewVehicle(newVehicle),
                partyRelation: PartyRelationKeys.CLAIM_VEHICLE,
            },
        ];
    }
    return vehicles.map((vehicle) => {
        if (vehicle.partyRelation === PartyRelationKeys.CLAIM_VEHICLE) {
            return {
                ...mapNewVehicle(newVehicle),
                partyRelation: vehicle.partyRelation,
            };
        }
        return vehicle;
    });
};

export const addDamage = (
    vehicle: VehicleModel,
    { x, y, svgId, vehicleBodyPart }: DamagePoint
): VehicleModel => {
    const updatedDamages = [
        ...vehicle.damages,
        { body: vehicleBodyPart, svgLocation: { x, y, svgId } },
    ];
    return {
        ...vehicle,
        damages: updatedDamages,
    };
};
export const removeDamage = (
    vehicle: VehicleModel,
    pointToRemove: DamagePoint
): VehicleModel => {
    return {
        ...vehicle,
        damages: vehicle.damages.filter((damage) => {
            if (damage.svgLocation) {
                const { x, y } = damage.svgLocation;
                return x !== pointToRemove.x || y !== pointToRemove.y;
            }

            return false;
        }),
    };
};

export const addDamageToSelectedVehicle = (
    vehicles: VehiclesModel,
    selectedVehicle: SelectedVehicleTypeModel,
    damagePoint: DamagePoint
): VehiclesModel => {
    return vehicles.map((v) => {
        if (v.internalId === selectedVehicle) {
            return addDamage(v, damagePoint);
        }
        return v;
    });
};
export const removeDamageToSelectedVehicle = (
    vehicles: VehiclesModel,
    selectedVehicle: SelectedVehicleTypeModel,
    damagePoint: DamagePoint
): VehiclesModel => {
    return vehicles.map((v) => {
        if (v.internalId === selectedVehicle) {
            return removeDamage(v, damagePoint);
        }
        return v;
    });
};
