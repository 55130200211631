import React from 'react';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { displayPerson, is, isYes } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { formatBankAccountInformation, formatCompanyName } from '../../../utils/strings/formatStrings';
import { renderReporterInformation } from './components/end/RenderReporterInformation';
import { renderPolicyHolder } from './components/end/RenderPolicyHolder';
import { renderAttachment } from './components/end/RenderAttachment';
import { renderLocation } from './components/end/RenderLocation';
import { renderUnit } from './components/end/RenderUnit';
import { renderClaimDescription } from './components/end/RenderClaimDescription';
import { renderHasContractWithOtherPart } from './components/end/RenderHasContractWithOtherPart';
import { renderPolicyHoldersContact } from './components/end/RenderPolicyHoldersContact';
import { renderHasDemands } from './components/end/RenderHasDemands';
import { renderHasVAT } from './components/end/RenderHasVAT';
import { renderReportedToInsurersInsurance } from './components/end/RenderReportedToInsurersInsurance';
import { renderLiabilityClaimDescription } from './components/end/RenderLiabilityClaimDescription';
import { renderHasOtherInsurance } from './components/end/RenderHasOtherInsurance';
import { renderOtherInsurance } from './components/end/RenderOtherInsurance';
import classNames from 'classnames';
import { renderAddBankAccount } from './components/end/RenderAddBankAccount';
import { renderLandlordInformation } from './components/end/RenderLandlordInformation';
import { formatLandlordInformation } from '../../../utils/strings/formatLandlordInformation';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_END_HAS_CONTRACT_WITH_OTHER_PART,
    DYN_CAROUSEL_END_HAS_DEMANDS,
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_END_HAS_VAT,
    END_ACCIDENT_LOCATION,
    END_ADD_BANK_ACCOUNT,
    END_CLAIM_DESCRIPTION,
    END_OTHER_INSURANCE_COMPANY,
    END_POLICY_HOLDER,
    END_POLICY_HOLDERS_CONTACT,
    END_PROPERTY_ADDRESS,
    END_REPORTER_INFORMATION,
    END_LANDLORD_INFORMATION,
    END_UPLOAD_ATTACHMENT,
    LIABILITY_CLAIM_DESCRIPTION,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayEndSummary = ({ model, t, tWithNS, handleClick, upload }: LpoSummaryProps) => {
    const {
        accidentLocation,
        bankAccountInformation,
        claimDescription,
        companyInformation,
        hasContractWithOtherPart,
        hasDemands,
        hasVAT,
        landlordInformation,
        liabilityClaimDescription,
        otherInsurance,
        otherInsuranceCompany,
        policyHoldersContact,
        reporterInformation,
        reportedToInsurersInsurance,
    } = model;
    const { attachment } = upload;

    // Classes
    const locationClass = classNames(
        `${!is(accidentLocation.unit, null) ? 'col-6' : 'col-12'}`,
        'divider text-align-left'
    );
    const hasOtherInsuranceClass = classNames(
        `${isYes(otherInsurance) ? 'col-4' : 'col-12'}`,
        'divider text-align-left'
    );

    return (
        <>
            {renderLocation(
                {
                    inputWrapperClass: locationClass,
                    textKey: tWithNS('accidentLocation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.accidentLocation,
                    buttonText: accidentLocation.note
                        ? accidentLocation.note
                        : `${accidentLocation.latitude}, ${accidentLocation.longitude}`,
                    path: END_ACCIDENT_LOCATION,
                    handleClick,
                },
                accidentLocation
            )}

            {renderUnit(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('propertyAddress.heading.title'),
                    dataTestId: TEST_ID_PREFIX.propertyAddress,
                    buttonText: accidentLocation.unit ? accidentLocation.unit : '-',
                    path: END_PROPERTY_ADDRESS,
                    handleClick,
                },
                accidentLocation
            )}

            {renderClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimDescription,
                    buttonText: claimDescription ? claimDescription : '-',
                    path: END_CLAIM_DESCRIPTION,
                    handleClick,
                },
                claimDescription
            )}

            {renderHasContractWithOtherPart(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasContractWithOtherPart.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasContractWithOtherPart,
                    buttonText: t(createTextYesNoDontKnow(hasContractWithOtherPart)),
                    path: DYN_CAROUSEL_END_HAS_CONTRACT_WITH_OTHER_PART,
                    handleClick,
                },
                hasContractWithOtherPart
            )}

            {renderHasDemands(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasDemands.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasDemands,
                    buttonText: t(createTextYesNoDontKnow(hasDemands)),
                    path: DYN_CAROUSEL_END_HAS_DEMANDS,
                    handleClick,
                },
                hasDemands
            )}

            {renderReportedToInsurersInsurance(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('reportedToInsurersInsurance.heading.title'),
                    dataTestId: TEST_ID_PREFIX.reportedToInsurersInsurance,
                    buttonText: t(createTextYesNoDontKnow(reportedToInsurersInsurance)),
                    path: LIABILITY_CLAIM_DESCRIPTION,
                    handleClick,
                },
                reportedToInsurersInsurance
            )}

            {renderLiabilityClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('liabilityClaimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.liabilityClaimDescription,
                    buttonText: liabilityClaimDescription ? liabilityClaimDescription : '-',
                    path: LIABILITY_CLAIM_DESCRIPTION,
                    handleClick,
                },
                reportedToInsurersInsurance
            )}

            {renderAttachment(
                {
                    textKey: `${tWithNS('uploadAttachment.heading.title')}`,
                    dataTestId: TEST_ID_PREFIX.uploadAttachments,
                    path: END_UPLOAD_ATTACHMENT,
                    buttonText: '',
                },
                `${tWithNS('uploadAttachment.heading.title')}`,
                TEST_ID_PREFIX.uploadAttachments,
                attachment
            )}

            {renderHasOtherInsurance({
                inputWrapperClass: hasOtherInsuranceClass,
                textKey: tWithNS('hasOtherInsurance.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasOtherInsurance,
                buttonText: t(createTextYesNoDontKnow(otherInsurance)),
                path: DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
                handleClick,
            })}

            {renderOtherInsurance(
                {
                    inputWrapperClass: 'col-8 divider text-align-left',
                    textKey: tWithNS('otherInsuranceCompany.heading.title'),
                    dataTestId: TEST_ID_PREFIX.otherInsuranceCompany,
                    buttonText: otherInsuranceCompany ? otherInsuranceCompany : '-',
                    path: END_OTHER_INSURANCE_COMPANY,
                    handleClick,
                },
                otherInsurance
            )}

            {renderPolicyHolder({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHolder.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHolder,
                buttonText: companyInformation ? formatCompanyName(companyInformation) : '-',
                path: END_POLICY_HOLDER,
                handleClick,
            })}

            {renderHasVAT(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasVAT.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasVAT,
                    buttonText: t(createTextYesNoDontKnow(hasVAT)),
                    path: DYN_CAROUSEL_END_HAS_VAT,
                    handleClick,
                },
                hasVAT
            )}

            {renderReporterInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('reporterInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.reporterInformation,
                buttonText: displayPerson(reporterInformation),
                path: END_REPORTER_INFORMATION,
                handleClick,
            })}

            {renderLandlordInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('landlordInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.landlordInformation,
                buttonText: formatLandlordInformation(landlordInformation),
                path: END_LANDLORD_INFORMATION,
                handleClick,
            })}

            {renderAddBankAccount({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('addBankAccount.heading.title'),
                dataTestId: TEST_ID_PREFIX.addBankAccountType,
                buttonText: formatBankAccountInformation(bankAccountInformation, t),
                path: END_ADD_BANK_ACCOUNT,
                handleClick,
            })}

            {renderPolicyHoldersContact({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHoldersContact.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHoldersContact,
                buttonText: displayPerson(policyHoldersContact),
                path: END_POLICY_HOLDERS_CONTACT,
                handleClick,
            })}
        </>
    );
};
