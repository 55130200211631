import { Nullable } from '../../../../../interfaces';

export enum CategoryKeys {
    ACCIDENT = 'ACCIDENT',
    ILLNESS = 'ILLNESS',
    DEATH = 'DEATH',
    HEALTH = 'HEALTH',
    LOSS_OF_LICENSE = 'LOSS_OF_LICENSE',
    SICK_PAY = 'SICK_PAY',
}

export type CategoryModel = Nullable<CategoryKeys>;
