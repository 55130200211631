export enum LanguageCodeEnums {
    DA = 'da',
    EN = 'en',
    FI = 'fi',
    NB = 'nb',
    SE = 'se',
}

export const LanguageCodeArray = [
    LanguageCodeEnums.DA,
    LanguageCodeEnums.EN,
    LanguageCodeEnums.FI,
    LanguageCodeEnums.NB,
    LanguageCodeEnums.SE,
];
