import { css } from '@emotion/react';
import { inputBorderColor, primary, white } from '../../variables';

export const fileDropzoneCss = css({
    alignItems: 'center',
    backgroundColor: white,
    border: `2px dashed ${inputBorderColor}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '150px',

    '& .hightlight': {
        background: primary,
    },

    label: {
        display: 'none',
    },

    h2: {
        paddingTop: '1rem',
    },
});
