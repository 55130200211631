/* Colors */
import { customCANProps } from '../config/customCANCompanies';

export const white = '#fff';
export const gray100 = '#f8f9fa';
export const gray200 = '#e9ecef';
export const gray300 = '#dee2e6';
export const gray400 = '#ced4da';
export const gray500 = '#adb5bd';
export const gray600 = '#6c757d';
export const gray700 = '#495057';
export const gray800 = '#343a40';
export const gray900 = '#212529';
export const black = '#000000';

export const blue = '#007bff';
export const indigo = '#6610f2';
export const purple = '#6f42c1';
export const pink = '#e83e8c';
export const red = '#dc3545';
export const orange = '#fd7e14';
export const yellow = '#ffc107';
export const green = '#28a745';
export const teal = '#20c997';
export const cyan = '#17a2b8';

export const dev = '#7d8890';
export const primary = '#455560';
export const primaryRGB = {
    r: 69,
    g: 85,
    b: 96,
};
export const primaryHover = '#192f3f';
export const primaryDisabled = '#b1bac0';
export const secondary = '#ef6e0b';
export const secondaryHover = '#c35c0f';
export const secondaryRGB = {
    r: 239,
    g: 110,
    b: 11,
};
export const secondaryShadow = '#ef6e0b';
export const secondaryLight = '#f58025';
export const primaryLink = '#17242c';
export const primaryLinkHover = '#ef6e0b';
export const success = '#28a745';
export const successLight = '#d2ffcc';
export const successDark = '#145222';
export const info = cyan;
export const warning = '#ffc107';
export const warningLight = '#fff3cd';
export const warningDark = '#846405';
export const danger = '#dc3545';
export const dangerLight = '#f8d7da';
export const dangerDark = '#721c24';
export const light = gray100;
export const dark = gray800;
export const grey = '#f4f4f4';
export const lightGrey = '#fff6ee';
export const inputBorderColor = '#ced4da';
export const borderFocusColor = 'rgba(239, 110, 11, 0.25)';
export const borderBottom = '#dee2e6';
export const checkIconColor = '#48b782';
export const cancelIconColor = '#19303f';

export const inputBorder = `1px solid ${inputBorderColor}`;
export const borderRadius = '2px';
export const inputFieldPlaceholder = '#7d8890';

export const iconWidthHeightDefault = 240;
export const iconBgColorDefault = 'none';

export const rgbWithOpacity = (
    color: { r: number; g: number; b: number },
    opacity: number
) => {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`;
};
export const withOpacity = (color: string, opacity: number) => {
    return `rgba(${color}, ${color}, ${color}, ${opacity})`;
};

export const inputPlaceholderStyle = {
    color: primary,
    opacity: '0.4',
};

export const inputFocusStyle = {
    borderColor: secondary,
    boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.25)',
    outline: '0',
};

export const defaultFocusStyle = (company?: customCANProps) => ({
    boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.25)',
    outlineColor: company ? company.color : secondary,
    outlineStyle: 'solid',
    transition: 'all 200ms ease-in-out',
});
