import { css } from '@emotion/react';
import { secondary, secondaryLight, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const actionButtonCss = (company?: customCANProps) =>
    css({
        background: company ? company.color : secondary,
        borderColor: company ? company.color : secondary,
        color: company ? company.textColor : white,

        '&:hover, &:focus': {
            background: company ? company.colorFaded : secondaryLight,
            borderColor: company ? company.colorFaded : secondaryLight,
        },

        '&.small': {
            maxWidth: 125,
            minWidth: 100,
            width: 'unset',
        },
    });
