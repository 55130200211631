import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { ROAD_WIDTH_LABEL, DISTANCE_FROM_ROAD_SIDE_LABEL } = PhraseKeys;
const { TYPE_ERROR, NO_NEGATIVE_NUMBERS } = FormFieldErrors;

/**
 * Validation logic
 */
export const roadSchema = (t: TFunction) => {
    return {
        roadWidth: Yup.number()
            .label(t(ROAD_WIDTH_LABEL))
            .moreThan(-1, t(NO_NEGATIVE_NUMBERS))
            .typeError(t(TYPE_ERROR))
            .nullable()
            .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
        distanceFromRoadSide: Yup.number()
            .label(t(DISTANCE_FROM_ROAD_SIDE_LABEL))
            .moreThan(-1, t(NO_NEGATIVE_NUMBERS))
            .typeError(t(TYPE_ERROR))
            .nullable()
            .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
    };
};
