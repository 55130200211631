/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { titleCss } from './PageHeader.style';

interface PageHeaderProps {
    mainTitle: string;
    subTitle: string;
}

export const PageHeader = ({ mainTitle, subTitle }: PageHeaderProps) => {
    return (
        <h1 className={'title'} css={css(titleCss)}>
            <span className={'main-title'} tabIndex={0}>
                {mainTitle}
            </span>
            {subTitle.trim() && (
                <span className={'sub-title'} tabIndex={0}>
                    {subTitle}
                </span>
            )}
        </h1>
    );
};
