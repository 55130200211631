import { CountryEnums } from '../../../modules';

export function RegistrationNumberValidationService(
    registrationNumber: string,
    registrationNumberCountryId: number
) {
    const NORWEGIAN_REG_NUM_PATTERN = /^[a-zA-Z]{2}[0-9]{4,5}$/i;
    const SWEDISH_REG_NUM_PATTERN =
        /^[a-zA-Z]{3}[0-9]{3}$|^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]$/;
    const DANISH_REG_NUM_PATTERN = /^[a-zA-Z]{2}[0-9]{3,5}$/;
    const UK_REG_NUM_PATTERN =
        /^[a-zA-Z]{1,3}[0-9]{1,3}$|^[0-9]{1,3}[a-zA-Z]{1,3}$|^[a-zA-Z]{3}[0-9]{1,3}[a-zA-Z]$|^[a-zA-Z][0-9]{1,3}[a-zA-Z]{3}$|^[a-zA-Z]{4}[0-9]$|^[0-9][a-zA-Z]{4}$|^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{3}$|^[a-zA-Z][0-9]{4}$|^[0-9]{4}[a-zA-Z]$|^[a-zA-Z]{3}[0-9]{4}$/;
    const ONLY_ZERO_PATTERN = /^[0]+$/;

    let registrationNumberUnknown = false;

    if (registrationNumber === '' || registrationNumber === null) {
        registrationNumberUnknown = true;
    }

    return {
        isValid: function isValid() {
            return isValidRegistrationNumber();
        },
    };

    function isValidRegistrationNumber() {
        const noRegNumValidation = validateNoRegNum();
        const requiredContentValidation = validateContainsContent();
        const countrySpecificValidation = validateForCountry();

        return (
            noRegNumValidation &&
            requiredContentValidation &&
            countrySpecificValidation
        );
    }

    //If registration number is unknown, then the registration number should be empty/blank
    function validateNoRegNum() {
        return (
            !registrationNumberUnknown ||
            registrationNumber === '' ||
            registrationNumber === null
        );
    }

    //If the registrationNumber is unknown, the registration number cannot be empty or only zeros
    function validateContainsContent() {
        const knownRegNumNotOnlyZeros =
            !registrationNumberUnknown &&
            registrationNumber !== '' &&
            !ONLY_ZERO_PATTERN.test(registrationNumber);
        if (knownRegNumNotOnlyZeros) {
            return true;
        }
        return registrationNumberUnknown;
    }

    function checkEnums(id: number) {
        return registrationNumberCountryId === id;
    }

    function validateForCountry() {
        const enums = [
            CountryEnums.UK,
            CountryEnums.DENMARK,
            CountryEnums.FINLAND,
            CountryEnums.NORWAY,
            CountryEnums.SWEDEN,
        ];
        const maybeProtectorCountry = enums.find(checkEnums);

        if (registrationNumberUnknown || maybeProtectorCountry === undefined) {
            return true;
        }
        if (registrationNumber === '') {
            return false;
        }
        switch (maybeProtectorCountry) {
            case 1:
                return NORWEGIAN_REG_NUM_PATTERN.test(registrationNumber);
            case 2:
                return SWEDISH_REG_NUM_PATTERN.test(registrationNumber);
            case 3:
                return DANISH_REG_NUM_PATTERN.test(registrationNumber);
            case 4:
                return true;
            case 5:
                return UK_REG_NUM_PATTERN.test(registrationNumber);
        }
        return false;
    }
}
