import { isFunction } from '../is/isFunction';
import {
    StateResetCbFnProps,
    StateResetKeysObject,
    StateResetValueResolveType,
} from '../../interfaces/types/StateReset.types';

export const stateResetMapResetKeys = (
    resetKey: string | StateResetKeysObject,
    resetValue: StateResetValueResolveType,
    newValue: any,
    oldValue: any
): StateResetCbFnProps => {
    const depth =
        typeof resetKey === 'string' ? undefined : resetKey?.depth || undefined;
    const key = typeof resetKey === 'string' ? resetKey : resetKey.key;
    const value =
        typeof resetKey === 'string'
            ? resetValue
            : isFunction(resetKey?.resetValue)
            ? resetKey?.resetValue(newValue, oldValue)
            : resetKey?.resetValue || resetValue || null;

    return { key, value, depth };
};
