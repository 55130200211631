/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { SyntheticEvent } from 'react';
import {
    FileError,
    FileErrorTypes,
    FileHandlers,
    FileModel,
    FileStatusEnums,
    MAX_FILE_SIZE_ATTACHMENT,
} from '../../../interfaces';
import bytes from 'bytes';
import { generateId } from '../../../utils';
import { fileUploaderCss } from './FileUploader.style';
import { FileDropzone } from './FileDropzone';
import { FileItem } from '../../molecules/upload/FileItem';
import { UploadDropzoneIcon } from '../icons/CommonIcons';
import { ALLOWED_MIME_TYPES } from '../../../config/allowedMimeTypes';
import { OptionType } from '../mui/muiAutocomplete/MuiAutocomplete';
import { FileCategoryEnums } from '../../../modules';

/**
 * Destructure necessary imports
 */
const { UNSUPPORTED, MAX_FILE_SIZE } = FileErrorTypes;

/**
 * Component view and logic
 */
export interface FileModelUploaderProps {
    base64?: boolean;
    categoryErrorMessage?: string;
    className?: string;
    fileCategories?: Array<{ option: OptionType | null; id: string }>;
    fileItemId?: string;
    fileItemLabel?: string;
    fileItemPlaceholder?: string;
    fileStore: any;
    handleAmountCallback?: ({
        id,
        amount,
    }: {
        id: string;
        amount: string;
    }) => void;
    handleDefinitionCallback?: ({
        id,
        definition,
    }: {
        id: string;
        definition: string;
    }) => void;
    handleFileUpdate?: Function;
    hasReceiptInformation?: boolean;
    id?: string;
    isChangeOfOwnership?: boolean;
    label: string;
    multiple?: boolean;
    noOptionsMessage?: string;
    onDelete: any;
    options?: OptionType[];
    receiptAmountErrorMessage?: string;
    receiptAmountLabel?: string;
    receiptAmountPlaceholder?: string;
    receiptDefinitionErrorMessage?: string;
    receiptDefinitionLabel?: string;
    receiptDefinitionPlaceholder?: string;
    receiptInformation?: boolean;
    requireCategory?: boolean;
    text: string;
    title: string;
}

export type FileUploaderProps = FileModelUploaderProps & FileHandlers;

export const validateFile = ({
    file,
    accept,
    maxSize,
}: {
    file: FileModel;
    accept: Array<string>;
    maxSize?: string;
}): FileError[] => {
    const err: FileError[] = [];
    const accepted = accept.find((t) => {
        if (file.name && file.name.substr(file.name.length - 4) === '.msg') {
            file.type = 'application/vnd.ms-outlook';
            return true;
        }
        const match = new RegExp(t.replace('*', '[^\\/,]+'));
        return match.test(file.type);
    });

    if (!accepted) {
        err.push({
            type: '',
            error: {
                name: 'FileValidationError',
                message: UNSUPPORTED,
                status: 400,
                statusText: UNSUPPORTED,
            },
        });
    }

    // Check single file max size
    if (maxSize && file.size > bytes(maxSize)) {
        err.push({
            type: '',
            error: {
                name: 'FileValidationError',
                message: MAX_FILE_SIZE,
                status: 400,
                statusText: MAX_FILE_SIZE,
            },
        });
    }

    return err;
};

export const addError = ({
    file,
    errors,
}: {
    file: FileModel;
    errors: FileError[];
}) => {
    return {
        ...file,
        errors: [...file.errors, ...errors],
        status: errors.length > 0 ? FileStatusEnums.ERROR : file.status,
    };
};

export const validate = (
    files: FileModel[],
    accept: string[],
    maxSize?: string
): FileModel[] => {
    return files.map((file) => {
        return addError({
            file,
            errors: validateFile({ file, accept, maxSize }),
        });
    });
};

export const processFiles = async ({
    files,
    accept,
    maxSize,
    onSuccess,
}: {
    files: FileList;
    accept: string[];
    maxSize?: string;
    onSuccess?: (files: FileModel[]) => void;
}) => {
    if (files.length === 0) {
        return;
    }

    const mappedFiles: Array<FileModel> = Array.from(files).map((file) => {
        return {
            id: generateId(),
            name: file.name,
            type: file.type,
            size: file.size,
            status: FileStatusEnums.CREATED,
            category: FileCategoryEnums.UNKNOWN,
            definition: '',
            src: { file },
            errors: [],
        };
    });

    const validatedFiles = validate(mappedFiles, accept, maxSize);

    onSuccess && onSuccess(validatedFiles);
};

export const FileUploader = ({
    categoryErrorMessage,
    className,
    fileCategories,
    fileItemId,
    fileItemLabel,
    fileItemPlaceholder,
    fileStore,
    handleAmountCallback,
    handleDefinitionCallback,
    handleFileUpdate,
    hasReceiptInformation,
    isChangeOfOwnership,
    label,
    multiple = true,
    noOptionsMessage,
    onDelete,
    onSuccess,
    options,
    receiptAmountErrorMessage,
    receiptAmountLabel,
    receiptAmountPlaceholder,
    receiptDefinitionErrorMessage,
    receiptDefinitionLabel,
    receiptDefinitionPlaceholder,
    requireCategory,
    text,
    title,
}: FileUploaderProps) => {
    const process = (files: FileList) => {
        processFiles({
            files,
            accept: ALLOWED_MIME_TYPES,
            maxSize: MAX_FILE_SIZE_ATTACHMENT,
            onSuccess,
        });
    };

    return (
        <div
            className={`file-uploader ${className}`}
            css={css(fileUploaderCss)}
        >
            <div>
                <FileDropzone onFilesAdded={process} {...{ multiple, text }}>
                    <UploadDropzoneIcon wh={50} />
                    <h2>{title}</h2>
                    <span>{label}</span>
                </FileDropzone>
                <div className={'items'}>
                    {fileStore.map((file: FileModel, idx: number) => {
                        if (
                            (isChangeOfOwnership || hasReceiptInformation) &&
                            fileCategories != undefined &&
                            handleFileUpdate != undefined
                        ) {
                            const value = fileCategories.find(
                                ({ id }) => id === file.id
                            );
                            return (
                                <FileItem
                                    enableCategories={true}
                                    file={file}
                                    id={fileItemId}
                                    key={idx}
                                    label={fileItemLabel}
                                    noOptionsText={noOptionsMessage}
                                    onChange={(
                                        e: SyntheticEvent,
                                        option: any
                                    ) => handleFileUpdate(idx, option)}
                                    onDelete={onDelete}
                                    onRemove={onDelete}
                                    openOnFocus={true}
                                    placeholder={fileItemPlaceholder}
                                    receiptInformation={hasReceiptInformation}
                                    value={value?.option}
                                    {...{
                                        categoryErrorMessage,
                                        handleAmountCallback,
                                        handleDefinitionCallback,
                                        options,
                                        receiptAmountErrorMessage,
                                        receiptAmountLabel,
                                        receiptAmountPlaceholder,
                                        receiptDefinitionErrorMessage,
                                        receiptDefinitionLabel,
                                        receiptDefinitionPlaceholder,
                                        requireCategory,
                                    }}
                                />
                            );
                        } else {
                            return (
                                <FileItem
                                    file={file}
                                    key={idx}
                                    onDelete={onDelete}
                                    onRemove={onDelete}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};
