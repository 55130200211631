import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';

export const driverInformationSchema = (t: TFunction, isUnknown: boolean = false, missingSSN: boolean = false) => {
    if (isUnknown) {
        return Yup.object().shape({});
    }

    return Yup.object().shape({
        ...nameSchema(t),
        ...(!missingSSN && nationalIdentitySchema(t)),
        ...(!missingSSN && contactDetailsSchema(t)),
    });
};
