import { CompanyModel } from './Company';
import { Nullable } from '../../../../../interfaces';
import { DamagedItemType } from './DamagedItem.model';

export interface OwnerInformationListModel extends CompanyModel {
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    street: Nullable<string>;
    zip: Nullable<string>;
    city: Nullable<string>;
    email: Nullable<string>;
    phone: Nullable<string>;
    isCompany: boolean;
    damagedItem: DamagedItemType;
}

export type OwnerInformationListTypeModel = Array<OwnerInformationListModel>;

export const initOwnerInformationList: OwnerInformationListModel = {
    city: null,
    damagedItem: null,
    email: null,
    firstName: null,
    isCompany: false,
    lastName: null,
    name: null,
    phone: null,
    street: null,
    zip: null,
};
