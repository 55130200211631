export function ssnFinlandValidateChecksum(ssn: string) {
    if (ssn.length === 0) {
        return false;
    }

    if (ssn.length !== 11) {
        return false;
    }

    const rollingId = ssn.substring(7, 10);
    const checksum = ssn.substring(10, 11);
    const cleaned = parseInt(ssn.substring(0, 6) + rollingId, 10);
    const controlNumber = cleaned % 31;

    if (controlNumber < 0 || controlNumber > 30) {
        return false;
    }

    const control = controlList.find(
        ({ key }) => key === controlNumber.toString()
    );
    if (control) {
        return control.value === checksum;
    }
}

const controlList = [
    { key: '0', value: '0' },
    { key: '1', value: '1' },
    { key: '2', value: '2' },
    { key: '3', value: '3' },
    { key: '4', value: '4' },
    { key: '5', value: '5' },
    { key: '6', value: '6' },
    { key: '7', value: '7' },
    { key: '8', value: '8' },
    { key: '9', value: '9' },
    { key: '10', value: 'A' },
    { key: '11', value: 'B' },
    { key: '12', value: 'C' },
    { key: '13', value: 'D' },
    { key: '14', value: 'E' },
    { key: '15', value: 'F' },
    { key: '16', value: 'H' },
    { key: '17', value: 'J' },
    { key: '18', value: 'K' },
    { key: '19', value: 'L' },
    { key: '20', value: 'M' },
    { key: '21', value: 'N' },
    { key: '22', value: 'P' },
    { key: '23', value: 'R' },
    { key: '24', value: 'S' },
    { key: '25', value: 'T' },
    { key: '26', value: 'U' },
    { key: '27', value: 'V' },
    { key: '28', value: 'W' },
    { key: '29', value: 'X' },
    { key: '30', value: 'Y' },
];
