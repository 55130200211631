import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { validateAccountNumberBBANSE } from '@protectorinsurance/ds-can';

export default function normalBankAccountNumber(
    this: StringSchema,
    key: string,
    message: string = yupCustomLocale.string.normalBankAccountNumber
) {
    // @ts-ignore
    return this.test('normalBankAccountNumber', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        if (value === undefined || value === null) {
            return false;
        }
        return validateAccountNumberBBANSE(value).isValid();
    });
}
