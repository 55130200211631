import { Nullable } from '../../../../../interfaces';

export enum TypeOfPropertyKeys {
    APARTMENT_BUILDING = 'APARTMENT_BUILDING',
    BUSINESS_INTERRUPTION = 'BUSINESS_INTERRUPTION',
    CONDOMINIUM = 'CONDOMINIUM',
    CONTRACT_WORKS = 'CONTRACT_WORKS',
    INDUSTRIAL_PROPERTY = 'INDUSTRIAL_PROPERTY',
    LOCAL = 'LOCAL',
    OTHER = 'OTHER',
    PROPERTY_DAMAGE = 'PROPERTY_DAMAGE',
    PUBLIC_PROPERTY = 'PUBLIC_PROPERTY',
    TENANCY = 'TENANCY',
    VILLA = 'VILLA',
}

export type TypeOfPropertyTypeModel = Nullable<TypeOfPropertyKeys>;
