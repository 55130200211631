import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';

// Dynamic Imports
import { DynCarouselPage } from 'pages/DynCarouselPage';

// Start Imports
import { StartOnboardingPage } from '../pages/lpo/start/Onboarding';
import { StartPrivacyPage } from '../pages/lpo/start/Privacy';
import { StartClaimTypePage } from '../pages/lpo/start/ClaimType';
import { StartClaimCausePage } from '../pages/lpo/start/ClaimCause';
import { StartClaimDatePage } from '../pages/lpo/start/ClaimDate';

// Property Imports
import { PropertyPolicyNumberPage } from '../pages/lpo/property/PolicyNumber';
import { PropertyTypeOfPropertyPage } from '../pages/lpo/property/TypeOfProperty';
import { PropertyContractorInformationPage } from '../pages/lpo/property/ContractorInformation';

// Liability Imports
import { LiabilityClaimantInformationPage } from '../pages/lpo/liability/ClaimantInformation';
import { LiabilityUploadAttachmentPage } from '../pages/lpo/liability/UploadAttachment';
import { LiabilityClaimDescriptionPage } from 'pages/lpo/liability/ClaimDescription';

// Legal Imports
import { LegalClaimDatePage } from '../pages/lpo/legal/ClaimDate';
import { LegalAgentInformationPage } from '../pages/lpo/legal/AgentInformation';
import { LegalAgentRequiredPage } from '../pages/lpo/legal/AgentRequired';
import { LegalPolicyNumberPage } from '../pages/lpo/legal/PolicyNumber';
import { LegalPolicyHolderPage } from '../pages/lpo/legal/PolicyHolder';
import { LegalClaimDescriptionPage } from '../pages/lpo/legal/ClaimDescription';
import { LegalUploadAttachmentPage } from '../pages/lpo/legal/UploadAttachment';

// Change Of Ownership Imports
import { ChangeOfOwnershipSellerInformationPage } from '../pages/lpo/changeOfOwnership/SellerInformation';
import { ChangeOfOwnershipPolicyNumberPage } from '../pages/lpo/changeOfOwnership/PolicyNumber';
import { ChangeOfOwnershipAccidentLocationPage } from '../pages/lpo/changeOfOwnership/AccidentLocation';
import { ChangeOfOwnershipPropertyAddressPage } from '../pages/lpo/changeOfOwnership/PropertyAddress';
import { ChangeOfOwnershipClaimDatePage } from '../pages/lpo/changeOfOwnership/ClaimDate';
import { ChangeOfOwnershipReportedToSellerDatePage } from '../pages/lpo/changeOfOwnership/ReportedToSellerDate';
import { ChangeOfOwnershipClaimDescriptionPage } from '../pages/lpo/changeOfOwnership/ClaimDescription';
import { ChangeOfOwnershipCostPage } from '../pages/lpo/changeOfOwnership/Cost';
import { ChangeOfOwnershipUploadAttachmentPage } from '../pages/lpo/changeOfOwnership/UploadAttachment';
import { ChangeOfOwnershipReporterInformationPage } from '../pages/lpo/changeOfOwnership/ReporterInformation';

// End Imports
import { EndAccidentLocationPage } from 'pages/lpo/end/AccidentLocation';
import { EndPropertyAddressPage } from '../pages/lpo/end/PropertyAddress';
import { EndClaimDescriptionPage } from '../pages/lpo/end/ClaimDescription';
import { EndUploadAttachmentPage } from '../pages/lpo/end/UploadAttachment';
import { EndOtherInsuranceCompanyPage } from '../pages/lpo/end/OtherInsuranceCompany';
import { EndPolicyHolderPage } from 'pages/lpo/end/PolicyHolder';
import { EndReporterInformationPage } from '../pages/lpo/end/ReporterInformation';
import { EndPolicyHoldersContactPage } from '../pages/lpo/end/PolicyHoldersContact';
import { EndAddBankAccountPage } from '../pages/lpo/end/AddBankAccount';
import { EndFinalSummaryPage } from '../pages/lpo/end/FinalSummary';
import { EndReportCompletedPage } from '../pages/lpo/end/ReportCompleted';
import { ChangeOfOwnershipClaimantInformationPage } from '../pages/lpo/changeOfOwnership/ClaimantInformation';
import { EndLandlordInformationPage } from '../pages/lpo/end/LandlordInformation';

export const Lpo: FC = () => {
    return (
        <Switch>
            {/* DYN */}
            <Route exact path={LpoRoutePaths.DYN_CAROUSEL} component={DynCarouselPage} />

            {/* START */}
            <Route exact path={LpoRoutePaths.INDEX} component={StartOnboardingPage} />
            <Route exact path={LpoRoutePaths.START_ONBOARDING} component={StartOnboardingPage} />
            <Route exact path={LpoRoutePaths.START_PRIVACY} component={StartPrivacyPage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_TYPE} component={StartClaimTypePage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_CAUSE} component={StartClaimCausePage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_DATE} component={StartClaimDatePage} />

            {/* PROPERTY */}
            <Route exact path={LpoRoutePaths.PROPERTY_POLICY_NUMBER} component={PropertyPolicyNumberPage} />
            <Route exact path={LpoRoutePaths.PROPERTY_TYPE_OF_PROPERTY} component={PropertyTypeOfPropertyPage} />
            <Route
                exact
                path={LpoRoutePaths.PROPERTY_CONTRACTOR_INFORMATION}
                component={PropertyContractorInformationPage}
            />

            {/* LIABILITY */}
            <Route
                exact
                path={LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION}
                component={LiabilityClaimantInformationPage}
            />
            <Route exact path={LpoRoutePaths.LIABILITY_UPLOAD_ATTACHMENT} component={LiabilityUploadAttachmentPage} />
            <Route exact path={LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION} component={LiabilityClaimDescriptionPage} />

            {/* LEGAL */}
            <Route exact path={LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DATE} component={LegalClaimDatePage} />
            <Route exact path={LpoRoutePaths.LEGAL_EXPENSES_AGENT_INFORMATION} component={LegalAgentInformationPage} />
            <Route exact path={LpoRoutePaths.LEGAL_EXPENSES_AGENT_REQUIRED} component={LegalAgentRequiredPage} />
            <Route exact path={LpoRoutePaths.LEGAL_EXPENSES_POLICY_NUMBER} component={LegalPolicyNumberPage} />
            <Route exact path={LpoRoutePaths.LEGAL_EXPENSES_POLICY_HOLDER} component={LegalPolicyHolderPage} />
            <Route exact path={LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DESCRIPTION} component={LegalClaimDescriptionPage} />
            <Route exact path={LpoRoutePaths.LEGAL_EXPENSES_UPLOAD_ATTACHMENT} component={LegalUploadAttachmentPage} />

            {/* CHANGE OF OWNERSHIP */}
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_SELLER_INFORMATION}
                component={ChangeOfOwnershipSellerInformationPage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIMANT_INFORMATION}
                component={ChangeOfOwnershipClaimantInformationPage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_POLICY_NUMBER}
                component={ChangeOfOwnershipPolicyNumberPage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_ACCIDENT_LOCATION}
                component={ChangeOfOwnershipAccidentLocationPage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_PROPERTY_ADDRESS}
                component={ChangeOfOwnershipPropertyAddressPage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DATE}
                component={ChangeOfOwnershipClaimDatePage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTED_TO_SELLER_DATE}
                component={ChangeOfOwnershipReportedToSellerDatePage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DESCRIPTION}
                component={ChangeOfOwnershipClaimDescriptionPage}
            />
            <Route exact path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_COST} component={ChangeOfOwnershipCostPage} />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_UPLOAD_ATTACHMENT}
                component={ChangeOfOwnershipUploadAttachmentPage}
            />
            <Route
                exact
                path={LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTER_INFORMATION}
                component={ChangeOfOwnershipReporterInformationPage}
            />

            {/* END */}
            <Route exact path={LpoRoutePaths.END_ACCIDENT_LOCATION} component={EndAccidentLocationPage} />
            <Route exact path={LpoRoutePaths.END_PROPERTY_ADDRESS} component={EndPropertyAddressPage} />
            <Route exact path={LpoRoutePaths.END_CLAIM_DESCRIPTION} component={EndClaimDescriptionPage} />
            <Route exact path={LpoRoutePaths.END_UPLOAD_ATTACHMENT} component={EndUploadAttachmentPage} />
            <Route exact path={LpoRoutePaths.END_OTHER_INSURANCE_COMPANY} component={EndOtherInsuranceCompanyPage} />
            <Route exact path={LpoRoutePaths.END_POLICY_HOLDER} component={EndPolicyHolderPage} />
            <Route exact path={LpoRoutePaths.END_REPORTER_INFORMATION} component={EndReporterInformationPage} />
            <Route exact path={LpoRoutePaths.END_LANDLORD_INFORMATION} component={EndLandlordInformationPage} />
            <Route exact path={LpoRoutePaths.END_POLICY_HOLDERS_CONTACT} component={EndPolicyHoldersContactPage} />
            <Route exact path={LpoRoutePaths.END_ADD_BANK_ACCOUNT} component={EndAddBankAccountPage} />
            <Route exact path={LpoRoutePaths.END_FINAL_SUMMARY} component={EndFinalSummaryPage} />
            <Route exact path={LpoRoutePaths.END_REPORT_COMPLETED} component={EndReportCompletedPage} />
        </Switch>
    );
};
