import { VehicleModel, VehiclesModel } from '../../interfaces';
import { spreadNewVehicle } from './spreadNewVehicle';

export const updateExistingVehicle = (
    vehicles: VehiclesModel,
    existingVehicle: Partial<VehicleModel>,
    matchFn?: (v: VehicleModel) => boolean
) => {
    return vehicles.map((vehicle) => {
        const match = matchFn
            ? matchFn(vehicle)
            : vehicle.registrationNumber === existingVehicle.registrationNumber;

        return match ? { ...spreadNewVehicle(existingVehicle) } : vehicle;
    });
};
