import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    BodyAndTeethIcon,
    BodyIcon,
    Carousel,
    CarouselItem,
    Clickable,
    is,
    PageLayout,
    StandardModal,
    TeethInjuryIcon,
    TeethOrBodyKeys,
    TeethOrBodyModel,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectPerson, selectTeethOrBody } from '../../../sagas/selectors/personSelectors';
import { useGoBack } from '../../../hooks/useGoBack';
import { personActions } from '../../../sagas/person';
import { resetActions } from '../../../sagas/reset';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure models, phrases etc.
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SELECT_NO, SELECT_YES, SUB_TITLE, TITLE } = PhraseKeys;
const { BODY, BOTH, TEETH } = TeethOrBodyKeys;

/**
 * Page view and page logic
 */
export const AccidentTeethOrBodyPage = () => {
    const dispatch = useDispatch();
    const personState = useSelector(selectPerson);
    const teethOrBody = useSelector(selectTeethOrBody);
    const customCAN = useSelector(selectCustomCAN);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempId, setTempId] = useState<TeethOrBodyModel>(null);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.teethOrBody');

    const options = [
        {
            icon: <BodyIcon />,
            id: BODY,
            name: tWithNS.t(`select.${BODY}`),
        },
        {
            icon: <TeethInjuryIcon />,
            id: TEETH,
            name: tWithNS.t(`select.${TEETH}`),
        },
        {
            icon: <BodyAndTeethIcon />,
            id: BOTH,
            name: tWithNS.t(`select.${BOTH}`),
        },
    ];

    const handleBackButton = useGoBack();

    const handleSelected = (id: TeethOrBodyKeys, e: Clickable) => {
        e.preventDefault();
        const option = options.find((x) => x.id === id);

        if (option) {
            if (!is(teethOrBody, null) && !is(option.id, teethOrBody) && !is(option.id, BOTH)) {
                setTempId(option.id);
                setIsModalOpen((_) => true);
            } else {
                dispatch(wizardActions.skipBackToPrev(true));

                dispatcherWithPromise(dispatch, personActions.update, { teethOrBody: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(() => false);
    };

    const handleModalConfirm = () => {
        setIsModalOpen(() => false);
        dispatch(wizardActions.skipBackToPrev(true));
        dispatch(
            resetActions.resetState({
                key: 'person',
                value: {
                    ...personState,
                    bodyPartList: [],
                    claimCause: null,
                    claimCauseConsequences: [],
                    dateOfPreviousInjury: null,
                    dateOfTreatment: null,
                    dentist: null,
                    finishedTreatment: null,
                    invalidity: null,
                    isDentistContacted: null,
                    isDoctorContacted: null,
                    previousInjury: null,
                    teeth: [],
                    typeOfMedicalInstitution: null,
                },
            })
        );
        dispatcherWithPromise(dispatch, personActions.update, { teethOrBody: tempId })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext(tempId)));
    };

    const renderSelection = options.map((option, idx) => (
        <CarouselItem
            {...option}
            handleClick={handleSelected}
            isSelected={option.id === teethOrBody}
            key={idx}
            {...{ customCAN }}
        />
    ));

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <Carousel>{renderSelection}</Carousel>
            {isModalOpen && (
                <StandardModal
                    closeButtonText={t(SELECT_NO)}
                    confirmButtonText={t(SELECT_YES)}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={tWithNS.t('modal.title')}
                >
                    <div className={'dialog__content text-center'}>{tWithNS.t('modal.question')}</div>
                </StandardModal>
            )}
        </PageLayout>
    );
};
