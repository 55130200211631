import { isNumber } from '../../../utils';
import { businessNumberDenmarkValidateChecksum } from './businessNumberDenmarkValidateChecksum';

export function businessNumberDenmarkValidator(businessNumber: string) {
    return {
        isValid: function isValid() {
            if (!businessNumber || !isNumber(businessNumber)) {
                return false;
            }

            return (
                businessNumber.length === 8 &&
                businessNumberDenmarkValidateChecksum(businessNumber)
            );
        },
    };
}
