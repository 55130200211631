import { TFunction } from 'i18next';
import { AddBankAccountEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
const createAddBankAccountKey = (id: string) => {
    return `lpo.addBankAccount.select.${id}`;
};

export const getAddBankAccountOption = (t: TFunction, key: AddBankAccountEnums) => {
    return {
        value: key,
        label: t(createAddBankAccountKey(key)),
    };
};
