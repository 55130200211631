import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { RegistrationNumberValidationService } from '@protectorinsurance/ds-can';

export default function registrationNumber(
    this: StringSchema,
    message: string = yupCustomLocale.string.registrationNumber
) {
    // @ts-ignore
    return this.test('registrationNumber', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        return RegistrationNumberValidationService(value, 2).isValid();
    });
}
