import { css } from '@emotion/react';

export const buttonBase = css({
    borderRadius: '2px',
    minWidth: '200px',
    width: '200px',
    textAlign: 'center',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    border: '1px solid transparent',
    transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
    boxShadow: '0.15s ease-in-out',
    cursor: 'pointer',

    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',

    '&:disabled': {
        opacity: '0.2',
    },

    '&:focus': {
        outline: '0',
    },

    '&.center-button': {
        margin: '0 auto',

        '@media screen and (max-width: 1439px)': {
            maxWidth: '50%',
            minWidth: 150,
        },
    },
});
