import React from 'react';
import { HospitalisationInformationModel } from '../../models/person/HospitalisationInformation';
import { CalendarIcon, Clickable } from '@protectorinsurance/ds-can';
import { SWE_DATE_TIME_FORMAT } from 'config/locale';
import moment from 'moment';

/**
 * Destructure necessary imports
 */

export interface HospitalisationListItemProps {
    hospitalisation: HospitalisationInformationModel;
    icon: React.ReactNode;
    handleDelete: (e: Clickable, hospitalisation: HospitalisationInformationModel) => void;
    buttonText: string;
    fromText: string;
    toText: string;
    atText: string;
}

export const HospitalisationInformationListItem = ({
    hospitalisation,
    handleDelete,
    buttonText,
    fromText,
    toText,
    atText,
}: HospitalisationListItemProps) => {
    return (
        <>
            <div className={'col-3 divider'}>
                <CalendarIcon wh={40} />
            </div>
            <div className={'col-6 divider'}>
                <span>
                    {fromText}
                    {hospitalisation.startDate && moment(hospitalisation.startDate).format(SWE_DATE_TIME_FORMAT)}
                    {toText}
                    {hospitalisation.endDate && moment(hospitalisation.endDate).format(SWE_DATE_TIME_FORMAT)}
                    {atText}
                    {hospitalisation.hospitalName}
                </span>
            </div>
            <div className={'col-3 divider'}>
                <button
                    type={'button'}
                    data-testid={'delete-btn'}
                    className={'delete-btn'}
                    onClick={(e) => handleDelete(e, hospitalisation)}
                >
                    {buttonText}
                </button>
            </div>
        </>
    );
};
