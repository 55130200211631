import {
    actionWithPromise,
    AgentInformationModel,
    ClaimantInformationListTypeModel,
    ClaimantModel,
    ClaimDescriptionTypeModel,
    ClaimReporterRoleTypeModel,
    ClaimTypeTypeModel,
    CompanyModel,
    ContractorInformationModel,
    CostTypeModel,
    Datable,
    emptyFn,
    initClaimantModel,
    initLandlordInformation,
    LandlordInformationModel,
    LocationModel,
    LpoClaimCauseTypeModel,
    Nullable,
    OtherInsuranceCompanyTypeModel,
    PolicyHoldersContactModel,
    PrivacyTypeModel,
    Rejectable,
    ReporterInformationModel,
    Resolvable,
    SellerInformationModel,
    TypeOfPropertyTypeModel,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { put, takeEvery } from 'redux-saga/effects';
import { BankAccountInformationModel } from '../models/person/BankAccountInformation';

/**
 * Constants
 */
export enum LpoActionTypes {
    UPDATE = '@lpo/UPDATE',
    UPDATED = '@lpo/UPDATED',
}

/**
 * Interfaces
 */
export interface LpoAction {
    type: LpoActionTypes;
    data?: Partial<LpoState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface LpoState {
    acceptedPoliceContact: PrivacyTypeModel;
    acceptedPrivacy: PrivacyTypeModel;
    accidentLocation: LocationModel;
    agentInformation: AgentInformationModel;
    bankAccountInformation: BankAccountInformationModel;
    claimCause: LpoClaimCauseTypeModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimReporterRole: ClaimReporterRoleTypeModel;
    claimType: ClaimTypeTypeModel;
    claimantInformationList: ClaimantInformationListTypeModel;
    claimantInformation: ClaimantModel;
    companyInformation: CompanyModel;
    contractorInformation: ContractorInformationModel;
    cost: CostTypeModel;
    externalReference: Nullable<string>;
    hasActiveOpposition: YesNoModel;
    hasClaim: YesNoModel;
    hasContractWithOtherPart: YesNoModel;
    hasDemands: YesNoModel;
    hasVAT: YesNoModel;
    isContractorContacted: YesNoModel;
    landlordInformation: LandlordInformationModel;
    liabilityClaimDescription: ClaimDescriptionTypeModel;
    otherInsurance: YesNoModel;
    otherInsuranceCompany: OtherInsuranceCompanyTypeModel;
    policyHoldersContact: PolicyHoldersContactModel;
    reportedToInsurersInsurance: YesNoModel;
    reportedToSellerDate: Datable;
    reporterInformation: ReporterInformationModel;
    sellerInformation: SellerInformationModel;
    typeOfProperty: TypeOfPropertyTypeModel;
}

/**
 * Initial State
 */
export const lpoInitState: LpoState = {
    acceptedPoliceContact: false,
    acceptedPrivacy: false,
    accidentLocation: {
        isUnknownLocation: false,
        latitude: null,
        longitude: null,
        note: null,
        unit: null,
    },
    agentInformation: {
        businessNumber: null,
        email: null,
        firstName: null,
        lastName: null,
        noAgent: false,
        phone: null,
    },
    bankAccountInformation: {
        ownerGivenName: null,
        ownerFamilyName: null,
        bankName: null,
        clearingNumber: null,
        bankAccountType: null,
        accountNumber: null,
    },
    claimCause: null,
    claimDate: null,
    claimDescription: '',
    claimReporterRole: null,
    claimType: null,
    claimantInformationList: [],
    claimantInformation: initClaimantModel,
    companyInformation: {
        name: null,
        businessNumber: null,
        policyNumber: null,
    },
    contractorInformation: {
        name: null,
        businessNumber: null,
        reason: null,
    },
    cost: null,
    externalReference: null,
    hasActiveOpposition: null,
    hasClaim: null,
    hasContractWithOtherPart: null,
    hasDemands: null,
    hasVAT: null,
    isContractorContacted: null,
    landlordInformation: initLandlordInformation,
    liabilityClaimDescription: '',
    otherInsurance: null,
    otherInsuranceCompany: null,
    policyHoldersContact: {
        email: null,
        firstName: null,
        isReporter: false,
        lastName: null,
        phone: null,
    },
    reportedToInsurersInsurance: null,
    reportedToSellerDate: null,
    reporterInformation: {
        dateOfBirth: null,
        email: null,
        firstName: null,
        isDriver: false,
        lastName: null,
        missingSSN: false,
        nationalIdentity: null,
        phone: null,
    },
    sellerInformation: {
        firstName: null,
        lastName: null,
    },
    typeOfProperty: null,
};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export default function (state = lpoInitState, { type, data }: LpoAction) {
    switch (type) {
        case LpoActionTypes.UPDATED:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const lpoActions = {
    update: actionWithPromise<LpoActionTypes.UPDATE, Partial<LpoState>>(LpoActionTypes.UPDATE),
    updated: actionWithPromise<LpoActionTypes.UPDATED, Partial<LpoState>>(LpoActionTypes.UPDATED),
};

/**
 * Saga watchers
 */
export const lpoWatcher = function* () {
    yield takeEvery(LpoActionTypes.UPDATE, lpoSagas.update);
};

/**
 * Saga functions
 */
export const lpoSagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: LpoAction) {
        try {
            yield put(lpoActions.updated({ ...data }));
            resolve();
        } catch (e) {
            reject();
        }
    },
};
