import { call, put, takeLatest } from 'redux-saga/effects';
import {
    Rejectable,
    Resolvable,
} from '../../../../interfaces/types/Promisable.types';
import { RegistrationNumberModel } from '../interfaces/models/RegistrationNumber.model';
import { actionWithPromise } from '../../common/utils/actionWithPromise';
import { Nullable } from '../../../../interfaces/types/Nullable.types';
import { CANRequestError } from '../../common/interfaces/models/CANRequestError.model';
import { apiService, APPLICATION_JSON_HEADER } from '../../common/utils/api';
import { emptyFn } from '../../../../utils/emptyFn';
import { VehicleServiceModel } from '../interfaces/models/VehicleService.model';
import { VehicleServiceActionDataModel } from '../interfaces/models/VehicleServiceActionData.model';
import { VehicleErrorEnums } from '../interfaces/enums';
import { HttpStatusCodeEnums } from '../../common/interfaces/enums';

/**
 * Action Types
 */
export enum VehicleServiceActionTypes {
    REQUEST = '@app/vehicle/REQUEST',
    REQUEST_SEND = '@app/vehicle/REQUEST_SEND',
    SUCCESS = '@app/vehicle/SUCCESS',
    FAILURE = '@app/vehicle/FAILURE',
    INTIALIZE = '@app/vehicle/INITIALIZE',
}

/**
 * Action Definitions
 */
export interface VehicleServiceAction {
    type: VehicleServiceActionTypes;
    data?: VehicleServiceActionDataModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const vehicleServiceActions = {
    request: actionWithPromise<
        VehicleServiceActionTypes,
        VehicleServiceActionDataModel
    >(VehicleServiceActionTypes.REQUEST),
    requestSend: actionWithPromise<
        VehicleServiceActionTypes,
        VehicleServiceActionDataModel
    >(VehicleServiceActionTypes.REQUEST_SEND),
    success: actionWithPromise<VehicleServiceActionTypes, VehicleServiceModel>(
        VehicleServiceActionTypes.SUCCESS
    ),
    failure: actionWithPromise<VehicleServiceActionTypes, CANRequestError>(
        VehicleServiceActionTypes.FAILURE
    ),
    initialize: actionWithPromise<
        VehicleServiceActionTypes,
        VehicleServiceActionDataModel
    >(VehicleServiceActionTypes.INTIALIZE),
};

export interface VehicleState extends RegistrationNumberModel {
    initiated: boolean;
    loading: boolean;
    data: Nullable<VehicleServiceModel>;
    errors: Nullable<CANRequestError>;
}

export const vehicleInitState = {
    registrationNumber: null,
    data: null,
    loading: false,
    initiated: false,
    errors: null,
};
export const vehicleServiceReducer = (
    state = vehicleInitState,
    { type, data }: VehicleServiceAction
) => {
    switch (type) {
        case VehicleServiceActionTypes.FAILURE:
            return { ...state, errors: data, loading: false, initiated: false };
        case VehicleServiceActionTypes.REQUEST:
            return {
                registrationNumber: null,
                data: null,
                errors: null,
                loading: null,
                initiated: true,
            };
        case VehicleServiceActionTypes.REQUEST_SEND:
            return {
                ...state,
                errors: null,
                loading: true,
                initiated: false,
                registrationNumber: data ? data.registrationNumber : null,
                data: null,
            };
        case VehicleServiceActionTypes.SUCCESS:
            return {
                ...state,
                data,
                loading: false,
                errors: null,
                initiated: false,
            };
        case VehicleServiceActionTypes.INTIALIZE:
            return {
                registrationNumber: null,
                data: null,
                errors: null,
                loading: null,
                initiated: false,
            };
        default:
            return state;
    }
};

/**
 * Saga watchers
 */
export const vehicleWatcher = function* () {
    yield takeLatest(
        VehicleServiceActionTypes.REQUEST,
        vehicleServiceSagas.request
    );
    yield takeLatest(
        VehicleServiceActionTypes.REQUEST_SEND,
        vehicleServiceSagas.requestSend
    );
    yield takeLatest(
        VehicleServiceActionTypes.INTIALIZE,
        vehicleServiceSagas.initialize
    );
};

/**
 * Saga functions
 */
export const vehicleServiceSagas = {
    *request({
        data,
        resolve = emptyFn,
        reject = emptyFn,
    }: VehicleServiceAction) {
        yield put(vehicleServiceActions.requestSend(data, resolve, reject));
    },

    *requestSend({
        data,
        resolve = emptyFn,
        reject = emptyFn,
    }: VehicleServiceAction) {
        try {
            if (data) {
                // @ts-ignore
                const res = yield call(
                    apiService.get,
                    `vehicles/${data.country}/${data.registrationNumber}`,
                    {
                        headers: APPLICATION_JSON_HEADER,
                    }
                );
                if (res.status === HttpStatusCodeEnums.NO_CONTENT) {
                    yield put(
                        vehicleServiceActions.failure({
                            name: VehicleErrorEnums.NO_CONTENT,
                            message: VehicleErrorEnums.NOT_FOUND,
                            status: res.status,
                            statusText: res.statusText,
                        })
                    );
                } else {
                    const responseData = res.data.data;
                    yield put(vehicleServiceActions.success(responseData));
                    yield resolve(responseData);
                }
            }
            // do nothing when there is no registrationNr
        } catch (e) {
            yield put(vehicleServiceActions.failure(e));
            yield reject(e);
        }
    },

    *initialize({ resolve = emptyFn }: VehicleServiceAction) {
        yield resolve();
    },
};
