import { Nullable } from '@protectorinsurance/ds-can';

export const prefixPostFixUtil = (name: Nullable<string>, prefix?: string, postfix?: string) => {
    if (name === null) {
        return '';
    } else if (prefix === undefined && postfix === undefined) {
        return name;
    } else if (prefix === undefined) {
        return [name, postfix].join('.');
    } else if (postfix === undefined) {
        return [prefix, name].join('.');
    } else {
        return [prefix, name, postfix].join('.');
    }
};
