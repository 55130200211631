import React, { MouseEvent } from 'react';
import { SmallPersonIcon } from '@protectorinsurance/ds-can';
import './EstateExecutorList.scss';
import { EstateExecutorModel } from '../../models/person/EstateExecutors';

interface EstateExecutorListProps {
    persons: EstateExecutorModel[];
    handleDelete(e: MouseEvent, person: EstateExecutorModel): void;
    buttonText: string;
}

/**
 * Component view and component logic
 */
export const EstateExecutorList = ({ persons, handleDelete, buttonText }: EstateExecutorListProps) => {
    const renderPersonList = persons.map((person: EstateExecutorModel, idx) => {
        return (
            <li key={idx} className={'estate-executor-list-item'}>
                <SmallPersonIcon />
                <span className={'list-text'} data-hj-suppress>
                    {person.firstName} {person.lastName}
                </span>
                <button
                    type={'button'}
                    data-testid={'delete-btn'}
                    className={'delete-btn'}
                    onClick={(e) => handleDelete(e, person)}
                >
                    {buttonText}
                </button>
            </li>
        );
    });

    return (
        <ul className={'list-container'} data-testid={'person-list'}>
            {renderPersonList}
        </ul>
    );
};
