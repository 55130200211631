import { Period } from '@protectorinsurance/ds-can';
import { HospitalisationFormModel } from '../../pages/person/illness/HospitalisationInformation';
import moment from 'moment';

export const periodIsBefore = (period: HospitalisationFormModel, existingPeriod: Period): boolean => {
    return (
        !!period.endDate &&
        !!period.startDate &&
        !!existingPeriod.startDate &&
        moment(period.startDate).isBefore(existingPeriod.startDate) &&
        moment(period.endDate).isBefore(existingPeriod.startDate)
    );
};

export const periodIsAfter = (period: HospitalisationFormModel, existingPeriod: Period): boolean => {
    return (
        !!period.startDate &&
        !!period.endDate &&
        !!existingPeriod.endDate &&
        moment(period.startDate).isAfter(existingPeriod.endDate) &&
        moment(period.endDate).isAfter(existingPeriod.endDate)
    );
};

export const isOverlappingPeriod = (period: HospitalisationFormModel, existingPeriods: Period[] = []): boolean => {
    const periodsOverlapping = existingPeriods.filter((p: Period) => {
        return (
            period.startDate &&
            period.endDate &&
            p.startDate &&
            p.endDate &&
            !periodIsBefore(period, p) &&
            !periodIsAfter(period, p)
        );
    });
    return periodsOverlapping.length !== 0;
};
