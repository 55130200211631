import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from 'config/phraseKeys';
import useErrorLoader from 'hooks/useErrorSelector';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import { selectSchoolInformation } from '../../../sagas/selectors/personSelectors';
import { useGoBack } from '../../../hooks/useGoBack';
import dispatcherWithPromise from 'utils/dispatcherWithPromise';
import { personActions } from '../../../sagas/person';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiAutocomplete,
    MuiTextInput,
    MunicipalityModel,
    MunicipalityServiceModel,
    Nullable,
    OptionType,
    PageLayout,
    SchoolInformationModel,
} from '@protectorinsurance/ds-can';
import { selectMunicipalityService } from '../../../sagas/selectors/municipalitySelectors';
import {
    municipalityServiceActions,
    MunicipalityServiceActionTypes,
} from '../../../sagas/services/municipalityService';
import { ToggleKeys } from 'models/common/Toggle';
import { schoolInformationSchema } from 'validations/schemas/schoolInformationSchema';
import { toggle } from '../../../utils/toggle';
import { zipCodeToPlace } from '../../../utils/zipCodeToPlace';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { CITY, MUNICIPALITY, NAME, STREET, ZIP } = FormFieldNames;
const { MUNICIPALITY_SERVICE } = ToggleKeys;
const {
    BACK_BUTTON,
    CITY_LABEL,
    CITY_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    MUNICIPALITY_LABEL,
    MUNICIPALITY_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SCHOOL_NAME_LABEL,
    SCHOOL_NAME_PLACEHOLDER,
    STREET_LABEL,
    STREET_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
    ZIP_LABEL,
    ZIP_PLACEHOLDER,
} = PhraseKeys;
const { CITY: CITY_AUTOCOMPLETE, POSTAL_CODE, STREET_ADDRESS } = AutocompleteValueKeys;

/**
 * Page view and page logic
 */
export const AccidentSchoolInformationPage = () => {
    const [municipalities, setMunicipalities] = useState<MunicipalityModel[]>([]);
    const [selectedMunicipality, setSelectedMunicipality] = useState<Nullable<MunicipalityModel>>(null);
    const { error: serviceError } = useErrorLoader(MunicipalityServiceActionTypes.FAILURE);
    const dispatch = useDispatch();
    const schoolInformation = useSelector(selectSchoolInformation);
    const municipalityService = useSelector(selectMunicipalityService);
    const customCAN = useSelector(selectCustomCAN);
    const municipality = schoolInformation.municipality;
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.schoolInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        trigger,
    } = useForm<SchoolInformationModel>({
        resolver: yupResolver(schoolInformationSchema(t)),
        defaultValues: {
            ...schoolInformation,
            city: schoolInformation.city ? schoolInformation.city : '',
        },
    });

    /**
     * Effects
     */
    useEffect(() => {
        register(MUNICIPALITY);
    }, [register]);

    useEffect(() => {
        dispatch(municipalityServiceActions.request());
    }, [dispatch]);

    useEffect(() => {
        setMunicipalities(
            municipalityService
                .map((m: MunicipalityServiceModel) => ({ value: m.external_id.trim(), label: m.name }))
                .sort((a: MunicipalityModel, b: MunicipalityModel) => a.label.localeCompare(b.label))
        );
    }, [municipalityService]);

    useEffect(() => {
        const selected = municipalities.find((x) => x.label === municipality);
        if (selected) {
            setSelectedMunicipality(selected);
        }
    }, [municipalities, setSelectedMunicipality, municipality]);

    /**
     * Handler functions
     */
    const handleBackButton = useGoBack();

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        setSelectedMunicipality(object);

        if (object) {
            const value = object.label;
            setValue('municipality', value, { shouldValidate: true });
            dispatcherWithPromise(dispatch, personActions.update, {
                schoolInformation: { municipality: value },
            });
        } else {
            setSelectedMunicipality(null);
            setValue('municipality', '', { shouldValidate: true });
            dispatcherWithPromise(dispatch, personActions.update, {
                schoolInformation: { municipality: null },
            });
        }
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const handleZipCodeChange = (e: FormChangeable) => {
        const value = e.target.value;

        const city = zipCodeToPlace(value);
        setValue('zip', value);
        setValue('city', city);
    };

    const onSubmit = (values: SchoolInformationModel) => {
        dispatcherWithPromise(dispatch, personActions.update, { schoolInformation: values })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    /**
     * Renders
     */
    const renderAutocomplete = () => (
        <MuiAutocomplete
            error={!!errors.municipality}
            errorMessage={errors.municipality?.message}
            id={MUNICIPALITY}
            inputFieldWrapper={'col-6'}
            label={t(MUNICIPALITY_LABEL)}
            noOptionsText={t(NO_OPTIONS_MESSAGE)}
            onBlur={handleBlur}
            onChange={handleSelect}
            openOnFocus={true}
            options={municipalities}
            placeholder={t(MUNICIPALITY_PLACEHOLDER)}
            value={selectedMunicipality}
            {...{ customCAN }}
        />
    );

    const renderMunicipalityInput = () => (
        <Controller
            control={control}
            name={MUNICIPALITY}
            render={({ field: { ref, ...field } }) => (
                <MuiTextInput
                    {...field}
                    error={!!errors.municipality}
                    errorMessage={errors.municipality?.message}
                    id={MUNICIPALITY}
                    inputFieldWrapper={'col-6'}
                    label={t(MUNICIPALITY_LABEL)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={t(MUNICIPALITY_PLACEHOLDER)}
                    reference={ref}
                    {...{ customCAN }}
                />
            )}
        />
    );

    return (
        <>
            <PageLayout
                backBtnText={t(BACK_BUTTON)}
                continueBtnText={t(CONTINUE_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerText={tWithNS.t(HELP_TEXT)}
                handleContinueButton={handleSubmit(onSubmit)}
                headerSubTitle={tWithNS.t(SUB_TITLE)}
                headerTitle={tWithNS.t(TITLE)}
                {...{ handleBackButton }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <HiddenInputSubmit />
                    <Grid className={'align-center'}>
                        <Controller
                            control={control}
                            name={NAME}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    error={!!errors.name}
                                    errorMessage={errors.name?.message}
                                    id={NAME}
                                    inputFieldWrapper={'col-6'}
                                    label={t(SCHOOL_NAME_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(SCHOOL_NAME_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                        {serviceError || !toggle(MUNICIPALITY_SERVICE)
                            ? renderMunicipalityInput()
                            : renderAutocomplete()}
                        <Controller
                            control={control}
                            name={STREET}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    autoComplete={STREET_ADDRESS}
                                    error={!!errors.street}
                                    errorMessage={errors.street?.message}
                                    id={STREET}
                                    inputFieldWrapper={'col-4'}
                                    label={t(STREET_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(STREET_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={ZIP}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    autoComplete={POSTAL_CODE}
                                    error={!!errors.zip}
                                    errorMessage={errors.zip?.message}
                                    id={ZIP}
                                    inputFieldWrapper={'col-4'}
                                    label={t(ZIP_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleZipCodeChange}
                                    placeholder={t(ZIP_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={CITY}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    autoComplete={CITY_AUTOCOMPLETE}
                                    error={!!errors.city}
                                    errorMessage={errors.city?.message}
                                    id={CITY}
                                    inputFieldWrapper={'col-4'}
                                    label={t(CITY_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(CITY_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    </Grid>
                </form>
            </PageLayout>
        </>
    );
};
