import { css, keyframes } from '@emotion/react';
import { primary } from '../../../variables';

const scaleDot = keyframes({
    '0%': {
        transform: 'scale(0.5)',
    },
    '25%': {
        transform: 'scale(1)',
    },
    '75%': {
        transform: 'scale(0.5)',
    },
    '100%': {
        transform: 'scale(0.5)',
    },
});

export const loaderCss = css({
    display: 'flex',
    alignItems: 'center',

    '&.flex-grow': {
        flex: 1,
    },
    '&.fixed': {
        background: primary,
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,

        '.spinner-size-lg': {
            width: '4rem',
            height: '4rem',
        },
    },
    '.dots': {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        '&.carousel': {
            left: 'unset',
            marginLeft: 70,
            top: '40%',
        },

        '&.dots-inline': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
        },
        '.item': {
            margin: '2px',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            background: '#ff8000',
            transform: 'scale(0.5)',
            animation: `${scaleDot} 1.2s ease-out infinite`,
            '&:nth-of-type(2)': {
                animationDelay: '0.2s',
            },
            '&:nth-of-type(3)': {
                animationDelay: '0.3s',
            },
            '&:nth-of-type(4)': {
                animationDelay: '0.4s',
            },
            '&:nth-of-type(5)': {
                animationDelay: '0.5s',
            },
            '&.dots-small': {
                margin: '2px',
                padding: '10px',
                width: '1rem',
                height: '1rem',
            },
        },
    },
});
