export enum CountryCodeEnums {
    DK = 'dk',
    FI = 'fi',
    NO = 'no',
    SE = 'se',
    UK = 'uk',
}

export const CountryCodeArray = [
    CountryCodeEnums.DK,
    CountryCodeEnums.FI,
    CountryCodeEnums.NO,
    CountryCodeEnums.SE,
    CountryCodeEnums.DK,
];
