import React, { useState } from 'react';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { SWE_DATE_TIME_FORMAT } from '../../../../config/locale';
import moment from 'moment';
import { useI18n } from 'hooks/useI18n';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectClaimDescription,
    selectEditClaimDescription,
    selectPerson,
} from '../../../../sagas/selectors/personSelectors';
import { Controller, useForm } from 'react-hook-form';
import { claimDescriptionSchema } from '../../../../validations/schemas/claimDescriptionSchema';
import { useGoBack } from '../../../../hooks/useGoBack';
import { personActions } from '../../../../sagas/person';
import {
    ClaimReporterRoleKeys,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    pdfServiceActions,
    Printable,
    SummaryItemText,
} from '@protectorinsurance/ds-can';
import { DisplayIllnessSituationSummary } from '../DisplayIllnessSituationSummary';
import { selectUpload } from '../../../../sagas/selectors/uploadSelectors';
import { DisplayAssociationInformation } from './DisplayAssociationInformation';
import { DisplayClaimantInformation } from './DisplayClaimantInformation';
import { DisplayReporterInformation } from './DisplayReporterInformation';
import { DisplayBankAccountInformation } from './DisplayBankAccountInformation';
import { DisplayBankAccountInformationChild } from './DisplayBankAccountInformationChild';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import {
    selectApiId,
    selectCustomCAN,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
    selectRequestId,
    selectSubmitted,
} from '../../../../sagas/selectors/commonSelectors';
import { claimDescriptionUtil } from '../../../../utils/claimDescriptionUtil';
import { logServiceActions } from '../../../../sagas/services/logService';

/**
 * Destructure necessary imports
 */
const { CLAIM_DESCRIPTION } = FormFieldNames;
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_LABEL,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    HELP_TEXT,
    PAGE_NAME,
    SEND_BUTTON,
    SUB_TITLE,
    SUBMITTING_BUTTON,
    TITLE,
} = PhraseKeys;
const reportDate = { date: moment().format(SWE_DATE_TIME_FORMAT) };
const { MYSELF, ON_BEHALF_CHILD } = ClaimReporterRoleKeys;

/**
 * Interfaces
 */
interface DisplayIllnessFinalSummaryProps {
    showSummary?: boolean;
}

interface FormValues {
    claimDescription: string;
}

/**
 * Page View and Page Logic
 */
export const DisplayIllnessFinalSummary = ({ showSummary = true }: DisplayIllnessFinalSummaryProps) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.illness.finalSummary');
    const claimDescription = useSelector(selectClaimDescription);
    const editClaimDescription = useSelector(selectEditClaimDescription);
    const model = useSelector(selectPerson);
    const upload = useSelector(selectUpload);
    const uuid = useSelector(selectApiId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const lob = useSelector(selectLob);
    const requestId = useSelector(selectRequestId);
    const customCAN = useSelector(selectCustomCAN);
    const [error, setError] = useState(false);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<FormValues>({
        resolver: yupResolver(claimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            claimDescription,
        },
    });
    const submitted = useSelector(selectSubmitted);

    const handleBackButton = useGoBack();

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
        dispatch(personActions.update({ editClaimDescription: !editClaimDescription }));
    };

    const onSubmit = ({ claimDescription }: FormValues, e?: FormChangeable) => {
        e?.preventDefault();
        e?.stopPropagation();
        dispatch(
            logServiceActions.request({
                level: 'info',
                message: `User clicked Continue button to send claim. UUID=${uuid}`,
            })
        );
        dispatch(commonActions.update({ submitted: true }));
        const data = {
            uuid,
            requestId,
            lob,
            locale: { country, language },
            model: { ...model, claimDescription },
        };
        dispatcherWithPromise(dispatch, personActions.update, { claimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatcherWithPromise(dispatch, pdfServiceActions.request, data))
            .then(() => dispatch(commonActions.submit()))
            .catch(() => dispatch(commonActions.update({ submitted: false })));
    };

    return (
        <PageLayout
            backBtnAriaLabel={t(BACK_BUTTON)}
            backBtnText={t(BACK_BUTTON)}
            continueBtnAriaLabel={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            continueBtnText={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            disableBackButton={submitted}
            disableContinueButton={submitted || error}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={!showSummary ? 'hide-content' : ''}
            {...{ handleBackButton }}
        >
            <Printable title={tWithNS.t('print.title', { date: reportDate })}>
                <div className={'summary'}>
                    <Grid>
                        <DisplayIllnessSituationSummary
                            tWithNS={tWithNS.t}
                            {...{ model, t, upload }}
                            error={setError}
                        />

                        {/*Claim description */}
                        {editClaimDescription ? (
                            <div className={'col-12 divider edit'}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <HiddenInputSubmit />
                                    <Grid className={'align-center'}>
                                        <Controller
                                            control={control}
                                            name={CLAIM_DESCRIPTION}
                                            render={({ field: { ref, ...field } }) => (
                                                <MuiTextInput
                                                    {...field}
                                                    error={!!errors.claimDescription}
                                                    errorMessage={errors.claimDescription?.message}
                                                    id={CLAIM_DESCRIPTION}
                                                    inputFieldWrapper={'col-12'}
                                                    label={t(CLAIM_DESCRIPTION_LABEL)}
                                                    multiline={true}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                                    reference={ref}
                                                    rows={10}
                                                    {...{ customCAN }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </form>
                            </div>
                        ) : (
                            <div className={'col-12 divider edit'}>
                                <SummaryItemText
                                    className={'col-12 divider text-align-left'}
                                    label={tWithNS.t('text.description')}
                                    text={claimDescription}
                                />
                            </div>
                        )}

                        <DisplayAssociationInformation
                            i18n={{ t: tWithNS.t }}
                            model={model.association}
                            error={setError}
                        />

                        {/*Claimant information*/}
                        <DisplayClaimantInformation
                            i18n={{ t: tWithNS.t }}
                            model={model.claimantInformation}
                            error={setError}
                        />

                        {/* Reporter information */}
                        {model.claimReporterRole !== MYSELF && (
                            <DisplayReporterInformation
                                i18n={{ t: tWithNS.t }}
                                model={model.reporterInformation}
                                error={setError}
                            />
                        )}

                        {/*Bank information*/}
                        {model.claimReporterRole === MYSELF && (
                            <DisplayBankAccountInformation
                                i18n={{ t: tWithNS.t }}
                                model={model.bankAccountInformation}
                            />
                        )}

                        {/*Bank information child*/}
                        {model.claimReporterRole === ON_BEHALF_CHILD && (
                            <DisplayBankAccountInformationChild
                                i18n={{ t: tWithNS.t }}
                                model={model.bankAccountInformation}
                            />
                        )}
                    </Grid>
                </div>
            </Printable>
            {error && (
                <div>
                    <br />
                    <p style={{ color: 'red' }} className={'divider'}>
                        Manglende informasjon må oppgis for å fortsette
                    </p>
                </div>
            )}
        </PageLayout>
    );
};
