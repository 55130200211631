/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { ReactComponent as ProtectorLogo } from '../../../assets/logo/protector-logo.svg';
import { printableCss } from './Printable.style';

interface PrintableProps {
    className?: string;
    title?: string;
    children?: ReactNode;
}

const PrintableComp = (
    { className, children, title }: PrintableProps,
    ref: Ref<HTMLDivElement>
) => {
    return (
        <div
            className={`container ${className}`}
            ref={ref}
            css={css(printableCss)}
        >
            <div className={'display'}>
                <div className={'logo-container'}>
                    <ProtectorLogo />
                    {title && <h1>{title}</h1>}
                </div>
                <hr />
            </div>
            {children}
        </div>
    );
};

export const Printable = forwardRef(PrintableComp);
