import { css } from '@emotion/react';
import { borderRadius, inputBorder } from '../../variables';

export const inputAppendCss = css({
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    border: inputBorder,
    borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
});
