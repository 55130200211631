import React, { useEffect, useRef } from 'react';
import './Map.scss';
import { DEFAULT_MAP_LOCATION } from 'config/maps';

interface MapProps {
    label: string;
    setMap: (map: google.maps.Map) => void;
    lat?: number;
    lng?: number;
    zoom?: number;
    className?: string;
    onClick: (e: google.maps.MapMouseEvent) => void;
}
/**
 * Map must be used as a child of LoadMapScript (or otherwise make sure to load map script before using Map)
 **/
export const Map = ({
    setMap,
    lat = DEFAULT_MAP_LOCATION.lat,
    lng = DEFAULT_MAP_LOCATION.lng,
    zoom = 7,
    label,
    className = '',
    onClick,
}: MapProps) => {
    const mapRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (mapRef && mapRef.current) {
            const m = createMap({ ref: mapRef.current, center: { lat, lng }, zoom });

            m.addListener('click', function handleClick(e: google.maps.MapMouseEvent) {
                onClick(e);
            });
            setMap(m);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div id="pf-map" className={className} ref={mapRef} aria-label={label}></div>;
};

interface CreateMapProps extends google.maps.MapOptions {
    ref: HTMLElement;
    zoom: number;
    center: {
        lat: number;
        lng: number;
    };
}

const createMap = ({
    ref,
    zoom,
    center,
    streetViewControl = false,
    mapTypeControl = true,
    mapTypeControlOptions = {
        style: google.maps.MapTypeControlStyle.DEFAULT,
        mapTypeIds: [google.maps.MapTypeId.ROADMAP],
    },
    ...rest
}: CreateMapProps) => {
    return new google.maps.Map(ref, {
        zoom,
        center: { ...center },
        mapTypeControl,
        mapTypeControlOptions,
        streetViewControl,
        ...rest,
    });
};
