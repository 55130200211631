import { isObject } from '../is/isObject';
import { ObjectWithDynamicKey } from '../../interfaces/types/ObjectWithDynamicKey.types';

export const stateResetObjectIterateAndReset = <
    O extends ObjectWithDynamicKey,
    V = string | null
>(
    obj: O,
    resetValue: V
): ObjectWithDynamicKey => {
    return Object.keys(obj).reduce((acc, key: string) => {
        const val = obj[key];
        if (isObject(val) && val !== null && !Array.isArray(val)) {
            return {
                ...acc,
                [key]: stateResetObjectIterateAndReset(val, resetValue),
            };
        } else if (Array.isArray(val)) {
            return { ...acc, [key]: [] };
        } else {
            return { ...acc, [key]: resetValue };
        }
    }, {});
};
