/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { FC, ReactElement } from 'react';

// types
import { PartnerWorkshopsLinkCardProps } from './PartnerWorkshopsLinkCard.props';

// style
import { partnerWorkshopsLinkCardCss } from './PartnerWorkshopsLinkCard.style';
import { buttonBase } from '../../atoms/buttons/Button.style';
import { actionButtonCss } from '../../atoms/buttons/ActionButton.style';

export const PartnerWorkshopsLinkCard: FC<PartnerWorkshopsLinkCardProps> = ({
    title,
    description,
    btnText,
    goToUrl,
    data,
}): ReactElement => {
    const qs = new URLSearchParams(data as any);
    const urlParams = data ? `/?${qs.toString()}` : '';

    return (
        <div css={css([partnerWorkshopsLinkCardCss])}>
            {title && (
                <h2 className={'partner-workshops-link-card__title'}>
                    {title}
                </h2>
            )}
            {description && (
                <p className={'partner-workshops-link-card__description'}>
                    {description}
                </p>
            )}

            <a
                href={`${goToUrl}${urlParams}`}
                target={'_blank'}
                className={'partner-workshops-link-card__link'}
                aria-label={btnText}
                css={css([buttonBase, actionButtonCss()])}
                rel="noreferrer"
            >
                {btnText}
            </a>
        </div>
    );
};
