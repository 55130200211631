/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ButtonProps } from '../../../interfaces';
import { buttonBase } from './Button.style';
import { primaryButtonCss } from './PrimaryButton.style';

export const PrimaryButton = ({
    value,
    ariaLabel,
    autoFocus,
    className = '',
    disabled = false,
    onClick,
    testId = 'primary-button',
    type = 'button',
    sx,
}: ButtonProps) => {
    return (
        <button
            aria-label={ariaLabel}
            className={`${className}`}
            css={css([buttonBase, primaryButtonCss])}
            data-testid={testId}
            {...{ autoFocus, disabled, onClick, type }}
            style={sx}
        >
            {value}
        </button>
    );
};
