import React from 'react';
import { CarArialImage } from './CarArialImage';
import { CarRightImage } from './CarRightImage';
import { CarLeftImage } from './CarLeftImage';
import { createSvgTabs, MainImageSVGProps, SVGImageView } from '../../../types/SVGProps';
import '../VehicleImageSvg.scss';
import Tabs from '../../tabs/Tabs';
import TabPanel from '../../tabs/TabPanel';
import { useI18n } from '../../../hooks/useI18n';

const { TOP, LEFT, RIGHT } = SVGImageView;
export const CarImage = (props: MainImageSVGProps) => {
    const { t } = useI18n();
    const tabs = createSvgTabs(t);
    return (
        <div className={'vehicle-body-damages'}>
            <Tabs label={t('tabs.label.car')} tabs={tabs}>
                <TabPanel tabId={TOP}>
                    <CarArialImage title={props.titles.top} {...props} />
                </TabPanel>
                <TabPanel tabId={RIGHT}>
                    <CarRightImage title={props.titles.right} {...props} />
                </TabPanel>
                <TabPanel tabId={LEFT}>
                    <CarLeftImage title={props.titles.left} {...props} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
