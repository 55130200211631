import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFlow } from '../../../sagas/selectors/flowSelectors';
import { selectIsVehicleInvolved } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import {
    CircleCheckIcon,
    Clickable,
    Grid,
    PageLayout,
    PrintLink,
    StandardModal,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { getFinalSummary } from '../../../utils/flow/getFinalSummary';
import { FeedbackModal } from '../../../components/modals/FeedbackModal';
import { selectCustomCAN, selectLocaleLanguageCode } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { YES } = YesNoKeys;
const { BACK_BUTTON, COMPLETED_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const ReportCompletedPage = () => {
    const flow = useSelector(selectFlow);
    const isVehicleInvolved = useSelector(selectIsVehicleInvolved);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(true);
    const { t } = useI18n();
    const tWithNS = useI18n(`lob.person.${flow?.toLocaleLowerCase()}.reportCompleted`);
    const tWithoutNS = useI18n();
    const isFeedbackModalEnabled = !!tWithoutNS.t('feedback.modal.enabled', { returnObjects: true });
    const language = useSelector(selectLocaleLanguageCode);
    const customCAN = useSelector(selectCustomCAN);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleBackButton = (e: any) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = (e: Clickable) => {
        e.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        setShowModal(false);
    };

    const handleConfirmModal = (e: Clickable) => {
        e.preventDefault();
        window.location.href = `/nor/${language}`;
    };

    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        window.location.replace(tWithNS.t('content.homePageLink'));
    };

    const printSummary = (e: Clickable) => {
        e.preventDefault();
        window.print();
    };

    const handleClose = () => setIsFeedbackOpen(false);

    return (
        <>
            <PageLayout
                backBtnText={t(BACK_BUTTON)}
                continueBtnText={t(COMPLETED_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerComponent={<PrintLink onClick={printSummary} title={tWithNS.t(HELP_TEXT)} {...{ customCAN }} />}
                handleContinueButton={handleContinueButton}
                headerSubTitle={tWithNS.t(SUB_TITLE)}
                headerTitle={tWithNS.t(TITLE)}
                pageClassName={'no-print'}
                showBackButton={false}
            >
                <Grid>
                    <div className={'col-3'}>{<CircleCheckIcon wh={100} />}</div>

                    <div className={'col-9'}>
                        <p>
                            {tWithNS.t('content.message')}
                            <a href={`mailto:${tWithNS.t('content.emailAddress')}`}>
                                {tWithNS.t('content.emailAddress')}
                            </a>
                            .
                        </p>

                        {isVehicleInvolved === YES && <p>{tWithNS.t('content.info')}</p>}
                    </div>
                </Grid>
            </PageLayout>
            {getFinalSummary(flow, false)}
            {isFeedbackOpen && isFeedbackModalEnabled && <FeedbackModal onClose={handleClose} />}
            {showModal && (
                <StandardModal
                    closeButtonText={t('modal.reportCompleted.closeButtonText')}
                    confirmButtonText={t('modal.reportCompleted.confirmButtonText')}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmModal}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={t('modal.reportCompleted.title')}
                >
                    <div className={'dialog__content text-center'}>{t('modal.reportCompleted.bodyText')}</div>
                </StandardModal>
            )}
        </>
    );
};
