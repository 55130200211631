import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectFileFiles } from '../../../sagas/selectors/uploadSelectors';
import FileModel from '../../../models/File';
import { Clickable, FileUploader, PageLayout } from '@protectorinsurance/ds-can';
import { uploadFileActions } from '../../../sagas/upload/file/uploadFile';
import { deleteFileActions } from '../../../sagas/upload/file/deleteFile';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, DROPZONE_DESCRIPTION, DROPZONE_TITLE, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } =
    PhraseKeys;

/**
 * Page view and page logic
 */
export const UploadDamagesPage = () => {
    const dispatch = useDispatch();
    const fileStore = useSelector(selectFileFiles);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.uploadDamages');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleOnSuccess = (files: FileModel[]) => {
        files.forEach((file) => dispatch(uploadFileActions.request(file)));
    };

    const handleDelete = (e: Clickable, file: FileModel) => {
        e.preventDefault();
        dispatch(deleteFileActions.request(file));
    };

    const checkFileErrors: boolean = fileStore.some((file) => {
        return file.errors.length > 0;
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={checkFileErrors}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <FileUploader
                onSuccess={handleOnSuccess}
                onDelete={handleDelete}
                text={tWithNS.t('upload.label')}
                title={tWithNS.t(DROPZONE_TITLE)}
                label={tWithNS.t(DROPZONE_DESCRIPTION)}
                fileStore={fileStore}
            />
        </PageLayout>
    );
};
