import React from 'react';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { renderClaimantInformation } from './components/liability/RenderClaimantInformation';

/**
 * Destructure necessary imports
 */
const { LIABILITY_CLAIMANT_INFORMATION } = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayLiabilitySummary = ({ model, t, tWithNS, handleClick, upload }: LpoSummaryProps) => {
    const { claimantInformationList } = model;

    return (
        <>
            {renderClaimantInformation({
                list: claimantInformationList,
                textKey: tWithNS('claimantInformation.heading.title'),
                path: LIABILITY_CLAIMANT_INFORMATION,
                show: claimantInformationList.length > 0,
                dataTestId: TEST_ID_PREFIX.claimantInformation,
                className: 'col-12 divider text-align-left',
            })}
        </>
    );
};
