import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../config/formFieldNames';
import { PHONE_REGEX } from '../../utils/validation/stringFormats';

const { EMAIL_LABEL, PHONE_LABEL } = PhraseKeys;

const { PHONE, EMAIL } = FormFieldNames;

const { EMAIL_ERROR_MESSAGE, FIELD_ERROR } = FormFieldErrors;

export const contactDetailsSchema = (t: TFunction) => {
    return {
        email: Yup.string()
            .label(t(EMAIL_LABEL))
            .email(t(EMAIL_ERROR_MESSAGE))
            .secureEndingEmail(t(EMAIL_ERROR_MESSAGE))
            .onlyAscii()
            .requiredIf([PHONE])
            .nullable(),
        phone: Yup.string()
            .label(t(PHONE_LABEL))
            .matches(PHONE_REGEX, {
                message: t(FIELD_ERROR),
                excludeEmptyString: true,
            })
            .requiredIf([EMAIL])
            .nullable(),
    };
};
