import React from 'react';
import { SVGImageProps } from '../../types/SVGProps';

interface DamageMarkersProps extends Pick<SVGImageProps, 'removeDamagePoint' | 'selectedDamagePoints'> {
    radius?: number;
    color?: string;
}

export const DamageMarkers = ({
    selectedDamagePoints,
    removeDamagePoint,
    radius = 20,
    color = '#ef6e0b',
}: DamageMarkersProps) => {
    return (
        <>
            {selectedDamagePoints.map((point, i) => {
                return (
                    <circle
                        key={`${i}-${point.x}${point.y}`}
                        cx={point.x}
                        cy={point.y}
                        r={radius}
                        fill={color}
                        onClick={(e) => removeDamagePoint(e, point)}
                    />
                );
            })}
        </>
    );
};
