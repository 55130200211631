/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { progressBarCss } from './ProgressBar.style';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface ProgressBarProps {
    customCAN?: customCANProps;
    width?: number;
}

/**
 * Component view and component logic
 */
export const ProgressBar = ({ customCAN, width = 0 }: ProgressBarProps) => {
    const styleProgressBar = {
        width: `${width}%`,
    };

    return (
        <div css={css(progressBarCss(customCAN))}>
            <div
                className={'progress-bar'}
                style={styleProgressBar}
                role="progressbar"
            />
        </div>
    );
};
