import AttachmentModel from '../../../models/Attachment';
import { produce } from 'immer';
import { actionWithPromise, FileModel, Rejectable, Resolvable, YesNoKeys } from '@protectorinsurance/ds-can';

/**
 * Action Types
 */
export enum FileActionTypes {
    REQUEST = '@app/upload/file/REQUEST',
    SUCCESS = '@app/upload/file/SUCCESS',
    ADD = '@app/upload/file/ADD',
    UPDATE = '@app/upload/file/UPDATE',
    FAILURE = '@app/upload/file/FAILURE',
    REMOVE = '@app/upload/file/REMOVE',
}

/**
 * Action Definitions
 */
export interface FileAction {
    type: FileActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Init State
 */
export const fileInitState: AttachmentModel = {
    files: [],
    hasAttachment: null,
};

/**
 * Default Reducer
 */
export default function (state = fileInitState, { type, data }: FileAction) {
    return produce(state, (draft: AttachmentModel) => {
        switch (type) {
            case FileActionTypes.ADD:
                draft.files.push(data);
                draft.hasAttachment = YesNoKeys.YES;
                break;
            case FileActionTypes.UPDATE:
                const { id, status, errors, externalId } = data;
                const index = state.files.findIndex((x: FileModel) => x.id === id);
                draft.files[index].status = status;
                draft.files[index].errors = errors;
                if (externalId) {
                    draft.files[index].externalId = externalId;
                }
                break;
            case FileActionTypes.REMOVE:
                draft.files = state.files.filter((x) => x.id !== data.id);
                break;
            default:
                break;
        }
    });
}

/**
 * Redux Actions
 */
export const fileActions = {
    add: actionWithPromise<FileActionTypes, FileModel>(FileActionTypes.ADD),
    failure: actionWithPromise(FileActionTypes.FAILURE),
    success: actionWithPromise(FileActionTypes.SUCCESS),
    remove: actionWithPromise(FileActionTypes.REMOVE),
    request: actionWithPromise(FileActionTypes.REQUEST),
    update: actionWithPromise(FileActionTypes.UPDATE),
};
