import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    isEmpty,
    MuiAutocomplete,
    MuiTextInput,
    ObjectWithDynamicKey,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectAssociationInformation } from '../../../sagas/selectors/personSelectors';
import { personActions } from '../../../sagas/person';
import { AssociationInformationFields } from '../../../models/person/AssociationInformation';
import { associationOptions } from '../../../config/person/associationOptions';
import { associationInformationSchema } from '../../../validations/schemas/associationInformationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { ASSOCIATION, SELECT_ASSOCIATION } = FormFieldNames;
const {
    ASSOCIATION_LABEL,
    ASSOCIATION_PLACEHOLDER,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SELECT_ASSOCIATION_LABEL,
    SELECT_ASSOCIATION_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const DeathAssociationInformationPage = () => {
    const dispatch = useDispatch();
    const [asso, setAsso] = useState<OptionType | null>(null);
    const [selectError, setSelectError] = useState<ObjectWithDynamicKey>({});
    const association = useSelector(selectAssociationInformation);
    const customCAN = useSelector(selectCustomCAN);
    const options = associationOptions;
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.death.associationInformation');
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        setError,
        setValue,
        trigger,
    } = useForm<AssociationInformationFields>({
        resolver: yupResolver(associationInformationSchema(t)),
        defaultValues: {
            association: association.name,
        },
    });

    useEffect(() => {
        const selected = options.find((x) => x.value === association.id);
        if (selected) {
            setAsso(selected);
        } else if (association.id) {
            setAsso({ value: '', label: 'Annan' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [association]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        const associationField = getValues(ASSOCIATION);
        dispatcherWithPromise(dispatch, personActions.update, {
            association: { id: '', name: associationField },
        });
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        setSelectError({});
        setAsso(object);

        object &&
            dispatcherWithPromise(dispatch, personActions.update, {
                association: { id: object.value, name: object.label },
            });
    };

    const handleContinueButton = () => {
        const value = getValues(ASSOCIATION);
        if (!asso) {
            const message = t(REQUIRED_ERROR_MESSAGE);
            setSelectError({ [SELECT_ASSOCIATION]: { message } });
            return;
        } else if (asso.value === '' && !value) {
            setError(ASSOCIATION, { type: 'Required', message: t(REQUIRED_ERROR_MESSAGE) });
            return;
        }

        if (isEmpty(selectError) && isEmpty(errors)) {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const onSubmit = async () => {
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const showTextField = () => asso && asso.value === '';

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <MuiAutocomplete
                        error={!!selectError[SELECT_ASSOCIATION]}
                        errorMessage={selectError[SELECT_ASSOCIATION]?.message}
                        id={SELECT_ASSOCIATION}
                        inputFieldWrapper={'col-12'}
                        label={t(SELECT_ASSOCIATION_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(SELECT_ASSOCIATION_PLACEHOLDER)}
                        value={asso}
                        {...{ customCAN, options }}
                    />
                    {showTextField() && (
                        <Controller
                            control={control}
                            name={ASSOCIATION}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    error={!!errors.association}
                                    errorMessage={errors.association?.message}
                                    id={ASSOCIATION}
                                    inputFieldWrapper={'col-12'}
                                    label={t(ASSOCIATION_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(ASSOCIATION_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    )}
                </Grid>
            </form>
        </PageLayout>
    );
};
