import React from 'react';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { is, LobKeys, LpoClaimCauseEnums } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { BaseRoutePaths } from '../../../config/wizardRouter/baseWizardRoutes';
import { trueFalseToYesNo } from 'utils/displayUtils';
import { dtParseAndFormat, dtTimeParseAndFormat } from '../../../utils/date/dateUtils';
import { renderLob } from './components/start/RenderLob';
import { renderPrivacy } from './components/start/RenderPrivacy';
import { renderClaimDate } from './components/start/RenderClaimDate';
import { renderClaimReporterRole } from './components/start/RenderClaimReporterRole';
import { renderClaimType } from './components/start/RenderClaimType';
import { renderClaimCause } from './components/start/RenderClaimCause';
import classNames from 'classnames';

/**
 * Destructure necessary imports
 */
const { INDEX } = BaseRoutePaths;
const { START_CLAIM_CAUSE, START_CLAIM_DATE, START_CLAIM_TYPE, START_PRIVACY } = LpoRoutePaths;
const { LIABILITY, PROPERTY } = LobKeys;
const { FALL } = LpoClaimCauseEnums;

/**
 * Component view and component logic
 */
export const DisplayStartSummary = ({ model, t, tWithNS, handleClick, upload, common }: LpoSummaryProps) => {
    const { acceptedPrivacy, claimCause, claimDate, claimReporterRole, claimType } = model;
    const { lob } = common;

    // Classes
    const claimTypeClass = classNames(
        `${is(lob, LIABILITY) || is(lob, PROPERTY) ? 'col-4' : 'col-12'}`,
        'divider text-align-left'
    );

    return (
        <>
            {renderLob({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('lob.heading.title'),
                dataTestId: TEST_ID_PREFIX.lob,
                buttonText: t(`lob.select.${lob}`),
                path: INDEX,
                handleClick,
            })}

            {renderPrivacy({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('privacy.heading.title'),
                dataTestId: TEST_ID_PREFIX.acceptedPrivacy,
                buttonText: t(trueFalseToYesNo(acceptedPrivacy)),
                path: START_PRIVACY,
                handleClick,
            })}

            {renderClaimType(
                {
                    inputWrapperClass: claimTypeClass,
                    textKey: tWithNS('claimType.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimType,
                    buttonText: claimType ? t(`lpo.claimType.select.${claimType}`) : '-',
                    path: START_CLAIM_TYPE,
                    handleClick,
                },
                lob
            )}

            {renderClaimCause(
                {
                    inputWrapperClass: 'col-8 divider text-align-left',
                    textKey: tWithNS('claimCause.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimCauseDropdown,
                    buttonText: claimCause ? t(`lpo.claimCause.select.${claimCause}`) : '-',
                    path: START_CLAIM_CAUSE,
                    handleClick,
                },
                lob
            )}

            {renderClaimReporterRole(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimReporterRole.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimReporterRole,
                    buttonText: claimReporterRole ? t(`lpo.start.claimReporterRole.select.${claimReporterRole}`) : '-',
                    path: START_CLAIM_TYPE,
                    handleClick,
                },
                lob
            )}

            {renderClaimDate(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimDate.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimDate,
                    buttonText: claimDate
                        ? is(claimCause, FALL)
                            ? dtTimeParseAndFormat(claimDate)
                            : dtParseAndFormat(claimDate)
                        : '-',
                    path: START_CLAIM_DATE,
                    handleClick,
                },
                lob
            )}
        </>
    );
};
