import { css } from '@emotion/react';
import { grey, success, white } from '../../variables';

export const fileUploaderCss = css({
    '& .errors': {
        marginTop: 0,
    },

    '& .item-wrapper': {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'grid',
        gridTemplateAreas:
            '"icon file fileCategory status status-delete" "icon fileSize fileCategory status status-delete" "error error error error error"',
        gridTemplateColumns: '1fr 2fr 1fr 1fr auto',
        marginTop: '1rem',
        padding: '0.5rem',

        '& .file-icon': {
            alignSelf: 'center',
            gridArea: 'icon',
            justifySelf: 'center',
        },

        '& .file-name': {
            alignSelf: 'end',
            fontSize: 'medium',
            gridArea: 'file',
            justifySelf: 'start',
            marginRight: '0.5rem',
            textAlign: 'start',
            wordBreak: 'break-word',
        },

        '& .file-size': {
            alignSelf: 'start',
            gridArea: 'fileSize',
            justifySelf: 'start',
            textAlign: 'start',
        },

        '& .file-category': {
            alignSelf: 'end',
            gridArea: 'fileCategory',
            justifySelf: 'start',
            width: '100%',
        },

        '& .errors': {
            alignSelf: 'start',
            gridArea: 'error',
            justifySelf: 'stretch',
            marginTop: '1rem',
            textAlign: 'start',
        },

        '& .file-status-icon': {
            alignContent: 'center',
            alignSelf: 'center',
            border: `2px solid ${grey}`,
            borderRadius: '50%',
            display: 'flex',
            gridArea: 'status',
            justifyContent: 'center',
            justifySelf: 'start',
            height: 49,
            width: 49,

            '&.pending, &.created': {
                border: 'none',
            },

            '&.passed': {
                background: success,
                border: `2px solid ${success}`,
                height: 25,
                width: 25,

                '& svg.check-icon': {
                    height: '60%',
                    width: '60%',

                    '& path': {
                        stroke: white,
                        strokeWidth: 3,
                    },
                },
            },

            '&.failed, &.error': {
                '& button': {
                    background: 'transparent',
                    borderRadius: '50%',

                    '& svg': {
                        height: '100%',
                        width: '100%',
                    },
                },
            },
        },

        '& .delete-button': {
            alignSelf: 'center',
            gridArea: 'status-delete',
        },

        '@media screen and (min-width: 768px)': {
            padding: '1.25rem',

            '& .file-name': {
                marginRight: '1rem',
            },

            '& .file-status-icon': {
                justifySelf: 'center',
            },
        },
    },
});
