import { Nullable } from '../../../../interfaces/types/Nullable.types';
import { InsuredByProtectorKeys } from '../interfaces/models/InsuredByProtector.model';

export const mapInsuredByProtector = (insuredByProtector: Nullable<string>) => {
    if (
        insuredByProtector ===
        InsuredByProtectorKeys.YES_RECENTLY_INSURED_BY_PROTECTOR
    ) {
        return InsuredByProtectorKeys.YES_RECENTLY_INSURED_BY_PROTECTOR;
    } else if (insuredByProtector === InsuredByProtectorKeys.YES) {
        return InsuredByProtectorKeys.YES;
    } else if (insuredByProtector === InsuredByProtectorKeys.NO) {
        return InsuredByProtectorKeys.NO;
    } else {
        return null;
    }
};
