import { css } from '@emotion/react';
import { secondary, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const personListCss = (company?: customCANProps) =>
    css({
        display: 'flex',
        flexDirection: 'row',

        '& .person-list-item': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: 0,
            marginRight: '1.5rem',

            '& .list-text': {
                marginBottom: '0.3rem',
                marginTop: '0.3rem',
            },

            '& .delete-btn': {
                alignSelf: 'center',
                backgroundColor: 'transparent',
                borderColor: company ? company.color : secondary,
                borderRadius: 2,
                borderWidth: 1,
                color: secondary,
                display: 'inline-block',
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: 1,
                maxWidth: 70,
                padding: '0.05rem 1rem',
                textAlign: 'center',
                transition:
                    'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',

                '&:hover': {
                    backgroundColor: company ? company.color : secondary,
                    borderColor: company ? company.color : secondary,
                    color: company ? company.textColor : white,
                },

                '&:focus': {
                    boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.5)',
                    outline: 0,
                },
            },
        },
    });
