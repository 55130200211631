import { takeLatest } from 'redux-saga/effects';
import {
    actionWithPromise,
    emptyFn,
    is,
    LobKeys,
    LogServiceModel,
    PFRequestError,
    Promisable,
} from '@protectorinsurance/ds-can';
import { NODE_API_BASE_URL } from '../../config/api';
import { selectApiId, selectLob, selectRequestId } from '../selectors/commonSelectors';
import { selectPerson } from '../selectors/personSelectors';
import { api } from '../../utils/api';
import { call, put, select } from 'typed-redux-saga';
import { selectMotorState } from '../selectors/motorSelectors';
import { selectLpoState } from '../selectors/lpoSelectors';

/**
 * Action Types
 */
export enum LogServiceActionTypes {
    REQUEST = '@app/api/service/log/REQUEST',
    SUCCESS = '@app/api/service/log/SUCCESS',
    FAILURE = '@app/api/service/log/FAILURE',
}

/**
 * Action Definitions
 */

export interface LogServiceRequestAction extends Promisable {
    type: LogServiceActionTypes.REQUEST;
    data: LogServiceModel;
}

export interface LogServiceSuccessAction extends Promisable {
    type: LogServiceActionTypes.SUCCESS;
}
export interface LogServiceFailureAction extends Promisable {
    type: LogServiceActionTypes.FAILURE;
    data: PFRequestError;
}

export type LogServiceActions = LogServiceRequestAction | LogServiceFailureAction | LogServiceSuccessAction;

/**
 * Redux Actions
 */
export const logServiceActions = {
    failure: actionWithPromise(LogServiceActionTypes.FAILURE),
    success: actionWithPromise(LogServiceActionTypes.SUCCESS),
    request: actionWithPromise(LogServiceActionTypes.REQUEST),
};

/**
 * Saga functions
 */
export const logServiceSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: LogServiceRequestAction) {
        try {
            const lob = yield* select(selectLob);
            let state;
            if (is(lob, LobKeys.AUTO)) {
                state = yield* select(selectMotorState);
            } else if (is(lob, LobKeys.PERSON)) {
                state = yield* select(selectPerson);
            } else {
                state = yield* select(selectLpoState);
            }
            const claimsReportId = yield* select(selectApiId);
            const requestId = yield* select(selectRequestId);
            yield call(
                api.post,
                `logger/${data.level}`,
                { ...data, req: { claimsReportId, requestId, state: JSON.stringify(state) } },
                {
                    baseURL: NODE_API_BASE_URL,
                }
            );
            yield put(logServiceActions.success());
            resolve();
        } catch (e) {
            yield put(logServiceActions.failure((e as any)?.response?.data || e));
            reject();
        }
    },
};

/**
 * Saga watchers
 */
export const logServiceWatchers = function* () {
    yield takeLatest(LogServiceActionTypes.REQUEST, logServiceSagas.request);
};
