/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ReactComponent as ProtectorLogo } from '../../../assets/logo/protector-logo.svg';
import { navBarWithOptComponentsCss } from './NavBarWithOptComponents.style';

interface ExtendedNavBarProps {
    domain: string;
    environment: string;
    homeUrl: string;
    centerComponent?: React.ReactNode;
    rightComponent?: React.ReactNode;
}

export const NavBarWithOptComponents = ({
    domain,
    environment,
    homeUrl,
    centerComponent,
    rightComponent,
}: ExtendedNavBarProps) => {
    return (
        <div
            className={'extended-navbar'}
            css={css(navBarWithOptComponentsCss)}
        >
            <a href={homeUrl} className={'extended-navbar-link'}>
                <ProtectorLogo />{' '}
                <p className={'link-text'}>
                    {domain} {environment}
                </p>
            </a>
            {centerComponent && (
                <div className={'center-navbar-component'}>
                    {centerComponent}
                </div>
            )}
            {rightComponent && (
                <div className={'right-navbar-component'}>{rightComponent}</div>
            )}
        </div>
    );
};
