import { HospitalisationInformationModel } from 'models/person/HospitalisationInformation';

export const removeHospitalisation = (
    hospitalisation: HospitalisationInformationModel,
    existingPeriods: HospitalisationInformationModel[]
): HospitalisationInformationModel[] => {
    return existingPeriods.filter((p: HospitalisationInformationModel) => {
        return (
            p.startDate !== hospitalisation.startDate ||
            p.endDate !== hospitalisation.endDate ||
            p.hospitalName !== hospitalisation.hospitalName
        );
    });
};
