import { OptionType } from '@protectorinsurance/ds-can';

export const associationOptions: OptionType[] = [
    { value: '802004-7737', label: 'Adoptionscentrum' },
    { value: '556662-3210', label: 'AHF HockeyAllsvenskan AB' },
    { value: '852000-3693', label: 'Barnen Framförallt (Frivillig Barnförsäkring)' },
    { value: '897000-7459', label: 'Barnens Vänner (Obligatorisk Barnförsäkring)' },
    { value: '802002-3605', label: 'Neuroförbundet' },
    { value: '556908-9203', label: 'Reitan Convenience Sweden AB' },
    { value: '802003-0246', label: 'Skattebetalarnas Förening' },
    { value: '556820-0926', label: 'Småföretagarna' },
    { value: '556092-9936', label: 'Svenska Byggingenjörers Riksförbund' },
    { value: '802007-6512', label: 'Svenska Diabetesförbundet' },
    { value: '898200-2902', label: 'Svenska Fallskärmsförbundet' },
    { value: '802005-4246', label: 'Sveriges Tandteknikerförbund' },
    { value: '556187-0410', label: 'Sydskånes avfallsaktiebolag' },
    { value: '556474-8803', label: 'Sysav Industri' },
    { value: '', label: 'Annan' },
];
