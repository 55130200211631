/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { startSummaryCss } from './StartSummary.style';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface StartSummaryProps {
    avgMin?: string;
    customCAN?: customCANProps;
    description: string;
    sessionText?: string;
    subTitle?: string;
    title: string;
}

/**
 * Component view and component logic
 */
export const StartSummary = ({
    avgMin,
    customCAN,
    description,
    sessionText,
    subTitle,
    title,
}: StartSummaryProps) => {
    return (
        <div className={'container'} css={css(startSummaryCss(customCAN))}>
            <h3 className={'title'} tabIndex={0}>
                {title}
            </h3>
            {avgMin && (
                <h2 className={'avgMin'} tabIndex={0}>
                    {avgMin}
                </h2>
            )}
            {subTitle && (
                <h3 className={'subTitle'} tabIndex={0}>
                    {subTitle}
                </h3>
            )}
            <p tabIndex={0}>{description}</p>
            {sessionText && <p tabIndex={0}>{sessionText}</p>}
        </div>
    );
};
