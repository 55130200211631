import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    TypeOfPropertyKeys,
} from '@protectorinsurance/ds-can';
import { selectTypeOfProperty } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import {
    getApartmentBuildingOption,
    getCondominiumOption,
    getIndustrialPropertyOption,
    getLocalOption,
    getOtherOption,
    getPublicPropertyOption,
    getTenancyOption,
    getVillaOption,
} from '../../../utils/lpo/typeOfPropertyUtils';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    TYPE_OF_PROPERTY_LABEL,
    TYPE_OF_PROPERTY_PLACEHOLDER,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { TYPE_OF_PROPERTY } = FormFieldNames;

/**
 * Page view and page logic
 */
export const PropertyTypeOfPropertyPage = () => {
    const dispatch = useDispatch();
    const [property, setProperty] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const typeOfProperty = useSelector(selectTypeOfProperty);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.typeOfProperty');

    const options = [
        getLocalOption(t),
        getPublicPropertyOption(t),
        getIndustrialPropertyOption(t),
        getApartmentBuildingOption(t),
        getCondominiumOption(t),
        getTenancyOption(t),
        getVillaOption(t),
        getOtherOption(t),
    ];

    useEffect(() => {
        const selected = options.find((x) => x.value === typeOfProperty);
        if (selected) {
            setProperty(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeOfProperty, setProperty]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(typeOfProperty, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? (object.value as TypeOfPropertyKeys) : null;
        setError('');
        setProperty(object);
        dispatch(lpoActions.update({ typeOfProperty: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(typeOfProperty, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={TYPE_OF_PROPERTY}
                    inputFieldWrapper={'col-12'}
                    label={t(TYPE_OF_PROPERTY_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(TYPE_OF_PROPERTY_PLACEHOLDER)}
                    value={property}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
