/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactElement } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField, TextFieldProps } from '@mui/material';
import { Nullable } from '../../../../interfaces';
import {
    datePickerStyles,
    muiDatePickerInputStyle,
} from './MuiDatePickerInput.style';
import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import 'moment/locale/da';
import { customCANProps } from '../../../../config/customCANCompanies';
import { makeStyles } from '@mui/styles';
import { secondary, secondaryHover } from '../../../variables';

/**
 * Interfaces
 */
interface MuiDatePickerInputProps {
    customCAN?: customCANProps;
    disableFuture?: boolean;
    error?: boolean;
    errorMessage?: string | ReactElement;
    id: string;
    inputFieldWrapper?: string;
    label: string;
    locale?: any;
    onChange: (newValue: Nullable<Date>) => void;
    placeholder?: string;
    value?: Nullable<Date>;
}

/**
 * Component view and component logic
 */
export const MuiDatePickerInput = ({
    customCAN,
    disableFuture,
    error,
    errorMessage,
    id,
    inputFieldWrapper,
    label,
    locale,
    onChange,
    placeholder,
    value,
}: MuiDatePickerInputProps) => {
    const useStyles = makeStyles({
        datePicker: {
            '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                    background: `${
                        customCAN ? customCAN.color : secondary
                    } !important`,
                    '&:hover': {
                        backgroundColor: `${
                            customCAN ? customCAN.colorFaded : secondaryHover
                        } !important`,
                    },
                },
            },
        },
    });
    const classes = useStyles();

    return (
        <div
            className={inputFieldWrapper}
            css={css(muiDatePickerInputStyle(customCAN))}
        >
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={moment().locale(locale)}
            >
                <DatePicker
                    PaperProps={{ className: classes.datePicker }}
                    renderInput={(params: TextFieldProps) => (
                        <TextField
                            data-testid={id}
                            {...params}
                            helperText={errorMessage}
                            {...{ error, id }}
                        />
                    )}
                    {...{ disableFuture, label, onChange, placeholder, value }}
                />
            </LocalizationProvider>
        </div>
    );
};
