import { css } from '@emotion/react';
import { inputFieldPlaceholder } from '../../variables';

export const summaryInputItemCss = css({
    border: `1px solid ${inputFieldPlaceholder}`,
    borderRadius: 2,
    fontWeight: 400,
    lineHeight: '1.5',
    padding: '0.5rem 1rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    width: '100%',
    height: '90px',
    textAlign: 'left',
});
