import { Nullable } from '../types';

export interface LobModel {
    lob: Nullable<LobKeys>;
}

export enum LobKeys {
    TRANSPORT = 1,
    CHANGE_OF_OWNERSHIP_NOR = 2,
    PROPERTY = 3,
    AUTO = 4,
    PERSON = 5,
    BOAT = 6,
    TRAVEL = 7,
    LIABILITY = 8,
    WORKSHOP = 21,
    HEALTH = 22,
    CHANGE_OF_OWNERSHIP_SWE = 23,
    LEGAL_EXPENCES = 24,
    CYBER = 25,
}
