import { Store } from 'redux';
import { get } from 'object-path-immutable';
import { stateResetCompareNotEqual } from './stateResetCompareNotEqual';
import { stateResetMapResetKeys } from './stateResetMapResetKeys';
import { stateResetResolveResetValue } from './stateResetResolveResetValue';
import {
    StateResetCbFn,
    StateResetRule,
} from '../../interfaces/types/StateReset.types';

export const stateResetWatcher = <S extends Store>(
    store: S,
    rule: StateResetRule,
    cb: StateResetCbFn
) => {
    const compare = rule?.compare || stateResetCompareNotEqual;
    let currentValue = get(store.getState(), rule?.key);
    return function () {
        const newValue = get(store.getState(), rule?.key);
        if (compare(currentValue, newValue)) {
            const oldValue = currentValue;
            currentValue = newValue;
            const resetValue = stateResetResolveResetValue(
                rule,
                newValue,
                oldValue
            );

            rule.resetKeys.map((resetKey) => {
                cb(
                    stateResetMapResetKeys(
                        resetKey,
                        resetValue,
                        newValue,
                        oldValue
                    )
                );
            });
        }
    };
};
