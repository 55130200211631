import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimReporterRoleKeys,
    Clickable,
    IllnessIcon,
    is,
    OnBehalfOfIllChildIcon,
    OnBehalfOfSbIllIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimReporterRole, selectInsuranceType } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { MYSELF, ON_BEHALF, ON_BEHALF_CHILD } = ClaimReporterRoleKeys;

/**
 * Page view and page logic
 */
export const illnessClaimReporterRolePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const insuranceType = selectInsuranceType(state);
    const options = [
        { id: MYSELF, name: t(`lob.person.illness.claimReporterRole.select.${MYSELF}`), icon: <IllnessIcon /> },
        {
            id: ON_BEHALF,
            name: t(`lob.person.illness.claimReporterRole.select.${ON_BEHALF}`),
            icon: <OnBehalfOfSbIllIcon />,
        },
        {
            id: ON_BEHALF_CHILD,
            name: t(`lob.person.illness.claimReporterRole.select.${ON_BEHALF_CHILD}`),
            icon: <OnBehalfOfIllChildIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_CLAIM_REPORTER_ROLE,
        stateKey: 'claimReporterRole',
        i18n: {
            ns: 'lob.person.illness.claimReporterRole',
        },
        selectorValue: selectClaimReporterRole(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (is(option.id, ON_BEHALF_CHILD) && insuranceType) {
                    dispatch(personActions.update({ insuranceType: null }));
                }

                dispatcherWithPromise(dispatch, personActions.update, { claimReporterRole: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
