import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { MotorClaimCauseListTypeModel, MotorClaimCauseTypeModel } from '../models/motor/MotorClaimCause';
import { LoginModel } from '../models/motor/Login';
import { FlowState } from './flow';
import {
    AccidentSketchTypeModel,
    actionWithPromise,
    ClaimDescriptionTypeModel,
    ClaimLocationTypeModel,
    CollisionSituationTypeKeys,
    CompanyModel,
    Datable,
    DriverInformationModel,
    emptyFn,
    FlowKeys,
    initDriverInformation,
    initVehicleModel,
    InjuredPersonInformationListTypeModel,
    LocationModel,
    MissingItemsTypeModel,
    NeedVehicleTowingModel,
    Nullable,
    OwnerInformationListTypeModel,
    PersonModel,
    PoliceCaseNumberTypeModel,
    PolicyHoldersContactModel,
    PrivacyTypeModel,
    Rejectable,
    ReporterInformationModel,
    Resolvable,
    RoundaboutPositionTypeKeys,
    SelectedVehicleTypeModel,
    TypeOfAnimalCollisionTypeKeys,
    TypeOfAutoClaimModel,
    TypeOfIntersectionTypeKeys,
    TypeOfRoadTypeKeys,
    updateOrAddVehicle,
    VehicleModel,
    VehicleServiceModel,
    VehiclesModel,
    WhoAtFaultTypeKeys,
    WitnessesTypeModel,
    YesNoModel,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { START } = FlowKeys;

/**
 * Constants
 */
export enum MotorActionTypes {
    UPDATE = '@motor/UPDATE',
    UPDATED = '@motor/UPDATED',
    UPDATE_CLAIM_VEHICLE = '@motor/vehicles/UPDATE_CLAIM_VEHICLE',
}

/**
 * Interfaces
 */
export interface IMotorUpdateAction {
    type: MotorActionTypes.UPDATE | MotorActionTypes.UPDATED;
    data?: Partial<MotorState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}
export interface IVehicleAction {
    type: MotorActionTypes.UPDATE_CLAIM_VEHICLE | MotorActionTypes.UPDATE_CLAIM_VEHICLE;
    data?: Partial<VehicleModel>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export type IMotorAction = IMotorUpdateAction | IVehicleAction;

export interface MotorState {
    acceptedPrivacy: PrivacyTypeModel;
    accidentDescription: ClaimDescriptionTypeModel;
    accidentLocation: LocationModel;
    accidentSketch: AccidentSketchTypeModel;
    claimCause: MotorClaimCauseTypeModel;
    claimCauseList: MotorClaimCauseListTypeModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimDiscovererInformation: PersonModel;
    claimLocationType: ClaimLocationTypeModel;
    claimantRoundaboutPositioning: RoundaboutPositionTypeKeys;
    collisionSituation: CollisionSituationTypeKeys;
    companyInformation: CompanyModel;
    counterpartyRoundaboutPositioning: RoundaboutPositionTypeKeys;
    distanceFromRoadSide: Nullable<number>;
    ditchClaimDescription: ClaimDescriptionTypeModel;
    driverInformation: DriverInformationModel;
    drivingSpeed: Nullable<number>;
    engineClaimDescription: ClaimDescriptionTypeModel;
    externalReference: Nullable<string>;
    fireClaimDescription: ClaimDescriptionTypeModel;
    flow: FlowState;
    hasAllKeys: YesNoModel;
    hasAnimalEscaped: YesNoModel;
    hasDigitalServiceBook: YesNoModel;
    hasNonVehicleDamages: YesNoModel;
    hasParkedWitnesses: YesNoModel;
    hasPersonInjuries: YesNoModel;
    hasVehicleDamages: YesNoModel;
    hasWitnesses: YesNoModel;
    injuredPersonInformationList: InjuredPersonInformationListTypeModel;
    isCounterpartyKnown: YesNoModel;
    isCounterpartyStationary: YesNoModel;
    isCyclistResponsible: boolean;
    isDriving: YesNoModel;
    isItemMissing: YesNoModel;
    isLaneChange: YesNoModel;
    isOtherVehicleInvolved: YesNoModel;
    isPoliceContacted: YesNoModel;
    isReversing: YesNoModel;
    isSelfDiscoveredClaim: YesNoModel;
    isVehicleRecovered: YesNoModel;
    isVehicleStolen: YesNoModel;
    login: LoginModel;
    missingItems: MissingItemsTypeModel;
    needVehicleTowing: NeedVehicleTowingModel;
    otherMissingItemsClaimDescription: ClaimDescriptionTypeModel;
    ownerInformationList: OwnerInformationListTypeModel;
    parkingDate: Datable;
    path: string;
    policeCaseNumber: PoliceCaseNumberTypeModel;
    policyHoldersContact: PolicyHoldersContactModel;
    roadConditionClaimDescription: ClaimDescriptionTypeModel;
    roadWidth: Nullable<number>;
    recovererInformation: PersonModel;
    recoveryDate: Datable;
    reporterInformation: ReporterInformationModel;
    reversingClaimDescription: ClaimDescriptionTypeModel;
    searchedVehicles: VehiclesModel;
    selectedVehicleId: SelectedVehicleTypeModel;
    selectedVehicle: VehicleModel;
    speedLimit: Nullable<number>;
    speedOnImpact: Nullable<number>;
    theftAndDamagesClaimDescription: ClaimDescriptionTypeModel;
    timeSinceAction: Nullable<string>;
    typeOfAnimalCollision: TypeOfAnimalCollisionTypeKeys;
    typeOfAutoClaim: TypeOfAutoClaimModel;
    typeOfIntersection: TypeOfIntersectionTypeKeys;
    typeOfRoad: TypeOfRoadTypeKeys;
    vehicles: VehiclesModel;
    whoAtFault: WhoAtFaultTypeKeys;
    whoResponsibleClaimDescription: ClaimDescriptionTypeModel;
    witnesses: WitnessesTypeModel;
}

/**
 * Initial state
 */
export const motorInitState: MotorState = {
    acceptedPrivacy: false,
    accidentDescription: '',
    accidentLocation: {
        longitude: null,
        latitude: null,
        note: null,
        isUnknownLocation: false,
        unit: null,
    },
    accidentSketch: {
        data: null,
        blob: null,
    },
    claimCause: null,
    claimCauseList: [],
    claimDate: null,
    claimDescription: '',
    claimDiscovererInformation: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
    },
    claimLocationType: null,
    claimantRoundaboutPositioning: null,
    collisionSituation: null,
    companyInformation: {
        name: null,
        businessNumber: null,
    },
    counterpartyRoundaboutPositioning: null,
    distanceFromRoadSide: null,
    ditchClaimDescription: '',
    driverInformation: initDriverInformation,
    drivingSpeed: null,
    engineClaimDescription: '',
    externalReference: null,
    fireClaimDescription: '',
    flow: START,
    hasAllKeys: null,
    hasAnimalEscaped: null,
    hasDigitalServiceBook: null,
    hasNonVehicleDamages: null,
    hasParkedWitnesses: null,
    hasPersonInjuries: null,
    hasVehicleDamages: null,
    hasWitnesses: null,
    injuredPersonInformationList: [],
    isCounterpartyKnown: null,
    isCounterpartyStationary: null,
    isCyclistResponsible: false,
    isDriving: null,
    isItemMissing: null,
    isLaneChange: null,
    isOtherVehicleInvolved: null,
    isPoliceContacted: null,
    isReversing: null,
    isSelfDiscoveredClaim: null,
    isVehicleRecovered: null,
    isVehicleStolen: null,
    login: null,
    missingItems: [],
    needVehicleTowing: null,
    otherMissingItemsClaimDescription: '',
    ownerInformationList: [],
    parkingDate: null,
    path: '',
    policeCaseNumber: null,
    policyHoldersContact: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        isReporter: false,
    },
    recovererInformation: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
    },
    recoveryDate: null,
    reporterInformation: {
        firstName: null,
        lastName: null,
        nationalIdentity: null,
        dateOfBirth: null,
        phone: null,
        email: null,
        isDriver: false,
    },
    reversingClaimDescription: '',
    roadConditionClaimDescription: '',
    roadWidth: null,
    searchedVehicles: [],
    selectedVehicle: initVehicleModel,
    selectedVehicleId: null,
    speedLimit: null,
    speedOnImpact: null,
    theftAndDamagesClaimDescription: '',
    timeSinceAction: null,
    typeOfAnimalCollision: null,
    typeOfAutoClaim: null,
    typeOfIntersection: null,
    typeOfRoad: null,
    vehicles: [],
    whoAtFault: null,
    whoResponsibleClaimDescription: '',
    witnesses: [],
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = motorInitState, { type, data }: IMotorAction) {
    switch (type) {
        case MotorActionTypes.UPDATED:
            return { ...state, ...data };
        case MotorActionTypes.UPDATE_CLAIM_VEHICLE:
            return { ...state, vehicles: updateOrAddVehicle(state.vehicles, data as VehicleServiceModel) };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const motorActions = {
    update: actionWithPromise<MotorActionTypes.UPDATE, Partial<MotorState>>(MotorActionTypes.UPDATE),
    updated: actionWithPromise<MotorActionTypes.UPDATED, Partial<MotorState>>(MotorActionTypes.UPDATED),
    updateClaimVehicle: actionWithPromise<MotorActionTypes.UPDATE_CLAIM_VEHICLE, Partial<VehicleServiceModel>>(
        MotorActionTypes.UPDATE_CLAIM_VEHICLE
    ),
};

/**
 * Saga watchers
 */
export const motorWatcher = function* () {
    yield takeEvery(MotorActionTypes.UPDATE, motorSagas.update);
    yield takeLatest(MotorActionTypes.UPDATE_CLAIM_VEHICLE, motorSagas.updateClaimVehicle);
};

/**
 * Saga functions
 */
export const motorSagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: IMotorUpdateAction) {
        try {
            yield put(
                motorActions.updated({
                    ...data,
                })
            );
            resolve();
        } catch (e) {
            reject();
        }
    },
    updateClaimVehicle({ resolve = emptyFn }: IVehicleAction) {
        // for using dispatchWithPromise
        resolve();
    },
};
