import { css } from '@emotion/react';
import {
    black,
    rgbWithOpacity,
    secondary,
    secondaryRGB,
    white,
} from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';
import { hexToRgb } from '../../../utils/hexToRgb';

export const timePickerCss = (company?: customCANProps) =>
    css({
        display: 'grid',
        gridTemplateColumns: '3rem 1rem 3rem',
        gridTemplateRows: 'auto 1rem',

        '& label': {
            gridColumn: 'span 3',
            marginBottom: '0.2rem',
        },

        '& .minutes-select, .hours-select': {
            appearance: 'none',
            background: `${white} url('/assets/images/icons/polygon.svg') no-repeat 90%`,
            border: `1px solid ${company ? company.color : secondary}`,
            borderRadius: '2px',
            color: company ? company.color : secondary,
            fontSize: '1.125rem',
            justifySelf: 'self-start',
            padding: '.25rem 1.125rem .25rem .4rem',
            position: 'relative',
            textTransform: 'capitalize',

            '&:hover': {
                cursor: 'pointer',
            },

            '&:focus': {
                boxShadow: `0 0 0 0.2rem ${rgbWithOpacity(
                    company ? hexToRgb(company.color) : secondaryRGB,
                    0.25
                )}`,
                outline: 0,
            },

            '& option': {
                color: black,
                fontSize: '1rem',
            },
        },
        '& span': {
            gridColumn: 2,
            justifySelf: 'center',
            margin: '0 auto',
            paddingTop: '0.3rem',
        },

        '& .minutes-select': {
            gridColumn: 3,
        },

        '& .hours-select': {
            gridColumn: 1,
        },

        '& .invalid-feedback': {
            gridColumn: '1 /span 3',
        },

        '& select::-ms-expand': {
            display: 'none',
        },
    });
