import { VehicleTypeKeys, VehicleTypeTypeModel } from '../../interfaces/models';
import { Nullable } from '../../../../../interfaces/types';

/**
 * Destructure necessary imports
 */
const { BUS, TRUCK } = VehicleTypeKeys;

/**
 * Logic
 */
export const isVehicleHeavy = (
    vehicleType: VehicleTypeTypeModel | undefined,
    vehicleWeight: Nullable<number> | undefined
): boolean => {
    if (
        vehicleType === BUS ||
        vehicleType === TRUCK ||
        (vehicleWeight && vehicleWeight > 3500)
    ) {
        return true;
    }

    return false;
};
