import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { OTHER_INSURANCE_COMPANY_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const otherInsuranceSchema = (t: TFunction) => {
    return {
        otherInsuranceCompany: Yup.string().label(t(OTHER_INSURANCE_COMPANY_LABEL)).required().nullable(),
    };
};
