import { Datable, Nullable } from '../../../../../interfaces';

export interface OccupationModel {
    dateOfEmployment: Datable;
    dateOfTermination: Datable;
    employmentRelationship: OccupationTypeModel;
    occupationType: OccupationTypeModel;
}

export type OccupationTypeModel = Nullable<string>;

export const initOccupation: OccupationModel = {
    dateOfEmployment: null,
    dateOfTermination: null,
    employmentRelationship: null,
    occupationType: null,
};
