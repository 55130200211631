/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React, { Fragment } from 'react';
import { Nullable } from '../../../interfaces/types/Nullable.types';
import { Clickable } from '../../../interfaces/types/Events.types';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';
import { SummaryNavButton } from '../../atoms/summaryItems/SummaryNavButton';

export interface SummaryItemProps {
    dataTestId: Nullable<string>;
    buttonText: string;
    path: string;
    inputWrapperClass?: string;
    className?: string;
    show?: boolean;
    textKey?: string;
    handleClick?: (e: Clickable, path: string) => void;
}

export const SummaryItem = ({
    dataTestId,
    buttonText,
    path,
    inputWrapperClass,
    className,
    show = true,
    textKey,
    handleClick,
}: SummaryItemProps) => {
    return (
        <Fragment>
            {show && (
                <div className={inputWrapperClass}>
                    <SummaryText text={textKey} />
                    <SummaryNavButton
                        {...{
                            path,
                            buttonText,
                            handleClick,
                            dataTestId,
                            textKey,
                            className,
                        }}
                    />
                </div>
            )}
        </Fragment>
    );
};
