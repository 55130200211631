import { Datable, Nullable } from '../../../../../interfaces';

export interface ClaimantModel {
    bankAccountNumber?: Nullable<string>;
    city: Nullable<string>;
    dateOfBirth?: Datable;
    email: Nullable<string>;
    firstName: Nullable<string>;
    language?: Nullable<string>;
    lastName: Nullable<string>;
    missingSSN?: boolean;
    nationalIdentity: Nullable<string>;
    nationality: Nullable<string>;
    nationalityId: {
        id: Nullable<number>;
        key: Nullable<string>;
    };
    phone: Nullable<string>;
    street: Nullable<string>;
    title?: Nullable<string>;
    zip: Nullable<string>;
}

export const initClaimantModel = {
    bankAccountNumber: null,
    city: null,
    email: null,
    firstName: null,
    lastName: null,
    nationalIdentity: null,
    nationality: null,
    nationalityId: {
        id: null,
        key: null,
    },
    phone: null,
    street: null,
    zip: null,
};
