const patterns = [
    /^[0-2]\d{7}$/,
    /^OC\d{6}$/,
    /^SC\d{6}$/,
    /^SO\d{6}$/,
    /^NI\d{6}$/,
    /^LP\d{6}$/,
    /^R\d{7}$/,
    /^SL\d{6}$/,
    /^NC\d{6}$/,
    /^NL\d{6}$/,
    /^FC\d{6}$/,
    /^SP\d{5}R$/,
    /^SP\d{4}RS$/,
    /^IP\d{5}R$/,
    /^IP\d{4}RS$/,
    /^IP\d{6}$/,
    /^E\d{8}$/,
    /^W\d{8}$/,
    /^S\d{8}$/,
    /^RS\d{6}$/,
];

export function businessNumberUKMatchPattern(businessNumber: string) {
    return patterns.some((pattern) => {
        return pattern.test(businessNumber);
    });
}
