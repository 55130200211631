/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import {
    singleCheckboxCss,
    singleCheckboxGreenCss,
} from './SingleCheckbox.style';
import { Changable } from '../../../../interfaces';
import { customCANProps } from '../../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface SingleCheckboxProps {
    checked: boolean;
    children: React.ReactNode;
    customCAN?: customCANProps;
    green?: boolean;
    handleChange: (e: Changable) => void;
    name: string;
    reference?: (instance: HTMLInputElement) => void;
    wrapperClass?: string;
}

/**
 * Component view and component logic
 */
export const SingleCheckbox = ({
    checked,
    children,
    customCAN,
    green = false,
    handleChange,
    name,
    reference,
    wrapperClass = '',
}: SingleCheckboxProps) => {
    return (
        <div
            className={`custom-checkbox ${wrapperClass}`}
            css={css(
                green
                    ? singleCheckboxGreenCss(customCAN)
                    : singleCheckboxCss(customCAN)
            )}
        >
            <input
                className={'custom-control-input'}
                data-testid={`${name}-checkbox-input`}
                id={name}
                onChange={handleChange}
                ref={reference}
                type={'checkbox'}
                {...{ checked, name }}
            />
            <label data-testid={`${name}-checkbox-label`} htmlFor={name}>
                {children}
            </label>
        </div>
    );
};
