import { isAllDigits } from '../../../utils';

export function validateAccountNumberBBANSE(accountNumber: string) {
    return {
        isValid: function isValid() {
            if (accountNumber.length === 0) {
                return false;
            }

            if (!isAllDigits(accountNumber)) {
                return false;
            }

            const parsedAccountNumber = parseInt(accountNumber);
            const accountNumberLength = parsedAccountNumber.toString().length;
            const parsedClearingNumber = parseInt(accountNumber.slice(0, 4));
            let checkBanks = false;

            banks.forEach(function (item) {
                if (checkBanks) {
                    return;
                }
                const min = item.minClearingNumber;
                const max = item.maxClearingNumber;
                const maxLength = item.maxAccountNumberLength;
                if (
                    parsedClearingNumber >= min &&
                    parsedClearingNumber <= max &&
                    accountNumberLength <= maxLength + 4
                ) {
                    checkBanks = true;
                }
            });

            return checkBanks;
        },
    };
}

const banks = [
    {
        name: 'Amfa Bank',
        minClearingNumber: 9660,
        maxClearingNumber: 9669,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Avanza Bank',
        minClearingNumber: 9550,
        maxClearingNumber: 9569,
        maxAccountNumberLength: 7,
    },
    {
        name: 'BNP Paribas Fortis',
        minClearingNumber: 9470,
        maxClearingNumber: 9479,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Citibank International Plc, Sweden Branch',
        minClearingNumber: 9040,
        maxClearingNumber: 9049,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Danske Bank A/S, Sverige Filial',
        minClearingNumber: 9660,
        maxClearingNumber: 9669,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Danske Bank A/S, Sverige Filial',
        minClearingNumber: 1200,
        maxClearingNumber: 1399,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Danske Bank A/S, Sverige Filial',
        minClearingNumber: 2400,
        maxClearingNumber: 2499,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Danske Bank A/S, Sverige Filial',
        minClearingNumber: 9180,
        maxClearingNumber: 9189,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Danske Bolån',
        minClearingNumber: 1200,
        maxClearingNumber: 1399,
        maxAccountNumberLength: 7,
    },
    {
        name: 'DNB Bank ASA, filial Sverige',
        minClearingNumber: 9190,
        maxClearingNumber: 9199,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Forex Bank AB',
        minClearingNumber: 9400,
        maxClearingNumber: 9449,
        maxAccountNumberLength: 7,
    },
    {
        name: 'GE Money Bank',
        minClearingNumber: 9460,
        maxClearingNumber: 9469,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Handelsbanken',
        minClearingNumber: 6000,
        maxClearingNumber: 6999,
        maxAccountNumberLength: 9,
    },
    {
        name: 'ICA Banken',
        minClearingNumber: 9270,
        maxClearingNumber: 9279,
        maxAccountNumberLength: 7,
    },
    {
        name: 'IKANO Banken',
        minClearingNumber: 9170,
        maxClearingNumber: 9179,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Länsförsäkringar Bank',
        minClearingNumber: 9020,
        maxClearingNumber: 9029,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Länsförsäkringar Bank',
        minClearingNumber: 3400,
        maxClearingNumber: 3409,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Länsförsäkringar Bank',
        minClearingNumber: 9060,
        maxClearingNumber: 9069,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Marginalen Bank',
        minClearingNumber: 9230,
        maxClearingNumber: 9239,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Nordax Finans AB',
        minClearingNumber: 9640,
        maxClearingNumber: 9649,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Nordea',
        minClearingNumber: 1100,
        maxClearingNumber: 1199,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Nordea',
        minClearingNumber: 1401,
        maxClearingNumber: 2099,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Nordea',
        minClearingNumber: 3000,
        maxClearingNumber: 3399,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Nordea',
        minClearingNumber: 3410,
        maxClearingNumber: 4999,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Nordea - PlusGirot',
        minClearingNumber: 9500,
        maxClearingNumber: 9549,
        maxAccountNumberLength: 10,
    },
    {
        name: 'Nordea - PlusGirot',
        minClearingNumber: 9960,
        maxClearingNumber: 9969,
        maxAccountNumberLength: 10,
    },
    {
        name: 'Nordnet Bank',
        minClearingNumber: 9100,
        maxClearingNumber: 9109,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Resurs Bank AB',
        minClearingNumber: 9280,
        maxClearingNumber: 9289,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Royal Bank of Scotland',
        minClearingNumber: 9090,
        maxClearingNumber: 9099,
        maxAccountNumberLength: 7,
    },
    {
        name: 'SBAB',
        minClearingNumber: 9250,
        maxClearingNumber: 9259,
        maxAccountNumberLength: 7,
    },
    {
        name: 'SEB',
        minClearingNumber: 5000,
        maxClearingNumber: 5999,
        maxAccountNumberLength: 7,
    },
    {
        name: 'SEB',
        minClearingNumber: 9120,
        maxClearingNumber: 9124,
        maxAccountNumberLength: 7,
    },
    {
        name: 'SEB',
        minClearingNumber: 9130,
        maxClearingNumber: 9149,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Skandiabanken AB',
        minClearingNumber: 9150,
        maxClearingNumber: 9169,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Sparbanken Syd',
        minClearingNumber: 9570,
        maxClearingNumber: 9579,
        maxAccountNumberLength: 10,
    },
    {
        name: 'Sparbanken Öresund',
        minClearingNumber: 9300,
        maxClearingNumber: 9349,
        maxAccountNumberLength: 10,
    },
    {
        name: 'Swedbank',
        minClearingNumber: 7000,
        maxClearingNumber: 7999,
        maxAccountNumberLength: 7,
    },
    {
        name: 'Swedbank och fristående Sparbanker',
        minClearingNumber: 8000,
        maxClearingNumber: 8999,
        maxAccountNumberLength: 10,
    },
    {
        name: 'Ålandsbanken Abp (Finland), svensk filial',
        minClearingNumber: 2300,
        maxClearingNumber: 2399,
        maxAccountNumberLength: 7,
    },
];
