import React from 'react';
import { SVGClickable } from '../../../interfaces';
import {
    BodyPartListKeys,
    BodyPartListTypeModel,
    isBodyPartChecked,
} from '../../../modules';

/**
 * Destructure necessary imports
 */
const {
    CRANIUM,
    EAR_LEFT,
    EAR_RIGHT,
    EYE_LEFT,
    EYE_RIGHT,
    FACE,
    HEAD,
    HEAD_OTHER,
    TEETH,
} = BodyPartListKeys;

/**
 * Component logic and component view
 */
interface BodyPartListLowerBodyImageProps {
    onClick: (e: SVGClickable) => void;
    selectedParts: BodyPartListTypeModel;
}

export const BodyPartListHeadImage = ({
    onClick,
    selectedParts,
}: BodyPartListLowerBodyImageProps) => {
    const isSelected = (part: BodyPartListKeys) =>
        isBodyPartChecked(part, selectedParts) ? 'selected' : '';
    return (
        <>
            <svg
                width={'386'}
                height={'570'}
                viewBox={'0 0 386 570'}
                fill={'none'}
                xmlns={'http://www.w3.org/2000/svg'}
            >
                <g id={'body-parts-svg-front'}>
                    <g id={'body-parts-svg-front__footer'}>
                        <line
                            id={'Line 38'}
                            x1={'0.695312'}
                            y1={'529.531'}
                            x2={'382.23'}
                            y2={'529.531'}
                            stroke={'#445562'}
                            strokeWidth={'2'}
                        />
                        <line
                            id={'Line 39'}
                            x1={'190.465'}
                            y1={'530.531'}
                            x2={'190.465'}
                            y2={'504.907'}
                            stroke={'#445562'}
                            strokeWidth={'2'}
                        />
                    </g>
                    <g id={'body-parts-svg-front__selectable'}>
                        {/* CRANIUM */}
                        <path
                            onClick={onClick}
                            className={
                                isSelected(CRANIUM) ||
                                isSelected(HEAD) ||
                                isSelected(HEAD_OTHER)
                            }
                            id={'CRANIUM'}
                            d={
                                'M206.481 45.64C186.791 16.84 154.701 0 103.581 0H104.581C53.5811 0 21.5811 16.73 1.87107 45.36C-20.9389 78.45 229.121 78.73 206.481 45.64Z'
                            }
                            fill={'#F58025'}
                            transform={'translate(85, 1)'}
                        />

                        {/* FACE */}
                        <path
                            onClick={onClick}
                            className={
                                isSelected(FACE) ||
                                isSelected(HEAD) ||
                                isSelected(HEAD_OTHER)
                            }
                            id={'FACE'}
                            d={
                                'M263.388 139.819C263.388 82.5993 257.118 33.7294 234.478 0.639374C257.118 33.7294 7.03831 33.4494 29.8283 0.359375C7.03831 33.4494 0.738281 82.4393 0.738281 139.819C0.738281 246.579 59.4783 321.609 132.058 321.979C204.638 321.609 263.388 246.579 263.388 139.819Z'
                            }
                            fill={'#F58025'}
                            transform={'translate(57, 45)'}
                        />

                        {/* EYE */}
                        <path
                            onClick={onClick}
                            className={isSelected(EYE_LEFT)}
                            id={'EYE_LEFT'}
                            d={
                                'M63.7113 9.83171C52.5913 20.1517 37.8013 22.7418 27.5312 21.9418C17.3554 21.1651 7.75903 16.902 0.361267 9.87175L0.28125 9.75175C0.28125 9.75175 29.1113 -11.7683 63.7113 9.83171Z'
                            }
                            fill={'#F58025'}
                            transform={'translate(217, 149)'}
                        />
                        <path
                            onClick={onClick}
                            className={isSelected(EYE_RIGHT)}
                            id={'EYE_RIGHT'}
                            d={
                                'M0.410156 9.83171C11.5402 20.1517 26.3302 22.7418 36.6002 21.9418C46.776 21.1651 56.3724 16.902 63.7702 9.87175L63.8502 9.75175C63.8502 9.75175 35.0102 -11.7683 0.410156 9.83171V9.83171Z'
                            }
                            fill={'#F58025'}
                            transform={'translate(99, 149)'}
                        />

                        {/* EAR */}
                        <path
                            onClick={onClick}
                            className={
                                isSelected(EAR_LEFT) ||
                                isSelected(HEAD) ||
                                isSelected(HEAD_OTHER)
                            }
                            id={'EAR_LEFT'}
                            d={
                                'M6.47192 13.3893C6.47192 13.3893 6.73187 9.48933 11.4119 4.90933C16.0919 0.329331 34.5619 -4.78068 37.2319 15.3393C39.9019 35.4593 36.0519 51.1693 31.7719 63.6293C27.4919 76.0893 19.9919 94.2593 11.3319 98.7193C2.67191 103.179 1.33191 99.5293 1.33191 99.5293L0.921875 99.0293C4.58993 81.1939 6.4096 63.0279 6.35193 44.8193C6.35193 34.1193 6.13195 23.7193 5.62195 13.6493L6.47192 13.3893Z'
                            }
                            fill={'#F58025'}
                            transform={'translate(314, 141)'}
                        />
                        <path
                            onClick={onClick}
                            className={
                                isSelected(EAR_RIGHT) ||
                                isSelected(HEAD) ||
                                isSelected(HEAD_OTHER)
                            }
                            id={'EAR_RIGHT'}
                            d={
                                'M32.4218 14.3591C31.9418 24.2091 31.7418 34.3591 31.7418 44.8191C31.6832 63.0102 33.4961 81.1591 37.1519 98.9791L36.7519 99.5291C36.7519 99.5291 35.4519 103.169 26.7519 98.7191C18.0519 94.2691 10.6018 76.0891 6.31185 63.6291C2.02185 51.1691 -1.82817 35.4591 0.851827 15.3391C3.53183 -4.7809 22.0018 0.339112 26.6718 4.90911C31.3418 9.47911 31.6118 13.3891 31.6118 13.3891L32.4218 14.3591Z'
                            }
                            fill={'#F58025'}
                            transform={'translate(27, 141)'}
                        />

                        {/* TEETH */}
                        <path
                            onClick={onClick}
                            className={isSelected(TEETH)}
                            id={'TEETH'}
                            d={
                                'M47.8625 19.0027C25.8025 21.0927 17.4125 13.8727 17.4125 13.8727L0.0625 4.16266C0.0625 4.16266 27.1225 10.3727 47.8525 10.3727H48.2325C68.9625 10.3727 96.0225 4.16266 96.0225 4.16266L78.7025 13.9127C78.7025 13.9127 70.3125 21.1327 48.2425 19.0427'
                            }
                            fill={'#F58025'}
                            transform={'translate(141, 290)'}
                        />
                        <path
                            onClick={onClick}
                            className={isSelected(TEETH)}
                            id={'TEETH'}
                            d={
                                'M48.2525 10.3326C68.9825 10.3326 96.0425 4.12268 96.0425 4.12268L90.6225 4.85266C90.6225 4.85266 69.3525 -3.54732 48.0625 2.25268C26.7625 -3.54732 5.49249 4.85266 5.49249 4.85266L0.0625 4.16266C0.0625 4.16266 27.1225 10.3727 47.8525 10.3727L48.2525 10.3326Z'
                            }
                            fill={'#F58025'}
                            transform={'translate(141, 290)'}
                        />
                    </g>
                    <g id={'body-parts-svg-front__content'}>
                        <path
                            d={
                                'M191.699 158.752C191.699 158.752 220.532 137.233 255.135 158.83'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M191.781 158.871C191.781 158.871 201.84 169.607 218.953 170.939C229.221 171.737 244.012 169.15 255.135 158.828'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M222.933 166.577C227.325 166.577 230.886 163.017 230.886 158.625C230.886 154.232 227.325 150.672 222.933 150.672C218.541 150.672 214.98 154.232 214.98 158.625C214.98 163.017 218.541 166.577 222.933 166.577Z'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M164.18 254.047C167.215 254.047 174.231 253.183 178.211 252.18C184.085 250.698 187.805 244.925 186.741 238.958C184.112 224.246 176.302 218.101 179.829 155.316C179.946 153.253 180.263 151.208 180.79 149.21C182.431 142.95 179.973 131.574 196.25 128.229L229.415 124.083C229.415 124.083 258.434 118.902 270.874 134.447'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M212.469 294.117C212.469 294.117 185.409 300.335 164.68 300.335'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M207.047 294.847C207.047 294.847 185.775 286.445 164.48 292.253'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M195.139 303.871C195.139 303.871 186.749 311.092 164.68 309.005'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M295.896 154.388C295.896 154.388 296.152 150.493 300.829 145.908C305.502 141.324 323.982 136.225 326.657 156.343C329.333 176.465 325.476 192.169 321.19 204.632C316.904 217.091 309.412 235.261 300.755 239.718C292.098 244.174 290.797 240.535 290.797 240.535'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M296.615 167.604C296.615 167.604 297.997 165.772 299.457 161.966C303.093 152.484 313.546 147.838 317.805 157.905C323.121 170.473 318.46 189.39 316.04 197.707C313.62 206.023 310.089 220.403 304.254 221.015C300.766 221.379 294.188 220.241 294.188 220.241'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M164.003 1C273.873 1 295.811 78.8035 295.811 185.823C295.811 292.843 236.774 367.987 163.949 367.987'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M164.963 1C55.0938 1 33.1562 78.8035 33.1562 185.823C33.1562 292.843 92.1932 367.987 165.018 367.987'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M137.271 158.752C137.271 158.752 108.438 137.233 73.8359 158.83'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M137.19 158.871C137.19 158.871 127.131 169.607 110.018 170.939C99.7498 171.737 84.9596 169.15 73.8359 158.828'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M106.035 166.577C110.427 166.577 113.987 163.017 113.987 158.625C113.987 154.232 110.427 150.672 106.035 150.672C101.643 150.672 98.082 154.232 98.082 158.625C98.082 163.017 101.643 166.577 106.035 166.577Z'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M164.788 254.047C161.753 254.047 154.737 253.183 150.757 252.18C144.884 250.698 141.163 244.925 142.228 238.958C144.857 224.246 152.666 218.101 149.139 155.316C149.023 153.253 148.705 151.208 148.179 149.21C146.537 142.95 148.995 131.574 132.718 128.229L99.5528 124.083C99.5528 124.083 70.5338 118.902 58.0938 134.447'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M116.5 294.117C116.5 294.117 143.56 300.335 164.289 300.335'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M121.922 294.847C121.922 294.847 143.193 286.445 164.488 292.253'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M133.828 303.871C133.828 303.871 142.218 311.092 164.287 309.005'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M33.0708 154.388C33.0708 154.388 32.8152 150.493 28.1381 145.908C23.461 141.324 4.98488 136.225 2.30948 156.343C-0.365925 176.461 3.49037 192.169 7.77643 204.632C12.0625 217.091 19.5544 235.261 28.2117 239.718C36.869 244.174 38.1699 240.535 38.1699 240.535'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                        <path
                            d={
                                'M32.3556 167.604C32.3556 167.604 30.9734 165.772 29.5138 161.966C25.8782 152.484 15.4243 147.838 11.1654 157.905C5.84942 170.473 10.5111 189.39 12.9309 197.707C15.3508 206.023 18.8818 220.403 24.7166 221.015C28.2051 221.379 34.7833 220.241 34.7833 220.241'
                            }
                            stroke={'#445562'}
                            strokeWidth={'1.5'}
                            strokeMiterlimit={'10'}
                            strokeLinecap={'round'}
                            transform={'translate(25, 0)'}
                        />
                    </g>
                </g>
            </svg>
        </>
    );
};
