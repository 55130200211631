import { css } from '@emotion/react';
import { primary, secondary } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const printLinkCss = (company?: customCANProps) =>
    css({
        '& button': {
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: primary,
            fontSize: '1rem',

            '&:hover, &:focus': {
                color: company ? company.color : secondary,
                outline: 0,
                transition: 'all 200ms ease-in-out',
            },
        },
    });
