import React from 'react';
import { isYes, SummaryItem, SummaryItemProps, YesNoModel } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderOtherInsurance = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    hasOtherInsurance: YesNoModel
) => {
    return (
        <>
            {isYes(hasOtherInsurance) && (
                <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />
            )}
        </>
    );
};
