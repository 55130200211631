import { VehicleModel } from '../modules/can/motor/interfaces/models';

export const vehicleListUtil = (vehicle: VehicleModel) => {
    const { make, type, registrationNumber, model } = vehicle;
    return `${make ? make : ''}${
        !!type && !!model && !!registrationNumber ? ' ' : ''
    }${type ? type : ''}${!!model && !!registrationNumber ? ' ' : ''}${
        model ? `(${model})` : ''
    }${registrationNumber ? ', ' : ''}${
        registrationNumber ? registrationNumber : ''
    }`;
};
