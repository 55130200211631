import { toUnicode } from 'punycode';

export function onlyAsciiValidator(
    value: string,
    shouldDecode: boolean = true
) {
    return {
        isValid: function isValid(): boolean {
            // eslint-disable-next-line no-control-regex
            return !new RegExp(/[^\x00-\x7F]/g).test(
                shouldDecode ? toUnicode(value) : value
            );
        },
    };
}
