import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { startIsDrivingPage } from './start/isDriving';
import { startInsuredByProtectorPage } from './start/insuredByProtector';
import { parkedClaimCausePage } from './parked/claimCause';
import { parkedEngineHasDigitalServiceBookPage } from './parked/engine/hasDigitalServiceBook';
import { drivingNoVehicleEngineHasDigitalServiceBookPage } from './driving/noVehicle/engine/hasDigitalServiceBook';
import { parkedOtherClaimCauseDetailsPage } from './parked/other/claimCauseDetails';
import { parkedParkingIsCounterpartyKnownPage } from './parked/parking/isCounterpartyKnown';
import { parkedParkingUnidentifiedTypePage } from './parked/parking/unidentifiedType';
import { parkedParkingHasWitnessesPage } from './parked/parking/hasWitnesses';
import { parkedTheftAndDamagesIsSelfDiscoveredClaimPage } from './parked/theftAndDamages/isSelfDiscoveredClaim';
import { parkedTheftAndDamagesIsVehicleStolenPage } from './parked/theftAndDamages/isVehicleStolen';
import { parkedTheftAndDamagesHasAllKeysPage } from './parked/theftAndDamages/hasAllKeys';
import { parkedTheftAndDamagesIsVehicleRecoveredPage } from './parked/theftAndDamages/isVehicleRecovered';
import { parkedTheftAndDamagesIsItemMissingPage } from './parked/theftAndDamages/isItemMissing';
import { endHasVehicleDamagesPage } from './end/hasVehicleDamages';
import { endNeedVehicleTowingPage } from './end/needVehicleTowing';
import { endHasNonVehicleDamagesPage } from './end/hasNonVehicleDamages';
import { endHasPersonInjuriesPage } from './end/hasPersonInjuries';
import { endIsPoliceContactedPage } from './end/isPoliceContacted';
import { endHasWitnessesPage } from './end/hasWitnesses';
import { drivingIsOtherVehicleInvolvedPage } from './driving/isOtherVehicleInvolved';
import { drivingNoVehicleClaimCausePage } from './driving/noVehicle/claimCause';
import { drivingNoVehicleIsReversingPage } from './driving/noVehicle/isReversing';
import { drivingNoVehicleAnimalTypeOfAnimalPage } from './driving/noVehicle/animal/typeOfAnimal';
import { drivingNoVehicleAnimalHasAnimalEscapedPage } from './driving/noVehicle/animal/hasAnimalEscaped';
import { drivingVehiclesUnidentifiedTypePage } from './driving/vehicles/unidentifiedType';
import { drivingVehiclesClaimLocationTypePage } from './driving/vehicles/claimLocationType';
import { drivingVehiclesIsLaneChangePage } from './driving/vehicles/isLaneChange';
import { drivingVehiclesHeadOnIsCounterpartyStationaryPage } from './driving/vehicles/headOn/isCounterpartyStationary';
import { drivingVehiclesIntersectionTypeOfIntersectionPage } from './driving/vehicles/intersection/typeOfIntersection';
import { drivingVehiclesIntersectionClaimCausePage } from './driving/vehicles/intersection/claimCause';
import { drivingVehiclesLaneChangeWhoAtFaultPage } from './driving/vehicles/laneChange/whoAtFault';
import { drivingVehiclesOvertakingWhoAtFaultPage } from './driving/vehicles/overtaking/whoAtFault';
import { drivingVehiclesParkedWhoAtFaultPage } from './driving/vehicles/parked/whoAtFault';
import { drivingVehiclesParkedIsReversingPage } from './driving/vehicles/parked/isReversing';
import { drivingVehiclesParkingOtherClaimCausePage } from './driving/vehicles/parkingOther/claimCause';
import { drivingVehiclesRearEndedWhoAtFaultPage } from './driving/vehicles/rearEnded/whoAtFault';
import { drivingVehiclesReversingWhoAtFaultPage } from './driving/vehicles/reversing/whoAtFault';
import { drivingVehiclesRoadTypeOfRoadPage } from './driving/vehicles/road/typeOfRoad';
import { drivingVehiclesRoadClaimCausePage } from './driving/vehicles/road/claimCause';
import { drivingVehiclesRoundaboutClaimantRoundaboutPositioningPage } from './driving/vehicles/roundabout/claimantRoundaboutPositioning';
import { drivingVehiclesRoundaboutCounterpartyRoundaboutPositioningPage } from './driving/vehicles/roundabout/counterpartyRoundaboutPositioning';
import { drivingVehiclesRoundaboutClaimCausePage } from './driving/vehicles/roundabout/claimCause';

/**
 * Config logic
 */

export const motorCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    startInsuredByProtectorPage(props),
    startIsDrivingPage(props),

    // Driving
    drivingIsOtherVehicleInvolvedPage(props),

    // Driving / Vehicles
    drivingVehiclesUnidentifiedTypePage(props),
    drivingVehiclesClaimLocationTypePage(props),
    drivingVehiclesIsLaneChangePage(props),

    // Driving / Vehicles / Head On
    drivingVehiclesHeadOnIsCounterpartyStationaryPage(props),

    // Driving / Vehicles / Intersection
    drivingVehiclesIntersectionTypeOfIntersectionPage(props),
    drivingVehiclesIntersectionClaimCausePage(props),

    // Driving / Vehicles / Lane Change
    drivingVehiclesLaneChangeWhoAtFaultPage(props),

    // Driving / Vehicles / Overtaking
    drivingVehiclesOvertakingWhoAtFaultPage(props),

    // Driving / Vehicles / Parked
    drivingVehiclesParkedWhoAtFaultPage(props),
    drivingVehiclesParkedIsReversingPage(props),

    // Driving / Vehicles / Parking Other
    drivingVehiclesParkingOtherClaimCausePage(props),

    // Driving / Vehicles / Rear Ended
    drivingVehiclesRearEndedWhoAtFaultPage(props),

    // Driving / Vehicles / Reversing
    drivingVehiclesReversingWhoAtFaultPage(props),

    // Driving / Vehicles / Road
    drivingVehiclesRoadTypeOfRoadPage(props),
    drivingVehiclesRoadClaimCausePage(props),

    // Driving / Vehicles / Roundabout
    drivingVehiclesRoundaboutClaimantRoundaboutPositioningPage(props),
    drivingVehiclesRoundaboutCounterpartyRoundaboutPositioningPage(props),
    drivingVehiclesRoundaboutClaimCausePage(props),

    // Driving / No Vehicle
    drivingNoVehicleClaimCausePage(props),
    drivingNoVehicleIsReversingPage(props),

    // Driving / No Vehicle / Animal
    drivingNoVehicleAnimalTypeOfAnimalPage(props),
    drivingNoVehicleAnimalHasAnimalEscapedPage(props),

    // Driving / No Vehicle / Engine
    drivingNoVehicleEngineHasDigitalServiceBookPage(props),

    // Parked
    parkedClaimCausePage(props),

    // Parked / Engine
    parkedEngineHasDigitalServiceBookPage(props),

    // Parked / Other
    parkedOtherClaimCauseDetailsPage(props),

    // Parked / Parking
    parkedParkingIsCounterpartyKnownPage(props),
    parkedParkingUnidentifiedTypePage(props),
    parkedParkingHasWitnessesPage(props),

    // Parked / Theft And Damages
    parkedTheftAndDamagesIsSelfDiscoveredClaimPage(props),
    parkedTheftAndDamagesIsVehicleStolenPage(props),
    parkedTheftAndDamagesHasAllKeysPage(props),
    parkedTheftAndDamagesIsVehicleRecoveredPage(props),
    parkedTheftAndDamagesIsItemMissingPage(props),

    // End
    endHasVehicleDamagesPage(props),
    endNeedVehicleTowingPage(props),
    endHasNonVehicleDamagesPage(props),
    endHasPersonInjuriesPage(props),
    endIsPoliceContactedPage(props),
    endHasWitnessesPage(props),
];
