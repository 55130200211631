import React, { MouseEvent, useEffect } from 'react';
import { LoginKeys } from '../../../models/motor/Login';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { selectLogin } from '../../../sagas/selectors/motorSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { Carousel, CarouselItem, NoIcon, PageLayout, YesIcon } from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { Helmet } from 'react-helmet';
import { bankIDJWTServiceActions } from '../../../sagas/services/bankIDJWTService';
import { selectBankIDService } from '../../../sagas/selectors/bankIDSelectors';
import { CORE_ID } from '../../../config/bankID';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { WITH_BANKID, WITHOUT_BANKID } = LoginKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { START_CLAIM_DATE } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const LoginPage = () => {
    const dispatch = useDispatch();
    const login = useSelector(selectLogin);
    const bankID = useSelector(selectBankIDService);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.start.login');

    useEffect(() => {
        dispatch(bankIDJWTServiceActions.request());
    }, [dispatch]);

    const handleBackButton = useGoBack();

    const options = [
        { icon: <YesIcon />, id: WITH_BANKID, name: tWithNS.t(`select.${WITH_BANKID}`) },
        { icon: <NoIcon />, id: WITHOUT_BANKID, name: tWithNS.t(`select.${WITHOUT_BANKID}`) },
    ];

    const handleSelect = (id: LoginKeys, e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const option = options.find((x) => x.id === id);

        if (option) {
            if (option.id === WITH_BANKID) {
                dispatch(wizardActions.skipBackToPrev(true));
                //@ts-ignore
                window.coreid_client.init({
                    config: {
                        allowedEids: ['*'],
                        mode: 'auth',
                        language: 'sv',
                        location: 'se',
                        provider: 'se-bankid',
                    },
                    token: `${bankID?.jwt}`,
                    //@ts-ignore
                    callback: function (data) {
                        console.log('This is the data', data);
                        if (data.success === true) {
                            dispatcherWithPromise(dispatch, motorActions.update, { login: option.id })
                                .then(() => {
                                    return dispatcherWithPromise(dispatch, commonActions.update, {
                                        jwt: data.token,
                                        loggedIn: true,
                                    });
                                })
                                .then(() => {
                                    console.log('Should go to next now');
                                    dispatch(wizardActions.goToNext());
                                    //@ts-ignore
                                    window.coreid_client.close();
                                });
                        } else if (data.type === 'failed') {
                            console.log('Auth failed', data.errorMessage);
                        } else if (data.type === 'cancelled') {
                            console.log('User closed the application');
                        } else if (data.type === 'error') {
                            console.log('An error occurred', data.errorMessage);
                        }
                    },
                });
                //@ts-ignore
                window.coreid_client.start();
            } else {
                console.log('Gets into the else statement');
                dispatcherWithPromise(dispatch, motorActions.update, { login: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goTo(START_CLAIM_DATE)));
            }
        }
    };

    const render = options.map((option, idx) => {
        return (
            <CarouselItem
                key={idx}
                {...option}
                isSelected={option.id === login}
                handleClick={handleSelect}
                {...{ customCAN }}
            />
        );
    });

    return (
        <>
            <Helmet>
                <script src={CORE_ID} async />
            </Helmet>
            <PageLayout
                backBtnText={t(BACK_BUTTON)}
                continueBtnText={t(CONTINUE_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerText={tWithNS.t(HELP_TEXT)}
                headerSubTitle={tWithNS.t(SUB_TITLE)}
                headerTitle={tWithNS.t(TITLE)}
                showContinueButton={false}
                {...{ handleBackButton }}
            >
                <Carousel>{render}</Carousel>
            </PageLayout>
        </>
    );
};
