import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectClaimDescription, selectPerson } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    ClaimDescriptionModel,
    Clickable,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { personActions } from '../../../sagas/person';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { Controller, useForm } from 'react-hook-form';
import { claimDescriptionSchema } from '../../../validations/schemas/claimDescriptionSchema';
import { FormFieldNames } from '../../../config/formFieldNames';
import { selectUpload } from '../../../sagas/selectors/uploadSelectors';
import { DisplayDeathSituationSummary } from '../../../components/summary/person/DisplayDeathSituationSummary';
import { yupResolver } from '@hookform/resolvers/yup';
import { claimDescriptionUtil } from '../../../utils/claimDescriptionUtil';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_LABEL,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { CLAIM_DESCRIPTION } = FormFieldNames;

/**
 * Page view and page logic
 */
export const DeathSituationSummaryPage = () => {
    const dispatch = useDispatch();
    const claimDescription = useSelector(selectClaimDescription);
    const personState = useSelector(selectPerson);
    const uploadState = useSelector(selectUpload);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.situationSummary');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimDescriptionModel>({
        resolver: yupResolver(claimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            claimDescription,
        },
    });
    const model = { ...personState };
    const upload = { ...uploadState };

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: ClaimDescriptionModel) => {
        dispatcherWithPromise(dispatch, personActions.update, { claimDescription: values.claimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    const handleClick = (e: Clickable, path: string) => {
        dispatch(wizardActions.update({ backToPrev: true }));
        dispatch(wizardActions.goTo(path));
    };

    /**
     * Renders
     */
    const renderFooterHelpComponent = `<span class="summary-help-text">${tWithNS.t('help.marked')}</span>`;

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT, { component: renderFooterHelpComponent })}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <DisplayDeathSituationSummary tWithNS={tWithNS.t} {...{ handleClick, model, t, upload }} />

                    {/* Claim Description */}
                    <Controller
                        control={control}
                        name={CLAIM_DESCRIPTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.claimDescription}
                                errorMessage={errors.claimDescription?.message}
                                id={CLAIM_DESCRIPTION}
                                inputFieldWrapper={'col-12 divider'}
                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                reference={ref}
                                rows={8}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
