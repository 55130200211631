import { css } from '@emotion/react';
import { secondary } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const incidentSummaryCss = (company?: customCANProps) =>
    css({
        '& .incident-title': {
            fontFamily: 'BrandonTextBold, sans-serif',
            fontSize: '1.25rem',
            textAlign: 'left',

            '@media print, screen and (min-width: 768px)': {
                paddingBottom: '1rem',
                textAlign: 'right',
            },
        },

        '& .incident-text': {
            fontFamily: 'BrandonTextRegular, sans-serif',
            fontSize: '1rem',
            paddingBottom: '0.5rem',
            textAlign: 'left',

            '@media print, screen and (min-width: 768px)': {
                paddingBottom: '1rem',
            },
        },

        '& .registration-number': {
            paddingBottom: '1rem',
            textAlign: 'left',

            '@media print, screen and (min-width: 768px)': {
                paddingBottom: '3rem',
                textAlign: 'right',
            },

            '& span': {
                borderRadius: 2,
                boxShadow: '0 0 5px rgba(68, 85, 98, 0.5)',
                fontFamily: 'BrandonTextBold, sans-serif',
                fontSize: '1.5rem',
                padding: '0.5rem 1.5rem',
                textAlign: 'center',
            },
        },

        '& .vehicle-info': {
            fontFamily: 'BrandonTextBold, sans-serif',
            fontSize: '1.75rem',
            paddingBottom: '1rem',
            textAlign: 'left',

            '@media print, screen and (min-width: 768px)': {
                paddingBottom: '3rem',
            },
        },

        '& .location': {
            '&:hover': {
                color: company ? company.color : secondary,
            },
        },
    });
