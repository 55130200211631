import {
    CategoryKeys,
    FlowKeys,
    InsuranceTypeKeys,
    ObjectWithDynamicKey,
    PersonClaimCauseKeys,
    PersonInVehicleKeys,
    TeethOrBodyKeys,
    YesNoKeys,
} from '@protectorinsurance/ds-can';

export enum PersonRoutePaths {
    INDEX = '/swe/:language/5',
    ERROR = '/error',
    SESSION_TIMEOUT = '/session-timeout',

    // Dyn Paths
    DYN_BASE = '/swe/:language/5/dyn/',
    DYN_CAROUSEL = '/swe/:language/5/dyn/:flow?/:slug',
    // Accident
    DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER_ROLE = '/swe/:language/5/dyn/accident/claim-reporter-role',
    DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER = '/swe/:language/5/dyn/accident/claim-reporter',
    DYN_CAROUSEL_ACCIDENT_INSURANCE_TYPE = '/swe/:language/5/dyn/accident/insurance-type',
    DYN_CAROUSEL_ACCIDENT_CLAIM_LOCATION = '/swe/:language/5/dyn/accident/claim-location',
    DYN_CAROUSEL_ACCIDENT_CLAIM_TIME_OF_DAY = '/swe/:language/5/dyn/accident/claim-time-of-day',
    DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_INVOLVED = '/swe/:language/5/dyn/accident/is-vehicle-involved',
    DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY = '/swe/:language/5/dyn/accident/happen-domestically',
    DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_MOTORCYCLE = '/swe/:language/5/dyn/accident/is-vehicle-motorcycle',
    DYN_CAROUSEL_ACCIDENT_PERSON_IN_VEHICLE = '/swe/:language/5/dyn/accident/person-in-vehicle',
    DYN_CAROUSEL_ACCIDENT_HAS_LICENSE = '/swe/:language/5/dyn/accident/has-license',
    DYN_CAROUSEL_ACCIDENT_HAS_HELMET = '/swe/:language/5/dyn/accident/has-helmet',
    DYN_CAROUSEL_ACCIDENT_DURING_SPORT = '/swe/:language/5/dyn/accident/during-sport',
    DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE = '/swe/:language/5/dyn/accident/claim-cause',
    DYN_CAROUSEL_ACCIDENT_HAS_PLASTER_TREATMENT = '/swe/:language/5/dyn/accident/has-plaster-treatment',
    DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY = '/swe/:language/5/dyn/accident/previous-injury',
    DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED = '/swe/:language/5/dyn/accident/is-doctor-contacted',
    DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED = '/swe/:language/5/dyn/accident/is-dentist-contacted',
    DYN_CAROUSEL_ACCIDENT_TYPE_OF_MEDICAL_INSTITUTION = '/swe/:language/5/dyn/accident/type-of-medical-institution',
    DYN_CAROUSEL_ACCIDENT_FINISHED_TREATMENT = '/swe/:language/5/dyn/accident/finished-treatment',
    DYN_CAROUSEL_ACCIDENT_INVALIDITY = '/swe/:language/5/dyn/accident/invalidity',
    DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES = '/swe/:language/5/dyn/accident/has-expenses',
    DYN_CAROUSEL_ACCIDENT_NEED_TRANSPORT = '/swe/:language/5/dyn/accident/need-transport',
    DYN_CAROUSEL_ACCIDENT_IN_ELEMENTARY_SCHOOL = '/swe/:language/5/dyn/accident/in-elementary-school',
    DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES = '/swe/:language/5/dyn/accident/has-other-expenses',
    DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE = '/swe/:language/5/dyn/accident/has-other-insurance',
    DYN_CAROUSEL_ACCIDENT_HAS_ATTACHMENT = '/swe/:language/5/dyn/accident/has-attachment',
    // Death
    DYN_CAROUSEL_DEATH_CLAIMANT_AGE_CATEGORY = '/swe/:language/5/dyn/death/claimant-age-category',
    DYN_CAROUSEL_DEATH_CLAIM_REPORTER_ROLE = '/swe/:language/5/dyn/death/claim-reporter-role',
    DYN_CAROUSEL_DEATH_INSURANCE_TYPE = '/swe/:language/5/dyn/death/insurance-type',
    DYN_CAROUSEL_DEATH_IS_ACCIDENT = '/swe/:language/5/dyn/death/is-accident',
    DYN_CAROUSEL_DEATH_CLAIM_LOCATION = '/swe/:language/5/dyn/death/claim-location',
    DYN_CAROUSEL_DEATH_IS_VEHICLE_INVOLVED = '/swe/:language/5/dyn/death/is-vehicle-involved',
    DYN_CAROUSEL_DEATH_CLAIMANT_MARITAL_STATUS = '/swe/:language/5/dyn/death/claimant-marital-status',
    DYN_CAROUSEL_DEATH_IS_ESTATE_EXECUTOR = '/swe/:language/5/dyn/death/is-estate-executor',
    // Illness
    DYN_CAROUSEL_ILLNESS_CLAIM_REPORTER_ROLE = '/swe/:language/5/dyn/illness/claim-reporter-role',
    DYN_CAROUSEL_ILLNESS_INSURANCE_TYPE = '/swe/:language/5/dyn/illness/insurance-type',
    DYN_CAROUSEL_ILLNESS_HOSPITALISED = '/swe/:language/5/dyn/illness/hospitalised',
    DYN_CAROUSEL_ILLNESS_PREVIOUS_ILLNESS = '/swe/:language/5/dyn/illness/previous-illness',
    DYN_CAROUSEL_ILLNESS_HAS_OTHER_INSURANCE = '/swe/:language/5/dyn/illness/has-other-insurance',
    DYN_CAROUSEL_ILLNESS_HAS_ATTACHMENT = '/swe/:language/5/dyn/illness/has-attachment',

    // General paths
    START_CATEGORY = '/swe/:language/5/start/category',
    START_SUMMARY = '/swe/:language/5/start/summary',

    // Accident paths
    ACCIDENT_PRIVACY = '/swe/:language/5/accident/privacy',
    ACCIDENT_CLAIM_DATE = '/swe/:language/5/accident/claim-date',
    ACCIDENT_VEHICLE_INFORMATION = '/swe/:language/5/accident/vehicle-information',
    ACCIDENT_TYPE_OF_SPORT = '/swe/:language/5/accident/type-of-sport',
    ACCIDENT_TEETH_OR_BODY = '/swe/:language/5/accident/teeth-or-body',
    ACCIDENT_BODY_PARTS = '/swe/:language/5/accident/body-parts',
    ACCIDENT_TEETH = '/swe/:language/5/accident/teeth',
    ACCIDENT_CLAIM_CAUSE_CONSEQUENCES = '/swe/:language/5/accident/claim-cause-consequences',
    ACCIDENT_WEEKS_OF_PLASTER_TREATMENT = '/swe/:language/5/accident/weeks-of-plaster-treatment',
    ACCIDENT_DATE_OF_PREVIOUS_INJURY = '/swe/:language/5/accident/date-of-previous-injury',
    ACCIDENT_DATE_OF_TREATMENT = '/swe/:language/5/accident/date-of-treatment',
    ACCIDENT_MEDICAL_INSTITUTION_INFORMATION = '/swe/:language/5/accident/medical-institution-information',
    ACCIDENT_DENTIST_INFORMATION = '/swe/:language/5/accident/dentist-information',
    ACCIDENT_UPLOAD_MEDICAL_CERTIFICATE = '/swe/:language/5/accident/upload-medical-certificate',
    ACCIDENT_UPLOAD_RECEIPT = '/swe/:language/5/accident/upload-receipt',
    ACCIDENT_OTHER_INSURANCE_COMPANY = '/swe/:language/5/accident/other-insurance-company',
    ACCIDENT_UPLOAD_ATTACHMENT = '/swe/:language/5/accident/upload-attachment',
    ACCIDENT_SITUATION_SUMMARY = '/swe/:language/5/accident/situation-summary',
    ACCIDENT_CLAIMANT_INFORMATION = '/swe/:language/5/accident/claimant-information',
    ACCIDENT_COMPANY_INFORMATION = '/swe/:language/5/accident/company-information',
    ACCIDENT_ASSOCIATION_INFORMATION = '/swe/:language/5/accident/association-information',
    ACCIDENT_SCHOOL_INFORMATION = '/swe/:language/5/accident/school-information',
    ACCIDENT_REPORTER_INFORMATION = '/swe/:language/5/accident/reporter-information',
    ACCIDENT_BANK_ACCOUNT_INFORMATION = '/swe/:language/5/accident/bank-account-information',
    ACCIDENT_BANK_ACCOUNT_INFORMATION_CHILD = '/swe/:language/5/accident/bank-account-information-child',
    ACCIDENT_FINAL_SUMMARY = '/swe/:language/5/accident/final-summary',
    ACCIDENT_REPORT_COMPLETED = '/swe/:language/5/accident/report-completed',

    // Death paths
    DEATH_PRIVACY = '/swe/:language/5/death/privacy',
    DEATH_VEHICLE_INFORMATION = '/swe/:language/5/death/vehicle-information',
    DEATH_DATE_OF_DEATH = '/swe/:language/5/death/date-of-death',
    DEATH_SITUATION_SUMMARY = '/swe/:language/5/death/situation-summary',
    DEATH_UPLOAD_DEATH_CERTIFICATE = '/swe/:language/5/death/upload-death-certificate',
    DEATH_CLAIMANT_INFORMATION = '/swe/:language/5/death/claimant-information',
    DEATH_REPORTER_INFORMATION = '/swe/:language/5/death/reporter-information',
    DEATH_ESTATE_EXECUTORS = '/swe/:language/5/death/estate-executors',
    DEATH_BANK_ACCOUNT_INFORMATION = '/swe/:language/5/death/bank-account-information',
    DEATH_FINAL_SUMMARY = '/swe/:language/5/death/final-summary',
    DEATH_REPORT_COMPLETED = '/swe/:language/5/death/report-completed',
    DEATH_COMPANY_INFORMATION = '/swe/:language/5/death/company-information',
    DEATH_ASSOCIATION_INFORMATION = '/swe/:language/5/death/association-information',
    DEATH_SCHOOL_INFORMATION = '/swe/:language/5/death/school-information',

    // Illness paths
    ILLNESS_DIAGNOSIS_INFORMATION = '/swe/:language/5/illness/diagnosis-information',
    ILLNESS_PRIVACY = '/swe/:language/5/illness/privacy',
    ILLNESS_CLAIM_DATE = '/swe/:language/5/illness/claim-date',
    ILLNESS_DATE_OF_DIAGNOSIS = '/swe/:language/5/illness/date-of-diagnosis',
    ILLNESS_HOSPITALISATION_INFORMATION = '/swe/:language/5/illness/hospitalisation-information',
    ILLNESS_OTHER_INSURANCE_COMPANY = '/swe/:language/5/illness/other-insurance-company',
    ILLNESS_UPLOAD_ATTACHMENT = '/swe/:language/5/illness/upload-attachment',
    ILLNESS_SITUATION_SUMMARY = '/swe/:language/5/illness/situation-summary',
    ILLNESS_ASSOCIATION_INFORMATION = '/swe/:language/5/illness/association-information',
    ILLNESS_CLAIMANT_INFORMATION = '/swe/:language/5/illness/claimant-information',
    ILLNESS_REPORTER_INFORMATION = '/swe/:language/5/illness/reporter-information',
    ILLNESS_BANK_ACCOUNT_INFORMATION = '/swe/:language/5/illness/bank-account-information',
    ILLNESS_BANK_ACCOUNT_INFORMATION_CHILD = '/swe/:language/5/illness/bank-account-information-child',
    ILLNESS_FINAL_SUMMARY = '/swe/:language/5/illness/final-summary',
    ILLNESS_REPORT_COMPLETED = '/swe/:language/5/illness/report-completed',
}

export const personWizardRoutes: ObjectWithDynamicKey = {
    [PersonRoutePaths.INDEX]: {
        [CategoryKeys.ACCIDENT]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER_ROLE,
        [CategoryKeys.ILLNESS]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_CLAIM_REPORTER_ROLE,
        [CategoryKeys.DEATH]: PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIMANT_AGE_CATEGORY,
    },
    [PersonRoutePaths.START_SUMMARY]: {
        [FlowKeys.ACCIDENT]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_INSURANCE_TYPE,
        [FlowKeys.ILLNESS]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_INSURANCE_TYPE,
        [FlowKeys.DEATH]: PersonRoutePaths.DYN_CAROUSEL_DEATH_INSURANCE_TYPE,
    },

    // Accident
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER_ROLE]: PersonRoutePaths.START_SUMMARY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER]: PersonRoutePaths.START_SUMMARY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_INSURANCE_TYPE]: PersonRoutePaths.ACCIDENT_PRIVACY,
    [PersonRoutePaths.ACCIDENT_PRIVACY]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_LOCATION,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_LOCATION]: PersonRoutePaths.ACCIDENT_CLAIM_DATE,
    [PersonRoutePaths.ACCIDENT_CLAIM_DATE]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_TIME_OF_DAY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_TIME_OF_DAY]:
        PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_INVOLVED,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_INVOLVED]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_VEHICLE_INFORMATION,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY,
    },
    [PersonRoutePaths.ACCIDENT_VEHICLE_INFORMATION]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_MOTORCYCLE,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_MOTORCYCLE]:
        PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PERSON_IN_VEHICLE,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PERSON_IN_VEHICLE]: {
        [PersonInVehicleKeys.DRIVER]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_LICENSE,
        [PersonInVehicleKeys.PASSENGER]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY,
        [PersonInVehicleKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY,
    },
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_LICENSE]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_HELMET]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_DURING_SPORT,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_DURING_SPORT]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_TYPE_OF_SPORT,
        [YesNoKeys.NO]: PersonRoutePaths.ACCIDENT_TEETH_OR_BODY,
    },
    [PersonRoutePaths.ACCIDENT_TYPE_OF_SPORT]: PersonRoutePaths.ACCIDENT_TEETH_OR_BODY,
    [PersonRoutePaths.ACCIDENT_TEETH_OR_BODY]: {
        [TeethOrBodyKeys.BODY]: PersonRoutePaths.ACCIDENT_BODY_PARTS,
        [TeethOrBodyKeys.TEETH]: PersonRoutePaths.ACCIDENT_TEETH,
        [TeethOrBodyKeys.BOTH]: PersonRoutePaths.ACCIDENT_BODY_PARTS,
    },
    [PersonRoutePaths.ACCIDENT_BODY_PARTS]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE,
    [PersonRoutePaths.ACCIDENT_TEETH]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE]: {
        [PersonClaimCauseKeys.TEMPERATURE_INJURY]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
        [PersonClaimCauseKeys.CHEMICALS]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
        [PersonClaimCauseKeys.FIRE]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
        [PersonClaimCauseKeys.SHOCK]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
        [PersonClaimCauseKeys.VIOLENCE]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.TWIST]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.CUT]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.CRUSH_INJURY]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.CRASH_INJURY]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.LIFTING_INJURY]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.FALL]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.ANIMAL_BITE]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.QUARREL]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
        [PersonClaimCauseKeys.OTHER]: PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES,
    },
    [PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_PLASTER_TREATMENT]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_WEEKS_OF_PLASTER_TREATMENT,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
    },
    [PersonRoutePaths.ACCIDENT_WEEKS_OF_PLASTER_TREATMENT]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY]: PersonRoutePaths.ACCIDENT_DATE_OF_PREVIOUS_INJURY,
    [PersonRoutePaths.ACCIDENT_DATE_OF_PREVIOUS_INJURY]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_TYPE_OF_MEDICAL_INSTITUTION,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES,
    },
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_TYPE_OF_MEDICAL_INSTITUTION]: PersonRoutePaths.ACCIDENT_DATE_OF_TREATMENT,
    [PersonRoutePaths.ACCIDENT_DATE_OF_TREATMENT]: PersonRoutePaths.ACCIDENT_MEDICAL_INSTITUTION_INFORMATION,
    [PersonRoutePaths.ACCIDENT_MEDICAL_INSTITUTION_INFORMATION]:
        PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_FINISHED_TREATMENT,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_DENTIST_INFORMATION,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES,
    },
    [PersonRoutePaths.ACCIDENT_DENTIST_INFORMATION]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_FINISHED_TREATMENT,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_FINISHED_TREATMENT]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_INVALIDITY,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_INVALIDITY]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_NEED_TRANSPORT]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IN_ELEMENTARY_SCHOOL,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES,
    },
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IN_ELEMENTARY_SCHOOL]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_UPLOAD_MEDICAL_CERTIFICATE,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES,
    },
    [PersonRoutePaths.ACCIDENT_UPLOAD_MEDICAL_CERTIFICATE]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_UPLOAD_RECEIPT,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE,
    },
    [PersonRoutePaths.ACCIDENT_UPLOAD_RECEIPT]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_ATTACHMENT,
    },
    [PersonRoutePaths.ACCIDENT_OTHER_INSURANCE_COMPANY]: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_ATTACHMENT,
    [PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_ATTACHMENT]: {
        [YesNoKeys.YES]: PersonRoutePaths.ACCIDENT_UPLOAD_ATTACHMENT,
        [YesNoKeys.NO]: PersonRoutePaths.ACCIDENT_SITUATION_SUMMARY,
    },
    [PersonRoutePaths.ACCIDENT_UPLOAD_ATTACHMENT]: PersonRoutePaths.ACCIDENT_SITUATION_SUMMARY,
    [PersonRoutePaths.ACCIDENT_SITUATION_SUMMARY]: PersonRoutePaths.ACCIDENT_CLAIMANT_INFORMATION,
    [PersonRoutePaths.ACCIDENT_COMPANY_INFORMATION]: PersonRoutePaths.ACCIDENT_CLAIMANT_INFORMATION,
    [PersonRoutePaths.ACCIDENT_ASSOCIATION_INFORMATION]: PersonRoutePaths.ACCIDENT_CLAIMANT_INFORMATION,
    [PersonRoutePaths.ACCIDENT_SCHOOL_INFORMATION]: PersonRoutePaths.ACCIDENT_CLAIMANT_INFORMATION,
    [PersonRoutePaths.ACCIDENT_CLAIMANT_INFORMATION]: PersonRoutePaths.ACCIDENT_REPORTER_INFORMATION,
    [PersonRoutePaths.ACCIDENT_REPORTER_INFORMATION]: PersonRoutePaths.ACCIDENT_BANK_ACCOUNT_INFORMATION,
    [PersonRoutePaths.ACCIDENT_BANK_ACCOUNT_INFORMATION]: PersonRoutePaths.ACCIDENT_FINAL_SUMMARY,
    [PersonRoutePaths.ACCIDENT_BANK_ACCOUNT_INFORMATION_CHILD]: PersonRoutePaths.ACCIDENT_FINAL_SUMMARY,
    [PersonRoutePaths.ACCIDENT_FINAL_SUMMARY]: PersonRoutePaths.ACCIDENT_REPORT_COMPLETED,

    // Illness
    [PersonRoutePaths.DYN_CAROUSEL_ILLNESS_CLAIM_REPORTER_ROLE]: PersonRoutePaths.START_SUMMARY,
    [PersonRoutePaths.DYN_CAROUSEL_ILLNESS_INSURANCE_TYPE]: {
        [InsuranceTypeKeys.ILLNESS]: PersonRoutePaths.ILLNESS_PRIVACY,
        [InsuranceTypeKeys.DIAGNOSIS]: PersonRoutePaths.ILLNESS_DIAGNOSIS_INFORMATION,
    },
    [PersonRoutePaths.ILLNESS_DIAGNOSIS_INFORMATION]: PersonRoutePaths.ILLNESS_PRIVACY,
    [PersonRoutePaths.ILLNESS_PRIVACY]: PersonRoutePaths.ILLNESS_CLAIM_DATE,
    [PersonRoutePaths.ILLNESS_CLAIM_DATE]: PersonRoutePaths.ILLNESS_DATE_OF_DIAGNOSIS,
    [PersonRoutePaths.ILLNESS_DATE_OF_DIAGNOSIS]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HOSPITALISED,
    [PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HOSPITALISED]: {
        [YesNoKeys.YES]: PersonRoutePaths.ILLNESS_HOSPITALISATION_INFORMATION,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_PREVIOUS_ILLNESS,
    },
    [PersonRoutePaths.ILLNESS_HOSPITALISATION_INFORMATION]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_PREVIOUS_ILLNESS,
    [PersonRoutePaths.DYN_CAROUSEL_ILLNESS_PREVIOUS_ILLNESS]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HAS_OTHER_INSURANCE,
    [PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: PersonRoutePaths.ILLNESS_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HAS_ATTACHMENT,
    },
    [PersonRoutePaths.ILLNESS_OTHER_INSURANCE_COMPANY]: PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HAS_ATTACHMENT,
    [PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HAS_ATTACHMENT]: {
        [YesNoKeys.YES]: PersonRoutePaths.ILLNESS_UPLOAD_ATTACHMENT,
        [YesNoKeys.NO]: PersonRoutePaths.ILLNESS_SITUATION_SUMMARY,
    },
    [PersonRoutePaths.ILLNESS_UPLOAD_ATTACHMENT]: PersonRoutePaths.ILLNESS_SITUATION_SUMMARY,
    [PersonRoutePaths.ILLNESS_SITUATION_SUMMARY]: PersonRoutePaths.ILLNESS_ASSOCIATION_INFORMATION,
    [PersonRoutePaths.ILLNESS_ASSOCIATION_INFORMATION]: PersonRoutePaths.ILLNESS_CLAIMANT_INFORMATION,
    [PersonRoutePaths.ILLNESS_CLAIMANT_INFORMATION]: PersonRoutePaths.ILLNESS_REPORTER_INFORMATION,
    [PersonRoutePaths.ILLNESS_BANK_ACCOUNT_INFORMATION]: PersonRoutePaths.ILLNESS_FINAL_SUMMARY,
    [PersonRoutePaths.ILLNESS_BANK_ACCOUNT_INFORMATION_CHILD]: PersonRoutePaths.ILLNESS_FINAL_SUMMARY,
    [PersonRoutePaths.ILLNESS_REPORTER_INFORMATION]: PersonRoutePaths.ILLNESS_FINAL_SUMMARY,
    [PersonRoutePaths.ILLNESS_FINAL_SUMMARY]: PersonRoutePaths.ILLNESS_REPORT_COMPLETED,

    // Death
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIMANT_AGE_CATEGORY]:
        PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIM_REPORTER_ROLE,
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIM_REPORTER_ROLE]: PersonRoutePaths.START_SUMMARY,
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_INSURANCE_TYPE]: {
        [InsuranceTypeKeys.COMPANY]: PersonRoutePaths.DEATH_COMPANY_INFORMATION,
        [InsuranceTypeKeys.PRIVATE]: PersonRoutePaths.DEATH_ASSOCIATION_INFORMATION,
        [InsuranceTypeKeys.MUNICIPALITY_SCHOOL]: PersonRoutePaths.DEATH_SCHOOL_INFORMATION,
    },
    [PersonRoutePaths.DEATH_COMPANY_INFORMATION]: PersonRoutePaths.DEATH_PRIVACY,
    [PersonRoutePaths.DEATH_ASSOCIATION_INFORMATION]: PersonRoutePaths.DEATH_PRIVACY,
    [PersonRoutePaths.DEATH_SCHOOL_INFORMATION]: PersonRoutePaths.DEATH_PRIVACY,
    [PersonRoutePaths.DEATH_PRIVACY]: PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_ACCIDENT,
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_ACCIDENT]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIM_LOCATION,
        [YesNoKeys.NO]: PersonRoutePaths.DEATH_DATE_OF_DEATH,
    },
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIM_LOCATION]: PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_VEHICLE_INVOLVED,
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_VEHICLE_INVOLVED]: {
        [YesNoKeys.YES]: PersonRoutePaths.DEATH_VEHICLE_INFORMATION,
        [YesNoKeys.NO]: PersonRoutePaths.DEATH_DATE_OF_DEATH,
    },
    [PersonRoutePaths.DEATH_VEHICLE_INFORMATION]: PersonRoutePaths.DEATH_DATE_OF_DEATH,
    [PersonRoutePaths.DEATH_DATE_OF_DEATH]: PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIMANT_MARITAL_STATUS,
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIMANT_MARITAL_STATUS]: PersonRoutePaths.DEATH_UPLOAD_DEATH_CERTIFICATE,
    [PersonRoutePaths.DEATH_UPLOAD_DEATH_CERTIFICATE]: PersonRoutePaths.DEATH_SITUATION_SUMMARY,
    [PersonRoutePaths.DEATH_SITUATION_SUMMARY]: PersonRoutePaths.DEATH_CLAIMANT_INFORMATION,
    [PersonRoutePaths.DEATH_CLAIMANT_INFORMATION]: PersonRoutePaths.DEATH_REPORTER_INFORMATION,
    [PersonRoutePaths.DEATH_REPORTER_INFORMATION]: PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_ESTATE_EXECUTOR,
    [PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_ESTATE_EXECUTOR]: {
        [YesNoKeys.YES]: PersonRoutePaths.DEATH_BANK_ACCOUNT_INFORMATION,
        [YesNoKeys.NO]: PersonRoutePaths.DEATH_ESTATE_EXECUTORS,
    },
    [PersonRoutePaths.DEATH_ESTATE_EXECUTORS]: PersonRoutePaths.DEATH_BANK_ACCOUNT_INFORMATION,
    [PersonRoutePaths.DEATH_BANK_ACCOUNT_INFORMATION]: PersonRoutePaths.DEATH_FINAL_SUMMARY,
    [PersonRoutePaths.DEATH_FINAL_SUMMARY]: PersonRoutePaths.DEATH_REPORT_COMPLETED,
};
