import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    ClaimReporterRoleKeys,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { selectClaimReporterRole, selectReporterInformation } from '../../../sagas/selectors/personSelectors';
import { personActions } from '../../../sagas/person';
import { ReporterInformationModel } from '../../../models/person/ReporterInformation';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { deathAndIllnessReporterInformationSchema } from '../../../validations/schemas/deathAndIllnessReporterInformationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { EMAIL, FIRST_NAME, LAST_NAME, MISSING_SSN, NATIONAL_IDENTITY, PHONE } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    MISSING_SSN_LABEL,
    NATIONAL_IDENTITY_LABEL,
    NATIONAL_IDENTITY_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { ON_BEHALF_CHILD } = ClaimReporterRoleKeys;
const { ILLNESS_BANK_ACCOUNT_INFORMATION_CHILD } = PersonRoutePaths;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;

/**
 * Page view and page logic
 */
export const IllnessReporterInformationPage = () => {
    const dispatch = useDispatch();
    const reporterInformation = useSelector(selectReporterInformation);
    const whoReport = useSelector(selectClaimReporterRole);
    const customCAN = useSelector(selectCustomCAN);
    const checkMissingSSN = reporterInformation.missingSSN || false;
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.illness.reporterInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ReporterInformationModel>({
        resolver: yupResolver(deathAndIllnessReporterInformationSchema(t, checkMissingSSN)),
        defaultValues: {
            ...reporterInformation,
        },
    });

    const handleBackButton = useGoBack();
    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const onSubmit = (values: ReporterInformationModel) => {
        let goToLink = wizardActions.goToNext();
        if (whoReport === ON_BEHALF_CHILD) {
            goToLink = wizardActions.goTo(ILLNESS_BANK_ACCOUNT_INFORMATION_CHILD);
        }

        dispatcherWithPromise(dispatch, personActions.update, {
            reporterInformation: { ...reporterInformation, ...values },
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(goToLink));
    };

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked, id } = e.target;
        setValue('missingSSN', checked);
        if (checked) {
            setValue('nationalIdentity', null);
        }
        dispatch(wizardActions.skipBackToPrev(true));
        dispatcherWithPromise(dispatch, personActions.update, {
            reporterInformation: {
                ...reporterInformation,
                [id]: checked,
            },
        });
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <SingleCheckbox
                        name={MISSING_SSN}
                        checked={checkMissingSSN}
                        handleChange={handleChecked}
                        wrapperClass={'col-12 multiple'}
                        {...{ customCAN }}
                    >
                        {t(MISSING_SSN_LABEL)}
                    </SingleCheckbox>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={NATIONAL_IDENTITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.nationalIdentity}
                                errorMessage={errors.nationalIdentity?.message}
                                id={NATIONAL_IDENTITY}
                                inputFieldWrapper={'col-4'}
                                label={t(NATIONAL_IDENTITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(NATIONAL_IDENTITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
