export enum ClaimTypeEnums {
    ARCHITECT = 'ARCHITECT',
    BOARD_RESPONSIBILITY = 'BOARD_RESPONSIBILITY',
    BUILDER_LIABILITY = 'BUILDER_LIABILITY',
    CARRIER = 'CARRIER',
    CONDOMINIUM_SUPPLEMENT = 'CONDOMINIUM_SUPPLEMENT',
    CONTRACT = 'CONTRACT',
    CRIME = 'CRIME',
    CYBER = 'CYBER',
    DAMAGE = 'DAMAGE',
    DAMAGE_CLAIM = 'DAMAGE_CLAIM',
    EMPLOYERS_LIABILITY = 'EMPLOYERS_LIABILITY',
    ELECTRICITY = 'ELECTRICITY',
    ENGINE = 'ENGINE',
    ENVIRONMENTAL = 'ENVIRONMENTAL',
    EXTENSION = 'EXTENSION',
    FIDELITY_GUARANTEE = 'FIDELITY_GUARANTEE',
    FIRE = 'FIRE',
    FREIGHT = 'FREIGHT',
    FUNGI_ROT_INSECTS = 'FUNGI_ROT_INSECTS',
    GLASS = 'GLASS',
    GOODS = 'GOODS',
    IMPACT = 'IMPACT',
    INTERRUPTION = 'INTERRUPTION',
    LEGAL = 'LEGAL',
    LEGAL_DISPUTE = 'LEGAL_DISPUTE',
    LIABILITY = 'LIABILITY',
    LIBEL_SLANDER = 'LIBEL_SLANDER',
    MACHINE = 'MACHINE',
    MOVING = 'MOVING',
    MUNICIPAL_RESPONSIBILITY = 'MUNICIPAL_RESPONSIBILITY',
    NATURE = 'NATURE',
    NON_INJURY = 'NON_INJURY',
    OFFICIALS_INDEMNITY = 'OFFICIALS_INDEMNITY',
    OTHER = 'OTHER',
    OTHER_HULL_DAMAGE = 'OTHER_HULL_DAMAGE',
    PERSON = 'PERSON',
    PRODUCT_LIABILITY = 'PRODUCT_LIABILITY',
    PROFESSIONAL_INDEMNITY = 'PROFESSIONAL_INDEMNITY',
    PROPERTY = 'PROPERTY',
    PUBLIC_LIABILITY = 'PUBLIC_LIABILITY',
    REAL_ESTATE_LIABILITY = 'REAL_ESTATE_LIABILITY',
    STORM_FLOOD = 'STORM_FLOOD',
    SUBSIDIENCE = 'SUBSIDIENCE',
    THEFT = 'THEFT',
    TRANSPORT = 'TRANSPORT',
    WATER = 'WATER',
    WORKSHOP = 'WORKSHOP',
}
