import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { selectTypeOfAutoClaim } from '../../../sagas/selectors/motorSelectors';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import {
    Carousel,
    CarouselItem,
    FlowKeys,
    PageLayout,
    RecentlyInsuredIcon,
    TypeOfAutoClaimKeys,
    WindshieldIcon,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { flowActions } from '../../../sagas/flow';
import { goBack } from 'connected-react-router';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure models, phrases etc.
 */
const { STANDARD, WINDSHIELD } = TypeOfAutoClaimKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { START } = FlowKeys;

/**
 * Page view and page logic
 */
export const WelcomePage = () => {
    const dispatch = useDispatch();
    const typeOfAutoClaim = useSelector(selectTypeOfAutoClaim);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.start.welcome');

    const options = [
        {
            icon: <WindshieldIcon />,
            id: WINDSHIELD,
            name: tWithNS.t(`select.${WINDSHIELD}`),
        },
        { icon: <RecentlyInsuredIcon />, id: STANDARD, name: tWithNS.t(`select.${STANDARD}`) },
    ];

    const handleBackButton = () => {
        dispatcherWithPromise(dispatch, flowActions.update, null).then(() => dispatch(goBack()));
    };

    const selected = (id: TypeOfAutoClaimKeys, e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const option = options.find((x) => x.id === id);

        if (option) {
            dispatcherWithPromise(dispatch, motorActions.update, {
                typeOfAutoClaim: option.id,
            })
                .then(() => dispatcherWithPromise(dispatch, flowActions.update, START))
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext(option.id)));
        }
    };

    const renderSelection = options.map((option, idx) => (
        <CarouselItem
            {...option}
            handleClick={selected}
            isSelected={option.id === typeOfAutoClaim}
            key={idx}
            {...{ customCAN }}
        />
    ));

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <p className={'mb-10'} tabIndex={0}>
                {tWithNS.t('body.description')}
            </p>
            <Carousel>{renderSelection}</Carousel>
        </PageLayout>
    );
};
