export const MAX_LENGTH_250 = 250;
export const MAX_LENGTH_80 = 80;
export const MAX_LENGTH_CLAIM_DESCRIPTION = 100000;

export enum FormFieldNames {
    ACCIDENT_DESCRIPTION = 'accidentDescription',
    ACCIDENT_SKETCH = 'accidentSketch',
    ACCOUNT_NUMBER = 'accountNumber',
    ACCOUNT_OWNER_FAMILY_NAME = 'ownerFamilyName',
    ACCOUNT_OWNER_GIVEN_NAME = 'ownerGivenName',
    ASSOCIATION = 'association',
    BANK_ACCOUNT_TYPE = 'bankAccountType',
    BANK_NAME = 'bankName',
    BUSINESS_NUMBER = 'businessNumber',
    CHASSIS_NUMBER = 'chassisNumber',
    CLAIM_CAUSE = 'claimCause',
    CLAIM_DESCRIPTION = 'claimDescription',
    CLAIM_TYPE = 'claimType',
    CLEARING_NUMBER = 'clearingNumber',
    CITY = 'city',
    CLAIM_DATE = 'claimDate',
    CLAIM_TIME = 'claimTime',
    COST = 'cost',
    COUNTRY = 'country',
    DAMAGE_DESCRIPTION = 'damageDescription',
    DAMAGED_ITEM = 'damagedItem',
    DATE_OF_DIAGNOSIS = 'dateOfDiagnosis',
    DATE_OF_PREVIOUS_INJURY = 'dateOfPreviousInjury',
    DATE_OF_TREATMENT = 'dateOfTreatment',
    DENTIST_NAME = 'name',
    DIAGNOSIS = 'diagnosis',
    DISTANCE_FROM_ROAD_SIDE = 'distanceFromRoadSide',
    DITCH_CLAIM_DESCRIPTION = 'ditchClaimDescription',
    DRIVER_IS_UNKNOWN = 'isUnknown',
    DRIVER_MISSING_SSN = 'missingSSN',
    DRIVING_SPEED = 'drivingSpeed',
    EMAIL = 'email',
    END_DATE = 'endDate',
    ENGINE_CLAIM_DESCRIPTION = 'engineClaimDescription',
    EXTERNAL_REFERENCE = 'externalReference',
    FIRE_CLAIM_DESCRIPTION = 'fireClaimDescription',
    FIRST_NAME = 'firstName',
    FREIGHT_WEIGHT = 'freightWeight',
    HOSPITAL_NAME = 'hospitalName',
    IS_DRIVER = 'isDriver',
    IS_CLAIMANT = 'isClaimant',
    IS_SELLER = 'isSeller',
    IS_REPORTER = 'isReporter',
    IS_COMPANY = 'isCompany',
    LAST_NAME = 'lastName',
    LIABILITY_CLAIM_DESCRIPTION = 'liabilityClaimDescription',
    MAKE = 'make',
    MAKE_MODEL = 'type',
    MEDICAL_INSTITUTION = 'medicalInstitution',
    MILEAGE = 'mileage',
    MISSING_ITEMS = 'missingItems',
    MISSING_SSN = 'missingSSN',
    MODEL = 'model',
    MUNICIPALITY = 'municipality',
    NAME = 'name',
    NATIONAL_IDENTITY = 'nationalIdentity',
    NO_AGENT = 'noAgent',
    OTHER_ID = 'otherId',
    OTHER_INSURANCE_COMPANY = 'otherInsuranceCompany',
    OTHER_MISSING_ITEMS_CLAIM_DESCRIPTION = 'otherMissingItemsClaimDescription',
    PARKING_DATE = 'parkingDate',
    PARKING_TIME = 'parkingTime',
    PHONE = 'phone',
    POLICE_CASE_NUMBER = 'policeCaseNumber',
    POLICY_NUMBER = 'policyNumber',
    PROPERTY_ADDRESS = 'note',
    PROPERTY_UNIT = 'unit',
    REASON = 'reason',
    RECOVERY_DATE = 'recoveryDate',
    REPORTED_TO_SELLER_DATE = 'reportedToSellerDate',
    REVERSING_CLAIM_DESCRIPTION = 'reversingClaimDescription',
    REG_NR = 'registrationNumber',
    REPORTED_TO_INSURERS_INSURANCE = 'reportedToInsurersInsurance',
    ROAD_CONDITION_CLAIM_DESCRIPTION = 'roadConditionClaimDescription',
    ROAD_WIDTH = 'roadWidth',
    SELECT_ASSOCIATION = 'selectAssociation',
    SPEED_LIMIT = 'speedLimit',
    SPEED_ON_IMPACT = 'speedOnImpact',
    START_DATE = 'startDate',
    STREET = 'street',
    NOTE = 'note',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION = 'theftAndDamagesClaimDescription',
    TIME_SINCE_ACTION = 'timeSinceAction',
    TYPE = 'type',
    TYPE_OF_PROPERTY = 'typeOfProperty',
    TYPE_OF_SPORT = 'typeOfSport',
    VEHICLE_TYPE = 'vehicleType',
    VEHICLE_INTERNAL_REFERENCE_NUMBER = 'vehicleInternalReferenceNumber',
    WEEKS_OF_PLASTER_TREATMENT = 'weeksOfPlasterTreatment',
    WHO_RESPONSIBLE_CLAIM_DESCRIPTION = 'whoResponsibleClaimDescription',
    ZIP = 'zip',
}

export enum FormFieldErrors {
    DUPLICATE_ESTATE_EXECUTORS = 'form.validation.string.estateExecutors.duplicates',
    EMAIL_ERROR_MESSAGE = 'form.validation.string.email',
    FIELD_ERROR = 'form.validation.string.fieldError',
    IF_REQUIRED_ERROR_MESSAGE = 'form.validation.string.ifRequired',
    INVALID_REGISTRATION_NUMBER = 'form.validation.string.invalidRegistrationNumberFormat',
    MANDATORY_DATE_FIELD = 'form.validation.string.mandatoryDateField',
    MANDATORY_TIME_FIELD = 'form.validation.string.mandatoryTimeField',
    MAX_LENGTH_ERROR_MESSAGE = 'form.validation.string.maxLength',
    MIN_LENGTH_ERROR_MESSAGE = 'form.validation.string.minLength',
    MISSING_ITEMS_REQUIRED = 'form.validation.string.missingItems',
    NO_JWT_RECEIVED = 'form.validation.string.noJWTReceived',
    NO_STORED_CLAIM = 'form.validation.string.noStoredClaim',
    NUMBER_ERROR_MESSAGE = 'form.validation.string.numberError',
    NUMBER_OF_DIGITS_ERROR = 'form.validation.number.numberOfDigits',
    PERSON_EXISTS = 'form.validation.string.personExists',
    REGISTRATION_NUMBER_EXISTS = 'form.validation.string.registrationNumberExists',
    REQUIRED_ERROR_MESSAGE = 'form.validation.string.required',
    REQUIRED_LOCATION = 'form.validation.string.requiredLocation',
    TYPE_ERROR = 'form.validation.number.typeError',
    VEHICLE_NOT_FOUND = 'form.validation.string.vehicleNotFound',
    DUPLICATE_OWNERS = 'form.validation.string.duplicateOwners',
    NO_NEGATIVE_NUMBERS = 'form.validation.number.noNegativeNumbers',
}
