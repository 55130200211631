import { TFunction } from 'i18next';
import { BodyModel, SVGClickable } from '@protectorinsurance/ds-can';

export interface SVGImageProps {
    onClick: (e: SVGClickable) => void;
    addDamagePoint: (d: DamagePoint) => void;
    removeDamagePoint: (e: SVGClickable, point: DamagePoint) => void;
    selectedDamagePoints: DamagePoint[];
}
export interface Point {
    x: number;
    y: number;
}
export interface SVGImage {
    svgId: string;
}
export type SVGImagePoint = Point & SVGImage;

export interface DamagePoint extends Point, SVGImage {
    vehicleBodyPart: BodyModel;
}

export interface SVGImageTitles {
    right: string;
    left: string;
    top: string;
}

export interface MainImageSVGProps
    extends Pick<SVGImageProps, 'addDamagePoint' | 'removeDamagePoint' | 'selectedDamagePoints'> {
    titles: SVGImageTitles;
    id?: string;
}
export interface SvgImg
    extends Pick<MainImageSVGProps, 'removeDamagePoint' | 'selectedDamagePoints' | 'id' | 'addDamagePoint'> {
    title: string;
}

export enum SVGImageView {
    TOP = 'TOP',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export const createSvgTabs = (t: TFunction) => {
    return [
        { id: SVGImageView.TOP, label: t('tabs.tab.label.top') },
        { id: SVGImageView.LEFT, label: t('tabs.tab.label.left') },
        { id: SVGImageView.RIGHT, label: t('tabs.tab.label.right') },
    ];
};
