import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { validateAccountNumberPGSE } from '@protectorinsurance/ds-can';

export default function plusgiroAccountNumber(
    this: StringSchema,
    required: boolean,
    key: string,
    message: string = yupCustomLocale.string.plusgiroNumber
) {
    // @ts-ignore
    return this.test('plusgiroAccountNumber', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        return validateAccountNumberPGSE(value).isValid();
    });
}
