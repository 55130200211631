import React from 'react';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import { selectClaimReporterRole, selectHospitalisationInformation } from '../../../sagas/selectors/personSelectors';
import { FormProvider, useForm } from 'react-hook-form';
import { HospitalisationInformationModel } from 'models/person/HospitalisationInformation';
import { useGoBack } from '../../../hooks/useGoBack';
import dispatcherWithPromise from 'utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { personActions } from '../../../sagas/person';
import { ClaimReporterRoleKeys, Nullable, PageLayout } from '@protectorinsurance/ds-can';
import { hospitalisationInformationSchema } from 'validations/schemas/hospitalisationInformationSchema';
import { isOverlappingPeriod } from '../../../utils/date/periodUtils';
import { removeHospitalisation } from '../../../utils/hospitalisationUtils';
import { HospitalisationInformationForm } from '../../../components/hospitalisation/HospitalisationInformationForm';
import { HospitalisationInformationList } from '../../../components/hospitalisation/HospitalisationInformationList';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

/**
 * Destructure necessary imports
 */
const { MYSELF } = ClaimReporterRoleKeys;
const { START_DATE } = FormFieldNames;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, OVERLAPPING_HOSPITALISATION_PERIODS, PAGE_NAME, SUB_TITLE, TITLE } =
    PhraseKeys;

export interface HospitalisationFormModel extends Omit<HospitalisationInformationModel, 'endDate' | 'startDate'> {
    endDate: Nullable<Date>;
    startDate: Nullable<Date>;
}

/**
 * Page View and Page Logic
 */
export const IllnessHospitalisationInformation = () => {
    const dispatch = useDispatch();
    const hospitalisationInformation = useSelector(selectHospitalisationInformation);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.illness.hospitalisationInformation');
    const schema = hospitalisationInformationSchema(t);
    const form = useForm<HospitalisationFormModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            endDate: null,
            hospitalName: '',
            startDate: null,
        },
    });
    const whoReport = useSelector(selectClaimReporterRole);
    const reporterPrefix = whoReport ? whoReport : MYSELF;

    const handleBackButton = useGoBack();

    const isFormEmpty = (values: HospitalisationFormModel) => {
        const { startDate, endDate, hospitalName } = values;
        return !startDate && !endDate && !hospitalName;
    };

    const handleContinueButton = () => {
        const values = form.getValues();

        if (!isFormEmpty(values)) {
            schema.isValid(values).then((valid) => {
                if (valid) {
                    handleDispatch(values, true);
                } else {
                    form.trigger();
                }
            });
        } else if (isFormEmpty(values) && hospitalisationInformation.length === 0) {
            form.trigger();

            // Send to server and go to next
        } else {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleDispatch = (hospitalisation: HospitalisationFormModel, isNext = false) => {
        if (isOverlapping(hospitalisation)) return;
        const hospitalisationPeriod: HospitalisationInformationModel = {
            endDate: hospitalisation.endDate ? moment(hospitalisation.endDate) : null,
            hospitalName: hospitalisation.hospitalName,
            startDate: hospitalisation.startDate ? moment(hospitalisation.startDate) : null,
        };
        dispatcherWithPromise(dispatch, personActions.update, {
            hospitalisationInformation: [...hospitalisationInformation, hospitalisationPeriod],
        })
            .then(() => isNext && dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => {
                form.reset({
                    startDate: null,
                    endDate: null,
                    hospitalName: null,
                });

                if (isNext) {
                    dispatch(wizardActions.goToNext());
                }
            });
    };

    const handleOnSubmit = (hospitalisation: HospitalisationFormModel) => {
        handleDispatch(hospitalisation);
    };

    const isOverlapping = (hospitalisation: HospitalisationFormModel): boolean => {
        const overlappingPeriods = isOverlappingPeriod(hospitalisation, hospitalisationInformation);

        if (overlappingPeriods) {
            form.setError(START_DATE, {
                type: 'OverlappingPeriods',
                message: t(OVERLAPPING_HOSPITALISATION_PERIODS),
            });
        }

        return overlappingPeriods;
    };

    const handleDelete = (e: React.MouseEvent, hospitalisation: HospitalisationInformationModel) => {
        e.preventDefault();
        const updatedHospitalisations = removeHospitalisation(hospitalisation, hospitalisationInformation);
        dispatch(personActions.update({ hospitalisationInformation: updatedHospitalisations }));
    };

    return (
        <FormProvider {...form}>
            <PageLayout
                backBtnText={t(BACK_BUTTON)}
                continueBtnText={t(CONTINUE_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerText={tWithNS.t(HELP_TEXT)}
                headerSubTitle={tWithNS.t(`${reporterPrefix}.${SUB_TITLE}`)}
                headerTitle={tWithNS.t(`${reporterPrefix}.${TITLE}`)}
                {...{ handleBackButton, handleContinueButton }}
            >
                <HospitalisationInformationForm onSubmit={handleOnSubmit} />

                <HospitalisationInformationList
                    atText={': '}
                    buttonText={t('button.delete')}
                    fromText={tWithNS.t('hospitalisationInformationList.fromText')}
                    handleDelete={handleDelete}
                    hospitalisation={hospitalisationInformation}
                    toText={tWithNS.t('hospitalisationInformationList.toText')}
                />
            </PageLayout>
        </FormProvider>
    );
};
