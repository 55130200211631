import { VehicleModel } from '../../interfaces';
import { initVehicleModel } from '../../sagas';
import { mapInsuredByProtector } from '../mapInsuredByProtector';
import { vehicleTypeToEnum } from './vehicleTypeToEnum';

export const spreadNewVehicle = (vehicle: Partial<VehicleModel>) => {
    const v = { ...initVehicleModel, ...vehicle };

    return {
        ...v,
        vehicleType: vehicleTypeToEnum(v),
        insuredByProtector: mapInsuredByProtector(v.insuredByProtector),
    };
};
