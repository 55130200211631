import { css } from '@emotion/react';
import { defaultFocusStyle, primary } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const startSummaryCss = (company?: customCANProps) =>
    css({
        color: primary,
        textAlign: 'start',
        '.title, .subTitle': {
            fontFamily: 'BrandonTextBold, sans-serif',
            fontSize: '1.125rem',
            marginBottom: '2rem',

            '&:focus': {
                ...defaultFocusStyle(company),
            },
        },

        '.subTitle': {
            marginBottom: '1rem',
        },
        '.avgMin': {
            fontSize: '1.75rem',
            marginBottom: '2rem',

            '&:focus': {
                ...defaultFocusStyle(company),
            },
        },
        p: {
            marginBottom: '1rem',
            marginTop: 0,

            '&:focus': {
                ...defaultFocusStyle(company),
            },
        },
    });
