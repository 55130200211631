import { css } from '@emotion/react';
import { gray200, secondary, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const progressBarCss = (company?: customCANProps) =>
    css({
        backgroundColor: gray200,
        borderRadius: '2px',
        display: 'flex',
        height: '3px',
        overflow: 'hidden',

        '& .progress-bar': {
            backgroundColor: company ? company.color : secondary,
            color: white,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            textAlign: 'center',
            transition: 'width 0.6s ease',
            whiteSpace: 'nowrap',
        },
    });
