/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactElement } from 'react';
import { inputAppendCss } from './InputAppend.style';

interface InputAppendProps {
    append: string | ReactElement;
}
const InputAppend: React.FC<InputAppendProps> = ({
    append,
}: InputAppendProps) => {
    return (
        <div css={css(inputAppendCss)}>
            <span id="basic-addon">{append}</span>
        </div>
    );
};
export default InputAppend;
