import { businessNumberUKMatchPattern } from './businessNumberUKMatchPattern';

export function businessNumberUKValidator(businessNumber: string) {
    return {
        isValid: function isValid() {
            if (!businessNumber || businessNumber.length === 0) {
                return false;
            }

            if (businessNumber.length !== 8 && businessNumber.length !== 9) {
                return false;
            }

            let hasNonZero: boolean = false;
            const array = [...businessNumber];

            array.forEach((digit: string) => {
                const isDigit = parseInt(digit);
                if (!isNaN(isDigit) && isDigit !== 0) {
                    hasNonZero = true;
                }
            });

            return hasNonZero && businessNumberUKMatchPattern(businessNumber);
        },
    };
}
