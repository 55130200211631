export enum TEST_ID_PREFIX {
    addBankAccountType = 'bankAccountType',
    acceptedPoliceContact = 'acceptedPoliceContact',
    acceptedPrivacy = 'accepted-privacy',
    acceptedPrivacyCheck = 'acceptedPrivacy',
    accidentLocation = 'accidentLocation',
    accountNumber = 'accountNumber',
    agentInformation = 'agentInformation',
    association = 'association',
    bankName = 'bankName',
    bodyParts = 'bodyParts',
    businessNumber = 'businessNumber',
    category = 'category',
    chassisNumber = 'chassisNumber',
    city = 'city',
    claimantAgeCategory = 'claimantAgeCategory',
    claimantInformation = 'claimantInformation',
    claimantMaritalStatus = 'claimantMaritalStatus',
    claimCause = 'claimCause',
    claimCauseDropdown = 'label-claimCause',
    claimCauses = 'claimCause-',
    claimCauseConsequences = 'claimCauseConsequences',
    claimCauseDetails = 'claimCauseDetails',
    claimDate = 'claim-date',
    claimDescription = 'claimDescription',
    claimDiscovererInformation = 'claimDiscovererInformation',
    claimLocationType = 'claimLocationType',
    claimNumber = 'claimNumber',
    claimReporter = 'claimReporter',
    claimReporterRole = 'claimReporterRole',
    claimTimeOfDay = 'claimTimeOfDay',
    claimType = 'claimType',
    claimantRoundaboutPositioning = 'claimantRoundaboutPositioning',
    clearingNumber = 'clearingNumber',
    collisionSituation = 'collisionSituation',
    companyInformation = 'companyInformation',
    companyName = 'name',
    contractorInformation = 'contractorInformation',
    cost = 'cost',
    counterpartyCompanyName = 'companyName',
    counterpartyInformation = 'counterpartyInformation',
    counterpartyRoundaboutPositioning = 'counterpartyRoundaboutPositioning',
    country = 'country',
    damagedItem = 'damagedItem',
    dateOfDiagnosis = 'dateOfDiagnosis',
    dateOfPreviousInjury = 'dateOfPreviousInjury',
    dateOfTreatment = 'dateOfTreatment',
    dentist = 'name',
    diagnosis = 'diagnosis',
    ditchClaimDescription = 'ditchClaimDescription',
    distanceFromRoadSide = 'distanceFromRoadSide',
    driverInformation = 'driverInformation',
    drivingSpeed = 'drivingSpeed',
    duringSport = 'duringSport',
    editButton = 'editButton',
    email = 'email',
    endDate = 'endDate',
    engineClaimDescription = 'engineClaimDescription',
    estateExecutors = 'estateExecutors',
    externalReference = 'externalReference',
    finishedTreatment = 'finishedTreatment',
    fireClaimDescription = 'fireClaimDescription',
    firstName = 'firstName',
    freightWeight = 'freightWeight',
    happenDomestically = 'happenDomestically',
    hasActiveOpposition = 'hasActiveOpposition',
    hasAllKeys = 'hasAllKeys',
    hasAnimalEscaped = 'hasAnimalEscaped',
    hasAttachments = 'has-attachments',
    hasClaim = 'hasClaim',
    hasContractWithOtherPart = 'hasContractWithOtherPart',
    hasDemands = 'hasDemands',
    hasDigitalServiceBook = 'hasDigitalServiceBook',
    hasExpenses = 'has-expenses',
    hasHelmet = 'hasHelmet',
    hasLicense = 'hasLicense',
    hasNonVehicleDamages = 'hasNonVehicleDamages',
    hasOtherExpenses = 'hasOtherExpenses',
    hasOtherInsurance = 'hasOtherInsurance',
    hasPersonInjuries = 'hasPersonInjuries',
    hasPlasterTreatment = 'hasPlasterTreatment',
    hasVAT = 'hasVAT',
    hasVehicleDamages = 'hasVehicleDamages',
    hasWitnesses = 'hasWitnesses',
    hospitalised = 'hospitalised',
    hospitalisationInformation = 'hospitalisationInformation-',
    hospitalName = 'hospitalName',
    inElementarySchool = 'inElementarySchool',
    injuredPersonInformationList = 'injuredPersonInformationList',
    policyNumber = 'policyNumber',
    insuranceType = 'insurance-type',
    insuredByProtector = 'insuredByProtector',
    invalidity = 'invalidity',
    isAccident = 'isAccident',
    isCompany = 'isCompany-checkbox-input',
    isContractorContacted = 'isContractorContacted',
    isCounterpartyKnown = 'isCounterpartyKnown',
    isCounterpartyStationary = 'isCounterpartyStationary',
    isDentistContacted = 'isDentistContacted',
    isDoctorContacted = 'isDoctorContacted',
    isDriver = 'isDriver-checkbox-input',
    isClaimant = 'isClaimant-checkbox-input',
    isSeller = 'isSeller-checkbox-input',
    isEstateExecutor = 'isEstateExecutor',
    isItemMissing = 'isItemMissing',
    isLaneChange = 'isLaneChange',
    isReporter = 'isReporter-checkbox-input',
    isReversing = 'isReversing',
    isDriving = 'isDriving',
    isOtherVehicleInvolved = 'isOtherVehicleInvolved',
    isPoliceContacted = 'isPoliceContacted',
    isSelfDiscoveredClaim = 'isSelfDiscoveredClaim',
    isVehicleInvolved = 'isVehicleInvolved',
    isVehicleMotorcycle = 'isVehicleMotorcycle',
    isVehicleRecovered = 'isVehicleRecovered',
    isVehicleStolen = 'isVehicleStolen',
    isUnknown = 'isUnknown',
    landlordInformation = 'landlordInformation',
    lastName = 'lastName',
    liabilityClaimDescription = 'liabilityClaimDescription',
    liabilityClaimDescriptionMeasures = 'liabilityClaimDescriptionMeasures',
    lob = 'lob',
    location = 'location',
    make = 'make',
    medicalInstitution = 'medicalInstitution',
    mileage = 'mileage',
    missingItems = 'missingItems',
    missingSSN = 'missingSSN',
    model = 'model',
    municipality = 'municipality',
    vehicleMileage = 'vehicleMileage',
    name = 'name',
    nationalIdentity = 'nationalIdentity',
    needTransport = 'needTransport',
    needVehicleTowing = 'needVehicleTowing',
    noAgent = 'noAgent-checkbox-input',
    otherId = 'otherId',
    otherInsurance = 'otherInsurance',
    otherInsuranceCompany = 'otherInsuranceCompany',
    otherMissingItemsClaimDescription = 'otherMissingItemsClaimDescription',
    ownerGivenName = 'ownerGivenName',
    ownerFamilyName = 'ownerFamilyName',
    ownerInformationList = 'ownerInformationList',
    parkedVehicleInformation = 'parkedVehicleInformation',
    parkingDate = 'parkingDate',
    personInVehicle = 'personInVehicle',
    phone = 'phone',
    policeCaseNumber = 'policeCaseNumber',
    policyHolder = 'policyHolder',
    policyHoldersContact = 'policyHoldersContact',
    previousIllness = 'previousIllness',
    previousInjury = 'previousInjury',
    propertyAddress = 'propertyAddress',
    propertyUnit = 'unit',
    reason = 'reason',
    recoveryDate = 'recoveryDate',
    registrationNumber = 'registrationNumber',
    reporterInformation = 'reporterInformation',
    reportedToInsurersInsurance = 'reportedToInsurersInsurance',
    reportedToSellerDate = 'reportedToSellerDate',
    reversingClaimDescription = 'reversingClaimDescription',
    roadConditionClaimDescription = 'roadConditionClaimDescription',
    roadWidth = 'roadWidth',
    role = 'role',
    salary = 'salary',
    schoolName = 'schoolName',
    selectAssociation = 'selectAssociation',
    sellerInformation = 'sellerInformation',
    speedLimit = 'speedLimit',
    speedOnImpact = 'speedOnImpact',
    startDate = 'startDate',
    theftAndDamagesClaimDescription = 'theftAndDamagesClaimDescription',
    street = 'street',
    teeth = 'teeth',
    teethOrBody = 'teethOrBody',
    timeSinceAction = 'timeSinceAction',
    type = 'type',
    typeOfAnimal = 'typeOfAnimal',
    typeOfIntersection = 'typeOfIntersection',
    typeOfMedicalInstitution = 'typeOfMedicalInstitution',
    typeOfProperty = 'typeOfProperty',
    typeOfRoad = 'typeOfRoad',
    typeOfSport = 'typeOfSport',
    unidentifiedType = 'unidentifiedType',
    uploadAttachments = 'upload-attachments-',
    uploadCertificate = 'uploadCertificate-',
    uploadPoliceReport = 'upload-police-report-',
    uploadReceipt = 'upload-receipt-',
    recovererInformation = 'recovererInformation',
    whoReport = 'who-report',
    vehicleInformation = 'vehicleInformation',
    vehicleList = 'vehicleList-',
    vehicleType = 'vehicleType',
    vehicleInternalReferenceNumber = 'vehicleInternalReferenceNumber',
    weeksOfPlasterTreatment = 'weeksOfPlasterTreatment',
    whoAtFault = 'whoAtFault',
    whoResponsibleClaimDescription = 'whoResponsibleClaimDescription',
    witnesses = 'witnesses',
    zip = 'zip',
}
