import { css } from '@emotion/react';
import { secondary } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const summaryItemTextCss = (company?: customCANProps) =>
    css({
        fontWeight: 700,
        textAlign: 'left',
        wordBreak: 'break-word',

        '&.margin-bottom': {
            marginBottom: '1rem',
        },

        '& button': {
            background: 'transparent',
            border: 'none',
            marginRight: '0.5rem',
            marginTop: '-0.15rem',
            maxHeight: '30px',
            padding: 0,

            '&:focus': {
                outline: '0',
            },

            '&:hover': {
                outline: '0',

                '& svg': {
                    '& path': {
                        stroke: company ? company.color : secondary,
                    },
                },
            },

            '& svg': {
                height: 35,
                width: 35,
            },
        },
    });
