export enum LeakCauseEnums {
    BATH = 'BATH',
    BLOCKED_DRAINS = 'BLOCKED_DRAINS',
    BOILER = 'BOILER',
    CARELESSNESS = 'CARELESSNESS',
    PIPE = 'PIPE',
    SEAL = 'SEAL',
    SHOWER = 'SHOWER',
    TOILET = 'TOILET',
    TP_CONTRACTOR = 'TP_CONTRACTOR',
    UNKNOWN = 'UNKNOWN',
    WASHING_MACHINE = 'WASHING_MACHINE',
    WATER_TANK = 'WATER_TANK',
}
