import { Nullable } from '../../../../../interfaces';

export enum ClaimReporterRoleKeys {
    BROKER = 'BROKER',
    CONDOMINIUM_HOLDERS = 'CONDOMINIUM_HOLDERS',
    INJURED = 'INJURED',
    MYSELF = 'SELF',
    ON_BEHALF = 'ON_BEHALF_EMPLOYEE',
    ON_BEHALF_CHILD = 'ON_BEHALF_CHILD',
    OTHER = 'OTHER',
    PARENT = 'PARENT',
    PARTNER = 'PARTNER',
    POLICY_HOLDER = 'POLICY_HOLDER',
    PROPERTY_MANAGER = 'PROPERTY_MANAGER',
    TENANT = 'TENANT',
}

export type ClaimReporterRoleTypeModel = Nullable<ClaimReporterRoleKeys>;
