import { LpoRoutePaths } from './lpoWizardRoutes';
import { FlowKeys, getLobFromUrl, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { strReplace } from '../../utils/strings/strReplace';

const lpoProgressBar = (path: LpoRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const lob = getLobFromUrl();
    const find = [':language', ':lob'];
    const replace = [language ? language : '', lob.lob ? lob.lob.toString() : ''];
    const url = strReplace(path, find, replace);
    return url;
};

export const lpoProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.START]: [
        lpoProgressBar(LpoRoutePaths.START_ONBOARDING),
        lpoProgressBar(LpoRoutePaths.START_PRIVACY),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_TYPE),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_CAUSE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_DATE),
    ],

    [FlowKeys.PROPERTY]: [
        lpoProgressBar(LpoRoutePaths.PROPERTY_POLICY_NUMBER),
        lpoProgressBar(LpoRoutePaths.PROPERTY_TYPE_OF_PROPERTY),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_CONTRACTOR_CONTACTED),
        lpoProgressBar(LpoRoutePaths.PROPERTY_CONTRACTOR_INFORMATION),
    ],

    [FlowKeys.LIABILITY]: [lpoProgressBar(LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION)],

    [FlowKeys.LEGAL]: [
        lpoProgressBar(LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DATE),
        lpoProgressBar(LpoRoutePaths.LEGAL_EXPENSES_AGENT_INFORMATION),
        lpoProgressBar(LpoRoutePaths.LEGAL_EXPENSES_POLICY_NUMBER),
        lpoProgressBar(LpoRoutePaths.LEGAL_EXPENSES_POLICY_HOLDER),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LEGAL_EXPENSES_HAS_CLAIM),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LEGAL_EXPENSES_HAS_ACTIVE_OPPOSITION),
        lpoProgressBar(LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.LEGAL_EXPENSES_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.LEGAL_EXPENSES_AGENT_REQUIRED),
        lpoProgressBar(LpoRoutePaths.END_FINAL_SUMMARY),
        lpoProgressBar(LpoRoutePaths.END_REPORT_COMPLETED),
    ],

    [FlowKeys.CHANGE_OF_OWNERSHIP]: [
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_SELLER_INFORMATION),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_POLICY_NUMBER),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_ACCIDENT_LOCATION),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_PROPERTY_ADDRESS),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DATE),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTED_TO_SELLER_DATE),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_COST),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTER_INFORMATION),
    ],

    [FlowKeys.END]: [
        lpoProgressBar(LpoRoutePaths.END_ACCIDENT_LOCATION),
        lpoProgressBar(LpoRoutePaths.END_PROPERTY_ADDRESS),
        lpoProgressBar(LpoRoutePaths.END_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_END_HAS_CONTRACT_WITH_OTHER_PART),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_END_HAS_DEMANDS),
        lpoProgressBar(LpoRoutePaths.LIABILITY_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.END_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE),
        lpoProgressBar(LpoRoutePaths.END_OTHER_INSURANCE_COMPANY),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_END_HAS_VAT),
        lpoProgressBar(LpoRoutePaths.END_REPORTER_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDERS_CONTACT),
        lpoProgressBar(LpoRoutePaths.END_FINAL_SUMMARY),
        lpoProgressBar(LpoRoutePaths.END_REPORT_COMPLETED),
    ],
};
