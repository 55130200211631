import { ssnSwedenMatchPattern } from './ssnSwedenMatchPattern';
import { ssnSwedenValidateChecksum } from './ssnSwedenValidateChecksum';
import { ssnSwedenHasValidDate } from './ssnSwedenHasValidDate';

export function ssnSwedenValidator(ssn: string) {
    return {
        isValid: function isValid() {
            if (!ssnSwedenMatchPattern(ssn)) {
                return false;
            }

            if (!ssnSwedenValidateChecksum(ssn)) {
                return false;
            }

            const thirdCharacter = parseInt(ssn.substring(2, 3));
            if (thirdCharacter >= 2) {
                return false;
            }

            return ssnSwedenHasValidDate(ssn);
        },
    };
}
