import React from 'react';
import { is, LocationModel, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderUnit = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    accidentLocation: LocationModel
) => {
    return (
        <>
            {!is(accidentLocation.unit, null) && (
                <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />
            )}
        </>
    );
};
