import { findBankByClearingNumber } from './findBankByClearingNumber';

/**
 * Main function
 */
export const bankClearingNumberUtils = {
    isValid: (clearingNumber: string) => {
        if (!clearingNumber) {
            return false;
        }

        return !!findBankByClearingNumber(clearingNumber);
    },
};
