import { SvgEnums } from '../../interfaces/enums/Svg.enums';

export const svgSetXmlNSAttrIfNotExists = (
    el: SVGSVGElement
): SVGSVGElement => {
    if (!el.hasAttribute('xmlns')) {
        el.setAttribute('xmlns', SvgEnums.NS);
    }

    return el;
};
