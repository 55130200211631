export enum ProtectorDomainEnums {
    DK = 'protectorforsikring.dk',
    FI = 'protectorvakuutus.fi',
    NO = 'protectorforsikring.no',
    SE = 'protectorforsakring.se',
    UK = 'protectorinsurance.co.uk',
}

export const ProtectorDomainArray = [
    ProtectorDomainEnums.DK,
    ProtectorDomainEnums.FI,
    ProtectorDomainEnums.NO,
    ProtectorDomainEnums.SE,
    ProtectorDomainEnums.UK,
];
