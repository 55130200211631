import React from 'react';
import { MCImage } from './MC/MCImage';
import { LightTruckImage } from './lightTruck/LightTruckImage';
import { CarImage } from './car/CarImage';
import { HeavyTruckImage } from './heavyTruck/HeavyTruckImage';
import { BusImage } from './bus/BusImage';
import { TFunction } from 'i18next';
import { Nullable, SVGImageProps, VehicleTypeKeys } from '@protectorinsurance/ds-can';
import { HelpToolTip } from '../toolTip/HelpToolTip';
import { useI18n } from '../../hooks/useI18n';

export interface VehicleBodyDisplayProps
    extends Pick<SVGImageProps, 'addDamagePoint' | 'removeDamagePoint' | 'selectedDamagePoints'> {
    vehicleType: Nullable<VehicleTypeKeys>;
    tWithNs: TFunction;
}

export const VehicleBodyDamages = (props: VehicleBodyDisplayProps) => {
    const { t } = useI18n();
    return (
        <div className={'mt-5 relative'}>
            <HelpToolTip title={t('motor.end.bodyDamages.toolTip.navigationInformation')} />
            {getVehicleBodySVG(props)}
        </div>
    );
};
export const getVehicleBodySVG = ({
    addDamagePoint,
    removeDamagePoint,
    selectedDamagePoints,
    vehicleType,
    tWithNs,
}: VehicleBodyDisplayProps) => {
    switch (vehicleType) {
        case VehicleTypeKeys.CAR:
        case VehicleTypeKeys.PRIVATE_CAR:
            return (
                <CarImage
                    addDamagePoint={addDamagePoint}
                    selectedDamagePoints={selectedDamagePoints}
                    removeDamagePoint={removeDamagePoint}
                    titles={{
                        top: tWithNs('image.car.title.top'),
                        left: tWithNs('image.car.title.left'),
                        right: tWithNs('image.car.title.right'),
                    }}
                />
            );
        case VehicleTypeKeys.MOPED:
        case VehicleTypeKeys.MOTORCYCLE:
            return (
                <MCImage
                    addDamagePoint={addDamagePoint}
                    selectedDamagePoints={selectedDamagePoints}
                    removeDamagePoint={removeDamagePoint}
                    titles={{
                        top: tWithNs('image.mc.title.top'),
                        left: tWithNs('image.mc.title.left'),
                        right: tWithNs('image.mc.title.right'),
                    }}
                />
            );
        case VehicleTypeKeys.LIGHT_TRUCK:
        case VehicleTypeKeys.LIGHT_COMMERCIAL_VEHICLE:
            return (
                <LightTruckImage
                    addDamagePoint={addDamagePoint}
                    selectedDamagePoints={selectedDamagePoints}
                    removeDamagePoint={removeDamagePoint}
                    titles={{
                        top: tWithNs('image.lightTruck.title.top'),
                        left: tWithNs('image.lightTruck.title.left'),
                        right: tWithNs('image.lightTruck.title.right'),
                    }}
                />
            );
        case VehicleTypeKeys.TRUCK:
        case VehicleTypeKeys.COMMERCIAL_VEHICLE:
            return (
                <HeavyTruckImage
                    addDamagePoint={addDamagePoint}
                    selectedDamagePoints={selectedDamagePoints}
                    removeDamagePoint={removeDamagePoint}
                    titles={{
                        top: tWithNs('image.heavyTruck.title.top'),
                        left: tWithNs('image.heavyTruck.title.left'),
                        right: tWithNs('image.heavyTrick.title.right'),
                    }}
                />
            );
        case VehicleTypeKeys.BUS:
        case VehicleTypeKeys.COACHES:
            return (
                <BusImage
                    addDamagePoint={addDamagePoint}
                    selectedDamagePoints={selectedDamagePoints}
                    removeDamagePoint={removeDamagePoint}
                    titles={{
                        top: tWithNs('image.bus.title.top'),
                        left: tWithNs('image.bus.title.left'),
                        right: tWithNs('image.bus.title.right'),
                    }}
                />
            );
        default:
            return (
                <CarImage
                    addDamagePoint={addDamagePoint}
                    selectedDamagePoints={selectedDamagePoints}
                    removeDamagePoint={removeDamagePoint}
                    titles={{
                        top: tWithNs('image.car.title.top'),
                        left: tWithNs('image.car.title.left'),
                        right: tWithNs('image.car.title.right'),
                    }}
                />
            );
    }
};
