export enum AutocompleteValueKeys {
    NAME = 'name',
    GIVEN_NAME = 'given-name',
    FAMILY_NAME = 'family-name',
    TEL = 'tel', // phone
    EMAIL = 'email',
    STREET_ADDRESS = 'street-address',
    POSTAL_CODE = 'postal-code', // zip
    CITY = 'city',
}
