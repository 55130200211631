import { BodyPartListKeys, BodyPartListTypeModel } from '../interfaces';

const { WHOLE_BODY } = BodyPartListKeys;

export const updateSelectablePartsList = (
    key: BodyPartListKeys,
    id: number,
    array: BodyPartListTypeModel,
    selected: boolean
): BodyPartListTypeModel => {
    let parts;

    if (selected) {
        if (key === WHOLE_BODY) {
            parts = [{ key, id }];
        } else {
            parts = [...array, { key, id }];
        }
    } else {
        parts = [...array];
        parts = parts.filter((p) => p.key !== key);
    }

    return parts;
};
