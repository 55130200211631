import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBodyPartList, selectTeethOrBody } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    BodyPartListBackImage,
    BodyPartListFrontImage,
    bodyPartListIds,
    BodyPartListKeys,
    BodyPartListModel,
    BodyPartListTypeModel,
    Clickable,
    emptyFn,
    Formable,
    isBodyPartChecked,
    PageLayout,
    PartCheckboxGroup,
    SVGClickable,
    TeethOrBodyKeys,
    updateSelectablePartsList,
} from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { personActions } from '../../../sagas/person';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { useForm } from 'react-hook-form';
import { LabelDirections } from '../../../models/common/LabelDirections';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import Tabs from '../../../components/tabs/Tabs';
import { TabKeys } from '../../../models/common/Tab';
import TabPanel from '../../../components/tabs/TabPanel';
import { CheckboxInput } from '../../../components/inputs/CheckboxInput';

/**
 * Destructure necessary imports
 */
const {
    ARM_LEFT,
    ARM_RIGHT,
    FOOT_LEFT_LEGACY,
    FOOT_RIGHT_LEGACY,
    HAND_LEFT_LEGACY,
    HAND_RIGHT_LEGACY,
    HEAD_BACK,
    HEAD_FRONT,
    LEG_LEFT,
    LEG_RIGHT,
    TORSO_BACK_LEGACY,
    TORSO_FRONT_LEGACY,
    WHOLE_BODY,
} = BodyPartListKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { LEFT, RIGHT } = LabelDirections;
const { BOTH } = TeethOrBodyKeys;
const { ACCIDENT_TEETH } = PersonRoutePaths;
const { BACK, FRONT } = TabKeys;

/**
 * Page view and page logic
 */
export const AccidentBodyPartsPage = () => {
    const dispatch = useDispatch();
    const bodyPartList = useSelector(selectBodyPartList);
    const teethOrBody = useSelector(selectTeethOrBody);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.bodyParts');
    const { register } = useForm<BodyPartListTypeModel>({});

    /**
     * Internal helper functions
     */
    const updateBodyParts = (key: BodyPartListKeys, id: number, selected: boolean) => {
        const parts = updateSelectablePartsList(key, id, bodyPartList, selected);

        dispatch(personActions.update({ bodyPartList: parts }));
    };

    const isDisabled = isBodyPartChecked(WHOLE_BODY, bodyPartList);
    const tCheckboxLabel = (direction: string, label: string) => `${t(direction)} ${tWithNS.t(label)}`;

    /**
     * Handle functions
     */

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();

        if (teethOrBody === BOTH) {
            dispatch(wizardActions.skipBackToPrev(true));
            dispatcherWithPromise(dispatch, commonActions.send).then(() =>
                dispatch(wizardActions.goTo(ACCIDENT_TEETH))
            );
        } else {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleChange = (e: Formable) => {
        const { checked, name } = e.currentTarget;
        const bodyPart = bodyPartListIds.find((bp: { id: number; key: BodyPartListKeys }) => bp.key === name);

        if (bodyPart) {
            updateBodyParts(name as BodyPartListKeys, bodyPart.id, checked);
        }
    };

    const handleBlur = emptyFn;

    const handleSvgClick = (e: SVGClickable) => {
        const name = e.currentTarget.id as BodyPartListKeys;
        const bodyPart = bodyPartListIds.find((bp: { id: number; key: BodyPartListKeys }) => bp.key === name);

        if (bodyPart) {
            const checked = bodyPartList.some((bp: BodyPartListModel) => bp.key === name);
            updateBodyParts(name, bodyPart.id, !checked);
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={'page__body-parts'}
            {...{ handleBackButton, handleContinueButton }}
        >
            <div className={'tabs__container mt-5'}>
                <Tabs
                    label={tWithNS.t('tabs.label')}
                    tabs={[
                        { id: FRONT, label: tWithNS.t('tabs.tab.front.label') },
                        { id: BACK, label: tWithNS.t('tabs.tab.back.label') },
                    ]}
                >
                    <TabPanel tabId={FRONT}>
                        <div className={'body-parts space-x-5 mt-5'}>
                            <div className={'body-parts__image'}>
                                <div className={'wrapper'}>
                                    <BodyPartListFrontImage onClick={handleSvgClick} selectedParts={bodyPartList} />
                                    <div className={'footer'}>
                                        <span>{tWithNS.t('image.footer.right')}</span>
                                        <span>{tWithNS.t('image.footer.left')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'body-parts__content'}>
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.WHOLE_BODY.description')}
                                    label={tWithNS.t('parts.WHOLE_BODY.label')}
                                    labelFor={WHOLE_BODY}
                                >
                                    <CheckboxInput
                                        id={WHOLE_BODY}
                                        isChecked={isBodyPartChecked(WHOLE_BODY, bodyPartList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                <h2>{tWithNS.t('parts.heading.upperBody')}</h2>

                                {/* HEAD_FRONT */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HEAD_FRONT.description')}
                                    label={tWithNS.t('parts.HEAD_FRONT.label')}
                                    labelFor={HEAD_FRONT}
                                >
                                    <CheckboxInput
                                        id={HEAD_FRONT}
                                        isChecked={isBodyPartChecked(HEAD_FRONT, bodyPartList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* TORSO_FRONT */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TORSO_FRONT.description')}
                                    label={tWithNS.t('parts.TORSO_FRONT.label')}
                                    labelFor={TORSO_FRONT_LEGACY}
                                >
                                    <CheckboxInput
                                        id={TORSO_FRONT_LEGACY}
                                        isChecked={isBodyPartChecked(TORSO_FRONT_LEGACY, bodyPartList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* ARM */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.ARM.description')}
                                    label={tWithNS.t('parts.ARM.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={LEFT}
                                        id={ARM_LEFT}
                                        isChecked={isBodyPartChecked(ARM_LEFT, bodyPartList)}
                                        label={tCheckboxLabel(LEFT, 'parts.ARM.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={RIGHT}
                                        id={ARM_RIGHT}
                                        isChecked={isBodyPartChecked(ARM_RIGHT, bodyPartList)}
                                        label={tCheckboxLabel(RIGHT, 'parts.ARM.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* HAND */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HAND.description')}
                                    label={tWithNS.t('parts.HAND.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={LEFT}
                                        id={HAND_LEFT_LEGACY}
                                        isChecked={isBodyPartChecked(HAND_LEFT_LEGACY, bodyPartList)}
                                        label={tCheckboxLabel(LEFT, 'parts.HAND.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={RIGHT}
                                        id={HAND_RIGHT_LEGACY}
                                        isChecked={isBodyPartChecked(HAND_RIGHT_LEGACY, bodyPartList)}
                                        label={tCheckboxLabel(RIGHT, 'parts.HAND.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                <h2>{tWithNS.t('parts.heading.lowerBody')}</h2>

                                {/* LEG */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.LEG.description')}
                                    label={tWithNS.t('parts.LEG.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={LEFT}
                                        id={LEG_LEFT}
                                        isChecked={isBodyPartChecked(LEG_LEFT, bodyPartList)}
                                        label={tCheckboxLabel(LEFT, 'parts.LEG.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={RIGHT}
                                        id={LEG_RIGHT}
                                        isChecked={isBodyPartChecked(LEG_RIGHT, bodyPartList)}
                                        label={tCheckboxLabel(RIGHT, 'parts.LEG.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* FOOT */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.FOOT.description')}
                                    label={tWithNS.t('parts.FOOT.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={LEFT}
                                        id={FOOT_LEFT_LEGACY}
                                        isChecked={isBodyPartChecked(FOOT_LEFT_LEGACY, bodyPartList)}
                                        label={tCheckboxLabel(LEFT, 'parts.FOOT.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={RIGHT}
                                        id={FOOT_RIGHT_LEGACY}
                                        isChecked={isBodyPartChecked(FOOT_RIGHT_LEGACY, bodyPartList)}
                                        label={tCheckboxLabel(RIGHT, 'parts.FOOT.label')}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel tabId={BACK}>
                        <div className={'body-parts space-x-5 mt-5'}>
                            <div className={'body-parts__image'}>
                                <div className={'wrapper'}>
                                    <BodyPartListBackImage onClick={handleSvgClick} selectedParts={bodyPartList} />
                                    <div className={'footer'}>
                                        <span>{tWithNS.t('image.footer.left')}</span>
                                        <span>{tWithNS.t('image.footer.right')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'body-parts__content'}>
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HEAD_BACK.description')}
                                    label={tWithNS.t('parts.HEAD_BACK.label')}
                                    labelFor={HEAD_BACK}
                                >
                                    <CheckboxInput
                                        id={HEAD_BACK}
                                        isChecked={isBodyPartChecked(HEAD_BACK, bodyPartList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TORSO_BACK.description')}
                                    label={tWithNS.t('parts.TORSO_BACK.label')}
                                    labelFor={TORSO_BACK_LEGACY}
                                >
                                    <CheckboxInput
                                        id={TORSO_BACK_LEGACY}
                                        isChecked={isBodyPartChecked(TORSO_BACK_LEGACY, bodyPartList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </PageLayout>
    );
};
