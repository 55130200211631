import { css } from '@emotion/react';
import { defaultFocusStyle } from '../../variables';

export const btnGroupCss = css({
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '100%',

    '& :last-child': {
        order: 1,
    },

    '& .btn': {
        marginBottom: '1rem',
        marginTop: '1rem',
        textTransform: 'uppercase',
    },

    '& .btn-lg': {
        borderRadius: 2,
        fontSize: '1.25rem',
        lineHeight: '1.5',
        padding: '0.5rem 1rem',
        width: '100%',
    },

    '@media screen and (min-width: 768px)': {
        '& :last-child': {
            order: -1,
        },

        '& .btn': {
            marginLeft: '0.75rem',
            marginRight: '0.75rem',
            minWidth: 200,
            width: 200,

            '&:not(:first-of-type)': {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
            },

            '&:not(:last-child)': {
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
            },
        },
    },
});

export const helpTextCss = css({
    fontFamily: 'BrandonTextRegular, sans-serif',
    fontSize: '0.875rem',
    padding: '1rem',

    '&:focus': {
        ...defaultFocusStyle,
    },
});
