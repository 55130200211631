import React, { Dispatch, SetStateAction } from 'react';
import { ClaimantModel } from '../../../../models/person/Claimant';
import { useI18n } from '../../../../hooks/useI18n';
import { FlowKeys, I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';
import { useSelector } from 'react-redux';
import { selectClaimantInformation } from '../../../../sagas/selectors/personSelectors';
import { selectFlow } from '../../../../sagas/selectors/flowSelectors';

interface DisplayClaimaintInformationProps {
    model: Partial<ClaimantModel>;
    i18n?: I18NOptions;
    error: Dispatch<SetStateAction<boolean>>;
}

export const DisplayClaimantInformation = ({ model, i18n, error }: DisplayClaimaintInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace);
    const flow = useSelector(selectFlow);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));
    const { firstName, lastName, nationalIdentity, email, phone, street, zip, city } = model;
    const id = useSelector(selectClaimantInformation);

    const renderFirstAndLastName = () => {
        if (firstName && !lastName) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.lastName')}</p>;
        }
        if (!firstName && lastName) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.firstName')}</p>;
        }
        if (!firstName && !lastName) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.firstAndLastName')}</p>;
        }
        return <SummaryItemText label={tWithPrefix(`text.fullName`)} text={`${firstName} ${lastName}`} />;
    };

    const renderNationalIdentity = () => {
        if (!nationalIdentity && !id.missingSSN) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.nationalIdentity')}</p>;
        }
        return <SummaryItemText label={tWithPrefix(`text.nationalIdentity`)} text={`${nationalIdentity}`} />;
    };

    const renderPhone = () => {
        if (phone) {
            return (
                <div className={`${email ? `col-4` : `col-12`} divider no-border text-align-left`}>
                    <SummaryItemText label={tWithPrefix(`text.phone`)} text={`${phone}`} />
                </div>
            );
        }
        if (!email && flow !== FlowKeys.DEATH) {
            error(true);
            return (
                <div className={`${email ? `col-4` : `col-12`} divider no-border text-align-left`}>
                    <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.phoneOrEmail')}</p>
                </div>
            );
        }
    };

    const renderStreet = () => {
        if (street) {
            return (
                <div className={'col-4 divider text-align-left'}>
                    <SummaryItemText label={tWithPrefix(`text.street`)} text={`${street}`} />
                </div>
            );
        }
        if (flow !== FlowKeys.DEATH) {
            error(true);
            return (
                <div className={'col-4 divider text-align-left'}>
                    <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.street')}</p>
                </div>
            );
        }
    };

    const renderZip = () => {
        if (zip) {
            return (
                <div className={'col-4 divider text-align-left'}>
                    <SummaryItemText label={tWithPrefix(`text.zip`)} text={`${zip}`} />
                </div>
            );
        }
        if (flow !== FlowKeys.DEATH) {
            error(true);
            return (
                <div className={'col-4 divider text-align-left'}>
                    <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.zip')}</p>
                </div>
            );
        }
    };

    const renderCity = () => {
        if (city) {
            return (
                <div className={'col-4 divider text-align-left'}>
                    <SummaryItemText label={tWithPrefix(`text.city`)} text={`${city}`} />
                </div>
            );
        }
        if (flow !== FlowKeys.DEATH) {
            error(true);
            return (
                <div className={'col-4 divider text-align-left'}>
                    <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.city')}</p>
                </div>
            );
        }
    };

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 spacing header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix(`text.claimantInformation`)}
                </p>
            </div>
            <div className={'col-4 divider no-border text-align-left'}>{renderFirstAndLastName()}</div>
            <div className={'col-8 divider no-border text-align-left'}>{renderNationalIdentity()}</div>
            {renderPhone()}
            {email && (
                <div className={`${phone ? `col-8` : `col-12`} divider no-border text-align-left`}>
                    <SummaryItemText label={tWithPrefix(`text.email`)} text={`${email}`} />
                </div>
            )}
            {renderStreet()}
            {renderZip()}
            {renderCity()}
        </>
    );
};
