import { yupCustomLocale } from '../config/yup';
import { setLocale } from 'yup';
import { ObjectWithDynamicKey } from '../interfaces';

export const yupSetLocale = (
    yup: ObjectWithDynamicKey,
    yupCustom: ObjectWithDynamicKey
) => {
    // Set global yup validations
    setLocale(yup as object);

    // set custom yup validations
    Object.keys(yupCustom).forEach((type) => {
        if (yupCustomLocale[type]) {
            Object.keys(yupCustom[type]).forEach(
                (method) =>
                    (yupCustomLocale[type][method] = yupCustom[type][method])
            );
        }
    });
};
