import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { policySchema } from '../fieldSchemas/policySchema';

/**
 * Validation logic
 */
export const policyNumberSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...policySchema(t),
    });
};
