import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';

export const personSchema = (t: TFunction, missingSSN: boolean = false) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...(!missingSSN && nationalIdentitySchema(t)),
        ...contactDetailsSchema(t),
    });
};
