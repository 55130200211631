import { Nullable } from '../../../../../interfaces/types';

export enum TypeOfIntersectionKeys {
    T_SECTION = 'T_SECTION',
    LIGHT_CONTROLLED = 'LIGHT_CONTROLLED',
    RIGHT_OF_WAY = 'RIGHT_OF_WAY',
    OTHER = 'OTHER',
}

export type TypeOfIntersectionTypeKeys = Nullable<TypeOfIntersectionKeys>;
