import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    ContractorInformationModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import { selectContractorInformation } from '../../../sagas/selectors/lpoSelectors';
import { contractorInformationSchema } from '../../../validations/schemas/contractorInformationSchema';
import { lpoActions } from '../../../sagas/lpo';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectOrgnrService } from '../../../sagas/selectors/orgnrSelectors';
import { orgnrServiceActions } from '../../../sagas/services/orgnrService';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BUSINESS_NUMBER, NAME, REASON } = FormFieldNames;
const {
    BACK_BUTTON,
    COMPANY_BUSINESS_NUMBER_LABEL,
    COMPANY_BUSINESS_NUMBER_PLACEHOLDER,
    COMPANY_NAME_LABEL,
    COMPANY_NAME_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    REASON_LABEL,
    REASON_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const PropertyContractorInformationPage = () => {
    const dispatch = useDispatch();
    const contractorInformation = useSelector(selectContractorInformation);
    const getBusinessName = useSelector(selectOrgnrService);
    const customCAN = useSelector(selectCustomCAN);
    const [businessName, setBusinessName] = useState('');
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.contractorInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ContractorInformationModel>({
        resolver: yupResolver(contractorInformationSchema(t)),
        defaultValues: {
            ...contractorInformation,
        },
    });

    useEffect(() => {
        if (getBusinessName.name) {
            setBusinessName(getBusinessName.name);
        }
    }, [getBusinessName]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        if (id === 'businessNumber' && value.length === 11) {
            dispatch(orgnrServiceActions.request({ country: 'se', orgnr: value.toString() }));
        }
        if (id === 'name') {
            setBusinessName(value);
        }
    };

    const onSubmit = (values: ContractorInformationModel) => {
        dispatcherWithPromise(dispatch, lpoActions.update, {
            contractorInformation: { ...values },
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={BUSINESS_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.businessNumber}
                                errorMessage={errors.businessNumber?.message}
                                id={BUSINESS_NUMBER}
                                inputFieldWrapper={'col-6'}
                                label={t(COMPANY_BUSINESS_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(COMPANY_BUSINESS_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.name}
                                errorMessage={errors.name?.message}
                                id={NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(COMPANY_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(COMPANY_NAME_PLACEHOLDER)}
                                reference={ref}
                                value={businessName}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={REASON}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.reason}
                                errorMessage={errors.reason?.message}
                                id={REASON}
                                inputFieldWrapper={'col-12'}
                                label={t(REASON_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(REASON_PLACEHOLDER)}
                                reference={ref}
                                rows={5}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
