/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { summaryInputItemCss } from './SummaryInputItem.style';
import { Nullable } from '../../../interfaces/types';
import { summaryItemTextCss } from '../summaryItemText/SummaryItemText.style';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface SummaryInputItemProps {
    className?: string;
    customCAN?: customCANProps;
    label: Nullable<string>;
    text?: Nullable<string>;
}

/**
 * Component view and component logic
 */
export const SummaryInputItem = ({
    className,
    customCAN,
    label,
    text,
}: SummaryInputItemProps) => {
    return (
        <div className={`${className}`}>
            <p
                className={'summary-title'}
                css={css(summaryItemTextCss(customCAN))}
                tabIndex={0}
            >
                {label ? label : ''}
            </p>
            <div
                className={'summary-title'}
                tabIndex={0}
                css={css(summaryInputItemCss)}
            >
                {text}
            </div>
        </div>
    );
};
