/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ReactComponent as ProtectorLogo } from '../../../assets/logo/protector-logo.svg';
import { navBarCss } from './NavBar.style';
import { customCANProps } from '../../../config/customCANCompanies';

interface NavBarProps {
    country: string;
    customCAN?: customCANProps;
    domain: string;
    envClassName?: string;
    environment: string;
    label: string;
    language: string;
}

export const NavBar = ({
    country,
    customCAN,
    domain,
    envClassName,
    environment,
    label,
    language,
}: NavBarProps) => {
    return (
        <div
            className={`navbar ${envClassName}`}
            css={css(navBarCss(customCAN))}
        >
            <a
                href={`/${country}/${language}${
                    customCAN
                        ? `?company=${customCAN.company}_${customCAN.partyId}`
                        : ''
                }`}
                className={'navbar-link'}
                aria-label={label}
            >
                {customCAN ? (
                    <img alt={'logo'} height={80} src={customCAN.logo} />
                ) : (
                    <ProtectorLogo />
                )}{' '}
                {domain} {environment}
            </a>
        </div>
    );
};
