import { VehiclesModel } from '../../../motor/interfaces/models';
import {
    OwnerInformationListModel,
    OwnerInformationListTypeModel,
} from '../../interfaces/models';
import { Nullable } from '../../../../../interfaces/types';
import { findOwnerByDamagedItem } from './findOwnerByDamagedItem';
import { findCounterpartyVehicle } from '../../../motor';

export const findOwnerByVehicles = (
    vehicles: VehiclesModel,
    ownerInformationList: OwnerInformationListTypeModel
): Nullable<OwnerInformationListModel> => {
    const vehicleInternalId = findCounterpartyVehicle(vehicles)?.internalId;
    return vehicleInternalId
        ? findOwnerByDamagedItem(ownerInformationList, vehicleInternalId)
        : null;
};
