import React, { SyntheticEvent, useEffect, useState } from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectLiabilityClaimDescription,
    selectReportedToInsurersInsurance,
} from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { liabilityClaimDescriptionSchema } from '../../../validations/schemas/liabilityClaimDescriptionSchema';
import { LiabilityClaimDescriptionModel } from '../../../models/lpo/ClaimDescription';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { getDontKnowOption, getNoOption, getYesOption } from '../../../utils/lpo/reportedToInsurersInsuranceUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import { claimDescriptionUtil } from '../../../utils/claimDescriptionUtil';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    LIABILITY_CLAIM_DESCRIPTION_LABEL,
    LIABILITY_CLAIM_DESCRIPTION_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    REPORTED_TO_INSURERS_INSURANCE_LABEL,
    REPORTED_TO_INSURERS_INSURANCE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { LIABILITY_CLAIM_DESCRIPTION, REPORTED_TO_INSURERS_INSURANCE } = FormFieldNames;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const LiabilityClaimDescriptionPage = () => {
    const dispatch = useDispatch();
    const [reported, setReported] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const liabilityClaimDescription = useSelector(selectLiabilityClaimDescription);
    const reportedToInsurersInsurance = useSelector(selectReportedToInsurersInsurance);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.liability.claimDescription');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<LiabilityClaimDescriptionModel>({
        resolver: yupResolver(liabilityClaimDescriptionSchema(t)),
        defaultValues: {
            liabilityClaimDescription,
        },
    });

    const options = [getYesOption(t), getNoOption(t), getDontKnowOption(t)];

    useEffect(() => {
        const selected = options.find((x) => x.value === reportedToInsurersInsurance);
        if (selected) {
            setReported(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportedToInsurersInsurance, setReported]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        if (is(reportedToInsurersInsurance, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            const { id } = e.currentTarget;
            await trigger(id);
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setReported(object);
        dispatch(lpoActions.update({ reportedToInsurersInsurance: value as YesNoKeys }));
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: LiabilityClaimDescriptionModel) => {
        if (is(reportedToInsurersInsurance, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            dispatcherWithPromise(dispatch, lpoActions.update, {
                liabilityClaimDescription: values.liabilityClaimDescription,
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <MuiAutocomplete
                        error={!!error}
                        errorMessage={error}
                        id={REPORTED_TO_INSURERS_INSURANCE}
                        inputFieldWrapper={'col-12'}
                        label={t(REPORTED_TO_INSURERS_INSURANCE_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(REPORTED_TO_INSURERS_INSURANCE_PLACEHOLDER)}
                        value={reported}
                        {...{ customCAN, options }}
                    />
                    {is(reportedToInsurersInsurance, YES) && (
                        <Controller
                            control={control}
                            name={LIABILITY_CLAIM_DESCRIPTION}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    error={!!errors.liabilityClaimDescription}
                                    errorMessage={errors.liabilityClaimDescription?.message}
                                    id={LIABILITY_CLAIM_DESCRIPTION}
                                    inputFieldWrapper={'col-12'}
                                    label={t(LIABILITY_CLAIM_DESCRIPTION_LABEL)}
                                    multiline={true}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(LIABILITY_CLAIM_DESCRIPTION_PLACEHOLDER)}
                                    reference={ref}
                                    rows={6}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    )}
                </Grid>
            </form>
        </PageLayout>
    );
};
