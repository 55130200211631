import { bankAccountFinlandMatchPattern } from './bankAccountFinlandMatchPattern';

export function bankAccountFinlandValidator(accountNumber: string) {
    return {
        isValid: function isValid() {
            if (accountNumber === null || accountNumber.length === 0) {
                return false;
            }

            return bankAccountFinlandMatchPattern(accountNumber);
        },
    };
}
