import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ClaimTypeEnums,
    Clickable,
    FormChangeable,
    Grid,
    is,
    LpoClaimCauseEnums,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectClaimCause, selectClaimType } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import {
    getBurglaryOption,
    getConsultantOption,
    getContractOption,
    getDamageOption,
    getDefectiveWaterproofingOption,
    getDownfallOption,
    getElectricOption,
    getEngineOption,
    getErrorOption,
    getExplosionOption,
    getFallOption,
    getFireOption,
    getFloodOption,
    getGlassOption,
    getHouseholdApplianceOption,
    getInjuryOption,
    getLeakageOption,
    getLiabilityOption,
    getLightningOption,
    getOtherOption,
    getOutbreakOption,
    getPressurizedPipeOption,
    getRobberyOption,
    getStemStopOption,
    getStormOption,
    getTheftAndDamageOption,
    getTransportOption,
    getUnlockedBuildingOption,
    getUnpressurizedPipeOption,
    getWaterOption,
    getWetRoomOption,
} from '../../../utils/lpo/claimCauseUtils';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_CAUSE_LABEL,
    CLAIM_CAUSE_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { CLAIM_CAUSE } = FormFieldNames;
const { CONDOMINIUM_SUPPLEMENT, DAMAGE_CLAIM, FIRE, NATURE, THEFT, WATER } = ClaimTypeEnums;

/**
 * Page view and page logic
 */
export const StartClaimCausePage = () => {
    const dispatch = useDispatch();
    const [cause, setCause] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const claimCause = useSelector(selectClaimCause);
    const claimType = useSelector(selectClaimType);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.start.claimCause');

    let options = [getEngineOption(t), getElectricOption(t), getErrorOption(t)];

    if (is(claimType, DAMAGE_CLAIM)) {
        options = [
            getLiabilityOption(t),
            getFallOption(t),
            getContractOption(t),
            getConsultantOption(t),
            getInjuryOption(t),
            getTransportOption(t),
        ];
    }

    if (is(claimType, FIRE)) {
        options = [getFireOption(t), getExplosionOption(t), getElectricOption(t)];
    }

    if (is(claimType, WATER)) {
        options = [
            getUnpressurizedPipeOption(t),
            getPressurizedPipeOption(t),
            getDefectiveWaterproofingOption(t),
            getStemStopOption(t),
            getLeakageOption(t),
            getWetRoomOption(t),
        ];
    }

    if (is(claimType, NATURE)) {
        options = [
            getFloodOption(t),
            getDownfallOption(t),
            getStormOption(t),
            getLightningOption(t),
            getOtherOption(t),
        ];
    }

    if (is(claimType, THEFT)) {
        options = [
            getBurglaryOption(t),
            getTheftAndDamageOption(t),
            getRobberyOption(t),
            getUnlockedBuildingOption(t),
            getOutbreakOption(t),
        ];
    }

    if (is(claimType, CONDOMINIUM_SUPPLEMENT)) {
        options = [
            getHouseholdApplianceOption(t),
            getGlassOption(t),
            getTheftAndDamageOption(t),
            getDamageOption(t),
            getFireOption(t),
            getWaterOption(t),
            getOtherOption(t),
        ];
    }

    useEffect(() => {
        const selected = options.find((x) => x.value === claimCause);
        if (selected) {
            setCause(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimCause, setCause]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(claimCause, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? (object.value as LpoClaimCauseEnums) : null;
        setError('');
        setCause(object);
        dispatch(lpoActions.update({ claimCause: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(claimCause, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={CLAIM_CAUSE}
                    inputFieldWrapper={'col-12'}
                    label={t(CLAIM_CAUSE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(CLAIM_CAUSE_PLACEHOLDER)}
                    value={cause}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
