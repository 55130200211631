/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { summaryTextHeaderCss } from './SummaryTextHeader.style';

interface SummaryTextHeaderProps {
    text?: string;
}

export const SummaryTextHeader = ({ text }: SummaryTextHeaderProps) => {
    return <h2 css={css(summaryTextHeaderCss)}>{text}</h2>;
};
