import React from 'react';
import { ClientErrorIcon, is, ServerErrorIcon } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { RETRY_BUTTON } = PhraseKeys;

/**
 * Utility logic
 */
export const isClientError = (status: number) => status.toString()[0] === '4';
export const expiredUUID = 410;

export const renderErrorContent = (status?: number) => {
    if (status && is(status, expiredUUID)) {
        return {
            continueButton: 'button.reset',
            icon: <ClientErrorIcon />,
            info: 'expiredUUIDError.content.info',
            message: 'expiredUUIDError.content.message',
            sub: 'expiredUUIDError.heading.sub',
            title: 'expiredUUIDError.heading.title',
        };
    } else if (!!status && isClientError(status)) {
        return {
            continueButton: RETRY_BUTTON,
            icon: <ClientErrorIcon />,
            info: 'clientError.content.info',
            message: 'clientError.content.message',
            sub: 'clientError.heading.sub',
            title: 'clientError.heading.title',
        };
    } else {
        return {
            continueButton: RETRY_BUTTON,
            icon: <ServerErrorIcon />,
            info: 'serverError.content.info',
            message: 'serverError.content.message',
            sub: 'serverError.heading.sub',
            title: 'serverError.heading.title',
        };
    }
};
