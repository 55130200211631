import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistoryListen } from '../../../hooks/useHistoryListen';
import { selectAcceptedPrivacy, selectClaimReporterRole } from '../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { HistoryListener } from '../../../types/HistoryListener';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { useGoBack } from '../../../hooks/useGoBack';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { personActions } from '../../../sagas/person';
import { ClaimReporterRoleKeys, Clickable, Link, PageLayout, SingleCheckbox } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useHistory } from 'react-router-dom';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { DEATH_PRIVACY, DYN_CAROUSEL_DEATH_CLAIM_LOCATION } = PersonRoutePaths;
const { ON_BEHALF_CHILD } = ClaimReporterRoleKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const DeathPrivacyPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { listen, unlisten } = useHistoryListen();
    const whoReport = useSelector(selectClaimReporterRole);
    const hasAcceptedPrivacy = useSelector(selectAcceptedPrivacy);
    const customCAN = useSelector(selectCustomCAN);
    const pathPrivacy = DEATH_PRIVACY;
    const pathNextRoute = DYN_CAROUSEL_DEATH_CLAIM_LOCATION;
    const { t } = useI18n();
    const tWithNS = useI18n(
        whoReport === ON_BEHALF_CHILD ? `lob.person.privacy.${ON_BEHALF_CHILD}` : 'lob.person.privacy'
    );

    const listener: HistoryListener = useCallback(
        (hLocation, hAction) => {
            if (hAction === 'POP' && hLocation.pathname === pathNextRoute && !hasAcceptedPrivacy) {
                history.push(pathPrivacy);
            }
        },
        [history, hasAcceptedPrivacy, pathNextRoute, pathPrivacy]
    );

    useEffect(() => {
        hasAcceptedPrivacy ? unlisten() : listen(listener);
    }, [hasAcceptedPrivacy, listen, unlisten, listener]);

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (hasAcceptedPrivacy) {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(personActions.update({ acceptedPrivacy: e.target.checked }));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={!hasAcceptedPrivacy}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <p className={'info-container'} tabIndex={0}>
                {tWithNS.t('text.info')}
            </p>
            <SingleCheckbox
                checked={hasAcceptedPrivacy}
                handleChange={handleCheckbox}
                name={'acceptedPrivacy'}
                {...{ customCAN }}
            >
                {tWithNS.t('text.accept')}
                <Link
                    linkText={tWithNS.t('text.privacyLinkText')}
                    url={tWithNS.t('privacyLinkURL')}
                    {...{ customCAN }}
                />
            </SingleCheckbox>
        </PageLayout>
    );
};
