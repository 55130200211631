/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Clickable } from '../../../interfaces/types/Events.types';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';
import { SummaryNavButton } from '../../atoms/summaryItems/SummaryNavButton';
import { vehicleListUtil } from '../../../utils/vehicleListUtils';
import { summaryVehicleListCss } from './SummaryVehicleList.style';
import { VehiclesModel } from '../../../modules/can/motor/interfaces/models';

interface SummaryVehicleListProps {
    list: VehiclesModel;
    textKey: string;
    path: string;
    dataTestId: string;
    className: string;
    handleClick?: (e: Clickable, path: string) => void;
}

export const SummaryVehicleList = ({
    list,
    textKey,
    path,
    dataTestId,
    className,
    handleClick,
}: SummaryVehicleListProps) => {
    return (
        <div className={className}>
            {list.length > 0 && <SummaryText text={textKey} />}
            {list.map((item, idx) => {
                return (
                    <div
                        key={idx}
                        className={'vehicle-list'}
                        css={css(summaryVehicleListCss)}
                    >
                        <SummaryNavButton
                            dataTestId={dataTestId}
                            path={path}
                            buttonText={vehicleListUtil(item)}
                            handleClick={handleClick}
                        />
                    </div>
                );
            })}
        </div>
    );
};
