import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    Clickable,
    FileModel,
    FileUploader,
    findClaimVehicle,
    isVehicleHeavy,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { selectAttachmentFiles } from '../../../../sagas/selectors/uploadSelectors';
import { uploadAttachmentActions } from '../../../../sagas/upload/attachment/uploadAttachment';
import { deleteAttachmentActions } from '../../../../sagas/upload/attachment/deleteAttachment';
import { selectVehicles } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../config/wizardRouter/motorWizardRoutes';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, DROPZONE_DESCRIPTION, DROPZONE_TITLE, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } =
    PhraseKeys;
const { PARKED_UPLOAD_FIRE_SAFETY_REPORT } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const PFUploadFireReportPage = () => {
    const dispatch = useDispatch();
    const fileStore = useSelector(selectAttachmentFiles);
    const vehicles = useSelector(selectVehicles);
    const claimVehicle = findClaimVehicle(vehicles);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.uploadFireReport');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let nextAction = wizardActions.goToNext();
        if (isVehicleHeavy(claimVehicle?.vehicleType, claimVehicle?.weight)) {
            nextAction = wizardActions.goTo(PARKED_UPLOAD_FIRE_SAFETY_REPORT);
        }

        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
    };

    const handleOnSuccess = (files: FileModel[]) => {
        files.forEach((file) => dispatch(uploadAttachmentActions.request(file)));
    };

    const handleDelete = (e: Clickable, file: FileModel) => {
        e.preventDefault();
        dispatch(deleteAttachmentActions.request(file));
    };

    const checkFileErrors: boolean = fileStore.some((file) => {
        return file.errors.length > 0;
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={checkFileErrors}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <FileUploader
                onSuccess={handleOnSuccess}
                onDelete={handleDelete}
                text={tWithNS.t('upload.label')}
                title={tWithNS.t(DROPZONE_TITLE)}
                label={tWithNS.t(DROPZONE_DESCRIPTION)}
                fileStore={fileStore}
            />
        </PageLayout>
    );
};
