import React from 'react';
import { FormFieldNames } from '../../config/formFieldNames';
import { PhraseKeys } from '../../config/phraseKeys';
import { AutocompleteValueKeys } from '../../config/AutocompleteValueKeys';
import { Controller, useFormContext } from 'react-hook-form';
import { useI18n } from '../../hooks/useI18n';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    OwnerInformationListModel,
} from '@protectorinsurance/ds-can';
import { useSelector } from 'react-redux';
import { selectCustomCAN } from '../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { FIRST_NAME, LAST_NAME, PHONE, EMAIL } = FormFieldNames;
const {
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
} = PhraseKeys;
const { GIVEN_NAME, FAMILY_NAME, EMAIL: EMAIL_AUTOCOMPLETE, TEL } = AutocompleteValueKeys;

/**
 * Interfaces and types
 */
interface CounterpartyInformationPersonFormProps {
    onSubmit: (values: OwnerInformationListModel) => void;
}

/**
 * Component view and component logic
 */
export const CounterpartyInformationPersonForm = ({ onSubmit }: CounterpartyInformationPersonFormProps) => {
    const customCAN = useSelector(selectCustomCAN);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useFormContext<OwnerInformationListModel>();
    const { t } = useI18n();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <HiddenInputSubmit />
            <Grid className={'align-center'}>
                <Controller
                    control={control}
                    name={FIRST_NAME}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={GIVEN_NAME}
                            error={!!errors.firstName}
                            errorMessage={errors.firstName?.message}
                            id={FIRST_NAME}
                            inputFieldWrapper={'col-6'}
                            label={t(FIRST_NAME_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(FIRST_NAME_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={LAST_NAME}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={FAMILY_NAME}
                            error={!!errors.lastName}
                            errorMessage={errors.lastName?.message}
                            id={LAST_NAME}
                            inputFieldWrapper={'col-6'}
                            label={t(LAST_NAME_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(LAST_NAME_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={EMAIL}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={EMAIL_AUTOCOMPLETE}
                            error={!!errors.email}
                            errorMessage={errors.email?.message}
                            id={EMAIL}
                            inputFieldWrapper={'col-6'}
                            inputMode={'email'}
                            label={t(EMAIL_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(EMAIL_PLACEHOLDER)}
                            reference={ref}
                            type={'email'}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={PHONE}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={TEL}
                            error={!!errors.phone}
                            errorMessage={errors.phone?.message}
                            id={PHONE}
                            inputFieldWrapper={'col-6'}
                            inputMode={'tel'}
                            label={t(PHONE_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(PHONE_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
            </Grid>
        </form>
    );
};
