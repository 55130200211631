/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { inputValidationErrorCss } from './InputValidationError.style';
import { CircleWarningIcon } from '../icons/BaseIcons';

interface InputValidationErrorProps {
    fieldName: string;
    error?: string | React.ReactElement;
    className?: string;
}

export function InputValidationError({
    fieldName,
    error = '',
    className = '',
}: InputValidationErrorProps) {
    return (
        <span
            id={`${fieldName}-id`}
            className={`invalid-feedback ${className}`}
            role={'alert'}
            data-testid={`${fieldName}-error`}
            css={css(inputValidationErrorCss)}
        >
            {error && (
                <div className={'error-msg'}>
                    <div className={'error-icon'}>
                        <CircleWarningIcon wh={25} />
                    </div>
                    <div>{error}</div>
                </div>
            )}
        </span>
    );
}
