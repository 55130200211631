import { AppState } from '../store';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import { CarouselConfigLookupResponse, LobKeys, Nullable } from '@protectorinsurance/ds-can';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import { lpoCarouselConfigPages } from '../config/carouselConfigPages/lpo/lpoCarouselConfigPages';
import { motorCarouselConfigPages } from '../config/carouselConfigPages/motor/motorCarouselConfigPages';
import { personCarouselConfigPages } from '../config/carouselConfigPages/person/personCarouselConfigPages';

/**
 * Destructure necessary imports
 */
const { AUTO, PERSON } = LobKeys;

/**
 * Utility logic
 */
export interface CarouselConfigLookupProps {
    slug: string | undefined;
    t: TFunction;
    dispatch: Dispatch;
    state: AppState;
    lob: Nullable<LobKeys>;
}

export const carouselConfigLookup = (props: CarouselConfigLookupProps): CarouselConfigLookupResponse | null => {
    const { slug, lob } = props;
    if (!slug) return null;
    let routes: LpoRoutePaths | MotorRoutePaths | PersonRoutePaths = LpoRoutePaths.DYN_BASE;
    let pages = lpoCarouselConfigPages(props);
    if (lob === AUTO) {
        routes = MotorRoutePaths.DYN_BASE;
        pages = motorCarouselConfigPages(props);
    } else if (lob === PERSON) {
        routes = PersonRoutePaths.DYN_BASE;
        pages = personCarouselConfigPages(props);
    }

    const page = pages.find((page) => page.slug.replace(routes, '') === slug);

    return page ? page : null;
};
