import { css } from '@emotion/react';
import {
    borderBottom,
    inputBorderColor,
    inputFieldPlaceholder,
    secondary,
} from '../../variables';

export const gridCss = css({
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '1rem',
    maxWidth: '100%',
    alignItems: 'end',

    '&.align-items-start': {
        alignItems: 'start',
    },

    '&.align-center': {
        alignItems: 'unset',
    },

    '& .height-100': {
        height: '100%',
    },

    '& .text-align-left': {
        textAlign: 'left',
    },

    '& .margin-bottom': {
        marginBottom: '1rem',
    },

    '& .no-margin-bottom': {
        marginBottom: 0,
    },

    '& .no-border': {
        border: 0,
    },

    '&.align-items-center': {
        alignItems: 'center',
    },

    '@supports (display: grid)': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '& .col-1, & .col-2, & .col-3, & .col-4, & .col-5, & .col-6, & .col-7, & .col-8, & .col-9, & .col-10, & .col-11, & .col-12':
            {
                gridColumn: 'span 3',
            },
        '& .single-2-center': {
            gridColumn: '2',
        },
        '& .single-4-center': {
            gridColumn: '2',
        },
        '& .single-4-center-w-button': {
            gridColumn: '1 / span 3',
        },
    },

    '& .col-1, & .col-2, & .col-3, & .col-4, & .col-5, & .col-6, & .col-7, & .col-8, & .col-9, & .col-10, & .col-11, & .col-12, & .single-4-center-w-button':
        {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '0 1rem',
            width: '100%',

            '& div': {
                '&:first-of-type': {
                    width: '100%',
                },

                '& input': {
                    width: '100%',
                },
            },

            '& .file-list': {
                display: 'flex',
            },

            '&.descInputField': {
                flexDirection: 'row',
                justifyContent: 'end',

                '& button': {
                    border: `1px solid ${inputFieldPlaceholder}`,
                    cursor: 'pointer',
                    borderRadius: '2px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    padding: '0.2rem',
                    minWidth: '150px',
                    width: '150px',
                    marginLeft: '3px',
                    boxShadow: '-2px 2px 8px -2px #777;',

                    '& span': {
                        verticalAlign: 'middle',
                    },

                    '&:hover': {
                        borderColor: secondary,
                        boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.25)',
                        outline: 0,
                    },
                },
            },
        },

    '& .divider': {
        borderBottom: `1px dotted ${borderBottom}`,
        paddingBottom: '1.5rem',
        paddingTop: '1.5rem',
    },

    '& .final-summary': {
        textAlign: 'left',
    },

    '& .vehicle-divider': {
        justifyContent: 'center',
        paddingBottom: '0.5rem',
        paddingTop: '0.5rem',

        '& svg': {
            alignSelf: 'center',
        },
    },

    '& .last': {
        borderBottom: `1px dotted ${borderBottom}`,
    },

    '& .delete': {
        minWidth: 82,
    },

    '& .spacing': {
        paddingBottom: '1.5rem',
        paddingTop: '1.5rem',
    },

    '& .multiple': {
        '& label': {
            alignItems: 'center',
            display: 'flex',
            height: '3rem',
            padding: '0 0 0 3rem',
        },
    },

    '& .no-padding': {
        padding: 0,
    },

    '& .padding-left': {
        paddingLeft: '1rem',
    },

    '& .padding-right': {
        paddingRight: '1rem',
    },

    '& .padding-top': {
        paddingTop: '1rem',
    },

    '& .padding-bottom': {
        paddingBottom: '1rem',
    },

    '& .overflow-paragraph': {
        maxWidth: 200,
        overflow: 'hidden',
        padding: '0 1rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

    '& .all-around-border': {
        border: `2px solid ${inputBorderColor}`,
        borderRadius: 2,
    },

    '& .sick-leave-divider': {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        display: '-webkit-box',
        alignItems: 'center',
        padding: '1rem',
        marginRight: 0,
        marginTop: '1rem',
        marginBottom: '1rem',

        '& .col-1, & .col-2, & .col-3, & .col-4, & .col-5, & .col-6, & .col-7, & .col-8, & .col-9, & .col-10, & .col-11, & .col-12, & .single-4-center-w-button':
            {
                width: 'unset',
            },
    },

    '@media screen and (min-width: 768px)': {
        '& .col-1, & .col-2': {
            width: '16.66666%',
        },

        '& .col-3, & .col-4': {
            width: '33.33333%',
        },

        '& .col-5, & .col-6': {
            width: '50%',
        },

        '& .col-7, & .col-8': {
            width: '66.66666%',
        },

        '& .col-9, & .col-10': {
            width: '83.33333%',
        },

        '& .col-11, & .col-12': {
            width: '100%',
        },

        '& .single-4-center, .single-2-center, .single-4-center-w-button': {
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },

        '& .vehicle-divider': {
            borderBottom: `1px dotted ${borderBottom}`,
            paddingBottom: '1.5rem',
            paddingTop: '1.5rem',
        },

        '& .sick-leave-divider': {
            border: '1px solid rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
            marginRight: '2rem',
            alignItems: 'center',
            display: '-webkit-box',
            padding: '1rem',
            marginTop: 0,
            marginBottom: 0,
        },

        '@supports (display: grid)': {
            gridTemplateColumns: 'repeat(6, 1fr)',

            '& .col-1, & .col-2, & .col-3, & .col-4, & .col-5, & .col-6, & .col-7, & .col-8, & .col-9, & .col-10, & .col-11, & .col-12, .single-2-center, .single-4-center, .single-4-center-w-button':
                {
                    width: 'unset',
                },

            '& .col-1, & .col-2': {
                gridColumnStart: 'span 1',
            },

            '& .col-3, & .col-4': {
                gridColumnStart: 'span 2',
            },

            '& .col-5, & .col-6': {
                gridColumnStart: 'span 3',
            },

            '& .col-7, & .col-8': {
                gridColumnStart: 'span 4',
            },

            '& .col-9, & .col-10': {
                gridColumnStart: 'span 5',
            },

            '& .col-11, & .col-12': {
                gridColumnStart: 'span 6',
            },
            '& .single-2-center, .single-4-center, .single-4-center-w-button': {
                marginLeft: 'unset',
                marginRight: 'unset',
            },
            '& .single-2-center': {
                gridColumn: '3 / span 2',
            },
            '& .single-4-center': {
                gridColumn: '3 / span 2',
            },

            '& .single-4-center-w-button': {
                gridColumn: 'span 6',
            },

            '& .align-self-start': {
                alignSelf: 'start',
            },

            '& .justify-self-center': {
                justifySelf: 'center',
            },

            '& .justify-self-end': {
                justifySelf: 'end',
            },

            '& .align-button-start': {
                '& button': {
                    float: 'left',
                },
            },
        },
    },

    '@media print, screen and (min-width: 992px)': {
        '& .col-1': {
            width: '8.33333%',
        },

        '& .col-2': {
            width: '16.66666%',
        },

        '& .col-3': {
            width: '25%',
        },

        '& .col-4': {
            width: '33.33333%',
        },

        '& .col-5': {
            width: '41.66666%',
        },

        '& .col-6': {
            width: '50%',
        },

        '& .col-7': {
            width: '58.33333%',
        },

        '& .col-8': {
            width: '66.66666%',
        },

        '& .col-9': {
            width: '75%',
        },

        '& .col-10': {
            width: '83.33333%',
        },

        '& .col-11': {
            width: '91.66666%',
        },

        '& .col-12': {
            width: '100%',
        },

        '@supports (display: grid)': {
            gridTemplateColumns: 'repeat(12, 1fr)',

            '& .col-1, & .col-2, & .col-3, & .col-4, & .col-5, & .col-6, & .col-7, & .col-8, & .col-9, & .col-10, & .col-11, & .col-12':
                {
                    width: 'unset',
                },

            '& .col-1': {
                gridColumnStart: 'span 1',
            },

            '& .col-2': {
                gridColumnStart: 'span 2',
            },

            '& .col-3': {
                gridColumnStart: 'span 3',
            },

            '& .col-4': {
                gridColumnStart: 'span 4',
            },

            '& .col-5': {
                gridColumnStart: 'span 5',
            },

            '& .col-6': {
                gridColumnStart: 'span 6',
            },

            '& .col-7': {
                gridColumnStart: 'span 7',
            },

            '& .col-8': {
                gridColumnStart: 'span 8',
            },

            '& .col-9': {
                gridColumnStart: 'span 9',
            },

            '& .col-10': {
                gridColumnStart: 'span 10',
            },

            '& .col-11': {
                gridColumnStart: 'span 11',
            },

            '& .col-12': {
                gridColumnStart: 'span 12',
            },

            '& .dual': {
                gridColumnEnd: '7',
            },

            '& .single-2-center, .single-4-center, .single-4-center-w-button': {
                marginLeft: 'unset',
                marginRight: 'unset',
            },

            '& .single-2-center': {
                gridColumn: '6 / span 2',
            },

            '& .single-4-center': {
                gridColumn: '5 / span 4',
            },

            '& .single-4-center-w-button': {
                gridColumn: '3 / span 8',
            },

            '& .justify-self-center': {
                justifySelf: 'center',
            },

            '& .justify-self-end': {
                justifySelf: 'end',
            },

            '& .justify-content-center': {
                alignSelf: 'center',
                justifyContent: 'center',
            },

            '& .align-items-start': {
                alignItems: 'start',
            },

            '& .align-self-start': {
                alignSelf: 'start',
            },

            '& .hide-items': {
                display: 'none',
            },

            '& .justify-content-start': {
                justifyContent: 'start',
            },

            '@media print': {
                '& .hide-items': {
                    display: 'block',
                },

                '& .align-self-start': {
                    alignSelf: 'start',
                },
            },
        },
    },
});
