/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { Fragment } from 'react';
import { Nullable } from '../../../interfaces/types/Nullable.types';
import { Clickable } from '../../../interfaces/types/Events.types';
import { capitalize } from '../../../utils/strings/textUtils';
import { summaryBtnCss } from './SummaryNavButton.style';

interface SummaryNavButtonProps {
    dataTestId: Nullable<string>;
    buttonText: string;
    path: string;
    textKey?: string;
    className?: string;
    handleClick?: (e: Clickable, path: string) => void;
}

export const SummaryNavButton = ({
    dataTestId,
    buttonText,
    path,
    textKey,
    className,
    handleClick,
}: SummaryNavButtonProps) => {
    const render = () => {
        if (!handleClick) {
            return (
                <span data-hj-suppress data-testid={dataTestId} tabIndex={0}>
                    {textKey ? buttonText : capitalize(buttonText)}
                </span>
            );
        } else {
            return (
                <button
                    type={'button'}
                    className={`summary-btn ${className}`}
                    data-testid={dataTestId}
                    onClick={(e) => handleClick(e, path)}
                    data-hj-suppress
                    css={css(summaryBtnCss)}
                >
                    {buttonText}
                </button>
            );
        }
    };

    return <Fragment>{render()}</Fragment>;
};
