/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactElement } from 'react';
import { Changable, Focusable, Nullable } from '../../../../interfaces';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { muiTextInputStyle } from './MuiTextInput.style';
import { customCANProps } from '../../../../config/customCANCompanies';

/**
 * Interface
 */
interface MuiTextInputProps {
    autoComplete?: string;
    append?: string;
    customCAN?: customCANProps;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: string | ReactElement;
    fullWidth?: boolean;
    id?: string;
    inputFieldWrapper?: string;
    inputMode?: 'numeric' | 'tel' | 'email' | 'text';
    label: string;
    loading?: boolean;
    multiline?: boolean;
    onBlur?: (e: Focusable) => void;
    onChange?: (e: Changable) => void;
    placeholder?: string;
    reference?: any;
    rows?: number;
    type?: 'text' | 'number' | 'email' | 'tel';
    value?: Nullable<string>;
}

/**
 * Component view and component logic
 */
export const MuiTextInput = ({
    autoComplete,
    append,
    customCAN,
    disabled,
    error,
    errorMessage,
    fullWidth,
    id,
    inputFieldWrapper,
    inputMode,
    label,
    loading,
    multiline,
    onBlur,
    onChange,
    placeholder,
    reference,
    rows,
    type,
    ...field
}: MuiTextInputProps) => {
    return (
        <div
            className={`${inputFieldWrapper}`}
            css={css(muiTextInputStyle(customCAN))}
        >
            <TextField
                {...field}
                helperText={errorMessage}
                inputRef={reference}
                inputProps={{
                    'data-testid': `${id}`,
                    step: '0.01',
                }}
                InputProps={
                    loading
                        ? {
                              endAdornment: (
                                  <InputAdornment position={'end'}>
                                      <CircularProgress size={30} />
                                  </InputAdornment>
                              ),
                          }
                        : append
                        ? {
                              endAdornment: (
                                  <InputAdornment position={'end'}>
                                      {append}
                                  </InputAdornment>
                              ),
                          }
                        : undefined
                }
                {...{
                    autoComplete,
                    disabled,
                    error,
                    fullWidth,
                    id,
                    inputMode,
                    label,
                    multiline,
                    onBlur,
                    onChange,
                    placeholder,
                    rows,
                    type,
                }}
            />
        </div>
    );
};
