import { OptionType } from '@protectorinsurance/ds-can';

const typeOfSportOptions: OptionType[] = [
    { value: 'ballSports', label: 'Bollsport' },
    { value: 'dance', label: 'Dans' },
    { value: 'diving', label: 'Dykning' },
    { value: 'parachuteAirSports', label: 'Fallskärms-/flygsport' },
    { value: 'athletics', label: 'Friidrott' },
    { value: 'gymnastics', label: 'Gymnastik' },
    { value: 'equestrian', label: 'Hästsport' },
    { value: 'iceWinterSports', label: 'Is-/alpinsport' },
    { value: 'martialArts', label: 'Kampsport' },
    { value: 'climbing', label: 'Klättring' },
    { value: 'motorSports', label: 'Motorsport' },
    { value: 'sailing', label: 'Segling' },
    { value: 'weightTraining', label: 'Styrketräning' },
    { value: 'waterAquatics', label: 'Vatten-/simsport' },
    { value: 'other', label: 'Annat' },
];

export default typeOfSportOptions;
