import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    isYes,
    MotorClaimCauseKeys,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause, selectHasVehicleDamages } from '../../../../sagas/selectors/motorSelectors';
import { motorActions } from '../../../../sagas/motor';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;
const { PARKING, THEFT_AND_DAMAGE } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const endHasVehicleDamagesPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCause = selectClaimCause(state);
    const options = [
        { id: YES, name: t(`motor.end.hasVehicleDamages.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.end.hasVehicleDamages.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES,
        stateKey: 'hasVehicleDamages',
        i18n: {
            ns: 'motor.end.hasVehicleDamages',
        },
        selectorValue: selectHasVehicleDamages(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext(option.id);
                if (isYes(option.id)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                } else if (claimCause && [THEFT_AND_DAMAGE, PARKING].includes(claimCause)) {
                    nextAction = wizardActions.goTo(MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED);
                }

                dispatcherWithPromise(dispatch, motorActions.update, { hasVehicleDamages: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
