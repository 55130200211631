import { LANG_CODE_SWEDEN } from '../config/locale';
import { getLocaleFromUrl } from './getLocaleFromUrl';

const locale = getLocaleFromUrl();
const i18nIsoCountries = require('i18n-iso-countries');
const isSweden = locale && locale.language === LANG_CODE_SWEDEN;
let language: string;
if (isSweden) {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
    language = 'sv';
} else {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    language = 'en';
}
export const countryCodes = i18nIsoCountries.getAlpha2Codes();
export const countryNames = i18nIsoCountries.getNames(language);
