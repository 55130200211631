/**
 * Validate bank account number towards mod11.
 *
 * @param {String} number Bank account number
 * @returns {Boolean}
 */
export const mod11 = (number: string) => {
    let len = number.length,
        sum = 0,
        val;
    const weights = [1, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
    const arr = weights.splice(
        weights.length - len,
        weights.length - (weights.length - len)
    );
    while (len) {
        val = parseInt(number.charAt(--len), 10);
        sum += arr[len] * val;
    }
    return !!(sum && sum % 11 === 0);
};
