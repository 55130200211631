import { api } from '../../../utils/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { reportActions } from './report';
import { pollReportActions } from './pollReport';
import { actionWithPromise, emptyFn, FileModel, Rejectable, Resolvable } from '@protectorinsurance/ds-can';
import { select } from 'typed-redux-saga';
import { selectRequestId } from '../../selectors/commonSelectors';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum DeleteReportActionTypes {
    REQUEST = '@app/upload/report/delete/REQUEST',
    SUCCESS = '@app/upload/report/delete/SUCCESS',
    FAILURE = '@app/upload/report/delete/FAILURE',
}

/**
 * Action Definitions
 */
export interface DeleteReportAction {
    type: DeleteReportActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const deleteReportActions = {
    request: actionWithPromise(DeleteReportActionTypes.REQUEST),
    success: actionWithPromise(DeleteReportActionTypes.SUCCESS),
    failure: actionWithPromise(DeleteReportActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const deleteReportSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: DeleteReportAction) {
        try {
            const requestId = yield* select(selectRequestId);
            if (data && data.externalId) {
                yield call(api.delete, `upload/${data.externalId}`, {
                    baseURL: NODE_API_BASE_URL,
                    headers: { 'X-Request-Id': `${requestId}` },
                });
            }

            yield put(deleteReportActions.success());
            yield put(reportActions.remove(data));
            resolve();
        } catch (e) {
            yield put(pollReportActions.failure((e as any).response.data));
            reject();
        }
    },
};

/**
 * Saga Watchers
 */
export const deleteReportWatchers = function* () {
    yield takeLatest(DeleteReportActionTypes.REQUEST, deleteReportSagas.request);
};
