import { css } from '@emotion/react';
import { secondary } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const vehicleObjectCss = (company?: customCANProps) =>
    css({
        '& .center': {
            justifyContent: 'center',
        },

        '& .icon': {
            alignItems: 'center',
            backgroundColor: '#dedede',
            borderRadius: '50%',
            display: 'flex',
            height: 45,
            justifyContent: 'center',
            margin: '1rem 0',
            width: '45px !important',
        },

        '& .badge': {
            alignItems: 'center',
            borderColor: company ? company.color : secondary,
            color: company ? company.color : secondary,
            display: 'flex',
            justifyContent: 'center',
            padding: '0.35rem 0',
        },
    });
