import { createFileError } from '../../../models/File';
import { takeEvery } from 'redux-saga/effects';
import { selectClaimReportId, selectLoggedInClaimReport } from '../../selectors/reportSelectors';
import { createFormData } from '../../../utils/createFormData';
import { api, MULTI_PART_HEADER } from '../../../utils/api';
import { safetyStandardsActions } from './safetyStandards';
import { pollSafetyStandardsActions } from './pollSafetyStandards';
import { selectJWT, selectRequestId } from '../../selectors/commonSelectors';
import {
    actionWithPromise,
    emptyFn,
    FileModel,
    LogServiceModel,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { FileErrorTypes } from '../../../models/CANRequestError';
import { call, put, select } from 'typed-redux-saga';
import { logServiceActions } from '../../services/logService';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum UploadSafetyStandardsActionTypes {
    REQUEST = '@app/upload/safetyStandards/upload/REQUEST',
    SUCCESS = '@app/upload/safetyStandards/upload/SUCCESS',
    FAILURE = '@app/upload/safetyStandards/upload/FAILURE',
}

/**
 * Action Definitions
 */
export interface UploadSafetyStandardsAction {
    type: UploadSafetyStandardsActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const uploadSafetyStandardsActions = {
    request: actionWithPromise(UploadSafetyStandardsActionTypes.REQUEST),
    success: actionWithPromise(UploadSafetyStandardsActionTypes.SUCCESS),
    failure: actionWithPromise(UploadSafetyStandardsActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const uploadSafetyStandardsSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: UploadSafetyStandardsAction) {
        try {
            yield* put(safetyStandardsActions.add(data));

            const requestId = yield* select(selectRequestId);
            const submissionId = yield* select(selectClaimReportId);
            const loggedIn = yield* select(selectLoggedInClaimReport);
            const jwt = yield* select(selectJWT);
            const formData = createFormData(data, jwt);
            const config = {
                baseURL: NODE_API_BASE_URL,
                headers: {
                    ...MULTI_PART_HEADER,
                    'X-Request-Id': `${requestId}`,
                },
            };

            if (data.errors.length === 0) {
                let res: any;
                if (loggedIn) {
                    res = yield* call(api.post, `upload/auth/submission/${submissionId}/files`, formData, config);
                } else {
                    res = yield* call(api.post, `upload/submission/${submissionId}/files`, formData, config);
                }
                const externalId = res.headers.location.replace('/api/', '').replace('/meta', '');
                const file = { ...data, externalId };

                yield* put(safetyStandardsActions.update(file));
                yield* put(pollSafetyStandardsActions.request(file));
                yield* put(uploadSafetyStandardsActions.success());
                resolve();
            } else {
                const file = createFileError(data, UploadSafetyStandardsActionTypes.FAILURE, {
                    name: 'FileValidationError',
                    message: FileErrorTypes.UNSUPPORTED,
                });
                yield* put(safetyStandardsActions.update(file));
                yield* put(uploadSafetyStandardsActions.failure(file));
                reject();
            }
        } catch (e) {
            const file = createFileError(data, UploadSafetyStandardsActionTypes.FAILURE, e as any);
            if ((e as any).url?.includes('null')) {
                const requestId = yield* select(selectRequestId);
                const errorPayload: LogServiceModel = {
                    level: 'error',
                    message: `Missing UUID: Tried to upload file by name of ${file.name} in uploadSafetyStandards (SE). X-Request-Id=${requestId}`,
                };
                yield* put(logServiceActions.request(errorPayload));
            }
            yield* put(safetyStandardsActions.update(file));
            yield* put(uploadSafetyStandardsActions.failure(file));
            reject();
        }
    },
};

/**
 * Saga Watchers
 */
export const uploadSafetyStandardsWatchers = function* () {
    yield takeEvery(UploadSafetyStandardsActionTypes.REQUEST, uploadSafetyStandardsSagas.request);
};
