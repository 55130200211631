import { Nullable } from '../../../../../interfaces';

export interface LandlordInformationModel {
    email: Nullable<string>;
    name: Nullable<string>;
    phone: Nullable<string>;
}

export const initLandlordInformation: LandlordInformationModel = {
    email: null,
    name: null,
    phone: null,
};
