import { css } from '@emotion/react';
import { primary, secondary } from '../../variables';

export const inputLabelCss = css({
    fontWeight: 'bold',
    fontSize: '1rem',
    color: primary,
    padding: '0.5rem 0',
    textAlign: 'left',

    '& button': {
        background: 'transparent',
        border: 'none',
        marginRight: '0.5rem',
        marginTop: '-0.15rem',
        maxHeight: '30px',
        padding: 0,

        '&:focus': {
            outline: '0',
        },

        '&:hover': {
            outline: '0',

            '& svg': {
                '& path': {
                    stroke: secondary,
                },
            },
        },

        '& svg': {
            height: 35,
            width: 35,
        },
    },
});
