import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Clickable } from '@protectorinsurance/ds-can';

export const useGoBack = () => {
    const dispatch = useDispatch();
    return (e: Clickable) => {
        e.preventDefault();
        dispatch(goBack());
    };
};
