import React from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { selectCost } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import {
    CostModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { FormFieldNames } from '../../../config/formFieldNames';
import { costInformationSchema } from '../../../validations/schemas/costInformationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, COST_LABEL, COST_PLACEHOLDER, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } =
    PhraseKeys;
const { COST } = FormFieldNames;

/**
 * Page view and page logic
 */
export const ChangeOfOwnershipCostPage = () => {
    const dispatch = useDispatch();
    const cost = useSelector(selectCost);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.changeOfOwnership.cost');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<CostModel>({
        resolver: yupResolver(costInformationSchema(t)),
        defaultValues: {
            cost,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ cost }: CostModel) => {
        dispatcherWithPromise(dispatch, lpoActions.update, { cost })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardRouterActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={COST}
                        render={({ field: { ref, value, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.cost}
                                errorMessage={errors.cost?.message}
                                id={COST}
                                inputFieldWrapper={'col-12'}
                                label={t(COST_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(COST_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
