import { css } from '@emotion/core';

export const summaryDocumentItemCss = css({
    alignItems: 'center',
    display: 'flex',

    '&.padding': {
        padding: '10px',
    },

    '& svg': {
        height: '24px',
        marginRight: '10px',
        width: '20px',
    },
});
