import { put, select } from 'typed-redux-saga';
import { push } from 'connected-react-router';
import { BaseRoutePaths, BaseRoutePathsType } from 'config/wizardRouter/baseWizardRoutes';
import { wizardRoutes as config } from 'config/wizardRouter/wizardRoutes';
import { strReplace } from './strings/strReplace';
import { isSummary } from './isSummary';
import { wizardRouterActions } from '../sagas/wizardRouter';
import {
    selectWizardRouterBackToPrev,
    selectWizardRouterPrevious,
    selectWizardRouterSkipBackToPrev,
} from '../sagas/selectors/wizardRouterSelectors';
import { selectFlow } from '../sagas/selectors/flowSelectors';
import { selectRouterPathname } from '../sagas/selectors/routerSelectors';
import { selectLob, selectLocaleCountryCode, selectLocaleLanguageCode } from '../sagas/selectors/commonSelectors';
import { changeLocaleInPathname } from './locale/changeLocaleInPathname';
import { LobKeys, Nullable } from '@protectorinsurance/ds-can';
import { changeLocaleAndLobInPathname } from './locale/changeLocaleAndLobInPathname';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';
import { isDev } from './env';

/**
 * Destructure necessary imports
 */
const { CHANGE_OF_OWNERSHIP_SWE, LEGAL_EXPENCES, LIABILITY, PROPERTY } = LobKeys;

/**
 * Utility logic
 */
export const wizardRouter = {
    *goTo(value: MotorRoutePaths | PersonRoutePaths | BaseRoutePathsType): any {
        let path;
        const prev = yield* select(selectWizardRouterPrevious);
        const backToPrev = yield* select(selectWizardRouterBackToPrev);
        const skipBackToPrev = yield* select(selectWizardRouterSkipBackToPrev);
        const verifiedCaptcha = sessionStorage.getItem('verifiedProtectorCaptcha');

        if (isSummary(prev) && backToPrev && !skipBackToPrev) {
            path = yield prev;
        } else if (value === BaseRoutePaths.ERROR || value === BaseRoutePaths.INDEX) {
            path = yield value;
        } else {
            const flow = yield* select(selectFlow);
            const language = yield* select(selectLocaleLanguageCode);
            const lob = yield* select(selectLob);
            const find = [':flow', ':language', ':lob'];
            const replace = [flow ? flow : '', language ? language : '', lob ? lob.toString() : ''];

            path = yield strReplace(value, find, replace);
        }

        if (verifiedCaptcha || isDev()) {
            yield* put(push(path));
        } else {
            const language = yield* select(selectLocaleLanguageCode);
            const find = [':language'];
            const replace = [language ? language : ''];

            yield* put(push(strReplace(BaseRoutePaths.CAPTCHA, find, replace)));
        }

        // Reset skipBackToPrev
        if (skipBackToPrev) {
            yield* put(wizardRouterActions.skipBackToPrev(false));
        }
    },

    *gotoNext(value?: Nullable<MotorRoutePaths | PersonRoutePaths | BaseRoutePathsType> | number): any {
        const next = yield this.getNext(value);
        if (next) {
            yield this.goTo(next);
        }
    },

    *getNext(value?: Nullable<string> | number) {
        const country = yield* select(selectLocaleCountryCode);
        const path = yield* select(selectRouterPathname);
        const previous = yield* select(selectWizardRouterPrevious);
        const lob = yield* select(selectLob);
        let nextRoute = getNextRoute(country, path, previous, value);
        if (lob === CHANGE_OF_OWNERSHIP_SWE || lob === LEGAL_EXPENCES || lob === LIABILITY || lob === PROPERTY) {
            nextRoute = getNextRoute(country, path, previous, value, lob);
        }
        return nextRoute;
    },
};

export const getNextRoute = (
    country: string,
    path: string,
    prev: string,
    value?: Nullable<string> | number,
    lob?: LobKeys
) => {
    let dynPath = changeLocaleInPathname(path, ':language');
    if (lob) {
        dynPath = changeLocaleAndLobInPathname(path, ':language', ':lob');
    }

    let next = null;
    const current = config[country][dynPath];
    if (current) {
        if (value) {
            next = current[value];
        } else {
            next = current;
        }
    } else {
        next = prev;
    }

    return next;
};
