export enum PhraseKeys {
    // Buttons
    ADD_BUTTON = 'form.buttons.add',
    BACK_BUTTON = 'form.buttons.back',
    BACK_TO_HOMEPAGE_BUTTON = 'button.backToHomepage',
    CANCEL_BUTTON = 'form.buttons.cancel',
    CLOSE_BUTTON = 'form.buttons.close',
    COMPLETED_BUTTON = 'button.completed',
    CONFIRM_BUTTON = 'form.buttons.confirm',
    CONTINUE_BUTTON = 'form.buttons.continue',
    DELETE_BUTTON = 'button.delete',
    DONT_KNOW = 'generic.select.dontKnow',
    EDIT_BUTTON = 'button.edit',
    LOOKUP_BUTTON = 'button.lookup',
    SELECT_BUTTON = 'button.select',
    SELECT_NO = 'generic.select.no',
    SELECT_YES = 'generic.select.yes',
    SEND_BUTTON = 'form.buttons.send',
    SUBMITTING_BUTTON = 'form.buttons.submitting',
    RETRY_BUTTON = 'button.retry',
    LEFT_ARROW = 'generic.arrow.left',
    RIGHT_ARROW = 'generic.arrow.right',
    RESET_BUTTON = 'generic.buttons.reset',
    UNDO_BUTTON = 'generic.buttons.undo',

    // Page layout standards
    PAGE_NAME = 'pageName',
    TITLE = 'heading.title',
    SUB_TITLE = 'heading.sub',
    HELP_TEXT = 'help.bottomHelpText',
    LEFT = 'label.left',
    RIGHT = 'label.right',

    // Upload phrases
    DROPZONE_TITLE = 'dropzone.title',
    DROPZONE_DESCRIPTION = 'dropzone.description',

    // Autocomplete phrases
    NO_OPTIONS_MESSAGE = 'autoComplete.noOptionsMessage',

    // Field phrases
    ACCIDENT_DESCRIPTION_LABEL = 'form.fields.accidentDescription',
    ACCIDENT_SKETCH_LABEL = 'form.fields.accidentSketch',
    ACCIDENT_DESCRIPTION_PLACEHOLDER = 'form.fields.accidentDescriptionPlaceholder',
    ACCOUNT_NUMBER_LABEL = 'form.fields.accountNumber',
    ACCOUNT_NUMBER_PLACEHOLDER = 'form.fields.accountNumberPlaceholder',
    BANKGIRO_ACCOUNT_NUMBER_PLACEHOLDER = 'form.fields.bankgiroAccountNumberPlaceholder',
    PLUSGIRO_ACCOUNT_NUMBER_PLACEHOLDER = 'form.fields.plusgiroAccountNumberPlaceholder',
    ADD_BANK_ACCOUNT_LABEL = 'form.fields.addBankAccount',
    ADD_BANK_ACCOUNT_PLACEHOLDER = 'form.fields.addBankAccountPlaceholder',
    ASSOCIATION_LABEL = 'form.fields.association',
    ASSOCIATION_PLACEHOLDER = 'form.fields.associationPlaceholder',
    BANK_NAME_LABEL = 'form.fields.bankName',
    BANK_NAME_PLACEHOLDER = 'form.fields.bankNamePlaceholder',
    CHASSIS_NUMBER_LABEL = 'form.fields.chassisNumber',
    CHASSIS_NUMBER_PLACEHOLDER = 'form.fields.chassisNumberPlaceholder',
    CLEARING_NUMBER_LABEL = 'form.fields.clearingNumber',
    CLEARING_NUMBER_PLACEHOLDER = 'form.fields.clearingNumberPlaceholder',
    CITY_LABEL = 'form.fields.city',
    CITY_PLACEHOLDER = 'form.fields.cityPlaceholder',
    CLAIM_CAUSE_LABEL = 'form.fields.claimCause',
    CLAIM_CAUSE_PLACEHOLDER = 'form.fields.claimCausePlaceholder',
    CLAIM_DESCRIPTION_LABEL = 'form.fields.claimDescription',
    CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.claimDescriptionPlaceholder',
    CLAIM_TYPE_LABEL = 'form.fields.claimType',
    CLAIM_TYPE_PLACEHOLDER = 'form.fields.claimTypePlaceholder',
    COMPANY_NAME_LABEL = 'form.fields.companyInformation.name',
    COMPANY_NAME_PLACEHOLDER = 'form.fields.companyInformation.namePlaceholder',
    COMPANY_BUSINESS_NUMBER_LABEL = 'form.fields.companyInformation.businessNumber',
    COMPANY_BUSINESS_NUMBER_PLACEHOLDER = 'form.fields.companyInformation.businessNumberPlaceholder',
    COST_LABEL = 'form.fields.cost',
    COST_PLACEHOLDER = 'form.fields.costPlaceholder',
    COUNTRY_LABEL = 'form.fields.country',
    COUNTRY_PLACEHOLDER = 'form.fields.countryPlaceholder',
    DAMAGE_DESCRIPTION_LABEL = 'form.fields.damageDescriptionLabel',
    DAMAGE_DESCRIPTION_PLACEHOLDER = 'form.fields.damageDescriptionPlaceholder',
    DAMAGED_ITEM_LABEL = 'form.fields.damagedItem',
    DAMAGED_ITEM_PLACEHOLDER = 'form.fields.damagedItemPlaceholder',
    DENTIST_NAME_LABEL = 'form.fields.dentist',
    DENTIST_NAME_PLACEHOLDER = 'form.fields.dentistPlaceholder',
    DIAGNOSIS_LABEL = 'form.fields.diagnosis',
    DIAGNOSIS_PLACEHOLDER = 'form.fields.diagnosisPlaceholder',
    DISTANCE_FROM_ROAD_SIDE_LABEL = 'form.fields.distanceFromRoadSide',
    DISTANCE_FROM_ROAD_SIDE_PLACEHOLDER = 'form.fields.distanceFromRoadSidePlaceholder',
    DITCH_CLAIM_DESCRIPTION_LABEL = 'form.fields.ditchClaimDescription',
    DITCH_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.ditchClaimDescriptionPlaceholder',
    DRIVING_SPEED_LABEL = 'form.fields.drivingSpeed',
    DRIVING_SPEED_PLACEHOLDER = 'form.fields.drivingSpeedPlaceholder',
    ENGINE_CLAIM_DESCRIPTION_LABEL = 'form.fields.engineClaimDescription',
    ENGINE_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.engineClaimDescriptionPlaceholder',
    EMAIL_LABEL = 'form.fields.email',
    EMAIL_PLACEHOLDER = 'form.fields.emailPlaceholder',
    END_DATE_LABEL = 'form.fields.endDate',
    DRIVER_MISSING_SSN_LABEL = 'form.fields.driverMissingSSN',
    DRIVER_IS_UNKNOWN_LABEL = 'form.fields.driverIsUnknown',
    FIRE_CLAIM_DESCRIPTION_LABEL = 'form.fields.fireClaimDescription',
    FIRE_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.fireClaimDescriptionPlaceholder',
    FIRST_NAME_LABEL = 'form.fields.firstName',
    FIRST_NAME_PLACEHOLDER = 'form.fields.firstNamePlaceholder',
    FREIGHT_WEIGHT_LABEL = 'form.fields.freightWeight',
    FREIGHT_WEIGHT_PLACEHOLDER = 'form.fields.freightWeightPlaceholder',
    HOSPITAL_NAME_LABEL = 'form.fields.hospitalName',
    HOSPITAL_NAME_PLACEHOLDER = 'form.fields.hospitalNamePlaceholder',
    IS_COMPANY_LABEL = 'form.fields.isCompany',
    IS_DRIVER_LABEL = 'form.fields.isDriver',
    IS_LIABILITY_COMPANY_LABEL = 'form.fields.isLiabilityCompany',
    IS_REPORTER_LABEL = 'form.fields.isReporter',
    IS_CLAIMANT_LABEL = 'form.fields.isClaimant',
    IS_SELLER_LABEL = 'form.fields.isSeller',
    LAST_NAME_LABEL = 'form.fields.lastName',
    LAST_NAME_PLACEHOLDER = 'form.fields.lastNamePlaceholder',
    LIABILITY_CLAIM_DESCRIPTION_LABEL = 'form.fields.liabilityClaimDescription',
    LIABILITY_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.liabilityClaimDescriptionPlaceholder',
    MAKE_LABEL = 'form.fields.makeLabel',
    MAKE_PLACEHOLDER = 'form.fields.makePlaceholder',
    MAKE_MODEL_LABEL = 'form.fields.type',
    MAKE_MODEL_PLACEHOLDER = 'form.fields.typePlaceholder',
    MEDICAL_INSTITUTION_LABEL = 'form.fields.medicalInstitution',
    MEDICAL_INSTITUTION_PLACEHOLDER = 'form.fields.medicalInstitutionPlaceholder',
    MILEAGE_LABEL = 'form.fields.mileage',
    MILEAGE_PLACEHOLDER = 'form.fields.mileagePlaceholder',
    MISSING_ITEMS_LABEL = 'form.fields.missingItems',
    MISSING_ITEMS_PLACEHOLDER = 'form.fields.missingItemsPlaceholder',
    MISSING_SSN_LABEL = 'form.fields.missingSSN',
    MODEL_LABEL = 'form.fields.model',
    MODEL_PLACEHOLDER = 'form.fields.modelPlaceholder',
    MUNICIPALITY_LABEL = 'form.fields.municipality',
    MUNICIPALITY_PLACEHOLDER = 'form.fields.municipalityPlaceholder',
    NAME_LABEL = 'form.fields.name',
    NAME_PLACEHOLDER = 'form.fields.namePlaceholder',
    NATIONAL_IDENTITY_LABEL = 'form.fields.nationalIdentity',
    NATIONAL_IDENTITY_PLACEHOLDER = 'form.fields.nationalIdentityPlaceholder',
    NOTE_LABEL = 'form.fields.note',
    NO_AGENT_LABEL = 'form.fields.noAgent',
    OTHER_ID_LABEL = 'form.fields.otherId',
    OTHER_ID_PLACEHOLDER = 'form.fields.otherIdPlaceholder',
    OTHER_INSURANCE_COMPANY_LABEL = 'form.fields.otherInsuranceCompany',
    OTHER_INSURANCE_COMPANY_PLACEHOLDER = 'form.fields.otherInsuranceCompanyPlaceholder',
    OVERLAPPING_HOSPITALISATION_PERIODS = 'form.validation.string.overlappingHospitalisationPeriods',
    OWNER_FAMILY_NAME_LABEL = 'form.fields.ownerFamilyName',
    OWNER_FAMILY_NAME_PLACEHOLDER = 'form.fields.ownerFamilyNamePlaceholder',
    OWNER_GIVEN_NAME_LABEL = 'form.fields.ownerGivenName',
    OWNER_GIVEN_NAME_PLACEHOLDER = 'form.fields.ownerGivenNamePlaceholder',
    OTHER_MISSING_ITEMS_LABEL = 'form.fields.otherMissingItems',
    OTHER_MISSING_ITEMS_PLACEHOLDER = 'form.fields.otherMissingItemsPlaceholder',
    PHONE_LABEL = 'form.fields.phone',
    PHONE_PLACEHOLDER = 'form.fields.phonePlaceholder',
    POLICE_CASE_NUMBER_LABEL = 'form.fields.policeCaseNumber',
    POLICE_CASE_NUMBER_PLACEHOLDER = 'form.fields.policeCaseNumberPlaceholder',
    POLICY_NUMBER_LABEL = 'form.fields.policyNumber',
    POLICY_NUMBER_PLACEHOLDER = 'form.fields.policyNumberPlaceholder',
    PROPERTY_ADDRESS_LABEL = 'form.fields.propertyAddress',
    PROPERTY_ADDRESS_PLACEHOLDER = 'form.fields.propertyAddressPlaceholder',
    PROPERTY_UNIT_LABEL = 'form.fields.propertyUnit',
    PROPERTY_UNIT_PLACEHOLDER = 'form.fields.propertyUnitPlaceholder',
    EXTERNAL_REFERENCE_LABEL = 'form.fields.externalReference',
    EXTERNAL_REFERENCE_PLACEHOLDER = 'form.fields.externalReferencePlaceholder',
    REASON_LABEL = 'form.fields.reason',
    REASON_PLACEHOLDER = 'form.fields.reasonPlaceholder',
    REG_NR_LABEL = 'form.fields.registrationNumber',
    REG_NR_PLACEHOLDER = 'form.fields.registrationNumberPlaceholder',
    REPORTED_TO_INSURERS_INSURANCE_LABEL = 'form.fields.reportedToInsurersInsurance',
    REPORTED_TO_INSURERS_INSURANCE_PLACEHOLDER = 'form.fields.reportedToInsurersInsurancePlaceholder',
    REVERSING_CLAIM_DESCRIPTION_LABEL = 'form.fields.reversingClaimDescription',
    REVERSING_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.reversingClaimDescriptionPlaceholder',
    ROAD_CONDITION_DESCRIPTION_LABEL = 'form.fields.roadConditionDescription',
    ROAD_CONDITION_DESCRIPTION_PLACEHOLDER = 'form.fields.roadConditionDescriptionPlaceholder',
    ROAD_WIDTH_LABEL = 'form.fields.roadWidth',
    ROAD_WIDTH_PLACEHOLDER = 'form.fields.roadWidthPlaceholder',
    SCHOOL_NAME_LABEL = 'form.fields.schoolInformation.name',
    SCHOOL_NAME_PLACEHOLDER = 'form.fields.schoolInformation.namePlaceholder',
    SELECT_ASSOCIATION_LABEL = 'form.fields.select.association',
    SELECT_ASSOCIATION_PLACEHOLDER = 'form.fields.select.associationPlaceholder',
    SPEED_LIMIT_LABEL = 'form.fields.speedLimit',
    SPEED_LIMIT_PLACEHOLDER = 'form.fields.speedLimitPlaceholder',
    SPEED_ON_IMPACT_LABEL = 'form.fields.speedOnImpact',
    SPEED_ON_IMPACT_PLACEHOLDER = 'form.fields.speedOnImpactPlaceholder',
    START_DATE_LABEL = 'form.fields.startDate',
    STREET_LABEL = 'form.fields.street',
    STREET_PLACEHOLDER = 'form.fields.streetPlaceholder',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION_LABEL = 'form.fields.theftAndDamagesClaimDescription',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.theftAndDamagesClaimDescriptionPlaceholder',
    TIME_SINCE_ACTION_LABEL = 'form.fields.timeSinceAction',
    TIME_SINCE_ACTION_PLACEHOLDER = 'form.fields.timeSinceActionPlaceholder',
    TYPE_LABEL = 'form.fields.type',
    TYPE_PLACEHOLDER = 'form.fields.typePlaceholder',
    TYPE_OF_PROPERTY_LABEL = 'form.fields.typeOfProperty',
    TYPE_OF_PROPERTY_PLACEHOLDER = 'form.fields.typeOfPropertyPlaceholder',
    TYPE_OF_SPORT_LABEL = 'form.fields.typeOfSport',
    TYPE_OF_SPORT_PLACEHOLDER = 'form.fields.typeOfSportPlaceholder',
    VEHICLE_TYPE_LABEL = 'form.fields.vehicleType',
    VEHICLE_TYPE_PLACEHOLDER = 'form.fields.vehicleTypePlaceholder',
    VEHICLE_INTERNAL_REFERENCE_NUMBER_LABEL = 'form.fields.vehicleInternalReferenceNumber',
    VEHICLE_INTERNAL_REFERENCE_NUMBER_PLACEHOLDER = 'form.fields.vehicleInternalReferenceNumberPlaceholder',
    WEEKS_OF_PLASTER_TREATMENT_LABEL = 'form.fields.weeksOfPlasterTreatment',
    WEEKS_OF_PLASTER_TREATMENT_PLACEHOLDER = 'form.fields.weeksOfPlasterTreatment',
    WHO_RESPONSIBLE_LABEL = 'form.fields.whoResponsible',
    WHO_RESPONSIBLE_PLACEHOLDER = 'form.fields.whoResponsiblePlaceholder',
    ZIP_LABEL = 'form.fields.zip',
    ZIP_PLACEHOLDER = 'form.fields.zipPlaceholder',

    // Status text
    BAD_REQUEST = 'statusText.badRequest',
    INTERNAL_SERVER_ERROR = 'statusText.internalServerError',
}
