import { css } from '@emotion/react';
import { primary, primaryHover, white } from '../../variables';

export const primaryButtonCss = css({
    background: primary,
    borderColor: primary,
    color: white,
    transition: 'all 200ms ease-in-out',

    '&:hover, &:focus': {
        background: primaryHover,
        borderColor: primaryHover,
        boxShadow: '0 0 0 0.2rem' + primaryHover,
    },

    '&.grid': {
        height: 56,
        textTransform: 'uppercase',
    },

    '&.no-max-width': {
        width: 'unset',
    },
});
