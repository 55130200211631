import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import { selectSafetyStandardsFiles } from '../../../../sagas/selectors/uploadSelectors';
import { Clickable, FileModel, FileUploader, PageLayout } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { uploadSafetyStandardsActions } from '../../../../sagas/upload/safetyStandard/uploadSafetyStandards';
import { deleteSafetyStandardsActions } from '../../../../sagas/upload/safetyStandard/deleteSafetyStandards';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, DROPZONE_DESCRIPTION, DROPZONE_TITLE, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } =
    PhraseKeys;

/**
 * Page view and page logic
 */
export const PFUploadFireSafetyReportPage = () => {
    const dispatch = useDispatch();
    const fileStore = useSelector(selectSafetyStandardsFiles);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.uploadFireSafetyReport');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleOnSuccess = (files: FileModel[]) => {
        files.forEach((file) => dispatch(uploadSafetyStandardsActions.request(file)));
    };

    const handleDelete = (e: Clickable, file: FileModel) => {
        e.preventDefault();
        dispatch(deleteSafetyStandardsActions.request(file));
    };

    const checkFileErrors: boolean = fileStore.some((file) => {
        return file.errors.length > 0;
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={checkFileErrors}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <FileUploader
                onSuccess={handleOnSuccess}
                onDelete={handleDelete}
                text={tWithNS.t('upload.label')}
                title={tWithNS.t(DROPZONE_TITLE)}
                label={tWithNS.t(DROPZONE_DESCRIPTION)}
                fileStore={fileStore}
            />
        </PageLayout>
    );
};
