import { Nullable } from '@protectorinsurance/ds-can';

interface FormatPhoneAndEmailOptions {
    template?: string;
    separator?: string;
}

export const formatPhoneAndEmail = (
    phone?: Nullable<string>,
    email?: Nullable<string>,
    options?: FormatPhoneAndEmailOptions
) => {
    const config = { template: '%s', separator: ', ', ...options };
    let output;
    if (phone && email) {
        output = `${phone}${config.separator}${email}`;
    } else {
        output = phone ? `${phone}` : email ? `${email}` : '';
    }

    return output === '' ? output : config.template.replace('%s', output);
};
