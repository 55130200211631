/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Clickable } from '../../../interfaces/types/Events.types';
import { PersonModel } from '../../../interfaces/models/Person.model';
import { summaryPersonListCss } from './SummaryPersonList.style';
import { Grid } from '../grid/Grid';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';

interface SummaryPersonListProps {
    list: PersonModel[];
    textKey: string;
    path: string;
    dataTestId: string;
    className: string;
    printSummary: boolean;
    handleClick?: (e: Clickable, path: string) => void;
}
export const SummaryPersonList = ({
    list,
    path,
    textKey,
    dataTestId,
    className,
    printSummary = false,
    handleClick,
}: SummaryPersonListProps) => {
    return (
        <div className={className}>
            <SummaryText text={textKey} />
            {list.map((item, idx) => {
                return (
                    <div
                        key={idx}
                        className={'persons-list'}
                        css={css(summaryPersonListCss)}
                    >
                        {!handleClick || printSummary ? (
                            <span data-hj-suppress tabIndex={0}>
                                <Grid>
                                    <div
                                        className={
                                            'col-4 spacing text-align-left'
                                        }
                                    >
                                        {item.firstName} {item.lastName}
                                    </div>
                                    <div
                                        className={
                                            'col-8 spacing text-align-left'
                                        }
                                    >
                                        {item.email}
                                    </div>
                                    <div
                                        className={
                                            'col-12 spacing text-align-left'
                                        }
                                    >
                                        {item.phone}
                                    </div>
                                    <div
                                        className={
                                            'col-12 divider text-align-left'
                                        }
                                    >
                                        {item.street ? `${item.street} ` : ''}
                                        {item.zip ? `${item.zip} ` : ''}
                                        {item.city ? `${item.city}` : ''}
                                    </div>
                                </Grid>
                            </span>
                        ) : (
                            <button
                                type={'button'}
                                data-testid={dataTestId}
                                onClick={(e) => handleClick(e, path)}
                                data-hj-suppress
                            >
                                <Grid>
                                    <div className={'col-4'}>
                                        <p>
                                            {item.firstName} {item.lastName}
                                        </p>
                                    </div>
                                    <div className={'col-5'}>
                                        <p>{item.email}</p>
                                    </div>
                                    <div className={'col-3'}>
                                        <p>{item.phone}</p>
                                    </div>
                                    <div className={'col-12 padding-top'}>
                                        {item.street ? `${item.street} ` : ''}
                                        {item.zip ? `${item.zip} ` : ''}
                                        {item.city ? `${item.city}` : ''}
                                    </div>
                                </Grid>
                            </button>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
