import { Nullable } from '../../../../../interfaces';

export enum RelationshipWithDeceasedKeys {
    NEXT_OF_KIN = 'NEXT_OF_KIN',
    EMPLOYER = 'EMPLOYER',
    OTHER = 'OTHER',
}

export type RelationshipWithDeceasedModel =
    Nullable<RelationshipWithDeceasedKeys>;
