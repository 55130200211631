/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { FC } from 'react';
import { carouselCss } from './Carousel.style';

interface CarouselProps {
    arrowLeftLabel?: string;
    arrowRightLabel?: string;
}

export const Carousel: FC<CarouselProps> = ({ children }) => {
    return (
        <div className={'carousel'} css={css(carouselCss)}>
            {children}
        </div>
    );
};
