import React, { forwardRef, HTMLProps } from 'react';
import { QuestionMarkIcon } from '@protectorinsurance/ds-can';
import { helpToolTipCss } from './HelpToolTip.style';
import { Tooltip } from '@mui/material';

const HelpInformation = forwardRef(function HelpInformation(
    props: HTMLProps<HTMLDivElement>,
    ref: string | ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined
) {
    //  Spread the props to the underlying DOM element.
    return (
        <div {...props} ref={ref}>
            <QuestionMarkIcon wh={40} />
        </div>
    );
});

interface HelpToolTipProps {
    title: string;
}

export const HelpToolTip = ({ title }: HelpToolTipProps) => {
    const { toolTip } = helpToolTipCss();

    return (
        <Tooltip {...{ title }} className={toolTip} tabIndex={0} arrow={true} aria-label={title}>
            <HelpInformation />
        </Tooltip>
    );
};
