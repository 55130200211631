/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { emptyFn } from '../../../utils';
import { pageCss } from './PageLayout.style';
import { PageHeader } from '../../molecules/pageHeader/PageHeader';
import { PageFooter } from '../../molecules/pageFooter/PageFooter';
import { PageLayoutProps } from './PageLayout.types';

/**
 * Component view and component logic
 */
export const PageLayout = ({
    backBtnAriaLabel,
    backBtnText,
    children,
    continueBtnAriaLabel,
    continueBtnText,
    disableBackButton = false,
    disableContinueButton = false,
    domainTitle,
    footerComponent,
    footerText,
    handleBackButton = emptyFn,
    handleContinueButton = emptyFn,
    headerSubTitle,
    headerTitle,
    pageClassName = '',
    pageContentClassName = '',
    showBackButton = true,
    showContinueButton = true,
}: PageLayoutProps) => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    {headerTitle} {headerSubTitle} - {domainTitle}
                </title>
            </Helmet>
            <div className={`page ${pageClassName}`} css={css(pageCss)}>
                <div className={'page-header no-print'}>
                    <PageHeader
                        mainTitle={headerTitle}
                        subTitle={headerSubTitle}
                    />
                </div>
                <div className={`page-content-wrapper ${pageContentClassName}`}>
                    {children}
                </div>
                <div className={'page-footer no-print'}>
                    <PageFooter
                        {...{
                            backBtnAriaLabel,
                            backBtnText,
                            continueBtnAriaLabel,
                            continueBtnText,
                            disableBackButton,
                            disableContinueButton,
                            footerComponent,
                            footerText,
                            handleBackButton,
                            handleContinueButton,
                            showBackButton,
                            showContinueButton,
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
};
