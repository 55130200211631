import React from 'react';
import { useI18n } from '../../hooks/useI18n';
import './FeedbackModal.scss';
import { Clickable, emptyFn, StandardModal } from '@protectorinsurance/ds-can';

interface FeedbackModalProps {
    onClose?: (e: Clickable) => void;
    onConfirm?: (e: Clickable) => void;
}

export const FeedbackModal = ({ onClose = emptyFn, onConfirm = emptyFn }: FeedbackModalProps) => {
    const { t } = useI18n();

    return (
        <StandardModal
            title={t('feedback.modal.title')}
            onClose={onClose}
            onConfirm={onConfirm}
            className={'center'}
            showCloseButton={true}
        >
            <div className="dialog__content feedback-modal" data-testid={'feedbackModal'}>
                <div
                    className={'netigate__inject-html'}
                    dangerouslySetInnerHTML={{ __html: t('netigate.inject.html') }}
                />
            </div>
        </StandardModal>
    );
};
