import { useI18n } from './useI18n';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppState } from '../store';
import { CarouselConfigLookupResponse } from '@protectorinsurance/ds-can';
import { carouselConfigLookup } from '../utils/carouselPageLookup';
import { selectLob } from '../sagas/selectors/commonSelectors';

export const useCarouselPage = (): CarouselConfigLookupResponse | false => {
    const { flow, slug } = useParams<{ flow: string; slug: string }>();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const { t } = useI18n();
    const state = useSelector((state: AppState) => state);
    const lob = useSelector(selectLob);

    const carousel = carouselConfigLookup({
        slug: flow ? [flow, slug].join('/') : slug,
        t,
        dispatch,
        state,
        lob,
    });

    if (!carousel) {
        push('/error');

        return false;
    }

    return carousel;
};
