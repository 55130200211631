import { Nullable } from '../../../../../interfaces/types';

export enum UnidentifiedTypeKeys {
    FOREIGN_VEHICLE = 'FOREIGN_VEHICLE',
    UNREGISTERED_VEHICLE = 'UNREGISTERED_VEHICLE',
    OTHER = 'OTHER',
}

export interface UnidentifiedTypeModel {
    unidentifiedType: UnidentifiedTypeTypeModel;
}
export type UnidentifiedTypeTypeModel = Nullable<UnidentifiedTypeKeys>;
