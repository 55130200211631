import React, { useRef, useState } from 'react';
import {
    SESSION_TIMEOUT_DEBOUNCE_MS,
    SESSION_TIMEOUT_MS,
    SESSION_TIMEOUT_WARNING_MS,
} from '../../../config/sessionTimeout/sessionTimeout';
import { Nullable } from '../../../interfaces';
import { getLocaleFromUrl } from '../../../modules';
import IdleTimer from 'react-idle-timer';
import { TimeoutWarningModal } from '../modals/TimeoutWarningModal';
import { TimeoutModal } from '../modals/TimeoutModal';

export interface SessionTimeoutTrackerProps {
    handleCallback: () => void;
    sessionTimeoutTitle: string;
    sessionTimeoutText: string;
    sessionTimeoutButton: string;
    sessionTimeoutWarningTitle: string;
    sessionTimeoutWarningText: string;
    sessionTimeoutWarningButton: string;
    sessionTimeoutDebounceMs?: number;
    sessionTimeoutMs?: number;
    sessionTimeoutWarningMs?: number;
}

export const SessionTimeoutTracker = ({
    handleCallback,
    sessionTimeoutTitle,
    sessionTimeoutButton,
    sessionTimeoutText,
    sessionTimeoutWarningTitle,
    sessionTimeoutWarningText,
    sessionTimeoutWarningButton,
    sessionTimeoutDebounceMs = SESSION_TIMEOUT_DEBOUNCE_MS,
    sessionTimeoutMs = SESSION_TIMEOUT_MS,
    sessionTimeoutWarningMs = SESSION_TIMEOUT_WARNING_MS,
}: SessionTimeoutTrackerProps) => {
    let idleTimerId: Nullable<IdleTimer> = null;
    const timeoutTimerId = useRef<number>();
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [isTimeoutOpen, setIsTimeoutOpen] = useState(false);

    /**
     * Handlers
     */
    const handleClose = () => setIsWarningOpen(false);
    const handleOpen = () => setIsWarningOpen(true);

    const handleTimeout = () => {
        const locale = getLocaleFromUrl();

        // Close modal
        setIsTimeoutOpen(false);

        if (locale) {
            window.location.href = `/nor/${locale.language}`;
        }
    };

    const handleWarning = () => {
        handleCallback();
        handleClose();

        if (timeoutTimerId) {
            window.clearTimeout(timeoutTimerId.current);
        }

        if (idleTimerId) {
            idleTimerId.reset();
        }
    };

    const handleOnIdle = () => {
        handleOpen();

        timeoutTimerId.current = window.setTimeout(() => {
            setIsWarningOpen(false);
            setIsTimeoutOpen(true);
        }, sessionTimeoutWarningMs);
    };

    return (
        <>
            <IdleTimer
                ref={(ref: IdleTimer) => (idleTimerId = ref)}
                element={document}
                timeout={sessionTimeoutMs}
                debounce={sessionTimeoutDebounceMs}
                onIdle={handleOnIdle}
                stopOnIdle={true}
            />
            {isWarningOpen && (
                <TimeoutWarningModal
                    title={sessionTimeoutWarningTitle}
                    confirmButtonText={sessionTimeoutWarningButton}
                    sessionTimeoutWarningText={sessionTimeoutWarningText}
                    onClose={handleClose}
                    onConfirm={handleWarning}
                />
            )}
            {isTimeoutOpen && (
                <TimeoutModal
                    title={sessionTimeoutTitle}
                    confirmButtonText={sessionTimeoutButton}
                    sessionTimeoutText={sessionTimeoutText}
                    onClose={handleClose}
                    onConfirm={handleTimeout}
                />
            )}
        </>
    );
};

export default SessionTimeoutTracker;
