import { css } from '@emotion/react';
import {
    primary,
    primaryHover,
    secondary,
    secondaryLight,
    white,
} from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const secondaryButtonCss = (company?: customCANProps) =>
    css({
        background: white,
        borderColor: primary,
        color: primary,
        transition: 'all 200ms ease-in-out',

        '&:hover, &:focus': {
            background: primary,
            borderColor: primary,
            color: white,
            boxShadow: '0 0 0 0.2rem' + primary,
        },

        '&.active': {
            background: primary,
            color: white,
        },

        '&.active:hover, &.active:focus': {
            background: primaryHover,
            borderColor: primaryHover,
        },

        '&.active-secondary': {
            background: company ? company.color : secondary,
            borderColor: company ? company.color : secondary,
            color: company ? company.textColor : white,
        },

        '&.active-secondary:hover, &.active-secondary:focus': {
            background: company ? company.colorFaded : secondaryLight,
            borderColor: company ? company.colorFaded : secondaryLight,
        },
    });
