import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { reasonSchema } from 'validations/fieldSchemas/reasonSchema';
import { companyNameSchema } from '../fieldSchemas/companyNameSchema';
import { optionalBusinessNumberSchema } from '../fieldSchemas/optionalBusinessNumberSchema';

/**
 * Validation logic
 */
export const contractorInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...companyNameSchema(t),
        ...optionalBusinessNumberSchema(t),
        ...reasonSchema(t),
    });
};
