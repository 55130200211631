import { css } from '@emotion/react';
import { primary, secondary, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const summaryVehicleItemCss = (company?: customCANProps) =>
    css({
        '& .registration-number': {
            borderRadius: 2,
            boxShadow: '0 0 5px rgba(68, 85, 98, 0.5)',
            maxWidth: 150,
            padding: '0.5rem 1rem',
            textAlign: 'center',
        },

        '& .vehicle-information': {
            paddingTop: '1rem',
            textAlign: 'left',

            '@media print, screen and (min-width: 768px)': {
                paddingTop: 'unset',
            },
        },

        '& .other-vehicle-damages': {
            border: `1px solid ${primary}`,

            '& .damages-list': {
                '& .damages-item': {
                    margin: '0.5rem 0',
                    textAlign: 'left',

                    '& span': {
                        alignItems: 'center',
                        backgroundColor: company ? company.color : secondary,
                        borderRadius: '50%',
                        color: white,
                        display: 'inline-flex',
                        height: 25,
                        justifyContent: 'center',
                        marginRight: '0.25rem',
                        width: 25,
                    },

                    '& p': {
                        display: 'inline',
                    },
                },
            },
        },
    });
