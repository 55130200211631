import { iterateEnum } from './iterateEnum';

type EnumType<T = string> = { [s: string]: T };

interface IFn<T, R> {
    (m: T): R;
}

export const mapEnum = <T = string, R = T>(
    enumerable: EnumType<T>,
    fn: IFn<T, R>
): R[] => {
    // now map through the enum values
    return iterateEnum<T>(enumerable).map((m: T) => fn(m));
};
