import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useGoBack } from '../../../../../hooks/useGoBack';
import { useI18n } from '../../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../../config/phraseKeys';
import { FormFieldNames } from '../../../../../config/formFieldNames';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    ReversingClaimDescriptionModel,
} from '@protectorinsurance/ds-can';
import { selectReversingClaimDescription } from '../../../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../../../sagas/motor';
import { reversingClaimDescriptionSchema } from '../../../../../validations/schemas/reversingClaimDescriptionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { claimDescriptionUtil } from '../../../../../utils/claimDescriptionUtil';
import { selectCustomCAN } from '../../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    REVERSING_CLAIM_DESCRIPTION_LABEL,
    REVERSING_CLAIM_DESCRIPTION_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REVERSING_CLAIM_DESCRIPTION } = FormFieldNames;

/**
 * Page view and page logic
 */
export const DVReversingClaimDescriptionPage = () => {
    const dispatch = useDispatch();
    const reversingClaimDescription = useSelector(selectReversingClaimDescription);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.reversingClaimDescription');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ReversingClaimDescriptionModel>({
        resolver: yupResolver(reversingClaimDescriptionSchema(t, REVERSING_CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            reversingClaimDescription,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ reversingClaimDescription }: ReversingClaimDescriptionModel, e?: FormChangeable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { reversingClaimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid>
                    <Controller
                        control={control}
                        name={REVERSING_CLAIM_DESCRIPTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.reversingClaimDescription}
                                errorMessage={errors.reversingClaimDescription?.message}
                                id={REVERSING_CLAIM_DESCRIPTION}
                                inputFieldWrapper={'col-12'}
                                label={t(REVERSING_CLAIM_DESCRIPTION_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(REVERSING_CLAIM_DESCRIPTION_PLACEHOLDER)}
                                reference={ref}
                                rows={10}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
