import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { accidentClaimReporterRolePage } from './accident/claimReporterRole';
import { accidentInsuranceTypePage } from './accident/insuranceType';
import { accidentClaimLocationPage } from './accident/claimLocation';
import { accidentClaimTimeOfDayPage } from './accident/claimTimeOfDay';
import { accidentIsVehicleInvolvedPage } from './accident/isVehicleInvolved';
import { accidentHappenDomesticallyPage } from './accident/happenDomestically';
import { accidentIsVehicleMotorcyclePage } from './accident/isVehicleMotorcycle';
import { accidentPersonInVehiclePage } from './accident/personInVehicle';
import { accidentHasLicensePage } from './accident/hasLicense';
import { accidentHasHelmetPage } from './accident/hasHelmet';
import { accidentDuringSportPage } from './accident/duringSport';
import { accidentClaimCausePage } from './accident/claimCause';
import { accidentPreviousInjuryPage } from './accident/previousInjury';
import { accidentIsDoctorContactedPage } from './accident/isDoctorContacted';
import { accidentIsDentistContactedPage } from './accident/isDentistContacted';
import { accidentFinishedTreatmentPage } from './accident/finishedTreatment';
import { accidentInvalidityPage } from './accident/invalidity';
import { accidentHasExpensesPage } from './accident/hasExpenses';
import { accidentNeedTransportPage } from './accident/needTransport';
import { accidentInElementarySchoolPage } from './accident/inElementarySchool';
import { accidentHasOtherExpensesPage } from './accident/hasOtherExpenses';
import { accidentHasOtherInsurancePage } from './accident/hasOtherInsurance';
import { accidentHasAttachmentPage } from './accident/hasAttachment';
import { illnessClaimReporterRolePage } from './illness/claimReporterRole';
import { illnessInsuranceTypePage } from './illness/insuranceType';
import { illnessHospitalisedPage } from './illness/hospitalised';
import { illnessPreviousIllnessPage } from './illness/previousIllness';
import { illnessHasOtherInsurancePage } from './illness/hasOtherInsurance';
import { illnessHasAttachmentPage } from './illness/hasAttachment';
import { deathClaimantAgeCategoryPage } from './death/claimantAgeCategory';
import { deathClaimReporterRolePage } from './death/claimReporterRole';
import { deathInsuranceTypePage } from './death/insuranceType';
import { deathIsAccidentPage } from './death/isAccident';
import { deathClaimLocationPage } from './death/claimLocation';
import { deathIsVehicleInvolvedPage } from './death/isVehicleInvolved';
import { deathClaimantMaritalStatusPage } from './death/claimantMaritalStatus';
import { deathIsEstateExecutorPage } from './death/isEstateExecutor';
import { accidentClaimReporterPage } from './accident/claimReporter';
import { accidentTypeOfMedicalInstitutionPage } from './accident/typeOfMedicalInstitution';
import { accidentHasPlasterTreatmentPage } from './accident/hasPlasterTreatment';

/**
 * Config logic
 */
export const personCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Accident
    accidentClaimReporterRolePage(props),
    accidentClaimReporterPage(props),
    accidentInsuranceTypePage(props),
    accidentClaimLocationPage(props),
    accidentClaimTimeOfDayPage(props),
    accidentIsVehicleInvolvedPage(props),
    accidentHappenDomesticallyPage(props),
    accidentIsVehicleMotorcyclePage(props),
    accidentPersonInVehiclePage(props),
    accidentHasLicensePage(props),
    accidentHasHelmetPage(props),
    accidentDuringSportPage(props),
    accidentClaimCausePage(props),
    accidentHasPlasterTreatmentPage(props),
    accidentPreviousInjuryPage(props),
    accidentIsDoctorContactedPage(props),
    accidentIsDentistContactedPage(props),
    accidentTypeOfMedicalInstitutionPage(props),
    accidentFinishedTreatmentPage(props),
    accidentInvalidityPage(props),
    accidentHasExpensesPage(props),
    accidentNeedTransportPage(props),
    accidentInElementarySchoolPage(props),
    accidentHasOtherExpensesPage(props),
    accidentHasOtherInsurancePage(props),
    accidentHasAttachmentPage(props),

    // Death
    deathClaimantAgeCategoryPage(props),
    deathClaimReporterRolePage(props),
    deathInsuranceTypePage(props),
    deathIsAccidentPage(props),
    deathClaimLocationPage(props),
    deathIsVehicleInvolvedPage(props),
    deathClaimantMaritalStatusPage(props),
    deathIsEstateExecutorPage(props),

    // Illness
    illnessClaimReporterRolePage(props),
    illnessInsuranceTypePage(props),
    illnessHospitalisedPage(props),
    illnessPreviousIllnessPage(props),
    illnessHasOtherInsurancePage(props),
    illnessHasAttachmentPage(props),
];
