import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';

const { FIRST_NAME_LABEL, LAST_NAME_LABEL } = PhraseKeys;
const { MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

export const nameSchema = (t: TFunction) => {
    return {
        firstName: Yup.string()
            .label(t(FIRST_NAME_LABEL))
            .required()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(FIRST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
        lastName: Yup.string()
            .label(t(LAST_NAME_LABEL))
            .required()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(LAST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
    };
};
