export enum Domains {
    SWE = 'protectorforsakring.se',
}

export enum LanguageCodes {
    EN = 'en',
    SE = 'se',
}

export const COUNTRY_CODE_SWEDEN = 'swe';

export const LANG_CODE_SWEDEN = 'se';
export const LANG_CODE_ENGLAND = 'en';

export const CountryCodeArray = [COUNTRY_CODE_SWEDEN];
export const LanguageCodeArray = [LANG_CODE_SWEDEN, LANG_CODE_ENGLAND];

export const SWE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const SWE_DATE_FORMAT = 'YYYY-MM-DD';

export enum LanguageAttributeCodes {
    SE = 'sv',
    EN = 'en',
}
