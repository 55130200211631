import { FormFieldNames } from '../../config/formFieldNames';

const { PHONE, EMAIL } = FormFieldNames;

export const crossContactInfoFieldValidation = async (
    id: string,
    triggerValidation: (payload?: 'phone' | 'email') => Promise<boolean>
) => {
    if (id === PHONE) {
        await triggerValidation(EMAIL);
    } else if (id === EMAIL) {
        await triggerValidation(PHONE);
    }
};
