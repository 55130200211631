import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimReporterRoleKeys,
    Clickable,
    ECareIcon,
    HospitalIcon,
    SchoolNurseIcon,
    TypeOfMedicalInstitutionEnums,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimReporterRole, selectTypeOfMedicalInstitution } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { E_CARE, HOSPITAL, SCHOOL_NURSE } = TypeOfMedicalInstitutionEnums;
const { ON_BEHALF_CHILD } = ClaimReporterRoleKeys;

/**
 * Page view and page logic
 */
export const accidentTypeOfMedicalInstitutionPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const whoReport = selectClaimReporterRole(state);
    let options = [
        {
            icon: <HospitalIcon />,
            id: HOSPITAL,
            name: t(`lob.person.accident.typeOfMedicalInstitution.select.${HOSPITAL}`),
        },
        { icon: <ECareIcon />, id: E_CARE, name: t(`lob.person.accident.typeOfMedicalInstitution.select.${E_CARE}`) },
    ];

    if (whoReport === ON_BEHALF_CHILD) {
        options = [
            {
                icon: <HospitalIcon />,
                id: HOSPITAL,
                name: t(`lob.person.accident.typeOfMedicalInstitution.select.${HOSPITAL}`),
            },
            {
                icon: <SchoolNurseIcon />,
                id: SCHOOL_NURSE,
                name: t(`lob.person.accident.typeOfMedicalInstitution.select.${SCHOOL_NURSE}`),
            },
            {
                icon: <ECareIcon />,
                id: E_CARE,
                name: t(`lob.person.accident.typeOfMedicalInstitution.select.${E_CARE}`),
            },
        ];
    }

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_TYPE_OF_MEDICAL_INSTITUTION,
        stateKey: 'typeOfMedicalInstitution',
        i18n: {
            ns: 'lob.person.accident.typeOfMedicalInstitution',
        },
        selectorValue: selectTypeOfMedicalInstitution(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { typeOfMedicalInstitution: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
