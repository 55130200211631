import React from 'react';
import {
    ClaimantInformationListModel,
    ClaimantInformationListTypeModel,
    Clickable,
    SummaryNavButton,
    SummaryText,
} from '@protectorinsurance/ds-can';

export interface SummaryClaimantInformationListProps {
    list: ClaimantInformationListTypeModel;
    textKey: string;
    path: string;
    show: boolean;
    dataTestId: string;
    className: string;
    handleClick?: (e: Clickable, path: string) => void;
}

export const renderClaimantInformation = ({
    list,
    path,
    textKey,
    dataTestId,
    className,
    handleClick,
}: SummaryClaimantInformationListProps) => {
    const companyButtonText = (model: ClaimantInformationListModel) => {
        const { businessNumber, name, email, phone } = model;
        return `${name} ${businessNumber}${email ? `, ${email}` : ''}${phone ? `, ${phone}` : ''}`;
    };

    const personButtonText = (model: ClaimantInformationListModel) => {
        const { firstName, lastName, email, phone } = model;
        return `${firstName} ${lastName}${email ? `, ${email}` : ''}${phone ? `, ${phone}` : ''}`;
    };
    return (
        <div className={className}>
            <SummaryText text={textKey} />
            {list.map((item: ClaimantInformationListModel, idx: number) => {
                return (
                    <div key={idx} className={'claimant-list'}>
                        <SummaryNavButton
                            dataTestId={dataTestId}
                            path={path}
                            buttonText={item.isCompany ? companyButtonText(item) : personButtonText(item)}
                            handleClick={handleClick}
                        />
                    </div>
                );
            })}
        </div>
    );
};
