/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import './Loader.style';
import { loaderCss } from './Loader.style';

interface LoaderProps {
    fixed?: boolean;
    inline?: boolean;
    size?: 'small' | 'normal';
    placement?: 'center' | 'end' | 'start';
    testId?: string;
    className?: string;
}

export function Loader({
    fixed = true,
    inline = false,
    size = 'normal',
    placement = 'center',
    testId = 'loading',
    className,
}: LoaderProps) {
    const wrapperClassNames: string[] = ['loader', `justify-${placement}`];
    const itemWrapperClassNames = ['item'];

    if (fixed) {
        wrapperClassNames.push('fixed');
    }

    if (inline) {
        wrapperClassNames.push('flex-grow');
    }

    if (size === 'small') {
        wrapperClassNames.push('spinner-size-s');
        itemWrapperClassNames.push('dots-small');
    }

    return (
        <div
            className={wrapperClassNames.join(' ')}
            data-testid={`loader-${testId}`}
            css={css(loaderCss)}
        >
            <div className={`dots ${inline ? 'dots-inline' : ''} ${className}`}>
                <div className={itemWrapperClassNames.join(' ')} />
                <div className={itemWrapperClassNames.join(' ')} />
                <div className={itemWrapperClassNames.join(' ')} />
            </div>
        </div>
    );
}
