import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimReporterRoleKeys,
    Clickable,
    is,
    isYes,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimReporterRole, selectHasExpenses } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;
const { MYSELF, ON_BEHALF, ON_BEHALF_CHILD } = ClaimReporterRoleKeys;
const { ACCIDENT_UPLOAD_RECEIPT, DYN_CAROUSEL_ACCIDENT_NEED_TRANSPORT } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const accidentHasExpensesPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const claimReporterRole = selectClaimReporterRole(state);
    const options = [
        { id: YES, name: t(`lob.person.accident.hasExpenses.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lob.person.accident.hasExpenses.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES,
        stateKey: 'hasExpenses',
        i18n: {
            ns: 'lob.person.accident.hasExpenses',
        },
        selectorValue: selectHasExpenses(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext();
                if (isYes(option.id) && (is(claimReporterRole, MYSELF) || is(claimReporterRole, ON_BEHALF))) {
                    dispatch(wizardActions.skipBackToPrev(true));
                    nextAction = wizardActions.goTo(ACCIDENT_UPLOAD_RECEIPT);
                } else if (isYes(option.id) && is(claimReporterRole, ON_BEHALF_CHILD)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_ACCIDENT_NEED_TRANSPORT);
                }

                dispatcherWithPromise(dispatch, personActions.update, { hasExpenses: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
