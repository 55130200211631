/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Fragment, ReactNode } from 'react';
import { Nullable } from '../../../interfaces/types/Nullable.types';
import { Grid } from '../grid/Grid';
import { SummaryText } from '../../atoms/summaryItems/SummaryText';
import { summaryVehicleItemCss } from './SummaryVehicleItem.style';
import {
    VehicleDamagesKeyValueModel,
    VehicleModel,
} from '../../../modules/can/motor/interfaces/models';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface SummaryVehicleItemProps {
    customCAN?: customCANProps;
    vehicle: Nullable<VehicleModel>;
    textKey: string;
    printSummary?: boolean;
    ownerInformationTextKey?: string;
    damagesTextKey?: string;
    className?: string;
    damages?: VehicleDamagesKeyValueModel[];
    damageDescription?: Nullable<string>;
    damageDescriptionTitle?: string;
    vehicleDamages?: ReactNode;
    drivingText?: string;
    passengersText?: string;
    isTotalDamage?: boolean;
    isTotalDamageTitle?: string;
    ownedByLtdTitle?: string;
    ownedByLtd?: string;
    leasingTitle?: string;
    leasing?: string;
    VATTitle?: string;
    VAT?: string;
}

/**
 * Component view and component logic
 */
export const SummaryVehicleItem = ({
    customCAN,
    vehicle,
    textKey,
    printSummary = false,
    ownerInformationTextKey,
    damagesTextKey,
    className,
    damages,
    damageDescription,
    damageDescriptionTitle,
    vehicleDamages,
    drivingText,
    passengersText,
    isTotalDamage,
    isTotalDamageTitle,
    ownedByLtdTitle,
    ownedByLtd,
    leasingTitle,
    leasing,
    VATTitle,
    VAT,
}: SummaryVehicleItemProps) => {
    return (
        <div className={className} css={css(summaryVehicleItemCss(customCAN))}>
            <Grid>
                {ownerInformationTextKey && (
                    <div className={'col-12'}>
                        <SummaryText
                            text={`${textKey} ${ownerInformationTextKey}`}
                        />
                    </div>
                )}
                <div
                    className={`${
                        printSummary ? 'col-3' : 'col-4'
                    } justify-content-center`}
                >
                    <span className={'registration-number'} tabIndex={0}>
                        {vehicle?.registrationNumber
                            ? vehicle.registrationNumber.toUpperCase()
                            : '-'}
                    </span>
                </div>
                <div
                    className={`${
                        printSummary ? 'col-9' : 'col-8'
                    } justify-content-center`}
                >
                    <p className={'vehicle-information'} tabIndex={0}>
                        {vehicle?.model ? vehicle.model : ''}{' '}
                        {vehicle?.make ? vehicle.make : ''}{' '}
                        {vehicle?.type ? vehicle.type : ''}
                    </p>
                </div>
                <div className={'col-12 padding-top'}>
                    <p className={'vehicle-information'} tabIndex={0}>
                        {drivingText}{' '}
                        {vehicle?.driverInformation &&
                        vehicle?.driverInformation.firstName
                            ? vehicle?.driverInformation.firstName
                            : ''}{' '}
                        {vehicle?.driverInformation &&
                        vehicle?.driverInformation.lastName
                            ? vehicle?.driverInformation.lastName
                            : ''}
                    </p>
                </div>
                <div className={'col-12 padding-top'}>
                    <p className={'vehicle-information'} tabIndex={0}>
                        {vehicle?.driverInformation &&
                        vehicle?.driverInformation.email
                            ? vehicle?.driverInformation.email
                            : ''}{' '}
                        {vehicle?.driverInformation &&
                        vehicle?.driverInformation.phone
                            ? vehicle?.driverInformation.phone
                            : ''}
                    </p>
                </div>
                <div className={'col-12 padding-top'}>
                    <p className={'vehicle-information'} tabIndex={0}>
                        {vehicle?.driverInformation &&
                        vehicle?.driverInformation.street
                            ? vehicle?.driverInformation.street
                            : ''}{' '}
                        {vehicle?.driverInformation &&
                        vehicle?.driverInformation.zip
                            ? vehicle?.driverInformation.zip
                            : ''}{' '}
                        {vehicle?.driverInformation &&
                        vehicle?.driverInformation.city
                            ? vehicle?.driverInformation.city
                            : ''}
                    </p>
                </div>
                {vehicle &&
                    vehicle.passengers &&
                    (passengersText !== null || passengersText !== '') && (
                        <div className={'col-12 padding-top'}>
                            <p className={'vehicle-information'}>
                                {passengersText} {vehicle.passengers}
                            </p>
                        </div>
                    )}
                {vehicle &&
                    vehicle.companyVehicle &&
                    (ownedByLtdTitle !== null || ownedByLtdTitle !== '') && (
                        <div className={'col-12 padding-top'}>
                            <p className={'vehicle-information'}>
                                {ownedByLtdTitle}: {ownedByLtd}
                            </p>
                        </div>
                    )}
                {vehicle &&
                    vehicle.leasing &&
                    (leasingTitle !== null || leasingTitle !== '') && (
                        <div className={'col-12 padding-top'}>
                            <p className={'vehicle-information'}>
                                {leasingTitle}: {leasing}
                            </p>
                        </div>
                    )}
                {vehicle &&
                    vehicle.VAT &&
                    (VATTitle !== null || VATTitle !== '') && (
                        <div className={'col-12 padding-top'}>
                            <p className={'vehicle-information'}>
                                {VATTitle}: {VAT}
                            </p>
                        </div>
                    )}
                {damages && damages.length > 0 && (
                    <Fragment>
                        <div className={'col-12 padding-top'}>
                            <SummaryText text={damagesTextKey} />
                        </div>
                        <div className={'col-12 other-vehicle-damages'}>
                            <Grid>
                                <div
                                    className={`${
                                        printSummary ? 'col-4' : 'col-3'
                                    } no-padding justify-content-center`}
                                >
                                    <ul className={'damages-list'}>
                                        {damages?.map(
                                            (
                                                damage: VehicleDamagesKeyValueModel,
                                                idx: number
                                            ) => {
                                                return (
                                                    <li
                                                        key={idx}
                                                        className={
                                                            'damages-item'
                                                        }
                                                        tabIndex={0}
                                                    >
                                                        <span>
                                                            {damage.value}
                                                        </span>
                                                        <p>{damage.key}</p>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                                <div
                                    className={`${
                                        printSummary ? 'col-8' : 'col-9'
                                    } no-padding justify-content-center damage`}
                                >
                                    {vehicleDamages}
                                </div>
                            </Grid>
                        </div>
                        {damageDescription && (
                            <Fragment>
                                <div className={'col-12 padding-top'}>
                                    <SummaryText
                                        text={damageDescriptionTitle}
                                    />
                                </div>
                                <div
                                    className={'col-12 text-align-left'}
                                    tabIndex={0}
                                >
                                    {damageDescription}
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}
                {isTotalDamage && (
                    <Fragment>
                        <div className={'col-12 padding-top'}>
                            <SummaryText text={damagesTextKey} />
                        </div>
                        <div className={'col-12 other-vehicle-damages'}>
                            <Grid>
                                <div
                                    className={`${
                                        printSummary ? 'col-4' : 'col-3'
                                    } no-padding justify-content-center`}
                                >
                                    {isTotalDamageTitle}
                                </div>
                                <div
                                    className={`${
                                        printSummary ? 'col-8' : 'col-9'
                                    } no-padding justify-content-center damage`}
                                >
                                    {vehicleDamages}
                                </div>
                            </Grid>
                        </div>
                    </Fragment>
                )}
            </Grid>
        </div>
    );
};
