import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { ACCOUNT_NUMBER_LABEL } = PhraseKeys;
/**
 * Validation logic
 */
export const plusgiroSchema = (t: TFunction) => {
    return Yup.object().shape({
        accountNumber: Yup.string().label(t(ACCOUNT_NUMBER_LABEL)).plusgiroAccountNumber().notRequired().nullable(),
    });
};
