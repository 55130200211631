export function ssnSwedenHasValidDate(ssn: string) {
    const year = getYear(ssn);
    const month = getMonth(ssn) - 1;
    const day = getDay(ssn);

    const date = new Date(year, month, day);

    const yearIsValid = date.getFullYear() === year;
    const monthIsValid = date.getMonth() === month;
    const dayIsValid = date.getDate() === day;

    return yearIsValid && monthIsValid && dayIsValid;
}

function getYear(ssn: string) {
    let year: number = parseInt(ssn.substring(0, 2));
    const hundredPlus: boolean = ssn.includes('+');

    const currentDate = new Date();
    const fictiveDate = new Date(year + 2000, getMonth(ssn) - 1, getDay(ssn));
    if (fictiveDate > currentDate) {
        year += 1900;
    } else {
        year += 2000;
    }

    if (hundredPlus) {
        year -= 100;
    }

    return year;
}

function getMonth(ssn: string) {
    return parseInt(ssn.substring(2, 4));
}

function getDay(ssn: string) {
    let day: number = parseInt(ssn.substring(4, 6));
    /*
        Samordningsnummer ser ut som ett personnummer,
        men till två sista siffrorna i personens födelsedatum adderas siffran 60,
        vilket ger ett tal mellan 61 och 91 istället för mellan 01 och 31. En person som är född 3 oktober 1970 får sålunda ett samordningsnummer som börjar på 701063
     */
    if (day >= 61 && day <= 91) {
        day = day - 60;
    }

    return day;
}
