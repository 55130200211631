import { PFRequestError } from '../../../../../interfaces';

export const createPFRequestError = (
    status: number,
    message: string,
    url: string,
    name?: string,
    statusText?: string
): PFRequestError => {
    return {
        message,
        name: name || `${status}`,
        status,
        statusText: statusText || message,
        url,
    };
};
