import { VehicleModel } from '../interfaces/models';

export const vehicleDamagesToKeyValueArr = (v: VehicleModel) => {
    const damages = v.damages.map((item) => item.body);
    const damagesList = damages.reduce((damagesCount: any, currentDamage) => {
        damagesCount[currentDamage] = (damagesCount[currentDamage] || 0) + 1;
        return damagesCount;
    }, {});

    const reducedDamagesList = Object.entries(damagesList).map(
        ([key, value]) => ({ key, value })
    );
    return reducedDamagesList;
};
