import { css } from '@emotion/react';
import { secondary, secondaryHover, white } from '../../../variables';
import { makeStyles } from '@mui/styles';
import { customCANProps } from '../../../../config/customCANCompanies';

export const muiDatePickerInputStyle = (company?: customCANProps) =>
    css({
        '& label.Mui-focused': {
            color: company ? company.color : secondary,
        },

        '& .MuiOutlinedInput-root': {
            '& .MuiInputAdornment-root': {
                width: 'unset !important',
            },

            '&.Mui-disabled:hover': {
                '& fieldset': {
                    borderColor: 'rgba(0,0,0,0.26)',
                },
            },

            '&:hover': {
                '& fieldset': {
                    borderColor: company ? company.color : secondary,
                },
            },

            '&.Mui-focused': {
                '& legend': {
                    backgroundColor: company ? company.textColor : white,
                },

                '& fieldset': {
                    borderColor: company ? company.color : secondary,
                },
            },
        },
    });

export const datePickerStyles = makeStyles({
    datePicker: {
        '& .MuiPickersDay-root': {
            '&.Mui-selected': {
                background: `${secondary} !important`,
                '&:hover': {
                    backgroundColor: `${secondaryHover} !important`,
                },
            },
        },
    },
});
