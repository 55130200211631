import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isEmpty } from '@protectorinsurance/ds-can';

export function requiredIf(this: StringSchema, key: string[], message: string = yupCustomLocale.string.requiredIf) {
    // @ts-ignore
    return this.test('requiredIf', message, function (
        this: TestContext,
        value: string
    ): boolean | ValidationError | Promise<boolean | ValidationError> {
        return (
            key.some((item) => {
                return !isEmpty(this.parent[item]);
            }) || !isEmpty(value)
        );
    });
}
