import React from 'react';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { formatContractorName } from '../../../utils/strings/formatStrings';
import { renderPolicyNumber } from './components/property/RenderPolicyNumber';
import { renderTypeOfProperty } from './components/property/RenderTypeOfProperty';
import { renderIsContractorContacted } from './components/property/RenderIsContractorContacted';
import { renderContractorInformation } from './components/property/RenderContractorInformation';
import { renderContractorInformationReason } from './components/property/RenderContractorInformationReason';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_PROPERTY_IS_CONTRACTOR_CONTACTED,
    PROPERTY_CONTRACTOR_INFORMATION,
    PROPERTY_POLICY_NUMBER,
    PROPERTY_TYPE_OF_PROPERTY,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayPropertySummary = ({ model, t, tWithNS, handleClick, upload }: LpoSummaryProps) => {
    const { companyInformation, contractorInformation, isContractorContacted, typeOfProperty } = model;

    return (
        <>
            {renderPolicyNumber({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyNumber.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyNumber,
                buttonText: companyInformation.policyNumber ? companyInformation.policyNumber : '-',
                path: PROPERTY_POLICY_NUMBER,
                handleClick,
            })}

            {renderTypeOfProperty({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('typeOfProperty.heading.title'),
                dataTestId: TEST_ID_PREFIX.typeOfProperty,
                buttonText: typeOfProperty ? t(`lpo.typeOfProperty.select.${typeOfProperty}`) : '-',
                path: PROPERTY_TYPE_OF_PROPERTY,
                handleClick,
            })}

            {renderIsContractorContacted({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('isContractorContacted.heading.title'),
                dataTestId: TEST_ID_PREFIX.isContractorContacted,
                buttonText: t(createTextYesNoDontKnow(isContractorContacted)),
                path: DYN_CAROUSEL_PROPERTY_IS_CONTRACTOR_CONTACTED,
                handleClick,
            })}

            {renderContractorInformation({
                inputWrapperClass: 'col-12 spacing text-align-left',
                textKey: tWithNS('contractorInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.contractorInformation,
                buttonText: contractorInformation ? formatContractorName(contractorInformation) : '-',
                path: PROPERTY_CONTRACTOR_INFORMATION,
                handleClick,
            })}

            {renderContractorInformationReason({
                inputWrapperClass: 'col-12 divider text-align-left',
                dataTestId: TEST_ID_PREFIX.reason,
                buttonText: contractorInformation.reason ? contractorInformation.reason : '-',
                path: PROPERTY_CONTRACTOR_INFORMATION,
                handleClick,
            })}
        </>
    );
};
