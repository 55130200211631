import { css } from '@emotion/react';
import {
    defaultFocusStyle,
    primaryLink,
    primaryLinkHover,
} from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const linkCss = (company?: customCANProps) =>
    css({
        color: primaryLink,
        cursor: 'pointer',
        textDecoration: 'underline',
        transition: 'all 200ms ease-in-out',

        '&:hover': {
            color: company ? company.color : primaryLinkHover,
        },

        '&:focus': {
            ...defaultFocusStyle(company),
        },
    });
