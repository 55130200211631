import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';

/*eslint-disable */
const bankAccountNumber = '${path} is not valid.';
const bankClearingNumber = '${path} is not valid.';
const bankAccountDepOnClearingNumber = '${path} depends on clearing number.';
const businessNumber = '${path} is not valid';
const digits = '${path} should only be numbers.';
const normalBankAccountNumber = '${path} is not valid.';
const bankgiroNumber = '${path} is not valid.';
const plusgiroNumber = '${path} is not valid.';
const noSpecialChars = '${path} cannot contain special characters';
const requiredIf = '${path} is a required field.';
const ssn = '${path} is not valid';
const onlyAscii = 'Only normal letters/characters are allowed in ${path}.';
const registrationNumber = '${path} is not valid.';
const email = '${path} is not valid.';

/*eslint-enable */
export const yupCustomLocale: ObjectWithDynamicKey = {
    string: {
        bankAccountNumber,
        bankAccountDepOnClearingNumber,
        bankClearingNumber,
        businessNumber,
        digits,
        normalBankAccountNumber,
        bankgiroNumber,
        plusgiroNumber,
        noSpecialChars,
        requiredIf,
        ssn,
        onlyAscii,
        registrationNumber,
        email,
    },
};
