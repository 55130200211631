import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    FlowKeys,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    Nullable,
    PageLayout,
    ReporterInformationModel,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { lpoActions } from '../../../sagas/lpo';
import {
    selectClaimantInformation,
    selectReporterInformation,
    selectSellerInformation,
} from '../../../sagas/selectors/lpoSelectors';
import { flowActions } from '../../../sagas/flow';
import { reporterInformationChangeOfOwnershipSchema } from '../../../validations/schemas/reporterInformationChangeOfOwnershipSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    IS_CLAIMANT_LABEL,
    IS_SELLER_LABEL,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL, FIRST_NAME, IS_CLAIMANT, IS_SELLER, LAST_NAME, PHONE } = FormFieldNames;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { END } = FlowKeys;

/**
 * Interfaces
 */
interface ReporterFormModel extends Omit<ReporterInformationModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const ChangeOfOwnershipReporterInformationPage = () => {
    const dispatch = useDispatch();
    const reporterInformation = useSelector(selectReporterInformation);
    const claimantInformation = useSelector(selectClaimantInformation);
    const sellerInformation = useSelector(selectSellerInformation);
    const customCAN = useSelector(selectCustomCAN);
    const isClaimant = reporterInformation?.isClaimant || false;
    const isSeller = reporterInformation?.isSeller || false;
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.changeOfOwnership.reporterInformation');
    const schema = reporterInformationChangeOfOwnershipSchema(t);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ReporterFormModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...reporterInformation,
            dateOfBirth: reporterInformation.dateOfBirth && reporterInformation.dateOfBirth.toDate(),
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const onSubmit = (values: ReporterFormModel) => {
        dispatcherWithPromise(dispatch, lpoActions.update, {
            reporterInformation: { ...values, dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null },
        })
            .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    const handleIsClaimantChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked, id } = e.target;
        const opposite = id === IS_CLAIMANT ? IS_SELLER : IS_CLAIMANT;
        const updatedState = {
            ...reporterInformation,
            ...(id === IS_CLAIMANT && claimantInformation),
            ...(id === IS_SELLER && sellerInformation),
            [opposite]: false,
            [id]: checked,
        };

        if (checked) {
            if (id === IS_CLAIMANT) {
                setValue('isSeller', false, { shouldValidate: true });
                setValue('firstName', claimantInformation.firstName, { shouldValidate: true });
                setValue('lastName', claimantInformation.lastName, { shouldValidate: true });
            }
            if (id === IS_SELLER) {
                setValue('isClaimant', false, { shouldValidate: true });
                setValue('firstName', sellerInformation.firstName, { shouldValidate: true });
                setValue('lastName', sellerInformation.lastName, { shouldValidate: true });
            }
        }

        dispatch(wizardActions.skipBackToPrev(true));
        dispatcherWithPromise(dispatch, lpoActions.update, {
            reporterInformation: updatedState,
        });
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <SingleCheckbox
                        checked={isClaimant}
                        handleChange={handleIsClaimantChecked}
                        name={IS_CLAIMANT}
                        wrapperClass={'col-6 multiple'}
                        {...{ customCAN }}
                    >
                        {t(IS_CLAIMANT_LABEL)}
                    </SingleCheckbox>

                    <SingleCheckbox
                        checked={isSeller}
                        handleChange={handleIsClaimantChecked}
                        name={IS_SELLER}
                        wrapperClass={'col-6 multiple'}
                        {...{ customCAN }}
                    >
                        {t(IS_SELLER_LABEL)}
                    </SingleCheckbox>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-6'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-6'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
