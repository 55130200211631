import { COUNTRY_CODE_SWEDEN } from '../locale';
import { baseWizardRoutes } from './baseWizardRoutes';
import { motorWizardRoutes } from './motorWizardRoutes';
import { unitTestWizardRoutes } from './unitTestWizardRoutes';
import { personWizardRoutes } from './personWizardRoutes';
import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { lpoWizardRoutes } from './lpoWizardRoutes';

export const wizardRoutes: ObjectWithDynamicKey = {
    [COUNTRY_CODE_SWEDEN]: {
        ...baseWizardRoutes,
        ...motorWizardRoutes,
        ...unitTestWizardRoutes,
        ...personWizardRoutes,
        ...lpoWizardRoutes,
    },
};
