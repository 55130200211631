import { VehicleModel, VehiclesModel } from '../../interfaces';

export const removeVehicle = (
    vehicle: VehicleModel,
    vehicles: VehiclesModel
): VehiclesModel => {
    return vehicles.filter(
        (v: VehicleModel) => v.internalId !== vehicle.internalId
    );
};
