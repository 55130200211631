import { ssnSwedenValidator } from '../ssn';
import { businessNumberSwedenValidateChecksum } from './businessNumberSwedenValidateChecksum';
import { isAllSameDigits } from '../../../utils';

export function businessNumberSwedenValidator(businessNumber: string) {
    return {
        isValid: function isValid() {
            // Check if business number is SSN
            if (ssnSwedenValidator(businessNumber).isValid()) {
                return true;
            }

            if (
                !businessNumber ||
                !businessNumberSwedenValidateChecksum(businessNumber)
            ) {
                return false;
            }

            const thirdCharacter = parseInt(businessNumber.substring(2, 3));
            const normalized = businessNumber.replace(/\D/g, '');
            return thirdCharacter >= 2 && !isAllSameDigits(normalized);
        },
    };
}
