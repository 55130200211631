import creator from 'utils/action-creator';
import { Rejectable, Resolvable } from '@protectorinsurance/ds-can';

/**
 * Constants
 */
export enum ResetActionTypes {
    RESET_PAGES = '@app/RESET_PAGES',
    RESET_STATE = '@app/RESET_STATE',
}

export interface ResetState {
    key: string;
    value: any;
    depth?: boolean;
}

export interface IResetAction {
    type: ResetActionTypes;
    data?: null;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const resetActions = {
    resetPages: () => creator<ResetActionTypes.RESET_PAGES>(ResetActionTypes.RESET_PAGES),
    resetState: <S = ResetState>(data: S) => creator<ResetActionTypes.RESET_STATE>(ResetActionTypes.RESET_STATE, data),
};
