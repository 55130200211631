import { combineReducers } from 'redux';

import maps, { MapsServiceAction, MapsServiceActionTypes, MapsServiceState, mapsServiceWatcher } from './mapsService';
import bankIDJWTService, {
    BankIDJWTServiceAction,
    BankIDJWTServiceActionTypes,
    BankIDJWTState,
    bankIDJWTWatcher,
} from './bankIDJWTService';
import municipalityService, {
    MunicipalityServiceAction,
    MunicipalityServiceActionTypes,
    MunicipalityServiceState,
    municipalityServiceWatchers,
} from './municipalityService';
import orgnrService, { OrgnrServiceAction, OrgnrServiceActionTypes, OrgnrState, orgnrWatcher } from './orgnrService';
import { LogServiceActions, LogServiceActionTypes, logServiceWatchers } from './logService';
import {
    PDFServiceAction,
    PDFServiceActionTypes,
    pdfServiceReducer,
    PDFServiceState,
    pdfServiceWatcher,
    VehicleServiceAction,
    VehicleServiceActionTypes,
    vehicleServiceReducer,
    VehicleState,
    vehicleWatcher,
    TogglesServiceAction,
    TogglesServiceActionTypes,
    togglesServiceReducer,
    TogglesServiceState,
    togglesServiceWatcher,
} from '@protectorinsurance/ds-can';

export type ServicesActionTypes =
    | VehicleServiceActionTypes
    | MapsServiceActionTypes
    | BankIDJWTServiceActionTypes
    | MunicipalityServiceActionTypes
    | LogServiceActionTypes
    | PDFServiceActionTypes
    | TogglesServiceActionTypes
    | OrgnrServiceActionTypes;

export type ServicesActions =
    | VehicleServiceAction
    | MapsServiceAction
    | BankIDJWTServiceAction
    | MunicipalityServiceAction
    | LogServiceActions
    | PDFServiceAction
    | TogglesServiceAction
    | OrgnrServiceAction;

export interface ServicesState {
    vehicleService: VehicleState;
    maps: MapsServiceState;
    bankIDJWTService: BankIDJWTState;
    municipalityService: MunicipalityServiceState;
    orgnrService: OrgnrState;
    pdfService: PDFServiceState;
    togglesService: TogglesServiceState;
}

/**
 * Export namespace watchers
 */
export const servicesWatchers = [
    vehicleWatcher,
    mapsServiceWatcher,
    bankIDJWTWatcher,
    municipalityServiceWatchers,
    orgnrWatcher,
    logServiceWatchers,
    pdfServiceWatcher,
    togglesServiceWatcher,
];

/**
 * Export combined reducers as default
 */
export default combineReducers({
    vehicleService: vehicleServiceReducer,
    togglesService: togglesServiceReducer,
    maps,
    bankIDJWTService,
    municipalityService,
    orgnrService,
    pdfService: pdfServiceReducer,
});
