import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    is,
    NoIcon,
    TeethOrBodyKeys,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectPreviousInjury, selectTeethOrBody } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;
const { BODY, BOTH, TEETH } = TeethOrBodyKeys;
const { DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED, DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const accidentPreviousInjuryPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const teethOrBody = selectTeethOrBody(state);
    const options = [
        { id: YES, name: t(`lob.person.accident.previousInjury.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lob.person.accident.previousInjury.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY,
        stateKey: 'previousInjury',
        i18n: {
            ns: 'lob.person.accident.previousInjury',
        },
        selectorValue: selectPreviousInjury(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext();
                if ((is(option.id, NO) && is(teethOrBody, BODY)) || (is(option.id, NO) && is(teethOrBody, BOTH))) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED);
                } else if (is(option.id, NO) && is(teethOrBody, TEETH)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED);
                }

                dispatch(wizardActions.skipBackToPrev(true));
                dispatcherWithPromise(dispatch, personActions.update, { previousInjury: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
