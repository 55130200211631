export enum BodyKeys {
    BACK_BUMPER = 'BACK_BUMPER',
    BACK_LEFT = 'BACK_LEFT',
    BACK_RIGHT = 'BACK_RIGHT',
    CENTER_LEFT = 'CENTER_LEFT',
    CENTER_RIGHT = 'CENTER_RIGHT',
    FRONT_BUMPER = 'FRONT_BUMPER',
    FRONT_LEFT = 'FRONT_LEFT',
    FRONT_RIGHT = 'FRONT_RIGHT',
    REAR_WINDSHIELD = 'REAR_WINDSHIELD',
    ROOF = 'ROOF',
    WINDSHIELD = 'WINDSHIELD',
}

export type BodyModel = BodyKeys;
