import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { FREIGHT_WEIGHT_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const freightWeightSchema = (t: TFunction) => {
    return {
        freightWeight: Yup.string().label(t(FREIGHT_WEIGHT_LABEL)).required().nullable(),
    };
};
