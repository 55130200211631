export function ssnFinlandHasValidDate(ssn: string) {
    const day = parseInt(ssn.substring(0, 2));
    const month = parseInt(ssn.substring(2, 4));
    const year = getYear(ssn);

    const date = new Date(year, month - 1, day);
    return (
        date.getFullYear() === year &&
        date.getMonth() + 1 === month &&
        date.getDate() === day
    );
}

function getYear(ssn: string) {
    let year = parseInt(ssn.substring(4, 6));
    if (ssn.includes('+')) {
        year += 1800;
    } else if (
        ssn.includes('-') ||
        ssn.includes('U') ||
        ssn.includes('V') ||
        ssn.includes('W') ||
        ssn.includes('X') ||
        ssn.includes('Y')
    ) {
        year += 1900;
    } else if (
        ssn.includes('A') ||
        ssn.includes('B') ||
        ssn.includes('C') ||
        ssn.includes('D') ||
        ssn.includes('E') ||
        ssn.includes('F')
    ) {
        year += 2000;
    }

    return year;
}
