import * as Yup from 'yup';
import { FormFieldErrors, MAX_LENGTH_CLAIM_DESCRIPTION } from '../../config/formFieldNames';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation
 */
export const descriptionSchema = (t: TFunction, label: string) => {
    return {
        claimDescription: Yup.string()
            .label(t(label))
            .required()
            .max(
                MAX_LENGTH_CLAIM_DESCRIPTION,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(label),
                    max: MAX_LENGTH_CLAIM_DESCRIPTION,
                })
            )
            .nullable(),
    };
};
