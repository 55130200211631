export enum ClaimCauseConsequencesKeys {
    CRACK = 'CRACK',
    FRACTURE = 'FRACTURE',
    CRUSH = 'CRUSH',
    WOUND = 'WOUND',
    CONTUSION = 'CONTUSION',
    SPRAIN = 'SPRAIN',
    DISLOCATION = 'DISLOCATION',
    OTHER = 'OTHER',
}

export type ClaimCauseConsequencesModel = Array<ClaimCauseConsequencesKeys>;
