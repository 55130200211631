export function ssnNorwayHasValidDate(
    day: number,
    month: number,
    year: number
) {
    const date = new Date(year, month - 1, day);
    return (
        date.getFullYear() === year &&
        date.getMonth() + 1 === month &&
        date.getDate() === day
    );
}
