import { css } from '@emotion/react';
import {
    borderRadius,
    inputBorder,
    inputFocusStyle,
    inputPlaceholderStyle,
    primary,
} from '../../variables';

export const inputCss = css({
    color: primary,
    padding: '0.5rem 1rem',
    lineHeight: '1.5',
    border: inputBorder,
    borderRadius: `${borderRadius}`,
    '&:placeholder': {
        ...inputPlaceholderStyle,
    },
    '&:focus': {
        ...inputFocusStyle,
    },
    '&.input-append': {
        borderBottom: inputBorder,
        borderTop: inputBorder,
        borderLeft: inputBorder,
        borderRight: 'none',
        borderRadius: `${borderRadius} 0 0 ${borderRadius}`,
    },
});
