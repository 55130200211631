import { AppActionWithPromise } from '../store/rootReducers';
import {
    actionWithPromise,
    ObjectWithDynamicKey,
    PFRequestError,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';

/**
 * Action Types
 */
export enum ErrorsActionTypes {
    RESET = '@app/errors/RESET',
    REMOVE = '@app/errors/REMOVE',
}

/**
 * Action Definitions
 */
export interface ErrorsAction {
    type: ErrorsActionTypes;
    data?: ObjectWithDynamicKey;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface ErrorState {
    [key: string]: PFRequestError;
}

/**
 * Init state
 */
export const errorInitState: ErrorState = {};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
export default function (state = errorInitState, { type, data }: AppActionWithPromise) {
    /*
     * Reset state
     */
    if (type === ErrorsActionTypes.RESET) {
        return errorInitState;
    }

    /*
     * Remove single action
     */
    if (type === ErrorsActionTypes.REMOVE) {
        const { [type]: value, ...removedState } = state;
        return removedState;
    }

    const isSuccess = type.includes('SUCCESS');
    const isRequest = type.includes('REQUEST');
    const isFailure = type.includes('FAILURE');

    if (isFailure) {
        return { ...state, [type]: data };
    }

    if (isRequest || isSuccess) {
        const { [type.replace(/SUCCESS|REQUEST/, 'FAILURE')]: value, ...restState } = state;

        return restState;
    }

    return state;
}

/**
 * Redux Actions
 */
export const errorsActions = {
    remove: actionWithPromise(ErrorsActionTypes.REMOVE),
    reset: actionWithPromise(ErrorsActionTypes.RESET),
};
