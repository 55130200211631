/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ButtonProps } from '../../../interfaces';
import { buttonBase } from './Button.style';
import { secondaryButtonCss } from './SecondaryButton.style';

/**
 * Component view and component logic
 */
export const SecondaryButton = ({
    ariaLabel,
    className,
    customCAN,
    disabled = false,
    onClick,
    testId = 'secondary-button',
    type = 'button',
    value,
}: ButtonProps) => {
    return (
        <button
            aria-label={ariaLabel}
            className={`${className}`}
            css={css([buttonBase, secondaryButtonCss(customCAN)])}
            data-testid={testId}
            {...{ disabled, onClick, type }}
        >
            {value}
        </button>
    );
};
