import { AppState } from '../../store';

export const selectPerson = (state: AppState) => state.person;
export const selectCategory = (state: AppState) => state.person.category;
export const selectClaimReporterRole = (state: AppState) => state.person.claimReporterRole;
export const selectClaimReporter = (state: AppState) => state.person.claimReporter;
export const selectAcceptedPrivacy = (state: AppState) => state.person.acceptedPrivacy;
export const selectInsuranceType = (state: AppState) => state.person.insuranceType;
export const selectClaimantAgeCategory = (state: AppState) => state.person.claimantAgeCategory;
export const selectClaimLocation = (state: AppState) => state.person.claimLocation;
export const selectClaimDate = (state: AppState) => state.person.claimDate;
export const selectClaimTimeOfDay = (state: AppState) => state.person.claimTimeOfDay;
export const selectIsVehicleInvolved = (state: AppState) => state.person.isVehicleInvolved;
export const selectRegistrationNumber = (state: AppState) => state.person.registrationNumber;
export const selectIsVehicleMotorcycle = (state: AppState) => state.person.isVehicleMotorcycle;
export const selectPersonInVehicle = (state: AppState) => state.person.personInVehicle;
export const selectHasLicense = (state: AppState) => state.person.hasLicense;
export const selectHasHelmet = (state: AppState) => state.person.hasHelmet;
export const selectHappenDomestically = (state: AppState) => state.person.happenDomestically;
export const selectDuringSport = (state: AppState) => state.person.duringSport;
export const selectTypeOfSport = (state: AppState) => state.person.typeOfSport;
export const selectTeethOrBody = (state: AppState) => state.person.teethOrBody;
export const selectBodyPartList = (state: AppState) => state.person.bodyPartList;
export const selectTeeth = (state: AppState) => state.person.teeth;
export const selectClaimCause = (state: AppState) => state.person.claimCause;
export const selectClaimCauseConsequences = (state: AppState) => state.person.claimCauseConsequences;
export const selectHasPlasterTreatment = (state: AppState) => state.person.hasPlasterTreatment;
export const selectWeeksOfPlasterTreatment = (state: AppState) => state.person.weeksOfPlasterTreatment;
export const selectPreviousInjury = (state: AppState) => state.person.previousInjury;
export const selectDateOfPreviousInjury = (state: AppState) => state.person.dateOfPreviousInjury;
export const selectIsDoctorContacted = (state: AppState) => state.person.isDoctorContacted;
export const selectTypeOfMedicalInstitution = (state: AppState) => state.person.typeOfMedicalInstitution;
export const selectDateOfTreatment = (state: AppState) => state.person.dateOfTreatment;
export const selectMedicalInstitution = (state: AppState) => state.person.medicalInstitution;
export const selectIsDentistContacted = (state: AppState) => state.person.isDentistContacted;
export const selectDentist = (state: AppState) => state.person.dentist;
export const selectFinishedTreatment = (state: AppState) => state.person.finishedTreatment;
export const selectInvalidity = (state: AppState) => state.person.invalidity;
export const selectHasExpenses = (state: AppState) => state.person.hasExpenses;
export const selectNeedTransport = (state: AppState) => state.person.needTransport;
export const selectInElementarySchool = (state: AppState) => state.person.inElementarySchool;
export const selectHasOtherExpenses = (state: AppState) => state.person.hasOtherExpenses;
export const selectHasOtherInsurance = (state: AppState) => state.person.otherInsurance;
export const selectOtherInsuranceCompany = (state: AppState) => state.person.otherInsuranceCompany;
export const selectHasAttachment = (state: AppState) => state.person.hasAttachment;
export const selectClaimDescription = (state: AppState) => state.person.claimDescription;
export const selectCompanyInformation = (state: AppState) => state.person.companyInformation;
export const selectAssociationInformation = (state: AppState) => state.person.association;
export const selectSchoolInformation = (state: AppState) => state.person.schoolInformation;
export const selectReporterInformation = (state: AppState) => state.person.reporterInformation;
export const selectBankAccountInformation = (state: AppState) => state.person.bankAccountInformation;
export const selectEditClaimDescription = (state: AppState) => state.person.editClaimDescription;
export const selectDiagnosis = (state: AppState) => state.person.diagnosis;
export const selectDateOfDiagnosis = (state: AppState) => state.person.dateOfDiagnosis;
export const selectHospitalised = (state: AppState) => state.person.hospitalised;
export const selectHospitalisationInformation = (state: AppState) => state.person.hospitalisationInformation;
export const selectPreviousIllness = (state: AppState) => state.person.previousIllness;
export const selectOtherInsurance = (state: AppState) => state.person.otherInsurance;
export const selectClaimantInformation = (state: AppState) => state.person.claimantInformation;
export const selectIsAccident = (state: AppState) => state.person.isAccident;
export const selectClaimantMaritalStatus = (state: AppState) => state.person.claimantMaritalStatus;
export const selectIsEstateExecutor = (state: AppState) => state.person.isEstateExecutor;
export const selectEstateExecutors = (state: AppState) => state.person.estateExecutors;
