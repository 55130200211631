import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';
import { ZIP_REGEX } from '../formats/stringFormats';

/**
 * Destructure necessary imports
 */
const { CITY_LABEL, STREET_LABEL, ZIP_LABEL } = PhraseKeys;
const { MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation logic
 */
export const addressSchema = (t: TFunction) => {
    return {
        street: Yup.string()
            .label(t(STREET_LABEL))
            .required()
            .noSpecialChars()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(STREET_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .nullable(),
        city: Yup.string()
            .label(t(CITY_LABEL))
            .required()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(CITY_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .city()
            .nullable(),
        zip: Yup.string()
            .label(t(ZIP_LABEL))
            .required()
            .matches(ZIP_REGEX, `${t(ZIP_LABEL)} ${t('error.format.zipLength')}`)
            .nullable(),
    };
};
