import {
    DamagedItemType,
    OwnerInformationListModel,
    OwnerInformationListTypeModel,
} from '../../interfaces/models';
import { Nullable } from '../../../../../interfaces/types';

export const findOwnerByDamagedItem = (
    ownerInformationList: OwnerInformationListTypeModel,
    damagedItem: DamagedItemType
): Nullable<OwnerInformationListModel> => {
    const selected = ownerInformationList.find(
        (owner) => owner.damagedItem === damagedItem
    );
    return selected ? selected : null;
};
