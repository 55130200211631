import { isTest } from 'utils/env';

export const MAP_KEY =
    (isTest() ? process.env.REACT_APP_GOOGLE_MAPS_KEY_TEST : process.env.REACT_APP_GOOGLE_MAPS_KEY) || '';

export const DEFAULT_MAP_LOCATION = {
    lat: 59.2351578,
    lng: 14.0447919,
    zoom: 5,
};
