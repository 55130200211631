import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    FlowKeys,
    is,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { selectIsOtherVehicleInvolved } from '../../../../sagas/selectors/motorSelectors';
import { motorActions } from '../../../../sagas/motor';
import { flowActions } from '../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;
const { NO_VEHICLES, VEHICLES } = FlowKeys;

/**
 * Page view and page logic
 */
export const drivingIsOtherVehicleInvolvedPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const isOtherVehicleInvolved = selectIsOtherVehicleInvolved(state);
    const options = [
        { id: YES, name: t(`motor.driving.isOtherVehicleInvolved.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.isOtherVehicleInvolved.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED,
        stateKey: 'isOtherVehicleInvolved',
        i18n: {
            ns: 'motor.driving.isOtherVehicleInvolved',
        },
        selectorValue: selectIsOtherVehicleInvolved(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let flow = VEHICLES;
                if (!is(option.id, isOtherVehicleInvolved)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (is(option.id, NO)) {
                    flow = NO_VEHICLES;
                }
                dispatcherWithPromise(dispatch, motorActions.update, { flow, isOtherVehicleInvolved: option.id })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, flow))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
