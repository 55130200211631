import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { END_DATE_LABEL, START_DATE_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const periodSchema = (t: TFunction) => {
    return {
        startDate: Yup.mixed().label(t(START_DATE_LABEL)).required().nullable(),
        endDate: Yup.mixed().label(t(END_DATE_LABEL)).required().nullable(),
    };
};
