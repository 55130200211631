import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';
import { PHONE_REGEX } from '../../utils/validation/stringFormats';

/**
 * Destructure necessary imports
 */
const { EMAIL_LABEL, PHONE_LABEL } = PhraseKeys;
const { EMAIL_ERROR_MESSAGE, FIELD_ERROR } = FormFieldErrors;

/**
 * Validation logic
 */
export const optionalContactDetailsSchema = (t: TFunction) => {
    return {
        email: Yup.string()
            .label(t(EMAIL_LABEL))
            .email(t(EMAIL_ERROR_MESSAGE))
            .secureEndingEmail(t(EMAIL_ERROR_MESSAGE))
            .onlyAscii()
            .nullable(),
        phone: Yup.string()
            .label(t(PHONE_LABEL))
            .matches(PHONE_REGEX, {
                message: t(FIELD_ERROR),
                excludeEmptyString: true,
            })
            .nullable(),
    };
};
