import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../config/formFieldNames';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { COUNTRY_LABEL, MAKE_LABEL, OTHER_ID_LABEL, VEHICLE_TYPE_LABEL } = PhraseKeys;
const { COUNTRY, MAKE, OTHER_ID, VEHICLE_TYPE } = FormFieldNames;
const { IF_REQUIRED_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation logic
 */
export const otherVehicleSchema = (t: TFunction) => {
    return {
        make: Yup.string()
            .label(t(MAKE_LABEL))
            .noSpecialChars()
            .requiredIf([COUNTRY, OTHER_ID, VEHICLE_TYPE], t(IF_REQUIRED_ERROR_MESSAGE))
            .nullable(),
        country: Yup.string()
            .label(t(COUNTRY_LABEL))
            .ensure()
            .requiredIf([MAKE, OTHER_ID, VEHICLE_TYPE], t(IF_REQUIRED_ERROR_MESSAGE))
            .nullable(),
        otherId: Yup.string()
            .label(t(OTHER_ID_LABEL))
            .requiredIf([COUNTRY, MAKE, VEHICLE_TYPE], t(IF_REQUIRED_ERROR_MESSAGE))
            .nullable(),
        vehicleType: Yup.string()
            .label(t(VEHICLE_TYPE_LABEL))
            .ensure()
            .requiredIf([COUNTRY, MAKE, OTHER_ID], t(IF_REQUIRED_ERROR_MESSAGE))
            .nullable(),
    };
};
