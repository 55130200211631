import { css } from '@emotion/react';
import { primary, secondary } from '../../variables';

export const navBarWithOptComponentsCss = css({
    alignItems: 'center',
    display: 'grid',
    fontFamily: 'BrandonTextBold, sans-serif',
    fontSize: 16,
    color: primary,
    fontWeight: 500,
    gridTemplateColumns: '1fr 1fr',
    lineHeight: 'unset',
    margin: '0 0.5rem',
    padding: '9px 0',

    '@media screen and (min-width: 568px)': {
        fontSize: 20,
        gridTemplateColumns: '1fr 1fr 1fr',
        margin: '0 1rem',
    },

    '& .extended-navbar-link': {
        color: secondary,
        justifySelf: 'start',
        textDecoration: 'none',

        '& .link-text': {
            lineHeight: '1',
            marginTop: '0.1rem',
        },

        '& svg': {
            fill: primary,
        },

        '&:hover, &:focus': {
            opacity: '50%',
            outline: 'none',
        },

        '@media screen and (min-width: 568px)': {
            justifySelf: 'start',
        },
    },

    '& .center-navbar-component': {
        display: 'none',

        '@media screen and (min-width: 568px)': {
            display: 'grid',
            justifySelf: 'center',
        },
    },

    '& .right-navbar-component': {
        gridColumnStart: '2',
        justifySelf: 'end',

        '@media screen and (min-width: 568px)': {
            gridColumnStart: '3',
        },
    },
});
