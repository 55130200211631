import { TFunction } from 'i18next';
import { OptionType, VehicleTypeKeys } from '@protectorinsurance/ds-can';

const createVehicleType = (id: string) => {
    return `motor.vehicleInformation.vehicleType.select.${id}`;
};

export const getVehicleTypeOptions = (t: TFunction): OptionType[] => {
    const {
        CAR,
        MOTORCYCLE,
        MOPED,
        BUS,
        EXCAVATOR,
        WHEEL_LOADER,
        TRUCK,
        LIGHT_TRUCK,
        MOTORIZED_TOOL,
        TRAILER,
        TERRAIN_SCOOTER,
        TERRAIN_VEHICLE,
        TERRAIN_TRAILER,
        SUP_400_TERRAIN_VEHICLE,
        TRACTOR,
        POWERED_INDUSTRIAL_TRUCK,
        OTHER,
    } = VehicleTypeKeys;
    return [
        { value: CAR, label: t(createVehicleType(CAR)) },
        { value: MOTORCYCLE, label: t(createVehicleType(MOTORCYCLE)) },
        { value: MOPED, label: t(createVehicleType(MOPED)) },
        { value: BUS, label: t(createVehicleType(BUS)) },
        { value: EXCAVATOR, label: t(createVehicleType(EXCAVATOR)) },
        { value: WHEEL_LOADER, label: t(createVehicleType(WHEEL_LOADER)) },
        { value: TRUCK, label: t(createVehicleType(TRUCK)) },
        { value: LIGHT_TRUCK, label: t(createVehicleType(LIGHT_TRUCK)) },
        { value: MOTORIZED_TOOL, label: t(createVehicleType(MOTORIZED_TOOL)) },
        { value: TRAILER, label: t(createVehicleType(TRAILER)) },
        { value: TERRAIN_SCOOTER, label: t(createVehicleType(TERRAIN_SCOOTER)) },
        { value: TERRAIN_VEHICLE, label: t(createVehicleType(TERRAIN_VEHICLE)) },
        { value: TERRAIN_TRAILER, label: t(createVehicleType(TERRAIN_TRAILER)) },
        {
            value: SUP_400_TERRAIN_VEHICLE,
            label: t(createVehicleType(SUP_400_TERRAIN_VEHICLE)),
        },
        { value: TRACTOR, label: t(createVehicleType(TRACTOR)) },
        {
            value: POWERED_INDUSTRIAL_TRUCK,
            label: t(createVehicleType(POWERED_INDUSTRIAL_TRUCK)),
        },
        { value: OTHER, label: t(createVehicleType(OTHER)) },
    ];
};
