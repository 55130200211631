/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React from 'react';
import { FileModel } from '../../../interfaces/models/File.model';
import { Clickable } from '../../../interfaces/types/Events.types';
import { Loader } from '../spinners/loader/Loader';
import { CircleWarningIcon, XIcon } from '../icons/BaseIcons';
import { ReactComponent as CheckIcon } from '../../../assets/icons/base/circle-check.svg';
import { FileStatusEnums } from '../../../interfaces/enums/FileStatus.enums';

export interface FileUploadStatusIconProps {
    file: FileModel;
    handleError?: (e: Clickable, file: FileModel) => void;
    handlePassed?: (e: Clickable, file: FileModel) => void;
    handleWarning?: (e: Clickable, file: FileModel) => void;
}

export const FileUploadStatusIcon = ({
    file,
    handleError,
    handlePassed,
    handleWarning,
}: FileUploadStatusIconProps) => {
    let icon: any = '';
    if (
        file.status === FileStatusEnums.CREATED ||
        file.status === FileStatusEnums.PENDING
    ) {
        icon = (
            <Loader
                testId={file.name}
                fixed={false}
                size={'small'}
                inline={true}
                placement={'end'}
            />
        );
    } else if (file.status === FileStatusEnums.PASSED) {
        icon = handlePassed ? (
            <button
                className={'circle-icon-button'}
                aria-label={`${file.status}`}
                onClick={(e) => handlePassed(e, file)}
            >
                <XIcon />
            </button>
        ) : (
            <CheckIcon
                className={'check-icon'}
                data-testid={`checkmark-${file.name}`}
            />
        );
    } else if (file.status === FileStatusEnums.ERROR) {
        icon = handleError ? (
            <button
                className={'circle-icon-button'}
                aria-label={`${file.status}`}
                onClick={(e) => handleError(e, file)}
            >
                <XIcon />
            </button>
        ) : (
            <XIcon />
        );
    } else if (file.status === FileStatusEnums.FAILED) {
        icon = handleWarning ? (
            <button
                className={'circle-icon-button'}
                aria-label={`${file.status}`}
                onClick={(e) => handleWarning(e, file)}
            >
                <XIcon />
            </button>
        ) : (
            <CircleWarningIcon />
        );
    }

    return (
        <div className={`file-status-icon ${file.status.toLowerCase()}`}>
            {icon}
        </div>
    );
};
