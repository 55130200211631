import {
    Nullable,
    PFError,
    PFRequestError,
    Rejectable,
    Resolvable,
} from '../../../../interfaces';
import {
    actionWithPromise,
    apiService,
    APPLICATION_JSON_HEADER,
} from '../utils';
import { takeLatest } from 'redux-saga/effects';
import { call, put, retry } from 'typed-redux-saga';
import { emptyFn } from '../../../../utils';
import { LogServiceModel } from '../interfaces';

/**
 * Action Types
 */
export enum PDFServiceActionTypes {
    REQUEST = '@app/pdf/REQUEST',
    SUCCESS = '@app/pdf/SUCCESS',
    FAILURE = '@app/pdf/FAILURE',
    ERROR = '@app/pdf/ERROR',
}

/**
 * Action Definitions
 */
export interface PDFServiceAction {
    type: PDFServiceActionTypes;
    data?: any;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const pdfServiceActions = {
    request: actionWithPromise<PDFServiceActionTypes>(
        PDFServiceActionTypes.REQUEST
    ),
    success: actionWithPromise<PDFServiceActionTypes, PDFServiceData>(
        PDFServiceActionTypes.SUCCESS
    ),
    failure: actionWithPromise<PDFServiceActionTypes, PFRequestError>(
        PDFServiceActionTypes.FAILURE
    ),
    error: actionWithPromise<PDFServiceActionTypes, Partial<PFError>>(
        PDFServiceActionTypes.ERROR
    ),
};

export interface PDFServiceData {
    uuid: Nullable<string>;
    lob: Nullable<number>;
    locale: {
        country: Nullable<string>;
        language: Nullable<string>;
    };
    state: any;
}

export interface PDFServiceState {
    data: PDFServiceData;
}

export const pdfInitState = {
    data: {
        uuid: null,
        lob: null,
        country: null,
        state: null,
    },
};

export const pdfServiceReducer = (
    state = pdfInitState,
    { type, data }: PDFServiceAction
) => {
    switch (type) {
        case PDFServiceActionTypes.REQUEST:
            return { ...state };
        case PDFServiceActionTypes.SUCCESS:
            return { ...state, data };
        case PDFServiceActionTypes.ERROR:
            return { ...state, errors: [data] };
        default:
            return state;
    }
};

/**
 * Saga Watchers
 */
export const pdfServiceWatcher = function* () {
    yield takeLatest(PDFServiceActionTypes.REQUEST, pdfServiceSagas.request);
};

/**
 * Saga Functions
 */
export const pdfServiceSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: PDFServiceAction) {
        try {
            const res: any = yield* retry(
                3,
                3000,
                apiService.post,
                `pdf/${data.locale.country}/generate/${data.uuid}`,
                data,
                {
                    headers: APPLICATION_JSON_HEADER,
                }
            );

            const responseData = res.data.data;
            yield put(pdfServiceActions.success(responseData));
            yield resolve(responseData);
        } catch (e) {
            if ((e as any).url?.includes('null')) {
                const requestId = data.requestId;
                const errorPayload: LogServiceModel = {
                    level: 'error',
                    message: `Missing UUID: Tried to generate PDF (${data.locale.country}). X-Request-Id=${requestId}`,
                };
                yield call(apiService.post, `logger/${errorPayload.level}`, {
                    ...errorPayload,
                    req: {
                        requestId,
                        claimsReportId: data.uuid,
                        state: JSON.stringify(data.model),
                    },
                });
            }
            yield put(pdfServiceActions.failure(e));
            yield reject(e);
        }
    },
};
