import { RefObject } from 'react';
import {
    SelectedVehicleTypeModel,
    VehicleModel,
    VehiclesModel,
} from '../../interfaces';
import { svgToString } from '../../../../../utils/svg/svgToString';

export const addDamageImageDataToSelectedVehicle = (
    vehicles: VehiclesModel,
    selectedVehicle: SelectedVehicleTypeModel,
    refSVG?: RefObject<SVGSVGElement>
) => {
    return vehicles.map((v: VehicleModel) => {
        if (v.internalId === selectedVehicle) {
            if (refSVG?.current) {
                const damageImages = v.damageImages;
                const svgId = refSVG?.current?.id;
                const data = encodeURIComponent(svgToString(refSVG.current));
                const exists = damageImages?.find((i) => i.svgId === svgId);
                const images = exists
                    ? damageImages?.map((i) =>
                          i.svgId === svgId ? { svgId, data } : i
                      )
                    : [...(damageImages || []), { svgId, data }];

                return {
                    ...v,
                    damageImages: images,
                };
            }
        }
        return v;
    });
};
