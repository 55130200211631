import { Nullable } from '../../../../../interfaces';

export interface PropertyInformationModel {
    bnr: Nullable<number>;
    city: Nullable<string>;
    gnr: Nullable<number>;
    snr: Nullable<number>;
    street: Nullable<string>;
    zip: Nullable<string>;
}

export const initPropertyInformation: PropertyInformationModel = {
    bnr: null,
    city: null,
    gnr: null,
    snr: null,
    street: null,
    zip: null,
};
