import { isAllDigits, isAllSameDigits } from '../../../utils';

const BUSINESS_NUMBER_WEIGHT: number[] = [3, 2, 7, 6, 5, 4, 3, 2];

export function businessNumberNorwayValidator(businessNumber: string) {
    return {
        isValid: function isValid() {
            if (!businessNumber || businessNumber.length === 0) {
                return false;
            }

            if (!isAllDigits(businessNumber)) {
                return false;
            }

            if (businessNumber.length !== 9) {
                return false;
            }

            let sum = 0;
            for (let i = 0; i < 8; i++) {
                sum +=
                    parseInt(businessNumber.charAt(i)) *
                    BUSINESS_NUMBER_WEIGHT[i];
            }

            let moduloEleven = sum % 11;
            const controlDigit = parseInt(businessNumber.charAt(8));

            if (moduloEleven !== 0) {
                moduloEleven = 11 - moduloEleven;
            }

            return (
                moduloEleven === controlDigit &&
                !isAllSameDigits(businessNumber)
            );
        },
    };
}
