import { css } from '@emotion/react';
import { defaultFocusStyle, primary } from '../../variables';

export const titleCss = css({
    marginTop: 0,
    marginBottom: 8,
    color: primary,

    '& .main-title': {
        display: 'block',
        fontFamily: 'BrandonTextBold, sans-serif',
        fontSize: '2rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        wordBreak: 'break-word',

        '&:focus': {
            ...defaultFocusStyle,
        },
    },

    '@media screen and (min-width: 576px)': {
        '& .main-title': {
            lineHeight: '2.84rem',
        },
    },

    '& .sub-title': {
        display: 'block',
        fontFamily: 'BrandonTextBold, sans-serif',
        fontSize: '1.125rem',
        fontWeight: 300,
        lineHeight: '1.56rem',
        wordBreak: 'break-word',

        '&:focus': {
            ...defaultFocusStyle,
        },
    },
});
