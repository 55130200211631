// @ts-nocheck
import { ComponentType } from 'react';
/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import {
    iconBgColorDefault,
    iconWidthHeightDefault,
} from '../components/variables';
import { IconProps } from '../interfaces/types/IconProps.types';

const iconStyle = ({
    wh = iconWidthHeightDefault,
    bgColor = iconBgColorDefault,
}: IconProps) => {
    return css({
        width: wh,
        height: wh,
        backgroundColor: bgColor,
    });
};

export const withIconStyle =
    (WrappedComponent: ComponentType) =>
    // eslint-disable-next-line react/display-name
    (props: IconProps): JSX.Element =>
        <WrappedComponent css={iconStyle({ ...props })} />;
