import {
    CountryCodeEnums,
    CountryCodeISOArray,
    CountryCodeISOEnums,
    LanguageCodeArray,
    LanguageCodeEnums,
    MomentLocaleEnums,
    ProtectorDomainArray,
    ProtectorDomainEnums,
} from '../interfaces/enums';
import { LocaleModel } from '../interfaces/models';

export class LocaleService {
    public country: CountryCodeISOEnums = CountryCodeISOEnums.NORWAY;
    public language: LanguageCodeEnums = LanguageCodeEnums.NB;
    public moment: MomentLocaleEnums = MomentLocaleEnums.NB;

    findLocaleFromUrl(): LocaleModel | null {
        const url = window.location.pathname.split('/');

        // Check if language and country is specified in the url
        if (
            CountryCodeISOArray.includes(url[1] as CountryCodeISOEnums) &&
            LanguageCodeArray.includes(url[2] as LanguageCodeEnums)
        ) {
            return { country: url[1], language: url[2] };
        }

        return null;
    }

    getLocaleFromUrl(): LocaleModel {
        return this.findLocaleFromUrl() ?? this.getLocaleFromDomain();
    }

    findLocaleFromDomain(): LocaleModel | null {
        const hostArr = window.location.host.split('.').reverse();
        const isUK = hostArr[0] === CountryCodeEnums.UK;
        const host = hostArr
            .splice(0, isUK ? 3 : 2)
            .reverse()
            .join('.') as ProtectorDomainEnums;

        if (ProtectorDomainArray.includes(host)) {
            if (host === ProtectorDomainEnums.DK) {
                return {
                    country: CountryCodeISOEnums.DENMARK,
                    language: LanguageCodeEnums.DA,
                };
            } else if (host === ProtectorDomainEnums.FI) {
                return {
                    country: CountryCodeISOEnums.FINLAND,
                    language: LanguageCodeEnums.FI,
                };
            } else if (host === ProtectorDomainEnums.UK) {
                return {
                    country: CountryCodeISOEnums.UK,
                    language: LanguageCodeEnums.EN,
                };
            } else if (host === ProtectorDomainEnums.SE) {
                return {
                    country: CountryCodeISOEnums.SWEDEN,
                    language: LanguageCodeEnums.SE,
                };
            }
        }

        return null;
    }

    getLocaleFromDomain(): LocaleModel {
        return (
            this.findLocaleFromDomain() ?? {
                country: this.country,
                language: this.language,
            }
        );
    }

    getCountry(): CountryCodeISOEnums {
        return this.country;
    }

    getLocale(): LanguageCodeEnums {
        return this.language;
    }

    getMoment(): MomentLocaleEnums {
        return this.moment;
    }

    initialize() {
        const locale = this.findLocaleFromUrl();

        if (locale) {
            const { language } = locale;

            if (language === LanguageCodeEnums.EN) {
                this.setLanguage(LanguageCodeEnums.EN);
                this.setMoment(MomentLocaleEnums.EN);
            }

            if (language === LanguageCodeEnums.DA) {
                this.setLanguage(LanguageCodeEnums.DA);
                this.setMoment(MomentLocaleEnums.DA);
            }

            if (language === LanguageCodeEnums.SE) {
                this.setLanguage(LanguageCodeEnums.SE);
                this.setMoment(MomentLocaleEnums.SE);
            }

            if (language === LanguageCodeEnums.FI) {
                this.setLanguage(LanguageCodeEnums.FI);
                this.setMoment(MomentLocaleEnums.FI);
            }

            if (language === LanguageCodeEnums.NB) {
                this.setLanguage(LanguageCodeEnums.NB);
                this.setMoment(MomentLocaleEnums.NB);
            }
        }
    }

    setCountry(country: CountryCodeISOEnums): LocaleService {
        this.country = country;

        return this;
    }

    setLanguage(language: LanguageCodeEnums): LocaleService {
        this.language = language;

        return this;
    }

    setMoment(locale: MomentLocaleEnums): LocaleService {
        this.moment = locale;

        return this;
    }
}

export const LocaleServiceInstance = new LocaleService();
