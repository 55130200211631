/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { summaryTextCss } from './SummaryText.style';

interface SummaryTextProps {
    text?: string;
}

export const SummaryText = ({ text }: SummaryTextProps) => {
    return (
        <p className={'summary-title'} tabIndex={0} css={css(summaryTextCss)}>
            {text}
        </p>
    );
};
