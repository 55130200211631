import React from 'react';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { formatAgent } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { formatCompanyName } from '../../../utils/strings/formatStrings';
import { renderAgentInformation } from './components/legal/RenderAgentInformation';
import { renderPolicyNumber } from './components/legal/RenderPolicyNumber';
import { renderPolicyHolder } from './components/legal/RenderPolicyHolder';
import { renderClaimDescription } from './components/legal/RenderClaimDescription';
import { renderAttachment } from './components/legal/RenderAttachment';
import { renderClaimDate } from './components/legal/RenderClaimDate';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';

/**
 * Destructure necessary imports
 */
const {
    LEGAL_EXPENSES_AGENT_INFORMATION,
    LEGAL_EXPENSES_CLAIM_DATE,
    LEGAL_EXPENSES_CLAIM_DESCRIPTION,
    LEGAL_EXPENSES_POLICY_HOLDER,
    LEGAL_EXPENSES_POLICY_NUMBER,
    LEGAL_EXPENSES_UPLOAD_ATTACHMENT,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayLegalSummary = ({ model, t, tWithNS, handleClick, upload }: LpoSummaryProps) => {
    const { agentInformation, claimDate, claimDescription, companyInformation } = model;
    const { attachment } = upload;

    return (
        <>
            {renderClaimDate({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtParseAndFormat(claimDate) : '-',
                path: LEGAL_EXPENSES_CLAIM_DATE,
                handleClick,
            })}

            {renderAgentInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('agentInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.agentInformation,
                buttonText: agentInformation ? formatAgent(agentInformation) : '-',
                path: LEGAL_EXPENSES_AGENT_INFORMATION,
                handleClick,
            })}

            {renderPolicyNumber({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyNumber.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyNumber,
                buttonText: companyInformation.policyNumber ? companyInformation.policyNumber : '-',
                path: LEGAL_EXPENSES_POLICY_NUMBER,
                handleClick,
            })}

            {renderPolicyHolder({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHolder.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHolder,
                buttonText: companyInformation ? formatCompanyName(companyInformation) : '-',
                path: LEGAL_EXPENSES_POLICY_HOLDER,
                handleClick,
            })}

            {renderClaimDescription({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimDescription.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDescription,
                buttonText: claimDescription ? claimDescription : '-',
                path: LEGAL_EXPENSES_CLAIM_DESCRIPTION,
                handleClick,
            })}

            {renderAttachment(
                {
                    textKey: `${tWithNS('uploadAttachment.heading.title')}`,
                    dataTestId: TEST_ID_PREFIX.uploadAttachments,
                    path: LEGAL_EXPENSES_UPLOAD_ATTACHMENT,
                    buttonText: '',
                },
                `${tWithNS('uploadAttachment.heading.title')}`,
                TEST_ID_PREFIX.uploadAttachments,
                attachment
            )}
        </>
    );
};
