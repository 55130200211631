import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from 'hooks/useI18n';
import { wizardRouterActions as wizardActions } from '../sagas/wizardRouter';
import {
    BodyIcon,
    CarIcon,
    Carousel,
    CarouselItem,
    CarouselModel,
    ChangeOfOwnershipIcon,
    Clickable,
    LiabilityIcon,
    LobKeys,
    PageLayout,
    PropertyDamageIcon,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../config/phraseKeys';
import { useGoBack } from '../hooks/useGoBack';
import { selectCustomCAN, selectLob } from '../sagas/selectors/commonSelectors';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { commonActions } from '../sagas/common';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import dispatcherWithPromise from '../utils/dispatcherWithPromise';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { AUTO, CHANGE_OF_OWNERSHIP_SWE, LIABILITY, PERSON, PROPERTY } = LobKeys;
const { INDEX: PERSON_INDEX } = PersonRoutePaths;
const { INDEX: MOTOR_INDEX } = MotorRoutePaths;
const { INDEX: LPO_INDEX } = LpoRoutePaths;

/**
 * Page view and page logic
 */
export const ClaimPage = () => {
    const dispatch = useDispatch();
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob');

    const options = [
        {
            description: tWithNS.t(`description.${PERSON}`),
            icon: <BodyIcon />,
            id: PERSON,
            name: tWithNS.t(`select.${PERSON}`),
        },
        {
            description: tWithNS.t(`description.${AUTO}`),
            icon: <CarIcon />,
            id: AUTO,
            name: tWithNS.t(`select.${AUTO}`),
        },
        {
            description: tWithNS.t(`description.${LIABILITY}`),
            icon: <LiabilityIcon />,
            id: LIABILITY,
            name: tWithNS.t(`select.${LIABILITY}`),
        },
        {
            description: tWithNS.t(`description.${PROPERTY}`),
            icon: <PropertyDamageIcon />,
            id: PROPERTY,
            name: tWithNS.t(`select.${PROPERTY}`),
        },
        {
            description: tWithNS.t(`description.${CHANGE_OF_OWNERSHIP_SWE}`),
            icon: <ChangeOfOwnershipIcon />,
            id: CHANGE_OF_OWNERSHIP_SWE,
            name: tWithNS.t(`select.${CHANGE_OF_OWNERSHIP_SWE}`),
        },
    ];

    const handleBackButton = useGoBack();

    const handleSelect = (id: LobKeys, e: Clickable) => {
        e.preventDefault();

        const option = options.find((x: CarouselModel) => x.id === id);

        if (option) {
            let nextAction = wizardActions.goTo(PERSON_INDEX);
            if (option.id === AUTO) {
                nextAction = wizardActions.goTo(MOTOR_INDEX);
            } else if (option.id === LIABILITY || option.id === CHANGE_OF_OWNERSHIP_SWE || option.id === PROPERTY) {
                nextAction = wizardActions.goTo(LPO_INDEX);
            }

            dispatch(commonActions.update({ lob: option.id }));
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    const renderSelection = options.map((option: CarouselModel, idx: number) => {
        return (
            <CarouselItem
                {...option}
                handleClick={handleSelect}
                isSelected={option.id === lob}
                key={idx}
                {...{ customCAN }}
            />
        );
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={'landing-page'}
            showBackButton={false}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <Carousel>{renderSelection}</Carousel>
        </PageLayout>
    );
};
