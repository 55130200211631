import React from 'react';
import './Loader.scss';

interface IProps {
    fixed?: boolean;
    inline?: boolean;
    size?: 'small' | 'normal';
    placement?: 'center' | 'end' | 'start';
    testId?: string;
}

export function Loader({
    fixed = true,
    inline = false,
    size = 'normal',
    placement = 'center',
    testId = 'loading',
}: IProps) {
    const wrapperClassNames: string[] = ['loader', `justify-content-${placement}`];
    const itemWrapperClassNames = ['item'];

    if (fixed) {
        wrapperClassNames.push('fixed');
    }

    if (inline) {
        wrapperClassNames.push('flex-grow');
    }

    if (size === 'small') {
        wrapperClassNames.push('spinner-size-s');
        itemWrapperClassNames.push('dots-small');
    }

    return (
        <div className={wrapperClassNames.join(' ')} data-testid={`loader-${testId}`}>
            <div className={`dots ${inline ? 'dots-inline' : ''}`}>
                <div className={itemWrapperClassNames.join(' ')} />
                <div className={itemWrapperClassNames.join(' ')} />
                <div className={itemWrapperClassNames.join(' ')} />
            </div>
        </div>
    );
}
