import * as Yup from 'yup';
import { TFunction } from 'i18next';

/**
 * Validation
 */
export const accidentDescriptionFieldSchema = (t: TFunction, label: string) => {
    return {
        accidentDescription: Yup.string().label(t(label)).required().nullable(),
    };
};
