import { PersonRoutePaths } from './personWizardRoutes';
import { FlowKeys, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';

const personProgressBar = (path: PersonRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const url = path.replace(':language', language);
    return url;
};

export const personProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.ACCIDENT]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER_ROLE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_REPORTER),
        personProgressBar(PersonRoutePaths.START_SUMMARY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_INSURANCE_TYPE),
        personProgressBar(PersonRoutePaths.ACCIDENT_PRIVACY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_LOCATION),
        personProgressBar(PersonRoutePaths.ACCIDENT_CLAIM_DATE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_TIME_OF_DAY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_INVOLVED),
        personProgressBar(PersonRoutePaths.ACCIDENT_VEHICLE_INFORMATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_VEHICLE_MOTORCYCLE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PERSON_IN_VEHICLE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_LICENSE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_HELMET),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAPPEN_DOMESTICALLY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_DURING_SPORT),
        personProgressBar(PersonRoutePaths.ACCIDENT_TYPE_OF_SPORT),
        personProgressBar(PersonRoutePaths.ACCIDENT_TEETH_OR_BODY),
        personProgressBar(PersonRoutePaths.ACCIDENT_BODY_PARTS),
        personProgressBar(PersonRoutePaths.ACCIDENT_TEETH),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_CLAIM_CAUSE),
        personProgressBar(PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_PLASTER_TREATMENT),
        personProgressBar(PersonRoutePaths.ACCIDENT_WEEKS_OF_PLASTER_TREATMENT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PREVIOUS_INJURY),
        personProgressBar(PersonRoutePaths.ACCIDENT_DATE_OF_PREVIOUS_INJURY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_DOCTOR_CONTACTED),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_TYPE_OF_MEDICAL_INSTITUTION),
        personProgressBar(PersonRoutePaths.ACCIDENT_DATE_OF_TREATMENT),
        personProgressBar(PersonRoutePaths.ACCIDENT_MEDICAL_INSTITUTION_INFORMATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED),
        personProgressBar(PersonRoutePaths.ACCIDENT_DENTIST_INFORMATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_FINISHED_TREATMENT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_INVALIDITY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_NEED_TRANSPORT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_IN_ELEMENTARY_SCHOOL),
        personProgressBar(PersonRoutePaths.ACCIDENT_UPLOAD_MEDICAL_CERTIFICATE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_EXPENSES),
        personProgressBar(PersonRoutePaths.ACCIDENT_UPLOAD_RECEIPT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_OTHER_INSURANCE),
        personProgressBar(PersonRoutePaths.ACCIDENT_OTHER_INSURANCE_COMPANY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_HAS_ATTACHMENT),
        personProgressBar(PersonRoutePaths.ACCIDENT_UPLOAD_ATTACHMENT),
        personProgressBar(PersonRoutePaths.ACCIDENT_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.ACCIDENT_COMPANY_INFORMATION),
        personProgressBar(PersonRoutePaths.ACCIDENT_ASSOCIATION_INFORMATION),
        personProgressBar(PersonRoutePaths.ACCIDENT_SCHOOL_INFORMATION),
        personProgressBar(PersonRoutePaths.ACCIDENT_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.ACCIDENT_REPORTER_INFORMATION),
        personProgressBar(PersonRoutePaths.ACCIDENT_BANK_ACCOUNT_INFORMATION),
        personProgressBar(PersonRoutePaths.ACCIDENT_BANK_ACCOUNT_INFORMATION_CHILD),
        personProgressBar(PersonRoutePaths.ACCIDENT_FINAL_SUMMARY),
        personProgressBar(PersonRoutePaths.ACCIDENT_REPORT_COMPLETED),
    ],
    [FlowKeys.ILLNESS]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ILLNESS_CLAIM_REPORTER_ROLE),
        personProgressBar(PersonRoutePaths.START_SUMMARY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ILLNESS_INSURANCE_TYPE),
        personProgressBar(PersonRoutePaths.ILLNESS_DIAGNOSIS_INFORMATION),
        personProgressBar(PersonRoutePaths.ILLNESS_PRIVACY),
        personProgressBar(PersonRoutePaths.ILLNESS_CLAIM_DATE),
        personProgressBar(PersonRoutePaths.ILLNESS_DATE_OF_DIAGNOSIS),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HOSPITALISED),
        personProgressBar(PersonRoutePaths.ILLNESS_HOSPITALISATION_INFORMATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ILLNESS_PREVIOUS_ILLNESS),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HAS_OTHER_INSURANCE),
        personProgressBar(PersonRoutePaths.ILLNESS_OTHER_INSURANCE_COMPANY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_ILLNESS_HAS_ATTACHMENT),
        personProgressBar(PersonRoutePaths.ILLNESS_UPLOAD_ATTACHMENT),
        personProgressBar(PersonRoutePaths.ILLNESS_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.ILLNESS_ASSOCIATION_INFORMATION),
        personProgressBar(PersonRoutePaths.ILLNESS_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.ILLNESS_REPORTER_INFORMATION),
        personProgressBar(PersonRoutePaths.ILLNESS_BANK_ACCOUNT_INFORMATION),
        personProgressBar(PersonRoutePaths.ILLNESS_BANK_ACCOUNT_INFORMATION_CHILD),
        personProgressBar(PersonRoutePaths.ILLNESS_FINAL_SUMMARY),
        personProgressBar(PersonRoutePaths.ILLNESS_REPORT_COMPLETED),
    ],
    [FlowKeys.DEATH]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIMANT_AGE_CATEGORY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIM_REPORTER_ROLE),
        personProgressBar(PersonRoutePaths.START_SUMMARY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_INSURANCE_TYPE),
        personProgressBar(PersonRoutePaths.DEATH_COMPANY_INFORMATION),
        personProgressBar(PersonRoutePaths.DEATH_ASSOCIATION_INFORMATION),
        personProgressBar(PersonRoutePaths.DEATH_SCHOOL_INFORMATION),
        personProgressBar(PersonRoutePaths.DEATH_PRIVACY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_ACCIDENT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIM_LOCATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_VEHICLE_INVOLVED),
        personProgressBar(PersonRoutePaths.DEATH_VEHICLE_INFORMATION),
        personProgressBar(PersonRoutePaths.DEATH_DATE_OF_DEATH),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIMANT_MARITAL_STATUS),
        personProgressBar(PersonRoutePaths.DEATH_UPLOAD_DEATH_CERTIFICATE),
        personProgressBar(PersonRoutePaths.DEATH_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.DEATH_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.DEATH_REPORTER_INFORMATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_DEATH_IS_ESTATE_EXECUTOR),
        personProgressBar(PersonRoutePaths.DEATH_ESTATE_EXECUTORS),
        personProgressBar(PersonRoutePaths.DEATH_BANK_ACCOUNT_INFORMATION),
        personProgressBar(PersonRoutePaths.DEATH_FINAL_SUMMARY),
        personProgressBar(PersonRoutePaths.DEATH_REPORT_COMPLETED),
    ],
};
