import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { WEEKS_OF_PLASTER_TREATMENT_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const weeksOfPlasterTreatmentSchema = (t: TFunction) => {
    return {
        weeksOfPlasterTreatment: Yup.string()
            .label(t(WEEKS_OF_PLASTER_TREATMENT_LABEL))
            .required()
            .noSpecialChars()
            .nullable(),
    };
};
