/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { createTheme, LinearProgress, ThemeProvider } from '@mui/material';
import { secondary } from '../../../variables';

const theme = createTheme({
    palette: {
        primary: {
            main: secondary,
        },
    },
});

export const MuiLinearProgress = () => {
    return (
        <ThemeProvider theme={theme}>
            <LinearProgress color={'primary'} />
        </ThemeProvider>
    );
};
