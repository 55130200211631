import { css } from '@emotion/react';
import {
    primary,
    primaryHover,
    secondary,
    secondaryLight,
    white,
} from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const doubleButtonCss = (company?: customCANProps) =>
    css({
        display: 'flex',
        flexDirection: 'row',

        '& button': {
            background: white,
            borderColor: primary,
            color: primary,
            minWidth: 'unset',
            transition: 'all 200ms ease-in-out',

            '&.primary': {
                borderBottomRightRadius: 0,
                borderColor: company ? company.color : secondary,
                borderTopRightRadius: 0,
                color: company ? company.color : secondary,

                '&:hover, &:focus': {
                    background: company ? company.color : secondary,
                    borderColor: company ? company.color : secondary,
                    color: white,
                    boxShadow: `-0.15rem 0 0 0.2rem ${
                        company ? company.color : secondary
                    }`,
                },

                '&.active': {
                    background: company ? company.color : secondary,
                    color: white,

                    '&:hover, &:focus': {
                        background: company
                            ? company.colorFaded
                            : secondaryLight,
                        borderColor: company
                            ? company.colorFaded
                            : secondaryLight,
                        boxShadow: `-0.15rem 0 0 0.2rem ${
                            company ? company.colorFaded : secondaryLight
                        }`,
                    },
                },
            },

            '&.secondary': {
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,

                '&:hover, &:focus': {
                    background: primary,
                    borderColor: primary,
                    color: white,
                    boxShadow: '0.15rem 0 0 0.2rem' + primary,
                },

                '&.active': {
                    background: primary,
                    color: white,

                    '&:hover, &:focus': {
                        background: primaryHover,
                        borderColor: primaryHover,
                        boxShadow: '0.15rem 0 0 0.2rem' + primaryHover,
                    },
                },
            },
        },
    });
