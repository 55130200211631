import { ssnFinlandValidateChecksum } from './ssnFinlandValidateChecksum';
import { ssnFinlandHasValidDate } from './ssnFinlandHasValidDate';
import { Nullable } from '../../../interfaces';

export function ssnFinlandValidator(ssn: Nullable<string>, optional?: boolean) {
    const SSN_REGEX =
        /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-U-Y]|[012]\d[A-F])\d{3}[\dA-Z]$/;

    return {
        isValid: function isValid() {
            if (optional && (ssn === '' || ssn === null)) {
                return true;
            }

            if (!ssn) {
                return false;
            }

            if (!SSN_REGEX.test(ssn)) {
                return false;
            }

            if (!ssnFinlandValidateChecksum(ssn)) {
                return false;
            }

            // Month check
            const month = parseInt(ssn.substring(2, 3));
            if (month >= 2) {
                return false;
            }

            return ssnFinlandHasValidDate(ssn);
        },
    };
}
