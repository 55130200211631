import { RelationshipWithDeceasedModel } from '../models/person/RelationshipWithDeceased';
import { ClaimantModel } from '../models/person/Claimant';
import { ReporterInformationModel } from '../models/person/ReporterInformation';
import { EstateExecutorsTypeModel } from '../models/person/EstateExecutors';
import { BankAccountInformationModel } from '../models/person/BankAccountInformation';
import { HospitalisationInformationTypeModel } from '../models/person/HospitalisationInformation';
import { OtherInsuranceCompanyModel } from '../models/person/OtherInsuranceCompany';
import { AssociationInformationModel } from '../models/person/AssociationInformation';
import { DiagnosisInformationModel } from '../models/person/DiagnosisInformation';
import { DentistTypeModel } from '../models/person/Dentist';
import { MedicalInstitutionTypeModel } from '../models/person/MedicalInstitution';
import { CompanyModel } from '../models/person/Company';
import { TypeOfSportModel } from '../models/person/TypeOfSport';
import { ClaimCauseConsequencesModel } from '../models/person/ClaimCauseConsequences';
import { put, takeEvery } from 'redux-saga/effects';
import {
    actionWithPromise,
    BodyPartListTypeModel,
    CategoryModel,
    ClaimantAgeCategoryModel,
    ClaimantMaritalStatusModel,
    ClaimDescriptionTypeModel,
    ClaimLocationTypeModel,
    ClaimReporterRoleTypeModel,
    ClaimTimeOfDayModel,
    Datable,
    emptyFn,
    InsuranceTypeModel,
    PersonClaimCauseTypeModel,
    PersonInVehicleModel,
    PrivacyTypeModel,
    RegistrationNumberTypeModel,
    Rejectable,
    Resolvable,
    TeethModel,
    TeethOrBodyModel,
    TypeOfMedicalInstitutionModel,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { EditClaimDescriptionTypeModel } from 'models/common/ClaimDescription';
import { WeeksOfPlasterTreatmentTypeModel } from 'models/person/WeeksOfPlasterTreatment';

/**
 * Constants
 */
export enum PersonActionTypes {
    UPDATE = '@person/UPDATE',
    UPDATED = '@person/UPDATED',
}

/**
 * Interfaces
 */
export interface IPersonAction {
    type: PersonActionTypes;
    data?: Partial<PersonState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface PersonState {
    acceptedPrivacy: PrivacyTypeModel;
    association: AssociationInformationModel;
    bankAccountInformation: BankAccountInformationModel;
    bodyPartList: BodyPartListTypeModel;
    category: CategoryModel;
    claimCause: PersonClaimCauseTypeModel;
    claimCauseConsequences: ClaimCauseConsequencesModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimLocation: ClaimLocationTypeModel;
    claimReporter: ClaimReporterRoleTypeModel;
    claimReporterRole: ClaimReporterRoleTypeModel;
    claimTimeOfDay: ClaimTimeOfDayModel;
    claimantAgeCategory: ClaimantAgeCategoryModel;
    claimantInformation: Partial<ClaimantModel>;
    claimantMaritalStatus: ClaimantMaritalStatusModel;
    companyInformation: Partial<CompanyModel>;
    dateOfDiagnosis: Datable;
    dateOfPreviousInjury: Datable;
    dateOfTreatment: Datable;
    dentist: DentistTypeModel;
    diagnosis: DiagnosisInformationModel;
    duringSport: YesNoModel;
    editClaimDescription: EditClaimDescriptionTypeModel;
    estateExecutors: EstateExecutorsTypeModel;
    finishedTreatment: YesNoModel;
    happenDomestically: YesNoModel;
    hasAttachment: YesNoModel;
    hasExpenses: YesNoModel;
    hasHelmet: YesNoModel;
    hasLicense: YesNoModel;
    hasOtherExpenses: YesNoModel;
    hasPlasterTreatment: YesNoModel;
    hospitalisationInformation: HospitalisationInformationTypeModel;
    hospitalised: YesNoModel;
    inElementarySchool: YesNoModel;
    insuranceType: InsuranceTypeModel;
    invalidity: YesNoModel;
    isAccident: YesNoModel;
    isDentistContacted: YesNoModel;
    isDoctorContacted: YesNoModel;
    isEstateExecutor: YesNoModel;
    isVehicleInvolved: YesNoModel;
    isVehicleMotorcycle: YesNoModel;
    medicalInstitution: MedicalInstitutionTypeModel;
    needTransport: YesNoModel;
    otherInsurance: YesNoModel;
    otherInsuranceCompany: OtherInsuranceCompanyModel;
    personInVehicle: PersonInVehicleModel;
    previousIllness: YesNoModel;
    previousInjury: YesNoModel;
    registrationNumber: RegistrationNumberTypeModel;
    relationshipWithDeceased: RelationshipWithDeceasedModel;
    reporterInformation: ReporterInformationModel;
    schoolInformation: Partial<CompanyModel>;
    teeth: TeethModel;
    teethOrBody: TeethOrBodyModel;
    typeOfMedicalInstitution: TypeOfMedicalInstitutionModel;
    typeOfSport: TypeOfSportModel;
    weeksOfPlasterTreatment: WeeksOfPlasterTreatmentTypeModel;
}

/**
 * Initial state
 */
export const personInitState: PersonState = {
    acceptedPrivacy: false,
    association: {
        id: null,
        name: null,
    },
    bankAccountInformation: {
        ownerGivenName: null,
        ownerFamilyName: null,
        bankName: null,
        clearingNumber: null,
        accountNumber: null,
        bankAccountType: null,
    },
    bodyPartList: [],
    category: null,
    claimCause: null,
    claimCauseConsequences: [],
    claimDate: null,
    claimDescription: '',
    claimLocation: null,
    claimReporter: null,
    claimReporterRole: null,
    claimTimeOfDay: null,
    claimantAgeCategory: null,
    claimantInformation: {
        nationalIdentity: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        street: null,
        zip: null,
        city: null,
        role: null,
        employedAt: null,
        salary: null,
        missingSSN: false,
    },
    claimantMaritalStatus: null,
    companyInformation: {
        name: null,
        businessNumber: null,
    },
    dateOfDiagnosis: null,
    dateOfPreviousInjury: null,
    dateOfTreatment: null,
    dentist: null,
    diagnosis: null,
    duringSport: null,
    editClaimDescription: false,
    estateExecutors: [],
    finishedTreatment: null,
    happenDomestically: null,
    hasAttachment: null,
    hasExpenses: null,
    hasHelmet: null,
    hasLicense: null,
    hasOtherExpenses: null,
    hasPlasterTreatment: null,
    hospitalisationInformation: [],
    hospitalised: null,
    inElementarySchool: null,
    insuranceType: null,
    invalidity: null,
    isAccident: null,
    isDentistContacted: null,
    isDoctorContacted: null,
    isEstateExecutor: null,
    isVehicleInvolved: null,
    isVehicleMotorcycle: null,
    medicalInstitution: null,
    needTransport: null,
    otherInsurance: null,
    otherInsuranceCompany: null,
    personInVehicle: null,
    previousIllness: null,
    previousInjury: null,
    relationshipWithDeceased: null,
    registrationNumber: null,
    reporterInformation: {
        firstName: null,
        lastName: null,
        nationalIdentity: null,
        email: null,
        phone: null,
        missingSSN: false,
    },
    schoolInformation: {
        municipality: null,
        name: null,
        street: null,
        zip: null,
        city: null,
    },
    teeth: [],
    teethOrBody: null,
    typeOfMedicalInstitution: null,
    typeOfSport: null,
    weeksOfPlasterTreatment: null,
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = personInitState, { type, data }: IPersonAction) {
    switch (type) {
        case PersonActionTypes.UPDATED:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux actions
 */
export const personActions = {
    update: actionWithPromise<PersonActionTypes, Partial<PersonState>>(PersonActionTypes.UPDATE),
    updated: actionWithPromise<PersonActionTypes, Partial<PersonState>>(PersonActionTypes.UPDATED),
};

/**
 * Saga watchers
 */
export const personWatchers = function* () {
    yield takeEvery(PersonActionTypes.UPDATE, sagas.update);
};

/**
 * Saga functions
 */
export const sagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: IPersonAction) {
        try {
            yield put(personActions.updated(data));
            resolve();
        } catch (e) {
            reject();
        }
    },
};
