export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;

export type TimePickerMode = 'normal' | 'quarterly';

export function numberToTwoDigitString(num: number) {
    return num < 10 ? `0${num}` : `${num}`;
}

export const createHourMinArray = (numberOfElements: number): string[] => {
    return [...new Array(numberOfElements)].map(function (_, index) {
        return numberToTwoDigitString(index);
    });
};

export const createQuarterMinArray = () => {
    return ['00', '15', '30', '45'];
};

export const createMinutesList = (mode: TimePickerMode) => {
    switch (mode) {
        case 'quarterly':
            return createQuarterMinArray();
        case 'normal':
            return createHourMinArray(MINUTES_IN_HOUR);
        default:
            return createHourMinArray(MINUTES_IN_HOUR);
    }
};
