/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { MouseEvent, ReactNode } from 'react';
import { Changable, Focusable, Nullable } from '../../../../interfaces/types';
import { Textarea } from '../../../atoms/textarea/Textarea';
import { damageDescriptionInputFieldCss } from './DamageDescriptionInputField.style';
import { PencilIcon } from '../../../atoms/icons/CommonIcons';
import { customCANProps } from '../../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface DamageDescriptionInputFieldProps {
    customCAN?: customCANProps;
    name: string;
    handleChange: (e: Changable) => void;
    handleBlur: (e: Focusable) => void;
    reference?: (instance: HTMLTextAreaElement) => void;
    placeholder?: string;
    rows?: number;
    inputFieldWrapper?: string;
    value?: Nullable<string>;
    checkIcon?: ReactNode;
    cancelIcon?: ReactNode;
    checkText?: string;
    cancelText?: string;
    id?: string;
    handleClickOK?(e: MouseEvent, id?: Nullable<number> | string): void;
    handleClickCancel?(e: MouseEvent, id?: Nullable<number> | string): void;
    handleClickEdit?(e: MouseEvent, id?: Nullable<number> | string): void;
    editableDamageDescription: boolean;
    editButtonAriaLabel: string;
}

/**
 * Component view and component logic
 */
export const DamageDescriptionInputField = ({
    customCAN,
    name,
    handleChange,
    handleBlur,
    reference,
    placeholder = '',
    rows = 3,
    inputFieldWrapper,
    value = null,
    checkIcon,
    cancelIcon,
    checkText,
    cancelText,
    id,
    handleClickOK,
    handleClickCancel,
    handleClickEdit,
    editableDamageDescription,
    editButtonAriaLabel,
}: DamageDescriptionInputFieldProps) => {
    const onClickOK = (e: MouseEvent) =>
        id !== undefined || id !== null
            ? handleClickOK && handleClickOK(e, id)
            : handleClickOK && handleClickOK(e);

    const onClickCancel = (e: MouseEvent) =>
        id !== undefined || id !== null
            ? handleClickCancel && handleClickCancel(e, id)
            : handleClickCancel && handleClickCancel(e);

    const onClickEdit = (e: MouseEvent) =>
        id !== undefined || id !== null
            ? handleClickEdit && handleClickEdit(e, id)
            : handleClickEdit && handleClickEdit(e);

    return (
        <div
            className={`input-wrapper ${inputFieldWrapper}`}
            css={css(damageDescriptionInputFieldCss(customCAN))}
        >
            {editableDamageDescription ? (
                <div>
                    <Textarea
                        {...{
                            name,
                            handleChange,
                            handleBlur,
                            placeholder,
                            rows,
                            value,
                            reference,
                        }}
                    />
                    <div className={'descInputField'}>
                        <button type={'submit'} onClick={onClickCancel}>
                            {cancelIcon}
                            <span>{cancelText}</span>
                        </button>
                        <button type={'submit'} onClick={onClickOK}>
                            {checkIcon}
                            <span>{checkText}</span>
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <button
                        type={'button'}
                        onClick={onClickEdit}
                        aria-label={editButtonAriaLabel}
                    >
                        {<PencilIcon wh={20} />}
                    </button>
                    <div className={'uneditableDescriptionField'} tabIndex={0}>
                        {value ? value : placeholder}
                    </div>
                </div>
            )}
        </div>
    );
};
