import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    is,
    isYes,
    NoIcon,
    PersonInVehicleKeys,
    TrainIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectIsVehicleMotorcycle, selectPersonInVehicle } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { DRIVER, NO, PASSENGER } = PersonInVehicleKeys;
const { DYN_CAROUSEL_ACCIDENT_HAS_HELMET } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const accidentPersonInVehiclePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const isVehicleMotorcycle = selectIsVehicleMotorcycle(state);
    const options = [
        { id: DRIVER, name: t(`lob.person.accident.personInVehicle.select.${DRIVER}`), icon: <CarIcon /> },
        { id: PASSENGER, name: t(`lob.person.accident.personInVehicle.select.${PASSENGER}`), icon: <TrainIcon /> },
        { id: NO, name: t(`lob.person.accident.personInVehicle.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_PERSON_IN_VEHICLE,
        stateKey: 'personInVehicle',
        i18n: {
            ns: 'lob.person.accident.personInVehicle',
        },
        selectorValue: selectPersonInVehicle(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext(option.id);
                if (isYes(isVehicleMotorcycle) && is(option.id, PASSENGER)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_ACCIDENT_HAS_HELMET);
                } else if (isYes(isVehicleMotorcycle) && is(option.id, DRIVER)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                dispatcherWithPromise(dispatch, personActions.update, { personInVehicle: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
