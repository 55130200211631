export enum OccupationalConditionEnums {
    AT_RECREATIONAL_EVENT = 'AT_RECREATIONAL_EVENT',
    AT_TRAINING_EVENT = 'AT_TRAINING_EVENT',
    AT_WORK = 'AT_WORK',
    AT_WORK_BREAK = 'AT_WORK_BREAK',
    AT_WORK_TRAVEL = 'AT_WORK_TRAVEL',
    EMPLOYEE_HOUSE = 'EMPLOYEE_HOUSE',
    EMPLOYER_PROVIDED_ACTIVITY = 'EMPLOYER_PROVIDED_ACTIVITY',
    HEALTH_CARE_APPOINTMENT = 'HEALTH_CARE_APPOINTMENT',
    ON_WAY_TO_WORK = 'COMMUTING',
    OTHER = 'OTHER',
    OTHER_ACTIVITIES = 'OTHER_ACTIVITIES',
    OTHER_PREMISES = 'OTHER_PREMISES',
    OUTSIDE_WORK_BREAK = 'OUTSIDE_WORK_BREAK',
    SCHOOL = 'SCHOOL',
    TRAVEL_TO_ACTIVITY = 'TRAVEL_TO_ACTIVITY',
    WORKING_AREA = 'WORKING_AREA',
}
