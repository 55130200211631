import { Nullable } from '../../../../../interfaces/types';

export enum TypeOfAnimalCollisionKeys {
    GAME = 'GAME',
    HORSE = 'HORSE',
    LIVESTOCK = 'LIVESTOCK',
    PET = 'PET',
}

export type TypeOfAnimalCollisionTypeKeys = Nullable<TypeOfAnimalCollisionKeys>;
