import React from 'react';
import { useI18n } from '../../../../hooks/useI18n';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { createTextYesNoDontKnow } from '../../../../utils/displayUtils';
import { I18NOptions, isNo, Nullable, SummaryItem, YesNoKeys } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary values
 */
const { isEstateExecutor } = TEST_ID_PREFIX;
const { DYN_CAROUSEL_DEATH_IS_ESTATE_EXECUTOR } = PersonRoutePaths;

/**
 * Interfaces and types
 */
interface DisplayIsEstateExecutorProps {
    model: Nullable<YesNoKeys>;
    onClick?: () => void;
    i18n?: I18NOptions;
}

export const DisplayIsEstateExecutor = ({ model, i18n, onClick }: DisplayIsEstateExecutorProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace); // Internal I18N instance

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));

    /**
     * Renders
     */
    return (
        <>
            <div className={`${isNo(model) ? 'col-4' : 'col-12'} divider text-align-left`}>
                <SummaryItem
                    textKey={tWithPrefix('text.isEstateExecutor')}
                    buttonText={t(createTextYesNoDontKnow(model))}
                    dataTestId={`${isEstateExecutor}${model}`}
                    path={DYN_CAROUSEL_DEATH_IS_ESTATE_EXECUTOR}
                    handleClick={onClick}
                />
            </div>
        </>
    );
};
