import { css } from '@emotion/react';
import { secondary } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const claimListItemCss = (company?: customCANProps) =>
    css({
        '&:hover, &:focus': {
            borderColor: company ? company.color : secondary,
            boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.25)',
            outline: '0',
        },

        '& .col-1': {
            '& svg': {
                height: 60,
                width: 50,
            },
        },

        '& .col-11': {
            textAlign: 'left',

            '& p': {
                marginBottom: '1rem',
            },
        },
    });
