import { css } from '@emotion/react';
import { inputFieldPlaceholder, secondary } from '../../../variables';
import { customCANProps } from '../../../../config/customCANCompanies';

export const damageDescriptionInputFieldCss = (company?: customCANProps) =>
    css({
        margin: 10,
        textAlign: 'left',

        '& TextArea': {
            boxShadow: '-2px 2px 8px -2px #777;',
        },

        '& .descInputField': {
            float: 'right',

            '& button': {
                border: `1px solid ${inputFieldPlaceholder}`,
                cursor: 'pointer',
                borderRadius: '2px',
                textAlign: 'center',
                verticalAlign: 'middle',
                padding: '0.2rem',
                minWidth: '150px',
                width: '150px',
                marginLeft: '3px',
                boxShadow: '-2px 2px 8px -2px #777;',

                '& span': {
                    verticalAlign: 'middle',
                },

                '&:hover': {
                    borderColor: company ? company.color : secondary,
                    boxShadow: '0 0 0 0.2rem rgba(239, 110, 11, 0.25)',
                    outline: 0,
                },
            },
        },

        '& .uneditableDescriptionField': {
            border: `1px solid ${inputFieldPlaceholder}`,
            borderRadius: 2,
            color: inputFieldPlaceholder,
            fontWeight: 400,
            lineHeight: '1.5',
            padding: '0.5rem 1rem',
            transition:
                'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            width: '100%',
            height: '90px',
        },
    });
