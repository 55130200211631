import { Nullable } from '../../../../../interfaces';

export interface ClaimCompanyModel {
    businessNumber: Nullable<string>;
    city: Nullable<string>;
    departmentName: Nullable<string>;
    insuranceNumber: Nullable<number>;
    name: Nullable<string>;
    policyNumber?: Nullable<string>;
    street: Nullable<string>;
    zip: Nullable<string>;
}

export const initClaimCompany: ClaimCompanyModel = {
    businessNumber: null,
    city: null,
    departmentName: null,
    insuranceNumber: null,
    name: null,
    policyNumber: null,
    street: null,
    zip: null,
};
