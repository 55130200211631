import { takeLatest, delay } from 'redux-saga/effects';
import {
    Rejectable,
    Resolvable,
} from '../../../../interfaces/types/Promisable.types';
import { actionWithPromise } from '../../common/utils/actionWithPromise';
import { CANRequestError } from '../../common/interfaces/models/CANRequestError.model';
import { apiService } from '../../common/utils/api';
import { emptyFn } from '../../../../utils/emptyFn';
import { toggleServiceInitState } from './togglesServiceInitState';
import { TogglesServiceActionDataModel } from '../interfaces/models/TogglesServiceActionData.model';
import { TogglesServiceModel } from '../interfaces/models/TogglesService.model';
import { put, call } from 'typed-redux-saga';

/**
 * Action Types
 */
export enum TogglesServiceActionTypes {
    REQUEST = '@app/toggles/REQUEST',
    SUCCESS = '@app/toggles/SUCCESS',
    FAILURE = '@app/toggles/FAILURE',
}

/**
 * Action Definitions
 */
export interface TogglesServiceAction {
    type: TogglesServiceActionTypes;
    data?: TogglesServiceActionDataModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const togglesServiceActions = {
    request: actionWithPromise<
        TogglesServiceActionTypes,
        TogglesServiceActionDataModel
    >(TogglesServiceActionTypes.REQUEST),
    success: actionWithPromise<TogglesServiceActionTypes, TogglesServiceModel>(
        TogglesServiceActionTypes.SUCCESS
    ),
    failure: actionWithPromise<TogglesServiceActionTypes, CANRequestError>(
        TogglesServiceActionTypes.FAILURE
    ),
};

export interface TogglesServiceState extends TogglesServiceModel {}

export const togglesServiceReducer = (
    state = toggleServiceInitState,
    { type, data }: TogglesServiceAction
) => {
    switch (type) {
        case TogglesServiceActionTypes.SUCCESS:
            return { ...data };
        default:
            return state;
    }
};

/**
 * Saga watchers
 */
export const togglesServiceWatcher = function* () {
    yield takeLatest(
        TogglesServiceActionTypes.REQUEST,
        togglesServiceSagas.request
    );
};

/**
 * Saga functions
 */
export const togglesServiceSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: any) {
        while (true) {
            try {
                if (data) {
                    const res: any = yield* call(
                        apiService.get,
                        `toggles/${
                            data.application
                        }/${data.country.toUpperCase()}?userName=${
                            data.userName
                        }`
                    );
                    const responseData = res.data.data;
                    yield put(togglesServiceActions.success(responseData));
                    yield resolve(responseData);
                }
            } catch (e) {
                yield put(togglesServiceActions.failure(e));
                yield reject(e);
            }

            yield delay(1000 * 30);
        }
    },
};
