/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactElement } from 'react';
import {
    Changable,
    Clickable,
    Focusable,
    Nullable,
} from '../../../interfaces/types';
import { InputLabel } from '../../atoms/inputs/InputLabel';
import { InputValidationError } from '../../atoms/inputs/InputValidationError';
import { textareaInputFieldCss } from './TextareaInputField.style';
import { Textarea } from '../../atoms/textarea/Textarea';

interface TextareaInputFieldProps {
    name: string;
    label: string;
    handleChange: (e: Changable) => void;
    handleBlur: (e: Focusable) => void;
    reference?: (instance: HTMLTextAreaElement) => void;
    error?: string | ReactElement;
    placeholder?: string;
    rows?: number;
    inputFieldWrapper?: string;
    editable?: boolean;
    value?: Nullable<string>;
    handleEditButton?: (e: Clickable) => void;
}

export const TextareaInputField = ({
    name,
    label,
    handleChange,
    handleBlur,
    reference,
    error,
    placeholder = '',
    rows = 3,
    inputFieldWrapper,
    value = null,
    editable = false,
    handleEditButton,
}: TextareaInputFieldProps) => {
    return (
        <div
            className={`input-wrapper ${inputFieldWrapper}`}
            css={css(textareaInputFieldCss)}
        >
            <InputLabel
                name={name}
                label={label}
                {...{ handleEditButton: handleEditButton, editable }}
            />
            <Textarea
                name={name}
                rows={rows}
                handleChange={handleChange}
                handleBlur={handleBlur}
                placeholder={placeholder}
                value={value}
                reference={reference}
            />
            <InputValidationError fieldName={name} error={error} />
        </div>
    );
};
