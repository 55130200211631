/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ReactNode } from 'react';
import { Clickable, Nullable } from '../../../interfaces';
import { carouselItemCss } from './CarouselItem.style';
import { Loader } from '../../atoms/spinners/loader/Loader';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface CarouselItemProps {
    className?: string;
    customCAN?: customCANProps;
    description?: string;
    handleClick(
        id: Nullable<number> | string,
        e: Clickable,
        name?: string
    ): void;
    icon?: ReactNode;
    id: Nullable<string> | number;
    isSelected?: boolean;
    loading?: boolean;
    name: string;
}

/**
 * Component view and component logic
 */
export const CarouselItem = ({
    className = '',
    customCAN,
    description,
    handleClick,
    icon,
    id,
    isSelected,
    loading = false,
    name,
}: CarouselItemProps) => {
    const buttonClassName = className + (isSelected ? 'selected' : '');
    const selected = isSelected ? 'selected' : '';

    const renderIcon = () => {
        return <div className={`icon ${selected}`}>{icon}</div>;
    };

    return (
        <button
            className={`carousel__item ${buttonClassName}`}
            onClick={(e) => handleClick(id, e, name)}
            data-testid={id}
            css={css(carouselItemCss(customCAN))}
        >
            {loading && <Loader fixed={false} className={'carousel'} />}
            {icon && renderIcon()}
            <div className={`title ${selected}`}>{name}</div>
            {description && (
                <div className={`description ${selected}`}>{description}</div>
            )}
        </button>
    );
};
