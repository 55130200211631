import { VehicleModel } from '../interfaces/models';

export const initVehicleModel: VehicleModel = {
    registrationNumber: null,
    insuredByProtector: null,
    vehicleType: null,
    make: null,
    type: null,
    model: null,
    color: null,
    weight: null,
    chassisNumber: null,
    vehicleInternalReferenceNumber: null,
    mileage: null,
    damages: [],
    vehicleHasDamages: null,
    otherDamageComment: null,
    internalId: null,
    unidentifiedType: null,
    country: null,
    otherId: null,
    freightWeight: null,
    partyRelation: null,
    isOverRoadCenter: false,
    isResponsible: false,
    driverInformation: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        street: null,
        zip: null,
        city: null,
    },
    passengers: null,
    peopleInjured: {
        numberOfPeopleInjured: null,
        numberOfPeopleHospitalized: null,
    },
    lookupSuccessful: false,
    isTotalDamage: false,
    companyVehicle: null,
    leasing: null,
    VAT: null,
};
