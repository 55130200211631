import React from 'react';
import { ListProps } from 'types/SummaryProps';
import { HospitalisationInformationModel } from '../../models/person/HospitalisationInformation';
import { SummaryText, SummaryNavButton } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../utils/testIdUtil';

interface HospitalisationInformationList extends ListProps {
    list: HospitalisationInformationModel[];
    getText: (model: HospitalisationInformationModel) => string;
    textKey: string;
}

export const HospitalisationInformationSummaryList = ({
    list,
    show,
    handleClick,
    path,
    getText,
    textKey,
}: HospitalisationInformationList) => {
    return (
        <>
            {show && <SummaryText text={textKey} />}
            {show &&
                list.map((hospitalisation, idx: number) => {
                    return (
                        <div key={`hospitalisation-information-${idx}`} className={'sick-leave-list'}>
                            <SummaryNavButton
                                key={`hospitalisation-information-${idx}`}
                                dataTestId={`${TEST_ID_PREFIX.hospitalisationInformation}${idx}`}
                                buttonText={getText(hospitalisation)}
                                {...{ handleClick, path }}
                            />
                        </div>
                    );
                })}
        </>
    );
};
