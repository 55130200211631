import { withIconStyle } from '../../../hoc/withIconStyle';

import { ReactComponent as AccidentIconSVG } from '../../../assets/icons/base/accident.svg';
import { ReactComponent as AlreadyTowedIconSVG } from '../../../assets/icons/base/already-towed.svg';
import { ReactComponent as AnimalBiteIconSVG } from '../../../assets/icons/base/animal-bite.svg';
import { ReactComponent as ArbeidsavklaringspengerIconSVG } from '../../../assets/icons/base/arbeidsavklaringspenger.svg';
import { ReactComponent as AssociationIconSVG } from '../../../assets/icons/base/association.svg';
import { ReactComponent as AtARecreationalEventIconSVG } from '../../../assets/icons/base/at-a-recreational-event.svg';
import { ReactComponent as AtLeast3DaysIconSVG } from '../../../assets/icons/base/at-least-3-days.svg';
import { ReactComponent as AtSchoolIconSVG } from '../../../assets/icons/base/at-school.svg';
import { ReactComponent as AtTheActivityIconSVG } from '../../../assets/icons/base/at-the-activity.svg';
import { ReactComponent as AtWorkDuringTravelIconSVG } from '../../../assets/icons/base/at-work-during-travel.svg';
import { ReactComponent as AtWorkIconSVG } from '../../../assets/icons/base/at-work.svg';
import { ReactComponent as BiologicalFactorIconSVG } from '../../../assets/icons/base/biological-factor.svg';
import { ReactComponent as BoatIconSVG } from '../../../assets/icons/base/boat.svg';
import { ReactComponent as BodyAndTeethIconSVG } from '../../../assets/icons/base/body-and-teeth.svg';
import { ReactComponent as BodyIconSVG } from '../../../assets/icons/base/body.svg';
import { ReactComponent as BothDriverIconSVG } from '../../../assets/icons/base/both-driver.svg';
import { ReactComponent as CarIconSVG } from '../../../assets/icons/base/car.svg';
import { ReactComponent as ChangeOfOwnershipIconSVG } from '../../../assets/icons/base/change-of-ownership.svg';
import { ReactComponent as ChemicalFactorIconSVG } from '../../../assets/icons/base/chemical-factor.svg';
import { ReactComponent as ChemicalInjuryIconSVG } from '../../../assets/icons/base/chemical-injury.svg';
import { ReactComponent as CircleCheckIconSVG } from '../../../assets/icons/base/circle-check.svg';
import { ReactComponent as CircleWarningIconSVG } from '../../../assets/icons/base/circle-warning.svg';
import { ReactComponent as CirculatingRoundaboutIconSVG } from '../../../assets/icons/base/circulating-roundabout.svg';
import { ReactComponent as CollitionIconSVG } from '../../../assets/icons/base/collition.svg';
import { ReactComponent as CompanyIconSVG } from '../../../assets/icons/base/company.svg';
import { ReactComponent as ContinuityInsuranceIconSVG } from '../../../assets/icons/base/continuity-insurance.svg';
import { ReactComponent as ContusionIconSVG } from '../../../assets/icons/base/contusion.svg';
import { ReactComponent as CrackedIconSVG } from '../../../assets/icons/base/cracked.svg';
import { ReactComponent as CrushInjuryIconSVG } from '../../../assets/icons/base/crush-injury.svg';
import { ReactComponent as CutInjuryIconSVG } from '../../../assets/icons/base/cut-injury.svg';
import { ReactComponent as CyberSecurityIconSVG } from '../../../assets/icons/base/cyber-security.svg';
import { ReactComponent as CyclistIconSVG } from '../../../assets/icons/base/cyclist.svg';
import { ReactComponent as DamageToCompartmentIconSVG } from '../../../assets/icons/base/damage-to-compartment.svg';
import { ReactComponent as DarkIconSVG } from '../../../assets/icons/base/dark.svg';
import { ReactComponent as DawnDuskIconSVG } from '../../../assets/icons/base/dawn-dusk.svg';
import { ReactComponent as DaySunIconSVG } from '../../../assets/icons/base/day-sun.svg';
import { ReactComponent as DaylightIconSVG } from '../../../assets/icons/base/daylight.svg';
import { ReactComponent as DeceaseIconSVG } from '../../../assets/icons/base/decease.svg';
import { ReactComponent as DeceasedChildIconSVG } from '../../../assets/icons/base/deceased-child.svg';
import { ReactComponent as DelayIconSVG } from '../../../assets/icons/base/delay.svg';
import { ReactComponent as DiagnosIconSVG } from '../../../assets/icons/base/diagnos.svg';
import { ReactComponent as DisabilityBenefitIconSVG } from '../../../assets/icons/base/disability-benefit.svg';
import { ReactComponent as DislocatedIconSVG } from '../../../assets/icons/base/dislocated.svg';
import { ReactComponent as DitchIconSVG } from '../../../assets/icons/base/ditch.svg';
import { ReactComponent as DoctorIconSVG } from '../../../assets/icons/base/doctor.svg';
import { ReactComponent as DocumentIconSVG } from '../../../assets/icons/base/document.svg';
import { ReactComponent as DocumentExcelIconSVG } from '../../../assets/icons/base/document-excel.svg';
import { ReactComponent as DocumentImageIconSVG } from '../../../assets/icons/base/document-image.svg';
import { ReactComponent as DocumentPDFIconSVG } from '../../../assets/icons/base/document-pdf.svg';
import { ReactComponent as DocumentWordIconSVG } from '../../../assets/icons/base/document-word.svg';
import { ReactComponent as DryRoadIconSVG } from '../../../assets/icons/base/dry-road.svg';
import { ReactComponent as DuringAHealthCareAppointmentIconSVG } from '../../../assets/icons/base/during-a-health-care-appointment.svg';
import { ReactComponent as DuringFreetimeIconSVG } from '../../../assets/icons/base/during-freetime.svg';
import { ReactComponent as ECareIconSVG } from '../../../assets/icons/base/e-care.svg';
import { ReactComponent as EmployeeIconSVG } from '../../../assets/icons/base/employee.svg';
import { ReactComponent as EmployeesHomeRemoteIconSVG } from '../../../assets/icons/base/employees-home-remote.svg';
import { ReactComponent as EmployerIconSVG } from '../../../assets/icons/base/employer.svg';
import { ReactComponent as EngineDamageIconSVG } from '../../../assets/icons/base/engine-damage.svg';
import { ReactComponent as EnteringRoundaboutIconSVG } from '../../../assets/icons/base/entering-roundabout.svg';
import { ReactComponent as EntrepreneurIconSVG } from '../../../assets/icons/base/entrepreneur.svg';
import { ReactComponent as ExitingRoundaboutIconSVG } from '../../../assets/icons/base/exiting-roundabout.svg';
import { ReactComponent as ExtraLightsIconSVG } from '../../../assets/icons/base/extra-lights.svg';
import { ReactComponent as EyewearIconSVG } from '../../../assets/icons/base/eyewear.svg';
import { ReactComponent as FallInjuryIconSVG } from '../../../assets/icons/base/fall-injury.svg';
import { ReactComponent as FireDamagesIconSVG } from '../../../assets/icons/base/fire-damages.svg';
import { ReactComponent as FireInjuryIconSVG } from '../../../assets/icons/base/fire-injury.svg';
import { ReactComponent as FlytandeIconSVG } from '../../../assets/icons/base/flytande.svg';
import { ReactComponent as ForeignRegistrationIconSVG } from '../../../assets/icons/base/foreign-registration.svg';
import { ReactComponent as GarbageIconSVG } from '../../../assets/icons/base/garbage.svg';
import { ReactComponent as GroupAccidentIconSVG } from '../../../assets/icons/base/group-accident.svg';
import { ReactComponent as HeadOnCollisionIconSVG } from '../../../assets/icons/base/head-on-collision.svg';
import { ReactComponent as HealthCompensationIconSVG } from '../../../assets/icons/base/health-compensation.svg';
import { ReactComponent as HealthInsuranceIconSVG } from '../../../assets/icons/base/health-insurance.svg';
import { ReactComponent as HearingAidIconSVG } from '../../../assets/icons/base/hearing-aid.svg';
import { ReactComponent as HighSchoolIconSVG } from '../../../assets/icons/base/high-school.svg';
import { ReactComponent as HigherEducationIconSVG } from '../../../assets/icons/base/higher-education.svg';
import { ReactComponent as HitAndRunIconSVG } from '../../../assets/icons/base/hit-and-run.svg';
import { ReactComponent as HitParkedIconSVG } from '../../../assets/icons/base/hit-parked.svg';
import { ReactComponent as HorseIconSVG } from '../../../assets/icons/base/horse.svg';
import { ReactComponent as HospitalIconSVG } from '../../../assets/icons/base/hospital.svg';
import { ReactComponent as HousingAssociationIconSVG } from '../../../assets/icons/base/housing-association.svg';
import { ReactComponent as IAmIllIconSVG } from '../../../assets/icons/base/i-am-ill.svg';
import { ReactComponent as IAmInjuredIconSVG } from '../../../assets/icons/base/i-am-injured.svg';
import { ReactComponent as IDontKnowIconSVG } from '../../../assets/icons/base/i-dont-know.svg';
import { ReactComponent as IllnessIconSVG } from '../../../assets/icons/base/illness.svg';
import { ReactComponent as InOtherActivitiesIconSVG } from '../../../assets/icons/base/in-other-activities.svg';
import { ReactComponent as InTheCourseOfActivitiesToMaintainTheAbilityToWorkIconSVG } from '../../../assets/icons/base/in-the-course-of-activities-to-maintain-the-ability-to-work.svg';
import { ReactComponent as InTheCourseOfTravelToEventIconSVG } from '../../../assets/icons/base/in-the-course-of-travel-to-event.svg';
import { ReactComponent as InTheWorkingAreaIconSVG } from '../../../assets/icons/base/in-the-working-area.svg';
import { ReactComponent as InjuryDayIconSVG } from '../../../assets/icons/base/injury-day.svg';
import { ReactComponent as InsuredByProtectorIconSVG } from '../../../assets/icons/base/insured-by-protector.svg';
import { ReactComponent as IntersectionIconSVG } from '../../../assets/icons/base/intersection.svg';
import { ReactComponent as KindergardenChildIconSVG } from '../../../assets/icons/base/kindergarden-child.svg';
import { ReactComponent as KindergardenIsInsuredIconSVG } from '../../../assets/icons/base/kindergarden-is-insured.svg';
import { ReactComponent as KindergardenIconSVG } from '../../../assets/icons/base/kindergarden.svg';
import { ReactComponent as LaneChangeIconSVG } from '../../../assets/icons/base/lane-change.svg';
import { ReactComponent as LegalIconSVG } from '../../../assets/icons/base/legal.svg';
import { ReactComponent as LiabilityIconSVG } from '../../../assets/icons/base/liability.svg';
import { ReactComponent as LicensePlateIconSVG } from '../../../assets/icons/base/license-plate.svg';
import { ReactComponent as LiftingInjuryIconSVG } from '../../../assets/icons/base/lifting-injury.svg';
import { ReactComponent as LightControlledIntersectionIconSVG } from '../../../assets/icons/base/light-controlled-intersection.svg';
import { ReactComponent as LivestockIconSVG } from '../../../assets/icons/base/livestock.svg';
import { ReactComponent as LossOfLicenseSVG } from '../../../assets/icons/base/loss-of-license.svg';
import { ReactComponent as LostKeysIconSVG } from '../../../assets/icons/base/lost-keys.svg';
import { ReactComponent as MedicalCareIconSVG } from '../../../assets/icons/base/medical-care.svg';
import { ReactComponent as MotorcycleIconSVG } from '../../../assets/icons/base/motorcycle.svg';
import { ReactComponent as MultipleCollisionIconSVG } from '../../../assets/icons/base/multiple-collision.svg';
import { ReactComponent as MunicipalityIconSVG } from '../../../assets/icons/base/municipality.svg';
import { ReactComponent as NarrowRoadCollisionIconSVG } from '../../../assets/icons/base/narrow-road-collision.svg';
import { ReactComponent as NaturalDisasterIconSVG } from '../../../assets/icons/base/natural-disaster.svg';
import { ReactComponent as NightMoonIconSVG } from '../../../assets/icons/base/night-moon.svg';
import { ReactComponent as NoCarIconSVG } from '../../../assets/icons/base/no-car.svg';
import { ReactComponent as NoIconSVG } from '../../../assets/icons/base/no.svg';
import { ReactComponent as OnBehalfOfAChildIconSVG } from '../../../assets/icons/base/on-behalf-of-a-child.svg';
import { ReactComponent as OnBehalfOfAnInjuredIconSVG } from '../../../assets/icons/base/on-behalf-of-an-injured.svg';
import { ReactComponent as OnBehalfOfIllChildIconSVG } from '../../../assets/icons/base/on-behalf-of-ill-child.svg';
import { ReactComponent as OnBehalfOfSbIllIconSVG } from '../../../assets/icons/base/on-behalf-of-sb-ill.svg';
import { ReactComponent as OneOrTwoDaysIconSVG } from '../../../assets/icons/base/one-or-two-days.svg';
import { ReactComponent as OtherCostsIconSVG } from '../../../assets/icons/base/other-costs.svg';
import { ReactComponent as OtherDriverIconSVG } from '../../../assets/icons/base/other-driver.svg';
import { ReactComponent as OtherInjuriesIconSVG } from '../../../assets/icons/base/other-injuries.svg';
import { ReactComponent as OtherPeopleIconSVG } from '../../../assets/icons/base/other-people.svg';
import { ReactComponent as OutOfSchoolHoursIconSVG } from '../../../assets/icons/base/out-of-school-hours.svg';
import { ReactComponent as OutsideWorkingAreaDuringBreakIconSVG } from '../../../assets/icons/base/outside-working-area-during-break.svg';
import { ReactComponent as OvertakingIconSVG } from '../../../assets/icons/base/overtaking.svg';
import { ReactComponent as ParentsChildIconSVG } from '../../../assets/icons/base/parents-child.svg';
import { ReactComponent as ParkedIconSVG } from '../../../assets/icons/base/parked.svg';
import { ReactComponent as ParkingIconSVG } from '../../../assets/icons/base/parking.svg';
import { ReactComponent as PartnerIconSVG } from '../../../assets/icons/base/partner.svg';
import { ReactComponent as PedestrianIconSVG } from '../../../assets/icons/base/pedestrian.svg';
import { ReactComponent as PermisjonIconSVG } from '../../../assets/icons/base/permisjon.svg';
import { ReactComponent as PetIconSVG } from '../../../assets/icons/base/pet.svg';
import { ReactComponent as PhysicalFactorIconSVG } from '../../../assets/icons/base/physical-factor.svg';
import { ReactComponent as PracticalStudiesIconSVG } from '../../../assets/icons/base/practical-studies.svg';
import { ReactComponent as PressureIconSVG } from '../../../assets/icons/base/pressure.svg';
import { ReactComponent as PrivatePersonIsInsuredIconSVG } from '../../../assets/icons/base/private-person-is-insured.svg';
import { ReactComponent as PropertyDamageIconSVG } from '../../../assets/icons/base/property-damage.svg';
import { ReactComponent as QuarrelIconSVG } from '../../../assets/icons/base/quarrel.svg';
import { ReactComponent as RadiationIconSVG } from '../../../assets/icons/base/radiation.svg';
import { ReactComponent as RearEndedIconSVG } from '../../../assets/icons/base/rear-ended.svg';
import { ReactComponent as RecentlyClaimIconSVG } from '../../../assets/icons/base/recently-claim.svg';
import { ReactComponent as RecentlyInsuredIconSVG } from '../../../assets/icons/base/recently-insured.svg';
import { ReactComponent as RelativeCopyIconSVG } from '../../../assets/icons/base/relative-copy.svg';
import { ReactComponent as RelativeIconSVG } from '../../../assets/icons/base/relative.svg';
import { ReactComponent as ReversingIconSVG } from '../../../assets/icons/base/reversing.svg';
import { ReactComponent as RighOfWayIntersectionIconSVG } from '../../../assets/icons/base/righ-of-way-intersection.svg';
import { ReactComponent as RoadIconSVG } from '../../../assets/icons/base/road.svg';
import { ReactComponent as RoundAboutIconSVG } from '../../../assets/icons/base/round-about.svg';
import { ReactComponent as SamboIconSVG } from '../../../assets/icons/base/sambo.svg';
import { ReactComponent as SchoolNurseIconSVG } from '../../../assets/icons/base/school-nurse.svg';
import { ReactComponent as SchoolTransferIconSVG } from '../../../assets/icons/base/school-transfer.svg';
import { ReactComponent as SelfOwnDriverIconSVG } from '../../../assets/icons/base/self-own-driver.svg';
import { ReactComponent as SeventhGradeIconSVG } from '../../../assets/icons/base/seventh-grade.svg';
import { ReactComponent as ShockInjuryIconSVG } from '../../../assets/icons/base/shock-injury.svg';
import { ReactComponent as ShockOrCrashInjuryIconSVG } from '../../../assets/icons/base/shock-or-crash-injury.svg';
import { ReactComponent as SickPaySVG } from '../../../assets/icons/base/sick-pay.svg';
import { ReactComponent as SmallPersonIconSVG } from '../../../assets/icons/base/small-person.svg';
import { ReactComponent as SnowRoadIconSVG } from '../../../assets/icons/base/snow-road.svg';
import { ReactComponent as SpesialistIconSVG } from '../../../assets/icons/base/spesialist.svg';
import { ReactComponent as SpouseOrCohabitantIconSVG } from '../../../assets/icons/base/spouse-or-cohabitant.svg';
import { ReactComponent as StationaryObjectIconSVG } from '../../../assets/icons/base/stationary-object.svg';
import { ReactComponent as SteeringWheelIconSVG } from '../../../assets/icons/base/steering-wheel.svg';
import { ReactComponent as StudentIconSVG } from '../../../assets/icons/base/student.svg';
import { ReactComponent as TSectionIconSVG } from '../../../assets/icons/base/t-section.svg';
import { ReactComponent as TeethInjuryIconSVG } from '../../../assets/icons/base/teeth-injury.svg';
import { ReactComponent as TemperatureInjuryIconSVG } from '../../../assets/icons/base/temperature-injury.svg';
import { ReactComponent as TheftAndDamageIconSVG } from '../../../assets/icons/base/theft-and-damage.svg';
import { ReactComponent as TheoreticalStudiesIconSVG } from '../../../assets/icons/base/theoretical-studies.svg';
import { ReactComponent as TrainIconSVG } from '../../../assets/icons/base/train.svg';
import { ReactComponent as TrainingEventIconSVG } from '../../../assets/icons/base/training-event.svg';
import { ReactComponent as TravelIconSVG } from '../../../assets/icons/base/travel.svg';
import { ReactComponent as TuberculosisIconSVG } from '../../../assets/icons/base/tuberculosis.svg';
import { ReactComponent as TwistedKneeIconSVG } from '../../../assets/icons/base/twisted-knee.svg';
import { ReactComponent as UnregisteredIconSVG } from '../../../assets/icons/base/unregistered.svg';
import { ReactComponent as VibrationIconSVG } from '../../../assets/icons/base/vibration.svg';
import { ReactComponent as ViolenceInjuryIconSVG } from '../../../assets/icons/base/violence-injury.svg';
import { ReactComponent as VirusIconSVG } from '../../../assets/icons/base/virus.svg';
import { ReactComponent as VocationalLabourMarketTrainingIconSVG } from '../../../assets/icons/base/vocational-labour-market-training.svg';
import { ReactComponent as WayToActivityIconSVG } from '../../../assets/icons/base/way-to-activity.svg';
import { ReactComponent as WayToSchoolIconSVG } from '../../../assets/icons/base/way-to-school.svg';
import { ReactComponent as WayToWorkIconSVG } from '../../../assets/icons/base/way-to-work.svg';
import { ReactComponent as WetRoadIconSVG } from '../../../assets/icons/base/wet-road.svg';
import { ReactComponent as WheelsIconSVG } from '../../../assets/icons/base/wheels.svg';
import { ReactComponent as WildAnimalIconSVG } from '../../../assets/icons/base/wild-animal.svg';
import { ReactComponent as WindshieldIconSVG } from '../../../assets/icons/base/windshield.svg';
import { ReactComponent as WorkCompIconSVG } from '../../../assets/icons/base/work-comp.svg';
import { ReactComponent as WorkingAreaOtherPremisesIconSVG } from '../../../assets/icons/base/working-area-other-premises.svg';
import { ReactComponent as WorkingAreaWorkingProcessMeetingIconSVG } from '../../../assets/icons/base/working-area-working-process-meeting.svg';
import { ReactComponent as WrongFuelIconSVG } from '../../../assets/icons/base/wrong-fuel.svg';
import { ReactComponent as XIconSVG } from '../../../assets/icons/base/x.svg';
import { ReactComponent as YesIconSVG } from '../../../assets/icons/base/yes.svg';

export const AccidentIcon = withIconStyle(AccidentIconSVG);
export const AlreadyTowedIcon = withIconStyle(AlreadyTowedIconSVG);
export const AnimalBiteIcon = withIconStyle(AnimalBiteIconSVG);
export const ArbeidsavklaringspengerIcon = withIconStyle(
    ArbeidsavklaringspengerIconSVG
);
export const AssociationIcon = withIconStyle(AssociationIconSVG);
export const AtARecreationalEventIcon = withIconStyle(
    AtARecreationalEventIconSVG
);
export const AtLeast3DaysIcon = withIconStyle(AtLeast3DaysIconSVG);
export const AtSchoolIcon = withIconStyle(AtSchoolIconSVG);
export const AtTheActivityIcon = withIconStyle(AtTheActivityIconSVG);
export const AtWorkDuringTravelIcon = withIconStyle(AtWorkDuringTravelIconSVG);
export const AtWorkIcon = withIconStyle(AtWorkIconSVG);
export const BiologicalFactorIcon = withIconStyle(BiologicalFactorIconSVG);
export const BoatIcon = withIconStyle(BoatIconSVG);
export const BodyAndTeethIcon = withIconStyle(BodyAndTeethIconSVG);
export const BodyIcon = withIconStyle(BodyIconSVG);
export const BothDriverIcon = withIconStyle(BothDriverIconSVG);
export const CarIcon = withIconStyle(CarIconSVG);
export const ChangeOfOwnershipIcon = withIconStyle(ChangeOfOwnershipIconSVG);
export const ChemicalFactorIcon = withIconStyle(ChemicalFactorIconSVG);
export const ChemicalInjuryIcon = withIconStyle(ChemicalInjuryIconSVG);
export const CircleCheckIcon = withIconStyle(CircleCheckIconSVG);
export const CircleWarningIcon = withIconStyle(CircleWarningIconSVG);
export const CirculatingRoundaboutIcon = withIconStyle(
    CirculatingRoundaboutIconSVG
);
export const CollitionIcon = withIconStyle(CollitionIconSVG);
export const CompanyIcon = withIconStyle(CompanyIconSVG);
export const ContinuityInsuranceIcon = withIconStyle(
    ContinuityInsuranceIconSVG
);
export const ContusionIcon = withIconStyle(ContusionIconSVG);
export const CrackedIcon = withIconStyle(CrackedIconSVG);
export const CrushInjuryIcon = withIconStyle(CrushInjuryIconSVG);
export const CutInjuryIcon = withIconStyle(CutInjuryIconSVG);
export const CyberSecurityIcon = withIconStyle(CyberSecurityIconSVG);
export const CyclistIcon = withIconStyle(CyclistIconSVG);
export const DamageToCompartmentIcon = withIconStyle(
    DamageToCompartmentIconSVG
);
export const DarkIcon = withIconStyle(DarkIconSVG);
export const DawnDuskIcon = withIconStyle(DawnDuskIconSVG);
export const DaySunIcon = withIconStyle(DaySunIconSVG);
export const DaylightIcon = withIconStyle(DaylightIconSVG);
export const DeceaseIcon = withIconStyle(DeceaseIconSVG);
export const DeceasedChildIcon = withIconStyle(DeceasedChildIconSVG);
export const DelayIcon = withIconStyle(DelayIconSVG);
export const DiagnosIcon = withIconStyle(DiagnosIconSVG);
export const DisabilityBenefitIcon = withIconStyle(DisabilityBenefitIconSVG);
export const DislocatedIcon = withIconStyle(DislocatedIconSVG);
export const DitchIcon = withIconStyle(DitchIconSVG);
export const DoctorIcon = withIconStyle(DoctorIconSVG);
export const DocumentIcon = withIconStyle(DocumentIconSVG);
export const DocumentExcelIcon = withIconStyle(DocumentExcelIconSVG);
export const DocumentImageIcon = withIconStyle(DocumentImageIconSVG);
export const DocumentPDFIcon = withIconStyle(DocumentPDFIconSVG);
export const DocumentWordIcon = withIconStyle(DocumentWordIconSVG);
export const DryRoadIcon = withIconStyle(DryRoadIconSVG);
export const DuringAHealthCareAppointmentIcon = withIconStyle(
    DuringAHealthCareAppointmentIconSVG
);
export const DuringFreetimeIcon = withIconStyle(DuringFreetimeIconSVG);
export const ECareIcon = withIconStyle(ECareIconSVG);
export const EmployeeIcon = withIconStyle(EmployeeIconSVG);
export const EmployeesHomeRemoteIcon = withIconStyle(
    EmployeesHomeRemoteIconSVG
);
export const EmployerIcon = withIconStyle(EmployerIconSVG);
export const EngineDamageIcon = withIconStyle(EngineDamageIconSVG);
export const EnteringRoundaboutIcon = withIconStyle(EnteringRoundaboutIconSVG);
export const EntrepreneurIcon = withIconStyle(EntrepreneurIconSVG);
export const ExitingRoundaboutIcon = withIconStyle(ExitingRoundaboutIconSVG);
export const ExtraLightsIcon = withIconStyle(ExtraLightsIconSVG);
export const EyewearIcon = withIconStyle(EyewearIconSVG);
export const FallInjuryIcon = withIconStyle(FallInjuryIconSVG);
export const FireDamagesIcon = withIconStyle(FireDamagesIconSVG);
export const FireInjuryIcon = withIconStyle(FireInjuryIconSVG);
export const FlytandeIcon = withIconStyle(FlytandeIconSVG);
export const ForeignRegistrationIcon = withIconStyle(
    ForeignRegistrationIconSVG
);
export const GarbageIcon = withIconStyle(GarbageIconSVG);
export const GroupAccidentIcon = withIconStyle(GroupAccidentIconSVG);
export const HeadOnCollisionIcon = withIconStyle(HeadOnCollisionIconSVG);
export const HealthCompensationIcon = withIconStyle(HealthCompensationIconSVG);
export const HealthInsuranceIcon = withIconStyle(HealthInsuranceIconSVG);
export const HearingAidIcon = withIconStyle(HearingAidIconSVG);
export const HighSchoolIcon = withIconStyle(HighSchoolIconSVG);
export const HigherEducationIcon = withIconStyle(HigherEducationIconSVG);
export const HitAndRunIcon = withIconStyle(HitAndRunIconSVG);
export const HitParkedIcon = withIconStyle(HitParkedIconSVG);
export const HorseIcon = withIconStyle(HorseIconSVG);
export const HospitalIcon = withIconStyle(HospitalIconSVG);
export const HousingAssociationIcon = withIconStyle(HousingAssociationIconSVG);
export const IAmIllIcon = withIconStyle(IAmIllIconSVG);
export const IAmInjuredIcon = withIconStyle(IAmInjuredIconSVG);
export const IDontKnowIcon = withIconStyle(IDontKnowIconSVG);
export const IllnessIcon = withIconStyle(IllnessIconSVG);
export const InOtherActivitiesIcon = withIconStyle(InOtherActivitiesIconSVG);
export const InTheCourseOfActivitiesToMaintainTheAbilityToWorkIcon =
    withIconStyle(InTheCourseOfActivitiesToMaintainTheAbilityToWorkIconSVG);
export const InTheCourseOfTravelToEventIcon = withIconStyle(
    InTheCourseOfTravelToEventIconSVG
);
export const InTheWorkingAreaIcon = withIconStyle(InTheWorkingAreaIconSVG);
export const InjuryDayIcon = withIconStyle(InjuryDayIconSVG);
export const InsuredByProtectorIcon = withIconStyle(InsuredByProtectorIconSVG);
export const IntersectionIcon = withIconStyle(IntersectionIconSVG);
export const KindergardenChildIcon = withIconStyle(KindergardenChildIconSVG);
export const KindergardenIsInsuredIcon = withIconStyle(
    KindergardenIsInsuredIconSVG
);
export const KindergardenIcon = withIconStyle(KindergardenIconSVG);
export const LaneChangeIcon = withIconStyle(LaneChangeIconSVG);
export const LegalIcon = withIconStyle(LegalIconSVG);
export const LiabilityIcon = withIconStyle(LiabilityIconSVG);
export const LicensePlateIcon = withIconStyle(LicensePlateIconSVG);
export const LiftingInjuryIcon = withIconStyle(LiftingInjuryIconSVG);
export const LightControlledIntersectionIcon = withIconStyle(
    LightControlledIntersectionIconSVG
);
export const LivestockIcon = withIconStyle(LivestockIconSVG);
export const LossOfLicenseIcon = withIconStyle(LossOfLicenseSVG);
export const LostKeysIcon = withIconStyle(LostKeysIconSVG);
export const MedicalCareIcon = withIconStyle(MedicalCareIconSVG);
export const MotorcycleIcon = withIconStyle(MotorcycleIconSVG);
export const MultipleCollisionIcon = withIconStyle(MultipleCollisionIconSVG);
export const MunicipalityIcon = withIconStyle(MunicipalityIconSVG);
export const NarrowRoadCollisionIcon = withIconStyle(
    NarrowRoadCollisionIconSVG
);
export const NaturalDisasterIcon = withIconStyle(NaturalDisasterIconSVG);
export const NightMoonIcon = withIconStyle(NightMoonIconSVG);
export const NoCarIcon = withIconStyle(NoCarIconSVG);
export const NoIcon = withIconStyle(NoIconSVG);
export const OnBehalfOfAChildIcon = withIconStyle(OnBehalfOfAChildIconSVG);
export const OnBehalfOfAnInjuredIcon = withIconStyle(
    OnBehalfOfAnInjuredIconSVG
);
export const OnBehalfOfIllChildIcon = withIconStyle(OnBehalfOfIllChildIconSVG);
export const OnBehalfOfSbIllIcon = withIconStyle(OnBehalfOfSbIllIconSVG);
export const OneOrTwoDaysIcon = withIconStyle(OneOrTwoDaysIconSVG);
export const OtherCostsIcon = withIconStyle(OtherCostsIconSVG);
export const OtherDriverIcon = withIconStyle(OtherDriverIconSVG);
export const OtherInjuriesIcon = withIconStyle(OtherInjuriesIconSVG);
export const OtherPeopleIcon = withIconStyle(OtherPeopleIconSVG);
export const OutOfSchoolHoursIcon = withIconStyle(OutOfSchoolHoursIconSVG);
export const OutsideWorkingAreaDuringBreakIcon = withIconStyle(
    OutsideWorkingAreaDuringBreakIconSVG
);
export const OvertakingIcon = withIconStyle(OvertakingIconSVG);
export const ParentsChildIcon = withIconStyle(ParentsChildIconSVG);
export const ParkedIcon = withIconStyle(ParkedIconSVG);
export const ParkingIcon = withIconStyle(ParkingIconSVG);
export const PartnerIcon = withIconStyle(PartnerIconSVG);
export const PedestrianIcon = withIconStyle(PedestrianIconSVG);
export const PermisjonIcon = withIconStyle(PermisjonIconSVG);
export const PetIcon = withIconStyle(PetIconSVG);
export const PhysicalFactorIcon = withIconStyle(PhysicalFactorIconSVG);
export const PracticalStudiesIcon = withIconStyle(PracticalStudiesIconSVG);
export const PressureIcon = withIconStyle(PressureIconSVG);
export const PrivatePersonIsInsuredIcon = withIconStyle(
    PrivatePersonIsInsuredIconSVG
);
export const PropertyDamageIcon = withIconStyle(PropertyDamageIconSVG);
export const QuarrelIcon = withIconStyle(QuarrelIconSVG);
export const RadiationIcon = withIconStyle(RadiationIconSVG);
export const RearEndedIcon = withIconStyle(RearEndedIconSVG);
export const RecentlyClaimIcon = withIconStyle(RecentlyClaimIconSVG);
export const RecentlyInsuredIcon = withIconStyle(RecentlyInsuredIconSVG);
export const RelativeCopyIcon = withIconStyle(RelativeCopyIconSVG);
export const RelativeIcon = withIconStyle(RelativeIconSVG);
export const ReversingIcon = withIconStyle(ReversingIconSVG);
export const RighOfWayIntersectionIcon = withIconStyle(
    RighOfWayIntersectionIconSVG
);
export const RoadIcon = withIconStyle(RoadIconSVG);
export const RoundAboutIcon = withIconStyle(RoundAboutIconSVG);
export const SamboIcon = withIconStyle(SamboIconSVG);
export const SchoolNurseIcon = withIconStyle(SchoolNurseIconSVG);
export const SchoolTransferIcon = withIconStyle(SchoolTransferIconSVG);
export const SelfOwnDriverIcon = withIconStyle(SelfOwnDriverIconSVG);
export const SeventhGradeIcon = withIconStyle(SeventhGradeIconSVG);
export const ShockInjuryIcon = withIconStyle(ShockInjuryIconSVG);
export const ShockOrCrashInjuryIcon = withIconStyle(ShockOrCrashInjuryIconSVG);
export const SickPayIcon = withIconStyle(SickPaySVG);
export const SmallPersonIcon = withIconStyle(SmallPersonIconSVG);
export const SnowRoadIcon = withIconStyle(SnowRoadIconSVG);
export const SpesialistIcon = withIconStyle(SpesialistIconSVG);
export const SpouseOrCohabitantIcon = withIconStyle(SpouseOrCohabitantIconSVG);
export const StationaryObjectIcon = withIconStyle(StationaryObjectIconSVG);
export const SteeringWheelIcon = withIconStyle(SteeringWheelIconSVG);
export const StudentIcon = withIconStyle(StudentIconSVG);
export const TSectionIcon = withIconStyle(TSectionIconSVG);
export const TeethInjuryIcon = withIconStyle(TeethInjuryIconSVG);
export const TemperatureInjuryIcon = withIconStyle(TemperatureInjuryIconSVG);
export const TheftAndDamageIcon = withIconStyle(TheftAndDamageIconSVG);
export const TheoreticalStudiesIcon = withIconStyle(TheoreticalStudiesIconSVG);
export const TrainIcon = withIconStyle(TrainIconSVG);
export const TrainingEventIcon = withIconStyle(TrainingEventIconSVG);
export const TravelIcon = withIconStyle(TravelIconSVG);
export const TuberculosisIcon = withIconStyle(TuberculosisIconSVG);
export const TwistedKneeIcon = withIconStyle(TwistedKneeIconSVG);
export const UnregisteredIcon = withIconStyle(UnregisteredIconSVG);
export const VibrationIcon = withIconStyle(VibrationIconSVG);
export const ViolenceInjuryIcon = withIconStyle(ViolenceInjuryIconSVG);
export const VirusIcon = withIconStyle(VirusIconSVG);
export const VocationalLabourMarketTrainingIcon = withIconStyle(
    VocationalLabourMarketTrainingIconSVG
);
export const WayToActivityIcon = withIconStyle(WayToActivityIconSVG);
export const WayToSchoolIcon = withIconStyle(WayToSchoolIconSVG);
export const WayToWorkIcon = withIconStyle(WayToWorkIconSVG);
export const WetRoadIcon = withIconStyle(WetRoadIconSVG);
export const WheelsIcon = withIconStyle(WheelsIconSVG);
export const WildAnimalIcon = withIconStyle(WildAnimalIconSVG);
export const WindshieldIcon = withIconStyle(WindshieldIconSVG);
export const WorkCompIcon = withIconStyle(WorkCompIconSVG);
export const WorkingAreaOtherPremisesIcon = withIconStyle(
    WorkingAreaOtherPremisesIconSVG
);
export const WorkingAreaWorkingProcessMeetingIcon = withIconStyle(
    WorkingAreaWorkingProcessMeetingIconSVG
);
export const WrongFuelIcon = withIconStyle(WrongFuelIconSVG);
export const XIcon = withIconStyle(XIconSVG);
export const YesIcon = withIconStyle(YesIconSVG);
