import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    Changable,
    ClaimantInformationListModel,
    ClaimantInformationListTypeModel,
    isEmpty,
    PageLayout,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormProvider, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { isOrgNumberValid } from '../../../utils/numbers/orgNumberUtils';
import { selectClaimantInformationList } from '../../../sagas/selectors/lpoSelectors';
import { claimantInformationListSchema } from '../../../validations/schemas/claimantInformationListSchema';
import { lpoActions } from '../../../sagas/lpo';
import { DisplayClaimantInformationList } from '../../../components/claimantInformationList/DisplayClaimantInformationList';
import { ClaimantInformationListCompanyForm } from 'components/claimantInformationList/ClaimantInformationListCompanyForm';
import { ClaimantInformationListPersonForm } from 'components/claimantInformationList/ClaimantInformationListPersonForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { IS_COMPANY } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DELETE_BUTTON,
    HELP_TEXT,
    IS_LIABILITY_COMPANY_LABEL,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const LiabilityClaimantInformationPage = () => {
    const dispatch = useDispatch();
    const claimantInformationList = useSelector(selectClaimantInformationList);
    const customCAN = useSelector(selectCustomCAN);
    const [isCompany, setIsCompany] = useState<boolean>(false);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.liability.claimantInformation');
    const form = useForm<ClaimantInformationListModel>({
        resolver: yupResolver(claimantInformationListSchema(t)),
    });

    const handleBackButton = useGoBack();

    const isEmptyForm = (values: ClaimantInformationListModel) => {
        const { businessNumber, city, email, firstName, isCompany, lastName, name, phone, street, zip } = values;
        let empty: boolean;
        if (isCompany) {
            empty =
                (!name || name.length === 0) &&
                (!businessNumber || businessNumber.length === 0) &&
                (!email || email.length === 0) &&
                (!phone || phone.length === 0);
        } else {
            empty =
                (!firstName || firstName.length === 0) &&
                (!lastName || lastName.length === 0) &&
                (!street || street.length === 0) &&
                (!zip || zip.length === 0) &&
                (!city || city.length === 0) &&
                (!email || email.length === 0) &&
                (!phone || phone.length === 0);
        }
        return empty;
    };

    const handleContinueButton = () => {
        const values = form.getValues();
        if (!isEmptyForm(values)) {
            claimantInformationListSchema(t)
                .isValid(values)
                .then((valid) => {
                    if (valid) {
                        handleDispatch(values, true);
                    } else {
                        form.trigger();
                    }
                });
        } else if (isEmpty(claimantInformationList)) {
            form.trigger();
        } else {
            // Send to server and go to next
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleDispatch = (claimant: ClaimantInformationListModel, isNext = false) => {
        const currentClaimant = { ...claimant, businessNumber: isOrgNumberValid(claimant.businessNumber) };
        dispatcherWithPromise(dispatch, lpoActions.update, {
            claimantInformationList: [...claimantInformationList, currentClaimant],
        })
            .then(() => isNext && dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => {
                form.reset({
                    businessNumber: '',
                    city: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    name: '',
                    phone: '',
                    street: '',
                    zip: '',
                });
                if (isNext) {
                    dispatch(wizardActions.goToNext());
                }
            });
    };

    const removeClaimantInformation = (
        claimant: ClaimantInformationListModel,
        existingClaimants: ClaimantInformationListTypeModel
    ): ClaimantInformationListTypeModel => {
        return existingClaimants.filter((o: ClaimantInformationListModel) => {
            return (
                o.firstName !== claimant.firstName ||
                o.lastName !== claimant.lastName ||
                o.street !== claimant.street ||
                o.zip !== claimant.zip ||
                o.city !== claimant.city ||
                o.email !== claimant.email ||
                o.phone !== claimant.phone ||
                o.name !== claimant.name ||
                o.businessNumber !== claimant.businessNumber
            );
        });
    };

    const handleDelete = (e: MouseEvent, claimant: ClaimantInformationListModel) => {
        e.preventDefault();
        const updatedClaimantList = removeClaimantInformation(claimant, claimantInformationList);
        dispatch(lpoActions.update({ claimantInformationList: updatedClaimantList }));
    };

    const handleOnSubmit = (claimant: ClaimantInformationListModel) => handleDispatch(claimant);

    const handleChecked = async (e: Changable) => {
        const { checked } = e.target;
        form.setValue('isCompany', checked);
        await setIsCompany(checked);
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <FormProvider {...form}>
                <SingleCheckbox
                    checked={isCompany}
                    handleChange={handleChecked}
                    name={IS_COMPANY}
                    wrapperClass={'col-12 multiple'}
                    {...{ customCAN }}
                >
                    {t(IS_LIABILITY_COMPANY_LABEL)}
                </SingleCheckbox>
                {isCompany ? (
                    <ClaimantInformationListCompanyForm onSubmit={handleOnSubmit} />
                ) : (
                    <ClaimantInformationListPersonForm onSubmit={handleOnSubmit} />
                )}
                <DisplayClaimantInformationList
                    buttonText={t(DELETE_BUTTON)}
                    claimants={claimantInformationList}
                    {...{ handleDelete }}
                />
            </FormProvider>
        </PageLayout>
    );
};
