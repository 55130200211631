import { Nullable, YesNoKeys } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Utility logic
 */
export const convertYesNoKeysToBool = (yesNo: Nullable<YesNoKeys>) => {
    if (yesNo && yesNo === YES) {
        return true;
    } else if (yesNo && yesNo === NO) {
        return false;
    } else {
        return null;
    }
};
