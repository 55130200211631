import React, { useContext } from 'react';
import { TabContext } from './Tabs';
import './Tabs.scss';

interface TabPanelProps {
    tabId: string;
    children: React.ReactNode;
}

const TabPanel = ({ tabId, children }: TabPanelProps) => {
    const tabContext = useContext(TabContext);
    if (tabId !== tabContext.activeTab) {
        return null;
    }

    return (
        <div role="tabpanel" data-testid={`${tabId}-tab`} id={`${tabId}-tab`} aria-labelledby={tabId}>
            {children}
        </div>
    );
};

export default TabPanel;
