/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import moment from 'moment';
import { timePickerCss } from './TimePicker.styles';
import { InputLabel } from '../../atoms/inputs/InputLabel';
import { numberToTwoDigitString, TimePickerMode } from '../../../utils';
import { HourOptions } from '../../atoms/dates/timePicker/HourOptions';
import { MinuteOptions } from '../../atoms/dates/timePicker/MinuteOptions';
import { InputValidationError } from '../../atoms/inputs/InputValidationError';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface TimePickerProps {
    className?: string;
    customCAN?: customCANProps;
    error?: string;
    hoursLabel: string;
    label?: string;
    locale?: string;
    minutesLabel: string;
    mode?: TimePickerMode;
    name?: string;
    onHourSelect: (newHoursVal: number) => void;
    onMinSelect: (newMinVal: number) => void;
    time: moment.Moment | null;
}

/**
 * Component view and component logic
 */
export const TimePicker = ({
    className = '',
    customCAN,
    error,
    hoursLabel,
    label,
    locale,
    minutesLabel,
    mode = 'normal',
    name = '',
    onHourSelect,
    onMinSelect,
    time,
}: TimePickerProps) => {
    locale && moment.locale(locale);

    const getHours = () => {
        if (time === null) {
            return '--';
        } else {
            return numberToTwoDigitString(time.hours());
        }
    };

    const getMinutes = () => {
        if (time === null) {
            return '--';
        } else {
            return numberToTwoDigitString(time.minutes());
        }
    };

    return (
        <div css={css(timePickerCss(customCAN))} {...{ className }}>
            {label && <InputLabel {...{ label, name }} />}
            <select
                aria-label={hoursLabel}
                aria-labelledby={name}
                className={'hours-select'}
                onChange={(e) => onHourSelect(parseInt(e.target.value))}
                value={getHours()}
            >
                <HourOptions />
            </select>
            <span>{':'}</span>
            <select
                aria-label={minutesLabel}
                aria-labelledby={name}
                className={'minutes-select'}
                onChange={(e) => onMinSelect(parseInt(e.target.value))}
                value={getMinutes()}
            >
                <MinuteOptions {...{ mode }} />
            </select>
            <InputValidationError fieldName={name} {...{ error }} />
        </div>
    );
};
