import { OptionType, TypeOfPropertyKeys } from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const {
    APARTMENT_BUILDING,
    CONDOMINIUM,
    INDUSTRIAL_PROPERTY,
    LOCAL,
    OTHER,
    PUBLIC_PROPERTY,
    TENANCY,
    VILLA,
} = TypeOfPropertyKeys;

/**
 * Utility logic
 */
const createTypeOfPropertyKey = (id: string) => {
    return `lpo.typeOfProperty.select.${id}`;
};

export const getApartmentBuildingOption = (t: TFunction): OptionType => {
    return {
        value: APARTMENT_BUILDING,
        label: t(createTypeOfPropertyKey(APARTMENT_BUILDING)),
    };
};

export const getCondominiumOption = (t: TFunction): OptionType => {
    return {
        value: CONDOMINIUM,
        label: t(createTypeOfPropertyKey(CONDOMINIUM)),
    };
};

export const getIndustrialPropertyOption = (t: TFunction): OptionType => {
    return {
        value: INDUSTRIAL_PROPERTY,
        label: t(createTypeOfPropertyKey(INDUSTRIAL_PROPERTY)),
    };
};

export const getLocalOption = (t: TFunction): OptionType => {
    return {
        value: LOCAL,
        label: t(createTypeOfPropertyKey(LOCAL)),
    };
};

export const getOtherOption = (t: TFunction): OptionType => {
    return {
        value: OTHER,
        label: t(createTypeOfPropertyKey(OTHER)),
    };
};

export const getPublicPropertyOption = (t: TFunction): OptionType => {
    return {
        value: PUBLIC_PROPERTY,
        label: t(createTypeOfPropertyKey(PUBLIC_PROPERTY)),
    };
};

export const getTenancyOption = (t: TFunction): OptionType => {
    return {
        value: TENANCY,
        label: t(createTypeOfPropertyKey(TENANCY)),
    };
};

export const getVillaOption = (t: TFunction): OptionType => {
    return {
        value: VILLA,
        label: t(createTypeOfPropertyKey(VILLA)),
    };
};
