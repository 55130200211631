import { AppState } from '../../store';

export const selectLpoState = (state: AppState) => state.lpo;

// Start Selectors
export const selectAcceptedPrivacy = (state: AppState) => state.lpo.acceptedPrivacy;
export const selectClaimType = (state: AppState) => state.lpo.claimType;
export const selectClaimCause = (state: AppState) => state.lpo.claimCause;
export const selectClaimReporterRole = (state: AppState) => state.lpo.claimReporterRole;
export const selectClaimDate = (state: AppState) => state.lpo.claimDate;

// Property Selectors
export const selectCompanyInformation = (state: AppState) => state.lpo.companyInformation;
export const selectPolicyNumber = (state: AppState) => state.lpo.companyInformation.policyNumber;
export const selectTypeOfProperty = (state: AppState) => state.lpo.typeOfProperty;
export const selectIsContactorContacted = (state: AppState) => state.lpo.isContractorContacted;
export const selectContractorInformation = (state: AppState) => state.lpo.contractorInformation;

// Liability Selectors
export const selectClaimantInformationList = (state: AppState) => state.lpo.claimantInformationList;
export const selectLiabilityClaimDescription = (state: AppState) => state.lpo.liabilityClaimDescription;
export const selectReportedToInsurersInsurance = (state: AppState) => state.lpo.reportedToInsurersInsurance;

// Legal Expenses Selectors
export const selectAgentInformation = (state: AppState) => state.lpo.agentInformation;
export const selectHasClaim = (state: AppState) => state.lpo.hasClaim;
export const selectHasActiveOpposition = (state: AppState) => state.lpo.hasActiveOpposition;

// Change Of Ownership Selectors
export const selectClaimantInformation = (state: AppState) => state.lpo.claimantInformation;
export const selectSellerInformation = (state: AppState) => state.lpo.sellerInformation;
export const selectReportToSellerDate = (state: AppState) => state.lpo.reportedToSellerDate;
export const selectCost = (state: AppState) => state.lpo.cost;

// End Selectors
export const selectAccidentLocation = (state: AppState) => state.lpo.accidentLocation;
export const selectClaimDescription = (state: AppState) => state.lpo.claimDescription;
export const selectHasContractWithOtherPart = (state: AppState) => state.lpo.hasContractWithOtherPart;
export const selectHasDemands = (state: AppState) => state.lpo.hasDemands;
export const selectHasOtherInsurance = (state: AppState) => state.lpo.otherInsurance;
export const selectOtherInsuranceCompany = (state: AppState) => state.lpo.otherInsuranceCompany;
export const selectHasVAT = (state: AppState) => state.lpo.hasVAT;
export const selectReporterInformation = (state: AppState) => state.lpo.reporterInformation;
export const selectLandlordInformation = (state: AppState) => state.lpo.landlordInformation;
export const selectPolicyHoldersContact = (state: AppState) => state.lpo.policyHoldersContact;
export const selectExternalReference = (state: AppState) => state.lpo.externalReference;
export const selectAcceptedPoliceContact = (state: AppState) => state.lpo.acceptedPoliceContact;
export const selectBankAccount = (state: AppState) => state.lpo.bankAccountInformation;
