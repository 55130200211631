import React from 'react';
import { PersonSummaryProps } from '../../../types/SummaryProps';
import { PhraseKeys } from 'config/phraseKeys';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import {
    ClaimReporterRoleKeys,
    FileList,
    InsuranceTypeKeys,
    is,
    isGreater,
    isNot,
    isYes,
    SummaryItem,
} from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { createTextYesNoDontKnow, trueFalseToYesNo } from 'utils/displayUtils';
import { dtTimeParseAndFormat } from 'utils/date/dateUtils';
import { HospitalisationInformationModel } from 'models/person/HospitalisationInformation';
import { HospitalisationInformationSummaryList } from '../../hospitalisation/HospitalisationInformationSummaryList';

/**
 * Destructure necessary imports
 */
const { SELECT_NO, SELECT_YES } = PhraseKeys;
const { ON_BEHALF_CHILD } = ClaimReporterRoleKeys;
const { DIAGNOSIS } = InsuranceTypeKeys;
const {
    DYN_CAROUSEL_ILLNESS_CLAIM_REPORTER_ROLE,
    DYN_CAROUSEL_ILLNESS_HAS_ATTACHMENT,
    DYN_CAROUSEL_ILLNESS_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_ILLNESS_HOSPITALISED,
    DYN_CAROUSEL_ILLNESS_INSURANCE_TYPE,
    DYN_CAROUSEL_ILLNESS_PREVIOUS_ILLNESS,
    INDEX,
    ILLNESS_PRIVACY,
    ILLNESS_CLAIM_DATE,
    ILLNESS_DATE_OF_DIAGNOSIS,
    ILLNESS_HOSPITALISATION_INFORMATION,
    ILLNESS_DIAGNOSIS_INFORMATION,
    ILLNESS_OTHER_INSURANCE_COMPANY,
    ILLNESS_UPLOAD_ATTACHMENT,
} = PersonRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayIllnessSituationSummary = ({ model, t, handleClick, upload, error }: PersonSummaryProps) => {
    /**
     * Helpers
     */
    const {
        category,
        claimReporterRole,
        insuranceType,
        acceptedPrivacy,
        claimDate,
        dateOfDiagnosis,
        hospitalised,
        hospitalisationInformation,
        diagnosis,
        previousIllness,
        otherInsurance,
        otherInsuranceCompany,
    } = model;

    const { hasAttachment, files } = upload.attachment;

    const renderCategory = () => {
        if (category) {
            return (
                <SummaryItem
                    textKey={t('lob.person.illness.summary.category.heading.title')}
                    dataTestId={TEST_ID_PREFIX.category}
                    buttonText={t(`lob.person.category.select.${category}`)}
                    path={INDEX}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.type')}</p>;
    };

    const renderClaimReporterRole = () => {
        if (claimReporterRole) {
            return (
                <SummaryItem
                    textKey={t('lob.person.illness.summary.whoReport.heading.title')}
                    dataTestId={TEST_ID_PREFIX.whoReport}
                    buttonText={t(`lob.person.illness.whoReport.select.${claimReporterRole}`)}
                    path={DYN_CAROUSEL_ILLNESS_CLAIM_REPORTER_ROLE}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.claimReporter')}</p>;
    };

    const renderInsuranceType = () => {
        if (insuranceType) {
            return (
                <SummaryItem
                    textKey={t('lob.person.illness.summary.insuranceType.heading.title')}
                    dataTestId={TEST_ID_PREFIX.insuranceType}
                    buttonText={t(`lob.person.illness.insuranceType.select.${insuranceType}`)}
                    path={DYN_CAROUSEL_ILLNESS_INSURANCE_TYPE}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.insuranceType')}</p>;
    };

    const renderReport = () => {
        return (
            <>
                {/* Category */}
                <div className={'col-4 divider align-button-start text-align-left'}>{renderCategory()}</div>

                {/* Who Report */}
                <div
                    className={`${
                        isNot(claimReporterRole, ON_BEHALF_CHILD) ? 'col-4' : 'col-8'
                    } divider align-button-start text-align-left`}
                >
                    {renderClaimReporterRole()}
                </div>

                {/* Insurance type */}
                {isNot(claimReporterRole, ON_BEHALF_CHILD) && (
                    <div className={'col-4 divider align-button-start text-align-left'}>{renderInsuranceType()}</div>
                )}
            </>
        );
    };

    const renderPrivacy = () => {
        if (acceptedPrivacy) {
            return (
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.illness.summary.privacy.heading.title')}
                    dataTestId={TEST_ID_PREFIX.acceptedPrivacy}
                    buttonText={t(trueFalseToYesNo(acceptedPrivacy))}
                    path={ILLNESS_PRIVACY}
                    {...{ handleClick }}
                />
            );
        }
        if (error) {
            error(true);
        }
        return (
            <div className={'col-12 divider align-button-start text-align-left'}>
                <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.privacy')}</p>
            </div>
        );
    };

    const renderDiagnosisDate = () => {
        return (
            <>
                {/* Date of first symptoms */}
                <div
                    className={`col-4 divider ${isYes(hospitalised) && 'no-border'} align-button-start text-align-left`}
                >
                    <SummaryItem
                        textKey={t('lob.person.illness.summary.claimDate.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimDate}
                        buttonText={claimDate ? dtTimeParseAndFormat(claimDate) : ''}
                        path={ILLNESS_CLAIM_DATE}
                        {...{ handleClick }}
                    />
                </div>

                {/* Date of diagnosis */}
                <div
                    className={`col-4 divider ${isYes(hospitalised) && 'no-border'} align-button-start text-align-left`}
                >
                    <SummaryItem
                        textKey={t(`lob.person.illness.summary.dateOfDiagnosis.heading.title`)}
                        dataTestId={TEST_ID_PREFIX.dateOfDiagnosis}
                        buttonText={dateOfDiagnosis ? dtTimeParseAndFormat(dateOfDiagnosis) : ''}
                        path={ILLNESS_DATE_OF_DIAGNOSIS}
                        {...{ handleClick }}
                    />
                </div>

                {/* Hospitalised */}
                <div
                    className={`col-4 divider ${isYes(hospitalised) && 'no-border'} align-button-start text-align-left`}
                >
                    <SummaryItem
                        textKey={t(`lob.person.illness.summary.hospitalised.${claimReporterRole}.heading.title`)}
                        dataTestId={TEST_ID_PREFIX.hospitalised}
                        buttonText={t(createTextYesNoDontKnow(hospitalised))}
                        path={DYN_CAROUSEL_ILLNESS_HOSPITALISED}
                        {...{ handleClick }}
                    />
                </div>

                {/* Hospitalisation information */}
                {isYes(hospitalised) && (
                    <div className={'col-12 divider align-button-start text-align-left'}>
                        <HospitalisationInformationSummaryList
                            list={hospitalisationInformation}
                            getText={(model: HospitalisationInformationModel) =>
                                `${t('lob.person.illness.summary.hospitalisationInformation.buttonText', {
                                    startDate: model.startDate && dtTimeParseAndFormat(model.startDate),
                                    endDate: model.endDate && dtTimeParseAndFormat(model.endDate),
                                    hospitalName: model ? model.hospitalName : '',
                                })}`
                            }
                            textKey={t(
                                `lob.person.illness.summary.hospitalisationInformation.${claimReporterRole}.heading.title`
                            )}
                            path={ILLNESS_HOSPITALISATION_INFORMATION}
                            show={true}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderDiagnosisInformation = () => {
        return (
            <>
                {/* Diagnosis Information */}
                {is(insuranceType, DIAGNOSIS) && (
                    <div className={'col-12 divider align-button-start text-align-left'}>
                        <SummaryItem
                            textKey={t('lob.person.illness.summary.diagnosisInformation.heading.title')}
                            dataTestId={TEST_ID_PREFIX.diagnosis}
                            buttonText={diagnosis ? diagnosis : ''}
                            path={ILLNESS_DIAGNOSIS_INFORMATION}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderPreviousIllness = () => {
        return (
            <>
                {/* Previous illness */}
                <div className={'col-12 divider align-button-start text-align-left'}>
                    <SummaryItem
                        textKey={t(`lob.person.illness.summary.previousIllness.${claimReporterRole}.heading.title`)}
                        dataTestId={TEST_ID_PREFIX.previousIllness}
                        buttonText={t(createTextYesNoDontKnow(previousIllness))}
                        path={DYN_CAROUSEL_ILLNESS_PREVIOUS_ILLNESS}
                        {...{ handleClick }}
                    />
                </div>
            </>
        );
    };

    const renderOtherInsurance = () => {
        return (
            <>
                {/* Other insurance */}
                <div
                    className={`${
                        isYes(otherInsurance) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                >
                    <SummaryItem
                        textKey={t('lob.person.illness.summary.otherInsurance.heading.title')}
                        dataTestId={TEST_ID_PREFIX.otherInsurance}
                        buttonText={t(createTextYesNoDontKnow(otherInsurance))}
                        path={DYN_CAROUSEL_ILLNESS_HAS_OTHER_INSURANCE}
                        {...{ handleClick }}
                    />
                </div>

                {/* Other insurance company */}
                {isYes(otherInsurance) && (
                    <div className={'col-8 divider align-button-start text-align-left'}>
                        <SummaryItem
                            textKey={t('lob.person.illness.summary.otherInsuranceCompany.heading.title')}
                            dataTestId={TEST_ID_PREFIX.otherInsuranceCompany}
                            buttonText={otherInsuranceCompany ? otherInsuranceCompany : ''}
                            path={ILLNESS_OTHER_INSURANCE_COMPANY}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderAttachments = () => {
        return (
            <>
                {/* Has attachment */}
                <div
                    className={`${
                        isGreater(files.length, 0) ? 'col-4' : 'col-12'
                    } item-content-top divider align-button-start text-align-left`}
                >
                    <SummaryItem
                        textKey={t('lob.person.illness.summary.hasAttachment.heading.title')}
                        buttonText={files.length > 0 ? `${t(SELECT_YES)}` : `${t(SELECT_NO)}`}
                        dataTestId={`${TEST_ID_PREFIX.hasAttachments}${hasAttachment}`}
                        path={DYN_CAROUSEL_ILLNESS_HAS_ATTACHMENT}
                        {...{ handleClick }}
                    />
                </div>

                {/* List of attachments */}
                {isGreater(files.length, 0) && (
                    <div className={'col-8 divider align-button-start text-align-left'}>
                        <FileList
                            list={files}
                            path={ILLNESS_UPLOAD_ATTACHMENT}
                            show={true}
                            dataTestId={TEST_ID_PREFIX.uploadAttachments}
                            textKey={t('lob.person.illness.summary.uploadAttachment.heading.title')}
                            {...{ handleClick }}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {renderReport()}

            {renderPrivacy()}

            {renderDiagnosisDate()}

            {renderDiagnosisInformation()}

            {renderPreviousIllness()}

            {renderOtherInsurance()}

            {renderAttachments()}
        </>
    );
};
