import { Datable, Nullable } from '../../../../../interfaces';

export interface ReporterInformationModel {
    city?: Nullable<string>;
    dateOfBirth: Datable;
    email: Nullable<string>;
    firstName: Nullable<string>;
    isBoardMember?: boolean;
    isClaimant?: boolean;
    isDriver: boolean;
    isSeller?: boolean;
    lastName: Nullable<string>;
    missingSSN?: boolean;
    nationalIdentity?: Nullable<string>;
    phone: Nullable<string>;
    role?: Nullable<string>;
    street?: Nullable<string>;
    zip?: Nullable<string>;
}

export const initReporterInformation: ReporterInformationModel = {
    city: null,
    dateOfBirth: null,
    email: null,
    firstName: null,
    isDriver: false,
    isClaimant: false,
    isSeller: false,
    lastName: null,
    missingSSN: false,
    nationalIdentity: null,
    phone: null,
    street: null,
    zip: null,
};
