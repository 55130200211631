import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { locationSchema } from 'validations/fieldSchemas/locationSchema';

/**
 * Validation logic
 */
export const accidentLocationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...locationSchema(t),
    });
};
