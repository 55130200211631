import { css } from '@emotion/react';
import { borderFocusColor, primary, secondary } from '../../../variables';
import { customCANProps } from '../../../../config/customCANCompanies';

export const checkboxWh = '3rem';
export const checkboxBorder = '1px';

export const singleCheckboxCss = (company?: customCANProps) =>
    css({
        position: 'relative',
        margin: '1rem',

        '& .custom-control-input': {
            height: checkboxWh,
            left: '0',
            opacity: '0',
            position: 'absolute',
            top: '0',
            width: checkboxWh,
        },

        '& input:focus + label::before': {
            borderColor: company ? company.color : secondary,
            boxShadow: `0 0 0 0.2rem ${borderFocusColor}`,
            outline: '0',
        },

        '& label': {
            color: primary,
            display: 'block',
            padding: '0 0 0 3.5rem',
            textAlign: 'left',
            fontSize: '1.05rem',
            fontWeight: 700,
        },

        '& input + label::before': {
            border: `${checkboxBorder} solid`,
            borderRadius: '50%',
            content: '""',
            height: checkboxWh,
            width: checkboxWh,
            left: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },

        '& input + label::after': {
            content: '""',
            border: `${checkboxBorder} solid transparent`,
            background:
                'url("/assets/images/icons/check.svg") center center no-repeat',
            height: checkboxWh,
            left: checkboxBorder,
            opacity: '0',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            transition: 'opacity 0.2s ease-in-out',
            width: checkboxWh,
        },

        '& input:checked + label::after': {
            opacity: '1',
        },
    });

export const singleCheckboxGreenCss = (company?: customCANProps) =>
    css({
        position: 'relative',
        margin: '1rem',

        '& .custom-control-input': {
            height: checkboxWh,
            left: '0',
            opacity: '0',
            position: 'absolute',
            top: '0',
            width: checkboxWh,
        },

        '& input:focus + label::before': {
            borderColor: company ? company.color : secondary,
            boxShadow: `0 0 0 0.2rem ${borderFocusColor}`,
            outline: '0',
        },

        '& label': {
            color: primary,
            display: 'block',
            padding: '0 0 0 3.5rem',
            textAlign: 'left',
            fontSize: '1.05rem',
            fontWeight: 700,
        },

        '& input + label::before': {
            border: `${checkboxBorder} solid`,
            borderRadius: '50%',
            content: '""',
            height: checkboxWh,
            width: checkboxWh,
            left: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },

        '& input + label::after': {
            content: '""',
            border: `${checkboxBorder} solid transparent`,
            background:
                'url("/assets/images/icons/check-green.svg") center center no-repeat',
            height: checkboxWh,
            left: checkboxBorder,
            opacity: '0',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            transition: 'opacity 0.2s ease-in-out',
            width: checkboxWh,
        },

        '& input:checked + label::after': {
            opacity: '1',
        },

        '& input:checked + label::before': {
            border: '#34b233 solid 1px',
        },
    });
