import { css } from '@emotion/react';
import { grey, lightGrey, secondary, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const carouselItemCss = (company?: customCANProps) =>
    css({
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem 2rem',
        padding: 0,
        textDecoration: 'none',
        textTransform: 'uppercase',
        width: '100%',

        '@media screen and (min-width: 768px)': {
            width: '30%',
        },

        '@media screen and (min-width: 1024px)': {
            width: '25%',
        },

        '&:focus': {
            boxShadow: 'none',
            outline: 'none',

            '& .icon': {
                background: grey,
                borderColor: company ? company.color : secondary,
                transition: 'all 200ms ease-in-out',
            },
        },

        '& svg': {
            width: '100%',
        },

        '& .title': {
            fontSize: '1.25rem',
            fontWeight: 'normal',
            width: '100%',

            '&.selected': {
                color: company ? company.color : secondary,
            },
        },

        '& .description': {
            fontSize: '0.935rem',
            padding: '0.5rem 0',

            '&.selected': {
                color: company ? company.color : secondary,
            },
        },

        '& .icon': {
            background: white,
            border: '1px solid rgba(0, 0, 0, 0.1)',
            borderRadius: '50%',
            boxSizing: 'border-box',
            height: 240,
            marginBottom: '1rem',
            padding: 60,
            width: 240,

            '& svg': {
                height: '100%',
                width: '100%',

                '& g': {
                    '& path': {
                        fill: 'transparent',
                    },
                },
            },

            '&:hover': {
                background: grey,
                borderColor: grey,
            },

            '&:active': {
                background: company ? company.colorFaded : lightGrey,
                borderColor: company ? company.colorFaded : lightGrey,
            },

            '&.selected': {
                background: company ? company.colorFaded : lightGrey,
                borderColor: company ? company.colorFaded : lightGrey,

                '& svg': {
                    '& path': {
                        stroke: company ? company.color : secondary,
                    },

                    '& g': {
                        '& path': {
                            fill: 'transparent',
                            stroke: company ? company.color : secondary,
                        },
                    },
                },
            },
        },
    });
