import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { otherInsuranceSchema } from '../fieldSchemas/otherInsuranceSchema';

/**
 * Validation logic
 */
export const otherInsuranceCompanySchema = (t: TFunction) => {
    return Yup.object().shape({
        ...otherInsuranceSchema(t),
    });
};
