import {
    PartyRelationKeys,
    VehicleModel,
    VehiclesModel,
} from '../../interfaces';
import { updateExistingVehicle } from './updateExistingVehicle';
import { spreadNewVehicle } from './spreadNewVehicle';

export const updateOrAddVehicle = (
    vehicles: VehiclesModel,
    newVehicle: Partial<VehicleModel>,
    matchFn?: (v: VehicleModel) => boolean
) => {
    const exists = matchFn
        ? vehicles.find(matchFn)
        : vehicles.find(
              (vehicle) =>
                  vehicle.registrationNumber === newVehicle.registrationNumber
          );

    if (exists) {
        return updateExistingVehicle(vehicles, newVehicle, matchFn);
    } else {
        return [
            ...vehicles,
            {
                ...spreadNewVehicle({
                    ...newVehicle,
                    partyRelation:
                        newVehicle.partyRelation ??
                        PartyRelationKeys.CLAIM_VEHICLE,
                }),
            },
        ];
    }
};
