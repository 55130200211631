import React, { useRef } from 'react';
import { DamageMarkers } from '../DamageMarker';
import { useMarkers } from '../../../hooks/useMarkers';
import { createOnClickAddDamagePoint } from '../../../utils/svgUtils';
import { BodyKeys, SvgImg } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { FRONT_RIGHT, CENTER_RIGHT, BACK_RIGHT } = BodyKeys;

/**
 * Component view and component logic
 */
export const HeavyTruckRightImage = ({
    addDamagePoint,
    removeDamagePoint,
    selectedDamagePoints,
    title,
    id = 'heavy-truck-right-image-svg',
}: SvgImg) => {
    const refSVG = useRef<SVGSVGElement>(null);
    const thisMarkers = useMarkers(selectedDamagePoints, id);
    const onClick = createOnClickAddDamagePoint(refSVG, addDamagePoint);

    return (
        <svg
            id={id}
            data-testid={id}
            className={'vehicle-img-svg right-image-svg'}
            ref={refSVG}
            width="2075"
            height="595"
            viewBox="0 0 2075 595"
            aria-labelledby={`${id}-title`}
            role="img"
            fill="none"
        >
            <title id={`${id}-title`}>{title}</title>
            <g className={'right-image-svg__selectable'}>
                <path
                    id={FRONT_RIGHT}
                    onClick={onClick}
                    fill="#FFFFFF"
                    stroke="#7D8890"
                    d="M2070 594.5C2072.49 594.5 2074.5 592.485 2074.5 590L2074.5 0.5L1471.5 0.499993L1471.5 594.5L2070 594.5Z"
                />
                <path
                    id={BACK_RIGHT}
                    onClick={onClick}
                    fill="#FFFFFF"
                    stroke="#7D8890"
                    d="M4.99999 594.5C2.51471 594.5 0.5 592.485 0.5 590L0.500007 0.5L603.5 0.500007L603.5 594.5L4.99999 594.5Z"
                />
                <path
                    id={CENTER_RIGHT}
                    onClick={onClick}
                    fill="#FFFFFF"
                    stroke="#7D8890"
                    d="M609 594.5C606.515 594.5 604.5 592.485 604.5 590L604.5 0.5L1470.5 0.50001L1470.5 594.5L609 594.5Z"
                />
            </g>
            <g className={'right-image-svg__content'}>
                <path
                    d="M1657.4 410.343V67.9999H40.4451V410.343M1657.4 410.343V454.224H1736.44V204.181C1729.38 198.886 1726.87 194.975 1727.74 184.51L1747.4 85.4008H1794.29L1857.82 120.581H1904.71C1955 120.581 1987.15 133.442 2010.59 184.51L2043.49 345.657L2049.92 356.249V407.695L2043.49 410.343L2061.26 444.767C2064.49 509.717 2064.07 537.981 2052.57 549.172H1952.36C1975.42 492.5 1929.29 444.767 1876.35 444.767C1823.41 444.767 1780.5 497.726 1809 560.899H1404.04C1421.5 497.726 1389.3 444.767 1329.17 444.767L55.9492 454.224V531.771L40.4451 552.198H17V531.771L40.4451 518.91V410.343M1657.4 410.343H40.4451M557.372 524.205C557.372 558.886 585.477 587 620.145 587C654.813 587 682.917 558.886 682.917 524.205C682.917 489.525 654.813 461.411 620.145 461.411C585.477 461.411 557.372 489.525 557.372 524.205ZM413.676 524.205C413.676 558.886 441.781 587 476.449 587C511.117 587 539.221 558.886 539.221 524.205C539.221 489.525 511.117 461.411 476.449 461.411C441.781 461.411 413.676 489.525 413.676 524.205ZM266.199 524.205C266.199 558.886 294.303 587 328.971 587C363.64 587 391.744 558.886 391.744 524.205C391.744 489.525 363.64 461.411 328.971 461.411C294.303 461.411 266.199 489.525 266.199 524.205ZM1263.75 524.205C1263.75 558.886 1291.86 587 1326.52 587C1361.19 587 1389.3 558.886 1389.3 524.205C1389.3 489.525 1361.19 461.411 1326.52 461.411C1291.86 461.411 1263.75 489.525 1263.75 524.205ZM1815.85 524.205C1815.85 558.886 1843.95 587 1878.62 587C1913.29 587 1941.39 558.886 1941.39 524.205C1941.39 489.525 1913.29 461.411 1878.62 461.411C1843.95 461.411 1815.85 489.525 1815.85 524.205ZM1890.34 213.638H1867.27C1855.93 213.638 1855.93 222.338 1855.93 228.012V276.81C1855.93 285.511 1855.93 290.428 1867.27 290.428H1890.34V213.638ZM1896.77 213.638V290.428H1955L1977.69 306.694H2035.55L2016.26 213.638H1896.77ZM1896.39 304.481V413.748C1955.03 427.823 1972.98 445.774 1975.42 497.726H2029.12V321.125H1975.42L1952.36 304.481H1896.39Z"
                    stroke="black"
                    strokeWidth="3.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <DamageMarkers removeDamagePoint={removeDamagePoint} selectedDamagePoints={thisMarkers} />
        </svg>
    );
};
