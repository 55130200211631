import { ObjectWithDynamicKey } from '../interfaces/types/ObjectWithDynamicKey.types';

/*eslint-disable */
const policyNumber = '${path} is not valid';
/*eslint-enable */

export const yupCustomLocale: ObjectWithDynamicKey = {
    string: {
        policyNumber,
    },
};
