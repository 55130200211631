import { css } from '@emotion/react';
import { lightGrey, primary, secondary, white } from '../../variables';
import { customCANProps } from '../../../config/customCANCompanies';

export const vehicleListItemCss = (company?: customCANProps) =>
    css({
        '& .regnr': {
            borderRadius: 2,
            boxShadow: '0 0 5px rgba(68, 85, 98, 0.5)',
            padding: '0.5rem 1rem',
        },

        '& h2': {
            fontWeight: 600,
        },

        '& ul': {
            alignItems: 'start',
            display: 'flex',
            flexDirection: 'column',

            '& li:before': {
                content: '"- "',
            },
        },

        '& .button-wrapper': {
            display: 'inline',
        },

        '& .has-damages-buttons': {
            backgroundColor: white,
            color: primary,
            fontSize: '0.8rem',
            minWidth: '53px',
            width: 'fit-content',
            borderRadius: '2px',
            textAlign: 'center',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            border: `1px solid ${primary}`,
            cursor: 'pointer',
            padding: '0.375rem 0.1rem',
            margin: '2px',
            transition:
                'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
        },

        '& .has-damages-buttons-active': {
            backgroundColor: lightGrey,
            color: company ? company.color : secondary,
            border: `1px solid ${lightGrey}`,
            transition:
                'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
        },

        '& .button-hover:hover': {
            backgroundColor: primary,
            color: white,
        },

        '& .has-damages-buttons-active:focus': {
            outline: 'none',
        },

        '& .icon': {
            height: 50,
            width: '50px !important',

            '& svg': {
                height: 25,
                width: 25,
            },
        },
    });
