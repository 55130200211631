import { ssnUKMatchPattern } from './ssnUKMatchPattern';

export function ssnUKValidator(ssn: string) {
    return {
        isValid: function isValid() {
            if (ssn.length === 0) {
                return false;
            }

            const cleaned = ssn.replace(/\s/g, '');

            if (cleaned.length !== 9) {
                return false;
            }

            return ssnUKMatchPattern(cleaned);
        },
    };
}
