/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactElement } from 'react';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Datable } from '../../../../interfaces';
import { muiDatePickerStyle } from './MuiDatePicker.style';
import { InputValidationError } from '../../inputs/InputValidationError';
import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import 'moment/locale/da';
import { customCANProps } from '../../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface MuiDatePickerProps {
    customCAN?: customCANProps;
    date: Datable;
    disableFuture?: boolean;
    error?: string | ReactElement;
    locale?: any;
    name: string;
    onChange: (e: Datable) => void;
}

/**
 * Component view and component logic
 */
export const MuiDatePicker = ({
    customCAN,
    date,
    disableFuture,
    error,
    locale,
    name,
    onChange,
}: MuiDatePickerProps) => {
    return (
        <div css={css(muiDatePickerStyle(customCAN))}>
            <LocalizationProvider
                adapterLocale={moment().locale(locale)}
                dateAdapter={AdapterMoment}
            >
                <CalendarPicker {...{ date, disableFuture, onChange }} />
            </LocalizationProvider>
            <InputValidationError fieldName={name} {...{ error }} />
        </div>
    );
};
