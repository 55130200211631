import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AddBankAccountEnums,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectBankAccount } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { getAddBankAccountOption } from '../../../utils/lpo/addBankAccountUtils';
import { Controller, useForm } from 'react-hook-form';
import { normalBankAccountSchema } from '../../../validations/schemas/normalBankAccountSchema';
import { bankgiroSchema } from '../../../validations/schemas/bankgiroSchema';
import { plusgiroSchema } from '../../../validations/schemas/plusgiroSchema';
import { BankAccountInformationModel } from '../../../models/person/BankAccountInformation';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ACCOUNT_NUMBER_LABEL,
    ACCOUNT_NUMBER_PLACEHOLDER,
    ADD_BANK_ACCOUNT_LABEL,
    ADD_BANK_ACCOUNT_PLACEHOLDER,
    BACK_BUTTON,
    BANKGIRO_ACCOUNT_NUMBER_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    PLUSGIRO_ACCOUNT_NUMBER_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { ACCOUNT_NUMBER, BANK_ACCOUNT_TYPE } = FormFieldNames;
const { BANKGIRO, NORMAL_BANK_ACCOUNT, PLUSGIRO } = AddBankAccountEnums;

/**
 * Page view and page logic
 */
export const EndAddBankAccountPage = () => {
    const dispatch = useDispatch();
    const bankAccount = useSelector(selectBankAccount);
    const customCAN = useSelector(selectCustomCAN);
    const [type, setType] = useState<OptionType | null>(null);
    const [placeholder, setPlaceholder] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.end.addBankAccount');
    let schema = null;
    if (type?.value === 'NORMAL_BANK_ACCOUNT') {
        schema = normalBankAccountSchema(t);
    } else if (type?.value === 'BANKGIRO') {
        schema = bankgiroSchema(t);
    } else if (type?.value === 'PLUSGIRO') {
        schema = plusgiroSchema(t);
    }
    const {
        clearErrors,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<BankAccountInformationModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...bankAccount,
        },
    });

    // Options
    const bankgiroOption = getAddBankAccountOption(t, BANKGIRO);
    const normalBankAccountOption = getAddBankAccountOption(t, NORMAL_BANK_ACCOUNT);
    const plusgiroOption = getAddBankAccountOption(t, PLUSGIRO);

    const options = [bankgiroOption, plusgiroOption, normalBankAccountOption];

    useEffect(() => {
        const selected = options.find((x) => x.value === bankAccount.bankAccountType);
        if (selected) {
            setType(selected);
            if (selected.value === BANKGIRO) {
                setPlaceholder(BANKGIRO_ACCOUNT_NUMBER_PLACEHOLDER);
            } else if (selected.value === PLUSGIRO) {
                setPlaceholder(PLUSGIRO_ACCOUNT_NUMBER_PLACEHOLDER);
            } else {
                setPlaceholder(ACCOUNT_NUMBER_PLACEHOLDER);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccount.bankAccountType, setType, setPlaceholder]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        if (object === null) {
            setValue('accountNumber', '', { shouldValidate: false });
            clearErrors();
            setPlaceholder('');
        }
        setType(object);
        dispatch(
            lpoActions.update({
                bankAccountInformation: {
                    accountNumber: null,
                    bankAccountType: value,
                    bankName: null,
                    clearingNumber: null,
                    ownerFamilyName: null,
                    ownerGivenName: null,
                },
            })
        );
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        setValue(id, value, { shouldValidate: false });
    };

    const onSubmit = (values: BankAccountInformationModel) => {
        let { accountNumber } = values;
        if (accountNumber === '') {
            accountNumber = null;
        }

        dispatcherWithPromise(dispatch, lpoActions.update, {
            bankAccountInformation: { ...bankAccount, accountNumber },
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <MuiAutocomplete
                        error={!!errors.bankAccountType}
                        errorMessage={errors.bankAccountType?.message}
                        id={BANK_ACCOUNT_TYPE}
                        inputFieldWrapper={'col-6'}
                        label={t(ADD_BANK_ACCOUNT_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(ADD_BANK_ACCOUNT_PLACEHOLDER)}
                        value={type}
                        {...{ customCAN, options }}
                    />
                    <Controller
                        control={control}
                        name={ACCOUNT_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.accountNumber}
                                errorMessage={errors.accountNumber?.message}
                                id={ACCOUNT_NUMBER}
                                inputFieldWrapper={'col-6'}
                                label={t(ACCOUNT_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(placeholder)}
                                reference={ref}
                                disabled={!type}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
