import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { COST_LABEL } = PhraseKeys;
const { NO_NEGATIVE_NUMBERS, TYPE_ERROR } = FormFieldErrors;

/**
 * Validation logic
 */
export const costSchema = (t: TFunction) => {
    return {
        cost: Yup.number()
            .label(t(COST_LABEL))
            .integer(t(TYPE_ERROR))
            .positive(t(NO_NEGATIVE_NUMBERS))
            .typeError(t(TYPE_ERROR))
            .nullable(),
    };
};
