import React from 'react';
import { is, SummaryItem, SummaryItemProps, YesNoModel } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderHasDemands = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    hasDemands: YesNoModel
) => {
    return (
        <>
            {!is(hasDemands, null) && (
                <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />
            )}
        </>
    );
};
