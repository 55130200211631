import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Loader } from '../loader/Loader';

interface LoadMapScriptProps {
    apiKey: string;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    setMapScript: (el: HTMLScriptElement | null) => void;
    onLoad?: () => void;
    loader?: ReactNode;
}

export const LoadMapScript = ({
    children,
    apiKey,
    setMapScript,
    isLoading,
    setIsLoading,
    onLoad,
    loader,
}: PropsWithChildren<LoadMapScriptProps>) => {
    useEffect(() => {
        // @ts-ignore
        // window.initMap is the callback function from creating maps. Its  not defined before script is loaded.
        if (!window.initMap) {
            setMapScript(createMapSource(apiKey));
            // @ts-ignore
            window.initMap = () => {
                setIsLoading(false);
                if (onLoad) {
                    onLoad();
                }
            };
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{isLoading ? loader ? loader : <Loader /> : children}</>;
};

const createMapSource = (apiKey: string) => {
    const mapScript = document.createElement('script');
    mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
    mapScript.defer = true;
    mapScript.async = true;

    document.head.appendChild(mapScript);
    return mapScript;
};
