import React, { FC } from 'react';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';
import { Route, Switch } from 'react-router-dom';

// Dynamic imports
import { DynCarouselPage } from '../pages/DynCarouselPage';
import { FinalSummaryPage } from '../pages/person/dynamic/FinalSummary';
import { ReportCompletedPage } from '../pages/person/dynamic/ReportCompleted';

// Start imports
import { CategoryPage } from '../pages/person/start/Category';
import { StartSummaryPage } from '../pages/person/start/StartSummary';

// Accident imports
import { AccidentPrivacyPage } from '../pages/person/accident/Privacy';
import { AccidentClaimDatePage } from '../pages/person/accident/ClaimDate';
import { AccidentVehicleInformationPage } from '../pages/person/accident/VehicleInformation';
import { AccidentTypeOfSportPage } from '../pages/person/accident/TypeOfSport';
import { AccidentTeethOrBodyPage } from '../pages/person/accident/TeethOrBody';
import { AccidentBodyPartsPage } from '../pages/person/accident/BodyPartList';
import { AccidentTeethPage } from '../pages/person/accident/Teeth';
import { AccidentClaimCauseConsequencesPage } from '../pages/person/accident/ClaimCauseConsequences';
import { AccidentWeeksOfPlasterTreatmentPage } from '../pages/person/accident/WeeksOfPlasterTreatment';
import { AccidentDateOfPreviousInjuryPage } from '../pages/person/accident/DateOfPreviousInjury';
import { AccidentDateOfTreatmentPage } from '../pages/person/accident/DateOfTreatment';
import { AccidentMedicalInstitutionInformation } from '../pages/person/accident/MedicalInstitutionInformation';
import { AccidentDentistInformation } from '../pages/person/accident/DentistInformation';
import { AccidentUploadMedicalCertificatePage } from '../pages/person/accident/UploadMedicalCertificate';
import { AccidentUploadReceiptPage } from '../pages/person/accident/UploadReceipt';
import { AccidentOtherInsuranceCompanyPage } from '../pages/person/accident/OtherInsuranceCompany';
import { AccidentUploadAttachmentPage } from '../pages/person/accident/UploadAttachment';
import { AccidentSituationSummaryPage } from '../pages/person/accident/SituationSummary';
import { AccidentCompanyInformationPage } from '../pages/person/accident/CompanyInformation';
import { AccidentAssociationInformationPage } from '../pages/person/accident/AssociationInformation';
import { AccidentSchoolInformationPage } from '../pages/person/accident/SchoolInformation';
import { AccidentReporterInformationPage } from '../pages/person/accident/ReporterInformation';
import { AccidentBankAccountInformationPage } from '../pages/person/accident/BankAccountInformation';
import { AccidentBankAccountInformationChildPage } from '../pages/person/accident/BankAccountInformationChild';

// Illness imports
import { IllnessDiagnosisInformationPage } from '../pages/person/illness/DiagnosisInformation';
import { IllnessPrivacyPage } from '../pages/person/illness/Privacy';
import { IllnessClaimDatePage } from '../pages/person/illness/ClaimDate';
import { IllnessDateOfDiagnosisPage } from '../pages/person/illness/DateOfDiagnosis';
import { IllnessHospitalisationInformation } from '../pages/person/illness/HospitalisationInformation';
import { IllnessOtherInsuranceCompanyPage } from '../pages/person/illness/OtherInsuranceCompany';
import { IllnessUploadAttachmentPage } from '../pages/person/illness/UploadAttachment';
import { IllnessSituationSummaryPage } from '../pages/person/illness/SituationSummary';
import { IllnessAssociationInformationPage } from '../pages/person/illness/AssociationInformation';
import { AccidentClaimantInformationPage } from '../pages/person/accident/ClaimantInformation';
import { IllnessClaimantInformationPage } from '../pages/person/illness/ClaimantInformation';
import { IllnessReporterInformationPage } from '../pages/person/illness/ReporterInformation';
import { IllnessBankAccountInformationPage } from '../pages/person/illness/BankAccountInformation';
import { IllnessBankAccountInformationChildPage } from '../pages/person/illness/BankAccountInformationChild';

// Death imports
import { DeathPrivacyPage } from '../pages/person/death/Privacy';
import { DeathVehicleInformationPage } from '../pages/person/death/VehicleInformation';
import { DeathDateOfDeathPage } from '../pages/person/death/DateOfDeath';
import { DeathUploadDeathCertificatePage } from '../pages/person/death/UploadDeathCertificate';
import { DeathSituationSummaryPage } from '../pages/person/death/SituationSummary';
import { DeathClaimantInformationPage } from '../pages/person/death/ClaimantInformation';
import { DeathReporterInformationPage } from '../pages/person/death/ReporterInformation';
import { DeathEstateExecutorsPage } from '../pages/person/death/EstateExecutors';
import { DeathBankAccountInformationPage } from '../pages/person/death/BankAccountInformation';
import { DeathCompanyInformationPage } from '../pages/person/death/CompanyInformation';
import { DeathAssociationInformationPage } from '../pages/person/death/AssociationInformation';
import { DeathSchoolInformationPage } from '../pages/person/death/SchoolInformation';

export const Person: FC = () => {
    return (
        <Switch>
            {/* DYN */}
            <Route exact path={PersonRoutePaths.DYN_CAROUSEL} component={DynCarouselPage} />
            {/* START */}
            <Route exact path={PersonRoutePaths.INDEX} component={CategoryPage} />
            <Route exact path={PersonRoutePaths.START_SUMMARY} component={StartSummaryPage} />
            {/* ACCIDENT */}
            <Route exact path={PersonRoutePaths.ACCIDENT_PRIVACY} component={AccidentPrivacyPage} />
            <Route exact path={PersonRoutePaths.ACCIDENT_CLAIM_DATE} component={AccidentClaimDatePage} />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_VEHICLE_INFORMATION}
                component={AccidentVehicleInformationPage}
            />
            <Route exact path={PersonRoutePaths.ACCIDENT_TYPE_OF_SPORT} component={AccidentTypeOfSportPage} />
            <Route exact path={PersonRoutePaths.ACCIDENT_TEETH_OR_BODY} component={AccidentTeethOrBodyPage} />
            <Route exact path={PersonRoutePaths.ACCIDENT_BODY_PARTS} component={AccidentBodyPartsPage} />
            <Route exact path={PersonRoutePaths.ACCIDENT_TEETH} component={AccidentTeethPage} />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_CLAIM_CAUSE_CONSEQUENCES}
                component={AccidentClaimCauseConsequencesPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_WEEKS_OF_PLASTER_TREATMENT}
                component={AccidentWeeksOfPlasterTreatmentPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_DATE_OF_PREVIOUS_INJURY}
                component={AccidentDateOfPreviousInjuryPage}
            />
            <Route exact path={PersonRoutePaths.ACCIDENT_DATE_OF_TREATMENT} component={AccidentDateOfTreatmentPage} />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_MEDICAL_INSTITUTION_INFORMATION}
                component={AccidentMedicalInstitutionInformation}
            />
            <Route exact path={PersonRoutePaths.ACCIDENT_DENTIST_INFORMATION} component={AccidentDentistInformation} />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_UPLOAD_MEDICAL_CERTIFICATE}
                component={AccidentUploadMedicalCertificatePage}
            />
            <Route exact path={PersonRoutePaths.ACCIDENT_UPLOAD_RECEIPT} component={AccidentUploadReceiptPage} />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_OTHER_INSURANCE_COMPANY}
                component={AccidentOtherInsuranceCompanyPage}
            />
            <Route exact path={PersonRoutePaths.ACCIDENT_UPLOAD_ATTACHMENT} component={AccidentUploadAttachmentPage} />
            <Route exact path={PersonRoutePaths.ACCIDENT_SITUATION_SUMMARY} component={AccidentSituationSummaryPage} />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_COMPANY_INFORMATION}
                component={AccidentCompanyInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_ASSOCIATION_INFORMATION}
                component={AccidentAssociationInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_SCHOOL_INFORMATION}
                component={AccidentSchoolInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_CLAIMANT_INFORMATION}
                component={AccidentClaimantInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_REPORTER_INFORMATION}
                component={AccidentReporterInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_BANK_ACCOUNT_INFORMATION}
                component={AccidentBankAccountInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ACCIDENT_BANK_ACCOUNT_INFORMATION_CHILD}
                component={AccidentBankAccountInformationChildPage}
            />
            <Route exact path={PersonRoutePaths.ACCIDENT_FINAL_SUMMARY} component={FinalSummaryPage} />
            <Route exact path={PersonRoutePaths.ACCIDENT_REPORT_COMPLETED} component={ReportCompletedPage} />
            {/* ILLNESS */}
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_DIAGNOSIS_INFORMATION}
                component={IllnessDiagnosisInformationPage}
            />
            <Route exact path={PersonRoutePaths.ILLNESS_PRIVACY} component={IllnessPrivacyPage} />
            <Route exact path={PersonRoutePaths.ILLNESS_CLAIM_DATE} component={IllnessClaimDatePage} />
            <Route exact path={PersonRoutePaths.ILLNESS_DATE_OF_DIAGNOSIS} component={IllnessDateOfDiagnosisPage} />
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_HOSPITALISATION_INFORMATION}
                component={IllnessHospitalisationInformation}
            />
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_OTHER_INSURANCE_COMPANY}
                component={IllnessOtherInsuranceCompanyPage}
            />
            <Route exact path={PersonRoutePaths.ILLNESS_UPLOAD_ATTACHMENT} component={IllnessUploadAttachmentPage} />
            <Route exact path={PersonRoutePaths.ILLNESS_SITUATION_SUMMARY} component={IllnessSituationSummaryPage} />
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_ASSOCIATION_INFORMATION}
                component={IllnessAssociationInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_CLAIMANT_INFORMATION}
                component={IllnessClaimantInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_REPORTER_INFORMATION}
                component={IllnessReporterInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_BANK_ACCOUNT_INFORMATION}
                component={IllnessBankAccountInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.ILLNESS_BANK_ACCOUNT_INFORMATION_CHILD}
                component={IllnessBankAccountInformationChildPage}
            />
            <Route exact path={PersonRoutePaths.ILLNESS_FINAL_SUMMARY} component={FinalSummaryPage} />
            <Route exact path={PersonRoutePaths.ILLNESS_REPORT_COMPLETED} component={ReportCompletedPage} />
            {/* DEATH */}
            <Route exact path={PersonRoutePaths.DEATH_COMPANY_INFORMATION} component={DeathCompanyInformationPage} />
            <Route
                exact
                path={PersonRoutePaths.DEATH_ASSOCIATION_INFORMATION}
                component={DeathAssociationInformationPage}
            />
            <Route exact path={PersonRoutePaths.DEATH_SCHOOL_INFORMATION} component={DeathSchoolInformationPage} />
            <Route exact path={PersonRoutePaths.DEATH_PRIVACY} component={DeathPrivacyPage} />
            <Route exact path={PersonRoutePaths.DEATH_VEHICLE_INFORMATION} component={DeathVehicleInformationPage} />
            <Route exact path={PersonRoutePaths.DEATH_DATE_OF_DEATH} component={DeathDateOfDeathPage} />
            <Route
                exact
                path={PersonRoutePaths.DEATH_UPLOAD_DEATH_CERTIFICATE}
                component={DeathUploadDeathCertificatePage}
            />
            <Route exact path={PersonRoutePaths.DEATH_SITUATION_SUMMARY} component={DeathSituationSummaryPage} />
            <Route exact path={PersonRoutePaths.DEATH_CLAIMANT_INFORMATION} component={DeathClaimantInformationPage} />
            <Route exact path={PersonRoutePaths.DEATH_REPORTER_INFORMATION} component={DeathReporterInformationPage} />
            <Route exact path={PersonRoutePaths.DEATH_ESTATE_EXECUTORS} component={DeathEstateExecutorsPage} />
            <Route
                exact
                path={PersonRoutePaths.DEATH_BANK_ACCOUNT_INFORMATION}
                component={DeathBankAccountInformationPage}
            />
            <Route exact path={PersonRoutePaths.DEATH_FINAL_SUMMARY} component={FinalSummaryPage} />
            <Route exact path={PersonRoutePaths.DEATH_REPORT_COMPLETED} component={ReportCompletedPage} />
        </Switch>
    );
};
