import React from 'react';
import { useDispatch } from 'react-redux';
import { wizardRouterActions } from 'sagas/wizardRouter';
import { PrintableSummaryPage } from 'components/summary/final/PrintableSummary';
import { Clickable } from '@protectorinsurance/ds-can';

export const FinalSummaryPage = () => {
    const dispatch = useDispatch();

    const handleClickOption = (e: Clickable, path: string) => {
        dispatch(wizardRouterActions.update({ backToPrev: true }));
        dispatch(wizardRouterActions.goTo(path));
    };
    return <PrintableSummaryPage editDescription={true} showSummary={true} handleClickOption={handleClickOption} />;
};
