import React, { createContext, useState } from 'react';
import Tab from './Tab';
import './Tabs.scss';
import { Clickable } from '@protectorinsurance/ds-can';

interface Tab {
    id: string;
    label: string;
}

interface TabsProps {
    label: string;
    tabs: Tab[];
    children: React.ReactNode;
    selected?: string;
}

// Tab context
export interface TabContext {
    activeTab?: string;
}

export const TabContext = createContext<TabContext>({});

const Tabs = ({ label, children, tabs = [], selected = '' }: TabsProps) => {
    const [activeTab, setActiveTab] = useState<string>(selected || tabs[0].id);

    const handleClick = (e: Clickable) => {
        const { id } = e.currentTarget;
        setActiveTab(id);
    };

    /**
     * Render functions
     */
    const renderTabs = (tabs: Tab[]) =>
        tabs.map(({ id, label }: Tab, idx) => (
            <Tab key={idx} id={id} isActive={activeTab === id} onClick={handleClick}>
                {label}
            </Tab>
        ));

    return (
        <TabContext.Provider value={{ activeTab }}>
            <div className={'tabs'}>
                <div role={'tablist'} className={'tabs__list'} aria-label={label}>
                    {renderTabs(tabs)}
                </div>

                <div className={'tabs__content'}>{children}</div>
            </div>
        </TabContext.Provider>
    );
};

export default Tabs;
