import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { PhraseKeys } from '../../config/phraseKeys';

const { NAME_LABEL } = PhraseKeys;

export const landlordInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        name: Yup.string().label(t(NAME_LABEL)).required().noSpecialChars(),
        ...contactDetailsSchema(t),
    });
};
