import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { BANK_NAME_LABEL, CLEARING_NUMBER_LABEL, ACCOUNT_NUMBER_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const bankInformationSchema = (t: TFunction, required: boolean) => {
    if (required) {
        return {
            bankName: Yup.string().label(t(BANK_NAME_LABEL)).required().nullable(),
            clearingNumber: Yup.string()
                .label(t(CLEARING_NUMBER_LABEL))
                .required()
                .bankClearingNumber(required)
                .nullable(),
            accountNumber: Yup.string()
                .label(t(ACCOUNT_NUMBER_LABEL))
                .required()
                .bankAccountNumber(required)
                .nullable(),
        };
    } else {
        return {
            bankName: Yup.string().label(t(BANK_NAME_LABEL)).nullable(),
            clearingNumber: Yup.string().label(t(CLEARING_NUMBER_LABEL)).bankClearingNumber(required).nullable(),
            accountNumber: Yup.string().label(t(ACCOUNT_NUMBER_LABEL)).bankAccountNumber(required).nullable(),
        };
    }
};
