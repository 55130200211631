import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { PROPERTY_UNIT_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const unitSchema = (t: TFunction) => {
    return {
        unit: Yup.string().label(t(PROPERTY_UNIT_LABEL)).nullable(),
    };
};
