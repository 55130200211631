import { css } from '@emotion/react';

export const modalCss = css({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(25, 25, 25, 0.6)',
    overflowY: 'auto',
    zIndex: 1050,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '.dialog': {
        maxWidth: '31.5rem',
        width: '100%',
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: '0.375rem',
        height: 'auto',
        display: 'flex',
        maxHeight: 'calc(100% - 64px)',
        justifyContent: 'center',
        flexDirection: 'column',
        overflowY: 'auto',

        '& .center': {
            textAlign: 'center',
        },

        '&__header': {
            height: '4rem',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 1px 0 0 rgba(211, 220, 230, 0.6)',

            h1: {
                fontSize: '1.125rem',
                fontWeight: 300,
                marginBottom: 0,
            },

            '.btn-close': {
                position: 'absolute',
                right: '15px',
                border: 'none',
                background: 'transparent',
            },
        },

        '&__content': {
            padding: '2rem 1.5rem',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
        },

        '&__footer': {
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem 1.5rem 1.125rem 1.5rem',
            boxShadow: '0 1px 0 0 rgba(211, 220, 230, 0.6)',

            button: {
                minWidth: '200px !important',
                width: '200px !important',
                textTransform: 'uppercase',
                '&:first-of-type': {
                    order: 1,
                    color: 'white',
                },
                '&:nth-child(2)': {
                    marginRight: '0.75rem',
                },
            },
        },

        '@media (max-width: $breakpoint-s)': {
            marginRight: '1.5rem',
            marginLeft: '1.5rem',
        },
    },
});
