export enum RoleEnums {
    ACCIDENT_MANAGEMENT_COMPANY = 'ACCIDENT_MANAGEMENT_COMPANY',
    ACCOMMODATION_SUPPLIER = 'ACCOMMODATION_SUPPLIER',
    BROKER = 'BROKER',
    CLAIMANT = 'CLAIMANT',
    CLAIMANT_CONTACT_PERSON = 'CLAIMANT_CONTACT_PERSON',
    CLAIMANT_SOLICITORS = 'CLAIMANT_SOLICITORS',
    CLAIMS_HANDLING_COMPANY = 'CLAIMS_HANDLING_COMPANY',
    CO_INSURED = 'CO_INSURED',
    CONTRACTOR = 'CONTRACTOR',
    COUNSEL = 'COUNSEL',
    COUNTER_PARTY = 'COUNTER_PARTY',
    COURT = 'COURT',
    CREDIT_HIRE_COMPANY = 'CREDIT_HIRE_COMPANY',
    CRU = 'CRU',
    DEFENDANT_SOLICITORS = 'DEFENDANT_SOLICITORS',
    DENTIST = 'DENTIST',
    DISASTER_RESTORATION_PROVIDER = 'DISASTER_RESTORATION_PROVIDER',
    DRIVER = 'DRIVER',
    DVLA = 'DVLA',
    ENGINEERS = 'ENGINEERS',
    EXPERTS_OTHER = 'EXPERTS_OTHER',
    FINANCE_PROVIDER = 'FINANCE_PROVIDER',
    FORENSIC_EXPERT = 'FORENSIC_EXPERT',
    FORENSIC_PROPERTY_SUPPLIER = 'FORENSIC_PROPERTY_SUPPLIER',
    GENERAL_PRACTITIONERS = 'GENERAL_PRACTITIONERS',
    HOSPITAL = 'HOSPITAL',
    INSURANCE_COMPANY = 'INSURANCE_COMPANY',
    LAND_REGISTRY = 'LAND_REGISTRY',
    LEASEHOLDER = 'LEASEHOLDER',
    LEASING_COMPANY = 'LEASING_COMPANY',
    LITIGATION_FRIEND = 'LITIGATION_FRIEND',
    LOSS_ADJUSTER = 'LOSS_ADJUSTER',
    LOSS_ASSESSOR = 'LOSS_ASSESSOR',
    MANAGEMENT_COMPANY = 'MANAGEMENT_COMPANY',
    MEDICAL_EXPERT = 'MEDICAL_EXPERT',
    MOTOR_INSURERS_BUREAU = 'MOTOR_INSURERS_BUREAU',
    OFFICIAL_BODY = 'OFFICIAL_BODY',
    OWN_HIRE_COMPANY = 'OWN_HIRE_COMPANY',
    OWN_SOLICITORS = 'OWN_SOLICITORS',
    POLICE = 'POLICE',
    POLICY_HOLDER = 'POLICY_HOLDER',
    POLICY_HOLDERS_CONTACT_PERSON = 'POLICY_HOLDERS_CONTACT_PERSON',
    POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
    PROPERTY_SUPPLIERS = 'PROPERTY_SUPPLIERS',
    REGULATORY_OFFICERS = 'REGULATORY_OFFICERS',
    REHABILITATION_PROVIDER = 'REHABILITATION_PROVIDER',
    REINSURER = 'REINSURER',
    SALVAGE_COMPANY = 'SALVAGE_COMPANY',
    SURVEYOR = 'SURVEYOR',
    TENANT = 'TENANT',
    THIRD_PARTY = 'THIRD_PARTY',
    UNINSURED_LOSS_RECOVERY_AGENT = 'UNINSURED_LOSS_RECOVERY_AGENT',
    VALIDATION_SUPPLIER = 'VALIDATION_SUPPLIER',
    WITNESS = 'WITNESS',
}
