import { AppState } from '../../store';

export const selectUpload = (state: AppState) => state.upload;
export const selectAttachmentFiles = (state: AppState) => state.upload.attachment.files;
export const selectReportFiles = (state: AppState) => state.upload.report.files;
export const selectFileFiles = (state: AppState) => state.upload.file.files;
export const selectDescriptionFileFiles = (state: AppState) => state.upload.descriptionFile.files;
export const selectSafetyStandardsFiles = (state: AppState) => state.upload.safetyStandards.files;
export const selectReceiptFiles = (state: AppState) => state.upload.receipt.files;
export const selectCertificateFiles = (state: AppState) => state.upload.certificate.files;
