import { OptionType } from '@protectorinsurance/ds-can';

export const diagnosisOptions: OptionType[] = [
    { value: 'bacterialMeningitis', label: 'Bakteriell hjärnhinneinflammation (meningit)' },
    { value: 'blindness', label: 'Blindhet' },
    { value: 'cancer', label: 'Cancer' },
    { value: 'deafness', label: 'Dövhet' },
    { value: 'tickBorneEncephalitis', label: 'Fästingburen hjärnhinneinflammation (TBE)' },
    { value: 'paralysis', label: 'Förlamning' },
    { value: 'aphasia', label: 'Förlorad talförmåga' },
    { value: 'lossOfArmLeg', label: 'Förlust av arm eller ben' },
    { value: 'treatmentInducedHIVAIDS', label: 'HIV/AIDS från blodtransfusion eller behandling' },
    { value: 'myocardialInfarction', label: 'Hjärtinfarkt' },
    { value: 'motorNeuronSyndrome', label: 'Motorneuronsyndrom' },
    { value: 'multipleSclerosis', label: 'Multipel skleros (MS)' },
    { value: 'neuroborreliosis', label: 'Neuroborrelios' },
    { value: 'aortaAneurysmSurgery', label: 'Operation av förträngning eller aneurysm i aorta' },
    { value: 'bypassSurgery', label: 'Operation av hjärtats kranskärl' },
    { value: 'heartValveSurgery', label: 'Operation av hjärtklaff' },
    { value: 'organTransplant', label: 'Organtransplantation' },
    { value: 'parkinsonsDisease', label: 'Parkinsons sjukdom' },
    { value: 'stroke', label: 'Stroke' },
    { value: 'renalFailure', label: 'Upphörande av njurfunktion' },
    { value: 'otherDiagnosis', label: 'Annan diagnos' },
];
