import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ClaimTypeEnums,
    Clickable,
    FlowKeys,
    FormChangeable,
    Grid,
    is,
    LobKeys,
    LpoClaimCauseEnums,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectClaimType } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { selectFlow } from '../../../sagas/selectors/flowSelectors';
import { flowActions } from '../../../sagas/flow';
import { commonActions } from '../../../sagas/common';
import { getClaimTypeOption } from '../../../utils/lpo/claimTypeUtils';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_TYPE_LABEL,
    CLAIM_TYPE_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { LEGAL_EXPENCES, PROPERTY } = LobKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE, LEGAL_EXPENSES_CLAIM_DATE } = LpoRoutePaths;
const { LEGAL } = FlowKeys;
const { CLAIM_TYPE } = FormFieldNames;
const { BREAKAGE, CONTRACT, DAMAGE, INTERRUPTION, OTHER } = LpoClaimCauseEnums;
const {
    CONDOMINIUM_SUPPLEMENT,
    CONTRACT: CONTRACT_CLAIM_TYPE,
    DAMAGE: DAMAGE_CLAIM_TYPE,
    DAMAGE_CLAIM,
    ENGINE,
    FIRE,
    GLASS,
    INTERRUPTION: INTERRUPTION_CLAIM_TYPE,
    LEGAL_DISPUTE,
    NATURE,
    OTHER: OTHER_CLAIM_TYPE,
    THEFT,
    WATER,
} = ClaimTypeEnums;

/**
 * Page view and page logic
 */
export const StartClaimTypePage = () => {
    const dispatch = useDispatch();
    const [type, setType] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const claimType = useSelector(selectClaimType);
    const lob = useSelector(selectLob);
    let flow = useSelector(selectFlow);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.start.claimType');

    // Options
    const condominiumSupplementOption = getClaimTypeOption(t, CONDOMINIUM_SUPPLEMENT);
    const contractOption = getClaimTypeOption(t, CONTRACT_CLAIM_TYPE);
    const damageOption = getClaimTypeOption(t, DAMAGE_CLAIM_TYPE);
    const damageClaimOption = getClaimTypeOption(t, DAMAGE_CLAIM);
    const engineOption = getClaimTypeOption(t, ENGINE);
    const fireOption = getClaimTypeOption(t, FIRE);
    const glassOption = getClaimTypeOption(t, GLASS);
    const interruptionOption = getClaimTypeOption(t, INTERRUPTION_CLAIM_TYPE);
    const legalDisputeOption = getClaimTypeOption(t, LEGAL_DISPUTE);
    const natureOption = getClaimTypeOption(t, NATURE);
    const otherOption = getClaimTypeOption(t, OTHER_CLAIM_TYPE);
    const theftOption = getClaimTypeOption(t, THEFT);
    const waterOption = getClaimTypeOption(t, WATER);

    let options = [legalDisputeOption, damageClaimOption];
    if (is(lob, PROPERTY)) {
        options = [
            fireOption,
            waterOption,
            natureOption,
            glassOption,
            theftOption,
            damageOption,
            condominiumSupplementOption,
            interruptionOption,
            contractOption,
            engineOption,
            otherOption,
        ];
    }

    useEffect(() => {
        const selected = options.find((x) => x.value === claimType);
        if (selected) {
            setType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimType, setType]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setType(object);
        dispatch(lpoActions.update({ claimType: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            let nextAction = wizardRouterActions.goToNext();
            if (is(claimType, LEGAL_DISPUTE)) {
                dispatcherWithPromise(dispatch, commonActions.update, { lob: LEGAL_EXPENCES });
                nextAction = wizardRouterActions.goTo(LEGAL_EXPENSES_CLAIM_DATE);
                flow = LEGAL;
            }

            if (is(claimType, GLASS)) {
                dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: BREAKAGE });
                nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE);
            }

            if (is(claimType, DAMAGE_CLAIM_TYPE)) {
                dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: DAMAGE });
                nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE);
            }

            if (is(claimType, INTERRUPTION_CLAIM_TYPE)) {
                dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: INTERRUPTION });
                nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE);
            }

            if (is(claimType, CONTRACT_CLAIM_TYPE)) {
                dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: CONTRACT });
                nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE);
            }

            if (is(claimType, OTHER_CLAIM_TYPE)) {
                dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: OTHER });
                nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE);
            }

            dispatcherWithPromise(dispatch, flowActions.update, flow)
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(nextAction));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={CLAIM_TYPE}
                    inputFieldWrapper={'col-12'}
                    label={t(CLAIM_TYPE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(CLAIM_TYPE_PLACEHOLDER)}
                    value={type}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
