import { Nullable } from '../../../../../interfaces/types';

export enum TypeOfRoadKeys {
    HIGHWAY = 'HIGHWAY',
    FREEWAY = 'FREEWAY',
    OTHER = 'OTHER',
    PRIVATE = 'PRIVATE',
    ROAD = 'ROAD',
    STREET = 'STREET',
}

export type TypeOfRoadTypeKeys = Nullable<TypeOfRoadKeys>;
