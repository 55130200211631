import { AppActionWithPromise } from '../store/rootReducers';
import { actionWithPromise, ObjectWithDynamicKey, Rejectable, Resolvable } from '@protectorinsurance/ds-can';

/**
 * Action Types
 */
export enum LoadersActionTypes {
    RESET = '@app/loaders/RESET',
    REMOVE = '@app/loaders/REMOVE',
}

/**
 * Action Definitions
 */
export interface LoadersAction {
    type: LoadersActionTypes;
    data?: ObjectWithDynamicKey;
    resolve?: Resolvable;
    reject?: Rejectable;
}
export interface LoadersState {
    [key: string]: boolean;
}
/**
 * Init state
 */
export const loadersInitState: LoadersState = {};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
export default function (state = loadersInitState, { type, data }: AppActionWithPromise) {
    /*
     * Reset state
     */
    if (type === LoadersActionTypes.RESET) {
        return loadersInitState;
    }

    /*
     * Remove single action
     */
    if (type === LoadersActionTypes.REMOVE) {
        const { [type]: value, ...removedState } = state;
        return removedState;
    }

    const isSuccess = type.includes('SUCCESS');
    const isRequest = type.includes('REQUEST');
    const isFailure = type.includes('FAILURE');

    if (isRequest) {
        return { ...state, [type]: true };
    }

    if (isSuccess || isFailure) {
        return { ...state, [type.replace(/SUCCESS|FAILURE/, 'REQUEST')]: false };
    }

    return state;
}

/**
 * Redux Actions
 */
export const loadersActions = {
    remove: actionWithPromise(LoadersActionTypes.REMOVE),
    reset: actionWithPromise(LoadersActionTypes.RESET),
};
