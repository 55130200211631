import { VehicleTypeKeys } from '../../interfaces';
import { Nullable } from '../../../../../interfaces';

export const mapVehicleTypeMapMatcher = (
    types: Map<VehicleTypeKeys, Array<string> | string>,
    type: Nullable<VehicleTypeKeys | string>
) => {
    let matched: false | VehicleTypeKeys = false;

    for (const [key, value] of types) {
        const match = Array.isArray(value)
            ? value.find((v) => v === type)
            : value === type;

        if (match) {
            matched = key;
            break;
        }
    }

    return matched;
};
