import { mod10, mod11, Nullable, ssnSwedenMatchPattern } from '@protectorinsurance/ds-can';
import {
    BankClearingNumberLength,
    BankCommentKeys,
    BankInformationModel,
    BankTypesKeys,
    BankValidationResultModel,
} from '../../models/common/BankInformation';
import { banks } from '../../config/banks';
import { BANK_ACCOUNT_NUMBER_REGEX } from 'validations/formats/stringFormats';

/**
 * Implementation is based on kontonummer.js package
 * @See https://github.com/jop-io/kontonummer.js
 */

/**
 * Constants
 */
const { FOUR, FIVE } = BankClearingNumberLength;
const { TYPE_1, TYPE_2 } = BankTypesKeys;
const { COMMENT_1, COMMENT_2, COMMENT_3 } = BankCommentKeys;
/**
 * Helper functions
 */

const getFormattedAccountNumber = (accountNumber: string, bank: BankInformationModel) => {
    if (bank.type === TYPE_1) {
        // Samtliga konton av typ 1
        return accountNumber.substr(-7);
    } else if (bank.type === TYPE_2 && bank.comment === COMMENT_2) {
        // Handelsbankens koton av typ 2
        return accountNumber.substr(-9);
    } else if (bank.type === TYPE_2 && bank.comment === COMMENT_3 && accountNumber.charAt(0) === '8') {
        // Swedbanks konton av typ 2
        return accountNumber.substr(5);
    } else if (bank.type === TYPE_2 && bank.comment === COMMENT_3 && accountNumber.charAt(0) === '9') {
        // Plusgirots konton av typ 2
        return accountNumber.substr(4);
    } else {
        // Resterande konton av typ 2
        return accountNumber.substr(-10);
    }
};

const validateMod = (formattedAccountNumber: string, clearingNumber: string, bank: BankInformationModel) => {
    if (bank.type === TYPE_1 && bank.comment === COMMENT_1) {
        return mod11((clearingNumber + formattedAccountNumber).substr(-10));
    } else if (bank.type === TYPE_1 && bank.comment === COMMENT_2) {
        return mod11(clearingNumber + formattedAccountNumber);
    } else if (bank.type === TYPE_2 && bank.comment === COMMENT_2) {
        return mod11(formattedAccountNumber);
    } else {
        return mod10(formattedAccountNumber) && (clearingNumber.charAt(0) === '8' ? mod10(clearingNumber) : true);
    }
};

export const validateBankAccountNumber = (
    accountNumber: string,
    bank: BankInformationModel
): BankValidationResultModel => {
    const clearingNumber = accountNumber.substr(0, accountNumber.charAt(0) === '8' ? FIVE : FOUR);
    const formattedAccountNumber = getFormattedAccountNumber(accountNumber, bank);
    const modResult = validateMod(formattedAccountNumber, clearingNumber, bank);

    if (modResult) {
        return {
            bankName: bank.name,
            clearingNumber: clearingNumber,
            accountNumber: formattedAccountNumber,
            isValid: true,
        };
    } else {
        return {
            bankName: null,
            clearingNumber: null,
            accountNumber: null,
            isValid: false,
        };
    }
};

/**
 * Main function
 */
export const bankAccountNumberUtils = {
    isValid: (accountNumber: string, clearingNumber: Nullable<string>) => {
        if (!accountNumber || !clearingNumber) {
            return false;
        }

        if (!BANK_ACCOUNT_NUMBER_REGEX.test(accountNumber) && !ssnSwedenMatchPattern(accountNumber)) {
            return false;
        }

        const number = [clearingNumber, accountNumber].join().replace(/\D/g, '');
        return !!banks.find((bank) => {
            if (bank.regex.both.test(number)) {
                return validateBankAccountNumber(number, bank).isValid;
            }

            return false;
        });
    },
};
