/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { Fragment, ReactNode } from 'react';
import { Nullable } from '../../../interfaces/types/Nullable.types';
import { vehicleInputListItemCss } from './VehicleInputListItem.style';
import { getVehicleTypeIcon } from './VehicleListItem';
import { VehicleTypeTypeModel } from '../../../modules/can/motor/interfaces/models';

interface VehicleInputListItemProps {
    registrationNumber: Nullable<string>;
    vehicleType: VehicleTypeTypeModel;
    children: ReactNode;
    dataTestId?: string;
}

export const VehicleInputListItem = ({
    registrationNumber,
    vehicleType,
    children,
    dataTestId,
}: VehicleInputListItemProps) => {
    return (
        <Fragment>
            <div
                className={'col-2 vehicle-divider'}
                tabIndex={0}
                css={css(vehicleInputListItemCss)}
                data-testid={dataTestId}
            >
                <span className={'regnr'}>
                    {registrationNumber
                        ? registrationNumber.toUpperCase()
                        : '-'}
                </span>
            </div>
            <div
                className={'col-2 vehicle-divider'}
                css={css(vehicleInputListItemCss)}
            >
                {getVehicleTypeIcon({ type: vehicleType })}
            </div>
            <div
                className={'col-8 vehicle-divider'}
                css={css(vehicleInputListItemCss)}
            >
                {children}
            </div>
        </Fragment>
    );
};
