import { ObjectWithDynamicKey, YesNoKeys } from '@protectorinsurance/ds-can';

export enum LpoRoutePaths {
    INDEX = '/swe/:language/:lob',
    ERROR = '/error',

    // Dyn Paths
    DYN_BASE = '/swe/:language/:lob/dyn/',
    DYN_CAROUSEL = '/swe/:language/:lob/dyn/:flow?/:slug',
    // Start
    DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE = '/swe/:language/:lob/dyn/start/reporter-role',
    // Property
    DYN_CAROUSEL_PROPERTY_IS_CONTRACTOR_CONTACTED = '/swe/:language/:lob/dyn/property/is-contractor-contacted',
    // Legal Expenses
    DYN_CAROUSEL_LEGAL_EXPENSES_HAS_CLAIM = '/swe/:language/:lob/dyn/legal-expenses/has-claim',
    DYN_CAROUSEL_LEGAL_EXPENSES_HAS_ACTIVE_OPPOSITION = '/swe/:language/:lob/dyn/legal-expenses/has-active-opposition',
    // End
    DYN_CAROUSEL_END_HAS_CONTRACT_WITH_OTHER_PART = '/swe/:language/:lob/dyn/end/has-contract-with-other-part',
    DYN_CAROUSEL_END_HAS_DEMANDS = '/swe/:language/:lob/dyn/end/has-demands',
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE = '/swe/:language/:lob/dyn/end/has-other-insurance',
    DYN_CAROUSEL_END_HAS_VAT = '/swe/:language/:lob/dyn/end/has-vat',

    // Start Paths
    START_ONBOARDING = '/swe/:language/:lob/start/onboarding',
    START_PRIVACY = '/swe/:language/:lob/start/privacy',
    START_CLAIM_TYPE = '/swe/:language/:lob/start/claim-type',
    START_CLAIM_CAUSE = '/swe/:language/:lob/start/claim-cause',
    START_CLAIM_DATE = '/swe/:language/:lob/start/claim-date',

    // Property
    PROPERTY_POLICY_NUMBER = '/swe/:language/:lob/property/policy-number',
    PROPERTY_TYPE_OF_PROPERTY = '/swe/:language/:lob/property/type-of-property',
    PROPERTY_CONTRACTOR_INFORMATION = '/swe/:language/:lob/property/contractor-information',

    // Liability
    LIABILITY_CLAIMANT_INFORMATION = '/swe/:language/:lob/liability/claimant-information',
    LIABILITY_UPLOAD_ATTACHMENT = '/swe/:language/:lob/liability/upload-attachment',
    LIABILITY_CLAIM_DESCRIPTION = '/swe/:language/:lob/liability/claim-description',

    // Change Of Ownership Paths
    CHANGE_OF_OWNERSHIP_SELLER_INFORMATION = '/swe/:language/:lob/change-of-ownership/seller-information',
    CHANGE_OF_OWNERSHIP_CLAIMANT_INFORMATION = '/swe/:language/:lob/change-of-ownership/claimant-information',
    CHANGE_OF_OWNERSHIP_POLICY_NUMBER = '/swe/:language/:lob/change-of-ownership/policy-number',
    CHANGE_OF_OWNERSHIP_ACCIDENT_LOCATION = '/swe/:language/:lob/change-of-ownership/accident-location',
    CHANGE_OF_OWNERSHIP_PROPERTY_ADDRESS = '/swe/:language/:lob/change-of-ownership/property-address',
    CHANGE_OF_OWNERSHIP_CLAIM_DATE = '/swe/:language/:lob/change-of-ownership/claim-date',
    CHANGE_OF_OWNERSHIP_REPORTED_TO_SELLER_DATE = '/swe/:language/:lob/change-of-ownership/reported-to-seller-date',
    CHANGE_OF_OWNERSHIP_CLAIM_DESCRIPTION = '/swe/:language/:lob/change-of-ownership/claim-description',
    CHANGE_OF_OWNERSHIP_COST = '/swe/:language/:lob/change-of-ownership/cost',
    CHANGE_OF_OWNERSHIP_UPLOAD_ATTACHMENT = '/swe/:language/:lob/change-of-ownership/upload-attachment',
    CHANGE_OF_OWNERSHIP_REPORTER_INFORMATION = '/swe/:language/:lob/change-of-ownership/reporter-information',

    // Legal Expenses Paths
    LEGAL_EXPENSES_CLAIM_DATE = '/swe/:language/:lob/legal-expenses/claim-date',
    LEGAL_EXPENSES_AGENT_INFORMATION = '/swe/:language/:lob/legal-expenses/agent-information',
    LEGAL_EXPENSES_AGENT_REQUIRED = '/swe/:language/:lob/legal-expenses/agent-required',
    LEGAL_EXPENSES_POLICY_NUMBER = '/swe/:language/:lob/legal-expenses/policy-number',
    LEGAL_EXPENSES_POLICY_HOLDER = '/swe/:language/:lob/legal-expenses/policy-holder',
    LEGAL_EXPENSES_CLAIM_DESCRIPTION = '/swe/:language/:lob/legal-expenses/claim-description',
    LEGAL_EXPENSES_UPLOAD_ATTACHMENT = '/swe/:language/:lob/legal-expenses/upload-attachment',

    // End Paths
    END_ACCIDENT_LOCATION = '/swe/:language/:lob/end/accident-location',
    END_PROPERTY_ADDRESS = '/swe/:language/:lob/end/property-address',
    END_CLAIM_DESCRIPTION = '/swe/:language/:lob/end/claim-description',
    END_UPLOAD_ATTACHMENT = '/swe/:language/:lob/end/upload-attachment',
    END_OTHER_INSURANCE_COMPANY = '/swe/:language/:lob/end/other-insurance-company',
    END_POLICY_HOLDER = '/swe/:language/:lob/end/policy-holder',
    END_REPORTER_INFORMATION = '/swe/:language/:lob/end/reporter-information',
    END_LANDLORD_INFORMATION = '/swe/:language/:lob/end/landlord-information',
    END_POLICY_HOLDERS_CONTACT = '/swe/:language/:lob/end/policy-holders-contact',
    END_ADD_BANK_ACCOUNT = '/swe/:language/:lob/end/add-bank-account',
    END_FINAL_SUMMARY = '/swe/:language/:lob/end/final-summary',
    END_REPORT_COMPLETED = '/swe/:language/:lob/end/report-completed',
}

export const lpoWizardRoutes: ObjectWithDynamicKey = {
    // Start Routes
    [LpoRoutePaths.INDEX]: LpoRoutePaths.START_PRIVACY,
    [LpoRoutePaths.START_PRIVACY]: LpoRoutePaths.START_CLAIM_TYPE,
    [LpoRoutePaths.START_CLAIM_TYPE]: LpoRoutePaths.START_CLAIM_CAUSE,
    [LpoRoutePaths.START_CLAIM_CAUSE]: LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
    [LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE]: LpoRoutePaths.START_CLAIM_DATE,
    [LpoRoutePaths.START_CLAIM_DATE]: LpoRoutePaths.PROPERTY_POLICY_NUMBER,

    // Property Routes
    [LpoRoutePaths.PROPERTY_POLICY_NUMBER]: LpoRoutePaths.PROPERTY_TYPE_OF_PROPERTY,
    [LpoRoutePaths.PROPERTY_TYPE_OF_PROPERTY]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_CONTRACTOR_CONTACTED,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_CONTRACTOR_CONTACTED]: {
        [YesNoKeys.YES]: LpoRoutePaths.PROPERTY_CONTRACTOR_INFORMATION,
        [YesNoKeys.NO]: LpoRoutePaths.END_ACCIDENT_LOCATION,
    },
    [LpoRoutePaths.PROPERTY_CONTRACTOR_INFORMATION]: LpoRoutePaths.END_ACCIDENT_LOCATION,

    // Liability Routes
    [LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION]: LpoRoutePaths.END_ACCIDENT_LOCATION,

    // Legal Routes
    [LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DATE]: LpoRoutePaths.LEGAL_EXPENSES_AGENT_INFORMATION,
    [LpoRoutePaths.LEGAL_EXPENSES_AGENT_INFORMATION]: LpoRoutePaths.LEGAL_EXPENSES_POLICY_NUMBER,
    [LpoRoutePaths.LEGAL_EXPENSES_POLICY_NUMBER]: LpoRoutePaths.LEGAL_EXPENSES_POLICY_HOLDER,
    [LpoRoutePaths.LEGAL_EXPENSES_POLICY_HOLDER]: LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DESCRIPTION,
    [LpoRoutePaths.DYN_CAROUSEL_LEGAL_EXPENSES_HAS_CLAIM]: {
        [YesNoKeys.YES]: LpoRoutePaths.DYN_CAROUSEL_LEGAL_EXPENSES_HAS_ACTIVE_OPPOSITION,
        [YesNoKeys.NO]: LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DESCRIPTION,
    },
    [LpoRoutePaths.DYN_CAROUSEL_LEGAL_EXPENSES_HAS_ACTIVE_OPPOSITION]: LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DESCRIPTION,
    [LpoRoutePaths.LEGAL_EXPENSES_CLAIM_DESCRIPTION]: LpoRoutePaths.LEGAL_EXPENSES_UPLOAD_ATTACHMENT,
    [LpoRoutePaths.LEGAL_EXPENSES_UPLOAD_ATTACHMENT]: LpoRoutePaths.END_FINAL_SUMMARY,

    // Change Of Ownership Routes
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_SELLER_INFORMATION]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIMANT_INFORMATION,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIMANT_INFORMATION]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_POLICY_NUMBER,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_POLICY_NUMBER]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_ACCIDENT_LOCATION,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_ACCIDENT_LOCATION]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_PROPERTY_ADDRESS,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_PROPERTY_ADDRESS]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DATE,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DATE]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTED_TO_SELLER_DATE,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTED_TO_SELLER_DATE]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DESCRIPTION,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_CLAIM_DESCRIPTION]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_COST,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_COST]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_UPLOAD_ATTACHMENT,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_UPLOAD_ATTACHMENT]: LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTER_INFORMATION,
    [LpoRoutePaths.CHANGE_OF_OWNERSHIP_REPORTER_INFORMATION]: LpoRoutePaths.END_FINAL_SUMMARY,

    // End Routes
    [LpoRoutePaths.END_ACCIDENT_LOCATION]: LpoRoutePaths.END_CLAIM_DESCRIPTION,
    [LpoRoutePaths.END_PROPERTY_ADDRESS]: LpoRoutePaths.END_CLAIM_DESCRIPTION,
    [LpoRoutePaths.END_CLAIM_DESCRIPTION]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,
    [LpoRoutePaths.DYN_CAROUSEL_END_HAS_CONTRACT_WITH_OTHER_PART]: LpoRoutePaths.DYN_CAROUSEL_END_HAS_DEMANDS,
    [LpoRoutePaths.DYN_CAROUSEL_END_HAS_DEMANDS]: LpoRoutePaths.LIABILITY_UPLOAD_ATTACHMENT,
    [LpoRoutePaths.LIABILITY_UPLOAD_ATTACHMENT]: LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION,
    [LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION]: LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    [LpoRoutePaths.END_UPLOAD_ATTACHMENT]: LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    [LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: LpoRoutePaths.END_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: LpoRoutePaths.END_POLICY_HOLDER,
    },
    [LpoRoutePaths.END_OTHER_INSURANCE_COMPANY]: LpoRoutePaths.END_POLICY_HOLDER,
    [LpoRoutePaths.END_POLICY_HOLDER]: LpoRoutePaths.DYN_CAROUSEL_END_HAS_VAT,
    [LpoRoutePaths.DYN_CAROUSEL_END_HAS_VAT]: LpoRoutePaths.END_REPORTER_INFORMATION,
    [LpoRoutePaths.END_REPORTER_INFORMATION]: LpoRoutePaths.END_ADD_BANK_ACCOUNT,
    [LpoRoutePaths.END_LANDLORD_INFORMATION]: LpoRoutePaths.END_ADD_BANK_ACCOUNT,
    [LpoRoutePaths.END_ADD_BANK_ACCOUNT]: LpoRoutePaths.END_POLICY_HOLDERS_CONTACT,
    [LpoRoutePaths.END_POLICY_HOLDERS_CONTACT]: LpoRoutePaths.END_FINAL_SUMMARY,
    [LpoRoutePaths.END_FINAL_SUMMARY]: LpoRoutePaths.END_REPORT_COMPLETED,
};
