/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Fragment } from 'react';
import { Clickable } from '../../../interfaces';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { SecondaryButton } from '../buttons/SecondaryButton';
import { XIcon } from '../icons/BaseIcons';
import { modalCss } from './ModalCss.style';
import { FocusTrap } from '@mui/base';
import emptyClick from '../../../utils/emptyClick';
import { Modal } from '@mui/material';

interface ModalProps {
    title: string;
    children: React.ReactNode;
    className?: string;
    onClose?: (e: Clickable) => void;
    onConfirm?: (e: Clickable) => void;
    showCloseIcon?: boolean;
    showCloseButton?: boolean;
    showConfirmButton?: boolean;
    closeButtonText?: string;
    confirmButtonText?: string;
}

export const StandardModal = ({
    title,
    onClose = emptyClick,
    onConfirm = emptyClick,
    showCloseButton,
    showCloseIcon,
    showConfirmButton,
    children,
    className = '',
    closeButtonText = 'buttons.close',
    confirmButtonText = 'buttons.confirm',
}: ModalProps) => {
    const showFooter = showCloseButton || showConfirmButton;

    /**
     * Renders
     */
    const renderFooter = () => (
        <div className="dialog__footer">
            {showConfirmButton && (
                <PrimaryButton
                    value={confirmButtonText}
                    onClick={onConfirm}
                    className={'btn-lg mr-3'}
                    autoFocus={true}
                />
            )}

            {showCloseButton && (
                <SecondaryButton
                    value={closeButtonText}
                    onClick={onClose}
                    className={'btn-lg'}
                />
            )}
        </div>
    );

    const renderCloseIcon = () => (
        <button
            className={'btn-close'}
            onClick={onClose}
            aria-label={closeButtonText}
        >
            <XIcon />
        </button>
    );

    return (
        <FocusTrap open={true} isEnabled={() => true}>
            <Modal open={true} onClose={onClose}>
                <Fragment>
                    <div
                        css={css(modalCss)}
                        role="dialog"
                        aria-labelledby="dialog-title"
                        aria-modal={'true'}
                        tabIndex={-1}
                    >
                        <div className={`dialog ${className}`}>
                            <div className="dialog__header">
                                <h1 id={'dialog-title'} tabIndex={0}>
                                    {title}
                                </h1>
                                {showCloseIcon && renderCloseIcon()}
                            </div>

                            {children}
                            {showFooter && renderFooter()}
                        </div>
                    </div>
                </Fragment>
            </Modal>
        </FocusTrap>
    );
};
