import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectHasClaim } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';

/**
 * Destructure necessary imports
 */
const { YES, NO } = YesNoKeys;

/**
 * Page view and page logic
 */
export const legalHasClaimPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const options = [
        { id: YES, name: t(`lpo.legal.hasClaim.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lpo.legal.hasClaim.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_LEGAL_EXPENSES_HAS_CLAIM,
        stateKey: 'hasClaim',
        i18n: {
            ns: 'lpo.legal.hasClaim',
        },
        selectorValue: selectHasClaim(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (option.id === YES) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }
                dispatcherWithPromise(dispatch, lpoActions.update, {
                    hasClaim: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
