/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { SyntheticEvent } from 'react';
import { Nullable } from '../../../interfaces';
import { getVehicleTypeIcon } from '../vehicleList/VehicleListItem';
import { vehicleObjectCss } from './VehicleObject.style';
import { Grid } from '../../molecules/grid/Grid';
import {
    displayArrayWithSeparator,
    VehicleTypeTypeModel,
} from '../../../modules';
import { Paper } from '@mui/material';
import { ActionButton } from '../buttons/ActionButton';
import {
    MuiAutocomplete,
    OptionType,
} from '../mui/muiAutocomplete/MuiAutocomplete';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface VehicleObjectProps {
    buttonAction?: () => void;
    buttonText?: string;
    className?: string;
    customCAN?: customCANProps;
    id?: string;
    label?: string;
    make: Nullable<string>;
    model: Nullable<number>;
    onChange?: (e: SyntheticEvent, option: any) => void;
    placeholder?: string;
    registrationNumber: Nullable<string>;
    type: Nullable<string>;
    vehicleSelect?: boolean;
    vehicleType: VehicleTypeTypeModel;
    vehicleTypeOptions?: Array<OptionType>;
    vehicleTypeSelectedOption?: Nullable<OptionType | OptionType[]>;
}

/**
 * Component view and component logic
 */
export const VehicleObject = ({
    buttonAction,
    buttonText,
    className,
    customCAN,
    id,
    label,
    make,
    model,
    onChange,
    placeholder,
    registrationNumber,
    type,
    vehicleSelect,
    vehicleType,
    vehicleTypeOptions,
    vehicleTypeSelectedOption,
}: VehicleObjectProps) => {
    return (
        <div className={className} css={css(vehicleObjectCss(customCAN))}>
            <Paper variant={'outlined'}>
                <Grid className={'align-items-center'}>
                    <div className={'col-1'}>
                        <div className={'icon'}>
                            {getVehicleTypeIcon({ type: vehicleType })}
                        </div>
                    </div>
                    {registrationNumber && (
                        <div className={'col-2'}>
                            <Paper className={'badge'} variant={'outlined'}>
                                {registrationNumber
                                    ? registrationNumber.toUpperCase()
                                    : ''}
                            </Paper>
                        </div>
                    )}
                    <div className={'col-2'}>
                        {model ? (
                            <Paper className={'badge'} variant={'outlined'}>
                                {model}
                            </Paper>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={'col-5'}>
                        {displayArrayWithSeparator([make, type], ' ')}
                    </div>
                    <div className={'col-2'}>
                        {buttonAction && buttonText && (
                            <ActionButton
                                className={'small'}
                                onClick={buttonAction}
                                value={buttonText}
                                {...{ customCAN }}
                            />
                        )}
                    </div>
                    {vehicleSelect && (
                        <MuiAutocomplete
                            inputFieldWrapper={'col-3 margin-bottom'}
                            openOnFocus={true}
                            options={vehicleTypeOptions}
                            value={vehicleTypeSelectedOption}
                            {...{ customCAN, id, label, onChange, placeholder }}
                        />
                    )}
                </Grid>
            </Paper>
        </div>
    );
};
