import React from 'react';
import { HospitalisationInformationModel } from 'models/person/HospitalisationInformation';
import { CalendarIcon, Grid } from '@protectorinsurance/ds-can';
import { HospitalisationInformationListItem } from './HospitalisationInformationListItem';

export interface IHospitalisationInformationList {
    hospitalisation: HospitalisationInformationModel[];
    handleDelete: (e: React.MouseEvent, hospitalisation: HospitalisationInformationModel) => void;
    buttonText: string;
    fromText: string;
    toText: string;
    atText: string;
}

export const HospitalisationInformationList = ({
    hospitalisation,
    handleDelete,
    buttonText,
    fromText,
    toText,
    atText,
}: IHospitalisationInformationList) => {
    const calendarIcon = <CalendarIcon wh={40} />;
    const hospitalisationRender = hospitalisation.map((hospitalisation: HospitalisationInformationModel, idx) => {
        return (
            <HospitalisationInformationListItem
                key={idx}
                hospitalisation={hospitalisation}
                icon={calendarIcon}
                {...{ handleDelete, buttonText, fromText, toText, atText }}
            />
        );
    });

    return <Grid>{hospitalisationRender}</Grid>;
};
