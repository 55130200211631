import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    AccidentIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimReporterRoleKeys,
    Clickable,
    CompanyIcon,
    EmployeeIcon,
    is,
    KindergardenIcon,
    LobKeys,
    LpoClaimCauseEnums,
    MunicipalityIcon,
    PedestrianIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectLob } from '../../../../sagas/selectors/commonSelectors';
import { selectClaimCause, selectClaimReporterRole } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';

/**
 * Destructure necessary imports
 */
const { BROKER, CONDOMINIUM_HOLDERS, INJURED, POLICY_HOLDER, PROPERTY_MANAGER, TENANT } = ClaimReporterRoleKeys;
const { LIABILITY } = LobKeys;
const { INJURY } = LpoClaimCauseEnums;

/**
 * Page view and page logic
 */
export const startClaimReporterRolePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const lob = selectLob(state);
    const claimCause = selectClaimCause(state);

    // Options
    const optBroker = { id: BROKER, name: t(`lpo.start.claimReporterRole.select.${BROKER}`), icon: <EmployeeIcon /> };
    const optPolicyHolder = {
        id: POLICY_HOLDER,
        name: t(`lpo.start.claimReporterRole.select.${POLICY_HOLDER}`),
        icon: <CompanyIcon />,
    };
    const optCondominiumHolders = {
        id: CONDOMINIUM_HOLDERS,
        name: t(`lpo.start.claimReporterRole.select.${CONDOMINIUM_HOLDERS}`),
        icon: <KindergardenIcon />,
    };
    const optPropertyManager = {
        id: PROPERTY_MANAGER,
        name: t(`lpo.start.claimReporterRole.select.${PROPERTY_MANAGER}`),
        icon: <MunicipalityIcon />,
    };
    const optTenant = { id: TENANT, name: t(`lpo.start.claimReporterRole.select.${TENANT}`), icon: <PedestrianIcon /> };
    const optInjured = {
        id: INJURED,
        name: t(`lpo.start.claimReporterRole.select.${INJURED}`),
        icon: <AccidentIcon />,
    };

    let options = [optBroker, optPolicyHolder, optCondominiumHolders, optPropertyManager, optTenant];

    if (is(lob, LIABILITY)) {
        if (is(claimCause, INJURY)) {
            options = [optBroker, optPolicyHolder, optInjured];
        } else {
            options = [optBroker, optPolicyHolder];
        }
    }

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
        stateKey: 'claimReporterRole',
        i18n: {
            ns: 'lpo.start.claimReporterRole',
        },
        selectorValue: selectClaimReporterRole(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, lpoActions.update, {
                    claimReporterRole: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
