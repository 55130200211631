import { Nullable } from '@protectorinsurance/ds-can';

/**
 * Bank types and bank comments are based on the documentation of Bankgirot
 * @See https://github.com/jop-io/kontonummer.js/blob/master/Documents/bankernaskontonummeruppbyggnad_anvandarmanual_sv_2019_08_21.pdf
 */

export enum BankTypesKeys {
    TYPE_1 = 1,
    TYPE_2 = 2,
}

export enum BankCommentKeys {
    COMMENT_1 = 1,
    COMMENT_2 = 2,
    COMMENT_3 = 3,
    COMMENT_4 = 4,
}

export enum BankClearingNumberLength {
    FOUR = 4,
    FIVE = 5,
}

export interface BankInformationModel {
    name: string;
    type: BankTypesKeys;
    comment: BankCommentKeys;
    regex: {
        both: RegExp;
        accountNumber: RegExp;
        clearingNumber: RegExp;
    };
}

export interface BankValidationResultModel {
    bankName: Nullable<string>;
    clearingNumber: Nullable<string>;
    accountNumber: Nullable<string>;
    isValid: boolean;
}
