import React, { Dispatch, SetStateAction } from 'react';
import { ReporterInformationModel } from '../../../../models/person/ReporterInformation';
import { useI18n } from '../../../../hooks/useI18n';
import { I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayReporterInformationProps {
    model: Partial<ReporterInformationModel>;
    i18n?: I18NOptions;
    error: Dispatch<SetStateAction<boolean>>;
}

export const DisplayReporterInformation = ({ model, i18n, error }: DisplayReporterInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace); // Internal I18N instance

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));

    const renderFirstAndLastName = () => {
        if (model.firstName && !model.lastName) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.lastName')}</p>;
        }
        if (!model.firstName && model.lastName) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.firstName')}</p>;
        }
        if (!model.firstName && !model.lastName) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.firstAndLastName')}</p>;
        }
        return <SummaryItemText label={tWithPrefix('text.fullName')} text={`${model.firstName} ${model.lastName}`} />;
    };

    const renderPhone = () => {
        if (model.phone) {
            return <SummaryItemText label={tWithPrefix(`text.phone`)} text={`${model.phone}`} />;
        }
        if (!model.email) {
            error(true);
            return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.phoneOrEmail')}</p>;
        }
    };

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 spacing header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix(`reporterInformation`)}
                </p>
            </div>
            <div className={'col-4 divider no-border text-align-left'}>{renderFirstAndLastName()}</div>
            <div className={'col-8 divider no-border text-align-left'}>
                {model.nationalIdentity && (
                    <SummaryItemText label={tWithPrefix(`text.nationalIdentity`)} text={model.nationalIdentity} />
                )}
            </div>
            <div className={`${model.email ? `col-4` : `col-12`} divider text-align-left`}>{renderPhone()}</div>
            {model.email && (
                <div className={'col-8 divider text-align-left'}>
                    <SummaryItemText label={tWithPrefix(`text.email`)} text={model.email} />
                </div>
            )}
        </>
    );
};
