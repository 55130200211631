import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';
import { FormFieldErrors } from '../../config/formFieldNames';

/**
 * Destructure necessary imports
 */
const { MILEAGE_LABEL } = PhraseKeys;
const { TYPE_ERROR, NO_NEGATIVE_NUMBERS } = FormFieldErrors;

/**
 * Validation logic
 */
export const mileageSchema = (t: TFunction) => {
    return {
        mileage: Yup.number()
            .label(t(MILEAGE_LABEL))
            .integer(t(TYPE_ERROR))
            .moreThan(-1, t(NO_NEGATIVE_NUMBERS))
            .typeError(t(TYPE_ERROR))
            .nullable()
            .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
    };
};
