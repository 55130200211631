/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ReactNode } from 'react';
import { displayErrorCss } from './DisplayError.style';

interface DisplayErrorProps {
    message: string;
    info: string;
    icon: ReactNode;
}

export const DisplayError = ({ message, info, icon }: DisplayErrorProps) => {
    return (
        <div className={'display-response'} css={css(displayErrorCss)}>
            {icon}
            <div className={'message'}>
                <p>{message}</p>
                <p>{info}</p>
            </div>
        </div>
    );
};
