import {
    CompanyModel,
    ContractorInformationModel,
    DamagedItemKeys,
    OwnerInformationListModel,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';
import { BankAccountInformationModel } from '../../models/person/BankAccountInformation';

/**
 * Destructure necessary imports
 */
const { ANIMAL, OTHER } = DamagedItemKeys;

/**
 * Utility logic
 */
export const formatBankAccountInformation = (bankAccount: BankAccountInformationModel, t: TFunction) => {
    return `${t(`lpo.addBankAccount.select.${bankAccount.bankAccountType}`)},
     ${bankAccount.accountNumber ? ` (${bankAccount.accountNumber})` : ''}`;
};

export const formatContractorName = (contractor: ContractorInformationModel) => {
    return `${contractor.name ? contractor.name : ''}${
        contractor.businessNumber ? ` (${contractor.businessNumber})` : ''
    }`;
};

export const formatCompanyName = (company: CompanyModel) => {
    return `${company.name ? company.name : ''}${company.businessNumber ? ` (${company.businessNumber})` : ''}`;
};

export const formatOwner = (isVehicle: VehicleModel | undefined, owner: OwnerInformationListModel, t: TFunction) => {
    return `${
        isVehicle
            ? ''
            : owner.damagedItem && owner.damagedItem.includes(ANIMAL || OTHER)
            ? t(`motor.end.finalSummary.damagedItem.select.${owner.damagedItem}`)
            : owner.damagedItem
    }${isVehicle ? '' : ` ${t('motor.end.finalSummary.text.ownedBy')} `}${
        isVehicle ? '' : owner.firstName ? owner.firstName : owner.name ? owner.name : ''
    }${isVehicle ? '' : owner.lastName ? ` ${owner.lastName}` : ''}`;
};
