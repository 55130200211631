import React, { useEffect, useState } from 'react';
import { isDev, isTest } from '../../utils/env';
import './Layout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectFlow } from '../../sagas/selectors/flowSelectors';
import { selectRouterPathname } from '../../sagas/selectors/routerSelectors';
import { progressBarUtil } from '../../utils/flow/progressBarUtil';
import { getLobProgressBarRoutes } from '../../utils/getLobProgressBarRoutes';
import { is, NavBar, ProgressBar } from '@protectorinsurance/ds-can';
import { useI18n } from '../../hooks/useI18n';
import {
    selectCustomCAN,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
} from '../../sagas/selectors/commonSelectors';
import { setHTMLLang } from '../../utils/locale/setHTMLLang';
import { motorActions } from '../../sagas/motor';
import dispatcherWithPromise from '../../utils/dispatcherWithPromise';
import { commonActions } from '../../sagas/common';
import { MotorRoutePaths } from '../../config/wizardRouter/motorWizardRoutes';
import { getLocaleFromUrl } from '../../utils/getLocaleFromUrl';
import { BaseRoutePaths } from '../../config/wizardRouter/baseWizardRoutes';
import { selectLoggedInClaimReport } from '../../sagas/selectors/reportSelectors';

/**
 * Destructure necessary imports
 */
const { START_LOGIN, START_CONTINUE_CLAIM, START_CLAIM_DATE } = MotorRoutePaths;

/**
 * Interfaces
 */
interface LayoutProps {
    children: React.ReactNode;
}

/**
 * Component view and component logic
 */
export const Layout = ({ children }: LayoutProps) => {
    const dispatch = useDispatch();
    const flow = useSelector(selectFlow);
    const path = useSelector(selectRouterPathname);
    const lob = useSelector(selectLob);
    const isLoggedIn = useSelector(selectLoggedInClaimReport);
    const customCAN = useSelector(selectCustomCAN);
    const [progress, setProgress] = useState(0);
    const progressBarRoutes = getLobProgressBarRoutes(lob);
    const motorPath = (path: MotorRoutePaths | BaseRoutePaths) => {
        const { language } = getLocaleFromUrl();
        const url = path.replace(':language', language);
        return url;
    };

    useEffect(() => {
        setProgress(progressBarUtil(flow, path, progressBarRoutes));
    }, [flow, path, progressBarRoutes]);

    useEffect(() => {
        if (
            isLoggedIn &&
            !is(path, motorPath(START_LOGIN)) &&
            !is(path, motorPath(START_CONTINUE_CLAIM)) &&
            !is(path, motorPath(START_CLAIM_DATE))
        ) {
            dispatcherWithPromise(dispatch, motorActions.update, { path }).then(() => dispatch(commonActions.send()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    const envClassName = isDev() ? 'is-dev' : isTest() ? 'is-test' : '';
    const { t } = useI18n();
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const titlePostfix = isDev() ? 'DEV' : isTest() ? 'TEST' : '';
    const logoText = 'logo.link';
    document.documentElement.lang = setHTMLLang(language);

    return (
        <>
            <header className={`header ${envClassName} no-print`}>
                <NavBar
                    domain={t('pageName')}
                    environment={titlePostfix}
                    label={t(logoText)}
                    {...{ country, customCAN, envClassName, language }}
                />
            </header>
            <section role="main" className={'content'}>
                {children}
            </section>
            <footer className={'footer no-print'}>
                <ProgressBar width={progress} {...{ customCAN }} />
            </footer>
        </>
    );
};
