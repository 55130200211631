import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimantMaritalStatusKeys,
    Clickable,
    PartnerIcon,
    RelativeIcon,
    SamboIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimantMaritalStatus } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { DOMESTIC_PARTNER, MARRIED, SINGLE } = ClaimantMaritalStatusKeys;

/**
 * Page view and page logic
 */
export const deathClaimantMaritalStatusPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: MARRIED, name: t(`lob.person.claimantMaritalStatus.death.select.${MARRIED}`), icon: <PartnerIcon /> },
        { id: SINGLE, name: t(`lob.person.claimantMaritalStatus.death.select.${SINGLE}`), icon: <RelativeIcon /> },
        {
            id: DOMESTIC_PARTNER,
            name: t(`lob.person.claimantMaritalStatus.death.select.${DOMESTIC_PARTNER}`),
            icon: <SamboIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIMANT_MARITAL_STATUS,
        stateKey: 'claimantMaritalStatus',
        i18n: {
            ns: 'lob.person.claimantMaritalStatus.death',
        },
        selectorValue: selectClaimantMaritalStatus(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { claimantMaritalStatus: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
