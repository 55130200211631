import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    EngineDamageIcon,
    FireDamagesIcon,
    MotorClaimCauseKeys,
    OtherInjuriesIcon,
    ParkedIcon,
    TheftAndDamageIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause, selectClaimCauseList } from '../../../../sagas/selectors/motorSelectors';
import { motorActions } from '../../../../sagas/motor';
import { claimCauseListUtil } from '../../../../utils/claimCauseListUtils';
import { flowActions } from '../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { ENGINE, FIRE, OTHER, PARKING, THEFT_AND_DAMAGE } = MotorClaimCauseKeys;
const { DYN_CAROUSEL_PARKED_CLAIM_CAUSE } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const parkedClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCauseList = selectClaimCauseList(state);
    const options = [
        {
            id: THEFT_AND_DAMAGE,
            name: t(`motor.parked.claimCause.select.${THEFT_AND_DAMAGE}`),
            icon: <TheftAndDamageIcon />,
        },
        { id: ENGINE, name: t(`motor.parked.claimCause.select.${ENGINE}`), icon: <EngineDamageIcon /> },
        { id: PARKING, name: t(`motor.parked.claimCause.select.${PARKING}`), icon: <ParkedIcon /> },
        { id: FIRE, name: t(`motor.parked.claimCause.select.${FIRE}`), icon: <FireDamagesIcon /> },
        { id: OTHER, name: t(`motor.parked.claimCause.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.parked.claimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                const claimCausesList = [...claimCauseList];
                dispatcherWithPromise(dispatch, motorActions.update, {
                    claimCauseList: claimCauseListUtil(claimCausesList, DYN_CAROUSEL_PARKED_CLAIM_CAUSE, option.id),
                });

                dispatch(wizardActions.skipBackToPrev(true));
                dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id, flow: option.id })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
