import { useEffect, useState } from 'react';

export const useGoogleMaps = () => {
    const [google, setGoogle] = useState<any>();
    useEffect(() => {
        if (window.google) {
            setGoogle(window.google);
        }
    }, [window.google]);
    return google;
};
