/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { MouseEvent } from 'react';
import { PersonModel } from '../../../interfaces/models/Person.model';
import { ReactComponent as PersonSmallIcon } from '../../../assets/icons/base/small-person.svg';
import { personListCss } from './PersonList.style';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface PersonListProps {
    buttonText: string;
    customCAN?: customCANProps;
    handleDelete(e: MouseEvent, person: PersonModel): void;
    persons: PersonModel[];
}

/**
 * Component view and component logic
 */
export const PersonList = ({
    buttonText,
    customCAN,
    handleDelete,
    persons,
}: PersonListProps) => {
    const personsRender = persons.map((person: PersonModel, idx) => {
        return (
            <li key={idx} className={'person-list-item'}>
                <PersonSmallIcon />
                <span className={'list-text'} data-hj-suppress>
                    {person.firstName} {person.lastName}
                </span>
                <button
                    type={'button'}
                    data-testid={'delete-btn'}
                    className={'delete-btn'}
                    onClick={(e) => handleDelete(e, person)}
                >
                    {buttonText}
                </button>
            </li>
        );
    });

    return (
        <ul
            className={'list-container'}
            data-testid={'person-list'}
            css={css(personListCss(customCAN))}
        >
            {personsRender}
        </ul>
    );
};
