import React, { useEffect } from 'react';

interface InfoWindowProps {
    setInfoWindow: (i: google.maps.InfoWindow) => void;
}
/**
 * InfoWindow must be used as a child of LoadMapScript (or otherwise make sure to load map script before using InfoWindow)
 **/
export const InfoWindow = ({ setInfoWindow }: InfoWindowProps) => {
    useEffect(() => {
        const infoWindow = createInfoWindow('');
        setInfoWindow(infoWindow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
};

const createInfoWindow = (content: string) => {
    return new google.maps.InfoWindow({
        content,
    });
};
