/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { MutableRefObject, Fragment, ReactElement } from 'react';
import { Changable, Focusable } from '../../../interfaces/types/Events.types';
import { inputCss } from './Input.style';
import InputAppend from './InputAppend';

export type InputModeTypes = 'numeric' | 'tel' | 'email' | 'text';

interface InputProps {
    name: string;
    label: string;
    handleChange: (e: Changable) => void;
    handleBlur: (e: Focusable) => void;
    reference?: (instance: HTMLInputElement) => void;
    placeholder?: string;
    inputType?: 'text' | 'number' | 'email';
    inputMode?: InputModeTypes;
    inputClassName?: string;
    append?: string | ReactElement;
    autocomplete?: 'on' | 'off';
    autocompleteValue?: string;
    error?: string | React.ReactElement;
    immRef?: MutableRefObject<HTMLInputElement | null>;
    disabled?: boolean;
    min?: string;
    max?: string;
}

export const Input = ({
    name,
    inputType = 'text',
    inputMode = 'text',
    append,
    reference,
    immRef,
    placeholder = '',
    handleBlur,
    handleChange,
    autocomplete = 'on',
    autocompleteValue,
    inputClassName = '',
    disabled,
    error,
    min,
    max,
}: InputProps) => {
    return (
        <Fragment>
            <input
                type={inputType}
                inputMode={inputMode}
                data-testid={name}
                css={css(inputCss)}
                className={`input-field ${inputClassName} ${
                    append ? 'input-append' : ''
                }`}
                id={name}
                name={name}
                ref={(i: HTMLInputElement) => {
                    if (reference) {
                        reference(i);
                    }
                    if (immRef) {
                        immRef.current = i;
                    }
                }}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete={autocompleteValue || autocomplete}
                disabled={disabled}
                aria-invalid={error ? 'true' : 'false'}
                aria-describedby={`${name}-id`}
                min={min}
                max={max}
            />
            {append && <InputAppend append={append} />}
        </Fragment>
    );
};
