import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { liabilityDescriptionSchema } from '../fieldSchemas/liabilityDescriptionSchema';

/**
 * Validation logic
 */
export const liabilityClaimDescriptionSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...liabilityDescriptionSchema(t),
    });
};
