import { useSelector } from 'react-redux';
import { selectTogglesService } from '../sagas/selectors/togglesServiceSelector';
import { TogglesServiceTogglesEnums } from '@protectorinsurance/ds-can';

export const useToggles = () => {
    const toggles = useSelector(selectTogglesService);

    const isEnabled = (toggle: TogglesServiceTogglesEnums) => {
        return toggles[toggle] ? toggles[toggle] === true : false;
    };

    const isDisabled = (toggle: TogglesServiceTogglesEnums) => !isEnabled(toggle);

    return [isEnabled, isDisabled];
};
