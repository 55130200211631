interface GeoLocation {
    browserHasGeolocation: boolean;
    position: GeolocationPosition;
}

export const getGeoLocation = () => {
    return new Promise<GeoLocation>((resolve, reject) => {
        if (navigator.geolocation) {
            let positionOptions = {};
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({ browserHasGeolocation: true, position });
                },
                () => {
                    reject({ browserHasGeolocation: true });
                },
                positionOptions
            );
        } else {
            reject({ browserHasGeolocation: false });
        }
    });
};
