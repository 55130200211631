export enum DamageLocationTypeEnums {
    BREEDING_AREA = 'BREEDING_AREA',
    CONSTRUCTION_SITE = 'CONSTRUCTION_SITE',
    CONSTRUCTION_SITE_MAINTENANCE = 'CONSTRUCTION_SITE_MAINTENANCE',
    CULTURAL_AREA = 'CULTURAL_AREA',
    FARMING_AREA_GROUND_CROP = 'FARMING_AREA_GROUND_CROP',
    FARMING_AREA_TREE_BUSH_CROP = 'FARMING_AREA_TREE_BUSH_CROP',
    FORESTRY_ZONE = 'FORESTRY_ZONE',
    HEALTH_ESTABLISHMENT = 'HEALTH_ESTABLISHMENT',
    HOME_AREA = 'HOME_AREA',
    MAINTENANCE_AREA = 'MAINTENANCE_AREA',
    NO_INFO = 'NO_INFO',
    OFFICE_AREA = 'OFFICE_AREA',
    OTHER_GROUP_010 = 'OTHER_GROUP010',
    OTHER_GROUP_020 = 'OTHER_GROUP020',
    OTHER_GROUP_030 = 'OTHER_GROUP_030',
    OTHER_GROUP_090 = 'OTHER_GROUP_090',
    PRODUCTION_AREA = 'PRODUCTION_AREA',
    PUBLIC_AREA = 'PUBLIC_AREA',
    QUARRIES_AND_MINES = 'QUARRIES_AND_MINES',
    SALES_AREA = 'SALES_AREA',
    SCHOOL = 'SCHOOL',
    STORAGE = 'STORAGE',
}
