import { css } from '@emotion/react';

export const printableCss = css({
    '&.container': {
        flexDirection: 'column',
        '.display': {
            backgroundColor: 'red',
            display: 'none',

            '.logo-container': {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '1.5rem',
            },
        },
    },
    '&.hide-content': {
        display: 'none',
    },
    '&.hide-all-content': {
        display: 'none',
    },
    '.printable': {
        '@media print': {
            'p, .lead': {
                fontSize: '1.5rem',
            },
        },
    },
    '@media print': {
        '&.container': {
            '.display': {
                display: 'block',
            },
        },
        '.summary-btn': {
            visibility: 'hidden',
        },
        '.help-text': {
            visibility: 'hidden',
        },
        '&.hide-content': {
            display: 'block !important',
        },

        '& .text-align-left': {
            textAlign: 'left',
        },
    },
});
