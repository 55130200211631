import { Nullable } from '../../../../../interfaces/types';

export enum RelationToWitnessKeys {
    NO_RELATION = 'NO_RELATION',
    FAMILY = 'FAMILY',
    FRIEND = 'FRIEND',
    COLLEAGUE = 'COLLEAGUE',
    PASSENGER_VEHICLE = 'PASSENGER_VEHICLE',
    PASSENGER_BUS_TAXI = 'PASSENGER_BUS_TAXI',
}

export interface RelationToWitnessModel {
    relationToWitness?: RelationToWitnessTypeModel;
}

export type RelationToWitnessTypeModel = Nullable<RelationToWitnessKeys>;
