/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { inputLabelCss } from './InputLabel.style';
import { EditButton } from '../buttons/EditButton';
import { Clickable } from '../../../interfaces';

interface InputLabelProps {
    ariaLabel?: string;
    className?: string;
    editable?: boolean;
    handleEdit?: (e: Clickable) => void;
    label: string;
    name: string;
}

export const InputLabel = ({
    ariaLabel,
    className = '',
    editable = false,
    handleEdit,
    label,
    name,
}: InputLabelProps) => {
    return (
        <label
            htmlFor={name}
            className={`input-label ${className}`}
            data-testid={`label-${name}`}
            css={css(inputLabelCss)}
        >
            {label}:
            {editable && <EditButton onClick={handleEdit} {...{ ariaLabel }} />}
        </label>
    );
};
