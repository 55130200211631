import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimantAgeCategoryKeys,
    ClaimReporterRoleKeys,
    Clickable,
    DeceasedChildIcon,
    EmployerIcon,
    is,
    ParentsChildIcon,
    PartnerIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimantAgeCategory, selectClaimReporterRole } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { OTHER, PARENT, PARTNER } = ClaimReporterRoleKeys;
const { CHILD } = ClaimantAgeCategoryKeys;

/**
 * Page view and page logic
 */
export const deathClaimReporterRolePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const claimantAgeCategory = selectClaimantAgeCategory(state);
    let options = [
        { id: PARTNER, name: t(`lob.person.death.claimReporterRole.select.${PARTNER}`), icon: <PartnerIcon /> },
        { id: OTHER, name: t(`lob.person.death.claimReporterRole.select.${OTHER}`), icon: <EmployerIcon /> },
    ];

    if (is(claimantAgeCategory, CHILD)) {
        options = [
            { id: PARENT, name: t(`lob.person.death.claimReporterRole.select.${PARENT}`), icon: <ParentsChildIcon /> },
            { id: OTHER, name: t(`lob.person.death.claimReporterRole.select.${OTHER}`), icon: <DeceasedChildIcon /> },
        ];
    }

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_DEATH_CLAIM_REPORTER_ROLE,
        stateKey: 'claimReporterRole',
        i18n: {
            ns: 'lob.person.death.claimReporterRole',
        },
        selectorValue: selectClaimReporterRole(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { claimReporterRole: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
