import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    MuiTextInput,
    PageLayout,
    TeethOrBodyKeys,
} from '@protectorinsurance/ds-can';
import { selectMedicalInstitution, selectTeethOrBody } from '../../../sagas/selectors/personSelectors';
import { MedicalInstitutionModel } from '../../../models/person/MedicalInstitution';
import { medicalInstitutionInformationSchema } from '../../../validations/schemas/medicalInstitutionInformationSchema';
import { personActions } from '../../../sagas/person';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { MEDICAL_INSTITUTION } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    MEDICAL_INSTITUTION_LABEL,
    MEDICAL_INSTITUTION_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { BOTH } = TeethOrBodyKeys;
const { DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const AccidentMedicalInstitutionInformation = () => {
    const dispatch = useDispatch();
    const medicalInstitution = useSelector(selectMedicalInstitution);
    const teethOrBody = useSelector(selectTeethOrBody);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.medicalInstitutionInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<MedicalInstitutionModel>({
        resolver: yupResolver(medicalInstitutionInformationSchema(t)),
        defaultValues: {
            medicalInstitution,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: MedicalInstitutionModel) => {
        let nextAction = wizardActions.goToNext();
        if (is(teethOrBody, BOTH)) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_ACCIDENT_IS_DENTIST_CONTACTED);
        }

        dispatcherWithPromise(dispatch, personActions.update, { medicalInstitution: values.medicalInstitution })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={MEDICAL_INSTITUTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.medicalInstitution}
                                errorMessage={errors.medicalInstitution?.message}
                                id={MEDICAL_INSTITUTION}
                                inputFieldWrapper={'col-12'}
                                label={t(MEDICAL_INSTITUTION_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(MEDICAL_INSTITUTION_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
