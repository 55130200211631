import { Nullable } from '../../../../../interfaces';

export enum IsDisabilityPensionerKeys {
    NO = 'NO',
    DISABILITY_BENEFIT = 'DISABILITY_BENEFIT',
    WORK_ASSESSMENT_ALLOWANCE = 'WORK_ASSESSMENT_ALLOWANCE',
    LEAVE = 'LEAVE',
    OTHER = 'OTHER',
}

export type IsDisabilityPensionerModel = Nullable<IsDisabilityPensionerKeys>;
