import { ORG_NUMBER_REGEX } from '../validation/stringFormats';
import { Nullable } from '@protectorinsurance/ds-can';

export const isOrgNumberValid = (orgNumber?: Nullable<string>) => {
    if (orgNumber && !orgNumber.match(ORG_NUMBER_REGEX)) {
        const hyphenatedOrgNumber = orgNumber.replace(/(\d{6})(\d{4})/, '$1-$2');
        return hyphenatedOrgNumber;
    } else {
        return orgNumber;
    }
};
