import React, { useEffect, useState } from 'react';
import { Clickable } from '../../../interfaces';
import { emptyFn } from '../../../utils';
import {
    SESSION_TIMEOUT_WARNING_COUNT_START,
    SESSION_TIMEOUT_WARNING_INTERVAL_MS,
} from '../../../config/sessionTimeout/sessionTimeout';
import { StandardModal } from '../standardModal/StandardModal';

/**
 * Destructure necessary imports
 */

interface TimeoutWarningModalProps {
    title: string;
    confirmButtonText: string;
    sessionTimeoutWarningText: string;
    onClose?: (e: Clickable) => void;
    onConfirm?: (e: Clickable) => void;
}

export const counter = SESSION_TIMEOUT_WARNING_COUNT_START;
let warningText = '';

export const TimeoutWarningModal = ({
    title,
    confirmButtonText,
    sessionTimeoutWarningText,
    onClose = emptyFn,
    onConfirm = emptyFn,
}: TimeoutWarningModalProps) => {
    if (warningText == '') {
        warningText = sessionTimeoutWarningText;
    }
    const [count, setCount] = useState<number>(
        SESSION_TIMEOUT_WARNING_COUNT_START
    );
    const timer = window.setInterval(() => {
        setCount(count - 1);
    }, SESSION_TIMEOUT_WARNING_INTERVAL_MS);
    useEffect(() => {
        if (count === 0) {
            window.clearInterval(timer);
        }
        warningText = sessionTimeoutWarningText.replace(
            SESSION_TIMEOUT_WARNING_COUNT_START.toString(),
            (count - 1).toString()
        );
        return () => window.clearInterval(timer);
    }, [count, timer]);
    return (
        <StandardModal
            title={title}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmButtonText={confirmButtonText}
            showConfirmButton={true}
        >
            <div
                className={'dialog__content text-center'}
                data-testid={'sessionTimeoutModalWarningContent'}
            >
                {warningText}
            </div>
        </StandardModal>
    );
};
