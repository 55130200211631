import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { startClaimReporterRolePage } from './start/claimReporterRole';
import { propertyIsContractorContactedPage } from './property/isContractorContacted';
import { endHasOtherInsurancePage } from './end/hasOtherInsurance';
import { endHasVATPage } from './end/hasVAT';
import { endHasContractWithOtherPartPage } from './end/hasContractWithOtherPart';
import { endHasDemandsPage } from './end/hasDemands';
import { legalHasClaimPage } from './legal/hasClaim';
import { legalHasActiveOppositionPage } from './legal/hasActiveOpposition';

/**
 * Config logic
 */

export const lpoCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    startClaimReporterRolePage(props),

    // Property
    propertyIsContractorContactedPage(props),

    // Legal Expenses
    legalHasClaimPage(props),
    legalHasActiveOppositionPage(props),

    // End
    endHasContractWithOtherPartPage(props),
    endHasDemandsPage(props),
    endHasOtherInsurancePage(props),
    endHasVATPage(props),
];
