import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    IDontKnowIcon,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectInvalidity } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { DONT_KNOW, NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const accidentInvalidityPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: YES, name: t(`lob.person.accident.invalidity.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lob.person.accident.invalidity.select.${NO}`), icon: <NoIcon /> },
        { id: DONT_KNOW, name: t(`lob.person.accident.invalidity.select.${DONT_KNOW}`), icon: <IDontKnowIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_ACCIDENT_INVALIDITY,
        stateKey: 'invalidity',
        i18n: {
            ns: 'lob.person.accident.invalidity',
        },
        selectorValue: selectInvalidity(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { invalidity: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
