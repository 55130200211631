import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    ClaimVehicleSummary,
    Clickable,
    DamagePoint,
    findClaimVehicle,
    findOwnerByDamagedItem,
    FormChangeable,
    generateGoogleMapsUrl,
    Grid,
    HiddenInputSubmit,
    IncidentSummary,
    InputValidationError,
    is,
    isYes,
    LoginKeys,
    MuiTextInput,
    NeedVehicleTowingKeys,
    NeedVehicleTowingModel,
    OwnerInformationListModel,
    PageLayout,
    PartyRelationKeys,
    pdfServiceActions,
    PDFServiceActionTypes,
    Printable,
    SingleCheckbox,
    SummaryItemText,
    SummaryText,
    SummaryVehicleItem,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import {
    selectAcceptedPrivacy,
    selectClaimDescription,
    selectLogin,
    selectMotorState,
} from '../../../sagas/selectors/motorSelectors';
import { motorActions, MotorState } from '../../../sagas/motor';
import { Controller, useForm } from 'react-hook-form';
import { claimDescriptionSchema } from '../../../validations/schemas/claimDescriptionSchema';
import 'components/summary/final/finalSummary.scss';
import { DisplayEndSummary } from './DisplayEndSummary';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { selectUpload } from '../../../sagas/selectors/uploadSelectors';
import { getClaimDescriptionHistory } from '../../../utils/summary/getClaimDescriptionHistory';
import { dtTimeParseAndFormat } from '../../../utils/date/dateUtils';
import { PrintableVehicleBodyDamages } from '../../vehicle/PrintableVehicleBodyDamages';
import { commonActions } from '../../../sagas/common';
import { DisplayIncidentEndSummary } from './DisplayIncidentEndSummary';
import { DisplayMotorStartSummary } from './DisplayMotorStartSummary';
import { DisplayMotorParkedSummary } from './DisplayParkedSummary';
import { trueFalseToYesNo } from '../../../utils/displayUtils';
import { DisplayMotorDrivingSummary } from './DisplayMotorDrivingSummary';
import { useHistoryListen } from '../../../hooks/useHistoryListen';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { HistoryListener } from '../../../types/HistoryListener';
import moment from 'moment';
import { SWE_DATE_TIME_FORMAT } from '../../../config/locale';
import {
    selectApiId,
    selectCustomCAN,
    selectJWT,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
    selectRequestId,
    selectSubmitted,
} from '../../../sagas/selectors/commonSelectors';
import { formatOwner } from 'utils/strings/formatStrings';
import { yupResolver } from '@hookform/resolvers/yup';
import useErrorLoader from '../../../hooks/useErrorSelector';
import { claimDescriptionUtil } from '../../../utils/claimDescriptionUtil';
import { logServiceActions } from '../../../sagas/services/logService';
import { useHistory } from 'react-router-dom';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    CLAIM_DESCRIPTION_LABEL,
    DAMAGE_DESCRIPTION_LABEL,
    HELP_TEXT,
    PAGE_NAME,
    SEND_BUTTON,
    SUB_TITLE,
    SUBMITTING_BUTTON,
    TITLE,
} = PhraseKeys;
const { CLAIM_DESCRIPTION } = FormFieldNames;
const { CLAIM_VEHICLE } = PartyRelationKeys;
const { YES, NO, ALREADY_TOWED } = NeedVehicleTowingKeys;
const { END_FINAL_SUMMARY, END_REPORT_COMPLETED } = MotorRoutePaths;
const reportDate = moment().format(SWE_DATE_TIME_FORMAT);
const { WITH_BANKID } = LoginKeys;

/**
 * Page view and page logic
 */
interface PrintableSummaryPageProps {
    editDescription: boolean;
    showSummary?: boolean;
    handleClickOption?: (e: Clickable, path: string) => void;
}

export const PrintableSummaryPage = ({
    editDescription,
    showSummary = true,
    handleClickOption,
}: PrintableSummaryPageProps) => {
    const dispatch = useDispatch();
    const uuid = useSelector(selectApiId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const lob = useSelector(selectLob);
    const requestId = useSelector(selectRequestId);
    const motorState = useSelector(selectMotorState);
    const uploadState = useSelector(selectUpload);
    const hasAcceptedPrivacy = useSelector(selectAcceptedPrivacy);
    const jwt = useSelector(selectJWT);
    const login = useSelector(selectLogin);
    const customCAN = useSelector(selectCustomCAN);
    const { error: pdfError } = useErrorLoader(PDFServiceActionTypes.FAILURE);
    const isLoggedIn: boolean = is(login, WITH_BANKID);
    const history = useHistory();
    const { listen, unlisten } = useHistoryListen();
    const pathNextRoute = END_REPORT_COMPLETED;
    const pathFinalSummary = END_FINAL_SUMMARY;
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.finalSummary');
    const temporaryClaimDescription = useSelector(selectClaimDescription);
    const claimDescriptionHistory = getClaimDescriptionHistory(motorState, t);
    const claimDescription =
        temporaryClaimDescription === ''
            ? `${claimDescriptionHistory && claimDescriptionHistory + '\n'}${
                  temporaryClaimDescription && temporaryClaimDescription + ' '
              }`
            : temporaryClaimDescription;
    const excludedClaimDescription = getClaimDescriptionHistory(motorState, t, true, claimDescription);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<Pick<MotorState, 'claimDescription'>>({
        resolver: yupResolver(claimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            claimDescription,
        },
    });
    const submitted = useSelector(selectSubmitted);

    const listener: HistoryListener = useCallback(
        (hLocation, hAction) => {
            if (hAction === 'POP' && hLocation.pathname === pathNextRoute && !hasAcceptedPrivacy) {
                history.push(pathFinalSummary);
            }
        },
        [history, hasAcceptedPrivacy, pathNextRoute, pathFinalSummary]
    );

    useEffect(() => {
        hasAcceptedPrivacy ? unlisten() : listen(listener);
    }, [hasAcceptedPrivacy, listen, unlisten, listener]);

    useEffect(() => {
        dispatch(motorActions.update({ claimDescription }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const model = {
        ...motorState,
    };

    const upload = {
        ...uploadState,
    };

    const handleBackButton = useGoBack();
    const {
        accidentLocation,
        claimCause,
        claimDate,
        isDriving,
        needVehicleTowing,
        ownerInformationList,
        vehicles,
        accidentSketch,
    } = model;
    const claimVehicle = findClaimVehicle(vehicles);
    const otherVehicles = vehicles.filter((v: VehicleModel) => v.partyRelation !== CLAIM_VEHICLE);

    const handleContinueButton = ({ claimDescription }: Pick<MotorState, 'claimDescription'>, e?: FormChangeable) => {
        e?.preventDefault();
        e?.stopPropagation();
        dispatch(
            logServiceActions.request({
                level: 'info',
                message: `User clicked Continue button to send claim. UUID=${uuid}`,
            })
        );
        dispatch(commonActions.update({ submitted: true }));
        const data = {
            uuid,
            requestId,
            lob,
            locale: { country, language },
            model: { ...model, isLoggedIn, claimDescription },
            jwt,
        };
        if (hasAcceptedPrivacy) {
            dispatcherWithPromise(dispatch, motorActions.update, { claimDescription })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatcherWithPromise(dispatch, pdfServiceActions.request, data))
                .then(() => dispatch(commonActions.submit()))
                .catch(() => dispatch(commonActions.update({ submitted: false })));
        }
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleNeedTowingChange = (e: NeedVehicleTowingModel) => {
        dispatcherWithPromise(dispatch, motorActions.update, { needVehicleTowing: e }).then(() =>
            dispatcherWithPromise(dispatch, commonActions.send)
        );
    };

    const handleDamages = (v: VehicleModel) => {
        const damages = v.damages.map((item) => t(`motor.end.damagesSummary.select.${item.body}`));
        const damagesList = damages.reduce((damagesCount: any, currentDamage) => {
            damagesCount[currentDamage] = (damagesCount[currentDamage] || 0) + 1;
            return damagesCount;
        }, {});

        return Object.entries(damagesList).map(([key, value]) => ({ key, value }));
    };

    const renderDamages = (claimVehicle: VehicleModel) => {
        const damages: DamagePoint[] = claimVehicle.damages.map((da) => {
            const t = da.svgLocation;
            return { x: t.x, y: t.y, vehicleBodyPart: da.body, svgId: t.svgId };
        });
        return (
            <PrintableVehicleBodyDamages
                addDamagePoint={() => null}
                removeDamagePoint={() => null}
                selectedDamagePoints={damages}
                tWithNs={() => null}
                vehicleType={claimVehicle.vehicleType}
            />
        );
    };

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(motorActions.update({ acceptedPrivacy: e.target.checked }));
    };

    const renderFooterHelpComponent = `<span class="summary-help-text">${tWithNS.t('help.marked')}</span>`;
    return (
        <>
            <PageLayout
                backBtnAriaLabel={t(BACK_BUTTON)}
                backBtnText={t(BACK_BUTTON)}
                continueBtnAriaLabel={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
                continueBtnText={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
                disableBackButton={submitted}
                disableContinueButton={!hasAcceptedPrivacy || submitted}
                domainTitle={t(PAGE_NAME)}
                footerText={tWithNS.t(HELP_TEXT, { component: renderFooterHelpComponent })}
                handleContinueButton={handleSubmit(handleContinueButton)}
                headerSubTitle={tWithNS.t(SUB_TITLE)}
                headerTitle={tWithNS.t(TITLE)}
                pageClassName={!showSummary ? 'hide-content' : ''}
                {...{ handleBackButton }}
            >
                <Printable
                    className={'hide-all-content'}
                    title={tWithNS.t('print.titleWithReportDate', { date: reportDate })}
                >
                    <Grid>
                        {/* Incident Summary */}
                        <IncidentSummary
                            claimCause={claimCause ? tWithNS.t(`claimCause.select.${claimCause}`) : ''}
                            claimDate={claimDate ? dtTimeParseAndFormat(claimDate) : ''}
                            className={'col-12 divider'}
                            incidentAt={tWithNS.t('text.incidentAt')}
                            incidentBy={tWithNS.t('text.incidentBy')}
                            incidentInvolving={tWithNS.t('text.incidentInvolving')}
                            incidentOn={tWithNS.t('text.incidentOn')}
                            location={
                                accidentLocation && accidentLocation.note
                                    ? accidentLocation.note
                                    : `${accidentLocation.latitude}, ${accidentLocation.longitude}`
                            }
                            locationUrl={generateGoogleMapsUrl(accidentLocation)}
                            make={claimVehicle?.make}
                            registrationNumber={claimVehicle?.registrationNumber}
                            type={claimVehicle?.type}
                            {...{ customCAN }}
                        >
                            {(otherVehicles.length > 0 || ownerInformationList.length > 0) && (
                                <>
                                    {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                                        const owner: OwnerInformationListModel | undefined = ownerInformationList.find(
                                            (o: OwnerInformationListModel) => o.damagedItem === vehicle.internalId
                                        );
                                        return (
                                            <p className={'incident-text'} key={idx}>
                                                {`${vehicle.registrationNumber ? vehicle.registrationNumber : ''}, ${
                                                    vehicle.model ? vehicle.model : ''
                                                } ${vehicle.make ? vehicle.make : ''} ${
                                                    vehicle.type ? vehicle.type : ''
                                                }`}{' '}
                                                {owner?.firstName || owner?.lastName || owner?.name
                                                    ? tWithNS.t('text.ownedBy')
                                                    : ''}{' '}
                                                {owner?.firstName ? owner?.firstName : owner?.name ? owner?.name : ''}{' '}
                                                {owner?.lastName ? owner?.lastName : ''}
                                            </p>
                                        );
                                    })}
                                    {ownerInformationList.map((owner: OwnerInformationListModel, idx: number) => {
                                        const isVehicle = vehicles.find(
                                            (v: VehicleModel) => v.internalId === owner.damagedItem
                                        );
                                        return (
                                            <p className={'incident-text'} key={idx}>
                                                {formatOwner(isVehicle, owner, t)}
                                            </p>
                                        );
                                    })}
                                </>
                            )}
                        </IncidentSummary>

                        <DisplayIncidentEndSummary
                            handleClick={handleClickOption}
                            printSummary={true}
                            tWithNS={tWithNS.t}
                            {...{ model, t, upload }}
                        />

                        {/* Registered Vehicles and Damages */}
                        <div className={'col-12 divider'}>
                            {claimVehicle && (
                                <ClaimVehicleSummary
                                    claimVehicle={claimVehicle}
                                    claimVehicleDamages={renderDamages(claimVehicle)}
                                    damageDescription={claimVehicle.otherDamageComment}
                                    damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                    damages={handleDamages(claimVehicle)}
                                    damagesTextKey={tWithNS.t('damages.heading.title')}
                                    handleChange={handleNeedTowingChange}
                                    needTowingTextKey={tWithNS.t('needVehicleTowing.heading.title')}
                                    needVehicleTowing={needVehicleTowing}
                                    needVehicleTowingSummary={t(
                                        `motor.end.needVehicleTowing.select.${needVehicleTowing}`
                                    )}
                                    printSummary={true}
                                    textKey={tWithNS.t('claimVehicle.heading.title')}
                                    {...{ customCAN }}
                                />
                            )}
                            <Grid>
                                {otherVehicles && otherVehicles.length > 0 && (
                                    <div className={'col-12'}>
                                        <SummaryText text={tWithNS.t('otherVehicle.heading.title')} />
                                    </div>
                                )}
                                {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                                    const owner = findOwnerByDamagedItem(ownerInformationList, vehicle.internalId);
                                    return (
                                        <SummaryVehicleItem
                                            damageDescription={vehicle.otherDamageComment}
                                            damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                            damages={handleDamages(vehicle)}
                                            damagesTextKey={tWithNS.t('damages.heading.title')}
                                            key={idx}
                                            ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                                                owner?.lastName ? ` ${owner.lastName}` : ''
                                            }`}
                                            printSummary={true}
                                            textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                                            vehicle={vehicle}
                                            vehicleDamages={renderDamages(vehicle)}
                                            {...{ customCAN }}
                                        />
                                    );
                                })}
                            </Grid>
                        </div>

                        {/* Claim Description */}
                        <SummaryItemText
                            className={`col-12 divider page-break`}
                            label={t(CLAIM_DESCRIPTION_LABEL)}
                            text={claimDescription}
                        />

                        {/* Excluded Claim Descriptions */}
                        {excludedClaimDescription && (
                            <SummaryItemText
                                className={'col-12 divider'}
                                label={tWithNS.t('excludedClaimDescription.heading.title')}
                                text={excludedClaimDescription}
                            />
                        )}

                        {/* Accident Description / Sketch */}
                        {accidentSketch?.blob && (
                            <>
                                <div className={`col-12 divider`}>
                                    <img alt={claimDescription} src={accidentSketch?.blob} />
                                </div>
                            </>
                        )}

                        {/* Start Summary */}
                        <DisplayMotorStartSummary tWithNS={tWithNS.t} {...{ model, t, upload }} />

                        {/* Parked Summary */}
                        {isYes(isDriving) ? (
                            <DisplayMotorDrivingSummary tWithNS={tWithNS.t} {...{ model, t, upload }} />
                        ) : (
                            <DisplayMotorParkedSummary tWithNS={tWithNS.t} {...{ model, t, upload }} />
                        )}

                        {/* End Summary */}
                        <DisplayEndSummary tWithNS={tWithNS.t} {...{ model, t, upload }} />

                        {/* Privacy */}
                        <div className={'col-12 spacing'}>
                            <SummaryText text={tWithNS.t('privacy.heading.title')} />
                        </div>
                        <div className={'col-12 divider'}>{t(trueFalseToYesNo(hasAcceptedPrivacy))}</div>
                    </Grid>
                </Printable>
                <Printable title={tWithNS.t('print.titleWithReportDate', { date: reportDate })}>
                    <Grid>
                        {/* Incident Summary */}
                        <IncidentSummary
                            className={'col-12 divider'}
                            claimCause={claimCause ? tWithNS.t(`claimCause.select.${claimCause}`) : ''}
                            claimDate={claimDate ? dtTimeParseAndFormat(claimDate) : ''}
                            incidentAt={tWithNS.t('text.incidentAt')}
                            incidentBy={tWithNS.t('text.incidentBy')}
                            incidentInvolving={tWithNS.t('text.incidentInvolving')}
                            incidentOn={tWithNS.t('text.incidentOn')}
                            location={
                                accidentLocation && accidentLocation.note
                                    ? accidentLocation.note
                                    : `${accidentLocation.latitude}, ${accidentLocation.longitude}`
                            }
                            locationUrl={generateGoogleMapsUrl(accidentLocation)}
                            make={claimVehicle?.make}
                            registrationNumber={claimVehicle?.registrationNumber}
                            type={claimVehicle?.type}
                            {...{ customCAN }}
                        >
                            {(otherVehicles.length > 0 || ownerInformationList.length > 0) && (
                                <>
                                    {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                                        const owner: OwnerInformationListModel | undefined = ownerInformationList.find(
                                            (o: OwnerInformationListModel) => o.damagedItem === vehicle.internalId
                                        );
                                        return (
                                            <p className={'incident-text'} key={idx}>
                                                {`${vehicle.registrationNumber ? vehicle.registrationNumber : ''}, ${
                                                    vehicle.model ? vehicle.model : ''
                                                } ${vehicle.make ? vehicle.make : ''} ${
                                                    vehicle.type ? vehicle.type : ''
                                                }`}{' '}
                                                {owner?.firstName || owner?.lastName || owner?.name
                                                    ? tWithNS.t('text.ownedBy')
                                                    : ''}{' '}
                                                {owner?.firstName ? owner?.firstName : owner?.name ? owner?.name : ''}{' '}
                                                {owner?.lastName ? owner?.lastName : ''}
                                            </p>
                                        );
                                    })}
                                    {ownerInformationList.map((owner: OwnerInformationListModel, idx: number) => {
                                        const isVehicle = vehicles.find(
                                            (v: VehicleModel) => v.internalId === owner.damagedItem
                                        );
                                        return (
                                            <p className={'incident-text'} key={idx}>
                                                {formatOwner(isVehicle, owner, t)}
                                            </p>
                                        );
                                    })}
                                </>
                            )}
                        </IncidentSummary>

                        <DisplayIncidentEndSummary
                            handleClick={handleClickOption}
                            printSummary={!showSummary}
                            tWithNS={tWithNS.t}
                            {...{ model, t, upload }}
                        />

                        {/* Registered Vehicles and Damages */}
                        <div className={'col-12 divider'}>
                            {claimVehicle && (
                                <ClaimVehicleSummary
                                    claimVehicle={claimVehicle}
                                    claimVehicleDamages={renderDamages(claimVehicle)}
                                    damageDescription={claimVehicle.otherDamageComment}
                                    damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                    damages={handleDamages(claimVehicle)}
                                    damagesTextKey={tWithNS.t('damages.heading.title')}
                                    handleChange={handleNeedTowingChange}
                                    needTowingTextKey={tWithNS.t('needVehicleTowing.heading.title')}
                                    needVehicleTowing={needVehicleTowing}
                                    needVehicleTowingAlreadyTowed={t(
                                        `motor.end.needVehicleTowing.select.${ALREADY_TOWED}`
                                    )}
                                    needVehicleTowingNo={t(`motor.end.needVehicleTowing.select.${NO}`)}
                                    needVehicleTowingSummary={t(
                                        `motor.end.needVehicleTowing.select.${needVehicleTowing}`
                                    )}
                                    needVehicleTowingYes={t(`motor.end.needVehicleTowing.select.${YES}`)}
                                    printSummary={!showSummary}
                                    textKey={tWithNS.t('claimVehicle.heading.title')}
                                    {...{ customCAN }}
                                />
                            )}
                            <Grid>
                                {otherVehicles && otherVehicles.length > 0 && (
                                    <div className={'col-12'}>
                                        <SummaryText text={tWithNS.t('otherVehicle.heading.title')} />
                                    </div>
                                )}
                                {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                                    const owner = findOwnerByDamagedItem(ownerInformationList, vehicle.internalId);
                                    return (
                                        <SummaryVehicleItem
                                            className={`${showSummary ? 'col-6' : 'col-12'} no-padding padding-top`}
                                            damageDescription={vehicle.otherDamageComment}
                                            damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                            damages={handleDamages(vehicle)}
                                            damagesTextKey={tWithNS.t('damages.heading.title')}
                                            key={idx}
                                            ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                                                owner?.lastName ? ` ${owner.lastName}` : ''
                                            }`}
                                            printSummary={!showSummary}
                                            textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                                            vehicle={vehicle}
                                            vehicleDamages={renderDamages(vehicle)}
                                            {...{ customCAN }}
                                        />
                                    );
                                })}
                            </Grid>
                        </div>

                        {/* Claim Description */}
                        {editDescription ? (
                            <div className={'col-12 divider'}>
                                <form onSubmit={handleSubmit(handleContinueButton)}>
                                    <HiddenInputSubmit />
                                    <Grid className={'align-center'}>
                                        <Controller
                                            control={control}
                                            name={CLAIM_DESCRIPTION}
                                            render={({ field: { ref, ...field } }) => (
                                                <MuiTextInput
                                                    {...field}
                                                    error={!!errors.claimDescription}
                                                    errorMessage={errors.claimDescription?.message}
                                                    id={CLAIM_DESCRIPTION}
                                                    inputFieldWrapper={'col-12'}
                                                    label={t(CLAIM_DESCRIPTION_LABEL)}
                                                    multiline={true}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                                    reference={ref}
                                                    rows={10}
                                                    {...{ customCAN }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </form>
                            </div>
                        ) : (
                            <SummaryItemText
                                className={`col-12 divider text-align-left`}
                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                text={claimDescription}
                            />
                        )}

                        {/* Accident Description / Sketch */}
                        {accidentSketch?.blob && (
                            <>
                                <div className={`col-12 divider`}>
                                    <img alt={claimDescription} src={accidentSketch?.blob} />
                                </div>
                            </>
                        )}

                        {/* Privacy */}
                        {showSummary ? (
                            <>
                                <SingleCheckbox
                                    checked={hasAcceptedPrivacy}
                                    handleChange={handleCheckbox}
                                    name={'acceptedPrivacy'}
                                    wrapperClass={'col-12 divider'}
                                    {...{ customCAN }}
                                >
                                    {tWithNS.t('privacy.text')}
                                </SingleCheckbox>
                            </>
                        ) : (
                            <>
                                <div className={'col-8 spacing justify-content-center'}>
                                    <SummaryText text={tWithNS.t('privacy.heading.title')} />
                                </div>
                                <div className={'col-4 spacing justify-content-center'}>
                                    {t(trueFalseToYesNo(hasAcceptedPrivacy))}
                                </div>
                            </>
                        )}

                        {pdfError ? (
                            <div className={'col-12'}>
                                <InputValidationError
                                    fieldName={'PdfError'}
                                    error={t('generatePDF.' + pdfError.status + 'Error')}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Printable>
            </PageLayout>
        </>
    );
};
