import React, { Dispatch, SetStateAction } from 'react';
import { useI18n } from 'hooks/useI18n';
import { I18NOptions, SchoolInformationModel, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplaySchoolInformationProps {
    model: Partial<SchoolInformationModel>;
    i18n?: I18NOptions;
    error: Dispatch<SetStateAction<boolean>>;
}

export const DisplaySchoolInformation = ({ model, i18n, error }: DisplaySchoolInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));

    const renderName = () => {
        if (model.name) {
            return <SummaryItemText label={tWithPrefix(`text.schoolName`)} text={`${model.name}`} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.schoolName')}</p>;
    };

    const renderMunicipality = () => {
        if (model.municipality) {
            return <SummaryItemText label={tWithPrefix(`text.schoolMunicipality`)} text={`${model.municipality}`} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.municipality')}</p>;
    };

    const renderStreet = () => {
        if (model.street) {
            return <SummaryItemText label={tWithPrefix(`text.schoolStreet`)} text={`${model.street}`} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.street')}</p>;
    };

    const renderZip = () => {
        if (model.zip) {
            return <SummaryItemText label={tWithPrefix(`text.schoolZip`)} text={`${model.zip}`} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.zip')}</p>;
    };

    const renderCity = () => {
        if (model.city) {
            return <SummaryItemText label={tWithPrefix(`text.schoolCity`)} text={`${model.city}`} />;
        }
        error(true);
        return <p style={{ color: 'red' }}>{t('lob.person.finalSummary.error.city')}</p>;
    };

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 spacing header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix(`text.schoolInformation`)}
                </p>
            </div>
            <div className={'col-4 divider no-border text-align-left'}>{renderName()}</div>
            <div className={'col-8 divider no-border text-align-left'}>{renderMunicipality()}</div>
            <div className={'col-4 divider text-align-left'}>{renderStreet()}</div>
            <div className={'col-4 divider text-align-left'}>{renderZip()}</div>
            <div className={'col-4 divider text-align-left'}>{renderCity()}</div>
        </>
    );
};
