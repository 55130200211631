import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../../config/yup';
import { PIAPolicyNumberValidator } from './PIAPolicyNumberValidator';
import { isEmpty } from '../../../utils';

export function PIAPolicyNumberYupMethod(
    this: StringSchema,
    required: boolean,
    message: string = yupCustomLocale.string.policyNumber
) {
    return this.test(
        'PIAPolicyNumberYupMethod',
        message,
        function (
            this: TestContext,
            value: string
        ): boolean | ValidationError | Promise<boolean | ValidationError> {
            const skipValidation = !required && isEmpty(value);
            return skipValidation
                ? true
                : PIAPolicyNumberValidator(value).isValid();
        }
    );
}
