import { TeethKeys } from '../interfaces';

const { WHOLE_MOUTH } = TeethKeys;

export const updateSelectableParts = <T extends TeethKeys>(
    key: T,
    array: T[],
    selected: boolean
): T[] => {
    if (selected) {
        if (key === WHOLE_MOUTH) {
            return [key];
        } else {
            return [...array, key];
        }
    } else {
        return array.filter((part) => part !== key);
    }
};
