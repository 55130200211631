import { LobModel } from '../interfaces';

export const getLobFromUrl = (): LobModel => {
    const url = window.location.pathname.split('/');

    if (url[3] === '3') {
        return { lob: 3 };
    }

    if (url[3] === '4') {
        return { lob: 4 };
    }

    if (url[3] === '5') {
        return { lob: 5 };
    }

    if (url[3] === '8') {
        return { lob: 8 };
    }

    if (url[3] === '23') {
        return { lob: 23 };
    }

    return { lob: 24 };
};
