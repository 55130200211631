import { isProd } from './env';
import { ToggleKeys } from 'models/common/Toggle';
import { toggles } from 'config/toggles';

export const toggle = (toggle: ToggleKeys, checkEnv = true) => {
    if (checkEnv && !isProd()) {
        return true;
    }

    return toggles[toggle] === true;
};
