import { Nullable } from '../../../../../interfaces/types/Nullable.types';

export enum InsuredByProtectorKeys {
    YES_RECENTLY_INSURED_BY_PROTECTOR = 'YES_RECENTLY_INSURED_BY_PROTECTOR',
    YES = 'YES',
    NO = 'NO',
}

export interface InsuredByProtectorModel {
    insuredByProtector: InsuredByProtectorTypeModel;
}

export type InsuredByProtectorTypeModel = Nullable<InsuredByProtectorKeys>;
