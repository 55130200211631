import AttachmentModel from '../../../models/Attachment';
import { produce } from 'immer';
import { actionWithPromise, FileModel, Rejectable, Resolvable, YesNoKeys } from '@protectorinsurance/ds-can';

/**
 * Action Types
 */
export enum ReportActionTypes {
    REQUEST = '@app/upload/report/REQUEST',
    SUCCESS = '@app/upload/report/SUCCESS',
    ADD = '@app/upload/report/ADD',
    UPDATE = '@app/upload/report/UPDATE',
    FAILURE = '@app/upload/report/FAILURE',
    REMOVE = '@app/upload/report/REMOVE',
}

/**
 * Action Definitions
 */
export interface ReportAction {
    type: ReportActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Init State
 */
export const reportInitState: AttachmentModel = {
    files: [],
    hasAttachment: null,
};

/**
 * Default Reducer
 */
export default function (state = reportInitState, { type, data }: ReportAction) {
    return produce(state, (draft: AttachmentModel) => {
        switch (type) {
            case ReportActionTypes.ADD:
                draft.files.push(data);
                draft.hasAttachment = YesNoKeys.YES;
                break;
            case ReportActionTypes.UPDATE:
                const { id, status, errors, externalId } = data;
                const index = state.files.findIndex((x: FileModel) => x.id === id);
                draft.files[index].status = status;
                draft.files[index].errors = errors;
                if (externalId) {
                    draft.files[index].externalId = externalId;
                }
                break;
            case ReportActionTypes.REMOVE:
                draft.files = state.files.filter((x) => x.id !== data.id);
                break;
            default:
                break;
        }
    });
}

/**
 * Redux Actions
 */
export const reportActions = {
    add: actionWithPromise<ReportActionTypes, FileModel>(ReportActionTypes.ADD),
    failure: actionWithPromise(ReportActionTypes.FAILURE),
    success: actionWithPromise(ReportActionTypes.SUCCESS),
    remove: actionWithPromise(ReportActionTypes.REMOVE),
    request: actionWithPromise(ReportActionTypes.REQUEST),
    update: actionWithPromise(ReportActionTypes.UPDATE),
};
