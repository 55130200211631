import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';
import { FormFieldErrors } from '../../config/formFieldNames';
import { PHONE_REGEX } from '../../utils/validation/stringFormats';
import { addressSchema } from '../fieldSchemas/addressSchema';

/**
 * Destructure necessary imports
 */
const { EMAIL_LABEL, PHONE_LABEL } = PhraseKeys;
const { EMAIL_ERROR_MESSAGE, FIELD_ERROR } = FormFieldErrors;

/**
 * Validation logic
 */
export const claimantInformationChildSchema = (t: TFunction, missingSSN: boolean = false) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...(!missingSSN && nationalIdentitySchema(t)),
        email: Yup.string()
            .label(t(EMAIL_LABEL))
            .email(t(EMAIL_ERROR_MESSAGE))
            .secureEndingEmail(t(EMAIL_ERROR_MESSAGE))
            .onlyAscii(),
        phone: Yup.string()
            .label(t(PHONE_LABEL))
            .matches(PHONE_REGEX, {
                message: t(FIELD_ERROR),
                excludeEmptyString: true,
            }),
        ...addressSchema(t),
    });
};
