import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { FormChangeable, Grid, HiddenInputSubmit, MuiTextInput, PageLayout } from '@protectorinsurance/ds-can';
import { selectBankAccountInformation } from '../../../sagas/selectors/personSelectors';
import { personActions } from '../../../sagas/person';
import { BankAccountInformationModel } from '../../../models/person/BankAccountInformation';
import { bankAccountInformationSchema } from '../../../validations/schemas/bankAccountInformationSchema';
import { findBankByClearingNumber } from '../../../utils/bank/findBankByClearingNumber';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { ACCOUNT_NUMBER, BANK_NAME, CLEARING_NUMBER } = FormFieldNames;
const {
    ACCOUNT_NUMBER_LABEL,
    ACCOUNT_NUMBER_PLACEHOLDER,
    BACK_BUTTON,
    BANK_NAME_LABEL,
    BANK_NAME_PLACEHOLDER,
    CLEARING_NUMBER_LABEL,
    CLEARING_NUMBER_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const AccidentBankAccountInformationPage = () => {
    const dispatch = useDispatch();
    const bankAccountInformation = useSelector(selectBankAccountInformation);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.accident.bankAccountInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
        watch,
    } = useForm<BankAccountInformationModel>({
        resolver: yupResolver(bankAccountInformationSchema(t, false)),
        defaultValues: {
            ...bankAccountInformation,
        },
    });
    const clearingNumberValue = watch(CLEARING_NUMBER);
    const accountNumberValue = watch(ACCOUNT_NUMBER);
    const clearingNumberHasError = errors.clearingNumber;

    useEffect(() => {
        if (clearingNumberValue && !clearingNumberHasError) {
            setValue('bankName', findBankByClearingNumber(clearingNumberValue)?.name || '', { shouldValidate: true });
            if (accountNumberValue) {
                trigger(ACCOUNT_NUMBER);
            }
        }
    }, [setValue, trigger, clearingNumberValue, clearingNumberHasError, accountNumberValue]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: BankAccountInformationModel) => {
        dispatcherWithPromise(dispatch, personActions.update, { bankAccountInformation: values })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={CLEARING_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.clearingNumber}
                                errorMessage={errors.clearingNumber?.message}
                                id={CLEARING_NUMBER}
                                inputFieldWrapper={'col-4'}
                                label={t(CLEARING_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CLEARING_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={ACCOUNT_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.accountNumber}
                                errorMessage={errors.accountNumber?.message}
                                id={ACCOUNT_NUMBER}
                                inputFieldWrapper={'col-4'}
                                label={t(ACCOUNT_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ACCOUNT_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    {clearingNumberValue && !clearingNumberHasError && (
                        <Controller
                            control={control}
                            name={BANK_NAME}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    disabled={true}
                                    error={!!errors.bankName}
                                    errorMessage={errors.bankName?.message}
                                    id={BANK_NAME}
                                    inputFieldWrapper={'col-4'}
                                    label={t(BANK_NAME_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(BANK_NAME_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    )}
                </Grid>
            </form>
        </PageLayout>
    );
};
