import React from 'react';
import { customCANProps, Nullable, VehicleModel, VehicleObject } from '@protectorinsurance/ds-can';

/**
 * Component view and logic
 */
export const DisplayVehicleInformation = ({
    buttonAction,
    buttonText,
    customCAN,
    vehicle,
}: {
    buttonAction: () => void;
    buttonText: string;
    customCAN?: customCANProps;
    vehicle: Nullable<VehicleModel>;
}) => {
    return vehicle && <VehicleObject className={'col-12'} {...vehicle} {...{ buttonAction, buttonText, customCAN }} />;
};
