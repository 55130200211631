import './InfoWindowContent.scss';

interface InfoWindowContentArgs {
    position: google.maps.LatLng | null | undefined;
    label: string;
    latText: string;
    lngText: string;
}

export const infoWindowContent = ({ position, label, latText, lngText }: InfoWindowContentArgs) => {
    return position
        ? `<div class="infowindow">
<label class="infowindow-label" for="markerLocation">${label}</label>
<p class="infowindow-text" name="markerLocation">
${latText}: ${position.lat()}
${lngText}: ${position.lng()}
</p>
</div>`
        : '';
};
