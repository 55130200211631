import { css } from '@emotion/react';
import { secondary, white } from '../../../variables';
import { customCANProps } from '../../../../config/customCANCompanies';

export const muiTextInputStyle = (company?: customCANProps) =>
    css({
        '& label.Mui-focused': {
            color: company ? company.color : secondary,
        },

        '& .MuiOutlinedInput-root': {
            marginBottom: '3.5rem',

            '& .MuiInputAdornment-root': {
                width: 'unset !important',
                alignItems: 'start',

                '& .MuiCircularProgress-root': {
                    color: company ? company.color : secondary,
                },
            },

            '&.Mui-disabled:hover': {
                '& fieldset': {
                    borderColor: 'rgba(0,0,0,0.26)',
                },
            },

            '&:hover': {
                '& fieldset': {
                    borderColor: company ? company.color : secondary,
                },
            },

            '&.Mui-focused': {
                '& legend': {
                    backgroundColor: white,
                },

                '& fieldset': {
                    borderColor: company ? company.color : secondary,
                },
            },

            '&.Mui-error': {
                marginBottom: 0,
            },
        },

        '& .MuiFormHelperText-root': {
            minHeight: '3.5rem',
        },

        '& .MuiTypography-root': {
            fontSize: '10px',
        },
    });
