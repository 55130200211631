import React, { useRef } from 'react';
import './CheckboxInput.scss';
import { Focusable, Formable } from '@protectorinsurance/ds-can';
import checkboxIcon from '/assets/images/icons/checkbox-check-white.svg';

interface CheckboxInputProps {
    id: string;
    name?: string;
    value?: string;
    label?: string;
    labelClassName?: string;
    checkIconClassName?: string;
    reference: any;
    handleChange: (e: Formable) => void;
    handleBlur: (e: Focusable) => void;
    isChecked: boolean;
    isDisabled?: boolean;
    prefix?: string;
}

export const CheckboxInput = ({
    id,
    name,
    value,
    label,
    labelClassName = '',
    checkIconClassName = '',
    reference,
    handleChange,
    handleBlur,
    isChecked,
    isDisabled,
    prefix = 'checkbox-',
}: CheckboxInputProps) => {
    const innerRef = useRef<any>(reference);
    const _id = `${prefix}${id}`;
    const _labelClassName = `${labelClassName} ${checkIconClassName ? checkIconClassName : ''}`;
    const _label = label && !checkIconClassName ? label : '';

    return (
        <div data-testid={`${_id}-input`} className={'checkbox-input'}>
            <input
                id={_id}
                data-testid={_id}
                type={'checkbox'}
                name={name ? name : id}
                value={value}
                checked={isChecked}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={innerRef}
                disabled={isDisabled}
                aria-checked={isChecked}
            />
            <label
                htmlFor={_id}
                className={_labelClassName}
                style={{
                    backgroundImage: `url(checkboxIcon)`,
                }}
            >
                {_label}
            </label>
            {label && checkIconClassName && (
                <label htmlFor={_id} className={'sr-only'}>
                    {label}
                </label>
            )}
        </div>
    );
};
