/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { DragEvent, ReactNode, useRef } from 'react';
import { generateId } from '../../../utils';
import { Changable } from '../../../interfaces';
import { fileDropzoneCss } from './FileDropzone.style';

export interface FileDropzoneProps {
    onFilesAdded: (files: FileList) => void;
    children?: ReactNode;
    disabled?: boolean;
    multiple?: boolean;
    text?: string;
}

export const FileDropzone = ({
    onFilesAdded,
    disabled = false,
    multiple = false,
    children,
    text = 'Upload File',
}: FileDropzoneProps) => {
    const input = useRef<HTMLInputElement>(null);
    const id = generateId();

    const browse = () => input.current && input.current.click();
    const onChange = (e: Changable) => {
        e.target.files && onFilesAdded(e.target.files);

        // Reset value
        e.target.value = '';
    };

    // Handle drag and drop
    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };
    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.dataTransfer &&
            e.dataTransfer.files &&
            onFilesAdded(e.dataTransfer.files);
    };

    return (
        <div
            css={css(fileDropzoneCss)}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onClick={browse}
            style={{ cursor: disabled ? 'default' : 'pointer' }}
        >
            <label htmlFor={id}>{text}</label>
            <input
                id={id}
                ref={input}
                style={{ display: 'none' }}
                type="file"
                data-testid="uploader"
                multiple={multiple}
                onChange={onChange}
            />
            {children}
            {!children && <span>{text}</span>}
        </div>
    );
};
