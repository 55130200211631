interface EnvProps {
    env?: string;
    testURL?: string;
    prodURL?: string;
}

/**
 * Environment checker
 *
 * Check different environment of the applicaiton.
 * Dont use it inside Design System since process.env.NODE_ENV will allways return "production"
 */
export function env(options?: EnvProps) {
    const env = options?.env || process.env.NODE_ENV;
    const testURL = options?.testURL || 'claims-at-net-test.';
    const prodURL = options?.prodURL || 'claims-at-net.';

    return {
        getEnv: () => env,
        isDev: (): boolean => env === 'development',
        isTest: (): boolean => indexOfHref(testURL) > 0,
        isTestEnv: (): boolean => env === 'test',
        isProd: (): boolean => indexOfHref(prodURL) > 0,
        isCypress: (): boolean => (window as any).Cypress,
    };
}

const indexOfHref = (value: string) => window.location.href.indexOf(value);
