import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    AddIcon,
    ButtonClickable,
    CircleIconButton,
    Clickable,
    findClaimVehicle,
    findCounterpartyVehicle,
    findSelectedVehicle,
    Grid,
    initVehicleModel,
    InputValidationError,
    is,
    PageLayout,
    PartyRelationKeys,
    SelectedVehicleTypeModel,
    StandardModal,
    VehicleListItem,
    VehicleModel,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectVehicles } from '../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DONT_KNOW,
    HELP_TEXT,
    PAGE_NAME,
    SELECT_NO,
    SELECT_YES,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES, END_BODY, END_VEHICLE_REGISTRATION_NUMBER } = MotorRoutePaths;
const { vehicleList } = TEST_ID_PREFIX;
const { OTHER } = PartyRelationKeys;
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const DamageOverviewPage = () => {
    const dispatch = useDispatch();
    const vehicles = useSelector(selectVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const claimVehicle = findClaimVehicle(vehicles);
    const counterpartyVehicle = findCounterpartyVehicle(vehicles);
    const [validation, setValidation] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const otherVehicles = vehicles.filter((v: VehicleModel) => v.partyRelation === OTHER);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.damageOverview');

    const checkValidation = (v: VehicleModel[]) => {
        let noValidation = true;

        v.map(async (vehicle) => {
            if (vehicle.vehicleHasDamages == null) {
                noValidation = false;
            }
        });
        return noValidation;
    };

    const handleBackButton = useGoBack();

    const handleContinueButton = async (e: Clickable) => {
        e.preventDefault();

        setValidation(checkValidation(vehicles));

        if (checkValidation(vehicles)) {
            if (claimVehicle?.vehicleHasDamages === NO) {
                setIsModalOpen(true);
            } else {
                let nextAction = wizardActions.goToNext();
                if (claimVehicle?.damages.length === 0) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES);
                }
                dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
            }
        }
    };

    const handleAddButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(wizardActions.goTo(END_VEHICLE_REGISTRATION_NUMBER));
    };

    const handleClick = (id: SelectedVehicleTypeModel, e?: ButtonClickable) => {
        e?.preventDefault();

        const hasDamages = e?.currentTarget.value as YesNoKeys;
        const currentVehicle = findSelectedVehicle(vehicles, id);
        const updatedVehicleList = vehicles.map((vehicle) => {
            if (is(vehicle.internalId, id)) {
                if (hasDamages !== YES) {
                    return {
                        ...initVehicleModel,
                        ...currentVehicle,
                        damages: [],
                        otherDamageComment: null,
                        vehicleHasDamages: hasDamages,
                    };
                } else {
                    return {
                        ...initVehicleModel,
                        ...currentVehicle,
                        vehicleHasDamages: hasDamages,
                    };
                }
            } else {
                return vehicle;
            }
        });

        dispatcherWithPromise(dispatch, motorActions.update, {
            selectedVehicleId: id,
            vehicles: updatedVehicleList,
        }).then(() => {
            hasDamages === YES && dispatch(wizardActions.goTo(END_BODY));
        });

        if (checkValidation(updatedVehicleList)) {
            setValidation(checkValidation(updatedVehicleList));
        }
    };

    const handleDelete = (id: SelectedVehicleTypeModel, e?: Clickable) => {
        e?.preventDefault();
        const vehicleArray = vehicles.filter((vehicle) => vehicle.internalId !== id);
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehicleArray });
    };

    const handleDamages = (v: VehicleModel) => {
        const damages = v.damages.map((item) => tWithNS.t(`select.${item.body}`));
        const damagesList = damages.reduce((damagesCount: any, currentDamage) => {
            damagesCount[currentDamage] = (damagesCount[currentDamage] || 0) + 1;
            return damagesCount;
        }, {});

        return Object.entries(damagesList).map(([key, value]) => ({ key, value }));
    };

    const handleModalClose = () => {
        setIsModalOpen(() => false);
    };

    const handleModalConfirm = () => {
        setIsModalOpen(() => false);

        setValidation(checkValidation(vehicles));

        if (checkValidation(vehicles)) {
            let nextAction = wizardActions.goToNext();
            if (claimVehicle?.damages.length === 0) {
                nextAction = wizardActions.goTo(DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES);
            }
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            {claimVehicle && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.claimVehicle')}</h2>
                    <Grid>
                        <VehicleListItem
                            buttonNoTitle={t(SELECT_NO)}
                            buttonUnknownTitle={t(DONT_KNOW)}
                            buttonYesTitle={t(SELECT_YES)}
                            damages={handleDamages(claimVehicle)}
                            dataTestId={`${vehicleList}claimVehicle`}
                            handleClick={(e) => handleClick(claimVehicle?.internalId, e)}
                            hasDamages={true}
                            make={claimVehicle?.make}
                            model={claimVehicle?.model}
                            registrationNumber={claimVehicle?.registrationNumber}
                            type={claimVehicle?.type}
                            vehicleHasDamages={claimVehicle?.vehicleHasDamages}
                            vehicleType={claimVehicle?.vehicleType}
                            {...{ customCAN }}
                        />
                    </Grid>
                </>
            )}

            {counterpartyVehicle && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.counterpartyVehicle')}</h2>
                    <Grid>
                        <VehicleListItem
                            buttonNoTitle={t(SELECT_NO)}
                            buttonUnknownTitle={t(DONT_KNOW)}
                            buttonYesTitle={t(SELECT_YES)}
                            damages={handleDamages(counterpartyVehicle)}
                            dataTestId={`${vehicleList}counterpartyVehicle`}
                            handleClick={(e) => handleClick(counterpartyVehicle?.internalId, e)}
                            handleDelete={() => handleDelete(counterpartyVehicle?.internalId)}
                            hasDamages={true}
                            make={counterpartyVehicle?.make}
                            model={counterpartyVehicle?.model}
                            registrationNumber={counterpartyVehicle?.registrationNumber}
                            type={counterpartyVehicle?.type}
                            vehicleHasDamages={counterpartyVehicle?.vehicleHasDamages}
                            vehicleType={counterpartyVehicle?.vehicleType}
                            {...{ customCAN }}
                        />
                    </Grid>
                </>
            )}

            {otherVehicles.length > 0 && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.otherVehicles')}</h2>
                    <Grid>
                        {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                            const {
                                internalId,
                                make,
                                model,
                                registrationNumber,
                                type,
                                vehicleHasDamages,
                                vehicleType,
                            } = vehicle;
                            return (
                                <VehicleListItem
                                    buttonNoTitle={t(SELECT_NO)}
                                    buttonUnknownTitle={t(DONT_KNOW)}
                                    buttonYesTitle={t(SELECT_YES)}
                                    damages={handleDamages(vehicle)}
                                    dataTestId={`${vehicleList}${idx}`}
                                    handleClick={(e) => handleClick(internalId, e)}
                                    handleDelete={() => handleDelete(internalId)}
                                    hasDamages={true}
                                    vehicleHasDamages={vehicleHasDamages}
                                    {...{ customCAN, make, model, registrationNumber, type, vehicleType }}
                                />
                            );
                        })}
                    </Grid>
                </>
            )}
            {!validation ? <InputValidationError error={tWithNS.t('error')} fieldName={'Vehicle Damage'} /> : ''}

            <CircleIconButton
                ariaLabel={t(ADD_BUTTON)}
                className={'dual add-btn'}
                dataTestId={'btn-add-vehicle'}
                handleClick={handleAddButton}
                icon={<AddIcon />}
                label={tWithNS.t('add.button')}
            />

            {isModalOpen && (
                <StandardModal
                    closeButtonText={t(SELECT_NO)}
                    confirmButtonText={t(SELECT_YES)}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={tWithNS.t('modal.title')}
                >
                    <div className={'dialog__content text-center'}>{tWithNS.t('modal.question')}</div>
                </StandardModal>
            )}
        </PageLayout>
    );
};
