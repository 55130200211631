import { VehicleModel, VehicleTypeKeys } from '../../interfaces';
import { mapVehicleType } from './mapVehicleType';

export const vehicleTypeToEnum = ({
    vehicleType,
    type,
    weight,
}: VehicleModel): VehicleTypeKeys | null => {
    return !!vehicleType || !!type
        ? mapVehicleType(vehicleType || type, weight)
        : null;
};
