import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';

/**
 * Validation logic
 */
export const sellerInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...nameSchema(t),
    });
};
