import { OptionType } from '@protectorinsurance/ds-can';

export const otherInsuranceCompanyOptions: OptionType[] = [
    { value: 'Accept', label: 'Accept' },
    { value: 'AFA Försäkring', label: 'AFA Försäkring' },
    { value: 'Agria Djurförsäkring', label: 'Agria Djurförsäkring' },
    { value: 'Aktsam', label: 'Aktsam' },
    { value: 'Alandia Försäkring', label: 'Alandia Försäkring' },
    { value: 'Alecta', label: 'Alecta' },
    { value: 'Allmänna Änke- och Pupillkassan i Sverige', label: 'Allmänna Änke- och Pupillkassan i Sverige' },
    { value: 'AMF', label: 'AMF' },
    { value: 'Atlantica Båtförsäkring', label: 'Atlantica Båtförsäkring' },
    { value: 'Bliwa', label: 'Bliwa' },
    { value: 'Dina Försäkringar', label: 'Dina Försäkringar' },
    { value: 'Euro Accident', label: 'Euro Accident' },
    { value: 'Europeiska ERV', label: 'Europeiska ERV' },
    { value: 'Folksam', label: 'Folksam' },
    { value: 'Förenade Liv', label: 'Förenade Liv' },
    { value: 'Gjensidige', label: 'Gjensidige' },
    { value: 'Gar-Bo', label: 'Gar-Bo' },
    { value: 'Gouda Reseförsäkring', label: 'Gouda Reseförsäkring' },
    { value: 'Hedvig', label: 'Hedvig' },
    { value: 'Hela Försäkring', label: 'Hela Försäkring' },
    { value: 'Ica Försäkring', label: 'Ica Försäkring' },
    { value: 'Idun Liv', label: 'Idun Liv' },
    { value: 'If Skadeförsäkring', label: 'If Skadeförsäkring' },
    { value: 'Insurify', label: 'Insurify' },
    { value: 'KPA Pension', label: 'KPA Pension' },
    { value: 'Länsförsäkringar', label: 'Länsförsäkringar' },
    { value: 'LÖF Patientförsäkring', label: 'LÖF Patientförsäkring' },
    { value: 'Moderna Försäkringar', label: 'Moderna Försäkringar' },
    { value: 'Movestic', label: 'Movestic' },
    { value: 'Mysafety', label: 'Mysafety' },
    { value: 'Paydrive', label: 'Paydrive' },
    { value: 'PRI Pensionsgaranti', label: 'PRI Pensionsgaranti' },
    { value: 'Skandia', label: 'Skandia' },
    { value: 'Solid Försäkring', label: 'Solid Försäkring' },
    { value: 'SPP Pension & Försäkring', label: 'SPP Pension & Försäkring' },
    { value: 'Svedea', label: 'Svedea' },
    { value: 'Sveland', label: 'Sveland' },
    { value: 'Swedbank Försäkring', label: 'Swedbank Försäkring' },
    { value: 'Tre Kronor Försäkring', label: 'Tre Kronor Försäkring' },
    { value: 'Trygg-Hansa', label: 'Trygg-Hansa' },
    { value: 'Vardia Försäkring', label: 'Vardia Försäkring' },
    { value: 'Villaägarna', label: 'Villaägarna' },
    { value: 'WaterCircles', label: 'WaterCircles' },
];
