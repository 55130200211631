import { TFunction } from 'i18next';
import { RelationToWitnessKeys } from '../interfaces';
import { OptionType } from '../../../../components/atoms/mui/muiAutocomplete/MuiAutocomplete';

/**
 * Destructure necessary imports
 */
const {
    COLLEAGUE,
    FAMILY,
    FRIEND,
    NO_RELATION,
    PASSENGER_BUS_TAXI,
    PASSENGER_VEHICLE,
} = RelationToWitnessKeys;

/**
 * Utility logic
 */
const createRelationToWitness = (id: string) => {
    return `motor.witnessInformation.select.${id}`;
};

export const getRelationToWitnessOptions = (t: TFunction): OptionType[] => {
    return [
        { value: NO_RELATION, label: t(createRelationToWitness(NO_RELATION)) },
        { value: FAMILY, label: t(createRelationToWitness(FAMILY)) },
        { value: FRIEND, label: t(createRelationToWitness(FRIEND)) },
        { value: COLLEAGUE, label: t(createRelationToWitness(COLLEAGUE)) },
        {
            value: PASSENGER_VEHICLE,
            label: t(createRelationToWitness(PASSENGER_VEHICLE)),
        },
        {
            value: PASSENGER_BUS_TAXI,
            label: t(createRelationToWitness(PASSENGER_BUS_TAXI)),
        },
    ];
};
