import { Nullable } from '../../../../../interfaces';
import { VehicleTypeKeys } from '../../interfaces';
import { mapVehicleTypeMapMatcher } from './mapVehicleTypeMapMatcher';
import { VehicleTypeCodesSwedenMap } from '../../interfaces/maps/VehicleTypeCodesSweden.map';
import { VehicleTypeCodesFinlandMap } from '../../interfaces/maps/VehicleTypeCodesFinland.map';
import { iterateEnum } from '../../../../../utils/iterateEnum';

const LIGHT_TRUCK_WEIGHT_LIMIT = 3500;

export const mapVehicleType = (
    type: Nullable<string | VehicleTypeKeys>,
    weight: Nullable<number>
): VehicleTypeKeys => {
    const mappedType =
        mapVehicleTypeMapMatcher(VehicleTypeCodesSwedenMap, type) ||
        mapVehicleTypeMapMatcher(VehicleTypeCodesFinlandMap, type) ||
        iterateEnum<VehicleTypeKeys>(VehicleTypeKeys).find((x) => x === type) ||
        VehicleTypeKeys.OTHER;

    if (
        mappedType === VehicleTypeKeys.TRUCK &&
        weight &&
        weight <= LIGHT_TRUCK_WEIGHT_LIMIT
    ) {
        return VehicleTypeKeys.LIGHT_TRUCK;
    }

    return mappedType;
};
